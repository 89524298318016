import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import IconArrowRight from '~/assets/images/chat/arrowRight.svg?component-solid';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import IconHeader from '~/assets/images/vendors/headerImg.png';
import { Avatar } from '~/components/common/Avatar';
import IconField from '~/components/common/IconField';
import { IconMail, IconPhone } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { stringToColor } from '~/utils/strings';

interface ParticipantDetailsProps {
  participant: MagicDoor.Api.HydratedChatParticipantDto;
  viewParticipantDetails: (id: string) => string | undefined;
  isMobileView: boolean;
  onCancel?: () => void;
  onDone?: () => void;
}

export const ParticipantDetails = (props: ParticipantDetailsProps) => {
  const { t } = useLocalization();

  return (
    <Show
      when={props.isMobileView}
      fallback={
        <div class="absolute top-0 z-10 rounded-md bg-white text-xs shadow-lg ring-1 ring-partingline">
          <div class="w-56 md:min-w-80">
            <div class="relative flex gap-2 p-4">
              <img class="absolute left-0 top-0 size-full" src={IconHeader} />
              <Avatar name={props.participant.name ?? ''} size="large" />
              <div class="flex flex-col justify-center">
                <div class="text-lg font-semibold">{props.participant.name}</div>
                <div class="text-xs font-normal capitalize text-text-level03">{props.participant.participantType}</div>
              </div>
            </div>

            <div class="flex flex-col gap-4 p-4">
              <IconField name={t('Phone')} value={props.participant.phoneNumber} src={IconPhone} iconRounded />
              <IconField name={t('Email')} value={props.participant.email} src={IconMail} iconRounded />
            </div>
            {/* TODO: BACKEND REMOVED IT? CONFIRM HOW WE WANT TO HANDLE THE DATA */}
            {/* <div class="flex items-center justify-between gap-2 p-4">
        <div
          class={`flex w-full items-center justify-between rounded-md p-2 ${
            props.participant.tenant?.currentLease?.balance ? 'bg-danger-light' : 'bg-success-light'
          }`}>
          <div class="text-text-level03">{t('Balance due')}</div>
          <CnMoney money={props.participant.tenant?.currentLease?.balance} />
        </div>
      </div> */}
            <A
              href={props.viewParticipantDetails(props.participant.id) || ''}
              class="flex items-center justify-center border-t px-4 py-3 text-sm font-medium hover-allowed:hover:underline">
              {t('View details')}
            </A>
          </div>
        </div>
      }>
      <div class="relative flex flex-col pb-4">
        <button
          class="absolute left-full top-0 size-8 -translate-x-1/2 -translate-y-1/2 rounded-full border border-x-input-border bg-white p-1 hover:bg-inputbox-bg"
          onClick={() => props.onCancel?.()}>
          <IconClose class="text-text-level03" />
        </button>
        <div
          class="flex items-center gap-2 bg-current-alpha px-5 py-4 text-[--c]"
          style={{ '--c': stringToColor(props.participant?.name ?? '') }}>
          <Avatar name={props.participant.name ?? ''} size="large" class="!bg-transparent" />
          <div class="inline-flex flex-col">
            <div class="text-lg font-semibold text-title-gray">{props.participant.name}</div>
            <div class="text-xs font-normal capitalize text-text-level03">{props.participant.participantType}</div>
          </div>
        </div>
        <div class="flex flex-col gap-4 bg-white p-5">
          <IconField
            name={t('Phone')}
            value={props.participant.phoneNumber}
            src={IconPhone}
            iconRounded
            class="justify-between"
            iconPosition="right"
          />
          <IconField
            name={t('Email')}
            value={props.participant.email}
            src={IconMail}
            iconRounded
            class="justify-between"
            iconPosition="right"
          />
        </div>
        <A
          href={props.viewParticipantDetails(props.participant.id) || ''}
          class="mx-5 flex items-center justify-center rounded-lg border border-link px-4 py-3 text-sm font-medium text-link"
          onClick={() => props.onCancel?.()}>
          <span>{t('View details')}</span>
          <IconArrowRight />
        </A>
      </div>
    </Show>
  );
};
