import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { JSX } from 'solid-js';

interface TopSectionProps {
  title: JSX.Element;
  action?: JSX.Element;
  coloredClass?: string;
  topRightClass?: string;
}

const TopSection = (props: TopSectionProps) => (
  <div class={cn('flex items-center justify-between border-b border-partingline p-3 text-text-level01 md:p-4', props.coloredClass)}>
    <div class="text-lg font-semibold">{props.title}</div>
    <Show when={props.action}>
      <div class={cn('text-xs text-link', props.topRightClass)}>{props.action}</div>
      {/* <div class="text-xs text-link">{props.action}</div> */}
    </Show>
  </div>
);

export default TopSection;
