import { Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { TransactionStatus as TransactionStatusEnum } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';

const statusMap: Record<TransactionStatusEnum, { label: string; color: string }> = {
  [TransactionStatusEnum.Pending]: { label: 'Pending', color: 'text-text-level03' },
  [TransactionStatusEnum.Failed]: { label: 'Failed', color: 'text-error' },
  [TransactionStatusEnum.Success]: { label: 'Success', color: 'text-success' },
  [TransactionStatusEnum.Cancelled]: { label: 'Cancelled', color: 'text-warning' },
  [TransactionStatusEnum.Returned]: { label: 'Returned', color: 'text-text-level03' },
  [TransactionStatusEnum.Refunded]: { label: 'Refunded', color: 'text-text-level03' },
  [TransactionStatusEnum.Disputing]: { label: 'Disputing', color: 'text-text-level03' },
  [TransactionStatusEnum.Disputed]: { label: 'Disputed', color: 'text-text-level03' },
};

export const TransactionStatus: Component<{ class?: string; status: TransactionStatusEnum | null | undefined }> = (props) => {
  const { t } = useLocalization();

  return (
    <Show when={props.status} fallback={emptyPlaceholder}>
      <span class={`rounded-full bg-current-alpha px-2 py-1 text-xs ${statusMap[props.status!].color}`}>
        {t(statusMap[props.status!].label)}
      </span>
    </Show>
  );
};
