import githubMarkdownCss from 'github-markdown-css/github-markdown-light.css?inline';
import showdown from 'showdown';
import { Html } from '~/components/ui';

const converter = new showdown.Converter();
converter.setFlavor('github');

export const Markdown = (props: { content: string }) => {
  return (
    <>
      <style>{`${githubMarkdownCss}
        .markdown-body ol {
          list-style: decimal !important;
        }
        .markdown-body ul {
          list-style: disc !important;
        }
      `}</style>
      <Html as="div" class="markdown-body">
        {converter.makeHtml(props.content.replace(/\\r/g, '\n'))}
      </Html>
    </>
  );
};
