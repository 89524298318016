import { For, Show, createEffect, createMemo, createSignal, splitProps } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelectProvider, useSearchSelectContext } from '~/components/search-select/Context';
import { MutiRender } from '~/components/search-select/LevelSearchComponents/MutiRender';
import { SearchItem } from '~/components/search-select/LevelSearchComponents/SearchItem';
import { IconSearch, Loader, Popover } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { renderSelected } from './LevelSearchComponents/renderSelected';
import type { Component } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { SearchSelectProps } from '~/components/search-select/Context';

const SearchWrapper = () => {
  const { t } = useLocalization();

  const [open, setOpen] = createSignal(false);

  const { searchText, selected, onSearchInput, searchResults, hasSearched, loadPortfolios, globalLoading, getPlaceholder, mergedProps } =
    useSearchSelectContext();

  const [isInputFocused, setIsInputFocused] = createSignal(false);

  const hasResults = createMemo(() => {
    const results = searchResults();
    return results?.portfolios && results.portfolios.length > 0;
  });

  const getEmptyDescription = createMemo(() => {
    if (!hasSearched()) {
      return t('Enter keywords to search');
    }
    return globalLoading() ? '' : t('No results found');
  });

  const inputValue = createMemo(() => {
    if (mergedProps().multiple || selected().length === 0) {
      return searchText();
    }
    return renderSelected(selected()[0]);
  });

  createEffect(() => {
    if (open()) {
      loadPortfolios();
    }
  });

  return (
    <Popover open={open()} onOpenChange={setOpen}>
      <Popover.Trigger
        class={cn(
          'relative flex w-full flex-wrap items-center gap-2 rounded-md border bg-inputbox-bg px-2.5 py-0.5 pr-8 text-sm text-title-gray outline-none placeholder:text-auxiliary-text focus-within:ring-1 focus-within:ring-primary aria-expanded:ring-1 aria-expanded:ring-primary',
          mergedProps().class
        )}>
        <Show when={mergedProps().prefix}>
          <span class="select-none whitespace-nowrap text-text-level03">{mergedProps().prefix}: </span>
        </Show>
        <Show when={mergedProps().multiple}>
          <MutiRender />
        </Show>

        <input
          id={mergedProps().id}
          value={isInputFocused() ? searchText() : inputValue()}
          onInput={onSearchInput}
          placeholder={isInputFocused() ? inputValue() : getPlaceholder()}
          class="flex-basis-[80px] flex-1 truncate bg-transparent px-1 py-1.5 outline-none"
          type="text"
          onFocus={() => {
            setIsInputFocused(true);
          }}
          onBlur={() => {
            setIsInputFocused(false);
          }}
        />
        <IconSearch class="pointer-events-none absolute bottom-2.5 right-3 size-4 text-text-level03" />
      </Popover.Trigger>
      <Popover.Content
        as="div"
        class="thinscroll my-1 max-h-dropdown min-w-[--reference-width] space-y-0.5 overflow-auto rounded-md border border-gray-300 bg-white p-2 py-3 text-sm text-text-level02 shadow-lg">
        <Show when={!globalLoading()} fallback={<Loader class="mx-auto my-2 h-8 opacity-50" />}>
          <Show when={hasResults()} fallback={<Empty description={getEmptyDescription()} />}>
            <For each={searchResults()?.portfolios}>
              {(item, index) => (
                <div class="border-b border-input-border py-2 last:border-b-0">
                  <SearchItem level={0} item={item} index={index()} />
                </div>
              )}
            </For>
          </Show>
        </Show>
      </Popover.Content>
    </Popover>
  );
};

export const LeveledSearchSelect: Component<SearchSelectProps> = (props) => {
  return (
    <SearchSelectProvider {...props}>
      <SearchWrapper />
    </SearchSelectProvider>
  );
};

export const LabeledLeveledSearchSelect = (props: Omit<LabeledGroupProps, 'onChange'> & SearchSelectProps) => {
  const [searchProps, labeledProps] = splitProps(props, [
    'onChange',
    'multiple',
    'enabledTypes',
    'singleTypeOnly',
    'initialSelected',
    'initialExpandedKeys',
    'placeholder',
    'exclude',
    'query',
    'showInactivePortfolios',
  ]);

  return (
    <LabeledGroup {...labeledProps}>
      <LeveledSearchSelect {...searchProps} id={labeledProps.id} />
    </LabeledGroup>
  );
};
