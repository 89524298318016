import { Show } from 'solid-js';
import { TinToggle } from '~/components/TinToggle';
import { IconCalendar, IconCircleDollar, IconHash, IconMail, IconPhone, IconUser, Skeleton } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component, JSX } from 'solid-js';

const ApplicantDetailsItem = (props: { label: JSX.Element; value: JSX.Element; icon: Component<{ class: string }> }) => {
  const { fetchApplication } = useRentalApplication();
  return (
    <div class="flex items-center justify-between gap-2">
      <div class="flex items-center gap-2">
        <props.icon class="size-4 text-text-level02" />
        <div class="text-sm font-normal text-text-level02">{props.label}</div>
      </div>
      <Show when={!fetchApplication.loading} fallback={<Skeleton class="h-4 w-20" />}>
        <div class="text-right text-sm font-medium text-title-gray">{props.value}</div>
      </Show>
    </div>
  );
};

export const ApplicantDetails = () => {
  const { applicationStore } = useRentalApplication();
  const { t } = useLocalization();
  return (
    <div class="pt-6">
      <div class="flex flex-col gap-6 divide-y">
        <div class="flex flex-col gap-6">
          <ApplicantDetailsItem label={t('Email')} value={applicationStore.application?.email} icon={IconMail} />
          <ApplicantDetailsItem label={t('Phone')} value={applicationStore.application?.phone} icon={IconPhone} />
        </div>
        <div class="flex flex-col gap-6 pt-6">
          <ApplicantDetailsItem
            label={t('Date of birth')}
            value={dateFormat('MMMM DD, YYYY', applicationStore.application?.dateOfBirth)}
            icon={IconCalendar}
          />
          <ApplicantDetailsItem
            label={t('Annual income')}
            value={currency(applicationStore.application?.incomeInformation?.annualIncome ?? 0)}
            icon={IconCircleDollar}
          />
          <ApplicantDetailsItem label={t('Pets')} value={applicationStore.application?.pets?.length ? t('Yes') : t('No')} icon={IconHash} />
          <ApplicantDetailsItem
            label={t('SSN')}
            value={<TinToggle number={applicationStore.application?.ssn ?? ''} type="ssn" />}
            icon={IconUser}
          />
        </div>
      </div>
    </div>
  );
};
