import { Show, createEffect, createSignal } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { RingLoader } from '~/components/common/Loaders';
import { toast, IconPencilLine } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component, JSX } from 'solid-js';
import type { Promisable } from '~/utils/types';

const AvatarMask: Component<{ class?: string; children: JSX.Element }> = (props) => (
  <div
    class={`absolute inset-0 flex items-center justify-center overflow-hidden rounded-full bg-black/40 text-xs font-medium text-white ${props.class}`}>
    {props.children}
  </div>
);

export const AvatarEditor: Component<{
  name: string;
  avatar?: string;
  onChange?: (avatar: File) => Promisable<void>;
}> = (props) => {
  const { t } = useLocalization();
  const [avatar, setAvatar] = createSignal<string | undefined>();
  const [uploading, setUploading] = createSignal<boolean>(false);

  createEffect(() => props.avatar && setAvatar(props.avatar));

  const handleChange = async (e: Event) => {
    const input = e.target as HTMLInputElement;
    if (input.files?.length !== 1) return;
    const file = input.files[0];
    if (file.size > 1024 * 512) {
      return toast(t('Image size should be less than 512KB'), 'error');
    }
    setUploading(true);
    const url = URL.createObjectURL(file);
    setAvatar(url);
    await props.onChange?.(file);
    setUploading(false);
  };

  return (
    <label class="group relative cursor-pointer">
      <input hidden type="file" accept="image/*" onChange={handleChange} />
      <Avatar
        name={props.name}
        imgUrl={avatar()}
        class="size-14 border-primary/40 bg-light-pink object-cover text-2xl font-semibold text-primary md:size-16"
      />
      <Show
        when={uploading()}
        fallback={<AvatarMask class="opacity-0 transition-opacity hover-allowed:group-hover:opacity-100">{t('Upload')}</AvatarMask>}>
        <AvatarMask>
          <RingLoader />
        </AvatarMask>
      </Show>
      <IconPencilLine class="absolute bottom-0 right-0 size-5 rounded-full bg-link fill-current p-1 text-white" />
    </label>
  );
};
