import { IconChevronDown, IconLoader } from '~/components/ui';
export const ExpandIcon = (props: { onClick: (e: any) => void }) => {
  return (
    <span
      id="expand-icon"
      onClick={(e) => props.onClick(e)}
      class="flex size-8 items-center justify-center rounded-lg text-text-level03 hover-allowed:group-hover:bg-[#E6CEEF] hover-allowed:group-hover:text-purple">
      <IconLoader size={16} class="hidden animate-spin group-aria-[loading]:block" />
      <IconChevronDown
        size={16}
        class="duration-200 group-aria-[loading]:hidden group-aria-[search-item-expanded]:-rotate-180 group-aria-[search-item-expanded]:text-purple"
      />
    </span>
  );
};
