import { createEffect, createResource, createSignal, untrack } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { OwnersRepository } from '~/repositories/ownersRepository';
import { TransactionPaymentMethod, PayBillType } from '~/swagger/Api';
import { paymentMethodOptions } from '~/utils/constant';
import { createLazyResource, createMutation } from '~/utils/resource';
import { cloneDeep, uuid } from '~/utils/tool';
import type { CalculatedDistributionsForPropertiesDto, OwnerDistributionsResultDto, PropertyDto } from '~/swagger/Api';

export interface EditingDistribution {
  propertyId: string;
  ownerId: string;
  ownerName: string;
  distributionAmount: number;
  memo: string;
  paymentBankAccountId: string;
  receivingBankAccountId?: string;
  paymentType: PayBillType;
  paymentMethod?: TransactionPaymentMethod;
  externalTransactionId?: string;
  commonMemo: string;
  checkNumber?: number;
  ownershipPercentage?: number;
}

type SelectedProperty = MagicDoor.Api.PropertyDto | null | MagicDoor.Api.HydratedPropertyDto | undefined;

interface EditingDistributionGroup {
  groupId: string;
  ownerId: string;
  propertyIds: string[];
}

export interface AddOwnerDistributionsStore {
  currentStep: number;
  selectedProperties: SelectedProperty[];
  selectedCategory: PropertiesCategory;
  isValidate: boolean;
  editingDistributions: EditingDistribution[];
  isSubmitting: boolean;
  response?: OwnerDistributionsResultDto;
  editingDistributionGroups: EditingDistributionGroup[];
  ownerReceiveBankAccountsMap: Map<string, { value: string; label: string }[]>;
}

export enum PropertiesCategory {
  ALL,
  SOME,
}

export type PropertyReviewRecord = {
  property?: MagicDoor.Api.PropertyDto;
  isMissingOwnerInformation?: boolean;
  ownershipPercentage?: number;
  checked?: boolean;
};

const getInitialStore = () => ({
  selectedCategory: PropertiesCategory.ALL,
  currentStep: 0,
  selectedProperties: [],
  isValidate: true,
  editingDistributions: [],
  isSubmitting: false,
  editingDistributionGroups: [],
  ownerReceiveBankAccountsMap: new Map(),
});

export interface AddDistributionData {
  propertyId: string;
  ownerId: string;
  distributionAmount: number;
  paymentType: PayBillType;
  paymentMethod?: TransactionPaymentMethod;
  bankAccountId: string;
  paymentAccountId?: string;
  externalTransactionId?: string;
  memo?: string;
}

export const [AddOwnerDistributionsProvider, useAddOwnerDistributions] = createMagicDoorContext('AddOwnerDistributions', () => {
  const ownersRepo = new OwnersRepository();
  const bankAccountsRepo = new BankAccountRepository();
  const { t } = useLocalization();
  const paymentOptionsForOwnerDistributions = (t: (str: string) => string) => [
    { label: t('ACH'), value: PayBillType.Ach },
    { label: t('Manual'), value: PayBillType.Manual },
    { label: t('Print check'), value: PayBillType.PrintCheck },
  ];
  const [store, setStore] = createStore<AddOwnerDistributionsStore>(getInitialStore());
  const [propertyValidationRecords, propertyValidationRecordsActions] = createLazyResource<PropertyReviewRecord[]>(async () => {
    const response = await ownersRepo.getOwnersDistributionsValidation({
      propertyIds:
        store.selectedCategory === PropertiesCategory.ALL
          ? undefined
          : store.selectedProperties.filter((property) => !!property).map((property) => property?.id),
    });
    const results = [
      ...(response.needsAttentionProperties ?? []).map((record) => ({
        property: record.property,
        isMissingOwnerInformation: record.isMissingOwnerInformation,
        ownershipPercentage: record.calculatedOwnershipPercentage,
        checked: record.isMissingOwnerInformation ? false : true,
      })),
      ...(response.validProperties ?? []).map((record) => ({
        property: record.property,
        isMissingOwnerInformation: false,
        ownershipPercentage: 100,
        checked: true,
      })),
    ];

    return results;
  });
  const [bankAccountOptions] = createResource(async () => {
    const response = await bankAccountsRepo.getBankAccounts();
    const options = (response ?? []).map((account) => ({
      label: account.name,
      value: account.id,
      plaid: account.plaid,
    }));

    return options;
  });

  const [ownerDistributions, setOwnerDistributions] = createSignal<CalculatedDistributionsForPropertiesDto['properties']>([]);

  const calculatePropertiesDistributions = createMutation(async () => {
    const validatedProperties = propertyValidationRecords()?.filter((record) => !record.isMissingOwnerInformation && record.checked);
    if (!validatedProperties?.length) return;
    const response = await ownersRepo.getOwnersDistributionsCalculation({
      propertyIds: validatedProperties?.filter((record) => !!record.property).map((record) => (record.property as PropertyDto).id),
    });
    setOwnerDistributions(response.properties);
    initEditingDistributionGroups();
  });

  const onChangeSelectedProperties = (properties: SelectedProperty[]) => {
    setStore('selectedProperties', properties);
  };

  const onChangeIsValidate = (step: number) => {
    let isValidate = store.isValidate;

    switch (step) {
      case 0: {
        isValidate =
          store.selectedCategory === PropertiesCategory.ALL || [...new Set(store.selectedProperties)].filter((id) => !!id).length > 0;
        break;
      }

      case 1: {
        isValidate = (propertyValidationRecords() ?? []).some((record) => record.checked);

        break;
      }

      case 2: {
        isValidate = Boolean(store.editingDistributions.find((item) => item.distributionAmount > 0));
        break;
      }

      case 3: {
        isValidate =
          Boolean(store.editingDistributionGroups.length) &&
          Boolean(
            !store.editingDistributions.find((item) => {
              if (item.paymentType === PayBillType.Ach && !item.receivingBankAccountId) {
                return true;
              }

              if (item.paymentType === PayBillType.Manual && !item.externalTransactionId) {
                return true;
              }

              return false;
            })
          );
        break;
      }

      default: {
        isValidate = true;
        break;
      }
    }

    setStore('isValidate', isValidate);
  };

  const onStepChange = (step: number, isBack?: boolean) => {
    if (store.currentStep === 3 && step === 4 && !isBack) {
      onSubmitOwnerDistributions();
      return;
    }

    if (step === 1 && !isBack) {
      setStore(
        'selectedProperties',
        store.selectedProperties.filter((property) => !!property)
      );
    }

    if (step === 2 && !isBack) {
      calculatePropertiesDistributions();
    }

    setStore('currentStep', step);
  };

  const onChangePropertiesCategory = (category: PropertiesCategory) => {
    setStore('selectedCategory', category);
  };

  const onChangePropertyValidationRecords = (records: PropertyReviewRecord[]) => {
    propertyValidationRecordsActions.mutate(records);
  };

  const onRemoveOwnerDistributionByPropertyId = (propertyId?: string) => {
    setOwnerDistributions(ownerDistributions()?.filter((item) => item.property?.id !== propertyId));
    setStore(
      produce((state) => {
        state.editingDistributions = state.editingDistributions.filter((item) => item.propertyId !== propertyId);
      })
    );
  };

  const onRemoveOwnerDistributionByOwnerId = (ownerId?: string) => {
    const clone = cloneDeep(ownerDistributions());

    clone?.forEach((record) => {
      record.distributionOwners = record.distributionOwners?.filter((owner) => owner.owner?.id !== ownerId);
    });
    setOwnerDistributions(clone?.filter((item) => item.distributionOwners.length));

    setStore(
      produce((state) => {
        state.editingDistributions = state.editingDistributions.filter((item) => item.ownerId !== ownerId);
      })
    );
  };

  const updateOwnerDistributionRecord = (params: {
    ownerId?: string;
    propertyId?: string;
    payload: { [key in keyof EditingDistribution]?: any };
  }) => {
    const { ownerId, propertyId, payload } = params;

    if (!ownerId && !propertyId) {
      return;
    }

    const editingRecordIndexes = store.editingDistributions
      .map((record, index) => {
        const isValid = (ownerId ? record.ownerId === ownerId : true) && (propertyId ? record.propertyId === propertyId : true);
        return isValid ? index : -1;
      })
      .filter((index) => index > -1);

    editingRecordIndexes.forEach((value) => {
      const editingRecord = store.editingDistributions[value];

      if (!editingRecord) {
        return;
      }

      setStore('editingDistributions', value, (prev) => ({ ...prev, ...payload }));
    });
  };

  const onSubmitOwnerDistribution = async (data: AddDistributionData) => {
    const res = await ownersRepo.submitOwnerDistributions({
      groups: [
        {
          ownerId: data.ownerId,
          bankAccountId: data.bankAccountId,
          paymentAccountId: data.paymentAccountId,
          paymentType: data.paymentType,
          paymentMethod: data.paymentMethod,
          externalTransactionId: data.externalTransactionId,
          distributions: [
            {
              propertyId: data.propertyId,
              distributionAmount: data.distributionAmount,
              memo: data.memo,
            },
          ],
        },
      ],
    });
    toast.success(t('Owner distribution(s) have been added successfully'));
    return res;
  };

  const onRequest = async (payload: MagicDoor.Api.CreateOwnerDistributionsGroupDto[]) => {
    setStore('isSubmitting', true);
    try {
      return await ownersRepo.submitOwnerDistributions({ groups: payload } as any);
    } finally {
      setStore('isSubmitting', false);
    }
  };

  const onSubmitOwnerDistributions = async () => {
    const payload: MagicDoor.Api.CreateOwnerDistributionsGroupDto[] = [];
    const filteredEditingDistributions = store.editingDistributions.filter((distribution) => {
      if (distribution.distributionAmount <= 0 || !distribution.paymentBankAccountId) {
        return false;
      }

      if (distribution.paymentType === PayBillType.Ach && !distribution.receivingBankAccountId) {
        return false;
      }

      if (distribution.paymentType === PayBillType.Manual && !distribution.externalTransactionId) {
        return false;
      }

      return true;
    });
    const findCommonData = (group: EditingDistributionGroup) => {
      const { ownerId, propertyIds } = group;
      const firstEditingDistribution = filteredEditingDistributions.find(
        (distribution) => distribution.ownerId === ownerId && propertyIds.includes(distribution.propertyId)
      );

      if (!firstEditingDistribution) {
        return;
      }

      return {
        bankAccountId: firstEditingDistribution.paymentBankAccountId,
        paymentAccountId: firstEditingDistribution.receivingBankAccountId,
        paymentType: firstEditingDistribution.paymentType,
        paymentMethod: firstEditingDistribution.paymentMethod,
        externalTransactionId: firstEditingDistribution.externalTransactionId,
        memo: firstEditingDistribution.commonMemo,
      };
    };

    store.editingDistributionGroups.forEach((group) => {
      const commonData = findCommonData(group);
      const distributions = group.propertyIds
        .map((propertyId) => {
          const data = filteredEditingDistributions.find(
            (distribution) => distribution.propertyId === propertyId && distribution.ownerId === group.ownerId
          );

          if (!data) {
            return undefined;
          }

          return {
            propertyId,
            distributionAmount: data.distributionAmount,
            memo: data.memo,
          };
        })
        .filter(Boolean) as { propertyId: string; distributionAmount: number; memo: string }[];

      if (commonData && distributions.length) {
        payload.push({ ownerId: group.ownerId, ...commonData, distributions });
      }
    });

    const response = await onRequest(payload);

    setStore('response', response);
    toast.success(t('Owner distribution(s) have been added successfully'));

    const timer = window.setTimeout(() => {
      setStore('currentStep', untrack(() => store.currentStep) + 1);
      window.clearTimeout(timer);
    }, 100);
  };

  const onResetStore = () => {
    setStore(() => ({ ...getInitialStore() }));
  };

  const onFetchOwnerPaymentAccounts = async (ownerId: string) => {
    try {
      const response = await ownersRepo.getOwnerPaymentAccounts(ownerId);
      return (response ?? []).paymentAccounts.map((item) => ({ label: item.name, value: item.id }));
    } catch {
      // do nothing
    }
  };

  function initEditingDistributionGroups() {
    const ownerBankAccountsMap = new Map<string, { label: string; value: string }[]>();
    const editingDistributions: EditingDistribution[] = [];

    ownerDistributions()?.forEach((property) => {
      property.distributionOwners?.forEach((record) => {
        if (property.property && record.owner) {
          if (!ownerBankAccountsMap.has(record.owner.id)) {
            ownerBankAccountsMap.set(
              record.owner.id,
              record.paymentAccounts.map((item) => ({ label: item.name, value: item.id }))
            );
          }

          const existingEditingDistribution = store.editingDistributions?.find(
            (item) => item.propertyId === property.property.id && item.ownerId === record.owner.id
          );

          editingDistributions.push({
            propertyId: property.property.id,
            ownerId: record.owner.id,
            ownerName: `${record.owner.firstName} ${record.owner.lastName || ''}`,
            memo: property.defaultMemo || '',
            commonMemo: '',
            paymentBankAccountId: property.defaultBankAccountId ?? bankAccountOptions()?.[0]?.value ?? '',
            receivingBankAccountId: ownerBankAccountsMap.get(record.owner.id)?.at(0)?.value ?? undefined,
            paymentMethod: TransactionPaymentMethod.Ach,
            distributionAmount: record.distributionAmount || 0,
            paymentType: PayBillType.PrintCheck,
            ownershipPercentage: record.ownershipPercentage,
            ...existingEditingDistribution,
          });
        }
      });
    });

    setStore('editingDistributions', editingDistributions);
    setStore('ownerReceiveBankAccountsMap', ownerBankAccountsMap);
  }

  createEffect(() => {
    const ownerGroups: { ownerId: string; propertyDefaultBankId: string }[] = [];

    store.editingDistributions.forEach((distribution) => {
      if (
        !ownerGroups.find(
          (item) => item.ownerId === distribution.ownerId && item.propertyDefaultBankId === distribution.paymentBankAccountId
        )
      ) {
        ownerGroups.push({ ownerId: distribution.ownerId, propertyDefaultBankId: distribution.paymentBankAccountId });
      }
    });

    setStore(
      'editingDistributionGroups',
      ownerGroups.map((group) => ({
        groupId: uuid(),
        ownerId: group.ownerId,
        propertyIds: store.editingDistributions
          .filter(
            (distribution) => distribution.ownerId === group.ownerId && distribution.paymentBankAccountId === group.propertyDefaultBankId
          )
          .map((distribution) => distribution.propertyId),
      }))
    );
  });

  return {
    store,
    setStore,
    paymentOptionsForOwnerDistributions,
    onChangeSelectedProperties,
    onChangeIsValidate,
    onStepChange,
    onChangePropertiesCategory,
    propertyValidationRecords,
    propertyValidationRecordsActions,
    ownerDistributions,
    bankAccountOptions,
    updateOwnerDistributionRecord,
    onChangePropertyValidationRecords,
    onResetStore,
    paymentMethodOptions: paymentMethodOptions(t),
    onSubmitOwnerDistribution,
    onRemoveOwnerDistributionByPropertyId,
    onRemoveOwnerDistributionByOwnerId,
    onFetchOwnerPaymentAccounts,
    calculatePropertiesDistributions,
  };
});
