import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal } from 'solid-js';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { PaymentAmount } from '~/components/common/PaymentAmount';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentPaymentSettings, useDepositPaymentSettings } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { OfflineTransactionPaymentMethodDto } from '~/swagger/Api';
import { formConfirmationContent, MAX_INPUT_AMOUNT, paymentTypes } from '~/utils/constant';
import { isNumber } from '~/utils/number';
import { isEmptyData } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { Option } from '~/components/common/DropdownMenu';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseReceivePaymentModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
  onSubmit: (data: ReceivePaymentDataType) => Promise<void>;
};

export type ReceivePaymentDataType = {
  date?: string;
  amount?: string;
  description?: string;
  tenantId?: string;
  paymentMethod?: OfflineTransactionPaymentMethodDto;
  reference?: string;
};

export const LeaseReceivePaymentModal: Component<LeaseReceivePaymentModalProps> = (props) => {
  const { t } = useLocalization();
  const { triggerUpdate } = useUpdates();
  const { allRentPaymentSettings } = useRentPaymentSettings();
  const { allDepositSettings } = useDepositPaymentSettings();
  const navigate = useNavigate();

  const formId = 'setup_charge_form';
  const MIN_AMOUNT = 1;

  const [loading, setLoading] = createSignal<boolean>(false);
  const [formData, setFormData] = createSignal<ReceivePaymentDataType>({
    date: undefined,
    amount: undefined,
    description: undefined,
    tenantId: undefined,
    paymentMethod: OfflineTransactionPaymentMethodDto.Check,
    reference: undefined,
  });
  const [amount, setAmount] = createSignal<string>();
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const tenantOptions = createMemo<Option[]>(() =>
    props.currentLease.tenants.map((tenant) => ({
      label: `${tenant.tenant.firstName || ''} ${tenant.tenant.lastName || ''}`,
      value: tenant.tenant.id || '',
    }))
  );
  const [formErrors, setFormErrors] = createSignal<ReceivePaymentDataType>({});

  const setFormDataCommon = (value: string, key: string) => {
    setFormData({
      ...formData(),
      [key]: value,
    });

    if (key === 'amount') {
      setAmount(value);
    }
  };

  const handleBankAccountCheck = (type: 'deposit' | 'rent') => {
    const config = {
      deposit: {
        condition: () => !allDepositSettings()?.settings?.bankAccountId,
        navigatePath: '/settings/property/deposit-payment',
      },
      rent: {
        condition: () => !allRentPaymentSettings()?.settings?.bankAccountId,
        navigatePath: '/settings/property/rent-payment',
      },
    }[type];

    if (config.condition()) {
      confirm({
        title: t('Information required'),
        content: t('Please set up the receiving bank account.'),
        doneText: t('Go to settings'),
        onResolve: (value) => {
          if (value) {
            navigate(config.navigatePath);
          }
        },
      });
      return true;
    }
    return false;
  };

  const handleSubmit = async (ev: Event) => {
    ev.preventDefault();
    const validateResult = validator();
    if (!validateResult) return;
    const isDeposit = location.pathname.includes('/deposits/');
    if (handleBankAccountCheck(isDeposit ? 'deposit' : 'rent')) return;
    setLoading(true);
    try {
      await props.onSubmit({
        date: formData().date as string,
        amount: amount(),
        description: formData().description || undefined,
        tenantId: formData().tenantId || undefined,
        paymentMethod: formData().paymentMethod || undefined,
        reference: formData().reference || undefined,
      });

      props.onCancel && props.onCancel();
      triggerUpdate();

      toast.success(t('{name} has been added successfully', { name: t('Receive payment') }));
    } finally {
      setLoading(false);
    }
  };

  const validator = (): boolean => {
    let result = true;
    const errors: ReceivePaymentDataType = {};
    Object.keys(formData()).forEach((key: string) => {
      if (!['description', 'tenantId', 'reference'].includes(key)) {
        const nameObj: any = {
          date: 'Date',
          amount: 'Amount',
        };
        (errors[key as keyof ReceivePaymentDataType] as any) = (() => {
          const name = nameObj[key];
          const value = formData()[key as keyof ReceivePaymentDataType];
          const translatedName = t(name);

          if (!value) {
            return t('{name} is required', { name: translatedName });
          }
          if (value && value.length > 255) {
            return t('{name} must be {length} characters or less', { name: translatedName, length: length.toString() });
          }
          if (key === 'amount' && !isNumber(amount())) {
            return t('{name} must be a number', { name: translatedName });
          }
          return undefined;
        })();
      }
    });
    for (const key in errors) {
      if (errors[key as keyof ReceivePaymentDataType]) {
        result = false;
        break;
      }
    }
    setFormErrors(errors);
    return result;
  };

  createEffect(() => {
    setShowCloseConfirmation(!isEmptyData(formData()));
  });

  return (
    <Modal
      {...props}
      title={t('Receive payment')}
      doneText={t('Save')}
      submitId={formId}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <form class="flex flex-col gap-5 px-8 py-6" id={formId} onSubmit={handleSubmit}>
        <LeaseOverviewBanner lease={props.currentLease} />
        <div class="grid grid-cols-2 gap-x-7 gap-y-6">
          <DueDateInputField
            class="col-span-full lg:col-span-1"
            required
            contentPosition={['right']}
            label={t('Date')}
            value={formData().date}
            error={formErrors().date}
            onInput={(value) => setFormDataCommon(value as string, 'date')}
          />
          <DropdownMenu
            class="col-span-full lg:col-span-1"
            label={t('Received from')}
            value={formData().tenantId}
            options={tenantOptions()}
            onChange={(value) => setFormDataCommon(value, 'tenantId')}
            placeholder={t('Please select')}
          />
          <LabeledNumberInput
            class="col-span-full lg:col-span-1"
            required
            label={t('Amount')}
            value={amount()}
            error={formErrors().amount}
            step={0.01}
            onInput={(value) => setFormDataCommon(`${value}`, 'amount')}
            placeholder={t('Please enter')}
            isBlur={false}
            min={MIN_AMOUNT}
            max={MAX_INPUT_AMOUNT}
          />
          <LabeledTextInput
            class="col-span-full lg:col-span-1"
            label={t('Description')}
            value={formData().description}
            onInput={(value) => setFormDataCommon(value, 'description')}
            placeholder={t('Please enter')}
          />
          <DropdownMenu
            class="col-span-full lg:col-span-1"
            label={t('Payment method')}
            value={formData().paymentMethod}
            options={paymentTypes(t)}
            onChange={(value) => setFormDataCommon(value, 'paymentMethod')}
            placeholder={t('Please select')}
          />
          <LabeledTextInput
            class="col-span-full lg:col-span-1"
            label={t('Reference')}
            value={formData().reference}
            onInput={(value) => setFormDataCommon(value, 'reference')}
            placeholder={t('Please enter')}
          />
        </div>
      </form>
      <PaymentAmount label={t('Total amount')} mount={formData().amount} min={MIN_AMOUNT} max={MAX_INPUT_AMOUNT} />
    </Modal>
  );
};
