import { Outlet } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Menu } from './Menu';
import { MenuProvider, useMenu } from './Menu/MenuContext';
import { Navigation } from './Navigation';

const Layout = () => {
  const [isSearchActive, setIsSearchActive] = createSignal<boolean>(false);

  return (
    <MenuProvider>
      <LayoutContent isSearchActive={isSearchActive} setIsSearchActive={setIsSearchActive} />
    </MenuProvider>
  );
};

const LayoutContent = (props: { isSearchActive: () => boolean; setIsSearchActive: (value: boolean) => void }) => {
  const menu = useMenu();
  return (
    <div
      class="flex min-h-dvh bg-inputbox-bg"
      classList={{
        'overflow-x-hidden': menu?.isSidebarOpen() && menu?.isMobile(),
      }}>
      <Menu />
      <main
        class="flex w-full grow pt-16"
        classList={{
          'md:pl-64': !menu?.isManuallyCollapsed(),
          'md:pl-16': menu?.isManuallyCollapsed(),
          'translate-x-[245px]': menu?.isSidebarOpen() && menu?.isMobile(),
        }}>
        <Navigation setIsSearchActive={props.setIsSearchActive} />
        <Outlet />
      </main>
      <Show when={props.isSearchActive()}>
        <div class="overlay fixed inset-0 z-40 bg-black/70" />
      </Show>
    </div>
  );
};

export default Layout;
