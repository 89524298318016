import { createMemo, createSignal, onMount, Show } from 'solid-js';
import { FormItem, Form } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { BankAccountType } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { getOrdinalDay } from '~/utils/date';
import { bankAccountName, bankAccountNumber, bankAccountRoutingNumber, bankAccountType } from '~/utils/formRules';
import type { BetterForm } from '~/components/common/BetterForm';

interface BankAccountFormProps {
  onSubmit: () => Promise<void> | void;
  title?: string;
  form: BetterForm.form;
  isEdit?: boolean;
  class?: string;

  bankAccount?: MagicDoor.Api.HydratedBankAccountDto;
}

function BankAccountForm(props: BankAccountFormProps) {
  const { t } = useLocalization();
  const { getSweepsLimits } = useBankAccount();

  const [loading, setLoading] = createSignal<boolean>(false);
  const [sweepsLimits, setSweepsLimits] = createSignal<MagicDoor.Api.BankAccountSweepLimitsDto>({
    interval: 1,
  });

  const options = createMemo(() => {
    let options: any[] = [];
    for (let i = 1; i <= 31; i++) {
      options.push({
        label: t('{day} of every month', {
          day: getOrdinalDay(i),
        }),
        value: i,
        disabled: false,
        disabledReason: t('For your current subscription plan, sweeps can only be made every {num} days.', {
          num: sweepsLimits().interval,
        }),
      });
    }
    const scheduleSweepDays = props.form.formStore.scheduleSweepDays;

    if (scheduleSweepDays?.length) {
      options = options.map((option) => {
        let shouldDisable = false;
        scheduleSweepDays.forEach((selectedValue: any) => {
          if (option.value !== selectedValue && Math.abs(selectedValue - option.value) < sweepsLimits().interval) {
            shouldDisable = true;
          }
        });
        return {
          ...option,
          disabled: shouldDisable,
        };
      });
    }
    return options;
  });

  const submit = async () => {
    setLoading(true);
    try {
      await props.onSubmit();
    } finally {
      setLoading(false);
    }
  };

  const showAccountNumberField = createMemo(() => {
    return !props.isEdit || (!props.bankAccount?.plaid && !props.bankAccount?.accountNumber);
  });

  const showRoutingNumberField = createMemo(() => {
    return !props.isEdit || (!props.bankAccount?.plaid && !props.bankAccount?.routingNumber);
  });

  const showTypeField = createMemo(() => {
    return !props.isEdit || (!props.bankAccount?.plaid && !props.bankAccount?.type);
  });

  onMount(async () => {
    const res = await getSweepsLimits();
    setSweepsLimits(res);
  });

  return (
    <Form defaultForm={props.form} class={cn('flex flex-col items-center justify-center', props.class)} onFormSubmit={submit}>
      <div class="w-full overflow-auto">
        <div class="mx-auto flex w-2/3 flex-col gap-6 py-4">
          <FormItem
            formFieldName="name"
            label={t('Name')}
            placeholder={t('Please enter the name')}
            component={LabeledTextInput}
            rules={[
              {
                required: true,
                message: t('Please enter the name'),
              },
              ...bankAccountName(t),
            ]}
          />
          <FormItem
            formFieldName="description"
            placeholder={t('Please enter')}
            label={t('Description')}
            rows={5}
            maxlength={500}
            component={LabeledTextArea}
          />
          <Show when={showAccountNumberField()}>
            <FormItem
              formFieldName="accountNumber"
              placeholder={t('Please enter')}
              label={t('Account number')}
              rules={bankAccountNumber(t, true)}
              component={LabeledTextInput}
            />
          </Show>
          <Show when={showRoutingNumberField()}>
            <FormItem
              formFieldName="routingNumber"
              placeholder={t('Please enter')}
              label={t('Routing number')}
              component={LabeledTextInput}
              rules={bankAccountRoutingNumber(t, true)}
            />
          </Show>
          <Show when={showTypeField()}>
            <FormItem
              formFieldName="type"
              placeholder={t('Please enter')}
              label={t('Type')}
              component={DropdownMenu}
              rules={bankAccountType(t, true)}
              options={Object.entries(BankAccountType).map(([key, value]) => ({ label: t(key), value }))}
              onChangeMethodName="onChange"
            />
          </Show>
          <div class="normal-case">
            <FormItem
              description={t(
                'On the withdrawal date, the funds from the third-party platform will be automatically withdrawn to the Funding account.'
              )}
              formFieldName="scheduleSweepDays"
              placeholder={t('Please enter')}
              label={t('Withdrawal date')}
              options={options()}
              component={LabeledSelect}
              multiple
            />
            <div class="mt-1.5 text-xs text-text-level03">
              {t('If the set date exceeds the number of days in a certain month, the payment will be made on the last day of that month.')}
            </div>
          </div>
        </div>
      </div>

      <div class="sticky bottom-0 flex w-full justify-end gap-2 border-t border-partingline bg-white px-6 py-5">
        <Button loading={loading()} disabled={!props.form.isValidate} type="submit">
          {t('Submit')}
        </Button>
      </div>
    </Form>
  );
}

export default BankAccountForm;
