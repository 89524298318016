import { createMemo, Show } from 'solid-js';
import { Skeleton } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import ScoreSVG from './score.svg?component-solid';
import StarSVG from './star.svg';
export const AiScore = () => {
  const { applicationStore, fetchApplication } = useRentalApplication();
  const { t } = useLocalization();

  const indicatorPosition = createMemo(() => {
    const score = applicationStore.application?.aiApplicationScore || 0;
    return `${score}%`;
  });

  return (
    <div class="flex grow basis-96 flex-col rounded-lg bg-white p-6">
      <div class="mb-4 text-base font-bold text-[#333850]">{t('AI score')}</div>

      <div class="flex flex-col gap-6 divide-y">
        <div class="flex w-full items-center gap-4 md:w-auto">
          <ScoreSVG />
          <div class="relative flex h-36 grow flex-col items-center justify-around gap-1 rounded-2xl bg-[#AD0EFD] p-4 before:absolute before:-left-4 before:top-1/2 before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-[#AD0EFD] before:content-[''] md:w-96">
            <div class="flex items-center gap-2">
              <div class="text-sm font-medium capitalize text-white">{t('Overall rating')}:</div>
              <span class="text-[44px] font-semibold capitalize leading-[60px] text-white">
                <Show when={!fetchApplication.loading} fallback={<Skeleton class="h-5 w-20" />}>
                  {applicationStore.application?.aiApplicationScore || 0}
                </Show>
              </span>
            </div>

            <div class="relative h-2 w-full rounded-full bg-gradient-to-r from-orange-500 via-yellow-400 to-green">
              <div class="absolute -top-0.5 h-3 w-0.5 rounded-full bg-white shadow-md" style={{ left: indicatorPosition() }} />
            </div>

            <div style={{ 'background-image': `url(${StarSVG})` }} class="absolute inset-0 bg-cover bg-center" />
          </div>
        </div>
        <div class="flex flex-col gap-2 pt-4">
          <div class="text-base font-semibold text-title-gray">{t('Reason for AI score')}</div>
          <div class="text-sm font-normal text-title-gray">
            {applicationStore.application?.aiApplicationDescription || emptyPlaceholder}
          </div>
        </div>
      </div>
    </div>
  );
};
