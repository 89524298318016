import { For, Show, createMemo, createSignal } from 'solid-js';
import { useFormContext } from '~/components/common/BetterForm';
import { IconCircleChevronDown, IconTrash } from '~/components/ui/Icons';
import { useLocalization } from '~/contexts/global';
import { EditFormItem } from '~/pages/settings/components/edit-form/EditForm';
import { PrincipalType } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { addressRequired, email, phone, ssn } from '~/utils/formRules';
import { FileUpload } from './FileUpload';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const PrincipalItem = (props: { principal: MagicDoor.Api.UpdateCompanyPrincipalDto; index: number; expand?: boolean }) => {
  const { t } = useLocalization();
  const form = useFormContext();
  const type = createMemo(() => form.formStore.principals?.at(props.index)?.type);
  const sections = createMemo<ItemProps[]>(() => {
    return [
      {
        field: ['principals', props.index, 'firstName'],
        label: 'First name',
        type: 'string',
        placeholder: 'Please enter first name',
      },
      {
        field: ['principals', props.index, 'lastName'],
        label: 'Last name',
        type: 'string',
        placeholder: 'Please enter last name',
      },
      {
        field: ['principals', props.index, 'email'],
        label: 'Email',
        type: 'string',
        placeholder: 'Please enter email',
        rules: email(t),
      },
      {
        field: ['principals', props.index, 'phone'],
        label: 'Phone',
        type: 'string',
        placeholder: 'Please enter phone',
        rules: phone(t),
      },
      {
        field: ['principals', props.index, 'ssn'],
        label: 'SSN',
        type: 'string',
        placeholder: 'Please enter SSN',
        rules: ssn(t),
      },
      {
        field: ['principals', props.index, 'dateOfBirth'],
        label: 'Date of birth',
        type: 'date',
        placeholder: 'MM/DD/YYYY',
      },
      {
        field: ['principals', props.index, 'type'],
        label: 'Type',
        type: 'select',
        placeholder: 'Please select type',
        options: [
          { label: t('Control person'), value: PrincipalType.ControlPerson },
          { label: t('Owner'), value: PrincipalType.BeneficialOwner },
        ],
        disabled: props.index === 0,
      },
      {
        field: ['principals', props.index, 'ownership'],
        label: 'Ownership %',
        type: 'number',
        rules: [
          {
            validator: (value: number) => {
              if (!value) return true;
              return value >= 25 && value <= 100;
            },
            message: t('Ownership must be between 25% and 100%'),
          },
          type() === PrincipalType.BeneficialOwner ? { required: true, message: t('Please enter ownership %') } : null,
        ].filter(Boolean),
        placeholder: 'Please enter ownership %',
      },
      {
        field: ['principals', props.index, 'address'],
        label: 'Address',
        type: 'address',
        class: 'col-span-full',
        rules: addressRequired(t),
      },
      {
        field: ['principals', props.index, 'ssnCardFileId'],
        label: 'SSN card file',
        type: FileUpload,
        description: "A scan of the individual's SSN card",
        class: 'col-span-full',
      },
      {
        field: ['principals', props.index, 'driversLicenseFileId'],
        label: 'Driver license file',
        type: FileUpload,
        description: "A scan of the individual's driver's license or other government-issued ID",
        class: 'col-span-full',
      },
      {
        field: ['principals', props.index, 'personalAddressFileId'],
        label: 'Personal address file',
        type: FileUpload,
        description:
          'A document that proves that the residential address belongs to the associated person. May be a utility bill, bank statement, or government ID. Must be from the past 3 months',
        class: 'col-span-full',
      },
    ];
  });

  return (
    <div class="grid gap-x-5 gap-y-6 px-3 py-4 text-left md:grid-cols-2 md:p-4">
      <For each={sections()}>
        {(section) => (
          <Show when={!section.visibleMethod || section.visibleMethod(form.formStore)}>
            <EditFormItem
              {...section}
              disabled={typeof section.disabled === 'function' ? section.disabled(form.formStore) : section.disabled}
              style={typeof section.style === 'function' ? section.style(form.formStore) : section.style}
            />
          </Show>
        )}
      </For>
    </div>
  );
};

export const ExpandablePrincipalItem = (props: { principal: MagicDoor.Api.UpdateCompanyPrincipalDto; index: number }) => {
  const [expand, setExpand] = createSignal(true);
  const { t } = useLocalization();

  const form = useFormContext();

  const handleDelete = (e: MouseEvent) => {
    // eslint-disable-next-line solid/reactivity
    form.setFormStore('principals', (prev: MagicDoor.Api.UpdateCompanyPrincipalDto[]) => prev.toSpliced(props.index, 1));
    e.stopPropagation();
  };

  return (
    <div class="mx-4 flex flex-col gap-2">
      <div class="flex cursor-pointer justify-between rounded-lg border border-partingline p-4" onClick={() => setExpand(!expand())}>
        <span class="text-sm font-semibold">
          {t('Principal')} {props.index + 1}
        </span>
        <span class="flex items-center gap-4">
          <IconTrash size={18} onClick={handleDelete} />
          <IconCircleChevronDown class={cn('transition-all ease-in-out', expand() && 'rotate-180 text-primary')} size={20} />
        </span>
      </div>
      <div class={cn('grid transition-all duration-300 ease-in-out', expand() ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]')}>
        <div class="overflow-hidden">
          <PrincipalItem principal={props.principal} index={props.index} />
        </div>
      </div>
    </div>
  );
};
