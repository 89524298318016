import { useNavigate } from '@solidjs/router';
import { useForm } from '~/components/common/BetterForm/context';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { AddTenantForm } from '~/pages/leases/add-tenant/AddTenantForm';

export type FieldErrors = {
  [key in string]: string | undefined;
};

function AddNewTenant() {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { addTenant } = useTenants();

  const form = useForm();

  let tenantFormRef: any;

  const handleAddTenant = async () => {
    const { validateStatus, errorFields } = form.validateForm();
    if (!validateStatus) {
      const name = errorFields[0].name;
      if (name[0] !== 'firstName') {
        tenantFormRef.expandableToggleOpen(true);
      }
      throw new Error();
    }

    const tenant = await addTenant(form.formStore as MagicDoor.Api.TenantCreatedDto);
    navigate(`/users/tenants/${tenant.id}`, { replace: true });
    toast.success(t('{name} has been added successfully', { name: tenant.firstName }));
  };

  return (
    <MultiStepsPanel
      onClose={() => navigate('/users/tenants', { replace: true })}
      title={t('Add a new tenant')}
      disableNext={!form.isValidate}
      onSubmit={handleAddTenant}>
      <AddTenantForm ref={tenantFormRef} form={form} class="mx-auto my-9 w-10/12 max-w-[1200px]" />
    </MultiStepsPanel>
  );
}

export default AddNewTenant;
