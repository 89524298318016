import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseTemplateRepository } from '~/repositories/leaseTemplateRepository';
import { LeaseSettingsRepository } from '~/repositories/settings/leaseSettingsRepository';

export const [LeaseSettingsProvider, useLeaseSettings] = createMagicDoorContext('LeaseSettings', () => {
  const repo = new LeaseSettingsRepository();
  const leaseTemplateRepo = new LeaseTemplateRepository();

  const [isLoading, setIsLoading] = createSignal(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [allLeaseSettings, setAllLeaseSettings] = createSignal<MagicDoor.Api.LeaseRenewalSettingsDtoSettingsWithOverridesDto | undefined>();
  const [leaseTemplateOptions, setLeaseTemplateOptions] = createSignal<any[]>([]);

  const getAllLeaseSettings = async () => {
    try {
      setIsLoading(true);
      const json = await repo.getAllLeaseSettings();
      setAllLeaseSettings(json);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateLeaseSettings = async (payload: MagicDoor.Api.LeaseRenewalSettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updateLeaseSettings(payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const updateOverrideLeaseSettings = async (level: string, levelId: string, payload: MagicDoor.Api.LeaseRenewalSettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updateLeaseSettingsByLevel(level, levelId, payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const addOverrideSettings = async (level: string, levelId: string, payload: MagicDoor.Api.LeaseRenewalSettingsDto) => {
    try {
      setIsLoading(true);
      await repo.updateLeaseSettingsByLevel(level, levelId, payload);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const removeOverrideSettings = async (level: string, levelId: string) => {
    try {
      setIsLoading(true);
      await repo.deleteLeaseSettingsByLevel(level, levelId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };
  const getLeaseTemplateOptions = async () => {
    const categories = await leaseTemplateRepo.getLeaseTemplateCategories();
    const leaseTemplates = await leaseTemplateRepo.getLeaseTemplates();
    const newLeaseTemplates = leaseTemplates?.filter((item) => item.type === 'leaseRenewal');
    const groupedLeaseTemplates = categories
      ?.map((category) => ({
        ...category,
        templates: newLeaseTemplates?.filter((template) => template.leaseTemplateCategoryId === category.id),
      }))
      .filter((item) => item.templates?.length);

    const options: any[] = [];

    groupedLeaseTemplates.forEach((category) => {
      options.push({
        type: 'group',
        label: category.name,
      });

      category.templates.forEach((template) => {
        options.push({
          type: 'option',
          label: template.name,
          value: template.id,
        });
      });
    });

    setLeaseTemplateOptions(options);
  };

  return {
    error,
    isLoading,
    allLeaseSettings,
    getAllLeaseSettings,
    updateLeaseSettings,
    addOverrideSettings,
    updateOverrideLeaseSettings,
    removeOverrideSettings,
    leaseTemplateOptions,
    getLeaseTemplateOptions,
  };
});
