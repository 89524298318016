import { checkMimeType } from '@magicdoor/file';
import { createSignal } from 'solid-js';
import { toast } from '~/components/ui/Toaster';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { DragAndDropProps } from '~/components/common/Upload';

export const DragAndDrop: Component<DragAndDropProps> = (props) => {
  const [active, setActive] = createSignal<boolean>(false);
  let inputRef: HTMLInputElement | undefined;
  const { t } = useLocalization();

  const reset = () => {
    inputRef && (inputRef.value = '');
  };

  const dropFiles = (files?: FileList | null) => {
    if (files == null || files.length === 0) return;
    props.onDropFiles?.(filterFileList(files));
    reset();
  };

  const isFileSizeValid = (file: File) => {
    let flag = true;
    if (props.maxSize && file.size > props.maxSize * 1024 * 1024) {
      flag = false;
    }

    if (!flag) toast(t(`File size should be less than {maxSize}MB`, { maxSize: props.maxSize }));
    return flag;
  };

  const isFileMimeTypeValid = (file: File) => {
    let flag = true;
    if (props.accept && !checkMimeType(file, props.accept)) {
      flag = false;
    }
    if (!flag) toast(t('File type is not supported'));
    return flag;
  };

  function filterFileList(fileList: FileList) {
    const dataTransfer = new DataTransfer();

    for (let i = 0; i < fileList.length; i++) {
      if (isFileMimeTypeValid(fileList[i]) && isFileSizeValid(fileList[i])) {
        dataTransfer.items.add(fileList[i]);
      }
    }

    return dataTransfer.files;
  }

  const handleFileDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dropFiles(e.dataTransfer?.files);
    setActive(false);
  };

  let deep = 0;
  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter') {
      deep++;
      setActive(true);
    } else if (e.type === 'dragleave') {
      --deep === 0 && setActive(false);
    }
  };

  const handleSelectFiles = (e: Event & { currentTarget: HTMLInputElement }) => {
    e.preventDefault();
    dropFiles(e.currentTarget.files);
  };

  return (
    <label
      id={`${props.name}-dnd`}
      class={props.class}
      data-active={active() ? '' : undefined}
      onDrop={handleFileDrop}
      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragOver}>
      <input
        id={`${props.name}-input`}
        hidden
        type="file"
        accept={props.accept}
        multiple={props.multiple}
        onChange={handleSelectFiles}
        ref={inputRef}
      />
      {props.children}
    </label>
  );
};
