import { Match, Show, Switch, createMemo, createSignal } from 'solid-js';
import IconDocument from '~/assets/images/common/document.svg?component-solid';
import { RingLoader } from '~/components/common/Loaders';
import { IconDownload, IconX, Image, Modal } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { decodeFileName, downloadFile, getFileAllUrl, getFileSuffix, saveAsFile } from '~/utils/file';
import { useLockBodyScroll, useOutsideClick } from '~/utils/hooks';
import { FileTitle } from './FileTitle';
import { IconPreviewFailed } from './Icons';
import type { Component } from 'solid-js';

export const getPreviewURL = (file: PreviewFile, isThumbnail = false) => {
  if (file.fileUrl?.startsWith('blob') || file.fileUrl?.startsWith('http')) {
    return file.fileUrl;
  }
  const url = isThumbnail ? file.fileThumbUrl : file.fileUrl;
  return getFileAllUrl(url || file.fileUrl || (file as any)?.signedUrl);
};

const FilePreviewInner: Component<{
  file: PreviewFile;
  onClose: () => void;
}> = (props) => {
  const { t } = useLocalization();

  const [status, setStatus] = createSignal<'loading' | 'loaded' | 'error'>('loading');
  const type = createMemo(() => props.file.fileType || getFileSuffix(props.file.fileName));
  const fileUrl = createMemo(() => getPreviewURL(props.file));

  let container: HTMLElement | undefined;

  useOutsideClick(
    () => container,
    () => true,
    () => props.onClose()
  );

  useLockBodyScroll();

  const handleLoaded = () => setStatus('loaded');

  const handleError = () => setStatus('error');

  const handleDownload = () => {
    const _fileUrl = fileUrl();
    if (_fileUrl?.startsWith('http') || _fileUrl?.startsWith('blob')) {
      return saveAsFile(_fileUrl, props.file.fileName);
    }
    downloadFile(props.file.fileUrl || (props.file as any)?.signedUrl, props.file.fileName);
    props.onClose();
  };

  return (
    <Modal class="thinscroll relative flex size-full items-center justify-center overflow-auto md:max-w-2xl lg:max-w-5xl">
      <div class="fixed inset-x-0 top-0 flex items-center gap-4 bg-black/50 px-4 py-3 text-white md:px-8 md:py-5">
        <FileTitle class="text-base text-inherit" file={props.file} />
        <button
          class="ml-auto p-2 opacity-80 transition-opacity hover-allowed:hover:opacity-100"
          aria-label="Download"
          onClick={handleDownload}>
          <IconDownload class="size-6" />
        </button>
        <button
          class="p-2 opacity-80 transition-opacity hover-allowed:hover:opacity-100"
          aria-label="Close"
          onClick={() => props.onClose()}>
          <IconX class="size-6" />
        </button>
      </div>
      <Switch
        fallback={
          <div
            ref={container as HTMLDivElement}
            class="flex flex-col items-center justify-center gap-2 rounded-lg bg-white p-20 shadow-2xl"
            data-status={handleLoaded()}>
            <IconDocument class="mt-2" />
            <p class="font-medium text-title-gray">{decodeFileName(props.file.fileName)}</p>
            <p class="mb-4 mt-2 text-xs text-text-level02">{t('File type not supported, please download and view')}</p>
            <button
              class="flex items-center gap-2 rounded-full border border-current px-4 py-2 text-primary transition-colors hover-allowed:hover:bg-current-alpha"
              aria-label="Download"
              onClick={handleDownload}>
              <IconDownload class="size-4" />
              {t('Download')}
            </button>
          </div>
        }>
        <Match when={['png', 'jpg', 'jpeg', 'gif', 'webp', 'svg', 'bmp'].includes(type())}>
          <Image
            ref={container as HTMLImageElement}
            class={`size-full object-contain shadow-2xl${status() === 'error' ? 'invisible' : ''}`}
            src={fileUrl()}
            onLoad={handleLoaded}
            onError={handleError}
          />
        </Match>
        <Match when={['mp4', 'webm'].includes(type())}>
          <video
            ref={container as HTMLVideoElement}
            class={`w-full bg-black shadow-2xl${status() === 'error' ? 'invisible' : ''}`}
            src={fileUrl()}
            controls
            onLoadedMetadata={handleLoaded}
            onError={handleError}
          />
        </Match>
        <Match when={['pdf'].includes(type())}>
          <embed
            ref={container as HTMLEmbedElement}
            class={`size-full pt-12 shadow-2xl${status() === 'error' ? 'invisible' : ''}`}
            src={fileUrl()}
            type="application/pdf"
            onLoad={handleLoaded}
            onError={handleError}
          />
        </Match>
      </Switch>
      <Show when={status() !== 'loaded'}>
        <div class="absolute">
          <Show when={status() === 'error'} fallback={<RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />}>
            <div class="rounded-lg bg-white p-20 shadow-2xl">
              <IconPreviewFailed />
              <p class="mt-2 text-center text-text-level03">{t('Something went wrong')}</p>
            </div>
          </Show>
        </div>
      </Show>
    </Modal>
  );
};

export const FilePreviewModal: Component<{
  file?: PreviewFile;
  onClose: () => void;
}> = (props) => {
  return <Show when={props.file}>{(file) => <FilePreviewInner onClose={props.onClose} file={file()} />}</Show>;
};

export type PreviewFile = {
  fileId: string;
  fileName: string;
  fileUrl: string;
  fileThumbUrl: string;

  fileType?: string;
};
