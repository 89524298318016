import { useParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo, createSignal, Show } from 'solid-js';
import IconDetail from '~/assets/images/common/detail.svg?component-solid';
import IconMarkAsReturned from '~/assets/images/leases/transactions/markAsReturned.svg?component-solid';
import IconCharge from '~/assets/images/leases/transactions/newCharge.svg?component-solid';
import IconCredit from '~/assets/images/leases/transactions/newCredit.svg?component-solid';
import IconPayment from '~/assets/images/leases/transactions/newPayment.svg?component-solid';
import IconReturned from '~/assets/images/leases/transactions/returned.svg?component-solid';
import IconReturnedDollar from '~/assets/images/leases/transactions/returned_dollar.svg?component-solid';
import IconUpload from '~/assets/images/transaction/upload.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import CnMoney from '~/components/common/CnMoney';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import IconTag from '~/components/common/IconTag/index';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { getDescription } from '~/components/leases/utils';
import { Modal } from '~/components/modals/Modal';
import { IconPencilLine, Table, TableActions, toast } from '~/components/ui';
import { MobileListView } from '~/components/ui/ListView/MobileListView';
import { useLocalization } from '~/contexts/global';
import { useDebug, useLeaseRentTransactions } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { DeleteBillPaymentMethod } from '~/swagger/Api';
import { emptyPlaceholder, isDebug } from '~/utils/constant';
import { dateFormat, isLaterDate } from '~/utils/date';
import { currency } from '~/utils/number';
import type { JSX, Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';
import type { TFunction } from '~/contexts/global';

type LeaseDepositTransactionTableProps = {
  data: MagicDoor.Api.HydratedLeaseTransactionDto[];
  loading: boolean;
  actions: any;
  status: string;
  setStatus: (status: string) => void;
  onDetailClick: (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  onUpdateClick: (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  onDeleteClick?: (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  onUploadClick?: (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  onMarkAsReturnedClick?: (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  PanelActions: JSX.Element;
  tableTitle: string;
  // detailModal: JSX.Element;
  updateModal: JSX.Element;
  showModal?: string;
  setShowModal?: (value: string) => void;
  transactionData: MagicDoor.Api.HydratedLeaseTransactionDto | undefined;
  getPrintContainer?: (container: HTMLDivElement) => void;
  ref?: any;
};

interface LeaseTransactionStatusProps {
  type: `${MagicDoor.Api.RentTransactionType}`;
  status: `${MagicDoor.Api.LeaseTransactionStatus}`;
  t?: TFunction;
}

const LeaseTransactionStatus: Component<LeaseTransactionStatusProps> = (props) => {
  const icon = () => {
    const map: Record<MagicDoor.Api.RentTransactionType, Component> = {
      charge: IconCharge,
      leaseCredit: IconCredit,
      payment: IconPayment,
      return: IconReturnedDollar,
    };
    let Icon;
    if (props.status === 'returned') {
      Icon = IconReturned;
    } else {
      Icon = map[props.type];
    }
    return <Icon />;
  };

  const bgColor = () => {
    if (props.status === 'returned') {
      return '#FFE7F1';
    }
    switch (props.type) {
      case 'charge':
        return '#FCE7FF';
      case 'leaseCredit':
        return '#FFF9E8';
      case 'payment':
        return '#D4ECFF';
      case 'return':
        return '#FFE7F1';
    }
  };

  const textColor = () => {
    if (props.type === 'return') {
      return 'bg-gray-level01 text-light-gray-level01';
    }
    if (['future', 'paid', 'settled'].includes(props.status)) {
      return 'bg-green-level01 text-light-green';
    } else if (['due', 'failed', 'pastDue'].includes(props.status)) {
      return 'bg-danger-light text-light-danger';
    } else if (props.status === 'processing') {
      return 'bg-warning-light text-light-warning';
    } else if (props.status === 'returned') {
      return 'bg-gray-level01 text-light-gray-level01';
    }
  };

  const statusText = () => {
    const map: Record<MagicDoor.Api.LeaseTransactionStatus, string> = {
      future: 'Future',
      due: 'Due',
      pastDue: 'Past due',
      paid: 'Paid',
      failed: 'Failed',
      returned: 'Returned',
      processing: 'Processing',
      settled: 'Settled',
      disputing: 'Disputing',
    };
    if (props.type === 'return') {
      return 'Return';
    }
    return map[props.status];
  };

  const safeT = (key: string) => {
    if (typeof props.t === 'function') {
      return props.t(key);
    }
    console.warn('Localization function is not available');
    return key;
  };

  return <IconTag icon={icon()} iconBgColor={bgColor()} textClass={textColor()} text={safeT(statusText())} />;
};

export const LeaseDepositTransactionTable = (props: LeaseDepositTransactionTableProps) => {
  const { t } = useLocalization();
  const { triggerUpdate } = useUpdates();

  const { setPlaidStatusDebug } = useDebug();
  const { leaseId } = useParams();

  const { deleteTransaction, deleteBill } = useLeaseRentTransactions();
  const [deleteTransactionLoading, setDeleteTransactionLoading] = createSignal<boolean>(false);

  const handleDeleteTransaction = async () => {
    setDeleteTransactionLoading(true);
    const apis = {
      bill: deleteBill,
      transaction: deleteTransaction,
    };
    const type = props.transactionData?.bill ? 'bill' : 'transaction';
    try {
      const result = await apis[type](leaseId, props.transactionData?.id as string, {
        deleteMethod: DeleteBillPaymentMethod.AdjustTransaction,
      });
      if (result) {
        toast.success(t('The entry has been deleted successfully'));
      }
    } finally {
      setDeleteTransactionLoading(false);
      props.setShowModal && props.setShowModal('');
      triggerUpdate();
      await props.actions();
    }
  };

  const statusOptions = createMemo(() => [
    { label: t('All'), value: '' },
    { label: t('Future'), value: 'future' },
    { label: t('Due'), value: 'due' },
    { label: t('Past due'), value: 'pastDue' },
    { label: t('Paid'), value: 'paid' },
    { label: t('Failed'), value: 'failed' },
    { label: t('Returned'), value: 'returned' },
    { label: t('Processing'), value: 'processing' },
    { label: t('Settled'), value: 'settled' },
  ]);

  const mapTypeTransactions: Record<MagicDoor.Api.RentTransactionType, string> = {
    charge: t('Charge'),
    leaseCredit: t('Lease credit'),
    payment: t('Payment'),
    return: t('Return'),
  };

  const filtered = () => (props.status ? props.data.filter((item) => item.status === props.status) : props.data);

  const columns = createMemo(() => {
    const baseColumns: TableColumns<MagicDoor.Api.HydratedLeaseTransactionDto> = [
      {
        title: t('Date'),
        class: 'text-sm',
        render: (item) => dateFormat(t('MM/DD/YYYY'), item.transactionDate),
      },
      {
        title: t('Chart of account'),
        render: (item) => (
          <div>
            <div>{item?.bill?.lineItems ? item.bill.lineItems.map((item) => item.chartOfAccount.name) : emptyPlaceholder}</div>
            <Show when={item.bill?.companyCharge}>
              <div class="text-xs text-link">{t('Company bill')}</div>
            </Show>
          </div>
        ),
      },
      {
        title: t('Status'),
        class: 'w-48',
        render: (item) => (
          <Tooltip message={mapTypeTransactions[item.type]} align="bottom">
            <LeaseTransactionStatus status={item.status} type={item.type} t={t} />
            <Show when={item.status === 'pastDue'}>
              <div class="text-xs text-text-level03">{dayjs().diff(item.transactionDate, 'd')} days overdue</div>
            </Show>
          </Tooltip>
        ),
      },
      {
        title: `${t('Description')}/${t('Memo')}`,
        class: 'w-64',
        render: (item) => (
          <div>
            <div class="mb-1 w-64 cursor-pointer truncate font-medium normal-case">{getDescription(item.description)}</div>
          </div>
        ),
      },
      {
        title: t('Amount'),
        headerClass: 'text-right',
        class: 'text-sm text-right',
        render: (item) => <span>{currency(item.amount)}</span>,
      },
      {
        title: t('Balance'),
        headerClass: 'text-right',
        render: (item) => (
          <div class="w-full text-right">{isLaterDate(item.transactionDate) ? emptyPlaceholder : <CnMoney money={item.balance} />}</div>
        ),
      },
      {
        title: t('Posted balance'),
        headerClass: 'text-right',
        class: 'text-right',
        render: (item) => <span>{currency(item.postedBalance)}</span>,
      },
      {
        title: '',
        render: (row) => (
          <TableActions
            actions={[
              {
                label: t('Detail'),
                icon: IconDetail,
                onClick: () => props.onDetailClick(row),
              },
              {
                label: t('Update'),
                icon: IconPencilLine,
                onClick: () => props.onUpdateClick(row),
              },
              ...(props.onMarkAsReturnedClick && row.status === 'settled' && row.type === 'payment'
                ? [
                    {
                      label: t('Mark as returned'),
                      icon: IconMarkAsReturned,
                      onClick: () => props.onMarkAsReturnedClick!(row),
                    },
                  ]
                : []),
              ...(props.onUploadClick
                ? [
                    {
                      label: t('Upload'),
                      icon: IconUpload,
                      onClick: () => props.onUploadClick!(row),
                    },
                  ]
                : []),
              ...(props.onDeleteClick
                ? [
                    {
                      label: t('Delete'),
                      icon: IconDelete,
                      onClick: () => props.onDeleteClick!(row),
                    },
                  ]
                : []),
            ]}
          />
        ),
      },
    ];
    if (isDebug()) {
      baseColumns.push({
        title: 'DEV DEBUG',
        render: (row) => (
          <Show when={row.transaction?.externalTransactionType === 'plaid'}>
            <TableActions
              actions={[
                {
                  label: 'Settled',
                  onClick: async () => {
                    await setPlaidStatusDebug(row.transaction?.externalTransactionId || '', 'settled');
                    await props.actions();
                  },
                },
                {
                  label: 'Failed',
                  onClick: async () => {
                    await setPlaidStatusDebug(row.transaction?.externalTransactionId || '', 'failed');
                    await props.actions();
                  },
                },
                {
                  label: 'Returned',
                  onClick: async () => {
                    await setPlaidStatusDebug(row.transaction?.externalTransactionId || '', 'returned');
                    await props.actions();
                  },
                },
              ]}>
              {(() => (
                <button
                  class="group flex items-center justify-center gap-1.5 rounded-full border px-4 py-1 capitalize"
                  classList={{
                    'text-link border-link': !!open,
                    'bg-link/5 text-link border-link hover-allowed:hover:bg-link hover-allowed:hover:text-white': !open,
                  }}>
                  {t('Set plaid')}
                </button>
              ))()}
            </TableActions>
          </Show>
        ),
      });
    }

    return baseColumns;
  });

  const TableFilter = () => (
    <DropdownMenu
      options={statusOptions()}
      value={String(props.status)}
      onChange={(e) => props.setStatus(e)}
      renderLabel={(value) => (
        <span class="text-xs">
          <span class="text-opacity-80">{t('Status')}: </span>
          <span class="font-medium">{value}</span>
        </span>
      )}
    />
  );

  props.ref &&
    props.ref({
      getData() {
        return filtered();
      },
      getColumns() {
        if (import.meta.env.DEV) return columns().slice(0, -2);
        else return columns().slice(0, -1);
      },
    });

  return (
    <div>
      <div class="hidden md:block">
        <Panel class="mb-4 flex items-center gap-2 lg:hidden">{<div class="flex items-center gap-2 p-4">{props.PanelActions}</div>}</Panel>
        <Panel
          title={props.tableTitle}
          headerClass="overflow-auto [&>h3]:mr-2"
          actions={
            <div class="flex items-center gap-2">
              <div>{TableFilter()}</div>
              <div class="hidden items-center gap-2 lg:flex">{props.PanelActions}</div>
            </div>
          }>
          <div class="thinscroll overflow-x-auto" ref={(e) => props?.getPrintContainer?.(e)}>
            <Table columns={columns()} loading={props.loading} data={filtered()} skeletonSize={5} onRowClick={props.onDetailClick} />
          </div>
        </Panel>
      </div>
      <MobileListView
        class="overflow-hidden md:hidden"
        titleClass="w-full"
        renderItem={(item) => {
          return (
            <div class="p-3" onClick={() => props.onDetailClick?.(item as MagicDoor.Api.HydratedLeaseTransactionDto)}>
              <div class="mb-2 flex items-center">
                <div class="flex flex-1 flex-col">
                  <span>{currency(item.amount)}</span>
                  <span class="text-xs text-text-level01">{dateFormat(t('MM/DD/YYYY'), item.transactionDate)}</span>
                </div>
                <Tooltip message={mapTypeTransactions[item.type as 'payment']} align="bottom">
                  <LeaseTransactionStatus status={item.status} type={item.type} t={t} />
                </Tooltip>
                <TableActions
                  actions={[
                    {
                      label: t('Detail'),
                      icon: IconDetail,
                      onClick: () => props.onDetailClick(item as MagicDoor.Api.HydratedLeaseTransactionDto),
                    },
                    {
                      label: t('Update'),
                      icon: IconPencilLine,
                      onClick: () => props.onUpdateClick(item as MagicDoor.Api.HydratedLeaseTransactionDto),
                    },
                    ...(props.onMarkAsReturnedClick && item.status === 'settled' && item.type === 'payment'
                      ? [
                          {
                            label: t('Mark as returned'),
                            icon: IconMarkAsReturned,
                            onClick: () => props.onMarkAsReturnedClick!(item as MagicDoor.Api.HydratedLeaseTransactionDto),
                          },
                        ]
                      : []),
                    ...(props.onUploadClick
                      ? [
                          {
                            label: t('Upload'),
                            icon: IconUpload,
                            onClick: () => props.onUploadClick!(item as MagicDoor.Api.HydratedLeaseTransactionDto),
                          },
                        ]
                      : []),
                    ...(props.onDeleteClick
                      ? [
                          {
                            label: t('Delete'),
                            icon: IconDelete,
                            onClick: () => props.onDeleteClick!(item as MagicDoor.Api.HydratedLeaseTransactionDto),
                          },
                        ]
                      : []),
                  ]}
                />
              </div>
              <div class="flex items-center space-x-1 text-sm">
                <span class="text-text-level03">{t('Chart of account')}:</span>
                <div class="flex items-center text-text-level01">
                  <div>
                    {item?.bill?.lineItems
                      ? item.bill.lineItems.map((lineItem: any) => (lineItem as { chartOfAccount: { name: string } }).chartOfAccount.name)
                      : emptyPlaceholder}
                  </div>
                  <Show when={item.bill?.companyCharge}>
                    <div class="text-xs text-link">({t('Company bill')})</div>
                  </Show>
                </div>
              </div>
              <div class="flex items-center space-x-1 text-sm">
                <span class="text-text-level03">
                  {t('Description')}/{t('Memo')}:
                </span>
                <div class="w-64 cursor-pointer truncate font-medium normal-case">{getDescription(item.description)}</div>
              </div>
              <div class="flex items-center space-x-1 text-sm">
                <span class="text-text-level03">{t('Balance')}:</span>
                <span>{isLaterDate(item.transactionDate) ? emptyPlaceholder : <CnMoney money={item.balance} />}</span>
              </div>
              <div class="flex items-center space-x-1 text-sm">
                <span class="text-text-level03">{t('Posted balance')}:</span>
                <span>{currency(item.postedBalance)}</span>
              </div>
            </div>
          );
        }}
        title={
          <div class="mb-2 flex w-full flex-col gap-1">
            <span class="text-lg">{props.tableTitle}</span>
            <div class="flex items-center gap-2">
              <div>{TableFilter()}</div>
              <div class="flex-1">{props.PanelActions}</div>
            </div>
          </div>
        }
        data={filtered()}
        loading={props.loading}
        footerClass="hidden"
      />
      {/* {props.detailModal} */}
      {props.updateModal}
      <Modal
        size="sm"
        onDone={handleDeleteTransaction}
        visible={props.showModal === 'deleteTransaction'}
        title={t('Delete entry')}
        doneText={t('Confirm')}
        loading={deleteTransactionLoading()}
        onCancel={() => props.setShowModal && props.setShowModal('')}
        confirmation={false}>
        <div>
          <div class="my-2 px-6">{t('Are you sure you would like to delete this entry')}?</div>
        </div>
      </Modal>
    </div>
  );
};
