import { HttpError } from '@magicdoor/errors';
import { useNavigate } from '@solidjs/router';
import { loadStripe } from '@stripe/stripe-js';
import { createMemo, createSignal } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { Empty } from '~/components/common/Empty';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { restCodeMap } from '~/errors/errorCodeMap';
import { RentalApplicationRepository, rentalApplicationRepo } from '~/repositories/rentalApplicationRepository';
import { rentalApplicationPaymentSettingRepository } from '~/repositories/settings/rentalApplicationPaymentSettingsRepository';
import { ApplicationPaymentStatus, ScreeningStatus } from '~/swagger/Api';
import { rentalApplicationPaymentStatus, emptyPlaceholder, NEW_RENTAL_APPLICATION_REVIEW_STEP } from '~/utils/constant';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import { getStripeKey } from '~/utils/stripeKey';
import { cloneDeep } from '~/utils/tool';
import type { HttpErrorJson } from '@magicdoor/errors';
import type { StripeElements } from '@stripe/stripe-js';
import type { BetterForm } from '~/components/common/BetterForm';

const applicationRepo = new RentalApplicationRepository();

const initStep = 0;

export const [NewRentalApplicationProvider, useNewRentalApplication] = createMagicDoorContext('NewRentalApplication', () => {
  const navigate = useNavigate();

  const [store, setStore] = createStore<{
    isAnsweringQuestions: boolean;
    paymentStatus: Partial<MagicDoor.Api.RentalApplicationPaymentStatusDto>;
    transUnionScreeningStatus: Partial<MagicDoor.Api.CheckScreeningStatusResultDto>;
    transUnionScreeningQuestions: Partial<MagicDoor.Api.TransunionExamQuestionsDto>;
    selectedAnswers: Record<string, string>;
  }>({
    paymentStatus: {},
    transUnionScreeningStatus: {},
    transUnionScreeningQuestions: {},
    selectedAnswers: {},
    isAnsweringQuestions: false,
  });

  const { t } = useLocalization();
  const [selectedApplyWith, setSelectedApplyWith] = createSignal<MagicDoor.Api.HydratedTenantDto[]>([]);
  const [currentTenant, setCurrentTenant] = createSignal<Partial<MagicDoor.Api.HydratedTenantDto>>({});
  const [currentStep, setCurrentStep] = createSignal<number>(initStep);
  const [disableNext, setDisableNext] = createSignal(false);
  const [settings, setSettings] = createSignal<MagicDoor.Api.RentalApplicationsSettingsDto>();

  const steps = createMemo(() => [
    { key: 'tenant-profile', label: t('Tenant profile') },
    { key: 'rental-history', label: t('Rental history') },
    { key: 'employment', label: t('Employment') },
    { key: 'identity', label: t('Identity') },
    { key: 'questions-and-terms', label: t('Questions & terms') },
  ]);

  const [rentalApplication, setRentalApplication] = createSignal<MagicDoor.Api.RentalApplicationDto>();

  const getSettings = async () => {
    const res = await applicationRepo.getApplicationSettings();
    setSettings(res);
  };

  const gotoStatusPage = (status: rentalApplicationPaymentStatus) => {
    navigate(`/leasing/rental-applications/new/${rentalApplication()?.id}/status/${status}`, { resolve: false });
  };

  const [paymentIntent, getPaymentIntent] = createTriggerResource((applicationId: string) => applicationRepo.onlinePayment(applicationId));
  const [stripe, getStripe] = createTriggerResource((stripeAccountId: string) =>
    loadStripe(getStripeKey(), { stripeAccount: stripeAccountId })
  );

  const [applicationPaymentSettings] = createLazyResource(() =>
    rentalApplicationPaymentSettingRepository.getRentalApplicationPaymentSettings()
  );

  const getPaymentStatus = async () => {
    const currentId = validateRentalApplicationID();
    const status = await applicationRepo.getRentalApplicationPaymentStatus(currentId);

    setStore({ paymentStatus: status });

    return status;
  };

  const handlePayment = createMutation(async (elements: StripeElements | null) => {
    const stripeInstance = stripe();
    if (!elements || !stripeInstance) return;
    const { paid } = await getPaymentStatus();
    if (paid) return autoNavigateByStatus(['paymentStatus']);
    const result = await stripeInstance.confirmPayment({
      elements: elements,
      redirect: 'if_required',
    });

    if (!result.error) {
      gotoStatusPage(rentalApplicationPaymentStatus.processing);
    } else {
      toast(result.error.message, 'error');
    }
  });

  const handleScreening = createMutation(async () => {
    if (!rentalApplication()?.id) return;
    try {
      await applicationRepo.startScreening(rentalApplication()?.id as string);
      gotoStatusPage(rentalApplicationPaymentStatus.questionnaire);
    } catch (error: any) {
      if (error instanceof HttpError) {
        const errorJson = error.getJson<HttpErrorJson>();

        if (errorJson?.type === restCodeMap.CompanyBusinessInfoNotFoundException) {
          confirm({
            title: t('Information required'),
            content: <Empty description={t('Please submit your business information to continue')} />,
            doneText: t('Go to settings'),
            onResolve: async (value) => {
              if (value) {
                window.open(`/settings/company/business`, '_blank');
              }
            },
          });
        }
      }
    }
  });

  const getScreeningStatus = createMutation(async () => {
    const currentId = validateRentalApplicationID();
    const status = await applicationRepo.getScreeningStatus(currentId);
    setStore(produce((draft) => (draft.transUnionScreeningStatus = status)));
  });

  const getScreeningQuestions = createMutation(async () => {
    const currentId = validateRentalApplicationID();
    const questions = await applicationRepo.getScreeningQuestions(currentId);
    if (questions.status === ScreeningStatus.ManualVerificationRequired) {
      return gotoStatusPage(rentalApplicationPaymentStatus.confirmIdentity);
    }
    setStore(produce((draft) => (draft.transUnionScreeningQuestions = questions)));
  });

  const handleSubmitAnswers = async () => {
    if (!rentalApplication()?.id) return;

    try {
      setStore('isAnsweringQuestions', true);
      await applicationRepo.postScreeningAnswers(rentalApplication()?.id as string, {
        examId: store.transUnionScreeningQuestions?.examId ?? 0,
        answers: Object.entries(store.selectedAnswers).map(([questionKeyName, selectedChoiceKeyName]) => ({
          questionKeyName,
          selectedChoiceKeyName,
        })),
      });
      autoNavigateByStatus();
    } catch {
      getScreeningQuestions();
    } finally {
      setStore('isAnsweringQuestions', false);
    }
  };

  const updateAnswer = (questionKey: string, choiceKey?: string) => {
    if (questionKey && choiceKey) {
      setStore(
        produce((draft) => {
          draft.selectedAnswers[questionKey] = choiceKey;
        })
      );
    }
  };

  const skipPayment = createMutation(async () => {
    if (!rentalApplication()?.id) return;
    await applicationRepo.skipPayment(rentalApplication()?.id as string);
    autoNavigateByStatus();
  });

  const validateRentalApplicationID = () => {
    const appId = rentalApplication()?.id;
    if (!appId) throw new Error('Application ID is required');
    return appId;
  };

  async function onSubmit() {
    const appId = validateRentalApplicationID();
    setRentalApplication(await applicationRepo.lockRentalApplication(appId));
    autoNavigateByStatus();
  }

  const autoNavigateByStatus = createMutation(async function (cacheFields?: Array<'paymentStatus' | 'transUnionScreeningStatus'>) {
    if (rentalApplication()?.draft) return;
    const promises: any[] = [];
    if (!cacheFields?.includes('paymentStatus') && settings()?.requirePayment) {
      promises.push(getPaymentStatus());
    }

    if (!cacheFields?.includes('transUnionScreeningStatus') && settings()?.requireScreening) {
      promises.push(getScreeningStatus());
    }

    await Promise.allSettled(promises);

    if (isPaid()) {
      if (!settings()?.requireScreening || store.transUnionScreeningStatus.status === ScreeningStatus.ManualVerificationRequired) {
        return gotoStatusPage(rentalApplicationPaymentStatus.confirmIdentity);
      }

      if (store.transUnionScreeningStatus.status === ScreeningStatus.Verified) {
        return gotoStatusPage(rentalApplicationPaymentStatus.submitted);
      }

      if (store.transUnionScreeningStatus.status === ScreeningStatus.UnVerified) {
        return gotoStatusPage(rentalApplicationPaymentStatus.questionnaire);
      }

      if (settings()?.requireScreening) {
        return gotoStatusPage(rentalApplicationPaymentStatus.readyToConnect);
      }

      return gotoStatusPage(rentalApplicationPaymentStatus.submitted);
    }

    if (settings()?.requirePayment) {
      if (rentalApplication()?.paymentStatus === ApplicationPaymentStatus.Processing) {
        return navigate(`/leasing/rental-applications/new/${rentalApplication()?.id}/payment`);
      }
      return navigate(`/leasing/rental-applications/new/${rentalApplication()?.id}/select-payment`);
    }

    return gotoStatusPage(rentalApplicationPaymentStatus.submitted);
  });

  function getValueFromStore(form: BetterForm.form) {
    const baseData = cloneDeep(form.formStore);
    if (currentTenant().id === baseData.tenantId && currentTenant().id) {
      baseData.lastName = currentTenant().lastName || emptyPlaceholder;
      baseData.firstName = currentTenant().firstName || emptyPlaceholder;
    }
    return baseData;
  }

  async function onCreate(formValues: BetterForm.FormStore) {
    const res = await rentalApplicationRepo.createApplication(formValues);
    setRentalApplication(res);
  }

  async function onEdit(formValues: BetterForm.FormStore) {
    const currentId = rentalApplication()?.id;
    if (!currentId) throw new Error('Application ID is required');
    await rentalApplicationRepo.updateApplication(currentId, formValues);
  }

  const onStepChange = createMutation(async function (step: number, form: BetterForm.form) {
    if (step > currentStep()) {
      const { validateStatus } = form.validateForm();
      if (!validateStatus) return;
    }

    if (step > currentStep() || step === currentStep()) {
      const formValues = getValueFromStore(form);
      rentalApplication()?.id ? await onEdit(formValues) : await onCreate(formValues);
    }

    const currentId = validateRentalApplicationID();

    if (step === NEW_RENTAL_APPLICATION_REVIEW_STEP) {
      return onSubmit();
    }

    navigate(`/leasing/rental-applications/new/${currentId}/${steps()[step].key}`);
  });

  const resetState = () => {
    setSelectedApplyWith([]);
    setCurrentTenant({});
    setCurrentStep(initStep);
    setRentalApplication(undefined);
    setStore({
      selectedAnswers: {},
    });
  };

  const initState = async (applicationId?: string) => {
    await getSettings();
    if (applicationId) {
      const application = await applicationRepo.getRentalApplication(applicationId);
      setRentalApplication(application);
      autoNavigateByStatus();
    }
  };

  const isPaid = createMemo(() => store.paymentStatus.paid || !settings()?.requirePayment);

  return {
    store,
    setStore,
    handlePayment,
    getPaymentStatus,
    handleScreening,
    handleSubmitAnswers,
    updateAnswer,
    skipPayment,
    rentalApplication,
    initState,
    settings,
    applicationPaymentSettings,
    getScreeningQuestions,
    autoNavigateByStatus,
    paymentIntent,
    getPaymentIntent,
    stripe,
    getStripe,
    onStepChange,
    selectedApplyWith,
    setSelectedApplyWith,
    setCurrentTenant,
    steps,
    currentStep,
    setCurrentStep,
    disableNext,
    setDisableNext,
    resetState,
    getScreeningStatus,
    isPaid,
  };
});
