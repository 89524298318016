import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconAiGradientBg from '~/assets/images/chat/aiMagicItGradientBg.png';
import { cn } from '~/utils/classnames';

type AiButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  class?: string;
  id?: string;
};
export const AiButton = (props: AiButtonProps) => {
  return (
    <div class={cn('w-fit overflow-hidden rounded-lg bg-gradient-to-tl', props.class)}>
      <button
        id={props.id}
        onClick={() => props.onClick()}
        disabled={props.disabled}
        type="button"
        class="flex h-10 w-fit items-center justify-center gap-2 bg-contain bg-left bg-no-repeat px-4 text-white"
        style={{
          'background-image': `url(${IconAiGradientBg})`,
          'background-position': 'left, right',
          'background-repeat': 'no-repeat, no-repeat',
        }}>
        <img src={IconAiBot} class="hide-on-mobile" />
        <span class="flex whitespace-nowrap text-base font-medium">{props.text}</span>
      </button>
    </div>
  );
};
