import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconSort from '~/assets/images/common/sort.svg?component-solid';
import IconSwitch from '~/assets/images/common/switch.svg?component-solid';
import IconDeleteWarning from '~/assets/images/confirm-modal/delete.svg?component-solid';
import IconEdit from '~/assets/images/maintenance/edit.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Radio } from '~/components/common/Inputs/Radio';
import { MaintenanceRequestTitle, MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { formatTenantNames } from '~/components/tenants/utils';
import { Html, ListView, toast, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { useMaintenanceRequestFilter } from '~/hooks';
import { getStatusColumn } from '~/pages/maintenance-request/list-maintenance-request/list-components/StatusColumn';
import { emptyPlaceholder, getMaintenanceStatusOptions } from '~/utils/constant';
import { formatAgo, dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';
// eslint-disable-next-line no-restricted-imports
import type { CrossMaintenanceRequestListDto } from '~/contexts/maintenance-request/MaintenanceRequestsContext';
import type { FilterKey } from '~/pages/maintenance-request/list-maintenance-request/list-components/statusFilters';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

export const MaintenanceRequestListView: Component<{
  title: string;
  filter?: MaintenanceRequestFilter;
  filterStatus?: FilterKey;
}> = (props) => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const {
    maintenanceRequests,
    categories,
    getMaintenanceRequests,
    updateMaintenanceRequestUrgency,
    updateMaintenanceRequestStatus,
    deleteMaintenanceRequest,
  } = useMaintenanceRequests();
  const { filterValue, filterItems, parseFilter, setFilterValue, handleFilterReset } = useMaintenanceRequestFilter();

  const maintenanceUrgencyOptions = createMemo(() => {
    const urgencies: `${MagicDoor.Api.Urgency}`[] = ['none', 'low', 'medium', 'high', 'urgent'];
    return urgencies.map((urgency) => ({ label: <MaintenanceRequestUrgency urgency={urgency} />, value: urgency }));
  });

  const handleStatusChange = async (row: CrossMaintenanceRequestListDto, value: MagicDoor.Api.MaintenanceRequestStatus) => {
    if (!row.id) return;
    const run = async () => {
      await updateMaintenanceRequestStatus(row.id, value);
      toast.success(t('Modify status success'));
    };
    if (row.status === 'aiProcessing' && value !== 'aiProcessing') {
      confirm({
        title: t('Modify Status'),
        content: t('After the status changes, the AI runbook will automatically close.'),
        async onResolve(confirmed) {
          if (!confirmed) return;
          run();
        },
      });
    } else {
      run();
    }
  };

  const getTenants = (tenantId: string) => {
    return maintenanceRequests()?.tenants.find((item) => item.id === tenantId);
  };

  const handleUrgencyChange = async (maintenanceId: string | undefined, value: MagicDoor.Api.Urgency) => {
    if (!maintenanceId) return;

    try {
      await updateMaintenanceRequestUrgency(maintenanceId, value);
      toast.success(t('Modify priority success'));
    } catch {
      toast.error(t('Modify priority failed, please try again later'));
    }
  };

  createEffect(() => {
    const page = Number(localStorage.getItem(`MaintenanceRequestListView${props.title}LocalPage`)) || 1;
    const pageSize = Number(localStorage.getItem(`MaintenanceRequestListView${props.title}LocalPageSize`)) || 50;

    getMaintenanceRequests({ page, pageSize, ...props.filter });
  });

  function onDelete(row: MagicDoor.Api.MaintenanceRequestListDto) {
    confirm({
      title: t(`Delete maintenance request`),
      content: (
        <>
          <IconDeleteWarning class="-mx-5 block bg-light-pink" />
          <Html as="p" class="mx-auto w-80 text-text-level03 [&>b]:font-bold [&>b]:text-primary">
            {t('Confirm to delete “<b>{title}</b>” permanently, or “Cancel” if you want to go back. This action can not be undo.', {
              title: row.title,
            })}
          </Html>
        </>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteMaintenanceRequest(row.id);
        toast.success(t('{name} has been deleted successfully', { name: row.title }));
      },
    });
  }

  const columns = createMemo<TableColumns<CrossMaintenanceRequestListDto>>(() => {
    const col: TableColumns<CrossMaintenanceRequestListDto> = [
      {
        title: t('Priority'),
        render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
      },
      {
        title: t('Category'),
        render: (row) => (
          <Show when={row.categoryId} fallback={<div class="text-gray-400">{emptyPlaceholder}</div>}>
            <div class="text-xs capitalize text-text-level01">{categories()?.find((item) => item.id === row.categoryId)?.name || ''}</div>
          </Show>
        ),
      },
      {
        title: t('Title'),
        render: (row) => <MaintenanceRequestTitle maintenanceRequest={row} />,
      },
      {
        title: t('Unit'),
        render: (row) => {
          const tenants = getTenants(row.tenantId);
          return (
            <div class="w-36">
              <div class="text-xs text-text-level01">{row.unit?.name}</div>
              <DisplayAddress class="text-[12px] text-text-level03" address={row.property?.address} />
              <span class="text-xs text-text-level03">
                {t('Tenants')}: {formatTenantNames([{ tenant: tenants } as any])}
              </span>
            </div>
          );
        },
      },
      {
        title: t('Assigned to'),
        render: (row) => (
          <Show when={row?.propertyManager} fallback={<div class="text-gray-400">{emptyPlaceholder}</div>}>
            <div class="flex">
              <Avatar name={row?.propertyManager?.name || ''} />
              <div class="ml-2">
                <div class="text-nowrap text-xs text-text-level01"> {row?.propertyManager?.name}</div>
                <div class="text-xxs text-text-level03">{t('Manager')}</div>
              </div>
            </div>
          </Show>
        ),
      },
      getStatusColumn(props.filterStatus)!,
      {
        title: t('AI score'),
        render: (row) => <AIScoreCell score={row.aiUrgencyScore} />,
      },
      {
        title: t('Last updated'),
        render: (row) => (
          <div>
            <div class="text-xs font-medium text-text-level01">{dateFormat(t('MM/DD/YYYY hh:mm A'), row.lastUpdated)}</div>
            <div class="text-xxs text-[#68738D]">{formatAgo(row.lastUpdated, 2)}</div>
          </div>
        ),
      },
    ];

    return col.filter(Boolean);
  });

  return (
    <ListView
      onChange={({ page, pageSize, filter, search }) => {
        localStorage.setItem(`MaintenanceRequestListView${props.title}LocalPage`, `${page}`);
        localStorage.setItem(`MaintenanceRequestListView${props.title}LocalPageSize`, `${pageSize}`);
        setFilterValue(filter);
        getMaintenanceRequests({ ...props.filter, page, pageSize, searchText: search, ...parseFilter(filter) });
      }}
      title={props.title}
      searchPlaceholder={t('Search')}
      titleActions={
        <LinkButton class="ml-auto text-sm capitalize" href="/maintenance/maintenance-request/add" noScroll>
          <IconPlus class="text-white" />
          {t('Add maintenance request')}
        </LinkButton>
      }
      totalPages={maintenanceRequests()?.totalPages}
      page={maintenanceRequests()?.currentPage}
      pageSize={maintenanceRequests()?.pageSize}
      filterValue={filterValue()}
      filterItems={filterItems()}
      onFilterReset={handleFilterReset}
      rowLink={(item) => `/maintenance/maintenance-request/${item.id}`}
      skeletonSize={10}
      loading={maintenanceRequests.loading}
      data={maintenanceRequests()?.items}
      columns={columns()}
      actions={(row) => [
        {
          label: t('Modify Priority'),
          icon: IconSort,
          children: (
            <Radio
              direction="column"
              items={maintenanceUrgencyOptions()}
              value={row?.urgency}
              onInput={(value) => handleUrgencyChange(row?.id, value as MagicDoor.Api.Urgency)}
            />
          ),
        },
        {
          label: t('Modify Status'),
          icon: IconSwitch,
          children: (
            <Radio
              direction="column"
              class="w-28"
              items={getMaintenanceStatusOptions(row?.status).map((item) => ({ ...item, label: t(item.label) }))}
              value={row?.status}
              onInput={(value) => handleStatusChange(row, value as MagicDoor.Api.MaintenanceRequestStatus)}
            />
          ),
        },
        {
          label: t('Edit'),
          icon: IconEdit,
          onClick: () => {
            navigate(`${row.id}/edit`);
          },
        },
        {
          label: t('Delete'),
          icon: IconTrash,
          onClick: () => onDelete(row),
        },
      ]}
    />
  );
};
