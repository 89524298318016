import { createSignal, Show } from 'solid-js';
import IconFile from '~/assets/images/chat/chatFile.png';
import { useLocalization } from '~/contexts/global';
import { imageType } from '~/utils/constant';
import { decodeFileName, getFileAllUrl } from '~/utils/file';
import { FilePreviewModal } from './FilePreviewModal';
import type { Component } from 'solid-js';

export const FileThumbnail: Component<{ file: MagicDoor.Api.FileDto }> = (props) => {
  const { t } = useLocalization();
  const [showPreview, setShowPreview] = createSignal<boolean>(false);
  return (
    <>
      <Show
        when={!imageType.some((type) => props.file.fileName?.endsWith(type))}
        fallback={
          <img
            class="aspect-square rounded-lg object-cover transition hover-allowed:hover:cursor-pointer hover-allowed:hover:brightness-90"
            src={getFileAllUrl(props.file.signedUrl)}
            alt={props.file.fileName}
            onClick={() => setShowPreview(true)}
          />
        }>
        <div
          class="flex aspect-square flex-col items-center justify-center rounded-lg bg-slate-300 p-2 transition hover-allowed:hover:cursor-pointer hover-allowed:hover:brightness-90"
          onClick={() => setShowPreview(true)}>
          <div class="relative py-2">
            <img src={IconFile} alt="IconFile" />
            <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-bold text-white">{t('File')}</div>
          </div>
          <span class="w-full truncate text-center text-xs text-text-level02">{decodeFileName(props.file.fileName)}</span>
        </div>
      </Show>
      <Show when={showPreview()}>
        <FilePreviewModal file={{ ...props.file, fileUrl: props.file.signedUrl } as any} onClose={() => setShowPreview(false)} />
      </Show>
    </>
  );
};
