import { Show, For, createEffect, createSignal, untrack } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import { debounce } from '~/utils/tool';
import type { BarData, Segment } from './UpDownBarGraph';

interface BarProps {
  barData: BarData;
  hoveredBar: BarData | null;
  selectedMonth: BarData | null;
  capHeight: number;
  onBarHover: (bar: BarData) => void;
  onBarLeave: () => void;
  onBarClick: (bar: BarData) => void;
  maxGroupSegmentValue: number;
}
export const Bar = (props: BarProps) => {
  const [tallestSegmentHeight, setTallestSegmentHeight] = createSignal<number>(0);
  const [isSelected, setIsSelected] = createSignal<boolean>(false);

  const { t } = useLocalization();

  const getSegmentHeightPercentage = (value: number) => {
    const maxGroupValue = props.maxGroupSegmentValue;
    if (maxGroupValue === 0) {
      return 1;
    }
    const heightValue = (value / maxGroupValue) * 100;
    return heightValue < 1 ? 1 : Math.round(heightValue);
  };

  const getColorClass = (color: string) => {
    if (color === 'warning') return 'bg-warning-hover';
    if (color === 'green') return 'bg-green';
    return 'bg-defaultColor';
  };

  const computeTallestHeight = (segments: Segment[], capHeight: number): number => {
    return segments.reduce((maxHeight, segment) => {
      const heightValue = Math.min(segment.value / capHeight, 1 / segments.length) * 100;
      const segmentHeight = heightValue < 1 ? 1 : Math.round(heightValue);
      return segmentHeight > maxHeight ? segmentHeight : maxHeight;
    }, 0);
  };

  createEffect(() => {
    const segments = props.barData.segments;

    const newTallestHeight = computeTallestHeight(segments, props.capHeight);

    setTallestSegmentHeight(newTallestHeight + 70);
  });

  createEffect(() => {
    setIsSelected(props.selectedMonth?.axisLabel === props.barData.axisLabel);
  });

  const handleMouseEnter = debounce(() => {
    const barData = untrack(() => props.barData);
    untrack(() => props.onBarHover(barData));
  }, 100);

  const handleMouseLeave = debounce(() => {
    untrack(() => props.onBarLeave());
  }, 100);

  return (
    <div class="relative flex w-full flex-row items-end gap-2">
      <div class="flex size-full h-[120px] flex-col justify-end rounded-md text-text-level03">
        <For each={props.barData.segments}>
          {(segment, index) => {
            const heightPercentage = getSegmentHeightPercentage(segment.value);
            const colorClass = getColorClass(segment.color);

            return (
              <div
                onClick={() => {
                  props.onBarClick(props.barData);
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ height: `${heightPercentage}%` }}
                class={`shrink-0 ${colorClass} cursor-pointer`}
                classList={{
                  'rounded-t-md': index() === 0,
                  'rounded-b-md': index() === props.barData.segments.length - 1,
                  'opacity-50': !isSelected(),
                }}
              />
            );
          }}
        </For>

        <span class="mt-3 text-center" classList={{ 'text-green': isSelected() }}>
          {t(props.barData.axisLabel)}
        </span>
      </div>

      <Show when={props.hoveredBar && props.hoveredBar.axisLabel === props.barData.axisLabel}>
        <div
          style={{ bottom: `${tallestSegmentHeight()}px` }}
          class="absolute left-6 z-10 w-24 rounded-lg border bg-white p-2 text-left text-xxs">
          <For each={props.hoveredBar?.segments}>
            {(segment) => (
              <div class="flex items-center gap-2">
                <div class={cn('size-2 rounded-full', getColorClass(segment.color))} />
                <div>
                  <span class="text-text-level03">{segment.label}</span>: {currency(segment.value)}
                </div>
              </div>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};
