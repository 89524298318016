export enum AnnotationType {
  NAME = 'fullName',
  TEXT = 'text',
  INITIALS = 'initials',
  SIGNATURE = 'signature',
  DATE = 'dateTime',
  AUTOFILL = 'autofill',
  CHECKMARK = 'flag',
}

export interface Annotation {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: AnnotationType;
  text: string;
  signerId: string | undefined;
  dataPathKey?: string;
  fontSize: number;
  isInputField: boolean;
}

export const inputAnnotations = new Set<AnnotationType>([AnnotationType.DATE, AnnotationType.INITIALS, AnnotationType.SIGNATURE]);
