import { createMagicDoorContext } from '~/contexts/utils';
import { CompaniesRepository } from '~/repositories/companiesRepository';
import { createLazyResource, createMutation } from '~/utils/resource';

const repo = new CompaniesRepository();

export const [CompaniesProvider, useCompanies] = createMagicDoorContext('Companies', () => {
  const [companies, { refetch, mutate }] = createLazyResource(() => repo.getCompanies());
  const [portalSettings, { mutate: psMutate }] = createLazyResource(() => repo.getPortalSettings());

  const updateSubUrl = createMutation(async (model: MagicDoor.Api.CompanySubUrlDto | MagicDoor.Api.CompanyDomainDto) => {
    const updated = await repo.updateSubUrl(model);
    mutate(updated);
    return updated;
  });

  const updateCustomUrl = createMutation(async (model: MagicDoor.Api.CompanyDomainDto | MagicDoor.Api.CompanySubUrlDto) => {
    const updated = await repo.updateCustomUrl(model);
    mutate(updated);
    return updated;
  });

  const validateSubUrl = createMutation(async (model: MagicDoor.Api.CompanySubUrlDto) => {
    return await repo.validateSubUrl(model);
  });

  const validateCustomUrl = createMutation(async (model: MagicDoor.Api.CompanyDomainDto) => {
    return await repo.validateCustomUrl(model);
  });

  const updateCompanyProfile = createMutation(async (model: MagicDoor.Api.UpdateCompanyDto) => {
    const updated = await repo.updateCompanyProfile(model);
    refetch();
    return updated;
  });

  const uploadFiles = createMutation(async (file: File | Blob, name: string, process: boolean = true) => {
    return await repo.uploadFiles(file, name, process);
  });

  const updatePortalSettings = createMutation(async (model: MagicDoor.Api.UpdatePortalSettingsDto) => {
    const updated = await repo.updatePortalSettings(model);
    psMutate(updated);
  });

  const getWebsiteUrl = (pathName: string) => {
    const _publicUrl = companies()?.publicUrl;

    if (!_publicUrl) {
      return '';
    }

    return `${_publicUrl}${pathName}`;
  };

  return {
    companies,
    refetch,
    updateSubUrl,
    updateCustomUrl,
    validateSubUrl,
    validateCustomUrl,
    updateCompanyProfile,
    portalSettings,
    uploadFiles,
    updatePortalSettings,
    getWebsiteUrl,
  };
});
