import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, For, on } from 'solid-js';
import { produce } from 'solid-js/store';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { Modal } from '~/components/modals/Modal';
import { OwnerSearch } from '~/components/owner/OwnerSearch';
import { getOwnerName } from '~/components/owner/utils';
import { PropertyTitle } from '~/components/properties';
import { toast } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { cloneDeep, isUndefined } from '~/utils/tool';
import type { Component } from 'solid-js';

export const AddAndEditOwnerModal: Component = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { current: currentProperty, updatePropertyOwner, removePropertyOwner, addPropertyOwner } = useProperties();
  const param = useParams<{ ownerId: string }>();
  const [submitLoading, setSubmitLoading] = createSignal<boolean>(false);
  const [owners, setOwners] = createSignal<MagicDoor.Api.HydratedPropertyOwnerDto[]>([], { equals: false });

  const isEdit = createMemo(() => !!param.ownerId);

  const ratioTotal = createMemo(() => owners().reduce((previous, current) => previous + (current.ownershipPercentage || 0), 0) * 100);
  const disabledSubmit = createMemo(() => {
    const isSomeOwnershipPercentageIsUndefined = owners().some((item) => isUndefined(item.ownershipPercentage));
    if (isSomeOwnershipPercentageIsUndefined) {
      return true;
    }

    const isSomeOwnershipPercentageIsNegative = owners().some(
      (item) => item.ownershipPercentage !== undefined && item.ownershipPercentage < 0
    );
    if (isSomeOwnershipPercentageIsNegative) {
      return true;
    }
    return false;
  });
  const updateOwner = (index: number, value?: number) => {
    setOwners(
      produce((state) => {
        state[index].ownershipPercentage = value ?? 0;
      })
    );
  };

  const onSelectOwner = (selected: MagicDoor.Api.OwnerDto) => {
    const result = owners().concat();

    const ownershipPercentage = !result.length
      ? 1
      : currentProperty()?.owners?.find((item) => item.owner?.id === selected?.id)?.ownershipPercentage;

    result.push({
      owner: selected,
      ownershipPercentage: ownershipPercentage ?? 0,
    });

    setOwners(result);
  };

  const removeOwner = (index: number) => {
    setOwners((prev) => prev.toSpliced(index, 1));
  };

  const handleClose = () => {
    goBack();
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const params = owners().map((item) => ({
      propertyId: currentProperty()?.id as string,
      ownerId: item.owner?.id as string,
      ownershipPercentage: (item.ownershipPercentage as number) || 0,
      type: '',
    }));

    params.forEach((param, index) => {
      if (!currentProperty()?.owners?.some((owner) => owner.owner?.id === param.ownerId)) {
        params[index].type = 'add';
      }
    });

    currentProperty()?.owners?.forEach((owner) => {
      const index = params?.findIndex(
        (param) => param.ownerId === owner.owner?.id && param.ownershipPercentage !== owner.ownershipPercentage
      );
      if (index != -1) {
        params[index].type = 'update';
      }
    });

    currentProperty()?.owners?.forEach((owner) => {
      if (!params?.some((param) => param.ownerId === owner.owner?.id)) {
        params.push({
          propertyId: currentProperty()?.id as string,
          ownerId: owner.owner?.id as string,
          ownershipPercentage: owner.ownershipPercentage as number,
          type: 'remove',
        });
      }
    });

    if (params.every((param) => !param.type)) {
      setSubmitLoading(false);
      handleClose();
      return;
    }

    try {
      for (const param of params) {
        if (param.type === 'add') await addPropertyOwner(param.propertyId, param.ownerId, param.ownershipPercentage);
        if (param.type === 'update') await updatePropertyOwner(param.propertyId, param.ownerId, param.ownershipPercentage);
        if (param.type === 'remove') await removePropertyOwner(param.propertyId, param.ownerId);
      }
      toast.success(t('{name} has been edited successfully', { name: t(' Owner') }));
      handleClose();
    } finally {
      setSubmitLoading(false);
    }
  };

  const footerElement = () => (
    <div class="flex h-20 w-full items-center justify-between px-8">
      <div
        class="text-sm"
        classList={{
          'text-warning': ratioTotal() < 100,
          'text-success': ratioTotal() == 100,
          'text-danger': ratioTotal() > 100,
        }}>
        {t('Total')}: {ratioTotal().toFixed(2)}%
      </div>
      <div class="flex gap-2">
        <Button class="capitalize" variant="outlined" type="button" onClick={() => handleClose()}>
          {t('Cancel')}
        </Button>
        <Button class="capitalize" loading={submitLoading()} disabled={disabledSubmit()} onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </div>
    </div>
  );

  createEffect(
    on(currentProperty, () => {
      if (!submitLoading()) {
        setOwners(cloneDeep(currentProperty()?.owners || []));
      }
    })
  );

  return (
    <Modal class="animate-zoom-in capitalize" title={t(isEdit() ? 'Edit owner' : 'Add owner')} visible={true} footer={footerElement()}>
      <div class="px-7 py-4">
        <PropertyTitle property={currentProperty()} showType feedbackIconReadonly typeClass="bg-[#8712CF] text-white" />
        <div class="mt-6 text-xs uppercase text-text-level02">
          <div class="mb-2">{t('Add owner')}</div>
          <OwnerSearch
            onSelect={onSelectOwner}
            doNotRenderSelected
            exclude={(item) => !!~owners().findIndex((it) => it.owner?.id === item.id)}
          />
        </div>
        <div class="thinscroll mt-6 flex max-h-52 w-full flex-col gap-2 overflow-y-auto">
          <For each={owners()}>
            {(item, index) => (
              <div class="flex items-center">
                <div class="flex h-16 flex-1 items-center justify-between rounded-lg bg-[#F7F6FD] px-4">
                  <div class="flex items-center justify-start gap-2">
                    <Avatar class="size-9 shrink-0" name={getOwnerName(item.owner)} />
                    <div class="flex flex-col text-sm text-text-level01">{getOwnerName(item.owner)}</div>
                  </div>
                  <div>
                    <LabeledNumberInput
                      class="w-36"
                      value={owners()[index()]?.ownershipPercentage}
                      onInput={(val) => updateOwner(index(), val)}
                      percent={true}
                      suffix="%"
                      step={1}
                    />
                  </div>
                </div>
                <div class="ml-2 cursor-pointer" onClick={() => removeOwner(index())}>
                  <IconRemove class="text-essential-colour" />
                </div>
              </div>
            )}
          </For>
        </div>
      </div>
    </Modal>
  );
};
