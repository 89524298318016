import dayjs from 'dayjs';
import { createMemo, For, Show } from 'solid-js';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import { Table } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type PropertyRenewalProps = {
  class?: string;
};

export const PropertyRenewal: Component<PropertyRenewalProps> = (props) => {
  const { t } = useLocalization();
  const { propertyOverview } = useProperties();

  const columns = createMemo<TableColumns<MagicDoor.Api.LeaseDto>>(() => [
    {
      title: t('Unit'),
      class: 'text-nowrap',
      render: (row) => {
        const unit = createMemo(() => {
          return propertyOverview()?.units?.find((unit) => unit.id === row.unitId);
        });

        return (
          <div>
            <div class="font-medium">{unit()?.name}</div>
            <DisplayAddress class="text-xs text-text-level03" address={propertyOverview()?.property?.address} />
          </div>
        );
      },
    },
    {
      title: t('Tenants'),
      headerClass: 'w-64',
      render: (row) => <span class="text-sm font-medium text-text-level01">{row.tenants.length}</span>,
    },
    {
      title: t('Renewal date'),
      class: 'text-nowrap',
      render: (item) => <span class="text-sm">{dayjs(item?.end).format('MM/DD')}</span>,
    },
    {
      title: t('Term'),
      class: 'text-nowrap',
      render: (item) => (
        <span class="text-sm font-medium text-text-level01">{`${dayjs(item.start).format('MM/DD/YYYY')} - ${dayjs(item.end).format(
          'MM/DD/YYYY'
        )}`}</span>
      ),
    },
  ]);

  return (
    <Card
      class={props.class}
      contentClass="h-auto max-h-[288px]"
      title={t('Leases with upcoming renewal')}
      description={t('The lease ends in less than 30 days')}>
      <div class="hidden md:block">
        <Table columns={columns()} data={propertyOverview()?.upcomingRenewalLeases} />
      </div>
      <div class="block p-3 md:hidden">
        <Show when={propertyOverview()?.upcomingRenewalLeases.length} fallback={<Empty class="py-0" size="100px" />}>
          <For each={propertyOverview()?.upcomingRenewalLeases}>
            {(item) => {
              const unit = createMemo(() => {
                return propertyOverview()?.units?.find((unit) => unit.id === item.unitId);
              });

              return (
                <div class="space-y-3 border-b border-light-gray pb-3">
                  <div>
                    <div class="font-medium">{unit()?.name}</div>
                    <DisplayAddress class="text-xs text-text-level03" address={propertyOverview()?.property?.address} />
                  </div>
                  <div class="flex gap-[6px]">
                    <p class="text-xs font-light text-title-gray">{t('Tenants')}:</p>
                    <h3 class="text-xs font-medium text-title-gray">{item.tenants.length}</h3>
                  </div>

                  <div class="flex gap-[6px]">
                    <p class="text-xs font-light text-title-gray">{t('Renewal date')}:</p>
                    <h3 class="text-xs font-medium text-title-gray">{dayjs(item?.end).format('MM/DD')}</h3>
                  </div>
                  <div class="flex gap-[6px]">
                    <p class="text-xs font-light text-title-gray">{t('Term')}:</p>
                    <h3 class="text-xs font-medium text-title-gray">
                      {`${dayjs(item.start).format('MM/DD/YYYY')} - ${dayjs(item.end).format('MM/DD/YYYY')}`}
                    </h3>
                  </div>
                </div>
              );
            }}
          </For>
        </Show>
      </div>
    </Card>
  );
};
