import { For, createSignal } from 'solid-js';
import useUseCase from '~/framework/hooks/useUseCase';
import { CreateAnnotationUseCase } from '~/pdfsigner/usecases/pdfEditing/createAnnotationUseCase';
import { DragAnnotationUseCase } from '~/pdfsigner/usecases/pdfEditing/dragAnnotationUseCase';
import { RenderUseCase } from '~/pdfsigner/usecases/pdfEditing/renderUseCase';
import { ResizeAnnotationUseCase } from '~/pdfsigner/usecases/pdfEditing/resizeAnnotationUseCase';
import { SetAnnotationTextUseCase } from '~/pdfsigner/usecases/pdfEditing/setAnnotationTextUseCase';
import { StartEditingUseCase } from '~/pdfsigner/usecases/pdfEditing/startEditingUseCase';
import { StopDraggingUseCase } from '~/pdfsigner/usecases/pdfEditing/stopDraggingUseCase';
import { StopTransformingUseCase } from '~/pdfsigner/usecases/pdfEditing/stopTransformingUseCase';
import { PdfPage } from './PdfPage';
import type { JSX } from 'solid-js';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';
import type { BoundingBox } from '~/pdfsigner/usecases/types/boundingBox';
import type { PageCoordinates } from '~/pdfsigner/usecases/types/pageCoordinates';
import type { Position } from '~/pdfsigner/usecases/types/position';
import type { RelativePosition } from '~/pdfsigner/usecases/types/relativePosition';

export interface PdfDocumentProps {
  pages: PresentableAnnotatedPdfPage[];
  onPdfRenderComplete?: () => void;
  onChange?: () => void;
}

export const PdfDocument = (props: PdfDocumentProps): JSX.Element => {
  const [renderedPages, setRenderedPages] = createSignal(0);
  const { execute: dragAnnotation } = useUseCase(DragAnnotationUseCase);
  const { execute: stopDraggingAnnotation } = useUseCase(StopDraggingUseCase);
  const { execute: render } = useUseCase(RenderUseCase);
  const { execute: addAnnotation } = useUseCase(CreateAnnotationUseCase);
  const { execute: resizeAnnotation } = useUseCase(ResizeAnnotationUseCase);
  const { execute: stopTransform } = useUseCase(StopTransformingUseCase);
  const { execute: startEditingAnnotation } = useUseCase(StartEditingUseCase);

  const { execute: setText } = useUseCase(SetAnnotationTextUseCase);
  const handlePdfChange = () => props.onChange?.();

  const getPositionOfEvent = (event: MouseEvent): Position => {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  };

  const getPageCoordinatesForPosition = (position: Position, numberOfPages: number): PageCoordinates | undefined => {
    for (let i = 0; i < numberOfPages; i++) {
      const relativePosition = getRelativePosition(position, i);
      if (isPositionInRect(position, relativePosition.boundingBox)) {
        return {
          relativePosition,
          page: i,
        };
      }
    }
    return undefined;
  };

  const isPositionInRect = (position: Position, rect: BoundingBox) => {
    return position.x >= rect.x && position.x <= rect.x + rect.width && position.y >= rect.y && position.y <= rect.y + rect.height;
  };

  const getRelativePosition = (position: Position, page: number): RelativePosition => {
    const rect: DOMRect | undefined = document.getElementById('pdfPage' + page)?.getBoundingClientRect();
    return {
      position,
      boundingBox: {
        x: rect?.x || 0,
        y: rect?.y || 0,
        width: rect?.width || 0,
        height: rect?.height || 0,
      },
    };
  };

  const handleStartDragging = async (id: string, event: MouseEvent, page: number) => {
    event.stopPropagation();
    await dragAnnotation({ id, relativePosition: getRelativePosition(getPositionOfEvent(event), page), startPage: page, endPage: page });
    document.onmousemove = (event: MouseEvent) => drag(id, event, page);
    document.onmouseup = (event: MouseEvent) => stopDragging(id, event, page);
  };

  const drag = async (id: string, event: MouseEvent, page: number) => {
    await dragAnnotation({ id, relativePosition: getRelativePosition(getPositionOfEvent(event), page), startPage: page, endPage: page });
  };

  const stopDragging = async (id: string, event: MouseEvent, page: number) => {
    const pdf = props.pages;
    if (!pdf) {
      return;
    }
    const relativePosition = getRelativePosition(getPositionOfEvent(event), page);
    const newPosition = getPageCoordinatesForPosition(relativePosition.position, pdf.length);
    const endPage = newPosition ? newPosition.page : page;
    await stopDraggingAnnotation({ id, relativePosition: newPosition?.relativePosition, startPage: page, endPage: endPage });
    handlePdfChange();
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const handleAnnotationInput = async (id: string, event: InputEvent, value: string) => {
    event.stopPropagation();
    await setText({ id, value });
    handlePdfChange();
  };

  const handleStartEditing = async (id: string, event: MouseEvent, pageNumber: number) => {
    await startEditingAnnotation({ id, page: pageNumber });
  };

  const handleAnnotationStartResizing = async (id: string, event: MouseEvent, pageNumber: number) => {
    event.stopPropagation();
    await resizeAnnotation({
      id,
      relativePosition: getRelativePosition(getPositionOfEvent(event), pageNumber),
      startPage: pageNumber,
    });
    document.onmousemove = (event: MouseEvent) => resize(id, event, pageNumber);
    document.onmouseup = () => reset();
  };

  const resize = async (id: string, event: MouseEvent, pageNumber: number) => {
    await resizeAnnotation({
      id,
      relativePosition: getRelativePosition(getPositionOfEvent(event), pageNumber),
      startPage: pageNumber,
    });
    handlePdfChange();
  };

  const reset = async () => {
    await stopTransform();
    handlePdfChange();
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const handlePageMouseDown = async (event: MouseEvent, pageNumber: number) => {
    addAnnotation({
      page: pageNumber,
      relativePosition: getRelativePosition(getPositionOfEvent(event), pageNumber),
    });
    handlePdfChange();
  };

  const handlePageRenderComplete = async () => {
    setRenderedPages(renderedPages() + 1);
    if (renderedPages() === props.pages.length) {
      setRenderedPages(0);
      render();
      props.onPdfRenderComplete?.();
    }
  };

  return (
    <div id="pdf-viewer" class="flex w-full flex-col items-center">
      <For each={props.pages}>
        {(page: PresentableAnnotatedPdfPage) => (
          <PdfPage
            page={page}
            onPageMouseDown={handlePageMouseDown}
            onAnnotationInput={handleAnnotationInput}
            onAnnotationStartEditing={handleStartEditing}
            onAnnotationStartDragging={handleStartDragging}
            onAnnotationStartResizing={handleAnnotationStartResizing}
            onPageRenderComplete={handlePageRenderComplete}
          />
        )}
      </For>
    </div>
  );
};
