import { createEffect, createSignal } from 'solid-js';
import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconChatLoading from '~/assets/images/chat/aiChatLoading.gif';
import IconLoadingStar from '~/assets/images/chat/aiChatStar.svg?component-solid';
import IconStar from '~/assets/images/chat/aiGenerateStar.png';
import IconAiStarBg from '~/assets/images/chat/aiGenerateStarBg.png';
import IconAiGradientBg from '~/assets/images/chat/aiMagicItGradientBg.png';
import IconAirplane from '~/assets/images/common/airplane.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { useChat } from '~/contexts/local';
import { resizeTextArea } from '~/utils/hooks';
import { getChatInput } from './chatInputStore';
import type { Accessor } from 'solid-js';

interface AiGenerateInputProps {
  newMessage: string;
  setNewMessage: (message: string) => void;
  handleSendMessage: (messageContent: string) => void;
  isSendingMessage: boolean;
  files: File[];
  setFiles: (files: File[]) => void;
  handleCancel: () => void;
  aiMessage: string;
  chatId: string;
  setAiMessage: (message: string) => void;
  messageStore: (chatId: string, message: string) => void;
  requestFocus: Accessor<boolean>;
  setRequestFocus: (requestFocus: boolean) => void;
  participantType?: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`;
  participantId?: string | null;
  createChat: (
    participantType: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`,
    participantId: string
  ) => Promise<{ chatId: string } | undefined>;
  setChatId: (chatId: string) => void;
}

const AiGenerateInput = (props: AiGenerateInputProps) => {
  const { t } = useLocalization();
  const { chatMagicIt } = useChat();

  const [isAiGenerating, setIsAiGenerating] = createSignal<boolean>(false);

  const [isDragging, setIsDragging] = createSignal(false);
  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer?.files;
    if (files) props.setFiles([...props.files, ...Array.from(files)]);
  };

  const handleMagicItClick = async () => {
    setIsAiGenerating(true);

    if (!props.newMessage || props.newMessage.trim() === '') {
      console.error('The MyText field is required and must be a non-empty string.');
      setIsAiGenerating(false);
      return;
    }

    try {
      let targetChatId = props.chatId;

      if (!targetChatId && props.participantType && props.participantId) {
        const newChatResult = await props.createChat(props.participantType, props.participantId);
        if (newChatResult && newChatResult.chatId) {
          targetChatId = newChatResult.chatId;
          props.setChatId(targetChatId);
        } else {
          console.error('Failed to create a new chat: No chatId returned');
          setIsAiGenerating(false);
          return;
        }
      }

      const response = await chatMagicIt(targetChatId, props.newMessage);
      if (response) {
        props.setAiMessage(response.suggestedText || 'No suggestion available');
        resizeTextArea(textAreaRef);
      } else {
        console.error('No response from chatMagicIt');
      }
    } catch (error) {
      console.error('Error generating AI message:', error);
    } finally {
      setIsAiGenerating(false);
    }
  };

  const handleSendAiMessage = () => {
    if (props.aiMessage) {
      props.setNewMessage(props.aiMessage);
    }
    props.handleSendMessage(props.aiMessage);
  };

  let textAreaRef: HTMLTextAreaElement;

  const handleInput = (e: string) => {
    if (e !== props.newMessage) {
      props.setNewMessage(e);
      props.messageStore(props.chatId, e);
    }
    resizeTextArea(textAreaRef);
  };

  createEffect(() => {
    const storedMessage = getChatInput(props.chatId);
    props.setAiMessage(storedMessage);
  });

  createEffect(() => {
    if (props.requestFocus()) {
      textAreaRef.focus();
      props.setRequestFocus(false);
    }
  });

  createEffect(() => {
    resizeTextArea(textAreaRef);
  });

  return (
    <div
      class="max-w-[100vw] border-t border-gray-200 bg-gradient-to-tr2"
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}>
      <div
        class="flex flex-col gap-2.5 rounded-lg border-gray-300 p-4 lg:px-8"
        style={{
          'background-image': `url(${IconAiStarBg})`,
          'background-position': 'right center',
          'background-repeat': 'no-repeat',
          'background-size': '25%',
        }}>
        <div class="flex items-start gap-1">
          <img src={IconStar} />
          <div class="text-sm font-semibold text-white md:text-lg">{t('Enter your message here and see what AI can do')}</div>
        </div>
        <div class="relative flex items-center gap-2 rounded-lg bg-inputbox-bg">
          <div
            class="pointer-events-none absolute inset-0 z-50 flex items-center justify-center rounded-lg opacity-0 transition-opacity duration-200"
            classList={{ 'opacity-100': isDragging() }}>
            <div
              class="border-blue-400/50 from-blue-200/20 to-blue-400/20 flex w-full items-center justify-center rounded-lg border-2 border-dashed bg-gradient-to-r p-4 backdrop-blur-sm transition-all duration-300"
              classList={{
                'border-blue-500/80': isDragging(),
              }}>
              {t('Drop files to upload')}
            </div>
          </div>
          <textarea
            ref={(textArea) => (textAreaRef = textArea)}
            autofocus
            class="thinscroll max-h-48 flex-1 resize-none rounded-lg bg-inputbox-bg py-3.5 pl-4 text-gray-800 focus:outline-none"
            placeholder={t('Please enter a description')}
            value={props.aiMessage || props.newMessage}
            onInput={(e) => handleInput(e.target.value)}
            disabled={props.isSendingMessage || isAiGenerating()}
            rows={1}
          />
          {isAiGenerating() && (
            <div class="absolute inset-0 flex items-start justify-start gap-1 rounded-lg bg-white py-3.5 pl-4 text-[#CB77FF]">
              <IconLoadingStar />
              <span class="ml-1">{t('AI is writing')}</span>
              <img src={IconChatLoading} alt="AI Writing" class="size-4" />
            </div>
          )}
          <div class="mr-1 rounded-lg bg-gradient-to-tl">
            <button
              disabled={isAiGenerating() || props.isSendingMessage}
              onClick={handleMagicItClick}
              class="flex h-10 w-fit items-center justify-center gap-2 bg-contain bg-left bg-no-repeat px-4 text-white"
              style={{
                'background-image': `url(${IconAiGradientBg})`,
                'background-position': 'left, right',
                'background-repeat': 'no-repeat, no-repeat',
              }}>
              <img src={IconAiBot} class="hide-on-mobile" />
              <span class="flex whitespace-nowrap text-base font-medium">{isAiGenerating() ? t('Generating...') : t('Magic it')}</span>
            </button>
          </div>
        </div>

        <div class="flex items-center justify-end text-xs lg:justify-between">
          <div class="hide-on-mobile text-white/70">{t('ENTER to send, ESC to cancel')}</div>
          <div class="flex gap-2">
            <Button
              variant="outlined"
              disabled={props.isSendingMessage}
              onClick={() => props.handleCancel()}
              class="flex h-10 items-center justify-center border-white py-2 text-white">
              {t('Cancel')}
            </Button>

            <Button
              disabled={props.isSendingMessage}
              onClick={() => handleSendAiMessage()}
              loading={props.isSendingMessage}
              class="flex h-10 items-center justify-center border-none bg-white/20 py-2 hover-allowed:hover:bg-white/30">
              <IconAirplane class="size-7 text-white" />
              {t('Send')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiGenerateInput;
