import type { JSX } from 'solid-js';

export interface UploaderFile extends File {
  uid: string;
  isUploading?: boolean;
}

export enum UploadStatus {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
  IDLE = 'idle',
}

export enum DeleteStatus {
  DELETED = 'deleted',
  DELETING = 'deleting',
  FAILED = 'failed',
}

export type HydrateFile = {
  fileName: string;
  fileId: string;
  fileUrl?: string;
  thumbUrl?: string;
  originalFile?: File;
  uploaded?: UploadStatus;

  deleted?: DeleteStatus;
};

export type FileFrom = 'local' | 'api';

export type UploadProps = DragAndDropProps & {
  maxCount?: number;
  onFilesListChange?: (files: HydrateFile[]) => void;
  entityFilePrefixURL?: string;
  accept?: string;
  defaultFileList?: HydrateFile[];

  onBeforeUpload?: (file: File) => void;
  onAfterUpload?: (file: HydrateFile) => void;
  onUploadingChange?: (uploading: boolean) => void;
  onInput?: (files: HydrateFile[]) => void;

  needProcess?: boolean;

  usePreUpload?: boolean;

  controlled?: (value: any) => (Partial<HydrateFile> & { fileId: string })[];
  value?: any;
};

export type DragAndDropProps = {
  class?: string;
  children?: JSX.Element;
  accept?: string;
  multiple?: boolean;
  onDropFiles?: (files: FileList) => void;
  name?: string;
  maxSize?: number; // in MB
};

export type FileDescriptor = {
  fileId: string;
  fileName: string;
};
