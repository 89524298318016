import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconPropertyFloors from '~/assets/images/property/propertyFloors.svg?component-solid';
import IconPropertyType from '~/assets/images/property/propertyType.svg?component-solid';
import IconUnitCount from '~/assets/images/property/unitCount.svg?component-solid';
import IconWaring from '~/assets/images/units/listing/warning.svg?component-solid';
import { Button, LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { ImagePreview } from '~/components/common/ImagePreview';
import { Panel } from '~/components/common/Panels';
import { EditMagicTagsModal, MagicTagsList } from '~/components/magic-tags';
import { propertyTypes } from '~/components/properties';
import { Image } from '~/components/ui';
import { IconRefresh } from '~/components/ui/Icons';
import { useLocalization, useProperties } from '~/contexts/global';
import { getImageUrl } from '~/utils/file';
import type { Component } from 'solid-js';
import type { PreviewList } from '~/components/common/ImagePreview/type';

export const PropertyInformation: Component = () => {
  const { t } = useLocalization();
  const { current, updatePropertyMagicTags } = useProperties();
  const params = useParams();

  const defaultUrl = createMemo(() => `/portfolios/${params.portfolioId}/properties/${params.propertyId}`);

  const [previewVisible, setPreviewVisible] = createSignal<boolean>(false);
  const [previewIndex, setPreviewIndex] = createSignal<number>(0);
  const [previewList, setPreviewList] = createSignal<PreviewList[]>([]);
  const [isMagicTagsEditModalVisible, setIsMagicTagsEditModalVisible] = createSignal(false);

  const showImagePreview = (index: number) => {
    setPreviewIndex(index);
    setPreviewVisible(true);
  };

  createEffect(() => {
    const list: PreviewList[] = [];
    if (params.propertyId) {
      const url = getImageUrl('property', params.propertyId);
      list.push({
        url,
        type: 'img',
      });
    }
    setPreviewList(list);
  });

  return (
    <>
      <Panel
        class="mx-3 mt-3 md:mx-8 md:mt-0"
        title={t('Property information')}
        actions={
          <LinkButton size="xs" href={defaultUrl() + '/edit'} variant="outlined">
            <IconEdit class="size-5" />
            <span>{t('Edit property')}</span>
          </LinkButton>
        }>
        <div class="flex flex-col gap-3 p-3 md:gap-6 md:px-6 lg:flex-row">
          <div class="relative flex w-full flex-col gap-3 lg:w-80">
            <Show when={previewList()[0]}>
              <Image
                class="size-full cursor-pointer rounded-lg object-cover lg:size-80"
                src={previewList()[0].url}
                onClick={() => showImagePreview(0)}
              />
            </Show>
            <div class="hidden w-full gap-2 md:flex lg:w-80" />
          </div>
          <div class="w-full divide-y divide-partingline">
            <div>
              <div class="mb-4 md:mb-8">
                <div class="flex items-center gap-2 text-2xl font-semibold text-text-level01">
                  {current()?.name}
                  <Show when={current()?.type && propertyTypes[current()!.type]}>
                    {(type) => (
                      <div class="hidden items-center gap-1 rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white md:flex">
                        <IconPropertyType class="" />
                        {t(type().name)}
                      </div>
                    )}
                  </Show>
                </div>
                <DisplayAddress class="text-sm font-medium text-text-level03" address={current()?.address} />
                <Show when={current()?.type && propertyTypes[current()!.type]}>
                  {(type) => (
                    <div class="mt-3 flex w-fit items-center gap-1 rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white md:hidden">
                      <IconPropertyType class="" />
                      {t(type().name)}
                    </div>
                  )}
                </Show>
                <Show when={!current()?.address?.isValid}>
                  <div class="mt-3 flex items-center justify-between rounded-xl bg-danger-light p-3 text-danger">
                    <div class="flex items-center">
                      <IconWaring class="mr-4 size-5" />
                      <span class="normal-case">
                        {t('The address is invalid. Please update with a valid address to proceed with the listing.')}
                      </span>
                    </div>
                    <LinkButton
                      variant="outlined"
                      class="rounded-full border-danger py-1 text-sm font-medium text-danger hover-allowed:hover:bg-danger/10"
                      href={defaultUrl() + '/edit'}>
                      <IconRefresh class="size-4" />
                      {t('Update')}
                    </LinkButton>
                  </div>
                </Show>
              </div>

              <div class="mb-6 flex gap-14">
                <div class="grow-1 basis-30 flex items-center space-x-2 text-left">
                  <IconUnitCount class="size-9 rounded-full bg-inputbox-bg" />
                  <div>
                    <div class="text-xs text-text-level03">{t('Unit')}</div>
                    <div class="text-sm text-text-level01">{current()?.unitCount ?? '-'}</div>
                  </div>
                </div>
                <div class="grow-1 basis-30 flex items-center space-x-2 text-left">
                  <IconPropertyFloors class="size-9 rounded-full bg-inputbox-bg" />
                  <div>
                    <div class="text-xs text-text-level03">{t('Floors')}</div>
                    <div class="text-sm text-text-level01">{current()?.totalFloors ?? '-'}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="pt-5">
                <div class="flex items-center justify-between">
                  <div class="my-4 text-base font-semibold text-text-level01">{t('Magic tags')}</div>
                  <Button size="xs" variant="outlined" onClick={() => setIsMagicTagsEditModalVisible(true)}>
                    <IconEdit class="size-5" />
                    {t('Edit magic tags')}
                  </Button>
                </div>
                <MagicTagsList defaultData={current()?.magicTags} />
              </div>
              <div class="mt-1 w-96 text-xs text-text-level03" />
            </div>
          </div>
        </div>
      </Panel>
      <ImagePreview previewList={previewList()} initIndex={previewIndex()} visible={[previewVisible, setPreviewVisible]} />

      <Show when={isMagicTagsEditModalVisible()}>
        <EditMagicTagsModal
          type="property"
          visible={isMagicTagsEditModalVisible()}
          defaultData={current()?.magicTags}
          onSubmit={(magicTags) => {
            return updatePropertyMagicTags(params.propertyId, { magicTags });
          }}
          onClose={() => {
            setIsMagicTagsEditModalVisible(false);
          }}
        />
      </Show>
    </>
  );
};
