import { Form } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import LabeledBankingInput from '~/components/common/Inputs/form-inputs/LabeledBankingInput';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import type { BetterForm } from '~/components/common/BetterForm';

interface AddOwnerPaymentProps {
  onSubmit: () => Promise<void> | void;
  form: BetterForm.form;
  onClose: () => void;
}

export const AddOwnerPayment = (props: AddOwnerPaymentProps) => {
  const { t } = useLocalization();

  return (
    <Form
      defaultForm={props.form}
      class="flex size-full flex-col items-center justify-center"
      onFormSubmit={() => {
        props.onSubmit();
      }}>
      <ThreeSectionBox
        topTitle={<h3 class="text-lg font-medium capitalize">{t('Add payment account')}</h3>}
        middle={
          <div class="px-4">
            <LabeledBankingInput required />
          </div>
        }
        bottom={
          <div class="sticky bottom-0 mt-auto flex w-full justify-end gap-2 border-t border-partingline bg-white px-6 py-5">
            <Button onClick={props.onClose} type="button" variant="outlined">
              {t('Cancel')}
            </Button>
            <Button disabled={!props.form.isValidate} type="submit">
              {t('Submit')}
            </Button>
          </div>
        }
      />
    </Form>
  );
};
