import { Show } from 'solid-js';
import IconFile from '~/assets/images/chat/chatFile.png';
import { useLocalization } from '~/contexts/global';
import { isAudio, isCode, isFile, isImage, isPdf, isPowerpoint, isSpreadsheet, isText, isSvg, isVideo, isWord, isZip } from '~/utils/file';

export function GetFilePreview(
  file: File | MagicDoor.Api.ChatMessageFileDto,
  createFileUrl: { (file: Blob | MediaSource): string; (arg0: any): string | undefined }
) {
  const { t } = useLocalization();
  const fileName = file instanceof File ? file.name : file.fileName;

  return (
    <>
      <Show when={isImage(file) || isSvg(file)}>
        <img src={createFileUrl(file)} alt={fileName || undefined} class="size-28 rounded-lg bg-[#F8EFE4] object-cover" />
      </Show>
      <Show when={isVideo(file)}>
        <video src={createFileUrl(file)} controls class="h-28 rounded-lg" />
      </Show>
      <Show
        when={
          isAudio(file) ||
          isPdf(file) ||
          isText(file) ||
          isSpreadsheet(file) ||
          isPowerpoint(file) ||
          isWord(file) ||
          isZip(file) ||
          isCode(file) ||
          isFile(file)
        }>
        <div class="mb-1 flex size-28 flex-col items-center justify-center rounded-lg bg-[#F8EFE4] p-1 text-start">
          <div class="relative py-2">
            <img src={IconFile} />
            <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-bold text-white">
              {t(
                isAudio(file)
                  ? 'Audio'
                  : isPdf(file)
                    ? 'PDF'
                    : isText(file)
                      ? 'Text'
                      : isSpreadsheet(file)
                        ? 'Spreadsheet'
                        : isPowerpoint(file)
                          ? 'Powerpoint'
                          : isWord(file)
                            ? 'Word'
                            : isZip(file)
                              ? 'Zip'
                              : isCode(file)
                                ? 'Code'
                                : 'File'
              )}
            </div>
          </div>
          <div class="w-full truncate text-text-level02">{fileName}</div>
        </div>
      </Show>
    </>
  );
}

export function createFileUrl(file: Blob | MediaSource) {
  return URL.createObjectURL(file);
}
