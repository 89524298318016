import { createSignal, onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconTextMessages from '~/assets/images/settings/property/propertyTextMessages.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequestSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

export const MaintenanceRequestSetting = () => {
  const { t } = useLocalization();

  const {
    isLoading,
    addMaintenanceRequestSettings,
    addMaintenanceRequestSettingsByLevel,
    getAllMaintenanceRequestSettings,
    allMaintenanceRequestSettings,
    deleteMaintenanceRequestSettingsByLevel,
  } = useMaintenanceRequestSettings();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  onMount(async () => {
    await getAllMaintenanceRequestSettings();
  });

  const onDone = async (level: string, levelId: string) => {
    await addMaintenanceRequestSettingsByLevel(level, levelId, {
      ...(allMaintenanceRequestSettings()?.settings as MagicDoor.Api.MaintenanceRequestSettingsDto),
    });
    setShowModal(false);
    getAllMaintenanceRequestSettings();
    toast(t('Maintenance request settings saved successfully'), 'success');
  };

  const handleSave = async (data: MagicDoor.Api.MaintenanceRequestSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      await addMaintenanceRequestSettings(data);
      toast(t('Maintenance request settings saved successfully'), 'success');
      getAllMaintenanceRequestSettings();
    } else {
      await addMaintenanceRequestSettingsByLevel(level, levelId, data);
      toast(t('Maintenance request settings saved successfully'), 'success');
      getAllMaintenanceRequestSettings();
    }
  };

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteMaintenanceRequestSettingsByLevel(level, levelId);
        toast(t('Maintenance request settings deleted successfully'), 'success');
        getAllMaintenanceRequestSettings();
      },
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  const sections = [
    {
      field: 'allowTenantToClose',
      label: 'Allow tenants to close maintenance requests',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
    },
    {
      field: 'allowTenantToEdit',
      label: 'Allow tenants to update maintenance requests',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
    },
    {
      field: 'onlyAllowRequesterToEdit',
      label: 'Only the request submitter can update maintenance request',
      type: 'boolean',
      class: 'col-span-2 ml-6 pl-4 border-l-2 border-gray-300',
      labelClass: 'w-full md:w-1/2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
      description: 'Restrict editing permissions to only the tenant who originally submitted the request',
      disabled: (model: MagicDoor.Api.MaintenanceRequestSettingsDto) => !model?.allowTenantToEdit,
      style: (model: MagicDoor.Api.MaintenanceRequestSettingsDto) => ({
        opacity: model?.allowTenantToEdit ? 1 : 0.6,
        cursor: model?.allowTenantToEdit ? 'pointer' : 'not-allowed',
      }),
    },
  ];

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus />
            <div class="hidden font-medium md:block">{t('Add new override')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Maintenance request')} icon={IconTextMessages} />}
        middle={
          <div class="flex flex-col gap-5 p-3 md:p-6">
            <div class="flex flex-col gap-4 text-left">
              <EditForm<MagicDoor.Api.MaintenanceRequestSettingsDto>
                sections={sections}
                onOk={(data) => handleSave(data)}
                model={allMaintenanceRequestSettings()?.settings || ({} as MagicDoor.Api.MaintenanceRequestSettingsDto)}
              />
            </div>

            <Overrides<MagicDoor.Api.MaintenanceRequestSettingsDtoSettingsWithOverrideDto>
              overrides={allMaintenanceRequestSettings()?.overrides || []}
              onDelete={onDelete}
              title={t('Maintenance request overrides')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm<MagicDoor.Api.MaintenanceRequestSettingsDto>
                      sections={sections}
                      onOk={(data) => handleSave(data, override.level, override.levelId)}
                      model={override.settings || ({} as MagicDoor.Api.MaintenanceRequestSettingsDto)}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allMaintenanceRequestSettings()?.overrides || []}
          onClose={onClose}
          onDone={onDone}
          loading={isLoading()}
        />
      </Show>
    </>
  );
};
