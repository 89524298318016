import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChatsSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';

interface flatProps {
  personalChats?: boolean;
  enableTranslation?: boolean;
  enableQuitHours: boolean;
  start?: string;
  end?: string;
}

export const Chat = () => {
  const { t } = useLocalization();
  const { chatsSettings, updateChatSettings: changeChatSettings, updateChatsSettingsByLevel } = useChatsSettings();

  const sections = [
    {
      field: 'personalChats',
      label: 'Enable personal chat',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
      rules: [],
    },
    {
      field: 'enableTranslation',
      label: 'Enable translation',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
      rules: [],
    },
    {
      field: 'enableQuitHours',
      label: 'Enable quite hours',
      type: 'boolean',
      class: 'col-span-2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
      rules: [],
    },
    {
      field: 'start',
      label: 'Quit hours start',
      type: 'timePicker',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
      rules: [],
      visibleMethod: (data: any) => {
        return data?.enableQuitHours;
      },
    },
    {
      field: 'end',
      label: 'Quit hours end',
      type: 'timePicker',
      class: 'col-span-2',
      labelContainerClass: 'flex-row justify-between',
      rules: [],
      visibleMethod: (data: any) => {
        return data?.enableQuitHours;
      },
    },
  ];

  const combineParam = (data: flatProps) => {
    if (data.enableQuitHours) {
      return {
        personalChats: data.personalChats,
        enableTranslation: data.enableTranslation,
        quitHours: {
          start: data.start,
          end: data.end,
        },
      };
    } else {
      return {
        personalChats: data.personalChats,
        enableTranslation: data.enableTranslation,
      };
    }
  };

  const formatParam = (params: any) => {
    if (!params) return undefined;
    if (Array.isArray(params)) {
      const temp = [] as any;
      params.forEach((item) => temp.push(combineParam(item)));
      return temp;
    } else {
      return combineParam(params);
    }
  };

  const handleChangeData = async (params: any, level?: string, levelId?: string) => {
    const data = formatParam(params);
    if (level && levelId) {
      updateChatsSettingsByLevel(level, levelId, {
        ...data,
      });
    } else {
      changeChatSettings({
        ...data,
      });
    }
    toast(t('Settings have been updated successfully'), 'success');
  };

  const handleFlatObj = (obj?: MagicDoor.Api.ChatSettingsDto) => {
    if (!obj)
      return {
        personalChats: false,
        enableTranslation: false,
        enableQuitHours: false,
      };
    if (obj?.quitHours?.start) {
      return {
        personalChats: obj?.personalChats,
        enableTranslation: obj?.enableTranslation,
        enableQuitHours: !!obj?.quitHours?.start,
        start: obj?.quitHours?.start as string,
        end: obj?.quitHours?.end as string,
      };
    } else {
      return {
        personalChats: obj?.personalChats,
        enableTranslation: obj?.enableTranslation,
        enableQuitHours: !!obj?.quitHours?.start,
      };
    }
  };

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Chat')} />}
        middle={
          <div class="flex flex-col gap-5 p-3 md:p-6">
            <div class="flex flex-col gap-4 text-left">
              <EditForm<MagicDoor.Api.ChatSettingsDto>
                sections={sections}
                onOk={(data) => handleChangeData(data)}
                model={handleFlatObj(chatsSettings())}
              />
            </div>
          </div>
        }
      />
    </>
  );
};
