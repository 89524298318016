import { FileRepository } from '~/repositories/fileRepository';
import { urlWithQuery } from '~/utils/url';

export type Filters = {
  disable?: boolean;
  page?: number;
  pageSize?: number;
  skip?: number;
  active?: boolean;
  firstName?: string;
  lastName?: string;
  firstOrLastName?: string;
  phone?: string;
  email?: string;
  searchText?: string;
  portalStatus?: 'notInvited' | 'invited' | 'active';
};

export const VIOLATION_BASE_URL = '/api/associations/violations';
export class ViolationsRepository extends FileRepository {
  public async list(filter: Filters): Promise<MagicDoor.Api.PaginatedAssociationViolationListDto> {
    const url = urlWithQuery(VIOLATION_BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async add(payload: MagicDoor.Api.CreateAssociationViolationRequestDto): Promise<MagicDoor.Api.AssociationViolationDto> {
    const url = `${VIOLATION_BASE_URL}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async get(id: string): Promise<MagicDoor.Api.AssociationViolationDto> {
    const url = `${VIOLATION_BASE_URL}/${id}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async update(
    id: string,
    payload: MagicDoor.Api.UpdateAssociationViolationRequestDto
  ): Promise<MagicDoor.Api.AssociationViolationDto> {
    const url = `${VIOLATION_BASE_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async delete(id: string): Promise<void> {
    const url = `${VIOLATION_BASE_URL}/${id}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
      body: JSON.stringify({ deleteBills: true }),
    });
  }

  public async upload(id: string, file: File): Promise<void> {
    const url = `${VIOLATION_BASE_URL}/${id}/file`;
    await this.uploadFileToUrl(url, file);
  }

  public async createFineBill(
    id: string,
    data: {
      dueDate: string;
    }
  ): Promise<MagicDoor.Api.AssociationViolationDto> {
    const url = `${VIOLATION_BASE_URL}/${id}/fine`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }

  public async changeStatus(id: string, status: MagicDoor.Api.AssociationViolationStatus): Promise<MagicDoor.Api.AssociationViolationDto> {
    const url = `${VIOLATION_BASE_URL}/${id}/status`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ status }),
    });
    return this.getJsonResponse(response);
  }

  public async createWorkOrder(
    id: string,
    data: MagicDoor.Api.CreateWorkOrderFromAssociationViolationDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${VIOLATION_BASE_URL}/${id}/work-order`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }

  public async createLeaseBill(
    id: string,
    data: MagicDoor.Api.AddAssociationViolationLeaseBillDto
  ): Promise<MagicDoor.Api.AssociationViolationDto> {
    const url = `${VIOLATION_BASE_URL}/${id}/lease-bill`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }
  public async createOwnerNotification(id: string, formData: FormData): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${VIOLATION_BASE_URL}/${id}/notification/owner`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async createLeaseNotification(id: string, formData: FormData): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    const url = `${VIOLATION_BASE_URL}/${id}/notification/lease`;

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return this.getJsonResponse(response);
  }
}
