import { A } from '@solidjs/router';
import { Show, createEffect, createSignal } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import { LinkButton, Button } from '~/components/common/Buttons';
import IconField from '~/components/common/IconField';
import { IconClock, IconMessage, IconHash, IconMessageSquareText } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { ConvertToWorkOrderModal } from '~/pages/maintenance-request/maintenance-request-details/components/ConvertToWorkOrderModal';
import { maintenanceRequestCategoryRepository } from '~/repositories/categoryRepository';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { createTriggerResource } from '~/utils/resource';
import { LoadingSkeleton } from './LoadingSkeleton';
import { MaintenanceRequestStatus } from './MaintenanceRequestStatus';
import type { Component } from 'solid-js';
import type { Category } from '~/repositories/categoryRepository';
import type { MaintenanceRequestDto, MaintenanceRequestListDto, HydratedWorkOrderDto, WorkOrderListDto } from '~/swagger/Api';

export interface MaintenanceWorkOrderSummaryProps {
  currentPage: 'workOrder' | 'maintenanceRequest';
  loading?: boolean;
  maintenanceRequest?: MaintenanceRequestDto | MaintenanceRequestListDto;
  workOrder?: HydratedWorkOrderDto | WorkOrderListDto;
}

interface MaintenanceRequestSummaryProps {
  loading?: boolean;
  maintenanceRequest?: MaintenanceRequestDto | MaintenanceRequestListDto;
  workOrder?: HydratedWorkOrderDto | WorkOrderListDto;
  category?: Category;
}

interface WorkOrderSummaryProps {
  loading?: boolean;
  workOrder?: HydratedWorkOrderDto | WorkOrderListDto;
  category?: Category;
}

const WorkOrderSummary: Component<WorkOrderSummaryProps> = (props) => {
  const { t } = useLocalization();

  const [showConvertToWorkOrder, setShowConvertToWorkOrder] = createSignal<boolean>(false);

  const ConvertToWorkorder = () => {
    return (
      <>
        <Show when={!props.workOrder}>
          <div class="my-0.5 text-center text-xs text-text-level03">{t('Click the button below to convert')}</div>
          <div class="h-px w-full bg-input-border" />
          <Button
            onClick={() => setShowConvertToWorkOrder(true)}
            variant="outlined"
            class="mx-auto max-w-fit text-base font-medium"
            color="primary">
            {t('Convert to work order')}
          </Button>
        </Show>
      </>
    );
  };

  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <Show when={!props.loading} fallback={<LoadingSkeleton extraFields={1} />}>
        <div class="flex flex-col gap-2 px-5 py-4">
          <div class="flex items-center gap-1 text-lg font-semibold text-title-gray">
            {props.workOrder?.title ?? t('Work order')}
            <MaintenanceRequestStatus status={props.workOrder?.status} />
          </div>
          <Show when={!!props.workOrder?.updated}>
            <div class="flex items-center gap-1 text-xs text-text-level03">
              {t('Last update')}:<span>{dateFormat(t('MM/DD/YYYY hh:mm A'), props.workOrder?.updated ?? emptyPlaceholder)}</span>
            </div>
          </Show>
        </div>
        <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
          <Show when={props.workOrder} fallback={<ConvertToWorkorder />}>
            <IconField name={t('Reference number')} value={<div>#{props.workOrder?.reference || emptyPlaceholder}</div>} src={IconHash} />
            <IconField name={t('Created at')} value={dateFormat(t('MM/DD/YYYY hh:mm A'), props.workOrder?.created)} src={IconClock} />
            <IconField name={t('Category')} value={props.category?.name || emptyPlaceholder} src={IconMessageSquareText} />

            <div class="h-px w-full bg-input-border" />

            <A
              href={`/maintenance/work-orders/${props.workOrder?.id}`}
              class="mx-auto flex cursor-pointer items-center gap-2 text-sm capitalize text-link">
              {t('Work order')}
              <IconViewArrow class="size-5" />
            </A>

            <Show when={props.workOrder?.chatId || props.workOrder?.groupChatId}>
              <LinkButton
                href={`/maintenance/work-orders/${props.workOrder?.id}/communications`}
                variant="outlined"
                class="flex w-full items-center justify-center text-base font-medium"
                color="primary">
                <IconMessage class="size-5" />
                {t('Work order chat')}
              </LinkButton>
            </Show>
          </Show>
        </div>
      </Show>

      <ConvertToWorkOrderModal visible={[showConvertToWorkOrder, setShowConvertToWorkOrder]} />
    </div>
  );
};

const MaintenanceRequestSummary: Component<MaintenanceRequestSummaryProps> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
      <Show when={!props.loading} fallback={<LoadingSkeleton />}>
        <div class="flex flex-col gap-2 px-5 py-4">
          <div class="flex items-center gap-1 text-lg font-semibold text-title-gray">
            {props.maintenanceRequest?.title ?? t('Maintenance request')}
            <MaintenanceRequestStatus status={props.maintenanceRequest?.status} />
          </div>
          <Show when={!!(props.maintenanceRequest as MaintenanceRequestListDto).lastUpdated}>
            <div class="flex items-center gap-1 text-xs text-text-level03">
              {t('Last update')}:
              <span>
                {dateFormat(
                  t('MM/DD/YYYY hh:mm A'),
                  (props.maintenanceRequest as MaintenanceRequestListDto).lastUpdated ?? emptyPlaceholder
                )}
              </span>
            </div>
          </Show>
        </div>
        <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
          <IconField name={t('Reference number')} value={`#${props.workOrder?.reference}`} src={IconHash} />
          <IconField
            name={t('Created at')}
            value={dateFormat(t('MM/DD/YYYY hh:mm A'), props.maintenanceRequest?.created)}
            src={IconClock}
          />
          <IconField name={t('Category')} value={props.category?.name || emptyPlaceholder} src={IconMessageSquareText} />

          <Show when={props.workOrder}>
            <A
              href={`/maintenance/maintenance-request/${props.maintenanceRequest?.id}`}
              class="mx-auto flex cursor-pointer items-center gap-2 text-sm capitalize text-link">
              {t('Maintenance request')}
              <IconViewArrow class="size-5" />
            </A>
          </Show>

          <Show when={props.maintenanceRequest?.chatId}>
            <LinkButton
              href={`/maintenance/maintenance-request/${props.maintenanceRequest?.id}/communications`}
              variant="outlined"
              class="flex w-full items-center justify-center text-base font-medium"
              color="primary">
              <IconMessage class="size-5" />
              {t('Maintenance chat')}
            </LinkButton>
          </Show>
        </div>
      </Show>
    </div>
  );
};

export const MaintenanceWorkOrderSummary: Component<MaintenanceWorkOrderSummaryProps> = (props) => {
  const [category, setCategoryId] = createTriggerResource(
    maintenanceRequestCategoryRepository.getCategory.bind(maintenanceRequestCategoryRepository)
  );

  createEffect(() => {
    const categoryId = props.maintenanceRequest?.categoryId;

    if (categoryId) {
      setCategoryId(categoryId);
    }
  });

  return (
    <Show
      when={props.currentPage === 'maintenanceRequest'}
      fallback={
        <MaintenanceRequestSummary
          loading={props.loading}
          maintenanceRequest={props.maintenanceRequest}
          workOrder={props.workOrder}
          category={category()}
        />
      }>
      <WorkOrderSummary loading={props.loading} workOrder={props.workOrder} category={category()} />
    </Show>
  );
};
