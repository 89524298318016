import { checkMimeType } from '@magicdoor/file';
import { createMemo, Show } from 'solid-js';
import IconFile from '~/assets/images/chat/chatFile.png';
import { RingLoader } from '~/components/common/Loaders';
import Tooltip from '~/components/common/Tooltip';
import { FilePreviewModal, getPreviewURL } from '~/components/file-attachments';
import { IconCloudUpload, IconSearch, IconX, Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { imageType } from '~/utils/constant';
import { decodeFileName } from '~/utils/file';
import { DeleteStatus, UploadStatus } from './Interface';
import { useUpload } from './context';
import { hydrateFileToPreviewFile } from './utils';
import type { HydrateFile } from './Interface';
import type { JSX } from 'solid-js';

const FileIcon = () => {
  const { t } = useLocalization();
  return (
    <div class="relative">
      <img src={IconFile} alt="IconFile" class="size-16 object-contain" />
      <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-bold text-white">{t('File')}</div>
    </div>
  );
};

const ImageThumbnail = (props: { file: HydrateFile; class?: string }) => {
  const fileToPreview = createMemo(() => hydrateFileToPreviewFile(props.file));
  return <Image src={getPreviewURL(fileToPreview(), true)} class={cn('size-16 object-cover', props.class)} alt={props.file.fileName} />;
};

const Operation = (props: { file: HydrateFile; onPreview?: () => void }) => {
  const { t } = useLocalization();
  const { onFileRetry, setPreview, preview } = useUpload();

  const fileToPreview = createMemo(() => hydrateFileToPreviewFile(props.file));

  const isMutation = createMemo(() => {
    return props.file.uploaded === UploadStatus.UPLOADING || props.file.deleted === DeleteStatus.DELETING;
  });

  const handlePreview = () => {
    if (props.onPreview) return props.onPreview();
    setPreview(fileToPreview());
  };

  const fileStatus = createMemo(() => {
    if (props.file.uploaded === UploadStatus.FAILED) {
      return (
        <Tooltip message={t('Retry')}>
          <IconCloudUpload class="size-6 text-error" onClick={() => onFileRetry(props.file)} />
        </Tooltip>
      );
    }

    if (isMutation()) {
      return <RingLoader class="size-6 animate-spin text-primary" />;
    }

    return (
      <Tooltip message={t('Preview')}>
        <IconSearch class="size-6 text-white" onClick={handlePreview} />
      </Tooltip>
    );
  });

  return (
    <div
      class={cn('absolute inset-0 z-10 hidden items-center justify-center bg-black/20 group-hover:flex', {
        flex: isMutation(),
      })}>
      <button type="button" onClick={handlePreview}>
        {fileStatus()}
      </button>

      <FilePreviewModal file={preview()} onClose={() => setPreview(undefined)} />
    </div>
  );
};

const Wrapper = (props: { children: JSX.Element; class?: string } & JSX.HTMLAttributes<HTMLDivElement>) => {
  return <div {...props} class={cn('group', props.class)} />;
};

const _FileItem = (props: { file: HydrateFile; onClean?: () => void; class?: string }) => {
  const { onFileRemove } = useUpload();

  const isImage = createMemo(() => {
    if (props.file.fileName.endsWith('.tif')) return false;
    if (props.file.originalFile) {
      return checkMimeType(props.file.originalFile, 'image/*');
    }
    return imageType.some((type) => props.file.fileName?.endsWith(type));
  });

  return (
    <Wrapper class={cn('relative z-0 w-28 shrink-0 cursor-pointer overflow-hidden rounded-lg border', props.class)}>
      <button
        type="button"
        class="absolute right-1 top-1 z-20 rounded-full bg-black/30 p-1 text-white transition-colors hover-allowed:hover:bg-black/60"
        onClick={() => {
          if (props.file.deleted === DeleteStatus.DELETING) {
            return;
          }
          onFileRemove(props.file);
          props.onClean?.();
        }}>
        <IconX class="size-4" />
      </button>

      <Operation file={props.file} />

      <div class="flex h-28 min-w-0 flex-col items-center justify-between p-2 transition">
        <Show when={isImage()} fallback={<FileIcon />}>
          <ImageThumbnail file={props.file} />
        </Show>
        <span
          class={cn('w-full truncate text-center text-xs text-text-level02', {
            'text-error': props.file.uploaded === UploadStatus.FAILED,
          })}>
          {decodeFileName(props.file.fileName)}
        </span>
      </div>
    </Wrapper>
  );
};

export const FileItem = Object.assign(_FileItem, {
  Operation,
  ImageThumbnail,
  Wrapper,
}) as typeof _FileItem & { Operation: typeof Operation; ImageThumbnail: typeof ImageThumbnail; Wrapper: typeof Wrapper };
