import { For } from 'solid-js';
import IconAutofillFiled from '~/assets/images/lease-template/autofill.svg';
import IconCheckBoxFiled from '~/assets/images/lease-template/checkBoxFiled.svg';
import IconDateField from '~/assets/images/lease-template/dateFiled.svg';
import IconInitialField from '~/assets/images/lease-template/initialFeild.svg';
import IconSignatureField from '~/assets/images/lease-template/signatureField.svg';
import IconTextFiled from '~/assets/images/lease-template/textFiled.svg';
import { TintedSVG } from '~/components/common/TintedSvg';
import { useLocalization } from '~/contexts/global';
import useUseCase from '~/framework/hooks/useUseCase';
import { UpdateLeaseTemplateUseCase } from '~/pdfsigner/usecases/pdfEditing/updateLeaseTemplateUseCase';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { cn } from '~/utils/classnames';
export interface DataFieldsSelectorProps {
  selectedAnnotationType?: AnnotationType;
  onChangeSelectedAnnotationType: (value: AnnotationType) => void;
}

export const DataFieldsSelector = (props: DataFieldsSelectorProps) => {
  const { execute: updateLeaseTemplate } = useUseCase(UpdateLeaseTemplateUseCase);

  const { t } = useLocalization();

  const dataFieldGroups = [
    {
      name: t('Annotations'),
      options: [
        { label: t('Name'), icon: IconInitialField, value: AnnotationType.NAME },
        { label: t('Initials'), icon: IconInitialField, value: AnnotationType.INITIALS },
        { label: t('Signature'), icon: IconSignatureField, value: AnnotationType.SIGNATURE },
        { label: t('Date'), icon: IconDateField, value: AnnotationType.DATE },
        { label: t('Autofill'), icon: IconAutofillFiled, value: AnnotationType.AUTOFILL },
      ],
    },
    {
      name: t('Modify document'),
      options: [
        { label: t('Text'), icon: IconTextFiled, value: AnnotationType.TEXT },
        { label: t('Check mark'), icon: IconCheckBoxFiled, value: AnnotationType.CHECKMARK },
      ],
    },
  ];

  return (
    <div class="flex flex-col gap-2">
      <For each={dataFieldGroups}>
        {(group) => (
          <>
            <p class="mt-2 text-sm text-text-level03">{group.name}</p>
            <For each={group.options}>
              {(option) => (
                <div
                  class={cn(
                    'flex w-full cursor-pointer items-center gap-1.5 rounded-lg border border-input-border px-5 py-2.5 text-text-level02 transition-all hover-allowed:hover:border-primary',
                    { 'border-primary bg-primary text-white': props.selectedAnnotationType === option.value }
                  )}
                  onClick={() => {
                    props.onChangeSelectedAnnotationType(option.value);
                    option.value === 'autofill' && updateLeaseTemplate({ dataPathKey: 'unit/address' });
                  }}>
                  <TintedSVG svg={option.icon} color={props.selectedAnnotationType === option.value ? '#FFFFFF' : '#464F74'} />
                  <span class="text-sm text-inherit">{option.label}</span>
                </div>
              )}
            </For>
          </>
        )}
      </For>
    </div>
  );
};
