import { createEffect, createMemo, createSignal } from 'solid-js';
import type { JSX } from 'solid-js';

type SwitchProps = {
  name?: string;
  disabled?: boolean;
  values?: any[];
  checked?: any;
  onChange?: (v: any) => void;
  style?: JSX.CSSProperties;
};
const InputSwitch = (props: SwitchProps) => {
  const [checked, setChecked] = createSignal<any>(undefined);

  const values = createMemo<any[]>(() => props.values || [true, false]);

  const toggleSwitch = async () => {
    if (props.disabled) {
      return;
    }
    const flag = checked();
    const v = !flag ? values()[0] : values()[1];
    props.onChange && props.onChange(v);
    setChecked(v);
  };

  createEffect(() => {
    setChecked(props.checked);
  });
  return (
    <div
      class="relative flex h-6 w-11 shrink-0 cursor-pointer select-none rounded-full transition-colors"
      classList={{
        'cursor-not-allowed': props.disabled,
        'bg-essential-colour': checked(),
        'bg-[#EDF2F7]': !checked(),
      }}
      style={props.style}
      tabIndex="0"
      onClick={toggleSwitch}>
      <span
        class="absolute m-0.5 size-5 rounded-full bg-white transition-all"
        classList={{
          'left-0': !checked(),
          'left-5': checked(),
        }}
      />
      <input name={props.name} type="hidden" value={checked() ? values()[0] : values()[1]} />
    </div>
  );
};

export default InputSwitch;
