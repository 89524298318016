import { createSignal, createEffect } from 'solid-js';
import { Section } from '~/components/common/Section';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

const RateBadge: Component<{ class: string; label: string; count: number | false; loading: boolean }> = (props) => (
  <div
    class={`absolute left-1/2 top-2 flex size-14 flex-col items-center justify-center rounded-full bg-white shadow-md ${props.class}${
      props.loading ? 'animate-pulse' : ''
    }`}>
    <span class="text-sm font-semibold">{props.loading ? '' : props.count !== false ? `${props.count}%` : '0%'}</span>
    <span class="text-xxs text-text-level03">{props.label}</span>
  </div>
);

export const OccupancyRate: Component<{
  loading: boolean;
  portfolioOverview?: MagicDoor.Api.PortfolioOverviewDto;
}> = (props) => {
  const { t } = useLocalization();
  const [rate, setRate] = createSignal<number>();

  const angle = () => {
    const r = rate();
    return r == null ? -10 : (r / 100) * 180;
  };

  const active = () => {
    const r = rate();
    return r != null && Math.floor(r);
  };

  const vacancy = () => {
    const r = rate();
    return r != null && Math.ceil(100 - r);
  };

  createEffect(() => {
    const totalUnits = props?.portfolioOverview?.summary?.totalUnits ?? 0;
    const occupiedUnits = props?.portfolioOverview?.summary?.occupiedUnits ?? 0;
    if (!totalUnits || occupiedUnits == null) return setRate(undefined);
    setRate((occupiedUnits / totalUnits) * 100);
  });

  return (
    <Section title={t('Occupancy rate')} class="flex-1" contentClass="relative p-5">
      <RateBadge class="-translate-x-28 text-success" label={t('Active')} count={active()} loading={props.loading} />
      <RateBadge class="top-3 translate-x-14 text-text-level03" label={t('Vacancy')} count={vacancy()} loading={props.loading} />
      <svg class="mx-auto h-24" viewBox="0 0 100 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="40" stroke="#c5c1de" stroke-width="20" />
        <g mask="url(#shape)">
          <circle
            r="50"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="#fff"
            stroke-width="100"
            stroke-dasharray="314.1592653589793"
            stroke-dashoffset={((100 * 3.1415926) / 360) * (180 - angle() - 2)}
            style={{ transition: 'stroke-dashoffset 1s' }}
          />
          <circle
            r="50"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="#36CBAC"
            stroke-width="100"
            stroke-dasharray="314.1592653589793"
            stroke-dashoffset={((100 * 3.1415926) / 360) * (180 - angle())}
            style={{ transition: 'stroke-dashoffset 1s' }}
          />
        </g>
        <path
          d="M46.35 36c.26 0 .52.04.767.12l4.356 1.394a2.513 2.513 0 0 1 1.747 2.393v2.308l1.369-.87a1.508 1.508 0 0 1 2.04.4l.041.062c.154.242.236.523.236.81v6.378h.335a.503.503 0 0 1 0 1.005H43.503a.503.503 0 0 1 0-1.005h.335V38.513a2.513 2.513 0 0 1 2.431-2.512l.082-.001zm0 1.005a1.508 1.508 0 0 0-1.507 1.508v10.482h7.372v-9.088a1.508 1.508 0 0 0-.98-1.412l-.069-.024-4.356-1.394a1.508 1.508 0 0 0-.46-.072zm9.472 5.342a.503.503 0 0 0-.694-.155l-1.908 1.215v5.588h2.68v-6.379a.501.501 0 0 0-.05-.22l-.028-.05zm-8.969.951a.503.503 0 0 1 .503.503v1.675a.502.502 0 0 1-1.005 0v-1.675a.503.503 0 0 1 .502-.503zm0-4.02a.502.502 0 0 1 .503.502v1.675a.503.503 0 0 1-1.005 0V39.78a.502.502 0 0 1 .502-.503z"
          fill="#36CBAC"
        />
        <defs>
          <radialGradient id="grad">
            <stop offset=".6" stop-opacity="0" />
            <stop offset="1" stop-opacity=".3" />
          </radialGradient>
          <mask id="shape" style={{ 'mask-type': 'alpha' }}>
            <circle cx="50" cy="50" r="40" fill="url(#grad)" stroke="#000" stroke-width="20" />
            <path
              d="M50 74C63.2548 74 74 63.2548 74 50C74 36.7452 63.2548 26 50 26C36.7452 26 26 36.7452 26 50C26 63.2548 36.7452 74 50 74Z"
              stroke="#000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-dasharray="0.01 8"
            />
          </mask>
        </defs>
      </svg>
    </Section>
  );
};
