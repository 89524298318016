import { Show, For, createMemo, createSignal, createEffect } from 'solid-js';
import IconInformation from '~/assets/images/common/info.svg?component-solid';
import DisplayAddress from '~/components/common/DisplayAddress';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import Tooltip from '~/components/common/Tooltip';
import { PropertyImage } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { usePayVendorBills } from '~/contexts/local';
import { PayBillType } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder, paymentMethodOptions, paymentTypeOptions } from '~/utils/constant';
import type { BillPayment } from '~/contexts/local';

interface PropertiesSectionProps {
  sectionId: string;
  vendorId: string;
  bills: BillPayment[];
  isOtherSectionDragging: boolean;
  onSetDraggingSectionId: (sectionId: string) => void;
  onInsertPropertyIntoSection: (event: DragEvent, targetSectionId: string) => void;
}

export const PropertiesSection = (props: PropertiesSectionProps) => {
  const { t } = useLocalization();
  const { store, onUpdateBillPayments, getBankAccounts } = usePayVendorBills();
  const [isDragging, setIsDragging] = createSignal(false);
  const [isShowDropSpace, setIsShowDropSpace] = createSignal(false);
  const processedPaymentTypeOptions = createMemo(() => {
    return paymentTypeOptions(t).map((option) => {
      if (option.value === PayBillType.Ach && !store.vendorBankAccountsMap.get(props.vendorId)?.length) {
        return { ...option, disabled: true };
      }

      return { ...option };
    });
  });
  const commonData = createMemo(() => {
    const firstBill = props.bills.at(0);

    if (!firstBill) {
      return undefined;
    }

    return {
      paymentType: firstBill.paymentType,
      paymentBankAccountId: firstBill.paymentBankAccountId,
      paymentMethod: firstBill.paymentMethod,
      receivingBankAccountId: firstBill.receivingBankAccountId,
      externalTransactionId: firstBill.externalTransactionId,
      memo: firstBill.memo,
    };
  });

  const onUpdateCommonData = (key: keyof BillPayment, value: any) => {
    onUpdateBillPayments({ vendorId: props.vendorId, billIds: [...props.bills.map((bill) => bill.id)], payload: { [key]: value } });
  };

  const onDragEnterPlaceholder = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target) {
      return;
    }

    target.style.transform = 'scale(1.02)';
    target.style.boxShadow = '0 0 30px rgba(0, 0, 0, 0.1)';
  };

  const onDragLeavePlaceholder = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target) {
      return;
    }

    e.stopPropagation();
    target.style.transform = '';
    target.style.boxShadow = '';
  };

  const onDragEnterTable = () => {
    if (isDragging() || !props.isOtherSectionDragging) {
      return;
    }

    setIsShowDropSpace(true);
  };

  const onDragStart = (event: DragEvent, billId?: string) => {
    if (!event.dataTransfer) {
      return;
    }

    setIsDragging(true);
    props.onSetDraggingSectionId(props.sectionId);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', JSON.stringify({ vendorId: props.vendorId, billId, sourceSectionId: props.sectionId }));
  };

  const onDragEnd = () => {
    setIsDragging(false);
    props.onSetDraggingSectionId('');
  };

  const onDropProperty = (event: DragEvent) => {
    try {
      const data = event.dataTransfer?.getData('text') as string;
      const { vendorId, billId } = JSON.parse(data);

      if (vendorId !== props.vendorId) {
        return;
      }

      props.onInsertPropertyIntoSection(event, props.sectionId);

      if (!commonData()) {
        return;
      }

      onUpdateBillPayments({ vendorId, billIds: [billId], payload: { ...commonData() } });
    } catch {
      // do nothing...
    } finally {
      setIsShowDropSpace(false);
    }
  };

  const onDragLeaveTable = () => {
    setIsShowDropSpace(false);
  };

  // eslint-disable-next-line solid/reactivity
  createEffect(async () => {
    if (commonData()?.paymentType === PayBillType.Ach) {
      await getBankAccounts(true);
      if (!store.bankAccountOptions.some((bankAccount) => bankAccount.value === commonData()?.paymentBankAccountId)) {
        onUpdateCommonData('paymentBankAccountId', undefined);
      }
    } else {
      getBankAccounts(false);
    }
  });

  return (
    <div class="overflow-hidden rounded-lg border-input-border bg-white">
      <div class="border-b border-input-border bg-[#FAE9FF] p-3">
        <div class={cn('mb-4 grid grid-cols-3 gap-3', { 'grid-cols-2': commonData()?.paymentType === PayBillType.PrintCheck })}>
          <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
            <DropdownMenu
              labelJSX={
                <Tooltip message={t('The ACH option is disabled when the owner does not have a receiving account setup.')}>
                  <div class="flex items-center gap-1">
                    <span>{t('Payment Type')}</span>
                    <IconInformation class="text-text-level02" />
                  </div>
                </Tooltip>
              }
              labelClass="mb-0"
              buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
              placeholder={t('Please select')}
              disabled={!commonData()}
              value={commonData()?.paymentType}
              options={processedPaymentTypeOptions()}
              onChange={(value) => onUpdateCommonData('paymentType', value)}
            />
          </div>
          <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
            <DropdownMenu
              label={t('Payment Account')}
              labelClass="mb-0"
              buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
              disabled={!commonData() || store.bankAccountsLoading}
              value={commonData()?.paymentBankAccountId}
              options={store.bankAccountOptions ?? []}
              placeholder={t('Please select')}
              onChange={(value) => onUpdateCommonData('paymentBankAccountId', value)}
            />
          </div>

          <Show when={commonData()?.paymentType === PayBillType.Manual}>
            <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
              <DropdownMenu
                label={t('Payment Method')}
                class="!mb-0"
                labelClass="mb-0"
                buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
                disabled={!commonData()}
                value={commonData()?.paymentMethod}
                options={paymentMethodOptions(t)}
                placeholder={t('Please select')}
                onChange={(value) => onUpdateCommonData('paymentMethod', value)}
              />
            </div>
          </Show>
          <Show when={commonData()?.paymentType === PayBillType.Ach}>
            <div class={cn('col-span-1 rounded-lg bg-white px-3 pt-2.5', { 'bg-white/50': !commonData() })}>
              <DropdownMenu
                label={t('Receiving account')}
                class="!mb-0"
                labelClass="mb-0"
                buttonClass="border-none bg-transparent px-0 font-medium !ring-0"
                disabled={!commonData()}
                value={commonData()?.receivingBankAccountId}
                options={store.vendorBankAccountsMap.get(props.vendorId) ?? []}
                error={commonData()?.receivingBankAccountId ? undefined : t('Receiving account is required')}
                placeholder={t('Please select')}
                onChange={(value) => onUpdateCommonData('receivingBankAccountId', value)}
              />
            </div>
          </Show>
        </div>
        <div class="grid grid-cols-3 gap-3">
          <Show when={commonData()?.paymentType === PayBillType.Manual}>
            <div class="col-span-1">
              <LabeledTextInput
                label={t('External transaction ID')}
                class="!mb-0"
                inputContainerClass={cn('!border-none bg-white !ring-0', { 'bg-white/50': !commonData() })}
                placeholder={t('Enter transaction ID')}
                disabled={!commonData()}
                maxlength={1000}
                value={commonData()?.externalTransactionId}
                error={commonData()?.externalTransactionId ? undefined : t('External transaction ID is required')}
                required
                onInput={(value) => onUpdateCommonData('externalTransactionId', value)}
              />
            </div>
          </Show>
          <div class={cn('col-span-2', { 'col-span-3': commonData()?.paymentType !== PayBillType.Manual })}>
            <LabeledTextInput
              label={t('Memo')}
              class="!mb-0"
              inputContainerClass={cn('!border-none bg-white !ring-0', { 'bg-white/50': !commonData() })}
              placeholder={t('Enter memo')}
              disabled={!commonData()}
              maxlength={1000}
              value={commonData()?.memo}
              onInput={(value) => onUpdateCommonData('memo', value)}
            />
          </div>
        </div>
      </div>
      <div class="relative flex flex-col px-3 py-4" onDragEnter={onDragEnterTable} onDragLeave={onDragLeaveTable}>
        <Show
          when={props.bills.length}
          fallback={
            <div
              class="flex flex-col items-center gap-0.5 rounded-lg bg-input py-2.5 duration-150"
              onDragEnter={onDragEnterPlaceholder}
              onDragLeave={onDragLeavePlaceholder}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={onDropProperty}>
              <p class="text-xs font-medium text-title-gray">{t('No Property Yet')}</p>
              <p class="text-xs text-text-level03">{t('Drag Property Over Here')}</p>
            </div>
          }>
          <p class="mb-2 text-xs text-text-level03">{t('Properties')}</p>
          <div
            class="grid grid-cols-3 gap-3"
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragLeave={(event) => {
              event.stopPropagation();
            }}>
            <For each={props.bills}>
              {(bill) => (
                <div
                  draggable="true"
                  class="flex cursor-grab items-center gap-2 rounded-lg bg-input p-3"
                  onDragStart={(event) => onDragStart(event, bill.id)}
                  onDragEnd={onDragEnd}>
                  <Show when={bill.property}>
                    <PropertyImage class="size-9" property={bill.property as { id: string; imageId?: string }} />
                    <div class="flex flex-col gap-0.5">
                      <p class="text-xs font-medium text-title-gray">{bill.property?.name || emptyPlaceholder}</p>
                      <DisplayAddress class="font-normaltext-text-level03 text-xs" address={bill.property?.address} data-slot="address" />
                    </div>
                  </Show>
                </div>
              )}
            </For>
          </div>
        </Show>
        <Show when={isShowDropSpace()}>
          <div
            class="absolute inset-0 flex items-center justify-center rounded-lg bg-white/40 text-xs text-primary backdrop-blur-lg"
            onDragOver={(event) => {
              event.preventDefault();
            }}
            onDrop={onDropProperty}>
            {t('Drop the property to insert into this section')}
          </div>
        </Show>
      </div>
    </div>
  );
};
