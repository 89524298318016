import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { useBankAccountsList } from './BankAccountsListContext';
import type { BankAccountConnectType, ConnectBankAccountDto } from '~/swagger/Api';

export const [BankAccountProvider, useBankAccount] = createMagicDoorContext('BankAccount', () => {
  const bankAccountListContext = useBankAccountsList();

  const repo = new BankAccountRepository();
  const [bankAccount, setBankAccount] = createSignal<MagicDoor.Api.HydratedBankAccountDto | undefined>(undefined);
  const [bankAccountId, setBankAccountId] = createSignal<string | undefined>(undefined);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);
  const [bankAccountPlaid, setBankAccountPlaid] = createSignal<MagicDoor.Api.BankAccountConnectionsDto['plaid']>();
  const [bankAccountStripe, setBankAccountStripe] = createSignal<MagicDoor.Api.BankAccountConnectionsDto['stripe']>();
  const [connectionLoading, setConnectionLoading] = createSignal<BankAccountConnectType | boolean>(false);

  const fetchBankAccountById = async (bankAccountId: string | undefined) => {
    if (bankAccountId === undefined) return;
    try {
      setIsLoading(true);
      const data = await repo.getBankAccount(bankAccountId);
      setBankAccount(data);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const addBankAccount = async (bankAccountData: MagicDoor.Api.CreateBankAccountDto) => {
    setIsLoading(true);
    try {
      const newBankAccount = await repo.createBankAccount(bankAccountData);
      if (newBankAccount) {
        bankAccountListContext.onBankAccountAdded(newBankAccount);
        setBankAccount(newBankAccount);
      }
      return newBankAccount;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateBankAccount = async (
    bankAccountId: string,
    bankAccountData: MagicDoor.Api.UpdateBankAccountDto
  ): Promise<MagicDoor.Api.HydratedBankAccountDto | undefined> => {
    try {
      const updatedBankAccount = await repo.updateBankAccount(bankAccountId, bankAccountData);
      if (updatedBankAccount !== undefined) {
        setBankAccount(updatedBankAccount);
      }
      return updatedBankAccount;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    }
  };

  const deleteBankAccount = async (bankAccountId: string) => {
    setIsLoading(true);
    try {
      await repo.deleteBankAccount(bankAccountId);
      bankAccountListContext.onBankAccountDeleted(bankAccountId);
      setBankAccount(undefined);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const getBankAccountConnections = async (bankAccountId: string) => {
    try {
      const result = await repo.getBankAccountConnections(bankAccountId);
      setBankAccountPlaid(result.plaid);
      setBankAccountStripe(result.stripe);
      return result;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    }
  };

  const bankAccountConnections = async (
    connectParams: {
      bankAccountId: string;
      type: BankAccountConnectType;
    } & ConnectBankAccountDto,
    refresh?: boolean
  ) => {
    setConnectionLoading(connectParams.type);
    try {
      const result = await repo.bankAccountConnections(connectParams);

      if (result.link) {
        window.open(result.link, '_blank');
      }
      if (refresh) getBankAccountConnections(connectParams.bankAccountId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setConnectionLoading(false);
    }
  };

  const plaidSweeps = async (bankAccountId: string) => {
    setIsLoading(true);
    try {
      await repo.plaidSweeps(bankAccountId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const getSweepsLimits = async () => {
    try {
      const result = await repo.getSweepsLimits();
      return result;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    }
  };

  return {
    addBankAccount,
    updateBankAccount,
    deleteBankAccount,
    fetchBankAccountById,
    setBankAccountId,
    bankAccount,
    isLoading,
    error,
    getBankAccountConnections,
    bankAccountPlaid,
    bankAccountStripe,
    connectionLoading,
    bankAccountConnections,
    bankAccountId,
    setBankAccount,
    plaidSweeps,
    getSweepsLimits,
  };
});
