export function isImage(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType?.startsWith('image/');
}

export function isSvg(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType === 'image/svg+xml';
}

export function isVideo(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType?.startsWith('video/') || fileType?.startsWith('Video/');
}

export function isAudio(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType?.startsWith('audio/');
}

export function isPdf(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType === 'application/pdf';
}

export function isText(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType === 'text/plain' || fileType === 'text/csv';
}

export function isSpreadsheet(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return (
    fileType !== null &&
    ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(fileType)
  );
}

export function isPowerpoint(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return (
    fileType !== null &&
    ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(fileType)
  );
}

export function isWord(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return (
    fileType !== null &&
    ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(fileType)
  );
}

export function isZip(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  const archiveTypes = [
    'application/zip',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
    'application/x-tar',
    'application/gzip',
    'application/x-zip-compressed',
  ];
  return fileType !== null && archiveTypes.includes(fileType);
}

export function isCode(file: File | MagicDoor.Api.ChatMessageFileDto) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  const codeMimeTypes = ['text/html', 'text/css', 'text/javascript', 'application/json', 'application/xml', 'application/x-python'];
  return fileType !== null && codeMimeTypes.includes(fileType);
}

export function isFile(file: File | MagicDoor.Api.ChatMessageFileDto) {
  return (
    !isImage(file) &&
    !isVideo(file) &&
    !isAudio(file) &&
    !isPdf(file) &&
    !isText(file) &&
    !isSpreadsheet(file) &&
    !isPowerpoint(file) &&
    !isWord(file) &&
    !isZip(file) &&
    !isCode(file)
  );
}

export function isOtherFiles(files: File[] | MagicDoor.Api.ChatMessageFileDto[]) {
  return files.some((file) => !isImage(file) && !isAudio(file) && !isVideo(file) && !isSvg(file));
}

export function isMediaFiles(files: File[] | MagicDoor.Api.ChatMessageFileDto[]) {
  return files.some((file) => isImage(file) || isVideo(file) || isSvg(file));
}

export function isFileType(file: File | MagicDoor.Api.ChatMessageFileDto, type: string) {
  const fileType = 'contentType' in file ? file.contentType : file.type;
  return fileType === type;
}

export const createMockFileObject = (fileDto: MagicDoor.Api.ChatMessageFileDto) => ({
  type: fileDto.contentType,
  name: fileDto.fileName,
});

export function formatFileSize(bytes: number, decimalPoint = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimalPoint < 0 ? 0 : decimalPoint;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getFileSuffix(fileName: string): string {
  return fileName?.split('.').pop()?.toLowerCase() || '';
}

export function getFileName(fileName: string): string {
  return fileName.substring(0, fileName.lastIndexOf('.'));
}

export function getFileAllUrl(fileUrl: string): string {
  return `/api${fileUrl}`;
}

export const getDownloadUrl = (fileUrl: string) => {
  let url = fileUrl;
  if (url.startsWith('/api')) {
    url = url.replace('/api', '');
  }
  return `/bff/download${url}`;
};

export const downloadFile = (fileUrl: string, fileName?: string) => {
  const a = document.createElement('a');
  const downloadUrl = getDownloadUrl(fileUrl);
  const urlParts = downloadUrl.split('/');
  urlParts[urlParts.length - 1] = encodeURIComponent(urlParts[urlParts.length - 1]);
  a.href = urlParts.join('/');
  a.download = fileName || fileUrl.split('/').pop() || 'unknown';
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const saveAsFile = async (fileUrl: string, customFileName?: string) => {
  if (!fileUrl) return;

  try {
    const res = await fetch(fileUrl);
    if (!res.ok) throw new Error(`Fetch failed: ${res.status} ${res.statusText}`);
    const blob = await res.blob();
    const url = URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
    const link = document.createElement('a');
    link.href = url;
    link.download = customFileName || fileUrl.split('/').pop() || 'unknown';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Download error:', error);
  }
};

export function getImageUrl(
  type: 'avatar' | 'property' | 'unit',
  entryId: string,
  imageId?: string | undefined | null,
  isNormal?: boolean | undefined
): string {
  if (type === 'avatar') return `/bff/images/property-managers/${entryId}/image.webp${imageId ? `?v=${imageId}` : ''}`;
  if (type === 'property')
    return `/bff/images/properties/${entryId}/${isNormal ? 'image' : 'image_thumb'}.webp${imageId ? `?v=${imageId}` : ''}`;
  if (type === 'unit') return `/bff/images/units/${entryId}/${isNormal ? 'image' : 'image_thumb'}.webp${imageId ? `?v=${imageId}` : ''}`;
  throw new Error('Invalid type');
}

export function decodeFileName(fileName: string): string {
  try {
    return decodeURIComponent(fileName);
  } catch (e) {
    console.error('Failed to decode fileName:', e);
    return fileName;
  }
}
export const imageFileAccept = `image/jpeg,image/png,image/gif,image/webp`;
export const proofFileAccept = `${imageFileAccept},application/pdf`;

export const documentFileAccept = `text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;

export const maintenanceRequestFileAccept = `video/*,${proofFileAccept},${documentFileAccept}`;

export const mimeTypeMap = {
  'image/jpeg': 'jpg,jpeg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/rtf': 'rtf',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-powerpoint': 'ppt',
  'video/*': 'mp4,webm',
  'text/plain': 'txt',
};

export const acceptStringToFileSuffix = (accept?: string) => {
  if (!accept || accept === '*') return '';
  const suffixes = accept.split(',').map((suffix) => {
    const matchedFiles = mimeTypeMap[suffix as keyof typeof mimeTypeMap];
    if (!matchedFiles) return '';
    return matchedFiles
      .split(',')
      .map((file) => `.${file}`)
      .join(', ');
  });
  return suffixes.join(', ');
};
