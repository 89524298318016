import { Form, FormItem } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { PaymentAmount } from '~/components/common/PaymentAmount';
import { useLocalization } from '~/contexts/global';
import { MAX_INPUT_AMOUNT_2, paymentMethodOptions } from '~/utils/constant';
import type { Component, Setter } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';

interface ReceivePaymentModalProps {
  visible?: Setter<boolean>[];
  rentalApplicationId?: string;
  form?: BetterForm.form;
}

export const ReceivePaymentModal: Component<ReceivePaymentModalProps> = (props) => {
  const { t } = useLocalization();
  const MIN_AMOUNT = 1;

  return (
    <div>
      <Form defaultForm={props.form} class="grid grid-cols-2 gap-5 py-6">
        <FormItem
          class="col-span-full sm:col-span-1"
          formFieldName="amount"
          label={t('Amount')}
          component={LabeledNumberInput}
          placeholder={t('Please enter')}
          isBlur={false}
          min={MIN_AMOUNT}
          max={MAX_INPUT_AMOUNT_2}
          step={0.01}
          prepend="$"
          rules={[{ required: true, message: t('Please enter') }]}
        />
        <FormItem
          formFieldName="paymentMethod"
          label={t('Payment method')}
          options={paymentMethodOptions(t)}
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
          class="col-span-full sm:col-span-1"
          rules={[{ required: true, message: t('Please select') }]}
        />
        <FormItem
          class="col-span-full sm:col-span-1"
          formFieldName="externalTransactionId"
          label={t('External transaction ID')}
          rules={[{ required: true, message: t('External transaction ID is required') }]}
          component={LabeledTextInput}
          maxlength={150}
          placeholder={t('Please enter')}
        />
        <FormItem
          class="col-span-full sm:col-span-1"
          formFieldName="memo"
          label={t('Memo')}
          component={LabeledTextInput}
          placeholder={t('Please enter')}
        />
      </Form>
      <PaymentAmount
        label={t('Total amount')}
        mount={props.form?.formStore.amount}
        min={MIN_AMOUNT}
        max={MAX_INPUT_AMOUNT_2}
        class="-mx-5 w-auto"
      />
    </div>
  );
};
