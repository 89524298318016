import { useNavigate } from '@solidjs/router';
import { Show, createMemo } from 'solid-js';
import IconEdit from '~/assets/images/maintenance/edit.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { IconCirclePlus, Table, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { maintenanceRequestCategoryRepository } from '~/repositories/categoryRepository';

export const CategoriesListPage = () => {
  const { t } = useLocalization();
  const { categories, categoriesActions, setupDefaultCategories } = useMaintenanceRequests();
  const navigate = useNavigate();

  const showInitialCategories = createMemo(() => {
    return !categories.loading && categories()?.length === 0;
  });

  return (
    <Panel
      title="Categories"
      actions={
        <div class="flex gap-4">
          <Show when={showInitialCategories()}>
            <Button variant="outlined" onClick={() => setupDefaultCategories()} loading={setupDefaultCategories.loading}>
              {t('Set up default categories')}
            </Button>
          </Show>
          <Button onClick={() => navigate('./add')}>
            <IconCirclePlus size={16} />
            {t('Add a new category')}
          </Button>
        </div>
      }>
      <Table
        loading={categories.loading}
        columns={[
          {
            title: 'Name',
            render: (item) => item.name,
          },
          {
            title: 'Description',
            render: (item) => item.description,
          },
        ]}
        data={categories()}
        actions={(item) => [
          {
            label: 'Edit',
            icon: IconEdit,
            onClick: () => {
              navigate(`./edit/${item.id}`);
            },
          },
          {
            label: 'Delete',
            icon: IconTrash,
            onClick: async () => {
              await maintenanceRequestCategoryRepository.deleteCategory(item.id);
              toast.success(t('Category deleted successfully'));
              categoriesActions.refetch();
            },
          },
        ]}
      />
    </Panel>
  );
};
