import { A, useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { Section } from '~/components/common/Section';
import { FilePreviewModal } from '~/components/file-attachments/FilePreviewModal';
import { FileTitle } from '~/components/file-attachments/FileTitle';
import { PropertyTitle, PropertyType } from '~/components/properties';
import { TransactionStatus } from '~/components/transactions';
import { IconCircleChevronRight, Table } from '~/components/ui';
import { ProgressPieChart } from '~/components/ui/PieChart/ProgressPieChart';
import { useLocalization } from '~/contexts/global';
import { useAttachments, useOwners, useOwnerTransfers } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency, percentFormat } from '~/utils/number';
import type { Component } from 'solid-js';

const Property: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();
  const { current } = useOwners();
  const properties = createMemo(() => current()?.properties || []);
  const ownerProperties = createMemo(() => current()?.ownerProperties || []);

  return (
    <Section
      title={t('Property')}
      class={props.class}
      titleClass="border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading}
        class="relative"
        data={properties()}
        rowLink={(item) => `/portfolios/${item.portfolioId}/properties/${item.id}`}
        columns={[
          {
            title: t('Property'),
            headerClass: 'w-32',
            render: (item) => <PropertyTitle property={item} class="[&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" />,
          },
          {
            title: t('Type'),
            headerClass: 'w-32',
            render: (item) => <PropertyType type={item.type} />,
          },
          {
            title: t('OwnershipPercentage'),
            headerClass: 'w-40',
            render: (item) => {
              const ownerProperty = createMemo(() => ownerProperties().find((owner) => owner.propertyId === item.id));

              return (
                <Show when={ownerProperty()} fallback={emptyPlaceholder}>
                  <div class="flex items-center gap-2">
                    <ProgressPieChart
                      strokeWidth={4}
                      size={16}
                      progress={ownerProperty()?.ownershipPercentage ? ownerProperty()!.ownershipPercentage * 100 : 0}
                    />
                    <span>{ownerProperty() ? percentFormat(ownerProperty()!.ownershipPercentage) : emptyPlaceholder}</span>
                  </div>
                </Show>
              );
            },
          },
        ]}
      />
    </Section>
  );
};

const LatestContributions: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();
  const { ownerTransfers, setFilter } = useOwnerTransfers();
  const params = useParams<{ ownerId: string }>();
  const getProperty = (propertyId?: string) => {
    return ownerTransfers()?.properties?.find((property) => property.id === propertyId);
  };

  createEffect(() => {
    setFilter({
      ownerId: params.ownerId,
      type: 'contribution',
      page: 1,
      pageSize: 5,
    });
  });

  return (
    <Section
      title={
        <>
          {t('Latest Contributions')}
          <A href={`/accounting/owner-funds/contributions`} class="flex items-center gap-1 text-xs font-normal text-link">
            {t('View all')}
            <IconCircleChevronRight class="size-3.5 text-link" />
          </A>
        </>
      }
      class={props.class}
      titleClass="flex justify-between border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        data={ownerTransfers()?.items ?? []}
        columns={[
          {
            title: t('Date'),
            render: (item) => dateFormat('MM/DD/YYYY', item.created),
          },
          {
            title: t('Status'),
            render: (item) => (
              <Show
                when={ownerTransfers()?.transactions?.find((transaction) => transaction.id === item.transactionId)}
                fallback={emptyPlaceholder}>
                <TransactionStatus
                  status={ownerTransfers()?.transactions?.find((transaction) => transaction.id === item.transactionId)?.status}
                />
              </Show>
            ),
          },
          {
            title: t('Property'),
            headerClass: 'w-[30%]',
            render: (item) => (
              <div class="overflow-hidden">
                <Show when={getProperty(item.propertyId)} fallback={emptyPlaceholder}>
                  <PropertyTitle class="[&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" property={getProperty(item.propertyId)} />
                </Show>
              </div>
            ),
          },
          {
            title: t('Memo'),
            render: (item) => ownerTransfers()?.bills?.find((bill) => bill.id === item.billId)?.memo ?? emptyPlaceholder,
          },
          {
            title: t('Amount'),
            render: (item) => (item.amount ? currency(item.amount) : emptyPlaceholder),
          },
        ]}
      />
    </Section>
  );
};

const Files: Component<{ class?: string; loading?: boolean; owner?: MagicDoor.Api.OwnerDto }> = (props) => {
  const { t } = useLocalization();
  const { attachments, getAttachments } = useAttachments();
  const [preview, setPreview] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto>();

  createEffect(() => props.owner && getAttachments('owner', props.owner.id));

  return (
    <Section
      title={t('Files')}
      class={props.class}
      titleClass="flex justify-between border-0"
      contentClass="thinscroll overflow-x-auto whitespace-nowrap text-text-level01">
      <Table
        loading={props.loading || attachments.loading}
        data={[]}
        onRowClick={setPreview}
        columns={[
          {
            title: t('Type'),
            render: (item) => <FileTitle file={item} />,
          },
          {
            title: t('From'),
            class: 'space-y-1 text-xs',
            render: () => (
              <>
                <p class="text-text-level03">
                  {t('by')} {props.owner?.firstName}
                </p>
              </>
            ),
          },
        ]}
      />
      <FilePreviewModal file={preview()} onClose={setPreview} />
    </Section>
  );
};

export const OwnerOverviewTab = () => {
  const { current } = useOwners();
  return (
    <div class="grid gap-5 lg:grid-cols-2">
      <Property class="relative col-span-full" loading={current.loading} owner={current()} />
      <LatestContributions class="relative col-span-full" loading={current.loading} owner={current()} />
      <Files class="relative col-span-full" loading={current.loading} owner={current()} />
    </div>
  );
};
