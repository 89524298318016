import { Route, Routes, useMatch } from '@solidjs/router';
import { createMemo, onCleanup, onMount, Show } from 'solid-js';
import { Form, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { useLocalization, useNewRentalApplication } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { OnlinePayment } from '~/pages/rental-applications/add-rental-application/OnlinePayment';
import { PaymentStatus } from '~/pages/rental-applications/add-rental-application/PaymentStatus';
import { SelectOnlinePaymentOrSkipPayment } from '~/pages/rental-applications/add-rental-application/SelectOnlinePaymentOrSkippayment';
import { StepsBeforePayment } from '~/pages/rental-applications/add-rental-application/StepsBeforePayment';

export const NewRentalApplication = () => {
  const { t } = useLocalization();
  const match = useMatch(() => `/leasing/rental-applications/new/:applicationId/*`);
  const goBack = useGoBack();
  const { rentalApplication, autoNavigateByStatus, resetState, initState } = useNewRentalApplication();

  const form = useForm({
    initialValues: {
      unit: [{}],
    },
  });

  onMount(() => {
    const params = match()?.params;
    initState(params?.applicationId);
  });

  onCleanup(() => {
    resetState();
  });

  const showLoading = createMemo(() => match()?.params?.applicationId && (!rentalApplication() || autoNavigateByStatus.loading));

  return (
    <div class="flex size-full flex-col">
      <Form defaultForm={form} validateTriggers={['onChange']}>
        <div class="flex size-full flex-col">
          <Breadcrumb
            backLink={() => goBack()}
            items={[{ label: t('Rental applications'), link: '/leasing/rental-applications' }, { label: t('New rental application') }]}
          />
          <Panel class="relative m-8 flex h-section1 flex-col" title={t('New rental application')}>
            <Show
              when={!showLoading()}
              fallback={<RingLoader color={'#A126EC'} class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}>
              <Routes>
                <Route path="/:applicationId/status/:paymentStatus" component={PaymentStatus} />
                <Route path="/:applicationId/payment" component={OnlinePayment} />
                <Route path="/:applicationId/select-payment" component={SelectOnlinePaymentOrSkipPayment} />
                <Route path={['/:applicationId/:step', '*']} component={StepsBeforePayment} />
              </Routes>
            </Show>
          </Panel>
        </div>
      </Form>
    </div>
  );
};
