import { createEffect, createSignal, on } from 'solid-js';
import { Form, FormItem } from '~/components/common/BetterForm';
import { useForm } from '~/components/common/BetterForm/context';
import { BaseModal } from '~/components/modals/BaseModal';
import { Footer } from '~/components/modals/Footer';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { currentUpdateType } from '~/utils/constant';
import { RentalApplicationDocuments } from './DocumentsForm';
export const DocumentsModal = () => {
  const { applicationStore, setApplicationStore, fetchApplication, baseUpdate } = useRentalApplication();
  const { t } = useLocalization();
  const form = useForm();

  const [loading, setLoading] = createSignal<boolean>(false);
  const [disabled, setDisabled] = createSignal<boolean>(false);

  function onClose() {
    setApplicationStore('currentUpdateType', null);
    fetchApplication();
  }

  const submit = async () => {
    try {
      setLoading(true);
      await baseUpdate({
        files: form.formStore.files ?? [],
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  createEffect(
    on(
      () => applicationStore.application?.files,
      () => {
        form.setFieldValue('files', applicationStore.application?.files);
      }
    )
  );

  return (
    <BaseModal
      class="w-[820px!important]"
      title={t('Update documents')}
      visible={applicationStore.currentUpdateType === currentUpdateType.document}
      onClose={() => {
        setApplicationStore('currentUpdateType', null);
      }}>
      <div class="thinscroll flex max-h-[50vh] flex-col gap-2 overflow-y-auto p-4">
        <Form defaultForm={form}>
          <FormItem
            formFieldName="files"
            component={RentalApplicationDocuments}
            beforeUpload={() => setDisabled(true)}
            afterUpload={() => setDisabled(false)}
          />
        </Form>
      </div>
      <Footer
        okProps={{
          loading: loading(),
          disabled: disabled(),
        }}
        onOk={submit}
        onCancel={onClose}
      />
    </BaseModal>
  );
};
