import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, onMount, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { RingLoader } from '~/components/common/Loaders';
import SectionedTableView from '~/components/common/SectionedTableView';
import { Modal } from '~/components/modals/Modal';
import { IconCirclePlus } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { usePdfPrinter } from '~/hooks/usePdfPrinter';
import TaxYearHeader from '~/pages/vendors/components/TaxYearHeader';
import TaxDocumentsCell from '~/pages/vendors/components/VendorTaxDocumentsCell';
import { YearlyVendorTaxDocPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { CheckIfTaxDocumentsCanBeGenerated } from '~/pdfsigner/usecases/taxes/checkIfTaxDocumentsCanBeGenerated';
import { GetNextVendorTaxDocumentBatchUseCase } from '~/pdfsigner/usecases/taxes/vendor/getNextVendorTaxDocumentBatchUseCase';
import { GetVendorTaxDocumentsUseCase } from '~/pdfsigner/usecases/taxes/vendor/getVendorTaxDocumentsUseCase';
import { GetVendorTaxAmountsUseCase } from '~/pdfsigner/usecases/taxes/vendor/getVendorsTaxAmountsUseCase';
import { PrintVendorTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/vendor/printVendorTaxDocsUseCase';
import { RegenerateVendorTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/vendor/regenerateVendorTaxDocsUseCase';
import { TaxEntityTypes } from '~/pdfsigner/usecases/types/taxDocumentRequests';
import { VendorTaxAmountsPage } from './VendorTaxAmountsPage';
import type { PresentableVendorTaxDocument } from '~/pdfsigner/ui/types/presentableTaxDocs';
import type {
  GetTaxDocumentsRequest,
  NextTaxDocumentBatchRequest,
  TaxDocRegenerationRequest,
  TaxYearDocumentRequest,
} from '~/pdfsigner/usecases/types/taxDocumentRequests';

export const VendorTaxDocumentsPage = () => {
  const { t } = useLocalization();
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const { printPdf, isPrinting } = usePdfPrinter();
  const { execute: getTaxDocuments, isLoading } = useUseCase(GetVendorTaxDocumentsUseCase);
  const { execute: getNextTaxDocBatch } = useUseCase(GetNextVendorTaxDocumentBatchUseCase);
  const {
    execute: regenerateTaxDoc,
    isLoading: isRegenerating,
    didSucceed: didRegenerateTaxDoc,
  } = useUseCase(RegenerateVendorTaxDocsUseCase);
  const { execute: printTaxDocs } = useUseCase(PrintVendorTaxDocsUseCase);
  const { execute: checkIfCanGenerateTaxDocs, didSucceed: canGenerateTaxDocs } = useUseCase(CheckIfTaxDocumentsCanBeGenerated);
  const { execute: getVendorTaxAmount } = useUseCase(GetVendorTaxAmountsUseCase);
  const { model: yearlyVendorTaxDocuments } = usePresenter(YearlyVendorTaxDocPresenter);
  const [yearDocument, setYearDocument] = createSignal<TaxYearDocumentRequest>();

  onMount(() => getTaxDocuments({ page: 1, entityId: vendorId } as GetTaxDocumentsRequest));

  const onCellScrolledIntoView = async (index: number) => {
    await getNextTaxDocBatch({ entityId: vendorId, currentIndex: index } as NextTaxDocumentBatchRequest);
  };

  const onRedoClicked = async (year: string, document: PresentableVendorTaxDocument) => {
    await getVendorTaxAmount({ year: Number(year), entityId: document.entityId } as TaxYearDocumentRequest);
    setYearDocument({ year: Number(year), entityId: document.entityId } as TaxYearDocumentRequest);
  };

  const onConfirmRegenerateClicked = async () => {
    await regenerateTaxDoc({
      year: yearDocument()!.year,
      entityId: yearDocument()!.entityId,
      shouldRefetchAllData: !vendorId,
    } as TaxDocRegenerationRequest);
  };

  createEffect(() => {
    if (didRegenerateTaxDoc()) {
      setYearDocument(undefined);
    }
  });

  const onGenerateButtonClicked = async () => {
    await checkIfCanGenerateTaxDocs();
    if (canGenerateTaxDocs()) {
      const url = vendorId ? `/maintenance/vendors/tax-documents/generate/${vendorId}` : `/maintenance/vendors/tax-documents/generate`;
      navigate(url);
    }
  };

  return (
    <>
      <div class="flex flex-1 flex-col">
        <div class="mb-5 flex w-full items-center justify-between gap-4 rounded-lg border border-partingline bg-white px-6 py-3 text-lg font-semibold">
          {t('Statements')}
          <div>
            <Button onClick={onGenerateButtonClicked}>
              <IconCirclePlus class="size-5" />
              {t('Generate')}
            </Button>
          </div>
        </div>
        <Show
          when={!isLoading()}
          fallback={
            <div class="flex size-full flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" size={100} showIcon={true} />
            </div>
          }>
          <Show
            when={yearlyVendorTaxDocuments() && yearlyVendorTaxDocuments()!.length > 0}
            fallback={
              <div class="flex size-full grow items-center justify-center rounded-lg border border-partingline bg-white p-6 text-lg text-gray-600">
                {t('No statements available')}
              </div>
            }>
            <SectionedTableView
              class="rounded-lg"
              sectionClass="mb-4 rounded-lg border border-partingline bg-white p-4"
              numberOfSections={() => yearlyVendorTaxDocuments()?.length || 0}
              numberOfRowsInSection={(section) => yearlyVendorTaxDocuments()?.[section].vendors.length || 0}
              headerForSection={(section) => (
                <TaxYearHeader
                  class="pl-2 text-2xl font-bold text-gray-800"
                  year={yearlyVendorTaxDocuments()?.[section].year || ''}
                  shouldShowPrintButton={!vendorId}
                  onPrint={(year) => printPdf(() => printTaxDocs({ year, entityType: TaxEntityTypes.Vendor }))}
                />
              )}
              rowForSection={(section, row) => {
                return (
                  <TaxDocumentsCell
                    year={yearlyVendorTaxDocuments()?.[section].year || ''}
                    class="m-4 rounded-md border border-gray-300 bg-white p-4"
                    indexOffset={yearlyVendorTaxDocuments()?.[section].vendors[row].indexOffset}
                    vendorName={vendorId ? undefined : yearlyVendorTaxDocuments()?.[section].vendors[row].vendorName || ''}
                    taxDocuments={yearlyVendorTaxDocuments()?.[section].vendors[row].taxDocuments || []}
                    onRowDidScrollIntoView={onCellScrolledIntoView}
                    onRedo={onRedoClicked}
                  />
                );
              }}
            />
          </Show>
        </Show>
      </div>
      <Show when={yearDocument()}>
        <Modal
          visible
          class="lg"
          title={`${t('Redo tax document')}`}
          onCancel={() => setYearDocument(undefined)}
          onDone={onConfirmRegenerateClicked}
          showFooter={!isRegenerating()}>
          <Show
            when={!isRegenerating()}
            fallback={
              <div class="m-10 flex flex-col items-center justify-center rounded-lg bg-white">
                <RingLoader color="#a126ec" size={100} showIcon={true} />
              </div>
            }>
            <VendorTaxAmountsPage class="px-5" />
          </Show>
        </Modal>
      </Show>
      <Show when={isPrinting()}>
        <Modal visible showFooter={false} class="lg" title={`${t('Printing')}...`}>
          <div class="m-10 flex flex-col items-center justify-center rounded-lg bg-white">
            <RingLoader color="#a126ec" size={100} showIcon={true} />
          </div>
        </Modal>
      </Show>
    </>
  );
};
