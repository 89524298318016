import {
  Status,
  OfflineTransactionPaymentMethodDto,
  PayBillType,
  TransactionPaymentMethod,
  MaintenanceRequestStatus,
  TransactionStatus,
} from '~/swagger/Api';
import type { JSX } from 'solid-js';
import type { RentalApplicationRegions } from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import type { ConfirmationModalProps } from '~/components/modals/ConfirmationModal';

export const genderTypes = [
  { label: 'male', value: 'male' },
  { label: 'female', value: 'female' },
];

export const emptyPlaceholder = '--';

export const priorityTypes = ['urgent', 'high', 'medium', 'low', 'none'].map((item) => ({ label: item, value: item }));

// TODO 后端API 会减少状态类型
export const maintenanceStatusTypes: Array<{
  label: string;
  value: MaintenanceRequestStatus;
}> = [
  {
    label: 'Pending',
    value: MaintenanceRequestStatus.Pending,
  },
  {
    label: 'In progress',
    value: MaintenanceRequestStatus.InProgress,
  },
  {
    label: 'Closed',
    value: MaintenanceRequestStatus.Closed,
  },
  {
    label: 'AI processing',
    value: MaintenanceRequestStatus.AiProcessing,
  },
  {
    label: 'Waiting for work order',
    value: MaintenanceRequestStatus.WaitingForWorkOrder,
  },
];

export const getMaintenanceStatusOptions = (status: MaintenanceRequestStatus) => {
  if (status !== 'aiProcessing') {
    return maintenanceStatusTypes.filter((item) => item.value !== 'aiProcessing');
  }
  return maintenanceStatusTypes;
};

export const permissionToEnterTypes = (t: (str: string) => string) => [
  {
    label: t('Yes'),
    value: true,
  },
  {
    label: t('No'),
    value: false,
  },
];

export const formConfirmationContent = (
  t: (str: string) => string
): Pick<ConfirmationModalProps, 'title' | 'message' | 'cancelText' | 'confirmText'> => ({
  title: t('Are you sure you want to cancel?'),
  message: t('If you cancel the addition, the information already filled in cannot be saved'),
  confirmText: t('Yes'),
  cancelText: t('Go back'),
});

export enum currentUpdateType {
  work = 'work',
  rental = 'rental',
  document = 'document',
  rentalInfo = 'rentalInfo',
  receivePayment = 'receivePayment',
}

export enum rentalApplicationPaymentStatus {
  processing = 'processing',
  readyToConnect = 'transunion-connection',
  confirmIdentity = 'manual-verification',
  submitted = 'application-submitted',
  questionnaire = 'questionnaire',
}
export const workOrderStatusTypes: Array<{
  label: string;
  value: Status;
}> = [
  {
    label: 'Pending',
    value: Status.Pending,
  },
  {
    label: 'In progress',
    value: Status.InProgress,
  },
  {
    label: 'Closed',
    value: Status.Closed,
  },
];

export const transactionStatusOptions = (t: (key: string) => string) => [
  { value: TransactionStatus.Pending, label: t('Pending') },
  { value: TransactionStatus.Failed, label: t('Failed') },
  { value: TransactionStatus.Success, label: t('Success') },
  { value: TransactionStatus.Cancelled, label: t('Cancelled') },
  { value: TransactionStatus.Returned, label: t('Returned') },
  { value: TransactionStatus.Refunded, label: t('Refunded') },
];

export const paymentTypes: (t: (str: string) => string) => Array<{
  label: string;
  value: OfflineTransactionPaymentMethodDto;
}> = (t) => [
  {
    label: t('Check'),
    value: OfflineTransactionPaymentMethodDto.Check,
  },
  {
    label: t('Cashiers Check'),
    value: OfflineTransactionPaymentMethodDto.CashiersCheck,
  },
  {
    label: t('Money Order'),
    value: OfflineTransactionPaymentMethodDto.MoneyOrder,
  },
  {
    label: t('Cash'),
    value: OfflineTransactionPaymentMethodDto.Cash,
  },
  {
    label: t('Other'),
    value: OfflineTransactionPaymentMethodDto.Other,
  },
];

export const enumToOptions = (enumObj: Record<string, string>, renderLabel?: (value: any) => JSX.Element) => {
  return Object.entries(enumObj).map(([key, value]) => ({ label: renderLabel ? renderLabel(value) : key, value }));
};

export enum BackendEntitiesEnum {
  Portfolios = 'portfolios',
  Properties = 'properties',
  Units = 'units',
  Leases = 'leases',
  Tenants = 'tenants',
  Vendors = 'vendors',
  MaintenanceRequests = 'maintenance-requests',
  AssociationsViolations = 'associations/violations',
}

export const isDebug = () => import.meta.env.DEV || ['test', 'demo'].some((host) => location.hostname.includes(host));

export const paymentTypeOptions = (t: (str: string) => string) => [
  { label: t('Manual'), value: PayBillType.Manual },
  { label: t('Print check'), value: PayBillType.PrintCheck },
  { label: t('ACH'), value: PayBillType.Ach },
];

export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Ach', value: TransactionPaymentMethod.Ach },
  { label: 'Credit', value: TransactionPaymentMethod.Credit },
  { label: 'Debit Card', value: TransactionPaymentMethod.DebitCard },
  { label: 'Wallet', value: TransactionPaymentMethod.Wallet },
  { label: 'Credit Card', value: TransactionPaymentMethod.CreditCard },
  { label: 'Check', value: TransactionPaymentMethod.Check },
  { label: 'Cashiers Check', value: TransactionPaymentMethod.CashiersCheck },
  { label: 'Money Order', value: TransactionPaymentMethod.MoneyOrder },
  { label: 'Cash', value: TransactionPaymentMethod.Cash },
  { label: 'Other', value: TransactionPaymentMethod.Other },
];

export const paymentMethodOptions = (t: (str: string) => string) =>
  PAYMENT_METHOD_OPTIONS.map((option) => ({ label: t(option.label), value: option.value }));

export const statesAndProvincesList: RentalApplicationRegions[] = [
  {
    name: 'United States',
    code: 'USA',
    regions: [
      {
        code: 'AL',
        name: 'Alabama',
      },
      {
        code: 'AK',
        name: 'Alaska',
      },
      {
        code: 'AZ',
        name: 'Arizona',
      },
      {
        code: 'AR',
        name: 'Arkansas',
      },
      {
        code: 'CA',
        name: 'California',
      },
      {
        code: 'CO',
        name: 'Colorado',
      },
      {
        code: 'CT',
        name: 'Connecticut',
      },
      {
        code: 'DE',
        name: 'Delaware',
      },
      {
        code: 'FL',
        name: 'Florida',
      },
      {
        code: 'GA',
        name: 'Georgia',
      },
      {
        code: 'HI',
        name: 'Hawaii',
      },
      {
        code: 'ID',
        name: 'Idaho',
      },
      {
        code: 'IL',
        name: 'Illinois',
      },
      {
        code: 'IN',
        name: 'Indiana',
      },
      {
        code: 'IA',
        name: 'Iowa',
      },
      {
        code: 'KS',
        name: 'Kansas',
      },
      {
        code: 'KY',
        name: 'Kentucky',
      },
      {
        code: 'LA',
        name: 'Louisiana',
      },
      {
        code: 'ME',
        name: 'Maine',
      },
      {
        code: 'MD',
        name: 'Maryland',
      },
      {
        code: 'MA',
        name: 'Massachusetts',
      },
      {
        code: 'MI',
        name: 'Michigan',
      },
      {
        code: 'MN',
        name: 'Minnesota',
      },
      {
        code: 'MS',
        name: 'Mississippi',
      },
      {
        code: 'MO',
        name: 'Missouri',
      },
      {
        code: 'MT',
        name: 'Montana',
      },
      {
        code: 'NE',
        name: 'Nebraska',
      },
      {
        code: 'NV',
        name: 'Nevada',
      },
      {
        code: 'NH',
        name: 'New Hampshire',
      },
      {
        code: 'NJ',
        name: 'New Jersey',
      },
      {
        code: 'NM',
        name: 'New Mexico',
      },
      {
        code: 'NY',
        name: 'New York',
      },
      {
        code: 'NC',
        name: 'North Carolina',
      },
      {
        code: 'ND',
        name: 'North Dakota',
      },
      {
        code: 'OH',
        name: 'Ohio',
      },
      {
        code: 'OK',
        name: 'Oklahoma',
      },
      {
        code: 'OR',
        name: 'Oregon',
      },
      {
        code: 'PA',
        name: 'Pennsylvania',
      },
      {
        code: 'RI',
        name: 'Rhode Island',
      },
      {
        code: 'SC',
        name: 'South Carolina',
      },
      {
        code: 'SD',
        name: 'South Dakota',
      },
      {
        code: 'TN',
        name: 'Tennessee',
      },
      {
        code: 'TX',
        name: 'Texas',
      },
      {
        code: 'UT',
        name: 'Utah',
      },
      {
        code: 'VT',
        name: 'Vermont',
      },
      {
        code: 'VA',
        name: 'Virginia',
      },
      {
        code: 'WA',
        name: 'Washington',
      },
      {
        code: 'WV',
        name: 'West Virginia',
      },
      {
        code: 'WI',
        name: 'Wisconsin',
      },
      {
        code: 'WY',
        name: 'Wyoming',
      },
    ],
  },
  // {
  //   name: "Canada",
  //   code: "CAN",
  //   regions: [
  //     [
  //       {
  //         code: "AB",
  //         name: "Alberta"
  //       },
  //       {
  //         code: "BC",
  //         name: "British Columbia"
  //       },
  //       {
  //         code: "MB",
  //         name: "Manitoba"
  //       },
  //       {
  //         code: "NB",
  //         name: "New Brunswick"
  //       },
  //       {
  //         code: "NL",
  //         name: "Newfoundland and Labrador"
  //       },
  //       {
  //         code: "NS",
  //         name: "Nova Scotia"
  //       },
  //       {
  //         code: "ON",
  //         name: "Ontario"
  //       },
  //       {
  //         code: "PE",
  //         name: "Prince Edward Island"
  //       },
  //       {
  //         code: "QC",
  //         name: "Quebec"
  //       },
  //       {
  //         code: "SK",
  //         name: "Saskatchewan"
  //       },
  //       {
  //         code: "NT",
  //         name: "Northwest Territories"
  //       },
  //       {
  //         code: "NU",
  //         name: "Nunavut"
  //       },
  //       {
  //         code: "YT",
  //         name: "Yukon"
  //       }],
  //   ],
  // }
];

export const imageType = ['.jpg', '.jpeg', '.png', '.webp'];

export const themeColors = [
  {
    label: 'Blue',
    value: '#2F49D0',
  },
  {
    label: 'Green',
    value: '#06603A',
  },
  {
    label: 'Orange',
    value: '#B64F16',
  },
  {
    label: 'Purple',
    value: '#4D0061',
  },
  {
    label: 'Black',
    value: '#2D2D2D',
  },
];

export const MAX_INPUT_AMOUNT = 2147483647;
export const MAX_INPUT_AMOUNT_2 = 1000000000000;

export const NO_LIMIT = 'noLimit';

export const LAST_DELETED_LEASE_ID = 'lastDeletedLeaseId';

export const REPORT_LOAD_FAILED_MARK = 'reportLoadFailedMark';

export const NEW_RENTAL_APPLICATION_REVIEW_STEP = Infinity;
