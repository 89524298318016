import { createSignal, For } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import type { Accessor, ValidComponent } from 'solid-js';

export const DraggableList = <T,>(props: { items: T[]; itemId: keyof T; renderItem: ValidComponent; onEnd: (items: T[]) => void }) => {
  const [draggedItemIndex, setDraggedItemIndex] = createSignal<number | null>(null);
  const [draggedOverItemIndex, setDraggedOverItemIndex] = createSignal<number | null>(null);

  const handleDragStart = (e: DragEvent, index: Accessor<number>) => {
    setDraggedItemIndex(index());
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '0.5';
    }
  };
  const handleDragOver = (e: DragEvent, index: Accessor<number>) => {
    e.preventDefault();
    setDraggedOverItemIndex(index());
  };

  const handleDragEnd = (e: DragEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '1';
    }
    const _draggedItemIndex = draggedItemIndex();
    const _draggedOverItemIndex = draggedOverItemIndex();
    if (_draggedItemIndex === null || _draggedOverItemIndex === null || _draggedItemIndex === _draggedOverItemIndex) return;

    const newItems = (props.items || []).slice();
    [newItems[_draggedItemIndex], newItems[_draggedOverItemIndex]] = [newItems[_draggedOverItemIndex], newItems[_draggedItemIndex]];
    props.onEnd(newItems);
  };

  return (
    <For each={props.items}>
      {(item, index) => (
        <div
          data-slot="draggable-item"
          draggable="true"
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDragEnd={handleDragEnd}>
          <Dynamic component={props.renderItem} item={item} index={index} />
        </div>
      )}
    </For>
  );
};
