import { useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization } from '~/contexts/global';
import { usePayVendorBills } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { PayBillType } from '~/swagger/Api';
import { isEmptyData } from '~/utils/tool';
import { AllocationsForm } from './components/AllocationsForm';
import { ChooseBillsForm } from './components/ChooseBillsForm';
import { PaymentForm } from './components/PaymentForm';
import { ReviewAndPrintForm } from './components/ReviewAndPrintForm';

export const PayBillView = () => {
  const { store, setStore, getVendorBillsPay, getChartOfAccounts, getBankAccounts, onPayVendorBills } = usePayVendorBills();
  const goBack = useGoBack();
  const [query] = useSearchParams<{ propertyId?: string; vendorId?: string; billId?: string }>();
  const { t } = useLocalization();
  const breadcrumbItems = createMemo(() => [{ label: t('Bills'), link: '/accounting/bills' }, { label: t('Pay bills') }]);
  const steps = createMemo(() => [t('Choose bills'), t('Allocations'), t('Payment'), t('Review & print')]);
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [isValidate, setIsValidate] = createSignal<boolean>(false);

  const stepValidator = (step: number) => {
    switch (step) {
      case 0: {
        const isNoProperties = !store.isAllProperties && isEmptyData(store.properties);
        const isNoVendors = !store.isAllVendors && isEmptyData(store.vendors);

        setIsValidate(!isNoProperties && !isNoVendors);
        break;
      }

      case 1: {
        const hasValidatedBill = store.billPayments.find(
          (item) => item.paymentAmount && item.paymentAmount > 0 && item.paymentAmount <= item.dueAmount
        );

        setIsValidate(Boolean(hasValidatedBill));
        break;
      }

      case 2: {
        const isValid = store.billPayments.every((bill) => {
          const isValidManual = bill.paymentType === PayBillType.Manual ? Boolean(bill.externalTransactionId?.trim()) : true;
          return isValidManual;
        });

        setIsValidate(isValid && store.billPayments.length > 0);
        break;
      }
    }
  };

  const onStepChange = (step: number, isBack?: boolean) => {
    if (step === 1 && !isBack) {
      getVendorBillsPay({
        propertyIdOr: store.properties?.filter((item) => item),
        vendorIdOr: store.vendors?.filter((item) => item),
      });
    }

    setCurrentStep(step);
  };

  createEffect(() => {
    stepValidator(currentStep());
  });

  onMount(() => {
    getChartOfAccounts();
    getBankAccounts();

    if (query.propertyId) {
      setStore('isAllProperties', false);
      setStore('properties', [query.propertyId]);
    }

    if (query.vendorId) {
      setStore('isAllVendors', false);
      setStore('vendors', [query.vendorId]);
    }

    if (query.propertyId || query.vendorId || query.billId) {
      setCurrentStep(1);
      getVendorBillsPay({
        propertyIdOr: store.properties?.filter((item) => item),
        vendorIdOr: store.vendors?.filter((item) => item),
        billIds: query.billId ? [query.billId] : [],
      });
    }
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      <MultiStepsPanel
        title={t('Pay bills')}
        submitText={t('Pay bills')}
        stepper={
          <div class="flex items-center justify-center pb-10">
            <Stepper class="p-9" steps={steps()} step={currentStep()} />
          </div>
        }
        currentStep={currentStep()}
        showPrevious
        disableNext={!isValidate()}
        successPage={<ReviewAndPrintForm />}
        onClose={() => goBack()}
        onStepChange={(step, isBack) => onStepChange(step, isBack)}
        onSubmit={onPayVendorBills}>
        <ChooseBillsForm />
        <AllocationsForm />
        <PaymentForm />
      </MultiStepsPanel>
    </div>
  );
};
