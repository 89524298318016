import { Show, For, createSignal, createMemo, createEffect } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { IconLoader, IconTrash } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useOverrideContext } from '~/pages/settings/Context';
import { IconLease, IconPortfolio, IconProperty, IconUnit } from '~/pages/settings/components/Icons';
import { UnitRepository } from '~/repositories/unitRepository';
import type { Component, JSX } from 'solid-js';
import type { Promisable } from '~/utils/types';

const unitRepo = new UnitRepository();

type OverrideProps<T> = T & { name?: string; icon?: Component<{ class?: string }>; level?: string; levelId?: string };
type LevelTargetFinder<T> = (id: string) => T | undefined;
interface IProps<T> {
  overrides: OverrideProps<T>[];
  children: (override: OverrideProps<T>) => JSX.Element;
  title: string;
  onDelete?: (level: string, levelId: string) => Promisable<void>;
}

const levelOrder: { [key: string]: number } = {
  portfolio: 1,
  property: 2,
  unit: 3,
  lease: 4,
};

const Overrides = <T,>(props: IProps<T>) => {
  const [deleting, setDeleting] = createSignal<string>();
  const [openMap, setOpenMap] = createSignal<Record<string, boolean>>({});
  const { store } = useOverrideContext();
  const [processedOverrides, setProcessedOverrides] = createSignal<OverrideProps<T>[]>([]);
  const { t } = useLocalization();

  const onExpand = (isOpen: boolean, id: string) => {
    setOpenMap((prev) => {
      return {
        ...prev,
        [id]: isOpen,
      };
    });
  };

  const finders: Record<string, LevelTargetFinder<any>> = {
    portfolio: (id) => store.portfolios.find((p) => p.id === id),
    property: (id) => store.properties.find((p) => p.id === id),
    unit: async (id) => await unitRepo.getUnit(id || '', false),
    lease: (id) => store.leases.find((l) => l.id === id),
  };

  const getIconForLevel = (level: string): any => {
    switch (level) {
      case 'portfolio':
        return IconPortfolio;
      case 'property':
        return IconProperty;
      case 'unit':
        return IconUnit;
      case 'lease':
        return IconLease;
    }
  };

  const processOverrides = async (overrides: OverrideProps<T>[]): Promise<void> => {
    const processedData: OverrideProps<T>[] = await Promise.all(
      overrides.map(async (override) => {
        const { level, levelId } = override;
        const finder = finders[level!];
        let target: any;
        try {
          target = await finder(levelId || '');
        } catch (error) {
          console.error('Error fetching target:', error);
        }
        const name = target?.name || '';
        const icon = getIconForLevel(level!);
        return { ...override, name, icon };
      })
    );

    setProcessedOverrides(processedData);
  };

  createEffect(() => {
    processOverrides(props.overrides);
  });

  const sortedOverrides = createMemo(() => {
    const data = processedOverrides();

    return data.sort((a, b) => {
      const levelComparison = (levelOrder[a.level || ''] || 99) - (levelOrder[b.level || ''] || 99);
      if (levelComparison !== 0) return levelComparison;
      return (a?.name as string).localeCompare(b?.name as string);
    });
  });

  const handleDelete = async (e: MouseEvent, level: string, levelId: string) => {
    setDeleting(`${level}:${levelId}`);
    e.stopPropagation();
    e.preventDefault();

    try {
      await props.onDelete?.(level, levelId);
    } finally {
      setDeleting(undefined);
    }
  };

  return (
    <Show when={sortedOverrides()?.length}>
      <div class="px-0 pb-5 pt-9 md:px-3">
        <div class="mb-3 text-left text-base font-semibold capitalize">{props.title}</div>
        <div class="flex flex-col gap-3">
          <For each={sortedOverrides()}>
            {(override) => (
              <ExpandableSection
                action={
                  <Show
                    when={deleting() !== `${override?.level}:${override?.levelId}`}
                    fallback={<IconLoader class="size-4 animate-spin cursor-wait text-text-level03" />}>
                    <IconTrash
                      class="size-4 text-title-gray"
                      onClick={(e) => handleDelete(e, override.level || '', override.levelId || '')}
                    />
                  </Show>
                }
                defaultOpen={openMap()?.[override?.levelId || '']}
                onExpand={(isOpen) => onExpand?.(isOpen, override?.levelId || '')}
                btnClass="mb-0 items-center border-0 bg-input px-5"
                headerBorder
                class="overflow-hidden rounded-lg border border-input-border px-0 text-base"
                title={
                  <div class="flex items-center gap-5">
                    <Dynamic component={override.icon} class="size-5 text-primary" />
                    <div class="flex flex-col gap-1">
                      <div class="text-sm font-medium">{override.name}</div>
                      <div class="flex gap-1 text-xs">
                        <span class="text-text-level03">{t('Override for')}:</span>
                        <span class="text-text-level02">{override?.level}</span>
                      </div>
                    </div>
                  </div>
                }>
                <div class="p-6">{props.children && props.children(override)}</div>
              </ExpandableSection>
            )}
          </For>
        </div>
      </div>
    </Show>
  );
};

export default Overrides;
