import { splitProps } from 'solid-js';
import { useFormContext } from '~/components/common/BetterForm/context';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { Uploader } from '~/components/common/Upload';
import type { TextAreaProps } from '~/components/common/Inputs/LabeledTextArea';
import type { HydrateFile, PreUploaderProps } from '~/components/common/Upload';

const fileTransfer = (files: HydrateFile[]) => {
  return {
    files: files.map((file) => ({
      fileId: file.fileId,
      description: file.fileName,
    })),
  };
};

export const Description = (
  props: Omit<TextAreaProps, 'extraItem'> & { fileTransfer?: (files: HydrateFile[]) => any; preUploadProps: PreUploaderProps }
) => {
  const [localProps, restProps] = splitProps(props, ['fileTransfer', 'preUploadProps']);

  const form = useFormContext();

  const handleFileInput = (files: HydrateFile[]) => {
    form.setFieldsValue((localProps.fileTransfer || fileTransfer)(files), false);
  };

  return (
    <div class="flex flex-col gap-2 divide-y">
      <LabeledTextArea
        rows={5}
        {...restProps}
        extraItem={() => (
          <div class="border-t border-t-[#e4e4e4] pt-2">
            <Uploader.PreUploader accept="*" {...localProps.preUploadProps} onInput={handleFileInput} class="border-none p-0" />
          </div>
        )}
      />
    </div>
  );
};
