import { PdfReceiptGenerator } from '~/pdfsigner/repositories/pdfReceiptGenerator';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';
import { companyRepository } from '~/repositories/companiesRepository';
import type { HydratedLeaseTransactionDto } from '~/swagger/Api';

export class PrintTransactionReceiptUseCase extends MagicUseCase {
  public async runLogic(transaction: HydratedLeaseTransactionDto) {
    const companyInfo = await companyRepository.getCompanies();
    this.getState().taxInfo.printablePdf = await new PdfReceiptGenerator().generate(companyInfo, transaction);
  }
}
