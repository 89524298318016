import { Route, Routes } from '@solidjs/router';
import { RentalApplicationDetails } from '~/components/rental-application/rental-application-details/RentalApplicationDetails';
import { NewRentalApplication } from './add-rental-application';
import { RentalApplicationsPageRoutes } from './list-rental-application';

export const RentalApplicationRoutes = () => (
  <Routes>
    <Route path="/rental-applications/new/*" component={NewRentalApplication} />
    <Route
      path={['/rental-applications/draft-rental-applications/:applicationId/*', '/rental-applications/:applicationId/*']}
      component={RentalApplicationDetails}
      matchFilters={{ applicationId: /^\d+$/ }}
    />
    <Route path="/rental-applications/*" component={RentalApplicationsPageRoutes} />
  </Routes>
);
