import { Show, createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { FileItem, hydrateFileToPreviewFile, Uploader } from '~/components/common/Upload';
import { useUpload } from '~/components/common/Upload/context';
import { IconImage, IconX } from '~/components/ui';
import { DraggableList } from '~/components/ui/DraggableList';
import { useLocalization } from '~/contexts/global';
import type { Accessor, Component } from 'solid-js';
import type { HydrateFile, UploadProps } from '~/components/common/Upload';

export const PhotosEditor: Component<UploadProps> = (props) => {
  return (
    <Uploader multiple {...props}>
      <PhotoInner />
    </Uploader>
  );
};

const PhotoInner = () => {
  const { fileList, setFileList } = useUpload();
  return (
    <Show when={fileList()?.length} fallback={<Trigger />}>
      <div class="grid grid-cols-2 gap-3 p-3 md:grid-cols-4 md:gap-4 md:p-8 xl:grid-cols-6">
        <Trigger inline />
        <DraggableList items={fileList()} itemId="fileId" renderItem={ImageItem} onEnd={setFileList} />
      </div>
    </Show>
  );
};

const Trigger = (props: { inline?: boolean }) => {
  const { t } = useLocalization();
  const { uploadProps } = useUpload();

  const _trigger = createMemo(() => {
    if (props.inline) {
      return (
        <Uploader.Trigger
          {...uploadProps}
          class="flex aspect-square cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray p-4 text-text-level03 transition-colors data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary hover-allowed:hover:bg-light-pink"
          icon={IconImage}
          triggerLabel={t('Drag and drop photos here')}
        />
      );
    }

    return (
      <Uploader.Trigger
        {...uploadProps}
        class="m-8 flex cursor-pointer flex-col items-center justify-center rounded-lg bg-light-gray p-20 text-text-level03 transition-colors data-[active]:bg-light-pink data-[active]:shadow-inner data-[active]:ring-1 data-[active]:ring-inset data-[active]:ring-primary hover-allowed:hover:bg-light-pink"
        data-slot="dnd"
        icon={IconImage}
        triggerLabel={t('Drag and drop photos here')}
      />
    );
  });

  return <Dynamic component={_trigger} />;
};

const ImageItem = (props: { item: HydrateFile; index: Accessor<number> }) => {
  const { uploadProps, setPreview, onFileRemove } = useUpload();
  return (
    <FileItem.Wrapper
      id={`${uploadProps.name}-item-${props.index()}`}
      class="relative cursor-move overflow-hidden rounded-lg"
      data-slot="photo">
      <FileItem.Operation
        file={props.item}
        onPreview={() => setPreview(Object.assign({ fileType: 'png' }, hydrateFileToPreviewFile(props.item)))}
      />
      <FileItem.ImageThumbnail file={props.item} class="aspect-square size-full object-cover" />
      <button
        id={`${uploadProps.name}-delete-btn`}
        type="button"
        class="absolute right-1 top-1 z-20 rounded bg-black/30 p-1 text-white transition-colors hover-allowed:hover:bg-black/60"
        onClick={() => onFileRemove(props.item)}>
        <IconX class="size-4" />
      </button>
    </FileItem.Wrapper>
  );
};
