import { Show } from 'solid-js';
import UploadIcon from '~/assets/images/common/upload.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Empty } from '~/components/common/Empty';
import { ForWithGutter } from '~/components/common/Gutter';
import IconField from '~/components/common/IconField';
import { WorkHistoryModal } from '~/components/rental-application/rental-application-details/infomation/work-history/WorkHistoryModal';
import { IconBriefcase, IconBuilding, IconClock, IconMail } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { currentUpdateType } from '~/utils/constant';
import { diffDates } from '~/utils/date';
import { showEmptyPlaceholderIfValueIsEmpty, validArr } from '~/utils/tool';

export const WorkHistory = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();

  return (
    <>
      <Show
        when={validArr(applicationStore.application?.employment)}
        fallback={<Empty description={t('Tenant has not report this information')} />}>
        <ForWithGutter each={applicationStore.application?.employment}>
          {(section, index) => (
            <div class="relative flex flex-wrap gap-y-6">
              <IconField
                class="grow-1 h-auto basis-full lg:basis-1/2"
                iconRounded
                src={IconBuilding}
                value={section.name}
                name={t(index() === 0 ? `Current company` : `History company`)}
              />
              <IconField
                class="grow-1 h-auto basis-full lg:basis-1/2"
                iconRounded
                src={IconClock}
                name={t(`Duration`)}
                value={diffDates(section.endDate, section.startDate, { withoutSuffix: true })}
              />
              <div class="flex w-full flex-wrap gap-y-6 rounded-lg bg-light-gray px-6 py-3">
                <IconField
                  class="grow-1 h-auto basis-full lg:basis-1/2"
                  iconRounded
                  src={IconBriefcase}
                  value={showEmptyPlaceholderIfValueIsEmpty(section.position)}
                  name={t(`Position`)}
                />
                <IconField
                  class="grow-1 h-auto basis-full lg:basis-1/2"
                  iconRounded
                  src={IconMail}
                  name={t(`Employment contact`)}
                  value={showEmptyPlaceholderIfValueIsEmpty(section.phone)}
                />
              </div>
            </div>
          )}
        </ForWithGutter>
      </Show>
      <WorkHistoryModal />
    </>
  );
};

export const WorkHistoryActions = () => {
  const { t } = useLocalization();
  const { setApplicationStore } = useRentalApplication();
  return (
    <Button
      variant="outlined"
      class="rounded-full"
      size="xs"
      onClick={() => setApplicationStore({ currentUpdateType: currentUpdateType.work })}>
      <UploadIcon />
      {t('Update')}
    </Button>
  );
};
