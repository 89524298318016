import IconAccountLoginPW from '~/assets/images/settings/accountLoginPW.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { LoginAndPassword } from './components/LoginAndPassword';
// import { TwoFactorAuth } from './components/TwoFactorAuth';

export const AccountSecurity = () => {
  const { t } = useLocalization();

  return (
    <ThreeSectionBox
      coloredClass="border-input-border p-3 md:px-6"
      topTitle={<SettingTitle icon={IconAccountLoginPW} title={t('Login & Password')} />}
      middle={
        <div class="flex flex-col gap-3 p-3 md:gap-8 md:px-6 md:py-5">
          <LoginAndPassword />
          {/* <TwoFactorAuth /> */}
        </div>
      }
    />
  );
};
