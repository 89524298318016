import { Route, Routes } from '@solidjs/router';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { AddOwnerModal } from './forms/AddOwnerModal';
import { ListOwner } from './list-owner/ListOwner';
import { OwnerTaxDocumentsPage } from './tax-documents/OwnerTaxDocumentsPage';

export const OwnerTabRoutes = () => {
  const { t } = useLocalization();

  return (
    <div class="flex grow flex-col overflow-hidden p-8">
      <TabLinks
        boxClass="mb-4 rounded-lg border border-partingline bg-white"
        items={[
          { label: t('Overview'), href: location.search },
          { label: t('Tax documents'), href: `tax-documents${location.search}` },
        ]}
      />
      <Routes>
        <Route path="/add" component={AddOwnerModal} />
      </Routes>
      <Routes>
        <Route path="*" component={ListOwner} />
        <Route path="/tax-documents" component={OwnerTaxDocumentsPage} />
      </Routes>
    </div>
  );
};
