import { createSignal, onMount, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconChargeLateFees from '~/assets/images/settings/property/propertyChargeLateFee.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLateFeesSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { LateFeesSettingsDto } from '~/swagger/Api';

export const PropertyChargeLateFees = () => {
  const { t } = useLocalization();

  const {
    isLoading,
    allLateFeesSettings,
    getAllLateFeesSettings,
    deleteLateFeesSettingsByLevel,
    addLateFeesSettings,
    addLateFeesSettingsByLevel,
  } = useLateFeesSettings();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  onMount(async () => {
    await getAllLateFeesSettings();
  });

  const onDone = async (level: string, levelId: string) => {
    await addLateFeesSettingsByLevel(level, levelId, {
      ...(allLateFeesSettings()?.settings as MagicDoor.Api.LateFeesSettingsDto),
    });
    setShowModal(false);
    getAllLateFeesSettings();
    toast(t('Late fees settings saved successfully'), 'success');
  };

  const handleSave = async (data: MagicDoor.Api.LateFeesSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      try {
        await addLateFeesSettings(data);
        toast(t('Late fees settings saved successfully'), 'success');
        getAllLateFeesSettings();
      } catch {
        toast(t('Failed to save late fees settings'), 'error');
      }
    } else {
      try {
        await addLateFeesSettingsByLevel(level, levelId, data);
        toast(t('Late fees settings saved successfully'), 'success');
        getAllLateFeesSettings();
      } catch {
        toast(t('Failed to save late fees settings'), 'error');
      }
    }
  };

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t(`Delete override`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteLateFeesSettingsByLevel(level, levelId);
        toast(t('Late fees settings deleted successfully'), 'success');
        getAllLateFeesSettings();
      },
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  const getCalculationField = (calculation: string) => {
    if (calculation === 'fixed') {
      return {
        field: 'amount',
        label: 'Amount',
        type: 'number',
        rules: [
          {
            validator: (value: number) => value <= 1000 && value >= 0,
            message: t('The value of amount is between 0 and 1000'),
          },
        ],
      };
    }

    return {
      field: 'feePercent',
      label: 'Fee percent',
      type: 'number',
      suffix: '%',
      percent: true,
      rules: [
        {
          validator: (value: number) => value <= 1 && value >= 0,
          message: t('The value of feePercent is between 0 and 1'),
        },
      ],
    };
  };

  const sections = (values: LateFeesSettingsDto) => {
    return [
      {
        label: 'Charge late fees',
        field: 'enabled',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass:
          'flex-row items-center md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
      },
      {
        field: 'gracePeriod',
        label: 'Grace period',
        type: 'number',
        rules: [
          {
            validator: (value: number) => value <= 31 && value >= 0,
            message: t('The value of gracePeriod is between 0 and 31'),
          },
        ],
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between flex-wrap',
        description:
          'Number of days to wait after payment due date before charging late fees. Payments in "Processing" status are not considered paid',
      },
      {
        field: 'lateFeeChartOfAccountId',
        label: 'Late fee chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'Select the chart of account where late fee will be recorded',
      },
      {
        field: 'lateFeeDescription',
        label: 'Late fee description',
        type: 'string',
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'Custom description that will appear on the lease ledger for late fee charges',
      },

      {
        field: 'calculation',
        label: 'Late fee calculation',
        type: 'select',
        options: [
          { label: t('Fixed'), value: 'fixed' },
          { label: t('Percent'), value: 'percent' },
        ],
        class: 'col-span-2 md:col-span-1',
        labelClass: 'w-full md:w-1/2',
      },
      getCalculationField(values.calculation),
      {
        field: 'lateFeeIncludeChartOfAccountIds',
        label: 'Include chart of accounts',
        type: 'chartOfAccountSelect',
        multiple: true,
        rules: [],
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'Only charge late fees for the following chart of account',
        types: 'revenue',
      },
      {
        field: 'lateFeePreventChartOfAccountIds',
        label: 'Exclude chart of accounts',
        type: 'chartOfAccountSelect',
        multiple: true,
        rules: [],
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'Do not charge late fees for the following chart of accounts',
      },
    ];
  };

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new override')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Late fees')} icon={IconChargeLateFees} />}
        middle={
          <div class="flex flex-col gap-5 p-3 md:p-6">
            <div class="flex flex-col gap-4 text-left">
              <EditForm
                sections={(values) => sections(values)}
                onOk={(data) => handleSave(data)}
                model={allLateFeesSettings()?.settings || ({} as MagicDoor.Api.LateFeesSettingsDto)}
              />
            </div>
            <Overrides overrides={allLateFeesSettings()?.overrides || []} onDelete={onDelete} title={t('Late fees overrides')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 text-left">
                    <EditForm
                      sections={(values) => sections(values)}
                      onOk={(data) => handleSave(data, override.level, override.levelId)}
                      model={override.settings || ({} as MagicDoor.Api.LateFeesSettingsDto)}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal overrides={allLateFeesSettings()?.overrides || []} onClose={onClose} onDone={onDone} loading={isLoading()} />
      </Show>
    </>
  );
};
