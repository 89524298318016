import { onMount, splitProps } from 'solid-js';
import { getFormContext, useForm } from '~/components/common/BetterForm/context';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

export const Form: Component<BetterForm.FormProps> = (_props) => {
  const [localProps, formProps] = splitProps(_props, [
    'onFormSubmit',
    'children',
    'initialValues',
    'defaultForm',
    'validateTriggers',
    'onFieldChange',
  ]);

  const FormContext = getFormContext();

  const defaultForm =
    localProps.defaultForm ||
    useForm({
      validateTriggers: localProps.validateTriggers,
      onFieldChange: localProps.onFieldChange,
    });

  onMount(() => {
    defaultForm.setFieldsValue(localProps.initialValues);
  });

  return (
    <FormContext.Provider value={defaultForm}>
      <form
        {...formProps}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const { validateStatus } = defaultForm.validateForm();
          if (validateStatus) {
            localProps.onFormSubmit && localProps.onFormSubmit(defaultForm.formStore);
          }
        }}>
        {localProps.children}
      </form>
    </FormContext.Provider>
  );
};
