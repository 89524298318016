import { A } from '@solidjs/router';
import { For, splitProps, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, ComponentProps, JSX } from 'solid-js';

export type TabLinkItem = { label: string; href: string; end?: boolean };

export const TabLinks: Component<
  ComponentProps<'div'> & { items: TabLinkItem[]; actions?: JSX.Element; boxClass?: string; replace?: boolean; name?: string }
> = (props) => {
  const [params, rest] = splitProps(props, ['ref', 'class', 'items']);
  const replaceParam = props.replace ? { replace: props.replace } : {};
  return (
    <div class={cn('flex w-full items-center justify-between', props.boxClass)}>
      <div
        role="tablist"
        class={cn(
          'thinscroll flex flex-1 overflow-x-auto whitespace-nowrap text-sm font-medium capitalize text-primary scrollbar-thumb-[#0003] *:text-text-level03',
          params.class
        )}
        {...rest}>
        <For each={params.items}>
          {(item, index) => (
            <A
              id={`${props.name}-tab-${index()}`}
              class="relative px-6 py-4 opacity-85 transition after:absolute after:inset-x-0 after:bottom-1 after:mx-auto after:h-1 after:w-10 after:rounded after:transition-colors aria-[current]:text-inherit aria-[current]:opacity-100 aria-[current]:after:bg-current hover-allowed:hover:opacity-100 md:text-base"
              href={item.href}
              {...replaceParam}
              end={item.end ?? true}
              role="tab">
              {item.label}
            </A>
          )}
        </For>
      </div>
      <Show when={props.actions}>{props.actions}</Show>
    </div>
  );
};
