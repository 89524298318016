import { useParams } from '@solidjs/router';
import { createEffect, createSignal, onCleanup, onMount, Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { FilesListProvider, useChat, useChatsList, useChatsSettings } from '~/contexts/local';
import { ChatWindow } from '~/pages/chats/chat-details/ChatWindow';
import { cn } from '~/utils/classnames';
import { useLockBodyScroll } from '~/utils/hooks';
import { ChatSidebar } from './list-components/ChatSidebar';

export const ChatListView = () => {
  const params = useParams<{ chatId?: `${number}` | 'add' }>();
  const { t } = useLocalization();
  const { isMobileView } = useChatsSettings();
  const { isLoading, fetchChats, chats, error } = useChatsList();
  const { chatId, initializeChat, resetChat } = useChat();

  const [isSidebarVisible, setIsSidebarVisible] = createSignal<boolean>(window.innerWidth > 1023);

  onMount(() => fetchChats());

  onMount(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
  });

  onCleanup(() => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  });

  let touchstartX = 0;
  let touchendX = 0;

  const handleTouchStart = (e: any): void => {
    touchstartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: any): void => {
    const threshold = 75;

    touchendX = e.changedTouches[0].screenX;

    if (touchendX < touchstartX - threshold && isSidebarVisible()) setIsSidebarVisible(false);
    if (touchendX > touchstartX + threshold && !isSidebarVisible()) setIsSidebarVisible(true);
  };

  const onToggleSidebarVisible = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  useLockBodyScroll();

  createEffect(() => {
    const chatIdFromUrl = params.chatId;
    if (chatIdFromUrl && chatIdFromUrl !== 'add') {
      initializeChat(chatIdFromUrl);
    } else {
      resetChat();
    }
  });

  createEffect(() => {
    if (params.chatId == null || params.chatId === 'add') return;
    if (isLoading() || chats().some((c) => c.id === params.chatId)) return;
    fetchChats();
  });

  createEffect(() => {
    if (error instanceof Error) {
      throw error();
    }
  });

  return (
    <div class="relative flex w-full text-sm" classList={{ 'cursor-wait': isLoading() }}>
      <Show when={isSidebarVisible() && isMobileView()}>
        <div class="absolute inset-0 top-[56px] z-[1] bg-black/70" onClick={onToggleSidebarVisible} />
      </Show>
      <ChatSidebar
        class={cn(
          'absolute left-0 top-0 z-20 h-section shrink-0 border-r transition-transform lg:relative lg:translate-x-0',
          { 'bottom-0 top-[56px] !h-auto': isMobileView() },
          { '-translate-x-full': !isSidebarVisible() }
        )}
        onToggleSidebarVisible={onToggleSidebarVisible}
      />
      <div class="grow" onClick={() => window.innerWidth <= 1023 && setIsSidebarVisible(false)}>
        <FilesListProvider entityType={'chat' as MagicDoor.Api.EntityType} entityId={chatId() as string}>
          <ChatWindow
            chatId={chatId()}
            class="border-t border-partingline"
            isSidebarVisible={isSidebarVisible()}
            onToggleSidebarVisible={onToggleSidebarVisible}
          />
        </FilesListProvider>
      </div>
    </div>
  );
};
