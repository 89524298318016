import dayjs from 'dayjs';
import { createMemo, For, Show, onMount, createEffect } from 'solid-js';
import { produce } from 'solid-js/store';
import MinusIcon from '~/assets/images/common/minus.svg?component-solid';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { useLease, useProrateCharge } from '~/contexts/local';
import { ChargeFrequency } from '~/swagger/Api';
import { MAX_INPUT_AMOUNT_2 } from '~/utils/constant';
import { cloneDeep } from '~/utils/tool';
import { minLengthValidator, minNumberValidator, verifyRequiredValidator } from '~/utils/validations';
import { Alert, ProrateChargeForm } from './ProrateCharge';
import type { NewLeaseStepProps } from './types';
import type { Component } from 'solid-js';

export const Rents: Component<NewLeaseStepProps> = (props) => {
  const { t } = useLocalization();

  const { showAlert, submitProrateCharges, showProrateChargeForm } = useProrateCharge();

  const { getNextChargeDate } = useLease();

  onMount(() => {
    if (!props.model.rent || Object.keys(props.model.rent).length === 0) {
      props.setModel &&
        props.setModel(
          produce((state) => {
            state.rent = {
              description: 'Rent',
              chartOfAccountId: '4100',
              chargeFrequency: 'monthly',
            } as MagicDoor.Api.LeaseChargeDto;
          })
        );
    }
  });

  async function updateByStartDate(start: string) {
    const res = await getNextChargeDate(start);
    if (res) {
      props.onUpdate({ rent: { ...props.model.rent, startFrom: res } as MagicDoor.Api.LeaseChargeDto });
    }
  }

  createEffect(() => {
    if (props.model.start) {
      updateByStartDate(props.model.start);
    }
  });

  const rentSplitOptions = createMemo(() => [
    { label: t('1 payment monthly'), value: ChargeFrequency.Monthly },
    { label: t('2 payments monthly'), value: ChargeFrequency.Monthly2XSplit },
  ]);

  const hasSubmit = createMemo(() => props.isSubmit);
  const amountError = createMemo(() => minNumberValidator(props.model.rent?.amount, 'Rent amount', 1, true));
  const descriptionError = createMemo(() => minLengthValidator(props.model.rent?.description ?? 'Rent', 'Description', 1, true));
  const chartOfAccountIdError = createMemo(() =>
    verifyRequiredValidator(props.model.rent?.chartOfAccountId ?? '4100', 'Chart of account', true)
  );

  const chargeFrequencyError = createMemo(() =>
    verifyRequiredValidator(props.model.rent?.chargeFrequency ?? 'monthly', 'Rent split', true)
  );

  const otherChargesError = createMemo(() => {
    const errors = (props.model.otherCharges || []).map((item) => {
      return {
        amount: minNumberValidator(item?.amount, 'Amount', 1, true),
        description: minLengthValidator(item?.description, 'Description', 1, true),
        chartOfAccountId: verifyRequiredValidator(item?.chartOfAccountId, 'Chart of account', true),
        hasInput: item?.amount || item?.description || item?.chartOfAccountId,
      };
    });
    return errors;
  });

  const handleAddCharge = () => {
    props.onUpdate({
      otherCharges: [...(props.model.otherCharges ?? []), {}] as MagicDoor.Api.LeaseChargeDto[],
    });
  };

  const updateOtherCharge = (index: number, field: 'amount' | 'description' | 'chartOfAccountId' | 'isCompanyBill', value: any) => {
    if (!props.model.otherCharges) return;
    const otherCharges = cloneDeep(props.model.otherCharges);
    otherCharges[index] = {
      ...otherCharges[index],
      [field]: value,
    };
    props.setModel &&
      props.setModel(
        produce((state) => {
          (state.otherCharges as any)[index][field] = value;
        })
      );
  };

  return (
    <div class="flex flex-col gap-6 divide-y">
      <div class="flex flex-col gap-4">
        <div class="grid grid-cols-2 gap-4">
          <LabeledNumberInput
            id="new-lease-form-amount"
            class="col-span-2 mb-4 lg:col-span-1"
            prepend="$"
            label={t('Rent amount')}
            min={1}
            required
            value={props.model.rent?.amount}
            onInput={(value) => props.onUpdate({ rent: { ...props.model.rent, amount: Number(value) } as MagicDoor.Api.LeaseChargeDto })}
            error={hasSubmit() ? amountError() : undefined}
            placeholder={t('Please enter')}
          />
          <LabeledTextInput
            id="new-lease-form-description"
            class="col-span-2 mb-4 lg:col-span-1"
            label={t('Description')}
            required
            value={props.model.rent?.description ?? 'Rent'}
            onInput={(value) => props.onUpdate({ rent: { ...props.model.rent, description: value } as MagicDoor.Api.LeaseChargeDto })}
            error={hasSubmit() ? descriptionError() : undefined}
            placeholder={t('Please enter')}
          />
          <LabeledChartOfAccountSelect
            id="new-lease-form-chartOfAccountId"
            types="revenue"
            groupClass="col-span-2 mb-4 lg:col-span-1"
            label={t('Chart of account')}
            required
            value={props.model.rent?.chartOfAccountId ?? '4100'}
            onInput={(value) => props.onUpdate({ rent: { ...props.model.rent, chartOfAccountId: value } as MagicDoor.Api.LeaseChargeDto })}
            error={hasSubmit() ? chartOfAccountIdError() : undefined}
            placeholder={t('Select chart of account')}
          />
          <LabeledSelect
            id="new-lease-form-chargeFrequency"
            class="col-span-2 mb-4 lg:col-span-1"
            label={t('Rent split')}
            options={rentSplitOptions()}
            required
            value={props.model.rent?.chargeFrequency ?? 'monthly'}
            onInput={(value) =>
              props.onUpdate({
                rent: { ...props.model.rent, chargeFrequency: value as MagicDoor.Api.ChargeFrequency } as MagicDoor.Api.LeaseChargeDto,
              })
            }
            error={hasSubmit() ? chargeFrequencyError() : undefined}
            placeholder={t('Select rent split')}
          />
          <DueDateInputField
            id="new-lease-form-startFrom"
            class="col-span-2 mb-4 lg:col-span-1"
            label={t('First charge date')}
            required
            value={props.model.rent?.startFrom}
            onInput={(value) => props.onUpdate({ rent: { ...props.model.rent, startFrom: value } as MagicDoor.Api.LeaseChargeDto })}
            placeholder={t('MM/DD/YYYY')}
            disabledDate={(date) => {
              if (props.model.start) {
                return dayjs(props.model.start).isAfter(date);
              }
              return false;
            }}
          />
        </div>
        <Show when={showAlert()}>
          <Alert
            onClick={submitProrateCharges}
            content={t('Your first rent date is different than your lease start date. Should we prorate the charges?')}
          />
        </Show>
      </div>
      <Show when={showProrateChargeForm()}>
        <ProrateChargeForm proratedAmount={props.model.proratedAmount} onUpdate={props.onUpdate} />
      </Show>
      <div class="pt-6">
        <div class="flex items-start gap-3">
          <Checkbox
            id="new-lease-form-otherCharges"
            checked={!!props.model.otherCharges}
            onInput={(checked) => props.onUpdate({ otherCharges: checked ? ([{}] as MagicDoor.Api.LeaseChargeDto[]) : undefined })}
          />
          <div class="flex flex-col gap-1">
            <span class="text-sm font-medium text-text-level01">{t('Other charges')}</span>
            <span class="text-xxs text-text-level02">{t('Pay on the first of the month bill')}</span>
          </div>
        </div>
        <Show when={!!props.model.otherCharges}>
          <div class="overflow-x-auto py-4 pl-8">
            <div class="mb-2 flex gap-x-4">
              <span class="min-w-40 flex-1 text-sm uppercase text-label">
                {t('Amount')}
                <span class="text-error"> *</span>
              </span>
              <span class="min-w-40 flex-1 text-sm uppercase text-label">
                {t('Chart of account')}
                <span class="text-error"> *</span>
              </span>
              <span class="min-w-40 flex-1 pl-6 text-sm uppercase text-label">
                {t('Description')}
                <span class="text-error"> *</span>
              </span>
              <div class="w-[200px]" />
            </div>

            <For each={props.model.otherCharges}>
              {(charge, index) => (
                <div id={`charge_${index()}`} class="mb-3 flex gap-x-4">
                  <LabeledNumberInput
                    id={`new-lease-form-charge-${index()}-amount`}
                    class="mb-4 min-w-40 flex-1"
                    required
                    min={1}
                    prepend="$"
                    value={charge.amount}
                    onInput={(value) => updateOtherCharge(index(), 'amount', Number(value))}
                    error={otherChargesError()[index()].hasInput ? otherChargesError()[index()].amount : undefined}
                    placeholder={t('Please enter')}
                    max={MAX_INPUT_AMOUNT_2}
                  />
                  <LabeledChartOfAccountSelect
                    id={`new-lease-form-charge-${index()}-chartOfAccountId`}
                    class="min-w-40 flex-1"
                    types="revenue"
                    required
                    value={charge.chartOfAccountId}
                    onInput={(value) => updateOtherCharge(index(), 'chartOfAccountId', value)}
                    error={otherChargesError()[index()].hasInput ? otherChargesError()[index()].chartOfAccountId : undefined}
                    placeholder={t('Please select')}
                  />
                  <LabeledTextInput
                    id={`new-lease-form-charge-${index()}-description`}
                    class="mb-4 min-w-40 flex-1"
                    required
                    value={charge.description}
                    onInput={(value) => updateOtherCharge(index(), 'description', value)}
                    error={otherChargesError()[index()].hasInput ? otherChargesError()[index()].description : undefined}
                    placeholder={t('Please enter')}
                  />
                  <div class="flex">
                    <div class="mb-4 min-w-40 pt-2">
                      <div class="flex items-start gap-3">
                        <Checkbox
                          id={`new-lease-form-charge-${index()}-isCompanyBill`}
                          checked={!!charge.isCompanyBill}
                          onInput={(checked: boolean) => updateOtherCharge(index(), 'isCompanyBill', checked)}
                        />
                        <div class="flex gap-1">
                          <span class="text-sm text-label">{t('Company bills')}</span>
                        </div>
                      </div>
                    </div>
                    <Show when={index() !== 0} fallback={<div class="w-6" />}>
                      <Button
                        id={`new-lease-form-charge-${index()}-update-btn`}
                        class="-ml-3 p-2"
                        variant="text"
                        rounded="full"
                        onClick={() => props.onUpdate({ otherCharges: props.model.otherCharges?.filter((_, i) => i !== index()) })}>
                        <MinusIcon />
                      </Button>
                    </Show>
                  </div>
                </div>
              )}
            </For>
            <Button id="new-lease-form-add-another-charge" class="w-56 text-sm" variant="text" color="link" onClick={handleAddCharge}>
              <PlusIcon /> {t('Add another charge')}
            </Button>
          </div>
        </Show>
      </div>
    </div>
  );
};
