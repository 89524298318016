import { For, Show, splitProps } from 'solid-js';
import { cn } from '~/utils/classnames';
import { validArr } from '~/utils/tool';

export const Gutter = (props: { direction?: 'horizontal' | 'vertical'; sizeClass?: string }) => {
  return (
    <div
      class={cn('flex flex-col items-stretch self-stretch py-6', {
        'flex-row px-6 py-0': props.direction === 'horizontal',
      })}>
      <div
        class={cn('h-0 border-t border-input-border', {
          'h-auto w-0 border-r border-t-0': props.direction === 'horizontal',
        })}
      />
    </div>
  );
};

export const ForWithGutter: typeof For = (props) => {
  const [children, _props] = splitProps(props, ['children']);
  return (
    <For {..._props}>
      {(item, index) => {
        const length = validArr(_props.each) ? (_props.each as unknown as []).length : 0;
        return (
          <>
            {children.children(item, index)}
            <Show when={index() !== length - 1}>
              <Gutter />
            </Show>
          </>
        );
      }}
    </For>
  );
};
