import { Route, Routes } from '@solidjs/router';
import ChatsRoutes from '~/pages/settings/chats';
import { OverrideContext } from './Context';
import SettingLayout from './SettingLayout';
import AccountRoutes from './account-settings';
import CompanyRoutes from './company-settings';
import LeaseRoutes from './lease-settings';
import PortalRoutes from './portal-settings';
import PropertyRoutes from './property-settings';
import RentalApplicationRoutes from './rental-application';

const SettingRoutes = () => {
  return (
    <OverrideContext>
      <Routes>
        <Route path="/" element={<SettingLayout />}>
          <Route path="/account/*" element={<AccountRoutes />} />
          <Route path="/company/*" element={<CompanyRoutes />} />
          <Route path="/property/*" element={<PropertyRoutes />} />
          <Route path="/portal/*" element={<PortalRoutes />} />
          <Route path="/preferences" element={<PortalRoutes />} />
          <Route path="/rental/*" element={<RentalApplicationRoutes />} />
          <Route path="/chats/*" element={<ChatsRoutes />} />
          <Route path="/lease/*" element={<LeaseRoutes />} />
        </Route>
      </Routes>
    </OverrideContext>
  );
};

export default SettingRoutes;
