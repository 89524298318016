import { useBeforeLeave } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import IconWarning from '~/assets/images/common/linear-warning.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { IconAlert, IconPencilLine, IconSave, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const SaveBtn: Component<{
  class?: string;
  dirty?: boolean;
  saveText?: string;
  onSave: () => Promise<void> | void;
  onCancel: () => void;
  disableConfirm?: boolean;
}> = (props) => {
  const { t } = useLocalization();

  const [saving, setSaving] = createSignal<boolean>(false);
  const [saved, setSaved] = createSignal<boolean>(false);
  const [showConfirm, setShowConfirm] = createSignal<boolean>(false);

  useBeforeLeave((e) => {
    if (saved()) return;
    if (!props.dirty) return;
    e.preventDefault();
    setShowConfirm(true);
  });

  const saveAfterConfirmation = async () => {
    setSaving(true);
    try {
      await props.onSave();
      setSaved(true);
    } finally {
      setSaving(false);
    }
  };

  const handleSave = async () => {
    if (props.disableConfirm) {
      saveAfterConfirmation();
      return;
    }

    confirm({
      icon: () => <IconWarning class="my-1 size-5 text-warning" />,
      title: t('Save'),
      titleClass: 'text-warning',
      content: t('Your settings may take some time to take effect. It is expected that they will come into effect within 5 minutes.'),
      doneText: t('Confirm'),
      class: 'w-[380px]',
      async onResolve(confirmed) {
        if (!confirmed) return;
        saveAfterConfirmation();
      },
    });
  };

  const handleCancel = () => {
    if (showConfirm()) {
      setShowConfirm(false);
    } else if (!saved() && props.dirty) {
      setShowConfirm(true);
      return;
    }
    props.onCancel();
  };

  return (
    <div
      class={cn(
        'flex flex-col items-center gap-2 border-y px-3 py-4 transition-colors ease-[cubic-bezier(0,0,0.9,2)] md:flex-row md:px-6',
        showConfirm() && 'bg-[#eec9ff]',
        props.class,
        {
          'justify-between': props.dirty,
          'justify-end': !props.dirty,
        }
      )}>
      <Show when={props.dirty && !showConfirm()}>
        <div class="flex gap-2">
          <IconPencilLine class="size-5 text-text-level02" />
          <p class="text-sm font-semibold text-title-gray">{t('The settings has been updated, please save it in time')}</p>
        </div>
      </Show>
      <Show when={showConfirm()}>
        <div class="flex gap-2">
          <IconAlert class="size-5 text-warning" />
          <p class="text-sm font-semibold text-warning">{t('Note that there are no updates yet saved.')}</p>
        </div>
      </Show>
      <div class="flex gap-2">
        <Button variant="outlined" class="ml-auto mr-2" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSave} loading={saving()} disabled={!props.dirty}>
          <IconSave class="size-5" />
          {props.saveText ?? t('Save')}
        </Button>
      </div>
    </div>
  );
};
