import { createMemo } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { parseFilesFromAPIToUploaderDefaultFileList } from '~/components/common/Upload';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { useAddOrEditMaintenanceModalContext } from '~/pages/maintenance-request/add-maintenance-request/Context';
import { permissionToEnterTypes, priorityTypes } from '~/utils/constant';
import { maintenanceRequestFileAccept } from '~/utils/file';
import { upperFirst } from '~/utils/tool';
export const EssentialInfomation = () => {
  const { t } = useLocalization();
  const { store, onUploadingChange } = useAddOrEditMaintenanceModalContext();
  const { categories } = useMaintenanceRequests();

  const maintenanceRequestFiles = createMemo(() => parseFilesFromAPIToUploaderDefaultFileList(store.maintenanceInfo?.files));
  const form = useFormContext();

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        class="w-full"
        label={t(`Description`)}
        rules={[{ required: true, message: t(`Please input description`) }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        preUploadProps={{
          accept: maintenanceRequestFileAccept,
          defaultFileList: maintenanceRequestFiles(),
          onUploadingChange,
        }}
      />

      <FormItem
        class="w-full"
        label={t(`Title`)}
        rules={[{ required: true, message: t('Please input title') }]}
        formFieldName="title"
        component={LabeledTextInput}
        placeholder={t('Please enter')}
      />

      <FormItem
        class="w-full"
        label={t(`Tenant`)}
        component={LabeledLeveledSearchSelect}
        rules={[
          {
            validator: () => {
              return !!form.formStore.requesterId;
            },
            message: t(`Please select tenant`),
          },
        ]}
        required
        formFieldName="requesterId"
        placeholder={t('Please select tenant')}
        enabledTypes={[CustomLevelSearch.Tenant]}
        initialSelected={[{ id: form.formStore.requesterId, __type: CustomLevelSearch.Tenant }]}
        singleTypeOnly
        onChange={(_, varints) => {
          if (varints.singleType === CustomLevelSearch.Tenant) {
            form.setFormStore({
              leaseId: varints.leveledIds[0].leaseId,
              requesterId: varints.leveledIds[0].tenantId,
            });
          } else {
            form.setFormStore({
              leaseId: undefined,
              requesterId: undefined,
            });
          }
        }}
      />

      <div class="grid grid-cols-2 gap-8">
        {/* TODO: UI 与接口不一致，不确定匹配字段，先隐藏 */}
        {/* <FormItem label={t(`Associated task`)} formFieldName="task" component={LabeledTextInput} /> */}

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Permission to enter`)}
          options={permissionToEnterTypes(t)}
          rules={[
            {
              required: true,
              message: t(`Please select permission to enter`),
            },
          ]}
          formFieldName="permissionToEnter"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Priority`)}
          rules={[{ required: true, message: t('Please select priority') }]}
          options={priorityTypes.map((item) => ({ value: item.value, label: t(upperFirst(item.label)) }))}
          formFieldName="urgency"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />
        <FormItem class="col-span-full lg:col-span-1" label={t(`Date`)} formFieldName="due" component={DueDateInputField} />

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Category')}
          rules={[{ required: true, message: t('Please select category') }]}
          options={categories()?.map((item) => ({ value: item.id!, label: item.name! }))}
          formFieldName="categoryId"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />
      </div>
    </div>
  );
};
