import { onMount, createSignal, createEffect } from 'solid-js';
import IconQuestions from '~/assets/images/settings/rental/questions.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import { cloneDeep } from '~/utils/tool';
import { QuestionList } from './components/QuestionList';
import type { RentalApplicationQuestionDto } from '~/swagger/Api';

export const StandardQuestions = () => {
  const { getRentalApplicationSettings, rentalApplicationSettings, updateRentalApplicationSettings } = useRentalApplicationSettings();
  const { t } = useLocalization();
  const [questions, setQuestions] = createSignal<RentalApplicationQuestionDto[]>();
  const [originalQuestion, setOriginalQuestion] = createSignal<RentalApplicationQuestionDto[]>();

  onMount(async () => {
    await getRentalApplicationSettings();
  });

  createEffect(() => {
    setOriginalQuestion(rentalApplicationSettings()?.questions);
    setQuestions(rentalApplicationSettings()?.questions);
  });

  const isDirty = () => {
    return JSON.stringify(rentalApplicationSettings()?.questions) !== JSON.stringify(questions());
  };
  const handleValid = () => {
    return questions()?.some((q) => q.text.trim().length === 0 || q.options?.some((o) => o.trim().length === 0));
  };
  const handleSave = async () => {
    const isValid = handleValid();
    if (isValid) {
      toast(t('Please fill in all fields'));
      return;
    }
    try {
      const formData = {
        ...rentalApplicationSettings(),
        questions: questions(),
      };
      await updateRentalApplicationSettings(formData as MagicDoor.Api.RentalApplicationSettingsDto);
      toast(t('Standard questions settings saved successfully'), 'success');
    } catch {
      toast(t('Failed to save standard questions settings'), 'error');
    }
  };

  const handleChange = (values: any) => {
    setQuestions(values);
  };

  const onCancel = () => {
    const oldQuestion = cloneDeep(rentalApplicationSettings()?.questions);
    setOriginalQuestion(oldQuestion);
    setQuestions(oldQuestion);
  };

  return (
    <ThreeSectionBox
      class="h-fit"
      topTitle={<SettingTitle title={t('Standard questions')} icon={IconQuestions} />}
      middle={
        <div class="flex flex-col gap-4 p-3 text-left md:p-6">
          <QuestionList value={originalQuestion()} onInput={handleChange} />
          <SaveBtn dirty={isDirty()} onCancel={onCancel} onSave={handleSave} />
        </div>
      }
    />
  );
};
