import { Show, createSignal } from 'solid-js';
import { CancelWithConfirmation } from '~/components/modals';
import MiddleModal from '~/components/modals/MiddleModal';
import { useLocalization } from '~/contexts/global';
import AddNewVendor from '~/pages/vendors/components/AddNewVendor';

interface Props {
  onClose?: (newVendorId?: string) => void;
}

export const AddVendorModal = (props: Props) => {
  const { t } = useLocalization();

  const [showConfirmationModal, setShowConfirmationModal] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  return (
    <MiddleModal
      bodyClass="overflow-hidden"
      confirmation={showCloseConfirmation()}
      onClose={() => props.onClose?.()}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <AddNewVendor
        onClose={(newVendorId?: string) => {
          props.onClose?.(newVendorId);
        }}
        setShowCloseConfirmation={setShowCloseConfirmation}
        showCloseConfirmation={showCloseConfirmation()}
      />
      <Show when={showConfirmationModal()}>
        <CancelWithConfirmation
          onConfirm={() => props.onClose}
          onCancel={() => setShowConfirmationModal(false)}
          message={t('Are you sure you want to cancel?')}
        />
      </Show>
    </MiddleModal>
  );
};
