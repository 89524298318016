import { useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { PhotosEditor } from '~/components/photos';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { imageFileAccept } from '~/utils/file';
import type { Component } from 'solid-js';
import type { HydrateFile } from '~/components/common/Upload';
export const UnitListingPhotosTab: Component = () => {
  const { t } = useLocalization();
  const params = useParams<{ listingId: `${number}` }>();
  const { unitListingDetail, updateUnitListing } = useUnitListings();

  const images = createMemo(() => {
    return unitListingDetail()?.images?.map((i) => ({
      fileId: i.fileId,
      fileName: i.description || '',
      fileUrl: i.signedUrl || '',
      thumbUrl: i.signedThumbUrl || '',
    }));
  });

  const handlePhotosInput = async (files: HydrateFile[]) => {
    if (params.listingId == null) return;

    const param = {
      images: files.map((f, index) => ({
        fileId: f.fileId,
        default: index === 0,
      })),
    };
    await updateUnitListing(param, params.listingId);
  };

  return (
    <Panel
      title={
        <div>
          <div>{t('Unit listing photos')}</div>
          <p class="text-xs font-normal normal-case text-text-level03">{t('Please drag the image to reorder')}</p>
        </div>
      }>
      <PhotosEditor
        name="listing-details-photo"
        defaultFileList={images()}
        onInput={handlePhotosInput}
        maxCount={10}
        accept={imageFileAccept}
      />
    </Panel>
  );
};
