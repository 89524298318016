import type { HydrateFile } from './Interface';
import type { PreviewFile } from '~/components/file-attachments';

const now = +new Date();
let index = 0;

export function getUid() {
  return `upload-${now}-${++index}`;
}

type APIFileType = {
  fileId: string;
  fileName: string;
  signedUrl?: string;
  signedThumbUrl?: string | null;

  fileUrl?: string | null;
  thumbUrl?: string | null;

  description?: string | null;
};

export const parseFilesFromAPIToUploaderDefaultFileList = (files?: APIFileType[]): HydrateFile[] | undefined => {
  return files?.map<HydrateFile>((file) => ({
    fileId: file.fileId,
    fileName: file.description || file.fileName,
    fileUrl: file.signedUrl || file.fileUrl || '',
    thumbUrl: file.signedThumbUrl || file.thumbUrl || file.signedUrl || '',
  }));
};

export const hydrateFileToPreviewFile = (file: HydrateFile): PreviewFile => {
  const fileUrl = file.fileUrl || '';
  return {
    fileId: file.fileId,
    fileName: file.fileName,
    fileUrl: fileUrl,
    fileThumbUrl: file.thumbUrl || fileUrl,
  };
};
