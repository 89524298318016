import { LeaseStatus } from '~/components/leases';
import { UnitImage } from '~/components/units';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { HydratedLeaseDto } from '~/swagger/Api';

export interface LeaseOverviewBannerProps {
  lease: HydratedLeaseDto;
  class?: string;
}

export const LeaseOverviewBanner = (props: LeaseOverviewBannerProps) => {
  return (
    <div class={cn('flex h-16 items-center justify-items-start rounded-lg bg-slate-100 p-2', props.class)}>
      <UnitImage class="size-12 rounded-lg" unitId={props.lease.unit.id} imageId={props.lease.unit.imageId} />
      <div class="ml-2">
        <div class="text-sm">
          {props.lease.property.address?.streetAddress1}-{props.lease?.unit?.name ?? emptyPlaceholder}
        </div>
        <LeaseStatus lease={props.lease} />
      </div>
    </div>
  );
};
