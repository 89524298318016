import { Form, FormItem, useForm } from '@magicdoor/form';
import { useSearchParams, Link } from '@solidjs/router';
import { createEffect, createSignal, onMount, Show } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import LocalizationButton from '~/components/layouts/Navigation/LocalizationButton/LocalizationButton';
import { AuthPageContainer } from '~/components/reset-password/AuthPageContainer';
import { Input } from '~/components/reset-password/InputField';
import { restoreEmail, saveEmail } from '~/components/reset-password/emailStorage';
import { IconLoader } from '~/components/ui';
import { useAuth, useLocalization } from '~/contexts/global';
import { CompaniesRepository } from '~/repositories/companiesRepository';

const companiesRepo = new CompaniesRepository();

const SignInPage = () => {
  const { t } = useLocalization();
  const [query] = useSearchParams<{ redirect?: string }>();

  const [error, setError] = createSignal<string | undefined>();
  const [companies, setCompanies] = createSignal<{ id: string; name: string }[] | undefined>();
  const signInForm = useForm();

  const { signIn, isAuthenticated, checkUrlForToken } = useAuth();

  const redirect = (path?: string) => location.replace(path ?? '/');

  const initAndStoreCompanies = async () => {
    let path = query.redirect ?? '/';
    try {
      const companies = await companiesRepo.getCompanies();
      const localCompanyId = localStorage.getItem('companiesId');
      if (localCompanyId !== companies.id) {
        path = '/';
      }
      localStorage.setItem('companiesId', companies.id);
    } finally {
      redirect(path);
    }
  };

  createEffect(() => {
    checkUrlForToken();
    if (isAuthenticated()) {
      initAndStoreCompanies();
    }
  });

  onMount(() => {
    restoreEmail(signInForm);
  });

  const handleSubmit = async (store: any) => {
    const email = store.email;
    const password = store.password;
    const remember = store.remember;
    const companyId = store.company;

    const result = await signIn(email, password, companyId, remember);

    if (result.success) {
      initAndStoreCompanies();
      return;
    }

    if (result.companies) {
      signInForm.setFieldValue('company', result.companies[0].id);
      setError(undefined);
      setCompanies(result.companies);
      return;
    }

    if (result.error) {
      const errorMap: Record<typeof result.error, string> = {
        invalid_credentials: t('Incorrect email or password'),
        bad_request: t('Email and password are required'),
      };
      setError(errorMap[result.error] || t('Unknown error'));
    }
  };

  return (
    <AuthPageContainer>
      <Form defaultForm={signInForm} onFormSubmit={handleSubmit} class="flex flex-col rounded-lg border-0 text-title-gray lg:max-w-[412px]">
        <div class="mb-5 flex items-center justify-between">
          <h1 class="text-2xl font-semibold">
            <span>{t('Login')}</span>&nbsp;
            <span class="text-essential-colour">MagicDoor</span>
          </h1>
          <LocalizationButton />
        </div>
        <div class={`${companies()?.length ? 'hidden' : 'flex'} flex-col gap-4 text-left`}>
          <Input
            label={t('Email')}
            rules={[
              {
                required: true,
                message: t('{name} is required', { name: t('Email') }),
              },
              {
                type: 'email',
                message: t('{name} is invalid', { name: t('Email') }),
              },
            ]}
            placeholder="mail@magicdoor.com"
            formFieldName="email"
            onChange={(e) => {
              saveEmail(e.target.value);
            }}
          />

          <Input
            label={t('Password')}
            type="password"
            rules={[
              { required: true, message: t('{name} is required', { name: t('Password') }) },
              {
                length: [5, 150],
                message: t('{name} must be at least {minLength} characters', { name: t('Password'), minLength: '5' }),
              },
            ]}
            placeholder={t('Enter password')}
            formFieldName="password"
          />
          <div class="flex items-center justify-between gap-2">
            <FormItem formFieldName="remember" component={Checkbox} checkBoxClass="text-sm" label={t('Remember me')} showLabel={true} />
            <Link class="text-sm text-essential-colour hover-allowed:hover:underline" href="/reset-password">
              {t('Forgot password?')}
            </Link>
          </div>
          <div class="flex gap-2">
            <Checkbox
              checkBoxClass="items-start text-sm"
              name="policy"
              checked
              required
              showLabel
              labelClass="inline-block w-[280px] whitespace-normal md:w-[350px]"
              label={
                <>
                  {t('By signing in, I agree to the')}
                  <a
                    class="m-1 text-blue hover-allowed:hover:underline"
                    href="/terms-of-service.pdf"
                    rel="noopener noreferrer"
                    target="_blank">
                    {t('Terms of service')}
                  </a>
                  {t('And')}
                  <a
                    class="ml-1 text-blue hover-allowed:hover:underline"
                    href="https://magicdoor.com/privacy-policy/"
                    rel="noopener noreferrer"
                    target="_blank">
                    {t('Privacy policy')}
                  </a>
                </>
              }
            />
          </div>
        </div>
        <Show when={companies()?.length}>
          <FormItem
            label={t('Please select the company you would like to log in to')}
            labelClass="text-sm font-medium normal-case text-title-gray"
            formFieldName="company"
            component={LabeledSelect}
            options={companies()?.map((company) => ({ type: 'option', label: company.name, value: company.id })) ?? []}
            selectClass="h-14 rounded-full bg-inputbox-bg px-6"
            rules={[{ required: true, message: t('Please select company') }]}
          />
        </Show>
        <Show when={error()}>
          <div class="-mb-2 mt-2 text-center text-danger">{error()}</div>
        </Show>
        <button class="mt-6 flex justify-center rounded-full bg-essential-colour py-3.5 text-sm font-medium text-white transition-colors hover:bg-essential-colour/90">
          <Show when={signIn.pending} fallback={t('Login')}>
            <IconLoader class="size-5 animate-spin" />
          </Show>
        </button>
      </Form>
    </AuthPageContainer>
  );
};

export default SignInPage;
