import { createSignal, createEffect } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { ChatRepository } from '~/repositories/chatRepository';
import { createLazyResource } from '~/utils/resource';
import type { SendChatData } from '~/repositories/chatRepository';
import type { ChatMessageDto } from '~/swagger/Api';

export const [ChatProvider, useChat] = createMagicDoorContext('Chat', () => {
  const repo = new ChatRepository();
  const { t } = useLocalization();

  const [chat, setChat] = createSignal<MagicDoor.Api.HydratedMagicChatDto>();
  const [chatMessages, setChatMessages] = createSignal<MagicDoor.Api.ChatMessageDto[]>([]);
  const [searchMessages, setSearchMessages] = createSignal<MagicDoor.Api.ChatMessageDto[]>([]);

  const [chatId, setChatId] = createSignal<string | undefined>(undefined);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const [hasMoreMessages, setHasMoreMessages] = createSignal<boolean>(true);
  const [newChat, setNewChat] = createSignal<MagicDoor.Api.ChatMessageDto[]>([]);
  const [isInitialized, setIsInitialized] = createSignal<boolean>(false);

  const [isAfterLoading, setIsAfterLoading] = createSignal<boolean>(false);
  const [isSearchMode, setIsSearchMode] = createSignal<boolean>(false);
  const [hasMoreAfterMessages, setHasMoreAfterMessages] = createSignal<boolean>(false);
  const [hasLoadedSearchAfter, setHasLoadedSearchAfter] = createSignal(false);
  const [latestMessage, setLatestMessage] = createSignal<MagicDoor.Api.ChatMessageDto | null>(null);
  const [jumpToMessage, setJumpToMessage] = createSignal<MagicDoor.Api.ChatMessageDto | null>(null);

  const [canCompanySendTextMessages] = createLazyResource(() => repo.canCompanySendTextMessages());

  const initializeChat = async (id: string) => {
    if (!isInitialized() || chatId() !== id) {
      setIsLoading(true);
      try {
        const chatData = await fetchChatById(id);
        const messages = await fetchChatMessagesById(id);
        setChatId(id);
        setChat(chatData);
        setChatMessages(messages || []);
        setIsInitialized(true);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetChat = () => {
    setChatId(undefined);
    setChat(undefined);
    setChatMessages([]);
    setIsInitialized(false);
  };

  const addNewMessage = (newMessage: any) => {
    setChatMessages((prevMessages) => [newMessage, ...prevMessages]);
  };

  const removeMessage = (messageId: string) => {
    setChatMessages((prevMessages) => prevMessages.filter((message) => message.id !== messageId));
  };

  const fetchChatById = async (chatId: string): Promise<MagicDoor.Api.HydratedMagicChatDto> => {
    setIsLoading(true);
    try {
      const chatData = await repo.getChat(chatId);
      if (!chatData) {
        throw new Error('Chat data not found');
      }
      setChat(chatData);

      return chatData;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChatMessagesById = async (chatId1: string, before?: string, after?: string, take = 20, search?: string, fileName?: string) => {
    return getChatMessageById('chat', chatId1, before, after, take, search, fileName);
  };

  const fetchSearchMessagesById = async (
    chatId1: string,
    before?: string,
    after?: string,
    take = 20,
    search?: string,
    fileName?: string
  ) => {
    return getChatMessageById('search', chatId1, before, after, take, search, fileName);
  };

  const messageSetters: Record<'chat' | 'search', typeof setChatMessages> = {
    chat: setChatMessages,
    search: setSearchMessages,
  };
  const getChatMessageById = async (
    type: 'chat' | 'search',
    chatId1: string,
    before?: string,
    after?: string,
    take = 20,
    search?: string,
    fileName?: string
  ) => {
    if (!chatId) return;

    const isSameChatNewSearch = latestMessage() ? after !== latestMessage()!.sentAt : false;

    if (isSearchMode() && (chatId1 !== chatId() || isSameChatNewSearch)) {
      setHasLoadedSearchAfter(false);
      setLatestMessage(null);
    }

    try {
      isSearchMode() ? setIsAfterLoading(true) : setIsLoading(true);
      const data = await repo.getChatMessages(chatId1, before, after, take, search, fileName);
      if (data.length < take) {
        if (after) {
          setHasMoreAfterMessages(false);
          setHasMoreMessages(true);
        } else {
          setHasMoreMessages(false);
        }
      } else {
        after ? setHasMoreAfterMessages(true) : setHasMoreMessages(true);
      }

      if (before) {
        messageSetters[type]((prev) => [...prev, ...data]);
      } else if (after) {
        const reverseData = data.reverse();
        const finalData = (prev: ChatMessageDto[]) => {
          if (isSearchMode()) {
            setLatestMessage(data[0]);

            if (!hasLoadedSearchAfter()) {
              setHasLoadedSearchAfter(true);
              jumpToMessage() && reverseData.push(jumpToMessage() as MagicDoor.Api.ChatMessageDto);
              setJumpToMessage(null);
              return reverseData;
            }

            return [...reverseData, ...prev];
          } else {
            return [...reverseData, ...prev];
          }
        };
        messageSetters[type](finalData);
      } else {
        messageSetters[type](data);
      }

      return data;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      isSearchMode() ? setIsAfterLoading(false) : setIsLoading(false);
    }
  };

  createEffect(() => {
    setChatMessages([]);
  });

  const sendChat = async (chatId: string, chatData: SendChatData, retryCount = 3): Promise<Response | void> => {
    setIsLoading(true);
    try {
      const result = await repo.sendChatMessage(chatId, chatData);
      await fetchChatMessagesById(chatId);
      return result;
    } catch (err) {
      if (retryCount > 0) {
        return await sendChat(chatId, chatData, retryCount - 1);
      } else {
        setError(err instanceof Error ? err : new Error(String(err)));
        toast.error(t('Operation failed, please try again later'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const chatMagicIt = async (chatId: string, chatData: string): Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined> => {
    setIsLoading(true);

    try {
      const response = await repo.sendChatMagicIt(chatId, chatData);
      await fetchChatMessagesById(chatId);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const createChat = async (entryType: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`, entryId: string) => {
    setIsLoading(true);
    try {
      const response = await repo.createChatWindow(entryType, entryId);
      if (response && response.chatId) {
        return { chatId: response.chatId };
      } else {
        toast.error(response.failureReason);
        throw new Error('Failed to create a new chatMessages. No chatId returned.');
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const createTextChat = async (request: MagicDoor.Api.CreatePhoneChatDto) => {
    setIsLoading(true);
    try {
      const response = await repo.createTextMessageChat(request);
      if (response && response.chatId) {
        return { chatId: response.chatId };
      } else {
        toast.error(response.failureReason);
        throw new Error('Failed to create a new chatMessages. No chatId returned.');
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const editChat = async (
    chatId: string,
    chatData: MagicDoor.Api.UpdateChatDto
  ): Promise<MagicDoor.Api.HydratedMagicChatDto | undefined> => {
    setIsLoading(true);
    try {
      const response = await repo.updateChat(chatId, chatData);
      setChat(response);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const markMessagesAsRead = async (chatId: string) => {
    try {
      await repo.markMessagesAsRead(chatId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    }
  };

  function getParticipantName(
    chatMessage: MagicDoor.Api.ChatMessageDto,
    chatDetails: MagicDoor.Api.HydratedMagicChatDto
  ): string | undefined | null {
    if (chatMessage.participantId && chatDetails.participants) {
      const participant = chatDetails.participants.find((p) => p.id === chatMessage.participantId);
      return participant ? participant.name : undefined;
    }
    return undefined;
  }

  const rewriteMessage = async (chatId: string, messageId: string): Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined> => {
    setIsLoading(true);
    try {
      const message = await repo.paraphraseMessage(chatId, messageId);
      return message;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const archiveChat = async (chatId: string) => {
    setIsLoading(true);
    try {
      const response = await repo.archiveChat(chatId);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    sendChat,
    fetchChatMessagesById,
    setChatId,
    chatMessages,
    isLoading,
    archiveChat,
    hasMoreMessages,
    editChat,
    rewriteMessage,
    setHasMoreMessages,
    error,
    chat,
    chatId,
    getParticipantName,
    createChat,
    newChat,
    createTextChat,
    markMessagesAsRead,
    addNewMessage,
    setChatMessages,
    chatMagicIt,
    setNewChat,
    fetchChatById,
    isInitialized,
    initializeChat,
    resetChat,
    removeMessage,
    isAfterLoading,
    setIsAfterLoading,
    isSearchMode,
    setIsSearchMode,
    hasMoreAfterMessages,
    setHasMoreAfterMessages,
    hasLoadedSearchAfter,
    jumpToMessage,
    setJumpToMessage,
    searchMessages,
    fetchSearchMessagesById,
    canCompanySendTextMessages,
  };
});
