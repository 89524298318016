import { Show, createSignal, createMemo } from 'solid-js';
import IconFacebook from '~/assets/images/settings/portal/facebook.svg?component-solid';
import IconInstagram from '~/assets/images/settings/portal/instagram.svg?component-solid';
import IconLinkedin from '~/assets/images/settings/portal/linkedin.svg?component-solid';
import IconSocialMedia from '~/assets/images/settings/portal/portalSocialMedia.svg';
import IconTikTok from '~/assets/images/settings/portal/tiktok.svg?component-solid';
import IconTwitter from '~/assets/images/settings/portal/twitter.svg?component-solid';
import IconYouTube from '~/assets/images/settings/portal/youtube.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { InformationForm } from '~/pages/settings/components/InformationForm';
import { InformationOverview } from '~/pages/settings/components/InformationOverview';
import { getUrlValidator } from '~/utils/validations';
import type { Section } from '~/pages/settings/account-settings/types';

export const PortalSocialMedia = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<boolean>(false);
  const { portalSettings, updatePortalSettings } = useCompanies();

  const sections = createMemo<Section[]>(() => {
    const model = portalSettings()?.socialLinks;
    if (model == null) return [];
    return [
      {
        field: 'facebook',
        value: model.facebook,
        label: 'Facebook',
        icon: IconFacebook,
        rules: [
          {
            validator: (value: string) => getUrlValidator(value),
            message: t('Please enter the correct url'),
          },
        ],
      },
      {
        field: 'instagram',
        value: model.instagram,
        label: 'Instagram',
        icon: IconInstagram,
        rules: [
          {
            validator: (value: string) => getUrlValidator(value),
            message: t('Please enter the correct url'),
          },
        ],
      },
      {
        field: 'twitter',
        value: model.twitter,
        label: 'Twitter',
        icon: IconTwitter,
        rules: [
          {
            validator: (value: string) => getUrlValidator(value),
            message: t('Please enter the correct url'),
          },
        ],
      },
      {
        field: 'linkedIn',
        value: model.linkedIn,
        label: 'Linkedin',
        icon: IconLinkedin,
        rules: [
          {
            validator: (value: string) => getUrlValidator(value),
            message: t('Please enter the correct url'),
          },
        ],
      },
      {
        field: 'youTube',
        value: model.youTube,
        label: 'YouTube',
        icon: IconYouTube,
        rules: [
          {
            validator: (value: string) => getUrlValidator(value),
            message: t('Please enter the correct url'),
          },
        ],
      },
      {
        field: 'tikTok',
        value: model.tikTok,
        label: 'TikTok',
        icon: IconTikTok,
        rules: [
          {
            validator: (value: string) => getUrlValidator(value),
            message: t('Please enter the correct url'),
          },
        ],
      },
    ];
  });
  const handleSubmit = async (info: Record<string, string | null>) => {
    const params = { ...portalSettings(), socialLinks: info };
    try {
      await updatePortalSettings(params);
    } catch (err) {
      console.log(err);
    } finally {
      setEditing(false);
    }
  };
  return (
    <ThreeSectionBox
      class="border-0 capitalize"
      topTitle={<SettingTitle title={t('Social media')} icon={IconSocialMedia} />}
      middle={
        <div class="p-3 md:p-6">
          <Show when={editing()} fallback={<InformationOverview sections={sections()} onEdit={() => setEditing(true)} title="Links" />}>
            <InformationForm sections={sections()} onClose={() => setEditing(false)} onSubmit={handleSubmit} />
          </Show>
        </div>
      }
    />
  );
};
