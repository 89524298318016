import { unwrap } from 'solid-js/store';
import { BankAccountMask } from '~/components/common/BankAccountMask';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { LabeledGroup } from '~/components/common/Inputs';
import { Uploader } from '~/components/common/Upload';
import { Modal } from '~/components/modals/Modal';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { proofFileAccept } from '~/utils/file';
import { createMutation } from '~/utils/resource';
import type { HydrateFile } from '~/components/common/Upload';
import type { ConnectBankAccountDto } from '~/swagger/Api';
export const LinkPlaidModal = (props: { visible: boolean; onDone: (values: ConnectBankAccountDto) => void; onCancel: () => void }) => {
  const { t } = useLocalization();
  const { bankAccount } = useBankAccount();

  const form = useForm();

  const handleDone = createMutation(async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    await props.onDone(unwrap(form.formStore));
    props.onCancel();
  });

  return (
    <Modal title="Link Plaid" visible={props.visible} onCancel={props.onCancel} onDone={handleDone} loading={handleDone.loading}>
      <Form defaultForm={form} class={cn('flex flex-col gap-4 p-6')}>
        <LabeledGroup label={t('Account number')}>
          <BankAccountMask accountMask={bankAccount()?.accountNumber} />
        </LabeledGroup>

        <LabeledGroup label={t('Routing number')}>
          <BankAccountMask accountMask={bankAccount()?.routingNumber} />
        </LabeledGroup>

        <FormItem
          component={Uploader.LabeledPreUploader}
          label={t('Verify ownership for this account')}
          formFieldName="statementFileIds"
          accept={proofFileAccept}
          maxSize={2}
          maxCount={2}
          getValueFromEvent={(files: HydrateFile[]) => {
            return files.map((file) => file.fileId);
          }}
          rules={[
            { required: true, message: t('Drop files to upload') },
            { length: [2, 2], message: t('Please upload {count} files', { count: 2 }) },
          ]}
          description={t(
            'Upload two bank statements to verify your connected bank. Documents must include your business name and the account and routing number.'
          )}
        />
      </Form>
    </Modal>
  );
};
