import { createContext, createEffect, createMemo, onCleanup, splitProps, useContext } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { safeCall } from '~/utils/tool';
import { InputLabel } from './Components';
import { getValueFromStore, useFormContext } from './context';
import { FormListContext } from './formListContext';
import { combineNamePaths, isFalsy, isRequired } from './utils';
import type { BetterForm } from './type';
import type { Accessor, ValidComponent } from 'solid-js';

const defaultOnChangeMethodName = 'onInput';
const defaultGetValueFromEvent = (val: any) => val;

const formItemContext = createContext<{ formFieldName: Accessor<BetterForm.NamePath> }>();

export const useFormItemContext = () => {
  return useContext(formItemContext);
};

export const FormItem = function <T extends ValidComponent>(_props: BetterForm.FormItemProps<T>) {
  const formContext = useFormContext();
  const formListContext = useContext(FormListContext);

  const [localProps, componentProps] = splitProps(_props, [
    'component',
    'formFieldName',
    'onChangeMethodName',
    'label',
    'rules',
    'removeValueOnClean',
    'customSyncValue',
    'customGetValue',
    'getValueFromEvent',
    'valuePropName',
  ]);
  if (!formContext) throw new Error('FormItem must be used within a FormWrapper');

  const realFormFieldName = createMemo(() => {
    if (formListContext && localProps.formFieldName) {
      return combineNamePaths(formListContext.formFieldName, [formListContext.index], localProps.formFieldName);
    }
    return localProps.formFieldName;
  });

  const getIdStr = (): string | undefined => {
    const name = realFormFieldName();
    if (name instanceof Array) {
      return name.join('_');
    }
    return name;
  };

  const labelMemo = createMemo(() =>
    localProps.label ? (
      <InputLabel
        id={getIdStr()}
        description={componentProps.description}
        label={localProps.label}
        required={!!isRequired(localProps.rules)}
      />
    ) : null
  );

  const onChangeMethodName = createMemo<string>(() => localProps.onChangeMethodName || defaultOnChangeMethodName);

  createEffect(() => {
    const _realFormFieldName = realFormFieldName();
    if (localProps.rules && _realFormFieldName) {
      formContext.setFormFieldRules(localProps.rules, _realFormFieldName);
    }
  });

  onCleanup(() => {
    const _realFormFieldName = realFormFieldName();
    if (_realFormFieldName) {
      formContext.removeFormField(_realFormFieldName);
    }
    if (localProps.removeValueOnClean && _realFormFieldName) {
      formContext.removeFormField(_realFormFieldName, { removeValue: true });
    }
  });

  const value = createMemo(() => {
    if (typeof localProps.customGetValue === 'function') {
      return localProps.customGetValue(formContext);
    }
    return getValueFromStore(formContext.formStore, realFormFieldName());
  });

  const getValueFromEvent = createMemo(() => {
    if (localProps.getValueFromEvent) return localProps.getValueFromEvent;
    return defaultGetValueFromEvent;
  });

  return (
    <formItemContext.Provider value={{ formFieldName: realFormFieldName }}>
      <Dynamic
        {...componentProps}
        component={localProps.component as any}
        label={localProps.label}
        labelJSX={labelMemo()}
        {...{
          [localProps.valuePropName || 'value']: value(),
          [onChangeMethodName()]: (...args: any[]) => {
            const value = args[0];
            const isEmpty =
              isFalsy(value) ||
              (Array.isArray(value) && value.length === 0) ||
              (typeof value === 'object' && Object.keys(value).length === 0);

            const realValue = isEmpty ? undefined : getValueFromEvent()(value);

            if (localProps.customSyncValue) {
              localProps.customSyncValue(formContext, realValue);
            } else {
              formContext.setFieldValue(realFormFieldName(), realValue);
            }
            safeCall(componentProps[onChangeMethodName() as keyof typeof componentProps] as any, ...args);
            formContext.onFieldChange?.();
          },
          error: formContext.getFormFieldError(realFormFieldName()),
        }}
      />
    </formItemContext.Provider>
  );
};
