import { Show, createEffect, createSignal } from 'solid-js';
import IconChatTag from '~/assets/images/chat/chatTag.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import { ChatContentPrint } from '~/pages/chats/chat-details/components/ChatContentPrint';
import { ChatTypes } from '~/swagger/Api';
import { highlightText } from './ChatContentSearch';
import type { JSX } from 'solid-js';
import type { ChatMessageProps as OriginalChatMessageProps } from '~/pages/chats/chat-details/components/hooks/UseMessageUtils';

type ChatMessageProps = Omit<OriginalChatMessageProps, 'allMessage'>;

export const MessageContentSearch = (
  props: ChatMessageProps & { highlightText?: string; handleJump?: () => void; showHeaderTag?: boolean }
) => {
  const { t } = useLocalization();

  const [showJump, setShowJump] = createSignal(false);
  const [ready, setReady] = createSignal(false);
  const [headerTag, setHeaderTag] = createSignal<JSX.Element | null>(null);

  const handleJump = () => {
    props.handleJump && props.handleJump();
  };

  createEffect(() => {
    setReady(true);
  });

  createEffect(() => {
    if (props.showHeaderTag && props.chatDetails && props.chatDetails.type !== ChatTypes.Unknown) {
      setHeaderTag(
        <div class="flex gap-1 text-text-level02">
          <IconChatTag />
          <p>{props.highlightText ? highlightText(props.chatDetails.subject, props.highlightText, 'JSX') : props.chatDetails.subject}</p>
        </div>
      );
    }
  });

  return (
    <div class="group relative px-6 hover:bg-[#A126EC0A]" onMouseEnter={() => setShowJump(true)} onMouseLeave={() => setShowJump(false)}>
      <Show when={ready()}>
        <ChatContentPrint
          message={props.message}
          allMessage={[]}
          chatDetails={props.chatDetails}
          headerTag={headerTag()}
          highlightText={props.highlightText}
        />
      </Show>
      <Show when={showJump()}>
        <button
          onClick={handleJump}
          class="absolute right-6 top-6 -translate-y-1/2 rounded-full border border-essential-colour px-2 align-top text-sm text-essential-colour hover:bg-[#a126ec30]">
          {t('Jump')}
        </button>
      </Show>
    </div>
  );
};
