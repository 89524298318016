import { useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { Panel } from '~/components/common/Panels';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount, useBankAccountsList } from '~/contexts/local';
import { isEmptyData } from '~/utils/tool';
import BankAccountForm from './BankAccountForm';
import type { Setter } from 'solid-js';

interface Props {
  onClose: () => void;
  setShowCloseConfirmation?: Setter<boolean>;
  source?: 'list' | 'details';
}

function EditBankAccount(props: Props) {
  const { t } = useLocalization();

  const form = useForm();

  const { bankAccountId } = useParams();

  const { updateBankAccount, bankAccount } = useBankAccount();
  const { fetchBankAccounts } = useBankAccountsList();

  createEffect(() => {
    if (bankAccount()) {
      form.setFormStore({
        name: bankAccount()?.name,
        description: bankAccount()?.description,
        scheduleSweepDays: bankAccount()?.scheduleSweepDays,
      });
    }
  });

  const handleEditBankAccount = async () => {
    await updateBankAccount(bankAccountId, form.formStore as MagicDoor.Api.UpdateBankAccountDto);
    props.onClose();
    toast.success(t('{name} has been edited successfully', { name: 'BankAccount' }));
    fetchBankAccounts();
  };

  createEffect(() => {
    props.setShowCloseConfirmation && props.setShowCloseConfirmation(!isEmptyData(form.formStore));
  });

  return (
    <Panel title={t('Edit bank account')} class="h-full">
      <BankAccountForm
        class="h-full justify-between"
        isEdit={true}
        form={form}
        bankAccount={bankAccount()}
        onSubmit={handleEditBankAccount}
      />
    </Panel>
  );
}

export default EditBankAccount;
