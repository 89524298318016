import { A, useNavigate } from '@solidjs/router';
import { For, Show, createEffect } from 'solid-js';
import { DeactivatedFlag } from '~/components/common';
import { LinkButton } from '~/components/common/Buttons';
import { PortfolioTitle } from '~/components/portfolios';
import { IconCirclePlus, IconSquarePen, Skeleton, TabLinks, Select } from '~/components/ui';
import { Warning } from '~/components/warning';
import { useLocalization } from '~/contexts/global';
import { IconBuidingBackground, IconBuildLocation } from './Icons';
import type { Component } from 'solid-js';

const PortfolioInfo: Component<{ portfolio?: MagicDoor.Api.HydratedPortfolioDto }> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="w-full divide-y divide-white/20 rounded-lg bg-essential-colour text-white">
      <Show when={props.portfolio && !props.portfolio.active}>
        <Warning text={t('This portfolio has been deactivated and is no longer operational.')} />
      </Show>
      <div class="relative flex w-full items-center gap-2 overflow-hidden px-3 py-2 md:px-6 md:py-5">
        <IconBuidingBackground class="absolute bottom-0 right-48 hidden md:block" />
        <PortfolioTitle portfolio={props.portfolio} />
        <A
          href={props.portfolio ? `/portfolios/${props.portfolio.id}/edit` : '#'}
          class="ml-auto flex shrink-0 items-center gap-1.5 rounded-md border border-white p-2.5 text-sm font-medium text-white transition-colors hover-allowed:hover:bg-white/10 md:px-4"
          noScroll>
          <IconSquarePen class="size-4 md:size-5" />
          <div class="hidden md:flex">{t('Edit portfolio')}</div>
        </A>
      </div>
      <TabLinks
        class="bg-black/10 text-inherit *:text-inherit"
        items={[
          { label: t('Portfolio overview'), href: '' },
          { label: t('Announcements'), href: `announcements` },
        ]}
      />
    </div>
  );
};

export const PortfolioTabs: Component<{
  portfolios?: MagicDoor.Api.HydratedPortfolioDto[];
  selected?: MagicDoor.Api.HydratedPortfolioDto;
}> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  let ref: HTMLDivElement | undefined;

  createEffect(() => {
    if (props.selected == null || ref == null) return;
    const selectedTab = ref.querySelector('[aria-selected=true]');
    if (selectedTab == null) return;
    setTimeout(() => selectedTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }));
  });

  return (
    <>
      <div class="flex rounded-lg border border-partingline bg-white">
        <div class="flex w-full items-center gap-2 px-2 py-1.5 md:hidden">
          <div class="flex-1">
            <Select
              class="h-10 border-essential-colour text-essential-colour"
              options={props.portfolios?.map((portfolio) => ({ label: portfolio.name, value: portfolio.id })) ?? []}
              value={props.selected?.id}
              onChange={(value) => navigate(`/portfolios/${value}`)}
            />
          </div>

          <div class="w-fit">
            <LinkButton href="/portfolios/add" class="h-10 p-[10px] font-medium" noScroll>
              <IconCirclePlus class="text-white" />
            </LinkButton>
          </div>
        </div>

        <div class="hidden w-full md:flex">
          <div
            ref={ref}
            class="thinscroll ml-3 flex w-fit flex-1 select-none items-center gap-4 overflow-x-scroll whitespace-nowrap px-3 pb-1 pt-4"
            role="tablist">
            <Show when={props.portfolios} fallback={<For each={Array.from({ length: 5 })}>{() => <Skeleton class="h-10 w-36" />}</For>}>
              <For each={props.portfolios}>
                {(item) => (
                  <A
                    class="group flex h-10 items-center gap-1 rounded-lg px-4 py-2 font-medium text-text-level02 transition-colors aria-selected:bg-essential-colour aria-selected:text-white hover-allowed:hover:bg-essential-colour/90 hover-allowed:hover:text-white"
                    href={`/portfolios/${item.id}`}
                    role="tab"
                    aria-selected={item === props.selected}>
                    <IconBuildLocation class="hidden size-0 transition-all group-aria-selected:size-6 md:flex" />
                    <span class="w-fit max-w-32 truncate">{item.name}</span>
                    <DeactivatedFlag active={item.active} />
                  </A>
                )}
              </For>
            </Show>
          </div>
          <div class="w-fit px-2 py-4 shadow-[-4px_0_4px_0_#00000014] md:px-6">
            <LinkButton href="/portfolios/add" class="font-medium" noScroll>
              <IconCirclePlus class="text-white" />
              <div class="hidden md:flex">{t('Create a portfolio')}</div>
            </LinkButton>
          </div>
        </div>
      </div>
      <PortfolioInfo portfolio={props.selected} />
    </>
  );
};
