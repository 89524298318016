import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import RobotCloseEye from '~/assets/images/dashboard/robotCloseEye.svg';
import RobotOpenEye from '~/assets/images/dashboard/robotOpenEye.svg';
import { Button } from '~/components/common/Buttons';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationPaymentSettings, useBankAccountsList } from '~/contexts/local';

export const ApplyRentalApplicationCard = () => {
  const { updateRentalApplicationPaymentSettings, rentalApplicationPaymentSettings } = useRentalApplicationPaymentSettings();

  const { t } = useLocalization();
  const navigate = useNavigate();
  const { bankAccounts } = useBankAccountsList();
  const [step, setStep] = createSignal<number>(0);
  const [hasStripeConnection, setHasStripeConnection] = createSignal<boolean>(false);

  createEffect(() => {
    const connectedStripeAccount = bankAccounts()?.find((account) => account?.stripe?.status === 'approved');
    if (!connectedStripeAccount) {
      setStep(0);
    } else {
      setStep(2);
    }

    setHasStripeConnection(!!connectedStripeAccount);
  });

  const handleRadioChange = async (requirePaymentForApplication: boolean) => {
    const paymentSettings = rentalApplicationPaymentSettings();
    await updateRentalApplicationPaymentSettings({
      requirePaymentForApplication,
      applicationFee: paymentSettings?.applicationFee ?? 0,
    } as MagicDoor.Api.RentalApplicationPaymentSettingsDto);

    if (requirePaymentForApplication) setStep(2);
    if (!requirePaymentForApplication) setStep(1);
  };

  const navigateToSettings = () => {
    navigate('/settings/rental/applicationfee');
  };

  return (
    <div class="normal-case">
      <Show when={step() === 0}>
        <section class="flex flex-col items-center text-center">
          <img src={RobotCloseEye} alt="Bank account not connected img" class="my-auto mt-3" />
          <h1 class="text-base font-medium capitalize text-title-gray">{t('Bank account not connected')}</h1>
          <div class="mb-6 mt-2 px-10 text-xs text-text-level03">
            {t('Please add a bank account to enable the rental application submission feature.')}
          </div>
          <Button color="primary" variant="solid" class="rounded-full" onClick={() => setStep(1)}>
            {t('Add account')}
          </Button>
        </section>
      </Show>
      <section>
        <Show when={step() !== 0}>
          <div class="flex items-center gap-2 bg-primary/5 px-5 py-4">
            <img src={RobotOpenEye} alt="img" />
            <div class="text-base text-primary">{t('Complete the following steps to activate this feature.')}</div>
          </div>
        </Show>
        <Show when={step() === 1}>
          <div class="mt-14 flex flex-col items-center justify-center">
            <h1 class="px-10 text-center text-base font-medium text-primary">{t('Do you want to require an application fee')}?</h1>
            <div class="mt-4 flex justify-center gap-2">
              <Button color="primary" variant="outlined" rounded="full" onClick={() => handleRadioChange(true)}>
                {t('Yes')}
              </Button>
              <Button color="primary" variant="outlined" rounded="full" onClick={() => handleRadioChange(false)}>
                {t('No')}
              </Button>
            </div>
          </div>
        </Show>
        <Show when={step() === 2}>
          <div class="p-6 px-7">
            <div class="relative border-l border-dashed border-primary pb-8 pl-6">
              <CircularProgress class="absolute -left-3 top-0 size-6 text-primary" value={100} />
              <h2 class="text-base text-title-gray">{t('Application fee requirement')}</h2>
              <div class="mt-2 text-base text-primary">{t('Yes')}</div>
            </div>
            <div class="relative pl-6">
              <CircularProgress class="absolute -left-3 top-0 size-6 text-primary" value={hasStripeConnection() ? 100 : 0} />
              <h2 class="mb-2 text-base text-title-gray">{t('Bank account link to stripe')}</h2>
              <Button color="primary" variant="solid" size="xs" class="rounded-full" onClick={navigateToSettings}>
                {t('Set')}
              </Button>
            </div>
          </div>
        </Show>
      </section>
    </div>
  );
};
