import { useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import IconField from '~/components/common/IconField';
import { PrintTablePageButton } from '~/components/print/PrintTablePageButton';
import { TenantTitle } from '~/components/tenants';
import { IconClock, IconHash, ListView } from '~/components/ui';
import { useDepositSlips, useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { getBankAccountName, getTenant } from '~/pages/deposit-slips/utils';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { TableColumns } from '~/components/ui';
export const DepositSlipDetail = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const breadcrumbItems = createMemo(() => [
    { label: t('Deposit slips'), link: '/accounting/deposit-slips' },
    { label: t('Deposit slip detail') },
  ]);

  const { getDepositSlip } = useDepositSlips();
  const [depositSlip, setDepositSlip] = createSignal<MagicDoor.Api.DepositSlipDto>();
  const params = useParams<{ depositSlipId?: string }>();

  const columns = createMemo(() => {
    const baseColumns: TableColumns<MagicDoor.Api.TransactionDto> = [
      {
        title: t('Date finalized'),
        render: (row) => <div>{dateFormat(t('MM/DD/YYYY'), row.transactionFinalizedDate)}</div>,
      },

      {
        title: t('Payment method'),
        render: (row) => <div>{row.transactionPaymentMethod || emptyPlaceholder}</div>,
      },
      {
        title: t('Reference'),
        render: (row) => <div>{row.reference || emptyPlaceholder}</div>,
      },

      {
        title: `${t('Memo')}/${t('Description')}`,
        render: (row) => <div>{row.memo || emptyPlaceholder}</div>,
      },

      {
        title: t('Payee owner or tenant'),
        render: (row) => (
          <Show when={getTenant(depositSlip()?.tenants, row.tenantId || undefined)} fallback={emptyPlaceholder}>
            <TenantTitle feedbackIconReadonly tenant={getTenant(depositSlip()?.tenants, row.tenantId || undefined)} />
          </Show>
        ),
      },
      {
        title: t('Amount'),
        render: (row) => <div>{currency(row.amount)}</div>,
      },
    ];
    return baseColumns;
  });

  const totalAmount = createMemo<number>(
    () => depositSlip()?.transaction?.reduce((previous, current) => previous + current.amount, 0) || 0
  );

  onMount(() => {
    params?.depositSlipId && getDepositSlip(params.depositSlipId).then((res) => setDepositSlip(res));
  });

  return (
    <div class="relative flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="relative flex flex-col-reverse flex-wrap gap-3 p-8 lg:flex-row">
        <div class="w-full lg:flex-1">
          <ListView
            title={t('Transactions')}
            data={depositSlip()?.transaction}
            skeletonSize={10}
            loading={!depositSlip()}
            columns={columns()}
            footerSummary={
              <div class="w-full text-right text-text-level01">
                {t('Total amount')}: {currency(totalAmount())}
              </div>
            }
          />
        </div>

        <div class="mb-4 h-fit w-full divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white lg:w-72">
          <div class="px-5 py-4">
            <div class="flex items-center justify-between gap-1">
              <span class="text-lg font-semibold text-title-gray">{t('Deposit slip detail')} </span>
            </div>
          </div>
          <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
            <IconField name={t('Date')} loading={!depositSlip()} value={dateFormat(t('MM/DD/YYYY'), depositSlip()?.date)} src={IconClock} />
            <IconField
              name={t('Bank account name')}
              loading={!depositSlip()}
              value={getBankAccountName(depositSlip()?.bankAccounts, depositSlip()?.bankAccountId)}
              src={IconHash}
            />
            <IconField name={t('Reference')} loading={!depositSlip()} value={depositSlip()?.reference} src={IconHash} />
            <Show when={params?.depositSlipId}>
              <PrintTablePageButton
                table={{
                  columns: columns(),
                  data: depositSlip()?.transaction || [],
                }}
                caption={{
                  title: 'Deposit slip detail',
                  extraData: [
                    {
                      label: 'Bank account name',
                      value: getBankAccountName(depositSlip()?.bankAccounts, depositSlip()?.bankAccountId),
                    },
                    {
                      label: 'Date',
                      value: dateFormat(t('MM/DD/YYYY'), depositSlip()?.date),
                    },
                    {
                      label: 'Total amount',
                      value: currency(totalAmount()),
                    },
                    {
                      label: 'Reference',
                      value: depositSlip()?.reference || '',
                    },
                  ],
                }}
              />
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};
