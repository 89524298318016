import { Uploader } from '~/components/common/Upload';
import { proofFileAccept } from '~/utils/file';
import { omit } from '~/utils/tool';
export const FileUpload = (props: any) => {
  return (
    <Uploader.LabeledPreUploader
      {...omit(props, ['onInput'])}
      accept={proofFileAccept}
      onInput={(file) => props.onInput?.(file.at(0)?.fileId)}
      maxSize={20}
      multiple={false}
      controlled={(value) => (value ? [{ fileId: value }] : [])}
    />
  );
};
