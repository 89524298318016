import { createContext, createSignal, splitProps, useContext } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { IconChevronDown } from '~/components/ui';
import { cn } from '~/utils/classnames';
import type { Accessor, ComponentProps, ParentComponent, Setter, ValidComponent } from 'solid-js';

const ExpandContext = createContext<{
  open: Accessor<boolean>;
  setOpen: Setter<boolean>;
}>();

const useExpandContext = () => {
  const context = useContext(ExpandContext);
  if (!context) throw new Error('ExpandContext not found');
  return context;
};

type ExpandProps = ComponentProps<'div'> & {
  defaultOpen?: boolean;
};

const Expand = (props: ExpandProps) => {
  const [local, rest] = splitProps(props, ['defaultOpen']);
  const [open, setOpen] = createSignal(local.defaultOpen ?? false);
  return (
    <ExpandContext.Provider value={{ open, setOpen }}>
      <div {...rest} />
    </ExpandContext.Provider>
  );
};

export const ExpandTrigger = <T extends ValidComponent>(props: { as?: T } & ComponentProps<T>) => {
  const { open, setOpen } = useExpandContext();
  const [params, rest] = splitProps(props, ['as']);
  return <Dynamic component={params.as ?? 'button'} onPointerUp={() => setOpen(!open())} {...rest} />;
};

export const ExpandContent: ParentComponent = (props) => {
  const { open } = useExpandContext();
  return (
    <div class={cn('grid grid-rows-[0fr] transition-all duration-300 ease-in-out', { 'grid-rows-[1fr]': open() })}>
      <div class="overflow-y-hidden">{props.children}</div>
    </div>
  );
};

export const ExpandIndicator = () => {
  const { open } = useExpandContext();
  return <IconChevronDown class="size-4 transition-transform duration-300 ease-in-out" classList={{ 'rotate-180 text-primary': open() }} />;
};

const ExpandWrap = Object.assign(Expand, {
  Trigger: ExpandTrigger,
  Content: ExpandContent,
  Indicator: ExpandIndicator,
});

export { ExpandWrap as Expand };
