import { RenderingCancelledException } from 'pdfjs-dist';
import { For, createSignal, onCleanup, onMount } from 'solid-js';
import { Annotation } from './Annotation';
import type { RectangularShape } from './Annotation';
import type { RenderTask } from 'pdfjs-dist';
import type { JSX } from 'solid-js';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';
import type { PresentableAnnotation } from '~/pdfsigner/ui/types/presentableAnnotation';

interface PDFPageProps {
  page: PresentableAnnotatedPdfPage;
  onAnnotationInput?: (id: string, event: InputEvent, value: string) => void;
  onAnnotationStartEditing?: (id: string, event: MouseEvent, pageNumber: number) => void;
  onAnnotationStartResizing?: (id: string, event: MouseEvent, pageNumber: number) => void;
  onAnnotationStartDragging?: (id: string, event: MouseEvent, page: number) => void;
  onPageMouseDown?: (event: MouseEvent, pageNumber: number) => void;
  onPageRenderComplete?: (pageNumber: number) => void;
}

export const PdfPage = (props: PDFPageProps): JSX.Element => {
  let canvas: HTMLCanvasElement | undefined = undefined;
  let currentRenderTask: RenderTask | undefined = undefined;

  const [canvasSize, setCanvasSize] = createSignal<RectangularShape>({ width: 0, height: 0 });
  const [scaleFactor, setScaleFactor] = createSignal(1);

  const renderPage = async () => {
    if (!canvas || !canvasSize().width || !canvasSize().height) {
      return;
    }
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    if (currentRenderTask) {
      currentRenderTask.cancel();
      try {
        await currentRenderTask.promise;
      } catch {
        // do nothing
      }
      currentRenderTask = undefined;
    }
    const dpr = window.devicePixelRatio < 1 ? 1 : window.devicePixelRatio;
    const baseViewport = props.page.page.getViewport({ scale: 1 });
    const newScaleFactor = canvasSize().width / baseViewport.width;
    setScaleFactor(newScaleFactor);
    const viewport = props.page.page.getViewport({ scale: newScaleFactor });
    if (!viewport.width || !viewport.height) {
      return;
    }
    canvas.width = viewport.width * dpr;
    canvas.height = viewport.height * dpr;
    context.scale(dpr, dpr);
    currentRenderTask = props.page.page.render({ canvasContext: context, viewport });

    try {
      await currentRenderTask.promise;
      props.onPageRenderComplete?.(props.page.pageNumber);
    } catch (error) {
      if (!(error instanceof RenderingCancelledException)) {
        console.error('Rendering error:', error);
      }
    } finally {
      currentRenderTask = undefined;
    }
  };

  onMount(() => {
    const resizeHandler = async () => {
      if (!canvas) return;
      const width = canvas.clientWidth;
      const baseViewport = props.page.page.getViewport({ scale: 1 });
      const aspectRatio = baseViewport.height / baseViewport.width;
      const height = width * aspectRatio;
      setCanvasSize((prev) => {
        const newSize = { width, height };
        if (prev.width !== newSize.width || prev.height !== newSize.height) {
          return newSize;
        }
        return prev;
      });
      await renderPage();
    };
    const observer = new ResizeObserver(resizeHandler);
    if (canvas) {
      observer.observe(canvas);
    }
    onCleanup(() => observer.disconnect());
    resizeHandler();
  });

  return (
    <div class="z-5 relative my-1 flex w-full flex-col overflow-hidden shadow-md first-of-type:mt-0">
      <canvas
        ref={(el) => (canvas = el)}
        id={'pdfPage' + props.page.pageNumber}
        class="block w-full"
        onMouseDown={(event) => props.onPageMouseDown?.(event, props.page.pageNumber)}
      />
      <For each={props.page.annotations}>
        {(annotation: PresentableAnnotation) => (
          <Annotation
            scaleFactor={scaleFactor() * 0.765}
            parentRect={canvasSize()}
            presentableAnnotation={annotation}
            onInput={props.onAnnotationInput}
            onStartEditting={(id, event) => props.onAnnotationStartEditing?.(id, event, props.page.pageNumber)}
            onStartDragging={(id, event) => props.onAnnotationStartDragging?.(id, event, props.page.pageNumber)}
            onStartResizing={(id, event) => props.onAnnotationStartResizing?.(id, event, props.page.pageNumber)}
          />
        )}
      </For>
    </div>
  );
};
