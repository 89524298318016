import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

export const ActionButton: Component<{
  icon?: Component;
  label: JSX.Element;
  class?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <div
      aria-roledescription="action-button"
      class={cn(
        'flex min-h-10 cursor-pointer select-none items-center gap-2 rounded-lg p-2 px-4 text-left text-sm font-medium text-white',
        props.class
      )}
      onClick={() => {
        props.onClick?.();
      }}>
      <Dynamic component={props.icon} />
      <Show when={typeof props.label === 'string'} fallback={props.label}>
        <div>{props.label}</div>
      </Show>
    </div>
  );
};
