import IconNetwork from '~/assets/images/identity/network.png';
import IconRobot from '~/assets/images/identity/robotWand.png';
import { useLocalization } from '~/contexts/global';
import type { ParentComponent } from 'solid-js';

export const AuthPageContainer: ParentComponent = (props) => {
  const { t } = useLocalization();

  return (
    <div class="h-fit min-h-screen w-full overflow-auto bg-gradient-dark-purple-blue px-3 lg:bg-[linear-gradient(90deg,_#7919C5_50%,_#2B1EC0_100%)] lg:px-0">
      <div
        class="relative flex h-screen min-h-fit w-full flex-col items-center justify-center bg-contain bg-right bg-no-repeat pb-8 pt-10 lg:flex-row lg:py-0"
        style={{
          'background-image': `url(${IconNetwork})`,
        }}>
        <div class="mb-7 flex max-w-[308px] flex-col items-center px-3 md:mb-20 md:max-w-[476px] lg:order-2 lg:mb-0 lg:h-full lg:min-w-[20vw] lg:max-w-[50vw] lg:flex-1 lg:justify-center">
          <img src="/logo.png" alt="Logo" class="absolute right-5 top-5 hidden h-20 lg:block" />
          <img src={IconRobot} alt="Robot" class="mx-auto mb-2 w-[142px] md:mb-6 md:w-[263px]" />
          <h1 class="mb-1 text-center text-lg font-bold text-white md:mb-2 md:text-[28px]">TIME IS MAGIC</h1>
          <p class="text-center text-xs text-white/70 md:text-xl lg:max-w-[476px]">
            The AI-Enabled Property Management Software That Honors Your Time
          </p>
        </div>
        <div class="flex w-full max-w-[516px] flex-col justify-center rounded-xl bg-white px-5 pb-8 pt-6 shadow-lg lg:h-full lg:min-w-[50vw] lg:max-w-[50vw] lg:flex-1 lg:items-center lg:rounded-none lg:shadow-none">
          {props.children}
          <div class="mt-4 text-center text-xs text-text-level03">
            @{new Date().getFullYear()} MagicDoor {t('All rights reserved')}
          </div>
        </div>
      </div>
    </div>
  );
};
