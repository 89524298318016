import { createEffect, createMemo, onMount, Show } from 'solid-js';
import { Elements, PaymentElement, useElements } from 'solid-stripe';
import IconCopy from '~/assets/images/common/iconCopy.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import { useCompanies, useLocalization, useNewRentalApplication } from '~/contexts/global';
import { PaymentLoading } from '~/pages/rental-applications/add-rental-application/PaymentStatus';
import { createClipboard } from '~/utils/clipboard';

const PayButton = () => {
  const { t } = useLocalization();
  const { handlePayment, paymentIntent } = useNewRentalApplication();
  const elements = useElements();

  return (
    <Button
      id="new-rental-application-pay-btn"
      block
      class="mt-5"
      loading={handlePayment.loading}
      onClick={() => {
        handlePayment(elements());
      }}>
      {t('Pay')} ${paymentIntent()?.totalAmount}
    </Button>
  );
};

export const OnlinePayment = () => {
  const { t } = useLocalization();
  const { getPaymentIntent, paymentIntent, stripe, getStripe, rentalApplication, isPaid } = useNewRentalApplication();
  const { companies } = useCompanies();

  const { copy } = createClipboard({
    successMessage: t('Link copied to clipboard'),
    errorMessage: t('Failed to copy link'),
  });

  const tenantLink = createMemo(() => {
    return `${companies()?.publicUrl}/rental-applications/${rentalApplication()?.id}/${rentalApplication()?.password}/payment`;
  });

  const canCopyPaymentLink = createMemo(() => {
    return rentalApplication()?.id && tenantLink();
  });

  onMount(() => {
    if (!rentalApplication()?.id || isPaid()) return;
    getPaymentIntent(rentalApplication()?.id as string);
  });

  createEffect(() => {
    const stripeAccount = paymentIntent()?.stripeAccountId;
    if (!stripeAccount) return;
    getStripe(stripeAccount);
  });

  return (
    <Show when={stripe()} fallback={<PaymentLoading />}>
      <div class="self-stretch overflow-y-auto p-6">
        <Elements stripe={stripe() ?? null} clientSecret={paymentIntent()?.clientSecret}>
          <div class="flex flex-col gap-4">
            <Section required border title={t('Application Payment')}>
              <div class="flex items-start gap-4">
                <div class="flex-1 [&>*]:mb-6">
                  <PaymentElement />
                </div>
                <div class="flex basis-64 flex-col">
                  <div class="basis-64 rounded bg-inputbox-bg px-4 py-5">
                    <div class="mb-5 flex items-center justify-between border-b border-partingline pb-5 text-base font-medium">
                      <span class="">{t('Total')}</span>
                      <span>$ {paymentIntent()?.totalAmount}</span>
                    </div>
                    <PayButton />
                  </div>
                  <Show when={canCopyPaymentLink()}>
                    <Button variant="outlined" class="mb-2 mt-5 justify-center" onClick={() => copy(tenantLink())}>
                      <IconCopy />
                      {t('Copy payment link')}
                    </Button>
                    <span class="text-center text-xs normal-case text-text-level03">{t('Send payment to tenant')}</span>
                  </Show>
                </div>
              </div>
            </Section>
          </div>
        </Elements>
      </div>
    </Show>
  );
};
