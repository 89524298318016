import { onMount } from 'solid-js';
import IconChargeLateFees from '~/assets/images/settings/property/propertyChargeLateFee.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentalApplicationScreeningSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const Screen = () => {
  const { t } = useLocalization();

  const { rentalApplicationScreeningSetting, updateRentalApplicationScreeningSettings, getRentalApplicationScreeningSettings } =
    useRentalApplicationScreeningSettings();

  onMount(() => {
    getRentalApplicationScreeningSettings();
  });

  const handleSave = async (data: MagicDoor.Api.RentalApplicationScreeningSettingsDto) => {
    await updateRentalApplicationScreeningSettings(data);
    toast(t('Rental application screening settings saved successfully'), 'success');
  };

  const sections: ItemProps[] = [
    {
      field: 'requireScreeningForApplication',
      label: 'Require screening for application',
      type: 'boolean',
      class: 'col-span-2',
      labelClass: 'flex-1 md:flex-none md:w-1/2',
      labelContainerClass:
        'flex-row items-center md:items-start justify-between gap-5 flex-wrap border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
      description: (
        <div class="flex flex-col">
          <div>{t('charge the property management company $30 per screening, make sure it is covered in you application fee.')}</div>
          <div>{t('Note: If you don’t have any properties in the system, screening will be automatically skipped.')}</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Require screening')} icon={IconChargeLateFees} />}
        middle={
          <div class="flex flex-col gap-5 p-3 md:p-6">
            <div class="flex flex-col gap-4 text-left">
              <EditForm<MagicDoor.Api.RentalApplicationScreeningSettingsDto>
                sections={sections}
                onOk={(data) => handleSave(data)}
                model={rentalApplicationScreeningSetting() || ({} as MagicDoor.Api.RentalApplicationScreeningSettingsDto)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};
