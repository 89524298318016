import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { createClipboard } from '~/utils/clipboard';
import { formConfirmationContent } from '~/utils/constant';

export const TenantPortalAccessTab = () => {
  const { t } = useLocalization();
  const params = useParams();

  const { inviteTenant, changeTenantPassword, current, setCurrentId, currentActions } = useTenants();
  const [showInviteModal, setShowInviteModal] = createSignal<boolean>(false);
  const [showResetModal, setShowResetModal] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const [inviteResult, setInviteResult] = createSignal<MagicDoor.Api.PortalInviteResponseDto | undefined>(undefined);
  const [passwordVisible, setPasswordVisible] = createSignal(false);
  const changePasswordForm = useForm();

  const { copy, tooltipMessage } = createClipboard({
    errorMessage: t('Failed to copy'),
    tooltipMessages: {
      default: t('Click to copy'),
      copied: t('Copied'),
    },
  });

  const handleInvite = async () => {
    const inviteResponse = await inviteTenant(params.tenantId);
    setInviteResult(inviteResponse);
    if (inviteResponse.newPassword) {
      setShowInviteModal(true);
    }
    currentActions.refetch(params.tenantId);
  };

  const openResetPasswordModal = () => {
    setShowResetModal(true);
    setPasswordVisible(false);
  };

  const handleResetPassword = async () => {
    const { validateStatus } = changePasswordForm.validateForm();

    if (!validateStatus) return;

    await changeTenantPassword(params.tenantId, changePasswordForm.formStore.password ?? '');
    setShowResetModal(false);
    toast.success(t('Password has been changed successfully'));
  };

  createEffect(() => {
    setShowCloseConfirmation(!!changePasswordForm.formStore.password);
    setCurrentId(params.tenantId);
  });

  const onConfirmation = () => {
    setShowInviteModal(false);
  };

  const canSendInvite = createMemo(() => !!current()?.email);

  return (
    <>
      <Panel class="flex w-full gap-2 p-4">
        <Tooltip class="cursor-pointer" disabled={canSendInvite()} message={t('Tenant email is required to send invite')} align="bottom">
          <Button
            disabled={current.loading || !canSendInvite() || inviteTenant.loading}
            loading={inviteTenant.loading}
            onClick={handleInvite}>
            {current()?.portalStatus === 'invited' ? t('Resend invite') : t('Send invite')}
          </Button>
        </Tooltip>

        <Button class="w-1/3" onClick={openResetPasswordModal} disabled={current.loading}>
          {t('Change password')}
        </Button>
      </Panel>
      <Show when={showInviteModal()}>
        <Modal
          visible={true}
          title={t('Send invite')}
          doneText={t('Confirm')}
          onDone={onConfirmation}
          onCancel={() => setShowInviteModal(false)}
          confirmation={false}>
          <div class="flex flex-col gap-2 p-6">
            <Show when={inviteResult()}>
              <div>{t('An invite has been sent to the tenant')}!</div>
              <div class="flex gap-2 rounded-md bg-input p-4">
                <div class="font-semibold">{t('Tenant password')}:</div>
                <Tooltip message={tooltipMessage()} class="cursor-pointer" align="top">
                  <div onClick={() => copy(inviteResult()?.newPassword || '')}>{inviteResult()?.newPassword}</div>
                </Tooltip>
              </div>
            </Show>
          </div>
        </Modal>
      </Show>
      <Show when={showResetModal()}>
        <Modal
          visible={true}
          title={t('Change password')}
          doneText={t('Confirm')}
          onDone={handleResetPassword}
          loading={changeTenantPassword.loading}
          onCancel={() => {
            setShowResetModal(false);
            changePasswordForm.resetFields();
          }}
          confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
          <Form defaultForm={changePasswordForm}>
            <div class="flex flex-col gap-2 p-6">
              <FormItem
                component={LabeledTextInput}
                label={t('New password')}
                type={passwordVisible() ? 'text' : 'password'}
                placeholder={t('Enter new password')}
                formFieldName="password"
                rules={[{ required: true, message: t('Please enter a new password') }]}
              />

              <Checkbox
                id="show-password"
                name="showPassword"
                checked={passwordVisible()}
                onChange={() => setPasswordVisible(!passwordVisible())}
                label={t('Show password')}
                labelPosition="left"
                showLabel={true}
              />
            </div>
          </Form>
        </Modal>
      </Show>
    </>
  );
};
