import { For, Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';

interface LimitProps {
  class?: string;
  titleClass?: string;
  limits?: MagicDoor.Api.PlaidTransferLimitsDto;
}
export const LimitsList = (props: LimitProps) => {
  const { t } = useLocalization();
  const List = [
    { label: 'Max single transfer credit amount', field: 'maxSingleTransferCreditAmount' },
    { label: 'Max single transfer debit amount', field: 'maxSingleTransferDebitAmount' },
    { label: 'Max daily credit amount', field: 'maxDailyCreditAmount' },
    { label: 'Max daily debit amount', field: 'maxDailyDebitAmount' },
    { label: 'Max monthly credit amount', field: 'maxMonthlyCreditAmount' },
    { label: 'Max monthly debit amount', field: 'maxMonthlyDebitAmount' },
  ];
  return (
    <For each={List}>
      {(item) => (
        <div class={cn('flex items-center justify-between text-text-level02', props.class)}>
          <div>{t(item.label)}: </div>
          <Show
            when={props.limits?.[item.field as keyof MagicDoor.Api.PlaidTransferLimitsDto] !== undefined}
            fallback={<div class="font-semibold">{emptyPlaceholder}</div>}>
            <span class="font-semibold">{currency(props.limits?.[item.field as keyof MagicDoor.Api.PlaidTransferLimitsDto] ?? '')}</span>
          </Show>
        </div>
      )}
    </For>
  );
};
