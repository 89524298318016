import { unwrap } from 'solid-js/store';
import { Form, FormItem } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { Uploader } from '~/components/common/Upload';
import type { HydrateFile } from '~/components/common/Upload';

export const UploadPage = () => {
  return (
    <div class="mx-auto mt-44 max-w-[600px] text-center text-xl font-semibold">
      <h2 class="my-6">UploadPage</h2>

      <Form
        class="flex flex-col gap-4"
        onFormSubmit={(values) => {
          console.log(unwrap(values));
        }}>
        <FormItem
          label="Muti Upload (Max count 3)"
          formFieldName="mutiUpload"
          onInput={(files: HydrateFile[]) => {
            console.log('mutiUpload', files);
          }}
          component={Uploader.LabeledPreUploader}
          maxCount={3}
          getValueFromEvent={(files: HydrateFile[]) => files.map((f) => f.fileId)}
        />
        <FormItem
          label="Single Upload (pdf,size limit 2MB)"
          formFieldName="singleUpload"
          multiple={false}
          component={Uploader.LabeledPreUploader}
          accept="application/pdf"
          maxSize={2}
        />

        <FormItem
          label="Lazy Upload"
          formFieldName="lazyUpload"
          component={Uploader.LabeledPreUploader}
          usePreUpload={false}
          onFilesListChange={(files) => {
            console.log('lazyUpload onFileListChange', files);
          }}
          onInput={(files) => {
            console.log('lazyUpload onInput', files);
          }}
        />

        <FormItem
          label="Default File List"
          formFieldName="defaultFileList"
          component={Uploader.LabeledPreUploader}
          defaultFileList={[
            {
              fileId: '1349282812604051457',
              fileName: 'image-副本（2）.png',
              fileUrl:
                'https://scontent-nrt1-2.xx.fbcdn.net/v/t39.30808-1/275237586_107248525242291_188551398914536873_n.png?stp=c76.0.788.788a_dst-png_s200x200&_nc_cat=104&ccb=1-7&_nc_sid=2d3e12&_nc_ohc=ZENJakkgKLoQ7kNvgG7jhwN&_nc_oc=Adj7CCY_KkXaBk0DF2QpHx5E5OzlwQ0I7DI62xP7CejBc3AZp_7h5I0LuJAbgfStNZY&_nc_zt=24&_nc_ht=scontent-nrt1-2.xx&_nc_gid=AB_PsPidZQ-LfwIqR21nyuN&oh=00_AYEAKNYuuh6EdxW-eg6DvHUZhJ0oohk3XPAzVUgnUowZFQ&oe=67D9ADFA',
            },
            {
              fileId: '1349282812604051458',
              fileName: '1mb_1349282841867710464.pdf',
              fileUrl:
                'https://poba-test-files-bucket.s3.us-east-1.amazonaws.com/1192372134522703872/1mb_1349282841867710464.pdf?X-Amz-Expires=43200&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3YSV5ICLZWII22F2%2F20250312%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250312T072818Z&X-Amz-SignedHeaders=host&X-Amz-Signature=904bf82e2527c76ca67da70c2aebef22b23d272462cf194aab5ddd7c6bf8aa86',
            },
          ]}
          onFilesListChange={(files) => {
            console.log('defaultFileList', files);
          }}
        />

        <Button type="submit">Submit!</Button>
      </Form>
    </div>
  );
};
