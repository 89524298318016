import { createMemo, Show, Switch, Match } from 'solid-js';
import IconCheck from '~/assets/images/common/check.svg?component-solid';
import IconPrint from '~/assets/images/owner-funds/print.svg?component-solid';
import IconRejected from '~/assets/images/owner-funds/rejected.svg?component-solid';
import IconScheduled from '~/assets/images/owner-funds/scheduled.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Stepper } from '~/components/common/Stepper';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { usePayVendorBills } from '~/contexts/local';
import { emptyPlaceholder, paymentMethodOptions } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

export const ReviewAndPrintForm = () => {
  const { t } = useLocalization();
  const { store } = usePayVendorBills();
  const steps = createMemo(() => [t('Choose bills'), t('Allocations'), t('Payment'), t('Review & print')]);

  return (
    <div class="flex flex-col overflow-hidden">
      <div class="thinscroll h-full overflow-auto">
        <div class="mx-auto flex w-10/12 min-w-[710px] flex-col gap-5 px-5">
          <div class="flex items-center justify-center pb-12">
            <Stepper class="p-9" steps={steps()} step={3} />
          </div>
          <div class="flex flex-col">
            <p class="mb-5 text-xs text-text-level02">{t('Table of Transaction')}</p>
            <Show
              when={
                store.payVendorBillsResult?.ach &&
                (store.payVendorBillsResult?.ach.failed.length ||
                  store.payVendorBillsResult?.ach.scheduled.length ||
                  store.payVendorBillsResult?.ach.successful.length)
              }>
              <AchTable data={store.payVendorBillsResult?.ach} />
            </Show>
            <Show when={store.payVendorBillsResult?.printCheck && store.payVendorBillsResult?.printCheck.length}>
              <CheckTable data={store.payVendorBillsResult?.printCheck} />
            </Show>
            <Show when={store.payVendorBillsResult?.manual && store.payVendorBillsResult?.manual.length}>
              <ManualTable data={store.payVendorBillsResult?.manual} />
            </Show>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 mt-auto flex justify-end gap-2 border-t border-partingline bg-white px-3 py-5 lg:px-6">
        <Button class="rounded-lg bg-primary px-4 py-2 text-white" href={'/accounting/bills'}>
          {t('Back to bills')}
        </Button>
      </div>
    </div>
  );
};

interface PrintButtonProps {
  text: string;
  onClick?: () => void;
}

const PrintButton = (props: PrintButtonProps) => {
  return (
    <button
      onClick={() => props.onClick?.()}
      class="inline-flex items-center gap-1 rounded-full border border-light-blue px-2.5 py-0.5 text-xs text-light-blue outline-none hover-allowed:hover:bg-light-blue/10">
      <IconPrint />
      <span>{props.text}</span>
    </button>
  );
};

interface AchTableProps {
  data?: MagicDoor.Api.VendorBillAchResultsDto;
}

type AchTableRow =
  | (MagicDoor.Api.VendorBillResultDto & { status: 'successful' | 'scheduled' | 'failed' })
  | (MagicDoor.Api.FailedVendorBillResultDto & { status: 'successful' | 'scheduled' | 'failed' });

const AchTable = (props: AchTableProps) => {
  const { t } = useLocalization();
  const records = createMemo(() => {
    if (!props.data) {
      return [];
    }

    return [
      ...props.data.successful.map((item) => ({ ...item, status: 'successful' })),
      ...props.data.scheduled.map((item) => ({ ...item, status: 'scheduled' })),
      ...props.data.failed.map((item) => ({ ...item, status: 'failed' })),
    ] as AchTableRow[];
  });
  const columns = [
    {
      title: t('Vendor'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-input-border text-xs text-text-level01',
      render: (item: AchTableRow) => item.vendor.name || emptyPlaceholder,
    },
    {
      title: t('Payment Account'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-input-border text-xs text-text-level01',
      render: (item: AchTableRow) => item.bankAccount.name || emptyPlaceholder,
    },
    {
      title: t('Memo'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: AchTableRow) => item.transaction.memo || emptyPlaceholder,
    },
    {
      title: t('Payment Status'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs',
      render: (item: AchTableRow) => (
        <Switch>
          <Match when={item.status === 'successful'}>
            <div class="flex items-center gap-1 text-light-green">
              <IconCheck />
              <span>{t('Success')}</span>
            </div>
          </Match>
          <Match when={item.status === 'scheduled'}>
            <div class="flex items-center gap-1 text-light-warning">
              <IconScheduled />
              <span>{t('Scheduled')}</span>
            </div>
          </Match>
          <Match when={item.status === 'failed'}>
            <div class="flex items-center gap-1 text-light-danger">
              <IconRejected />
              <span>{(item as MagicDoor.Api.FailedVendorBillResultDto).failureReason || emptyPlaceholder}</span>
            </div>
          </Match>
        </Switch>
      ),
    },
    {
      title: t('Amount'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white text-right',
      class: 'p-4 border-input-border text-xs text-text-level01 text-right',
      render: (item: AchTableRow) => currency(item.transaction.amount),
    },
  ];

  return (
    <div class="flex flex-col pb-6">
      <p class="mb-3 text-base font-semibold text-text-level01">{t('ACH')}</p>
      <div class="overflow-hidden rounded-lg border border-input-border">
        <Table rowClass="border-t border-input-border" columns={columns} data={records()} />
      </div>
    </div>
  );
};

interface CheckTableProps {
  data?: MagicDoor.Api.VendorBillPrintCheckResultDto[];
}

const CheckTable = (props: CheckTableProps) => {
  const { t } = useLocalization();
  const handlePrint = (records: MagicDoor.Api.VendorBillPrintCheckResultDto[]) => {
    const printWindow = window.open(' ', ' ', 'width=' + '1024px' + ', height=' + '800px');
    let checkContainers = '';

    for (const item of records) {
      const checkData = item;

      const lineHeaders = checkData.check.dataHeaders?.map((header) => `<th>${header}</th>`).join('');
      const lineItems = checkData.check.billPayments
        ?.map(
          (bill) => `
      <tr>
        ${checkData.check.dataHeaders?.map((key) => `<td>${bill.data?.[key] ?? ''}</td>`).join('')}
        <td>$${bill.amount?.toFixed(2)}</td>
      </tr>`
        )
        .join('');
      checkContainers += `<div class="check-container">
        <div class="top-routing">${checkData.check.checkNumber}</div>
        <div class="check-header">
          <div>
            <div>${checkData.check.company?.name}</div>
            <div>${checkData.check.company?.address?.streetAddress1 || ''}</div>
            <div>${checkData.check.company?.address?.city || ''}, ${checkData.check.company?.address?.state || ''} ${
              checkData.check.company?.address?.zipCode || ''
            }</div>
          </div>
          <div>Date: ${dateFormat('MM/DD/YYYY', checkData.check.date)}</div>
        </div>
        <div class="payee-section">
          <div class="half-size">Pay to the order of:</div>
          <div>
            <strong>${checkData.vendor?.name}</strong>
          </div>
        </div>
        <div class="payee-section">
          <div class="half-size">This amount:</div>
          <div class="amount-section-wrapper">
            <div> **** ${checkData.check.amountString}</div>
            <div class="amount-section">
              $${checkData.check.amount?.toFixed(2)}
            </div>
          </div>
        </div>
        <div class="payee-address">
          ${checkData.vendor?.contact.address?.streetAddress1 || ''}<br>
          ${checkData.vendor?.contact.address?.city || ''}, ${checkData.vendor?.contact.address?.state || ''} ${
            checkData.vendor?.contact.address?.zipCode || ''
          }
        </div>
        <div class="memo-section">
          ${checkData.check.memo}
        </div>
        <div class="micr-font">
        ${checkData.check.micrCode}
        </div>
        <div class="check-info-wrapper">
          <div class="check-information">
            <div class="date-section">
              <div>Date: ${dateFormat('MM/DD/YYYY', checkData.check.date)}</div>
              <div>Check #${checkData.check.checkNumber}</div>
              <div>Account: ${checkData.bankAccount?.name}</div>
            </div>
            <div class="account-section">
              Pay to: ${checkData.vendor?.name}
            </div>
          </div>
          <div class="check-routing">${checkData.check.checkNumber}</div>
        </div>
        <table class="line-items-table">
          <thead>
           <tr>
              ${lineHeaders}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            ${lineItems}
            <tr>
              <td class="line-items-total" colspan="3">Total:</td>
              <td>$${checkData.check.amount?.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>`;
    }

    printWindow?.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          @font-face {
            font-family: 'MICR';
            src: url('/fonts/micr-encoding.ttf') format('truetype');
          }

          @page { size: auto;  margin: 0mm; }

          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
          .check-container {
            width: 1000px;
            margin: 20px auto;
            padding: 30px;
            padding-top: 10px;
            padding-bottom: 20px;
            position: relative;
            font-size: 15px;
            page-break-before: always;
          }
          .check-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 64px;
            padding: 0 60px;
          }
          .check-header div {
            text-align: left;
          }
          .half-size {
            width: 80px;
          }
          .top-routing {
            text-align: right;
            padding-bottom: 10px;
            padding: 0 60px;
          }
          .payee-section {
            margin: 0 20px 10px 0;
            display: flex;
          }
          .payee-section strong {
            font-size: 16px;
            display: block;
          }
          .amount-section-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 16px;
          }
          .amount-section {
            font-size: 16px;
            font-weight: bold;
            padding: 0 60px;
          }
          .payee-address {
            font-size: 16px;
            margin-left: 80px;
            height: 74px;
          }
          .memo-section {
            margin-top: 20px;
            font-size: 15px;
          }
          .memo-section:before {
            content: 'MEMO';
            display: inline-block;
            margin-right: 10px;
          }
          .date-section {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            gap: 50px;
          }
          .account-section {
            margin-bottom: 10px;
            font-size: 14px;
          }
          .check-info-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 60px;
          }
          .check-number {
            font-size: 20px;
            font-weight: bold;
            position: absolute;
            right: 30px;
            top: 30px;
          }
          .check-routing {
            font-size: 20px;
          }
          .line-items-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
            font-size: 14px;
          }
          .line-items-table th,
          .line-items-table td {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
          }
          .line-items-table th {
            background-color: #f2f2f2;
          }
          .line-items-total {
            text-align: right;
            padding-right: 10px;
            font-weight: bold;
            font-size: 14px;
          }
        .micr-font {
            font-family: 'MICR', monospace;
            font-size: 32px;
            text-align: center;
            margin-top: 30px;
            letter-spacing: 2px;
          }
        </style>
        <title>Print check</title>
      </head>
      <body>
        ${checkContainers}
      </body>
      </html>
      `);

    printWindow?.document.close();
    setTimeout(() => {
      printWindow?.print();
      printWindow?.close();
    }, 500);
  };
  const columns = [
    {
      title: t('Vendor '),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillPrintCheckResultDto) => item.vendor.name || emptyPlaceholder,
    },
    {
      title: t('Payment Account'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillPrintCheckResultDto) => item.bankAccount?.name || emptyPlaceholder,
    },
    {
      title: t('Memo'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillPrintCheckResultDto) => item.transaction.memo || emptyPlaceholder,
    },
    {
      title: t('Check Status'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillPrintCheckResultDto) => (
        <div class="flex items-center justify-between">
          <div class="inline-flex items-center gap-1 text-xs text-light-green">
            <IconCheck />
            <span>{t('Success')}</span>
          </div>
          <PrintButton text={t('Print')} onClick={() => handlePrint([item])} />
        </div>
      ),
    },
    {
      title: t('Amount'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white text-right',
      class: 'p-4 border-input-border text-xs text-text-level01 text-right',
      render: (item: MagicDoor.Api.VendorBillPrintCheckResultDto) => currency(item.transaction.amount),
    },
  ];

  return (
    <div class="mb-6 flex flex-col">
      <p class="mb-1 text-base font-semibold text-text-level01">{t('Check')}</p>
      <p class="mb-1 text-xs normal-case text-light-danger">
        {t('A rejected check by the bank must be repaid as it was not successfully processed')}
      </p>
      <div class="overflow-hidden rounded-lg border border-input-border">
        <Table rowClass="border-t border-input-border" columns={columns} data={props.data} />
      </div>
      <Show when={props.data?.length}>
        <div class="mt-4 flex justify-end">
          <PrintButton text={t('Print All')} onClick={() => handlePrint(props.data ?? [])} />
        </div>
      </Show>
    </div>
  );
};

interface ManualTableProps {
  data?: MagicDoor.Api.VendorBillResultDto[];
}

const ManualTable = (props: ManualTableProps) => {
  const { t } = useLocalization();
  const processedPaymentMethodOptions = paymentMethodOptions(t);
  const columns = [
    {
      title: t('Vendor'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillResultDto) => item.vendor.name || emptyPlaceholder,
    },
    {
      title: t('Payment Account'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillResultDto) => item.bankAccount?.name || emptyPlaceholder,
    },
    {
      title: t('Memo'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillResultDto) => item.transaction.memo || emptyPlaceholder,
    },
    {
      title: t('Payment Method'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillResultDto) =>
        processedPaymentMethodOptions.find((option) => option.value === item.transaction.transactionPaymentMethod)?.label ||
        emptyPlaceholder,
    },
    {
      title: t('External transaction ID'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white',
      class: 'p-4 border-x border-input-border text-xs text-text-level01',
      render: (item: MagicDoor.Api.VendorBillResultDto) => item.transaction.externalTransactionId || emptyPlaceholder,
    },
    {
      title: t('Amount'),
      headerClass: 'p-4 text-text-level03 text-xs bg-white text-right',
      class: 'p-4 border-input-border text-xs text-text-level01 text-right',
      render: (item: MagicDoor.Api.VendorBillResultDto) => currency(item.transaction.amount),
    },
  ];

  return (
    <div class="flex flex-col pb-6">
      <p class="mb-3 text-base font-semibold text-text-level01">{t('Manual')}</p>
      <div class="overflow-hidden rounded-lg border border-input-border">
        <Table rowClass="border-t border-input-border" columns={columns} data={props.data} />
      </div>
    </div>
  );
};
