import { Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const MaintenanceRequestUrgency: Component<{ class?: string; urgency?: `${MagicDoor.Api.Urgency}` }> = (props) => {
  const { t } = useLocalization();
  const map: Record<MagicDoor.Api.Urgency, { label: string; color: string }> = {
    urgent: { label: 'Urgent', color: 'text-light-danger' },
    high: { label: 'High', color: 'text-light-purple' },
    medium: { label: 'Medium', color: 'text-light-green' },
    low: { label: 'Low', color: 'text-light-warning' },
    none: { label: 'None', color: 'text-light-gray-level01' },
  };
  return (
    <Show when={props.urgency}>
      {(urgency) => (
        <span class={cn('rounded-full bg-current-alpha px-2 py-0.5 text-xs', map[urgency()].color, props.class)}>
          {t(map[urgency()].label)}
        </span>
      )}
    </Show>
  );
};
