import { BaseRestRepository } from './baseRestRepository';

const LEASE_TEMPLATE_URL = '/api/lease-templates';

export interface CreateLeaseTemplateDto {
  categoryId: string;
  file: File;
  name: string;
  description?: string;
  type?: `${MagicDoor.Api.LeaseTemplateType}`;
}

export class LeaseTemplateRepository extends BaseRestRepository {
  public async getLeaseTemplates(): Promise<MagicDoor.Api.LeaseTemplateDto[]> {
    const response = await this.fetchWithAuth(LEASE_TEMPLATE_URL);
    return this.getJsonResponse(response);
  }

  public async getLeaseTemplate(id: string): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const url = `${LEASE_TEMPLATE_URL}/${id}`;

    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createLeaseTemplate(params: CreateLeaseTemplateDto): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const formData = new FormData();
    formData.append('LeaseTemplateCategoryId', params.categoryId);
    formData.append('Name', params.name);
    formData.append('File', params.file);
    formData.append('Description', params.description || '');
    formData.append('Type', params.type || '');

    const response = await this.fetchWithAuth(LEASE_TEMPLATE_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async updateLeaseTemplate(id: string, payload: MagicDoor.Api.UpdateLeaseTemplateDto): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const url = `${LEASE_TEMPLATE_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteLeaseTemplate(id: string): Promise<void> {
    const url = `${LEASE_TEMPLATE_URL}/${id}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async addLeaseTemplateAttachment(id: string, file: File): Promise<MagicDoor.Api.LeaseTemplateDto> {
    const url = `${LEASE_TEMPLATE_URL}/${id}/file`;
    const formData = new FormData();
    formData.append('File', file);

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async getLeaseTemplateCategories(): Promise<MagicDoor.Api.LeaseTemplateCategoryDto[]> {
    const url = `${LEASE_TEMPLATE_URL}/categories`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
