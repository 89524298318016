import { mergeProps, Show, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { LabeledGroup } from '~/components/common/Inputs';
import { DragAndDrop, IconCloudUpload } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { acceptStringToFileSuffix } from '~/utils/file';
import { FileItem } from './FileItem';
import { FileList } from './FileList';
import { UploadProvider, useUpload } from './context';
import type { UploadProps } from './Interface';
import type { ParentProps, JSX, ValidComponent } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
const _Uploader = (props: ParentProps<UploadProps>) => <UploadProvider {...props} />;

type UploadTriggerProps = {
  class?: string;
  icon?: ValidComponent;
  triggerLabel?: JSX.Element;
};

const fileAnnotation = (accept?: string, maxSize?: number) => {
  const { t } = useLocalization();
  const annotation: string[] = [];
  if (accept && accept !== '*') annotation.push(`${t('Accepts')} ${acceptStringToFileSuffix(accept)}`);
  if (maxSize) annotation.push(`${t('with a max size of {maxSize} MB', { maxSize })}`);
  return annotation.join(', ');
};

const UploadTrigger = (props: UploadTriggerProps) => {
  const { uploadProps, handleDropFiles } = useUpload();
  const { t } = useLocalization();

  return (
    <DragAndDrop
      {...uploadProps}
      class={cn(
        'border-gray group flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed text-sm normal-case text-gray-500 transition-colors duration-200 hover:border-essential-colour hover:text-primary',
        props.class
      )}
      onDropFiles={handleDropFiles}>
      <div class="flex cursor-pointer flex-col items-center justify-center gap-2 p-4">
        <Dynamic component={props.icon || IconCloudUpload} />
        <span>{props.triggerLabel || t('Drag & drop or click to add files')}</span>
        <span class="text-center text-xs text-gray-400 duration-200 group-hover:text-essential-colour">
          {fileAnnotation(uploadProps.accept, uploadProps.maxSize)}
        </span>
      </div>
    </DragAndDrop>
  );
};

type PreUploaderInnerProps = {
  onClean?: () => void;
} & UploadTriggerProps;

export type PreUploaderProps = PreUploaderInnerProps & UploadProps;

const MutiUploaderInner = (props: PreUploaderInnerProps) => {
  const [localProps, uploaderProps] = splitProps(props, ['class']);
  return (
    <div class={cn('flex flex-col justify-center gap-2 rounded-lg border p-2', localProps.class)}>
      <UploadTrigger {...uploaderProps} />
      <FileList />
    </div>
  );
};

const SingleUploaderInner = (props: PreUploaderInnerProps) => {
  const { fileList } = useUpload();
  return (
    <div class="flex min-h-[150px] items-center justify-center rounded-lg border p-2">
      <Show
        when={!fileList().length}
        fallback={
          <FileItem
            file={fileList()[0]}
            onClean={() => {
              props.onClean?.();
            }}
          />
        }>
        <UploadTrigger {...props} class="flex-1" />
      </Show>
    </div>
  );
};

const PreUploader = (props: PreUploaderProps) => {
  const mergedProps = mergeProps({ multiple: true }, props);
  const [localProps, uploaderProps] = splitProps(mergedProps, ['triggerLabel', 'icon', 'onClean', 'class']);

  return (
    <Uploader {...uploaderProps}>
      <Dynamic component={!uploaderProps.multiple ? SingleUploaderInner : MutiUploaderInner} {...localProps} />
    </Uploader>
  );
};

const LabeledPreUploader = (props: LabeledGroupProps & PreUploaderProps) => {
  const [localProps, preUploaderProps] = splitProps(props, ['label', 'error', 'labelClass', 'labelJSX', 'class']);
  return (
    <LabeledGroup {...localProps}>
      <PreUploader {...preUploaderProps} />
    </LabeledGroup>
  );
};

export const Uploader = Object.assign(_Uploader, {
  PreUploader,
  LabeledPreUploader,
  Trigger: UploadTrigger,
}) as typeof _Uploader & {
  PreUploader: typeof PreUploader;
  LabeledPreUploader: typeof LabeledPreUploader;
  Trigger: typeof UploadTrigger;
};
