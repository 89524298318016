import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { WorkOrdersRepository } from '~/repositories/workOrderRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { HydrateFile } from '~/components/common/Upload';
import type { NewFileReadyToUpload } from '~/components/file-attachments';
import type { WorkOrdersFilter } from '~/repositories/workOrderRepository';

const repository = new WorkOrdersRepository();

export const [WorkOrdersProvider, useWorkOrders] = createMagicDoorContext('WorkOrders', () => {
  const [filtered, setFilter, filteredActions] = createTriggerResource((filter: WorkOrdersFilter) => repository.getWorkOrders(filter));
  const [fileList, setFileList] = createSignal<NewFileReadyToUpload[]>([]);
  const [newFiles, setNewFiles] = createSignal<HydrateFile[]>([]);
  const [workOrder, getWorkOrder, workOrderActions] = createTriggerResource(repository.getWorkOrder.bind(repository));

  const [userWorkOrder, getUserWorkOrder] = createTriggerResource(repository.getUserWorkOrders.bind(repository));

  const addWorkOrder = createMutation(async (payload: MagicDoor.Api.CreateWorkOrderDto) => {
    const response = await repository.addWorkOrder(payload);
    workOrderActions.mutate(response);
    return response;
  });

  const updateMaintenanceRequest = createMutation(
    async (workOrderId: string, payload: MagicDoor.Api.UpdateWorkOrderMaintenanceRequestDto) => {
      const response = await repository.updateMaintenanceRequest(workOrderId, payload);
      workOrderActions.mutate(response);
    }
  );

  const deleteWorkOrder = createMutation(async (workOrderId: string) => {
    await repository.deleteWorkOrder(workOrderId);
    filteredActions.refetch();
  });

  const updateWorkOrder = createMutation(async (workOrderId: string, payload: MagicDoor.Api.UpdateWorkOrderDto) => {
    const response = await repository.updateWorkOrder(workOrderId, payload);
    workOrderActions.mutate(response);
  });

  const updateWorkOrderStatus = createMutation(async (workOrderId: string, payload: MagicDoor.Api.UpdateWorkOrderStatusDto) => {
    const hydrated = await repository.updateWorkOrderStatus(workOrderId, payload);
    filteredActions.mutate(
      (prev) =>
        prev && {
          ...prev,
          items: prev.items.map((i) => ({
            ...i,
            status: i.id === workOrderId ? hydrated.status : i.status,
          })),
        }
    );
  });

  const updateVendor = createMutation(async (workOrderId: string, payload: MagicDoor.Api.UpdateWorkOrderVendorDto) => {
    const response = await repository.updateVendor(workOrderId, payload);
    workOrderActions.mutate(response);
    filteredActions.refetch();
  });

  const updateLease = createMutation(async (workOrderId: string, payload: MagicDoor.Api.UpdateWorkOrderLeaseDto) => {
    const response = await repository.updateLease(workOrderId, payload);
    workOrderActions.mutate(response);
    filteredActions.refetch();
  });

  const addDraftBill = createMutation(async (workOrderId: string, payload: MagicDoor.Api.AddDraftWorkOrderBillDto) => {
    const hydrated = await repository.addDraftBill(workOrderId, payload);
    workOrderActions.mutate(
      (prev) =>
        prev && {
          ...prev,
          bills: prev.bills ? [...prev.bills, hydrated] : [hydrated],
        }
    );
    return hydrated;
  });
  const updateDraftBill = createMutation(async (workOrderId: string, billId: string, payload: MagicDoor.Api.AddDraftWorkOrderBillDto) => {
    const hydrated = await repository.updateDraftBill(workOrderId, billId, payload);
    workOrderActions.mutate(
      (prev) =>
        prev && {
          ...prev,
          bills: prev.bills?.map((item) => ({
            ...(item.id === billId ? hydrated : item),
          })),
        }
    );
  });

  const deleteDraftBill = createMutation(async (workOrderId: string, billId: string) => {
    await repository.deleteDraftBill(workOrderId, billId);
    workOrderActions.mutate(
      (prev) =>
        prev && {
          ...prev,
          bills: prev.bills?.filter((bill) => bill.id !== billId),
        }
    );
  });

  const convertDraftBill = createMutation(async (workOrderId: string, billId: string) => {
    await repository.convertDraftBill(workOrderId, billId);
    workOrderActions.refetch();
  });

  const addBillFile = createMutation(async (workOrderId: string, billId: string, file: File, description?: string) => {
    const hydrated = await repository.addBillFile(workOrderId, billId, file, description);
    workOrderActions.mutate(
      (prev) =>
        prev && {
          ...prev,
          bills: prev.bills?.map((item) => ({
            ...(item.id === billId ? hydrated : item),
          })),
        }
    );
  });

  const deleteBillFile = createMutation(async (workOrderId: string, billId: string, fileId: string) => {
    await repository.deleteBillFile(workOrderId, billId, fileId);
    workOrderActions.mutate(
      (prev) =>
        prev && {
          ...prev,
          bills: prev.bills?.map((item) => ({
            ...item,
            files: item.files?.filter((file) => file.fileId !== fileId),
          })),
        }
    );
  });

  const createVendorNotification = createMutation(async (workOrderId: string, formData: FormData) => {
    if (!workOrderId) return;
    await repository.createWorkOrderNotification(workOrderId, formData);
    workOrderActions.refetch();
  });

  return {
    filtered: filtered,
    setFilter: (filter: WorkOrdersFilter) => setFilter(filter),
    addWorkOrder,
    updateMaintenanceRequest,
    getWorkOrder,
    workOrder,
    createVendorNotification,
    deleteWorkOrder,
    updateWorkOrder,
    updateWorkOrderStatus,
    workOrderActions,
    updateVendor,
    updateLease,
    addDraftBill,
    updateDraftBill,
    deleteDraftBill,
    convertDraftBill,
    addBillFile,
    deleteBillFile,
    fileList,
    setFileList,
    userWorkOrder,
    getUserWorkOrder,
    newFiles,
    setNewFiles,
    refetchWorkOrders: workOrderActions.refetch,
  };
});
