import { Show, createMemo, createSignal, onMount } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconLeaseRenewal from '~/assets/images/settings/lease/leaseRenewal.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, confirm, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const LeaseRenewalSettings = () => {
  const { t } = useLocalization();
  const {
    allLeaseSettings,
    getAllLeaseSettings,
    isLoading,
    updateLeaseSettings,
    addOverrideSettings,
    updateOverrideLeaseSettings,
    removeOverrideSettings,
    leaseTemplateOptions,
    getLeaseTemplateOptions,
  } = useLeaseSettings();
  const sections = createMemo<ItemProps[]>(() => [
    {
      label: 'Notify tenant on renewal',
      field: 'sendTenantNotifyOnRenewal',
      type: 'boolean',
      class: 'col-span-2',
    },
    {
      field: 'rentChartOfAccounts',
      label: 'Rent chart of accounts',
      type: 'chartOfAccountSelect',
      multiple: true,
      class: 'col-span-2',
      types: 'revenue',
    },
    {
      field: 'leaseTemplates',
      label: 'Lease templates',
      type: 'select',
      multiple: true,
      class: 'col-span-2',
      options: leaseTemplateOptions(),
    },
  ]);
  const [isShowModal, setIsShowModal] = createSignal(false);

  const onSave = async (data: MagicDoor.Api.LeaseRenewalSettingsDto, level?: string, levelId?: string) => {
    if (!level && !levelId) {
      await updateLeaseSettings(data);
      toast.success(t('Lease renewal settings saved successfully'));
      getAllLeaseSettings();
      return;
    }

    await updateOverrideLeaseSettings(level as string, levelId as string, data);
    toast.success(t('Lease renewal settings saved successfully'));
    getAllLeaseSettings();
  };

  const onIsShowModalChange = () => {
    setIsShowModal((prev) => !prev);
  };

  const onAddOverrideSettingsConfirm = async (level: string, levelId: string) => {
    await addOverrideSettings(level, levelId, { ...((allLeaseSettings()?.settings ?? {}) as MagicDoor.Api.LeaseRenewalSettingsDto) });
    onIsShowModalChange();
    toast.success(t('Add lease renewal settings successfully'));
    getAllLeaseSettings();
  };

  const onRemoveOverrideSettings = (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this override?'),
      title: t('Delete override'),
      onResolve: async (confirmed) => {
        if (!confirmed) {
          return;
        }

        await removeOverrideSettings(level, levelId);
        toast.success(t('Lease renewal settings deleted successfully'));
        getAllLeaseSettings();
      },
    });
  };

  onMount(() => {
    getLeaseTemplateOptions();
    getAllLeaseSettings();
  });

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Lease renewal')} icon={IconLeaseRenewal} />}
        topAction={
          <Button variant="outlined" class="rounded-3xl px-3 py-1 text-sm" onClick={onIsShowModalChange}>
            <IconPlus />
            <span class="hidden font-medium md:block">{t('Add new override')}</span>
          </Button>
        }
        middle={
          <div class="flex flex-col gap-5 p-3 md:p-6">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => onSave(data)}
                  model={allLeaseSettings()?.settings || ({} as MagicDoor.Api.LeaseRenewalSettingsDto)}
                />
              </div>
            </Show>
            <Overrides
              overrides={allLeaseSettings()?.overrides ?? []}
              onDelete={onRemoveOverrideSettings}
              title={t('Lease renewal overrides')}>
              {(override) => (
                <div class="flex flex-col gap-4 p-2 text-left">
                  <EditForm
                    sections={sections()}
                    model={(override.settings ?? {}) as MagicDoor.Api.LeaseRenewalSettingsDto}
                    onOk={(data) => onSave(data, override.level, override.levelId)}
                  />
                </div>
              )}
            </Overrides>
          </div>
        }
      />
      <Show when={isShowModal()}>
        <AddOverridesModal
          overrides={allLeaseSettings()?.overrides || []}
          loading={isLoading()}
          onClose={onIsShowModalChange}
          onDone={onAddOverrideSettingsConfirm}
        />
      </Show>
    </>
  );
};
