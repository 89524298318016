import { useNavigate } from '@solidjs/router';
import { createSignal, createMemo, Show, createEffect } from 'solid-js';
import DetailIcon from '~/assets/images/common/detail.svg?component-solid';
import IconCall from '~/assets/images/contact/call.svg?component-solid';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import { TenantTitle } from '~/components/tenants';
import { Table, TableActions } from '~/components/ui';
import { MobileListView } from '~/components/ui/ListView/MobileListView';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { TenantPortalStatus } from '~/pages/tenants/tenant-details/components/TenantPortalStatus';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type MovedOutTenantsTableProps = {
  tenants: MagicDoor.Api.HydratedLeaseTenantDto[];
};

export const MovedOutTenantsTable: Component<MovedOutTenantsTableProps> = (props) => {
  const { t } = useLocalization();
  const { lease } = useLease();
  const navigate = useNavigate();

  const [currentMovedOutTenants, setCurrentMovedOutTenants] = createSignal<MagicDoor.Api.HydratedLeaseTenantDto[]>([]);
  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseTenantDto>>(() => [
    {
      title: t('Name'),
      render: (item) => <TenantTitle tenant={item.tenant} />,
    },
    {
      title: t('Contact'),
      render: (item) => (
        <div class="space-y-1 text-xs">
          <Show when={item.tenant?.phone}>
            <div class="flex items-center space-x-1">
              <IconCall class="text-text-level03" />
              <span>{item.tenant?.phone}</span>
            </div>
          </Show>
          <Show when={item.tenant?.email}>
            <div class="flex items-center space-x-1">
              <IconEmail class="text-text-level03" />
              <span>{item.tenant?.email}</span>
            </div>
          </Show>
        </div>
      ),
    },
    {
      title: t('Start/End date'),
      render: (item) => (
        <span>
          {dateFormat('MM/DD/YYYY', item.moveIn)} - {dateFormat('MM/DD/YYYY', item.moveOut)}
        </span>
      ),
    },
    {
      title: '',
      class: 'relative',
      render: (item) => (
        <div class="flex justify-end">
          <TableActions
            actions={[
              {
                label: t('Detail'),
                icon: DetailIcon,
                href: `/users/tenants/${item.tenant?.id}`,
              },
            ]}
          />
        </div>
      ),
    },
  ]);

  createEffect(() => {
    if (lease()?.ended === true) {
      setCurrentMovedOutTenants(props.tenants);
    } else {
      setCurrentMovedOutTenants(props.tenants.filter((item: MagicDoor.Api.HydratedLeaseTenantDto) => item.isMovedOut === true));
    }
  });

  return (
    <Panel title={t('Past tenants')}>
      <Table
        class="hidden md:table"
        columns={columns()}
        data={currentMovedOutTenants()}
        rowLink={(item) => `/users/tenants/${item.tenant.id}`}
      />

      <MobileListView
        class="overflow-hidden md:hidden"
        titleClass="w-full"
        renderItem={(item) => {
          return (
            <div
              class="flex flex-col space-y-2 p-5"
              onClick={() => {
                navigate(`/users/tenants/${item.tenant.id}`);
              }}>
              <div class="flex">
                <TenantTitle tenant={item.tenant} />
                <div class="ml-2 flex flex-1 justify-between">
                  <TenantPortalStatus status={item.tenant?.portalStatus} />
                  <TableActions
                    actions={[
                      {
                        label: t('Detail'),
                        icon: DetailIcon,
                        href: `/users/tenants/${item.tenant?.id}`,
                      },
                    ]}
                  />
                </div>
              </div>
              <div class="flex items-center space-x-1">
                <span class="text-sm text-text-level03">{t('Phone')}:</span>
                <span class="text-sm text-text-level01">{item.tenant?.phone}</span>
              </div>
              <div class="flex items-center space-x-1">
                <span class="text-sm text-text-level03">{t('Email')}:</span>
                <span class="text-sm text-text-level01">{item.tenant?.email}</span>
              </div>
            </div>
          );
        }}
        data={currentMovedOutTenants()}
        footerClass="hidden"
      />
    </Panel>
  );
};
