import { Show, createSignal, createEffect } from 'solid-js';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Uploader } from '~/components/common/Upload';
import { Modal } from '~/components/modals';
import { useLocalization } from '~/contexts/global';
import { useAttachments } from '~/contexts/local';
import { LeaseTemplateType } from '~/swagger/Api';
import type { Component } from 'solid-js';
export type NewFileReadyToUpload = { type: string; fileName: string; description?: string; file: File };
export type FormErrors = {
  name?: string;
  description?: string;
};
export const FileUploadModal: Component<{
  entryType?: `${MagicDoor.Api.EntityType}`;
  entryId?: string;
  open: boolean;
  disableDescription?: boolean;
  accept?: string;
  allowPdfOnly?: boolean;
  allowNameInput?: boolean;
  pending?: boolean;
  onClose: () => void;
  onConfirm?: (
    categoryId: string,
    files: File[],
    name: string,
    description?: string,
    type?: `${LeaseTemplateType}`
  ) => Promise<boolean | undefined | void>;
  nameRequired?: boolean;
  descriptionRequired?: boolean;
  errors?: FormErrors;
  allowTypeSelect?: boolean;
  isEdit?: boolean;
  file?: MagicDoor.Api.HydratedFileAttachmentDto;
}> = (props) => {
  const { t } = useLocalization();
  const [fileName, setFileName] = createSignal('');
  const [description, setDescription] = createSignal<string>('');
  const [files, setFiles] = createSignal<File[]>([]);
  const [name, setName] = createSignal<string>('');
  const [isSaving, setIsSaving] = createSignal(false);
  const [type, setType] = createSignal<`${LeaseTemplateType}`>();

  const { addAttachment, cancelAttachment } = useAttachments();

  const formatLabel = (value: string) => value.replace(/([A-Z])/g, ' $1').trim();

  const leaseTemplateOptions = Object.values(LeaseTemplateType).map((value) => ({
    value,
    label: formatLabel(value),
  }));

  const handleClose = () => {
    cancelAttachment();
    props.onClose();
  };

  const handleConfirm = async () => {
    if (props.isEdit) {
      setIsSaving(true);
      await props.onConfirm?.(props.file?.id || '', [], fileName(), description());
      setIsSaving(false);
      props.onClose();
    } else {
      const currentFiles = files();
      if (currentFiles.length === 0) return;
      if (props.onConfirm) {
        const result = await props.onConfirm(props.entryId || '', currentFiles, name(), description(), type());
        if (result === false) return;
      } else if (props.entryType && props.entryId) {
        for (const file of currentFiles) {
          await addAttachment(props.entryType, props.entryId, file, description());
        }
      }

      props.onClose();
    }
  };

  createEffect(() => {
    if (props.isEdit && props.file) {
      setFileName(props.file.fileName || '');
      setDescription(props.file.description || '');
    }
    if (!props.open) {
      setFiles([]);
      setDescription('');
      setName('');
      setType(undefined);
    }
  });

  return (
    <Modal
      title={t(props.isEdit ? 'Edit file' : 'Upload file')}
      visible={props.open}
      doneText={t(props.isEdit ? 'Save' : 'Upload')}
      onCancel={handleClose}
      onDone={handleConfirm}
      disabled={props.isEdit ? !fileName() : files().length === 0}
      loading={props.isEdit ? isSaving() : addAttachment.pending || props.pending}
      confirmation={!props.isEdit && (files().length > 0 || description()) ? undefined : false}>
      <div class="m-6 flex flex-col gap-6">
        <Show when={!props.isEdit}>
          <Uploader.LabeledPreUploader
            label={t('Upload file')}
            accept={props.accept}
            usePreUpload={false}
            onFilesListChange={(files) => setFiles(files.map((file) => file.originalFile!))}
          />
          <Show when={props.allowNameInput}>
            <LabeledTextInput
              required={props.nameRequired}
              maxlength={150}
              label={t('Name')}
              placeholder={t('Enter name')}
              value={name()}
              onInput={setName}
              error={props.errors?.name}
            />
          </Show>
          <Show when={props.allowTypeSelect}>
            <LabeledSelect label={t('Type')} options={leaseTemplateOptions} value={type()} onInput={setType} />
          </Show>
        </Show>
        <Show when={props.isEdit}>
          <LabeledTextInput
            required
            label={t('File name')}
            placeholder={t('Enter file name')}
            value={fileName()}
            onInput={setFileName}
            maxlength={250}
            eventTriggerName="onInput"
          />
        </Show>
        <Show when={!props.disableDescription}>
          <LabeledTextInput
            required={props.descriptionRequired}
            label={t('Description')}
            placeholder={t('Enter description')}
            value={description()}
            onInput={setDescription}
            maxlength={200}
            error={props.errors?.description}
          />
        </Show>
      </div>
    </Modal>
  );
};
