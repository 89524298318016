import { createMagicDoorContext } from '~/contexts/utils';
import { RentPaymentProcessingFeesSettingsRepository } from '~/repositories/settings/rentPaymentProcessingFeesSettingsRepository';
import { createLazyResource, createMutation } from '~/utils/resource';
import type { Level } from '~/repositories/settings/rentPaymentProcessingFeesSettingsRepository';

const repo = new RentPaymentProcessingFeesSettingsRepository();

export const [RentPaymentProcessingFeesSettingsProvider, useRentPaymentProcessingFeesSettings] = createMagicDoorContext(
  'RentPaymentProcessingFeesSettings',
  () => {
    const [allSettings, allSettingsActions] = createLazyResource(() => repo.getAllRentPaymentProcessingFees());

    const updateSettings = createMutation(async (payload: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto) => {
      const updated = await repo.createRentPaymentProcessingFees(payload);
      allSettingsActions.mutate(
        (prev) => ({ ...prev, settings: updated }) as MagicDoor.Api.RentPaymentProcessingFeesSettingsDtoSettingsWithOverridesDto
      );
    });

    const deleteSettings = createMutation(async () => {
      await repo.deleteRentPaymentProcessingFees();
      allSettingsActions.mutate(undefined);
    });

    const updateLevelSettings = createMutation(
      async (level: Level, levelId: string, payload: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto) => {
        const updated = await repo.createLevelRentPaymentProcessingFees(level, levelId, payload);
        const override = { level: level as MagicDoor.Api.SettingsLevel, levelId, settings: updated };
        allSettingsActions.mutate((prev) => {
          if (prev == null) return;
          if (!prev.overrides?.some((x) => x.level === level && x.levelId === levelId)) {
            return { ...prev, overrides: [...(prev.overrides ?? []), override] };
          }
          return {
            ...prev,
            overrides: prev.overrides?.map((x) => (x.level === level && x.levelId === levelId ? override : x)),
          };
        });
      }
    );

    const deleteLevelSettings = createMutation(async (level: Level, levelId: string) => {
      await repo.deleteLevelRentPaymentProcessingFees(level, levelId);
      allSettingsActions.mutate((prev) => {
        if (prev == null) return;
        return {
          ...prev,
          overrides: prev.overrides?.filter((x) => x.level !== level || x.levelId !== levelId),
        };
      });
    });

    return {
      get allSettings() {
        allSettingsActions.fetch();
        return allSettings;
      },
      updateLevelSettings,
      deleteLevelSettings,
      updateSettings,
      deleteSettings,
    };
  }
);
