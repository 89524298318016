import { createSignal, Show } from 'solid-js';
import { DeactivatedFlag } from '~/components/common';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { getUnitFullname } from '~/components/leases/utils';
import { Image, Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { getImageUrl } from '~/utils/file';
import type { Component } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';

export const UnitImage: Component<{ class?: string; unitId?: string; imageId?: string | null }> = (props) => {
  const [isLoading, setIsLoading] = createSignal(true);

  return (
    <Show when={props.unitId} fallback={<Skeleton class={cn('size-12', props.class)} data-slot="image" />}>
      <Image
        class={cn('object-cover', isLoading() ? 'skeleton-shimmer' : 'bg-primary/20', props.class)}
        src={getImageUrl('unit', props.unitId!, props.imageId)}
        onLoad={() => setIsLoading(false)}
      />
    </Show>
  );
};

export const UnitTitle: Component<
  {
    class?: string;
    unit?: MagicDoor.Api.HydratedUnitDto | MagicDoor.Api.UnitDto;
    property?: MagicDoor.Api.PropertyDto;
  } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const { t } = useLocalization();
  return (
    <TitleWithFeedbackIcon {...pickProps(props)} type="units" entityId={props.unit?.id} initalSelectedIcons={props.unit?.icons}>
      <div class={cn('flex items-center gap-2 text-sm text-title-gray', props.class)}>
        <UnitImage class="size-12 rounded-md" unitId={props.unit?.id} imageId={props.unit?.imageId} data-slot="image" />
        <div class="line-clamp-2 space-y-0.5 text-left text-sm font-medium" data-slot="content">
          <h4 data-slot="title">
            {getUnitFullname({
              property: props.property,
              ...props.unit,
            } as MagicDoor.Api.HydratedUnitDto)}
            <DeactivatedFlag class="ml-2" active={props.unit?.active} />
          </h4>
          <p class="text-xs font-normal text-text-level03" data-slot="subtitle">
            <Show when={props.unit?.beds}>{t('Beds', { beds: String(props.unit?.beds) })}</Show>
            <Show when={props.unit?.beds && props.unit?.baths}> | </Show>
            <Show when={props.unit?.baths}>{t('Baths', { baths: String(props.unit?.baths) })}</Show>
            <Show when={props.unit?.baths && props.unit?.unitSizeSqft}> | </Show>
            <Show when={props.unit?.unitSizeSqft}>{t('{sqft} Sqft', { sqft: String(props.unit?.unitSizeSqft) })}</Show>
            <Show when={!props.unit?.beds && props.unit?.baths && props.unit?.unitSizeSqft}>{emptyPlaceholder}</Show>
          </p>
        </div>
      </div>
    </TitleWithFeedbackIcon>
  );
};
