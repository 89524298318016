import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';
import type { BankAccountConnectType, ConnectBankAccountDto } from '~/swagger/Api';

const BANK_ACCOUNTS_URL = '/api/bank-accounts';

export function validateBankAccount(
  bankAccount: MagicDoor.Api.HydratedBankAccountDto | MagicDoor.Api.CreateBankAccountDto | MagicDoor.Api.UpdateBankAccountDto
): void {
  if (!bankAccount) throw new ValidationError('BankAccount object must be provided');
}

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export class BankAccountRepository extends BaseRestRepository {
  public async getBankAccounts(): Promise<MagicDoor.Api.HydratedBankAccountDto[]> {
    const url = BANK_ACCOUNTS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getBankAccount(bankAccountId: string): Promise<MagicDoor.Api.HydratedBankAccountDto> {
    validateId(bankAccountId);
    const url = `${BANK_ACCOUNTS_URL}/${bankAccountId}`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async createBankAccount(bankAccount: MagicDoor.Api.CreateBankAccountDto): Promise<MagicDoor.Api.HydratedBankAccountDto> {
    validateBankAccount(bankAccount);
    const url = BANK_ACCOUNTS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(bankAccount),
    });
    return this.getJsonResponse(response);
  }

  public async updateBankAccount(
    bankAccountId: string,
    bankAccount: MagicDoor.Api.UpdateBankAccountDto
  ): Promise<MagicDoor.Api.HydratedBankAccountDto> {
    validateId(bankAccountId);
    validateBankAccount(bankAccount);
    const url = `${BANK_ACCOUNTS_URL}/${bankAccountId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(bankAccount),
    });
    return this.getJsonResponse(response);
  }

  public async deleteBankAccount(bankAccountId: string): Promise<void> {
    validateId(bankAccountId);
    if (!bankAccountId) throw new ValidationError('BankAccount ID must be provided');
    const url = `${BANK_ACCOUNTS_URL}/${bankAccountId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error(await response.json());
    }
  }

  public async getBankAccountConnections(bankAccountId: string): Promise<MagicDoor.Api.BankAccountConnectionsDto> {
    validateId(bankAccountId);
    const url = `${BANK_ACCOUNTS_URL}/${bankAccountId}/connections`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async bankAccountConnections(
    params: {
      bankAccountId: string;
      type: BankAccountConnectType;
    } & ConnectBankAccountDto
  ): Promise<MagicDoor.Api.ConnectBankAccountResultDto> {
    const { bankAccountId, type, ...connectParams } = params;
    validateId(bankAccountId);
    const url = `${BANK_ACCOUNTS_URL}/${bankAccountId}/connections/${type}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(connectParams),
    });
    return this.getJsonResponse(response);
  }

  public async plaidSweeps(bankAccountId: string): Promise<MagicDoor.Api.PlaidSweepTrackingDto> {
    validateId(bankAccountId);
    const url = `${BANK_ACCOUNTS_URL}/${bankAccountId}/sweeps`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
    });
    return this.getJsonResponse(response);
  }

  public async getSweepsLimits(): Promise<MagicDoor.Api.BankAccountSweepLimitsDto> {
    const url = `${BANK_ACCOUNTS_URL}/sweeps/limits`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }
}
