import { createResource, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { FileRepository } from '~/repositories/fileRepository';
import { createMutation } from '~/utils/resource';

const repo = new FileRepository();

type AttachmentsFilter = {
  entityType: `${MagicDoor.Api.EntityType}`;
  entityId: string;
};

export const [AttachmentsProvider, useAttachments] = createMagicDoorContext('Attachments', () => {
  const [filter, setFilter] = createSignal<AttachmentsFilter>();
  let abortController: AbortController;

  const [attachments, { refetch, mutate }] = createResource(filter, async ({ entityType, entityId }) =>
    repo.getAttachments(entityType, entityId)
  );

  const addAttachment = createMutation(
    async (entityType: `${MagicDoor.Api.EntityType}`, entityId: string, file: File, description?: string) => {
      abortController = new AbortController();
      const created = await repo.addAttachment(entityType, entityId, file, description, abortController.signal);
      mutate((prev) => prev && [created, ...prev]);
    }
  );

  const updateAttachment = createMutation(
    async (
      entityType: `${MagicDoor.Api.EntityType}`,
      entityId: string,
      fileAttachmentId: string,
      updateData: { fileName: string; description?: string }
    ) => {
      const updated = await repo.updateAttachment(entityType, entityId, fileAttachmentId, updateData, abortController?.signal);
      mutate((prev) => prev?.map((attachment) => (attachment.id === fileAttachmentId ? { ...attachment, ...updated } : attachment)));
    }
  );

  const deleteAttachment = createMutation(async (entityType: `${MagicDoor.Api.EntityType}`, entityId: string, fileAttachmentId: string) => {
    await repo.deleteAttachment(entityType, entityId, fileAttachmentId);
    mutate((prev) => prev && prev.filter((attachment) => attachment.id !== fileAttachmentId));
  });

  const cancelAttachment = () => {
    abortController?.abort();
  };

  return {
    attachments,
    refetch,
    getAttachments: (entityType: `${MagicDoor.Api.EntityType}`, entityId: string) => setFilter({ entityType, entityId }),
    addAttachment,
    deleteAttachment,
    cancelAttachment,
    updateAttachment,
  };
});
