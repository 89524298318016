import { createEffect, createSignal, splitProps } from 'solid-js';
import imgFailed from '~/assets/images/common/img-failed.svg';
import type { Component, ComponentProps } from 'solid-js';

export const Image: Component<ComponentProps<'img'> & { fallback?: string }> = (props) => {
  const [params, rest] = splitProps(props, ['fallback', 'src', 'onLoad', 'onError']);
  const [state, setState] = createSignal<'loading' | 'loaded' | 'error'>('loading');

  createEffect(() => params.src && setState('loading'));

  return (
    <img
      {...rest}
      src={state() !== 'error' ? params.src : (params.fallback ?? imgFailed)}
      onLoad={(e) => {
        setState((prev) => (prev === 'loading' ? 'loaded' : prev));
        typeof params.onLoad === 'function' && params.onLoad(e);
      }}
      onError={(e) => {
        setState((prev) => (prev === 'loading' ? 'error' : prev));
        typeof params.onError === 'function' && params.onError(e);
      }}
      data-state={state()}
    />
  );
};
