import { onCleanup, onMount, createEffect } from 'solid-js';
import ImgSideBar from '~/assets/images/sidebar/sidebarBg.png';
import { LinkToWebsite } from '~/components/layouts/Menu/components/LinkToWebsite';
import { IconChevronLeft, IconChevronRight } from '~/components/ui/Icons';
import { useOutsideClick } from '~/utils/hooks';
import MainMenuItems from './MainMenuItems';
import { useMenu } from './MenuContext';
import SubMenuItems from './SubMenuItems';

const Sidebar = () => {
  const menu = useMenu();
  const { isSidebarOpen, setIsSidebarOpen } = menu!;
  const toggleMenuCollapse = () => {
    menu?.setIsManuallyCollapsed(!menu.isManuallyCollapsed());
  };

  let refMenuContainer!: HTMLDivElement;

  const handleResize = () => setIsSidebarOpen(window.innerWidth > 767);
  onMount(() => {
    window.addEventListener('resize', handleResize);
  });
  onCleanup(() => window.removeEventListener('resize', handleResize));
  useOutsideClick(
    () => refMenuContainer,
    () => menu?.isCollapsed() == true,
    () => menu?.setActiveMenuItem(undefined)
  );

  createEffect(() => {
    if (isSidebarOpen() && menu?.isMobile()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  });

  onCleanup(() => {
    document.body.style.overflow = '';
  });

  return (
    <>
      <div
        class="fixed inset-0 z-30 bg-black/50 transition-opacity md:hidden"
        classList={{ 'opacity-0 pointer-events-none': !isSidebarOpen(), 'opacity-100': isSidebarOpen() }}
        onClick={() => setIsSidebarOpen(false)}
      />

      <div
        class={`fixed left-0 top-0 z-40 flex h-dvh -translate-x-full flex-col text-sm transition-transform md:translate-x-0`}
        classList={{
          'translate-x-0 w-[245px] md:w-64': isSidebarOpen() && !menu?.isManuallyCollapsed(),
          'translate-x-0 w-[245px] md:w-[68px]': isSidebarOpen() && menu?.isManuallyCollapsed(),
        }}
        ref={refMenuContainer}
        style={{
          background: 'linear-gradient(177deg, #510FA6 0%, #74139D 100%), linear-gradient(177deg, #7C2EC6 0%, #6222B9 100%), #5B18AC',
        }}>
        <img
          src="/logo.png"
          class="sticky top-0 z-20 mx-auto select-none pb-6 pt-4"
          classList={{
            'w-44': !menu?.isManuallyCollapsed() || !isSidebarOpen(),
            'w-10': menu?.isManuallyCollapsed() && isSidebarOpen() && !menu.isMobile(),
            'mt-[55px]': menu?.isManuallyCollapsed() && isSidebarOpen(),
          }}
          alt="logo"
        />
        <button
          id="sidebar-toggle-collapse-btn"
          onClick={toggleMenuCollapse}
          class="absolute right-2 top-4 hidden rounded-md border border-white/40 bg-white/10 p-1 text-white/70 hover-allowed:hover:text-white md:block">
          {menu?.isManuallyCollapsed() ? <IconChevronRight /> : <IconChevronLeft />}
        </button>
        <img class="absolute bottom-0 left-0 w-full" src={ImgSideBar} alt="sidebar background" />
        <div class="thinscroll flex overflow-x-hidden" style={{ '--track-color': 'transparent' }}>
          <MainMenuItems
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen()}
            isMenuCollapsed={menu?.isManuallyCollapsed() ?? false}
          />
          <SubMenuItems setIsSidebarOpen={setIsSidebarOpen} />
        </div>

        <LinkToWebsite isMenuCollapsed={menu?.isManuallyCollapsed()} />
      </div>
    </>
  );
};

export default () => {
  return <Sidebar />;
};
