import { onMount, createEffect } from 'solid-js';
import { createStore } from 'solid-js/store';
import { getLeaseDateName } from '~/components/leases/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { PortfolioRepository } from '~/repositories/portfolioRepository';
import { contextCreator } from '~/utils/contextCreator';

const leaseRepo = new LeaseRepository();
const portfolioRepo = new PortfolioRepository();

export interface AddOverridePortfolio extends MagicDoor.Api.HydratedPortfolioDto {
  unitCount: number;
}

export interface LeaseWithName extends MagicDoor.Api.HydratedLeaseDto {
  name?: string;
}

export const [OverrideContext, useOverrideContext] = contextCreator('addOverride', () => {
  const [store, setStore] = createStore<{
    properties: MagicDoor.Api.PropertyDto[];
    error: string;
    leases: LeaseWithName[];
    leaseWithoutName: MagicDoor.Api.HydratedLeaseDto[];
    portfolios: AddOverridePortfolio[];
    overrides: any[];
    portfoliosMap: Record<string, any>;
    propertiesMap: Record<string, any>;
    unitsMap: Record<string, any>;
    leasesMap: Record<string, any>;
  }>({
    leaseWithoutName: [],
    error: '',
    properties: [],
    leases: [],
    portfolios: [],
    overrides: [],
    portfoliosMap: {},
    propertiesMap: {},
    unitsMap: {},
    leasesMap: {},
  });

  async function getPortfolios() {
    try {
      const res = await portfolioRepo.getPortfolios();
      const properties: MagicDoor.Api.PropertyDto[] = [];
      const portfolios: AddOverridePortfolio[] = [];
      const portfoliosMap: Record<string, any> = {};
      const propertiesMap: Record<string, any> = {};
      res.forEach((item) => {
        const portfolio = { ...item, unitCount: 0 };
        portfoliosMap[portfolio.id] = { name: portfolio.name };
        item.properties?.forEach((property) => {
          propertiesMap[property.id] = { name: property.name };
          portfolio.unitCount += property?.unitCount || 0;
          properties.push(property);
        });
        portfolios.push(portfolio);
      });

      setStore({ portfolios, properties, portfoliosMap, propertiesMap });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  async function getLeases() {
    try {
      const res = await leaseRepo.getLeases();
      setStore({ leaseWithoutName: res.items });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  createEffect(() => {
    const leases: LeaseWithName[] = [];
    const leasesMap: Record<string, any> = {};
    store.leaseWithoutName.forEach((item) => {
      const name = getLeaseDateName(item);
      leasesMap[item.id] = { name };
      const target = {
        ...item,
        name: name,
      };
      leases.push(target);
    });
    setStore({ leases, leasesMap });
  });

  onMount(() => {
    getPortfolios();
    getLeases();
  });

  const setData = (data: any) => {
    setStore(data);
  };

  return {
    store,
    setData,
    getLeases,
  };
});
