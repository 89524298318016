import { A } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show, createEffect, createMemo, createSignal } from 'solid-js';
import IconChat from '~/assets/images/chat/chatIcon.svg';
import { IconChevronRight } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useChat } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { ChatMessageDto } from '~/swagger/Api';

type ChatDropdownProps = {
  title: string;
  icon: string;
  chats: MagicDoor.Api.HydratedMagicChatDto[];
  selectedChatId: string;
  searchingInProgress?: boolean;
  onChatClick?: (chatId: string) => void;
};

export const ChatDropdown: Component<ChatDropdownProps> = (props) => {
  const { t } = useLocalization();
  const { setIsSearchMode } = useChat();
  const { companies } = useCompanies();

  const [expanded, setExpanded] = createSignal(false);

  const sortedChats = createMemo(() =>
    props.chats.sort((a, b) => {
      const dateA = dayjs(a.lastMessageSentAt ?? a.created ?? 0);
      const dateB = dayjs(b.lastMessageSentAt ?? b.created ?? 0);

      return dateB.valueOf() - dateA.valueOf();
    })
  );

  const totalUnread = createMemo(() => {
    return props.chats.reduce((acc, chat) => acc + (chat.unreadMessages ?? 0), 0);
  });

  createEffect(() => {
    const isOpen = props.chats.some((chat) => chat.id === props.selectedChatId);
    isOpen && setExpanded(true);
  });

  const shownMessage = (latestMessage?: ChatMessageDto) => {
    if (!latestMessage) {
      return;
    }

    if (companies()?.language === latestMessage.translatedMessageLanguage) {
      return latestMessage.translatedMessage;
    } else {
      return latestMessage.message;
    }
  };

  return (
    <Show when={sortedChats().length}>
      <div
        class="my-0.5 flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-3 hover-allowed:hover:bg-[#d7dcea]"
        onClick={() => setExpanded((prev) => !prev)}>
        <div class="flex gap-2">
          <IconChevronRight
            class="size-5 text-text-level02 transition"
            classList={{ 'rotate-90 text-text-level01': expanded() || props.searchingInProgress }}
          />
          <div class="flex">
            <img src={props.icon} alt={`${props.title} Icon`} class="mr-1.5" />
            <span class={cn('truncate text-sm font-medium text-text-level01', { 'max-w-36': totalUnread() > 0 })}>{t(props.title)}</span>
          </div>
        </div>
        <Show when={totalUnread() > 0}>
          <div class="flex text-text-level02">
            {totalUnread()} {t('Unread')}
          </div>
        </Show>
      </div>
      <For each={expanded() || props.searchingInProgress ? sortedChats() : []}>
        {(chat) => (
          <A
            href={`/communications/chats/${chat.id}`}
            class={`ml-7 mt-0.5 flex items-center justify-between gap-1 rounded-md p-3 hover-allowed:hover:bg-[#d7dcea] ${
              props.selectedChatId === chat.id ? 'bg-[#d7dcea]' : ''
            }`}
            onClick={() => {
              props.onChatClick?.(chat.id);
              setIsSearchMode(false);
            }}>
            <div class="flex w-48">
              <img src={IconChat} class="mr-2" />
              <Show
                when={chat.subject}
                fallback={
                  <span class="truncate text-text-level01">
                    {chat.participants &&
                      chat.participants.length > 0 &&
                      chat.participants
                        .filter((p) => p.participantType !== ('propertymanager' as MagicDoor.Api.ChatParticipantType))
                        .map((p) => p.name)
                        .join(', ')}
                  </span>
                }>
                <div class="flex w-48 flex-col">
                  <div class="truncate" classList={{ 'font-semibold': chat.unreadMessages > 0 }}>
                    {chat.subject}
                  </div>
                  <Show when={chat.latestMessage} fallback={<div class="text-text-level03">👋 {t('This is a new chat')}</div>}>
                    <div class="flex gap-2 truncate text-text-level03">
                      <div class="text-text-level03">
                        <Show when={chat.latestMessage?.participantId}>
                          {chat.latestMessage && chat.participants.find((p) => p.id === chat.latestMessage?.participantId)?.name}:
                        </Show>
                        <Show when={chat.latestMessage?.messageType === 'bot'}>{t('AI')}:</Show>
                        <Show when={chat.latestMessage?.messageType === 'announcement'}>{t('Announcement')}:</Show>
                      </div>
                      <Show
                        when={chat.latestMessage?.files && !chat.latestMessage.message}
                        fallback={<div class="truncate">{shownMessage(chat.latestMessage || undefined)}</div>}>
                        [{t('Attachment')}]
                      </Show>
                    </div>
                  </Show>
                </div>
              </Show>
            </div>
            <Show when={chat.unreadMessages > 0}>
              <div class="flex size-5 items-center justify-center rounded-full bg-danger p-1 text-xs text-white">{chat.unreadMessages}</div>
            </Show>
          </A>
        )}
      </For>
    </Show>
  );
};
