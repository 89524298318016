import { Routes, Route } from '@solidjs/router';
import { CategoriesListPage } from '~/pages/maintenance-request/maintenance-request-categories/CategoriesList';
import { CategoryModal } from '~/pages/maintenance-request/maintenance-request-categories/CategoryModal';

export const MaintenanceRequestCategoriesRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="*" component={CategoriesListPage} />
      </Routes>
      <Routes>
        <Route path="/add" component={CategoryModal} />
        <Route path="/edit/:id" component={CategoryModal} />
      </Routes>
    </>
  );
};
