import { FormItem } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { tenantName } from '~/utils/formRules';

export const NewApplicantForm = () => {
  const { t } = useLocalization();

  return (
    <div class="grid grid-cols-3 gap-2">
      <div class="text-sm text-text-level03">
        {t('If the applicant’s information is not in the system, please type in legal name here')}
      </div>
      <FormItem
        id="new-rental-application-step1-firstname"
        placeholder={t('First name')}
        rules={[{ required: true, message: t('Please input first name') }, ...tenantName(t('First name'))]}
        label={t(`First name`)}
        formFieldName={['firstName']}
        component={LabeledTextInput}
      />
      <FormItem
        id="new-rental-application-step1-lastName"
        rules={[{ required: true, message: t('Please input last name') }, ...tenantName(t('Last name'))]}
        placeholder={t('Last name')}
        label={t(`Last name`)}
        formFieldName={['lastName']}
        component={LabeledTextInput}
      />
    </div>
  );
};
