import { useParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show, createMemo, createSignal, onCleanup, onMount } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconPic from '~/assets/images/common/pic.svg?component-solid';
import IconFeatures from '~/assets/images/identity/robotBox.png';
import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconDateAvailable from '~/assets/images/property/date.svg';
import IconMarketRent from '~/assets/images/property/marketRent.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import IconFacts from '~/assets/images/units/listing/factsDefault.png';
import IconSelect from '~/assets/images/units/listing/select.svg?component-solid';
import IconWaring from '~/assets/images/units/listing/warning.svg?component-solid';
import { Button, LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { DropdownMenu } from '~/components/common/DropdownMenu/DropdownMenu';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { propertyTypes } from '~/components/properties';
import { IconDownload, IconLink, IconRefresh } from '~/components/ui/Icons';
import { useCompanies, useLocalization, useUnitListings } from '~/contexts/global';
import { GoogleMap } from '~/pages/listing/components/GoogleMap';
// import { ListingStatus, statusMap } from '~/pages/listing/components/ListingStatus';
import { ListingIs } from '~/pages/listing/components/ListingIs';
import { UnitMetric } from '~/pages/units/components/UnitMetric';
import { emptyPlaceholder } from '~/utils/constant';
import { downloadFile, getFileAllUrl } from '~/utils/file';
import { currency } from '~/utils/number';
import { hydratedCheckbox } from '~/utils/tool';
import { ListingDescriptions } from './components/ListingDescriptions';
import { NotSet } from './components/NotSet';
import { PetPolicy } from './components/PetPolicy';

export const UnitInformationTab = () => {
  const params = useParams<{ portfolioId: `${number}`; listingId: `${number}` }>();
  const [imgSize, setImgSize] = createSignal<number>(window.innerWidth > 767 ? 4 : 3);

  const { t } = useLocalization();
  const { unitListingDetail, updateUnitListing } = useUnitListings();
  const { getWebsiteUrl } = useCompanies();
  const combinedAmenities = createMemo(() => hydratedCheckbox(unitListingDetail()?.amenities as MagicDoor.Api.AmenityDto[]));

  const handleResize = () => setImgSize(window.innerWidth > 767 ? 4 : 3);
  onMount(() => {
    window.addEventListener('resize', handleResize);
  });

  onCleanup(() => window.removeEventListener('resize', handleResize));

  const ImageList = (props: { listing: MagicDoor.Api.HydratedUnitListingDto; images: MagicDoor.Api.EmbedFileDto[] }) => {
    return (
      <>
        <For each={props.images?.slice(0, imgSize())}>
          {(item, index) => (
            <div class="relative overflow-hidden rounded-lg">
              <img
                src={getFileAllUrl(item.signedUrl!)}
                class="h-20 w-full rounded-lg border-2 border-essential-colour object-cover md:h-52"
              />
              <Show when={index() === imgSize() - 1 && props.images?.length > 0}>
                <div class="absolute inset-0 flex items-center justify-center bg-black/50">
                  <IconPic class="size-6" />
                  <span class="ml-1 text-2xl text-white">{props.images?.length}</span>
                </div>
              </Show>
            </div>
          )}
        </For>
      </>
    );
  };

  const listingOptions = [
    { label: t('Unlisted'), value: 'Unlisted' },
    { label: t('Listed'), value: 'Listed' },
  ];

  const handleUpdateStatus = async (status: string) => {
    try {
      const listed = status === 'Listed';
      const model = { ...unitListingDetail(), listed };
      await updateUnitListing(model as MagicDoor.Api.UpdateUnitListingDto, params.listingId);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadSelect = async () => {
    const files = unitListingDetail()?.images || [];
    if (files.length === 0) return;
    files.forEach((file) => downloadFile(file.signedUrl!));
  };

  const renderOperateButton = (isMobile?: boolean) => {
    return (
      <div class="flex items-center gap-2">
        <Show when={unitListingDetail()?.listed}>
          <LinkButton
            href={getWebsiteUrl(`/properties/${unitListingDetail()?.id}`)}
            variant="outlined"
            class="border-link py-2 font-medium text-link hover-allowed:hover:bg-link/10"
            target="_blank">
            <IconLink class="size-4" />
            {t('Go listing')}
          </LinkButton>
        </Show>

        <LinkButton
          id={isMobile ? 'listing-details-view-unit-btn-mobile' : 'listing-details-view-unit-btn'}
          href={`/portfolios/${params.portfolioId}/properties/${unitListingDetail()?.property?.id}/units/${unitListingDetail()?.unit?.id}`}
          variant="outlined"
          class="border-link py-2 font-medium text-link hover-allowed:hover:bg-link/10">
          {t('View unit')}
        </LinkButton>
        <LinkButton
          id={isMobile ? 'listing-details-edit-btn-mobile' : 'listing-details-edit-btn'}
          href="./edit-listing"
          variant="outlined"
          class="border-link py-2 font-medium text-link hover-allowed:hover:bg-link/10">
          <IconEdit /> {t('Edit listing')}
        </LinkButton>
      </div>
    );
  };

  const renderDrowdownMenu = (isMobile?: boolean) => {
    return (
      <DropdownMenu
        name={isMobile ? 'listing-status-mobile' : 'listing-status'}
        disabled={Object.keys(unitListingDetail()?.canNotBeListedReasons || {}).length > 0}
        class="cursor-pointer"
        options={listingOptions}
        value={unitListingDetail()?.listed ? 'Listed' : 'Unlisted'}
        onChange={handleUpdateStatus}
        renderLabel={() => <ListingIs listed={unitListingDetail()?.listed} />}
        contentPosition={['bottom']}
        buttonClass="gap-2 border-none bg-transparent p-0"
        arrowIcon={<IconSelect class={unitListingDetail()?.listed ? 'text-[#B5FFEE]' : 'text-[#FFF4D7]'} />}
      />
    );
  };

  return (
    <div>
      <Show when={Object.keys(unitListingDetail()?.canNotBeListedReasons || {}).length > 0}>
        <div class="mb-2 flex items-start rounded-xl bg-danger-light p-4 text-danger shadow-[0_6px_24px_0_rgba(0,0,0,0.12)] md:mb-5 md:items-center md:shadow-none">
          <IconWaring class="mr-4 size-5" />
          <div class="flex-1 text-center md:flex md:items-center md:justify-between">
            <div class="text-left">
              <For each={Object.values(unitListingDetail()?.canNotBeListedReasons || {})}>
                {(reason) => <span>{reason as string}</span>}
              </For>
            </div>
            <Show when={!unitListingDetail()?.property.address?.isValid}>
              <LinkButton
                id="listing-details-update-properties-btn"
                variant="outlined"
                class="mx-auto inline-block rounded-full border-danger py-1 text-sm font-medium text-danger hover-allowed:hover:bg-danger/10"
                href={`/portfolios/${params.portfolioId}/properties/${unitListingDetail()?.property?.id}/edit`}>
                <IconRefresh class="size-4" />
                {t('Update')}
              </LinkButton>
            </Show>
          </div>
        </div>
      </Show>
      <div class="grid grid-cols-1 gap-5 lg:grid-cols-12">
        <Panel class="col-span-full p-3 md:px-6 md:py-5 lg:col-span-9">
          <div>
            <div class="mb-4 flex items-center justify-between border-b border-input-border pb-3 md:hidden">
              {renderDrowdownMenu(true)}
              {renderOperateButton(true)}
            </div>
            <div class="flex flex-wrap justify-between gap-2">
              <div class="flex flex-col gap-2">
                <div class="flex flex-wrap items-center gap-2 text-xl font-semibold text-text-level01 md:text-2xl">
                  <Tooltip
                    class="w-fit max-w-[90%] whitespace-normal break-words sm:max-w-[500px] md:max-w-[700px]"
                    message={unitListingDetail()?.title}
                    align="bottom">
                    {unitListingDetail()?.title}
                  </Tooltip>
                  <div class="mt-2 flex flex-wrap items-center gap-2 sm:mt-0">
                    <div class="flex items-center rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
                      <Dynamic
                        component={propertyTypes[unitListingDetail()?.property?.type as MagicDoor.Api.PropertyType]?.icon}
                        class="size-4"
                      />
                      {propertyTypes[unitListingDetail()?.property?.type as MagicDoor.Api.PropertyType]?.name}
                    </div>

                    <div class="hidden md:inline">{renderDrowdownMenu()}</div>
                  </div>
                </div>

                <DisplayAddress class="truncate text-sm text-text-level03 md:text-xs" address={unitListingDetail()?.property?.address} />
              </div>
              <div class="hidden md:block">{renderOperateButton()}</div>
            </div>

            <div class="mb-0 mt-5 flex gap-20 md:my-6">
              <div>
                <div class="text-xs text-text-level03">{t('Rent')}</div>
                <div class="text-xl font-semibold text-essential-colour">
                  <Show when={unitListingDetail()?.rentalPrice} fallback={<NotSet />}>
                    {currency(unitListingDetail()?.rentalPrice || '')}
                  </Show>
                </div>
              </div>
              <div>
                <div class="text-xs text-text-level03">{t('Security deposit')}</div>
                <div class="text-xl font-semibold text-text-level01">
                  {currency(unitListingDetail()?.securityDeposit || emptyPlaceholder)}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-10 pt-6 md:grid-cols-5 md:py-6">
              <UnitMetric icon={IconBedroom} title={t('Bedroom')} value={unitListingDetail()?.unit?.beds || emptyPlaceholder} />
              <UnitMetric icon={IconBathroom} title={t('Bathroom')} value={unitListingDetail()?.unit?.baths || emptyPlaceholder} />
              <UnitMetric
                icon={IconUnitSize}
                title={t('Unit size')}
                value={unitListingDetail()?.unit?.unitSizeSqft || emptyPlaceholder}
                suffix={t('Sqft')}
              />
              <UnitMetric
                icon={IconDateAvailable}
                title={t('Date available')}
                value={
                  unitListingDetail()?.availableFrom ? dayjs(unitListingDetail()?.availableFrom).format('YYYY-MM-DD') : emptyPlaceholder
                }
              />
              <UnitMetric
                icon={IconMarketRent}
                title={t('Market rent')}
                value={currency(unitListingDetail()?.marketRent || emptyPlaceholder)}
              />
            </div>
          </div>
        </Panel>

        <Panel class="col-span-full h-64 overflow-hidden rounded-lg bg-white p-2 lg:col-span-3 lg:h-auto">
          <GoogleMap address={unitListingDetail()?.singleLineAddress} />
        </Panel>

        <Panel title={t('Facts and features')} class="col-span-full lg:col-span-4">
          <div class="relative h-[calc(100%-2.75rem)] min-h-36 px-6 py-4">
            <Show
              when={combinedAmenities().length > 0}
              fallback={
                <div class="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center">
                  <img src={IconFacts} alt="Facts icon" />
                  <p class="mt-1 text-xs normal-case text-text-level03">{t('Facts and features have not been added')}</p>
                </div>
              }>
              <div class="flex w-full flex-wrap gap-3">
                <For each={combinedAmenities()}>
                  {(item) => (
                    <div class="flex w-full items-center gap-2 text-sm text-text-level02 md:w-[calc(50%-0.375rem)] xl:w-[calc(50%-0.5rem)]">
                      <Checkbox checked={item?.value} disabled />
                      <span class="w-[98%] truncate">{item?.label}</span>
                    </div>
                  )}
                </For>
              </div>
            </Show>
          </div>
        </Panel>
        <Panel title={t('Pet policy')} class="col-span-full lg:col-span-4">
          <div class="relative h-[calc(100%-2.75rem)] min-h-36 px-6 py-4">
            <PetPolicy />
          </div>
        </Panel>
        <Panel title={t('Highlight features')} class="col-span-full lg:col-span-4">
          <div class="px-6 py-4 text-text-level02">
            <Show
              when={unitListingDetail()?.tags}
              fallback={
                <div class="flex w-full flex-col items-center">
                  <img src={IconFeatures} />
                  <p class="mt-1 text-center text-xs normal-case text-text-level03">
                    {t("Click on 'Magic AI' below to automatically generate")}
                  </p>
                </div>
              }>
              <div class="text-sm normal-case">{t("Here are the features we've analyzed for your property!")}:</div>
              <div class="mt-2 flex flex-wrap items-center gap-1.5">
                <For each={unitListingDetail()?.tags}>
                  {(tag) => <div class="rounded-md bg-light-pink px-3 py-1 text-sm text-[#B140F5]">{tag}</div>}
                </For>
              </div>
            </Show>
          </div>
        </Panel>

        <ListingDescriptions />
        <Show
          when={unitListingDetail()?.images && unitListingDetail()?.images?.length}
          fallback={
            <span class="col-span-full text-center text-sm text-text-level03">
              {t('There are no images associated with this listing')}...
            </span>
          }>
          <Panel
            title={t('Photos')}
            class="col-span-full"
            actions={
              <Button variant="outlined" onClick={handleDownloadSelect}>
                <IconDownload class="size-5" />
              </Button>
            }>
            <div class="m-4 grid grid-cols-3 gap-3 md:grid-cols-4">
              <ImageList images={unitListingDetail()?.images || []} listing={unitListingDetail() as MagicDoor.Api.HydratedUnitListingDto} />
            </div>
          </Panel>
        </Show>
      </div>
    </div>
  );
};
