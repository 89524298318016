import { For, onMount, onCleanup } from 'solid-js';
import { useMenu } from './MenuContext';
import { MainMenuItem } from './components/MainMenuItem';

interface MainMenuItemsProps {
  setIsSidebarOpen: (value: boolean) => void;
  isSidebarOpen: boolean;
  isMenuCollapsed: boolean;
}

const MainMenuItems = (props: MainMenuItemsProps) => {
  const menu = useMenu();

  const handleResize = () => {
    if (window.innerWidth <= 767 && menu?.isManuallyCollapsed()) {
      menu?.setIsManuallyCollapsed(false);
    }
  };

  onMount(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  });

  onCleanup(() => {
    window.removeEventListener('resize', handleResize);
  });

  return (
    <div
      class="z-20 h-full pl-3 text-left text-sm text-white"
      classList={{
        'min-w-[68px] max-w-[68px]': menu?.isManuallyCollapsed() || menu?.isCollapsed(),
        'w-64': !menu?.isManuallyCollapsed() && !menu?.isCollapsed(),
        'w-full pr-2': props.isSidebarOpen && !menu?.isManuallyCollapsed() && !menu?.isCollapsed(),
      }}>
      <ul class="space-y-1 pb-24">
        <For each={menu?.menuItems()}>
          {(item) => <MainMenuItem item={item} setIsSidebarOpen={props.setIsSidebarOpen} isMenuCollapsed={props.isMenuCollapsed} />}
        </For>
      </ul>
    </div>
  );
};

export default MainMenuItems;
