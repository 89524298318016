import { validateEmail } from '~/utils/validations';
import type { BetterForm } from '@magicdoor/form';

const emailSessionKey = 'session-email-for-reset-password';

export const restoreEmail = (form: BetterForm.form) => {
  const email = sessionStorage.getItem(emailSessionKey);
  if (email) {
    form.setFieldValue('email', email);
  }
};

export const saveEmail = (email: string) => {
  sessionStorage.setItem(emailSessionKey, validateEmail(email) ? email : '');
};
