import { useNavigate } from '@solidjs/router';
import { children, createEffect, createMemo, createSignal, For, onCleanup, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import ArchiveIcon from '~/assets/images/chat/archive.svg?component-solid';
import IconBacktrack from '~/assets/images/chat/backtrack.svg?component-solid';
import ImgCancelArchive from '~/assets/images/chat/cancelArchive.png';
import IconEdit from '~/assets/images/chat/chatEdit.svg?component-solid';
import IconChatsList from '~/assets/images/chat/chatsList.svg?component-solid';
import IconSave from '~/assets/images/chat/save.svg';
import IconTempPfp from '~/assets/images/chat/tempPfp.svg';
import IconDetail from '~/assets/images/common/detail.svg?component-solid';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import IconCall from '~/assets/images/contact/call.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LanguageSelect } from '~/components/languages';
import { IconCircleEllipsis, Popover, DropdownActions, toast, confirm, IconSearch } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChat, useChatsList, useChatsSettings } from '~/contexts/local';
import { ArchiveChatModal } from '~/pages/chats/delete-chat/ArchiveChatModal';
import { cn } from '~/utils/classnames';
import { printDocument } from '~/utils/print';
import { ParticipantDetails } from './ChatHeaderParticipantDetails';
import { ChatSearchModal } from './ChatSearchModal';
import type { JSX } from 'solid-js';
import type { DropdownAction } from '~/components/ui';

interface ChatHeaderProps {
  chats: MagicDoor.Api.HydratedMagicChatDto | undefined;
  chatId?: string | null;
  printContainer?: JSX.Element;
  printHeader?: JSX.Element;
  printWrapClass?: string;
  extraStyle?: string;
  source?: 'chat' | 'archive';
  isSidebarVisible?: boolean;

  onToggleSidebarVisible?: () => void;
}

const ChatHeader = (props: ChatHeaderProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { isMobileView } = useChatsSettings();
  const { editChat, archiveChat } = useChat();
  const { fetchChats, unarchiveChat } = useChatsList();

  const [showArchiveModal, setShowArchiveModal] = createSignal<boolean>(false);
  const [modalLoading, setModalLoading] = createSignal<boolean>(false);

  let inputRef: HTMLInputElement | undefined;
  const [isEditingTitle, setIsEditingTitle] = createSignal<boolean>(false);
  const [newTitle, setNewTitle] = createSignal<string | undefined>('');
  const [oldTitle, setOldTitle] = createSignal<string | undefined>('');

  const [showSearchChatModal, setShowSearchChatModal] = createSignal<boolean>(false);

  const header = children(() => props.printHeader);
  const container = children(() => props.printContainer);

  const initInputValue = () => {
    setOldTitle(props.chats?.subject || '');
  };

  createEffect(initInputValue);

  const currentChatParticipants = () => {
    return (
      props.chats?.participants.filter(
        (participant) => participant.participantType === 'tenant' || participant.participantType === 'vendor'
      ) || []
    );
  };

  const matchingParticipant = createMemo(() => {
    return currentChatParticipants().find((p) => p.participantType === (props.chats?.type as any));
  });

  const navigateToDetail = () => {
    const chatType = props.chats?.type;

    if (!chatType) {
      console.error('Chat type is undefined or not valid.');
      return;
    }

    let path = '';

    if (chatType === 'lease' && props.chats?.lease) {
      path = `/leasing/leases/${props.chats?.typeKey}`;
    } else if (chatType === 'maintenanceRequest') {
      path = `/maintenance/maintenance-request/${props.chats?.typeKey}`;
    } else if (['workOrderGroup', 'workOrder'].includes(chatType)) {
      path = `/maintenance/work-orders/${props.chats?.typeKey}`;
    } else {
      if (matchingParticipant()) {
        switch (matchingParticipant()?.participantType) {
          case 'tenant':
            path = `/users/tenants/${props.chats?.typeKey}`;
            break;
          case 'vendor':
            path = `/maintenance/vendors/${props.chats?.typeKey}`;
            break;

          default:
            console.error(`Unsupported participant type: ${matchingParticipant()?.participantType}`);
            return;
        }
      }
    }

    if (path) {
      navigate(path);
    } else {
      console.error('No valid navigation path found.');
      toast.error(t('The details page for this participant does not exist'));
    }
  };

  const archiveCurrentChat = async () => {
    if (!props.chatId) return;
    setModalLoading(true);

    try {
      await archiveChat(props.chatId);
      toast.success(t('Chat has been archived successfully'));

      setShowArchiveModal(false);
      fetchChats();
      navigate('/communications/chats', {
        replace: true,
      });
    } finally {
      setModalLoading(false);
    }
  };

  const viewParticipantDetails = (participantId: string) => {
    const participant = currentChatParticipants().find((p) => p.id === participantId);

    if (!participant) {
      console.error(`Participant with ID ${participantId} not found.`);
      return;
    }

    let path = '';

    switch (participant.participantType) {
      case 'tenant':
        path = `/users/tenants/${participant.accountId}`;
        break;
      case 'vendor':
        path = `/maintenance/vendors/${participant.accountId}`;
        break;
      default:
        console.error(`Unsupported participant type: ${participant.participantType}`);
        return;
    }

    return path;
  };

  const handleEditActive = () => {
    setIsEditingTitle(true);
    setNewTitle(oldTitle());
    setTimeout(() => inputRef?.focus(), 0);
  };

  const handleEditChatTitle = async (newTitle: string, eventType: string) => {
    if (!props.chatId) {
      return;
    }

    if (newTitle.trim() === oldTitle()?.trim()) {
      setIsEditingTitle(false);
      return;
    }

    if (!newTitle.trim()) {
      if (eventType === 'blur') {
        setNewTitle(oldTitle);
        setIsEditingTitle(false);
      }

      if (eventType === 'click') {
        toast.error(t('Operation failed, title is empty'));
      }

      return;
    }

    if (newTitle.length > 250) {
      toast.error(t('Operation failed, title cannot be longer than 250 characters'));
      return;
    }

    await editChat(props.chatId, { subject: newTitle });
    setIsEditingTitle(false);
    setOldTitle(newTitle);
  };

  const handleLanguageChange = async (selectedValue: string) => {
    if (props.chatId && selectedValue) {
      await editChat(props.chatId, {
        participantLanguage: selectedValue as MagicDoor.Api.UpdateChatDto['participantLanguage'],
        subject: props.chats?.subject || '',
      });
    }
  };

  const onChatParticipantClick = (participant: MagicDoor.Api.HydratedChatParticipantDto) => {
    if (!isMobileView()) {
      return;
    }

    confirm({
      title: '',
      class: 'px-0 w-96 !py-0 max-w-[90vw]',
      titleClass: 'px-5',
      footerClass: 'hidden',
      content: (props) => (
        <ParticipantDetails
          onCancel={props.onCancel}
          isMobileView={isMobileView()}
          participant={participant}
          viewParticipantDetails={viewParticipantDetails}
        />
      ),
    });
  };

  const renderParticipantImages = () => {
    const participants = currentChatParticipants();
    const [windowWidth, setWindowWidth] = createSignal(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    onCleanup(() => window.removeEventListener('resize', handleResize));

    const visibleCount = () => (windowWidth() < 1382 ? 1 : currentChatParticipants().length > 4 ? 4 : currentChatParticipants().length);
    const overflowCount = () => currentChatParticipants().length + 1 - visibleCount();

    return (
      <>
        <Show when={participants.length === 1}>
          <Popover>
            <Popover.Trigger>
              <Avatar name={participants[0].name ?? ''} size="large" />
            </Popover.Trigger>
            <Popover.Content class="relative">
              <div class="absolute top-0 z-10 rounded-md bg-white text-xs shadow-lg ring-1 ring-partingline">
                <ParticipantDetails
                  isMobileView={isMobileView()}
                  participant={participants[0]}
                  viewParticipantDetails={viewParticipantDetails}
                />
              </div>
            </Popover.Content>
          </Popover>
        </Show>

        <Show when={participants.length > 1}>
          <DropdownActions
            class="text-auxiliary-text"
            align="start"
            isShowExpandArrow={!isMobileView()}
            overlayClass={cn({ 'border-0 p-0 m-0': isMobileView() })}
            actionClass={cn({ 'w-screen rounded-none px-5 py-3 ring-0': isMobileView() })}
            // @ts-expect-error: supresssing click event workaround
            actions={currentChatParticipants().map((participant) => ({
              label: participant.name,
              onClick: () => onChatParticipantClick(participant),
              jsxIcon: <Avatar name={participant.name ?? ''} size="large" />,
              childrenPosition: 'right',
              childrenStyle: 'p-0',
              children: (
                <ParticipantDetails
                  isMobileView={isMobileView()}
                  participant={participant}
                  viewParticipantDetails={viewParticipantDetails}
                />
              ),
            }))}>
            <div
              class={cn('relative flex -space-x-3 rounded-lg p-1 hover-allowed:hover:bg-input-border', {
                'min-w-fit items-center space-x-0 p-1': isMobileView(),
              })}>
              <For each={currentChatParticipants()?.slice(0, visibleCount())}>
                {(participant, index) => (
                  <div class="relative shrink-0">
                    <Avatar name={participant.name ?? ''} solid class={cn('size-10 p-0', { 'size-8': isMobileView() })} />
                    <Show when={index() === visibleCount() - 1 && currentChatParticipants().length > visibleCount()}>
                      <div class="absolute inset-0 flex items-center justify-center rounded-full bg-black/50 text-white">
                        +{overflowCount()}
                      </div>
                    </Show>
                  </div>
                )}
              </For>
              <Show when={isMobileView()}>
                <div class="!ml-1.5 inline-block max-w-32 truncate lg:hidden xl:w-fit">{props.chats?.subject}</div>
              </Show>
            </div>
          </DropdownActions>
        </Show>
      </>
    );
  };

  createEffect(() => {
    setNewTitle(props.chats?.subject);
  });

  const onPrint = () => {
    const headerElement = header();
    const containerElement = container();

    if (headerElement && containerElement) {
      printDocument(containerElement as HTMLDivElement, props.printWrapClass, headerElement as HTMLDivElement, props.extraStyle);
    }
  };

  const cancelArchive = () => {
    confirm({
      title: t('Unarchive'),
      class: 'px-0 w-96',
      titleClass: 'px-5',
      footerClass: 'px-5',
      doneText: t('Confirm'),
      content: (
        <div>
          <img src={ImgCancelArchive} alt="ImgCancelArchive" />
          <div class="mt-4 px-5">
            {t('After clicking confirm, chat records will be restored. After successful restoration, it will jump to the chat window.')}
          </div>
        </div>
      ),
      async onResolve(confirmed) {
        if (!confirmed || !props.chats?.id) return;
        await unarchiveChat(props.chats.id);
        navigate(`/communications/chats/${props.chats.id}`, { replace: true });
      },
    });
  };

  const chatActions = createMemo<DropdownAction[]>(() => {
    const baseActions = [
      {
        label: t('Details'),
        icon: IconDetail,
        onClick: navigateToDetail,
      },
      isMobileView()
        ? {
            label: t('Rename'),
            icon: IconEdit,
            onClick: () => {
              confirm({
                title: t('Rename Chat'),
                titleClass: 'border-b border-[#EDF2F7] px-5 pb-3',
                footerClass: 'mt-6 px-5 [&>button]:flex-1',
                class: 'px-0 pt-4 pb-6',
                content: (
                  <div class="px-5 pt-4">
                    <LabeledTextInput value={newTitle()} onInput={(value) => setNewTitle(value)} />
                  </div>
                ),
                doneText: t('Save'),
                onResolve: async (isConfirmed) => {
                  if (!props.chatId) {
                    return;
                  }

                  if (!isConfirmed) {
                    setNewTitle(oldTitle());
                    return;
                  }

                  if (!newTitle()?.trim()?.length) {
                    setNewTitle(oldTitle());
                    return;
                  }

                  if (newTitle()?.trim() === oldTitle()?.trim()) {
                    setNewTitle(oldTitle());
                    return;
                  }

                  if ((newTitle() as string).trim().length > 250) {
                    setNewTitle(oldTitle());
                    toast.error(t('Operation failed, title cannot be longer than 250 characters'));
                    return;
                  }

                  await editChat(props.chatId, { subject: newTitle()?.trim() as string });
                  setOldTitle(newTitle());
                },
              });
            },
          }
        : undefined,
      {
        label: t('Print'),
        icon: PrintIcon,
        onClick: onPrint,
      },
    ].filter(Boolean) as DropdownAction[];
    const archiveActions = [
      {
        label: t('Archive'),
        icon: ArchiveIcon,
        onClick: () => setShowArchiveModal(true),
      },
    ];
    const unarchiveActions = [
      {
        label: t('Unarchive'),
        icon: IconBacktrack,
        onClick: () => cancelArchive(),
      },
    ];

    return props.source === 'archive' ? baseActions.concat(unarchiveActions) : baseActions.concat(archiveActions);
  });

  return (
    <div
      class={cn('flex w-full items-center justify-between gap-2 border-b border-partingline bg-white p-4', {
        'p-0': isMobileView(),
      })}>
      <Show when={isMobileView()}>
        <button
          class={cn(
            'relative inline-flex shrink-0 cursor-pointer flex-col items-center justify-center gap-1 px-2 py-1.5 text-text-level02 lg:hidden',
            { 'bg-[rgba(161,_38,_236,_0.08)] text-essential-colour': props.isSidebarVisible }
          )}
          onClick={(e) => {
            e.stopPropagation();
            props.onToggleSidebarVisible?.();
          }}>
          <IconChatsList />
          <span class="text-xs">{t('Chats list')}</span>
          <div class="absolute left-full top-1/2 -ml-px h-7 w-px -translate-y-1/2 bg-input-border" />
        </button>
      </Show>
      <Show when={props.chats && props.chatId}>
        <>
          <div class={cn('flex items-center md:overflow-hidden', { 'grow pl-0': isMobileView() })}>
            <Show
              when={currentChatParticipants().length > 0}
              fallback={<img src={IconTempPfp} alt="Temporary Profile Picture" class="size-10 rounded-full" />}>
              {renderParticipantImages()}
            </Show>
            <div class="ml-2flex flex-col items-start justify-center text-base font-semibold md:overflow-hidden md:text-xl">
              <div class="flex gap-2">
                <Show
                  when={!isEditingTitle()}
                  fallback={
                    <div class="relative flex items-center justify-center gap-2">
                      <input
                        type="text"
                        ref={inputRef}
                        value={newTitle()}
                        onInput={(e) => setNewTitle(e.currentTarget.value)}
                        onBlur={(e) => handleEditChatTitle(newTitle() || '', e.type)}
                        class="w-40 truncate rounded-full border border-essential-colour bg-input px-2 py-0.5 pr-20 sm:w-40 md:w-fit"
                      />
                      <button
                        class="absolute right-1 top-1/2 -translate-y-1/2 rounded-full bg-essential-colour px-2 py-1"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => handleEditChatTitle(newTitle() || '', e.type)}>
                        <div class="flex items-center justify-center gap-1 text-xs font-normal text-white">
                          <img src={IconSave} alt="Save Chat Title" class="size-5" />
                          <span class="hidden md:flex">{t('Save')}</span>
                        </div>
                      </button>
                    </div>
                  }>
                  <div class={cn('block max-w-32 truncate sm:w-40 md:max-w-fit xl:w-fit', { hidden: isMobileView() })}>
                    {props.chats?.subject}
                  </div>
                  <Show when={props.source !== 'archive'}>
                    <button onClick={handleEditActive} class={cn('inline-block', { hidden: isMobileView() })}>
                      <Dynamic component={IconEdit} class="size-5" />
                    </button>
                  </Show>
                </Show>
              </div>

              <div>
                <For each={props.chats?.participants.filter((participant) => participant.participantType === 'unknown')}>
                  {(participant) => (
                    <div class="mt-0.5 flex gap-2 text-xs font-normal text-text-level03 md:text-sm">
                      <Show when={participant.phoneNumber}>
                        <div class="flex gap-1 rounded-lg bg-slate-100 px-2 py-0.5">
                          <div class="hide-on-mobile flex">
                            <IconCall /> :
                          </div>
                          {participant.phoneNumber}
                        </div>
                      </Show>
                    </div>
                  )}
                </For>
              </div>
            </div>
          </div>
          <div class="flex shrink-0 items-center justify-center gap-2 pr-2">
            <ArchiveChatModal
              visible={showArchiveModal()}
              onCancel={() => setShowArchiveModal(false)}
              onConfirm={archiveCurrentChat}
              chatSubject={props.chats?.subject}
              modalLoading={modalLoading()}
            />
            <button onClick={() => setShowSearchChatModal(true)}>
              <IconSearch class="size-9 rounded-md border p-2 hover:border-essential-colour hover:bg-[#A126EC0A] hover:text-essential-colour" />
            </button>
            <Show
              when={isMobileView()}
              fallback={
                <DropdownActions
                  class="size-9 text-auxiliary-text"
                  actions={chatActions()}
                  header={
                    <div class="flex flex-col items-center justify-center gap-2 text-title-gray">
                      <div class="hide-on-mobile hidden xl:flex">{t('Your message is being sent out as')}</div>
                      <Show when={props.chatId && props.source !== 'archive'}>
                        <div class="w-full">
                          <LanguageSelect
                            value={props.chats?.participantLanguage}
                            onChange={(e) => handleLanguageChange(e)}
                            placeholder="Select language"
                          />
                        </div>
                      </Show>
                      <div class="mb-2 mt-1 h-px w-full bg-input-border" />
                    </div>
                  }
                />
              }>
              <Popover>
                {({ setOpen }) => (
                  <>
                    <Popover.Trigger class="rounded p-1 [&.expanded]:bg-light-gray [&.expanded_svg]:text-essential-colour [&_svg]:size-5 [&_svg]:text-auxiliary-text">
                      <IconCircleEllipsis class="size-5" />
                    </Popover.Trigger>
                    <Popover.Content class="!animate-none">
                      <div class="fixed left-0 top-[120px] h-[calc(100vh_-_20px)] w-screen bg-black/70" onClick={() => setOpen(false)}>
                        <div
                          class="absolute right-2.5 top-2 w-[278px] rounded-lg bg-white px-4 py-3"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}>
                          <Show when={props.chatId && props.source !== 'archive'}>
                            <>
                              <p class="mb-2 text-center text-sm text-title-gray">{t('your message is being sent out as')}</p>
                              <LanguageSelect
                                class="w-full"
                                value={props.chats?.participantLanguage}
                                onChange={(e) => handleLanguageChange(e)}
                                placeholder="Select language"
                              />
                            </>
                          </Show>
                          <div class="my-3 h-px w-full bg-input-border" />
                          <ul class="flex flex-col gap-1">
                            <For each={chatActions()}>
                              {(action) => (
                                <li class="w-full">
                                  <a
                                    class="flex w-full cursor-pointer items-center gap-1.5 rounded-lg border border-input-border px-3 py-2.5"
                                    href={action.href ?? 'javascript:;'}
                                    onClick={() => {
                                      setOpen(false);
                                      action.onClick?.();
                                    }}>
                                    <Dynamic component={action.icon} class="size-4 text-text-level03" />
                                    <span class="text-sm text-text-level01">{action.label}</span>
                                  </a>
                                </li>
                              )}
                            </For>
                          </ul>
                        </div>
                      </div>
                    </Popover.Content>
                  </>
                )}
              </Popover>
            </Show>
          </div>
          <Show when={showSearchChatModal()}>
            <ChatSearchModal
              chatSearchMode="message"
              chat={props.chats!}
              visible={showSearchChatModal()}
              onClose={() => {
                setShowSearchChatModal(false);
              }}
            />
          </Show>
        </>
      </Show>
    </div>
  );
};

export default ChatHeader;
