import { Route, Routes, useLocation } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Communication } from '~/components/rental-application/rental-application-details/communication';
import { RentalApplicationInformation } from '~/components/rental-application/rental-application-details/infomation';
import { getTenantFullName } from '~/components/tenants/utils';
import { TabLinks } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { emptyPlaceholder } from '~/utils/constant';

interface RentalApplicationDetailsProps {
  noBreadcrumb?: boolean;
  noDefaultStyle?: boolean;
}

export const RentalApplicationDetails = (props: RentalApplicationDetailsProps) => {
  const { t } = useLocalization();
  const location = useLocation();
  const goBack = useGoBack();

  const { applicationStore } = useRentalApplication();

  const from = createMemo(() => location.pathname.slice(0, location.pathname.lastIndexOf('/')));

  const breadcrumbItems = createMemo(() => [
    { label: from().includes('draft-rental-applications') ? t('Unfinished') : t('Submitted'), link: from() },
    { label: getTenantFullName(applicationStore.application) || emptyPlaceholder },
  ]);

  return (
    <div class="flex size-full min-h-contentWithoutHeader flex-col">
      <Show when={!props.noBreadcrumb}>
        <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />
      </Show>

      <div class={props.noDefaultStyle ? '' : 'm-3 md:mx-8 md:my-6'}>
        <TabLinks
          class="mb-4 overflow-visible rounded-lg border border-partingline bg-white"
          items={[
            { label: t('Renter report'), href: '' },
            { label: t('Communication'), href: 'communications' },
          ]}
          replace
        />

        <Routes>
          <Route path="/" component={RentalApplicationInformation} />
          <Route path="/communications" component={Communication} />
        </Routes>
      </div>
    </div>
  );
};
