import { createEffect, createMemo } from 'solid-js';
import usePresenter from '~/framework/hooks/usePresenter';
import { CurrentAnnotationPresenter } from '~/pdfsigner/presenters/CurrentAnnotationPresenter';
import { HandlerWrapper } from './HandlerWrapper';
import { MoveHandler } from './MoveHandler';
import { ResizeHandler } from './ResizeHandler';
import type { JSX } from 'solid-js';
import type { PresentableAnnotation } from '~/pdfsigner/ui/types/presentableAnnotation';

export interface RectangularShape {
  width: number;
  height: number;
}

export interface AnnotationProps {
  parentRect: RectangularShape | undefined;
  scaleFactor: number;
  presentableAnnotation: PresentableAnnotation;
  onStartDragging?: (id: string, event: MouseEvent) => void;
  onInput?: (id: string, event: InputEvent, value: string) => void;
  onStartEditting?: (id: string, event: MouseEvent) => void;
  onStartResizing?: (id: string, event: MouseEvent) => void;
}

export const Annotation = (props: AnnotationProps): JSX.Element => {
  let textArea: HTMLTextAreaElement;
  const { model: currentAnnotation } = usePresenter(CurrentAnnotationPresenter);

  const isCurrentAnnotation = createMemo<boolean>(() => currentAnnotation()?.selectedAnnotation?.id === props.presentableAnnotation.id);

  createEffect(() => {
    if (textArea && props.presentableAnnotation.isSelected) {
      setTimeout(() => {
        textArea?.focus();
      }, 0);
    }
  });

  return (
    <div
      class="absolute z-10 flex border"
      classList={{
        'border-dashed': isCurrentAnnotation(),
      }}
      style={{
        left: `${props.presentableAnnotation.x}px`,
        top: `${props.presentableAnnotation.y}px`,
        width: `${props.presentableAnnotation.width}px`,
        height: `${props.presentableAnnotation.height}px`,
        'background-color': `${props.presentableAnnotation.backgroundColor}`,
        'border-color': `${props.presentableAnnotation.borderColor}`,
      }}>
      <textarea
        class="m-0 grow resize-none overflow-hidden bg-transparent px-[5pt] py-[2pt] text-left align-top outline-none"
        ref={(el) => (textArea = el)}
        value={props.presentableAnnotation.text}
        readOnly={props.presentableAnnotation.isReadOnly}
        onMouseDown={(event: MouseEvent) => props.onStartEditting?.(props.presentableAnnotation.id, event)}
        onInput={(event: InputEvent) => props.onInput?.(props.presentableAnnotation.id, event, textArea?.value || '')}
        style={{
          'font-family': `${props.presentableAnnotation.fontType}`,
          'font-size': `${props.presentableAnnotation.fontSize * props.scaleFactor}pt`,
          'line-height': '1.2',
        }}
      />
      <HandlerWrapper isVisible={isCurrentAnnotation()} placement="br" class="z-10">
        <ResizeHandler onMouseDown={(event) => props.onStartResizing?.(props.presentableAnnotation.id, event)} />
      </HandlerWrapper>
      <HandlerWrapper isVisible={isCurrentAnnotation()} placement="tl">
        <MoveHandler onMouseDown={(event) => props.onStartDragging?.(props.presentableAnnotation.id, event)} />
      </HandlerWrapper>
    </div>
  );
};
