import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, createMemo, createSignal, For, Show } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import leasesWithBalanceDueBg from '~/assets/images/dashboard/leasesWithBalanceDueBg.svg';
import NoDataImg from '~/assets/images/empty/noData.svg';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { LeasePeriod, LeaseTitle } from '~/components/leases';
import { LeaseName } from '~/components/leases/LeaseName';
import { Select, Table } from '~/components/ui';
import { IconChevronDown } from '~/components/ui/Icons';
import { UnitImage } from '~/components/units';
import { useLocalization, usePortfolios } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import Card from '~/pages/dashboard/components/Card';
import { QuickLeaseNotifyBalanceDue } from '~/pages/leases/quick-lease-notify-balance-due';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';
type LeasesWithBalanceDueProps = {
  class?: string;
};

const LeasesWithBalanceDue: Component<LeasesWithBalanceDueProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const [portfolioId, setPortfolioId] = createSignal<string>('');
  const { portfolios } = usePortfolios();
  const { leases, getLeases, loading } = useLeasesList();

  const portfolioOptions = createMemo(() => [
    { value: '', label: t('All') },
    ...(portfolios()?.map((cur) => ({ value: cur.id, label: cur.name })) ?? []),
  ]);

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseDto>>(() => [
    {
      title: t('Lease'),
      render: (item) => <LeaseTitle lease={item} showTenants />,
    },
    {
      title: t('Balance'),
      class: 'text-nowrap text-right',
      headerClass: 'text-right',
      render: (item) => (
        <div>
          <CnMoney money={item.balance} />
          <div class="flex justify-end text-xs text-text-level03">
            <span class="hidden md:flex">{t('Last payment date')}:&nbsp</span>
            <span class="flex md:hidden">{t('Last paid')}:&nbsp</span>
            <span class="font-medium text-danger"> {dayjs(item.lastPaymentDate).format('MMM DD')}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('Operation'),
      headerClass: 'text-left',
      class: 'w-16',
      render: (item) => (
        <div class="flex w-24 justify-end gap-3">
          <QuickLeaseNotifyBalanceDue targets={[item.id]} />
        </div>
      ),
    },
  ]);

  createEffect(() => {
    getLeases({
      portfolioId: portfolioId(),
      ended: false,
      withBalance: true,
    });
  });

  return (
    <Card
      class={props.class}
      title={t('Leases with balance due')}
      description={t('{count} in total', { count: loading() ? '-' : leases().length.toString() })}
      bgClass="h-auto items-start bg-[#FEF9FF] md:h-16  md:items-center"
      bgImgSrc={leasesWithBalanceDueBg}
      actions={
        <div class="flex flex-col-reverse items-center gap-3 md:flex-row">
          <Select
            prefix={t('Portfolio')}
            filterable
            options={portfolioOptions()}
            value={portfolioId()}
            onChange={setPortfolioId}
            class="w-32 md:w-44"
          />
          <LinkButton href="/leasing/leases" class="px-3 py-1 text-xs" rounded="full" variant="outlined">
            {t('View all')}
            <IconViewArrow class="size-4 text-essential-colour" />
          </LinkButton>
        </div>
      }>
      <div class="flex flex-col divide-y px-3 md:hidden">
        <For each={leases()}>
          {(item) => (
            <div class="py-3" onClick={() => navigate(`/leasing/leases/${item.id}`)}>
              <div class="mb-3 flex items-center justify-between">
                <div class="flex">
                  <UnitImage class="mr-2 hidden size-11 rounded-lg md:flex" unitId={item.unit.id} imageId={item.unit.imageId} />
                  <div>
                    <LeaseName lease={item} />
                    <LeasePeriod class="text-xs" lease={item} />
                  </div>
                </div>

                <div class="w-5">
                  <IconChevronDown class="size-4 -rotate-90" />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div>
                  <div class="flex gap-1">
                    <span class="text-xs">{t('Balance')}:</span>
                    <CnMoney class="justify-start" money={item.balance} />
                  </div>
                  <div class="mt-1 flex gap-1 text-xs text-text-level03">
                    <span>(</span>
                    <span class="">{t('Last payment date')}:</span>
                    <span class="font-medium text-danger"> {dayjs(item.lastPaymentDate).format('MMM DD')}</span>
                    <span>)</span>
                  </div>
                </div>

                <div class="flex w-24 justify-end gap-3">
                  <QuickLeaseNotifyBalanceDue targets={[item.id]} />
                </div>
              </div>
            </div>
          )}
        </For>
        <Show when={leases().length === 0}>
          <div class="p-10 text-center text-text-level03">
            <img src={NoDataImg} class="mx-auto mb-5 w-44" alt={t('No results found')} />
            {t('No results found')}
          </div>
        </Show>
      </div>

      <Table
        class="hidden md:table"
        data={leases()}
        columns={columns()}
        rowLink={(item) => `/leasing/leases/${item.id}`}
        loading={loading()}
      />
    </Card>
  );
};

export default LeasesWithBalanceDue;
