import { Show, createEffect, createSignal } from 'solid-js';
import IconInviteUrl from '~/assets/images/settings/portal/portalInviteUrl.svg';
import IconPortalInvite from '~/assets/images/settings/portal/portalInviteUrlBanner.png';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import Tooltip from '~/components/common/Tooltip';
import { IconLoader, toast } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import { createClipboard } from '~/utils/clipboard';
import { getDomainValidator, getSubUrlValidator } from '~/utils/validations';

export const PortalAppUrlSettings = () => {
  const { t } = useLocalization();
  const [text, setText] = createSignal<string>('');
  const [customUrl, setCustomUrl] = createSignal<string>('');
  const [subUrl, setSubUrl] = createSignal<string>('');
  const { companies, validateSubUrl, updateSubUrl, validateCustomUrl, updateCustomUrl } = useCompanies();
  const SUFFIX = '.with.magicdoor.com';

  const handleCancel = () => {
    setText(companies()?.subUrl || '');
    setSubUrl(companies()?.subUrl + SUFFIX);
    setCustomUrl(companies()?.customDomain || '');
  };

  const handleSubUrl = async () => {
    const url = text().trim();

    if (url === companies()?.subUrl) return;

    if (typeof url !== 'string' || url.length < 3) {
      return toast.error(t('Invalid subdomain, must be at least 3 characters long'));
    }
    if (!getSubUrlValidator(url)) {
      return toast.error(
        t('Subdomain can only contain letters, numbers, hyphens and underscores, and must start and end with alphanumeric characters')
      );
    }

    const result = await validateSubUrl({ url });
    if (!result.isValid) {
      return toast.error(t('Subdomain already taken'));
    }

    await updateSubUrl({ url });
  };

  const handleCustomUrl = async () => {
    const domain = customUrl().trim();
    if (domain === companies()?.customDomain) return;
    if (domain.length > 0) {
      if (!getDomainValidator(domain)) {
        return toast.error(t('Domain must be a valid domain name (e.g. example.com or sub.example.com)'));
      }
      const result = await validateCustomUrl({ domain });
      if (!result.isValid) {
        return toast.error(t('Subdomain already taken'));
      }
    }
    await updateCustomUrl({ domain });
  };

  const handleSave = async () => {
    handleCustomUrl();
    handleSubUrl();
  };

  const { copy, tooltipMessage } = createClipboard({
    successMessage: t('Link copied to clipboard'),
    errorMessage: t('Failed to copy link'),
    tooltipMessages: {
      default: t('Click to copy link'),
      copied: t('Link copied'),
    },
  });

  const handleChange = (e: { target: HTMLInputElement }) => {
    if (e.target.value.trim()) {
      setText(e.target.value);
      setSubUrl(e.target.value + SUFFIX);
    } else {
      setText(companies()?.subUrl || '');
    }
  };

  createEffect(handleCancel);

  return (
    <ThreeSectionBox
      class="border-0"
      topTitle={<SettingTitle title={t('Modify your url')} icon={IconInviteUrl} />}
      middle={
        <div class="p-3 md:p-4">
          <div class="flex flex-col gap-4 rounded-md bg-light-pink text-left">
            <Show when={!companies.loading} fallback={<IconLoader class="mx-auto my-10 animate-spin" />}>
              <div class="size-full px-4 py-6">
                <div class="relative mb-3 border-b border-[#D9D9D9] pb-3">
                  <img
                    src={IconPortalInvite}
                    alt="img"
                    class="relative mx-auto mb-4 bg-light-pink pl-10 md:absolute md:-top-4 md:right-0 md:mb-0"
                  />
                  <h1 class="mb-1 text-sm font-bold capitalize text-text-level02">{t('Default magicDoor url')}</h1>
                  <div class="font-regular mb-2 text-sm text-text-level02">
                    {t('This is the URL assigned to your account. This field is required.')}
                    <span class="text-[#FF2259]">*</span>
                  </div>
                  <div class="flex flex-col gap-1 md:flex-row md:items-center">
                    <div class="relative flex items-center">
                      <input
                        class="bg-parting-line w-full rounded-md border px-3 py-2 text-sm placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-essential-colour md:w-fit"
                        placeholder={t('Brand subdomain')}
                        value={text()}
                        onChange={handleChange}
                      />
                      <Show when={validateSubUrl.pending || updateSubUrl.pending}>
                        <IconLoader class="absolute right-3 size-4 animate-spin text-text-level02" />
                      </Show>
                    </div>
                    <samp class="select-none text-text-level02">.with.magicdoor.com</samp>
                  </div>
                </div>
                <div>
                  <h1 class="mb-1 text-sm font-bold capitalize text-text-level02">{t('Custom domain (optional)')}</h1>
                  <h2 class="text-sm text-text-level02">
                    {t('If you want to use your own custom domain (e.g., customdomain.com), you must')}
                  </h2>
                  <ul class="mb-2 list-disc pl-5 text-sm font-normal leading-5 text-text-level02">
                    <li>{t('Ensure the Default MagicDoor URL is filled in.')}</li>
                    <li>
                      {t("Set up a CNAME record in your domain's DNS settings, pointing to")}
                      <Tooltip message={tooltipMessage()} class="inline-block cursor-pointer sm:w-auto" align="top">
                        <a class="cursor-pointer text-sm text-primary" onClick={() => copy(subUrl())}>
                          {subUrl()}
                        </a>
                      </Tooltip>
                    </li>
                  </ul>
                  <div class="relative flex w-1/2 items-center">
                    <input
                      class="bg-parting-line w-full rounded-md border px-3 py-2 text-sm placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-essential-colour"
                      placeholder={t('Please enter')}
                      value={customUrl()}
                      onChange={(e) => setCustomUrl(e.target.value.toLocaleLowerCase())}
                    />
                    <Show when={validateCustomUrl.pending || updateCustomUrl.pending}>
                      <IconLoader class="absolute right-3 size-4 animate-spin text-text-level02" />
                    </Show>
                  </div>
                  <div class="mt-2 text-xs text-warning">
                    {t(
                      'Note: Once the DNS configuration is complete, it may take up to 1 hour for HTTPS to become active on your custom domain.'
                    )}
                  </div>
                </div>
              </div>
            </Show>
          </div>
          <SaveBtn
            class="border-b-0"
            dirty={!companies.loading && (text() !== companies()?.subUrl || customUrl() !== (companies()?.customDomain ?? ''))}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </div>
      }
    />
  );
};
