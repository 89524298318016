import IconGID from '~/assets/images/rental-application/gid-trigger.svg?component-solid';
import IconPayStub from '~/assets/images/rental-application/paystub-trigger.svg?component-solid';
import { FormItem } from '~/components/common/BetterForm';
import { Uploader, parseFilesFromAPIToUploaderDefaultFileList } from '~/components/common/Upload';
import { useLocalization } from '~/contexts/global';
import { FileType } from '~/swagger/Api';
import { proofFileAccept } from '~/utils/file';
import type { Component } from 'solid-js';
import type { FileDescriptor, HydrateFile } from '~/components/common/Upload';
import type { UpdateRentalApplicationFileDto } from '~/swagger/Api';
export const RentalApplicationDocuments: Component<{
  onInput?: (files: UpdateRentalApplicationFileDto[]) => void;
  value?: (FileDescriptor & { type: FileType })[] | null;
  beforeUpload?: (file: File) => void;
  afterUpload?: (uploadResult: HydrateFile) => void;
  id?: string;
}> = (props) => {
  const { t } = useLocalization();

  function getInitialFile(type: FileType) {
    return parseFilesFromAPIToUploaderDefaultFileList(props.value?.filter((f) => f.type === type));
  }

  async function onAfterUpload(file: HydrateFile, type: FileType) {
    if (file.fileId) {
      let _value = (props.value ?? []).slice();
      const existFile = _value.findIndex((f) => f.type === type) || -1;
      const newFile = {
        fileId: file.fileId || '',
        type,
        fileName: file.fileName || '',
        fileUrl: file.fileUrl || '',
        thumbUrl: file.thumbUrl || '',
      };

      if (existFile !== -1) {
        _value = _value.toSpliced(existFile, 1, newFile);
      } else {
        _value.push(newFile);
      }

      props.onInput?.(_value);
    }
    props.afterUpload?.(file);
  }

  function onClean(type: FileType) {
    props.onInput?.(props.value?.filter((f) => f.type !== type) ?? []);
  }

  return (
    <div class="grid grid-cols-2 gap-5">
      <FormItem
        name={`${props.id}-${FileType.Identification}`}
        label={t('Government ID')}
        component={Uploader.LabeledPreUploader}
        icon={IconGID}
        triggerLabel={t(`Drag & drop or click to add government ID`)}
        type={FileType.Identification}
        defaultFileList={getInitialFile(FileType.Identification)}
        accept={proofFileAccept}
        groupClass="h-full flex-1"
        onAfterUpload={(file) => onAfterUpload(file, FileType.Identification)}
        onClean={() => onClean(FileType.Identification)}
        onBeforeUpload={(file) => {
          props.beforeUpload?.(file);
        }}
        multiple={false}
      />
      <FormItem
        name={`${props.id}-${FileType.EmploymentVerification}`}
        label={t('Pay Stub')}
        component={Uploader.LabeledPreUploader}
        icon={IconPayStub}
        triggerLabel={t(`Drag & drop or click to add pay stub`)}
        type={FileType.EmploymentVerification}
        defaultFileList={getInitialFile(FileType.EmploymentVerification)}
        accept={proofFileAccept}
        groupClass="h-full flex-1"
        onAfterUpload={(file) => onAfterUpload(file, FileType.EmploymentVerification)}
        onClean={() => onClean(FileType.EmploymentVerification)}
        multiple={false}
        onBeforeUpload={(file) => {
          props.beforeUpload?.(file);
        }}
      />
    </div>
  );
};
