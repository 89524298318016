import { getMaskString } from '~/components/common/BankAccountMask';
import { Button } from '~/components/common/Buttons';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { MessageModal } from '~/components/modals';
import { useLocalization } from '~/contexts/global';
import { getAccountTypeElement } from '~/pages/bank-accounts/list-bank-accounts/list-components/BankAccountsListView';
import { useBankAccountActions } from '~/pages/bank-accounts/utils/bankAccountActions';
import { getOrdinalDay } from '~/utils/date';

interface AccountDetailsPanelProps {
  loading: boolean;
  bankAccount: MagicDoor.Api.HydratedBankAccountDto | undefined;
}

export const AccountDetailsPanel = (props: AccountDetailsPanelProps) => {
  const { t } = useLocalization();
  const { confirmDeleteBankAccount, isDeleting, handleDeleteBankAccount, showDeleteConfirmation, setShowDeleteConfirmation } =
    useBankAccountActions();

  return (
    <>
      <Panel
        title={t('Bank account details')}
        actions={
          <Button variant="white" onClick={() => props.bankAccount?.id && confirmDeleteBankAccount(props.bankAccount.id)}>
            {t('Deactivate')}
          </Button>
        }
        class="rounded-xl shadow-sm transition-shadow hover:shadow-md">
        <div class="grid grid-cols-1 gap-6 p-4 sm:grid-cols-2 md:p-6">
          <IconField name={t('Account number')} value={getMaskString(props.bankAccount?.accountNumber)} loading={props.loading} />
          <IconField name={t('Routing number')} value={getMaskString(props.bankAccount?.routingNumber)} loading={props.loading} />
          <IconField
            name={t('Account type')}
            value={props.bankAccount?.type && getAccountTypeElement(props.bankAccount.type)}
            loading={props.loading}
          />
          <IconField
            name={t('Withdrawal date')}
            value={
              props.bankAccount?.scheduleSweepDays?.length
                ? props.bankAccount?.scheduleSweepDays.map((day) => t('{day} of every month', { day: getOrdinalDay(day) })).join(', ')
                : t('Not scheduled')
            }
            loading={props.loading}
          />
          <IconField
            name={t('Description')}
            noTruncate={true}
            value={props.bankAccount?.description}
            loading={props.loading}
            class="col-span-full"
          />
        </div>
      </Panel>
      {showDeleteConfirmation() && (
        <MessageModal
          title={t('Deactivate bank account')}
          onDone={() => handleDeleteBankAccount()}
          onClose={() => setShowDeleteConfirmation(false)}
          doneText={t('Deactivate')}
          loading={isDeleting()}
          visible={showDeleteConfirmation()}>
          <div class="p-6">
            <p>{t('Are you sure you want to deactivate this bank account? This action cannot be undone.')}</p>
          </div>
        </MessageModal>
      )}
    </>
  );
};
