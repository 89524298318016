import { UnauthorizedError } from '@magicdoor/errors';
import { useLocation } from '@solidjs/router';
import { createEffect, Show } from 'solid-js';
import ErrorIcon from '~/assets/images/common/error-icon.png';
import { Button } from '~/components/common/Buttons';
import { useAuth, useLocalization } from '~/contexts/global';
import { sentryCapture } from '~/utils/sentry';

interface ErrorFallbackProps {
  error: Error | undefined;
  reset: () => void;
}

const ErrorFallback = (props: ErrorFallbackProps) => {
  const { t } = useLocalization();
  const { redirectToAuth } = useAuth();
  const location = useLocation();

  createEffect(() => {
    console.log('Got the error:', props.error);

    if (props.error instanceof UnauthorizedError) {
      redirectToAuth();
    }

    sentryCapture(props.error);
  });

  return (
    <div class="mx-auto flex min-h-dvh max-w-[600px] flex-col items-center justify-center gap-5 text-center text-xl font-semibold">
      <img src={ErrorIcon} alt="Error icon" class="w-[300px]" />
      <p class="text-text-level03">{t('Oops! Something went wrong. Please try again or reach out to our support team for assistance.')}</p>
      <div class="flex gap-3">
        <Show when={location.pathname !== '/'}>
          <Button href="/" variant="outlined" rounded="full">
            {t('Back to home')}
          </Button>
        </Show>
        <Button onClick={props.reset} variant="outlined" rounded="full">
          {t('Try again')}
        </Button>
        <a href="mailto:contact@magicdoor.com">
          <Button variant="outlined" rounded="full">
            {t('Contact support')}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default ErrorFallback;
