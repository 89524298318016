import { ApplicationPaymentStatus, ReportStatus } from '~/swagger/Api';

export interface PaymentStatusIndicatorProps {
  label: ApplicationPaymentStatus;
  color: string;
}
export interface StatusIndicatorProps {
  label: ReportStatus;
  color: string;
}

export interface RentalApplicationProps {
  application?: MagicDoor.Api.RentalApplicationDto;
}

export const paymentStatusIndicatorsArray: Array<PaymentStatusIndicatorProps> = [
  {
    label: ApplicationPaymentStatus.Paid,
    color: '#36CBAC',
  },
  {
    label: ApplicationPaymentStatus.Unpaid,
    color: '#BEC6DB',
  },
  {
    label: ApplicationPaymentStatus.Processing,
    color: '#00C0FF',
  },
  {
    label: ApplicationPaymentStatus.Failed,
    color: '#FF0000',
  },
  {
    label: ApplicationPaymentStatus.Refunded,
    color: '#999999',
  },
  {
    label: ApplicationPaymentStatus.Unrequired,
    color: '#999999',
  },
];

export const PAYMENT_STATUS_MAP = {
  [ApplicationPaymentStatus.Paid]: 'Paid',
  [ApplicationPaymentStatus.Unpaid]: 'Unpaid',
  [ApplicationPaymentStatus.Processing]: 'Processing',
  [ApplicationPaymentStatus.Failed]: 'Failed',
  [ApplicationPaymentStatus.Refunded]: 'Refunded',
  [ApplicationPaymentStatus.Unrequired]: 'Unrequired',
};

export const REPORT_STATUS_MAP = {
  [ReportStatus.NotRequested]: 'Not requested',
  [ReportStatus.Requested]: 'Requested',
  [ReportStatus.Available]: 'Available',
} as const;

export const statusIndicatorsArray: Array<StatusIndicatorProps> = [
  {
    label: ReportStatus.Available,
    color: '#36CBAC',
  },
  {
    label: ReportStatus.NotRequested,
    color: '#BEC6DB',
  },
  {
    label: ReportStatus.Requested,
    color: '#3C35F2',
  },
];
