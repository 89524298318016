import { useLocalization } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { currency, percentFormat } from '~/utils/number';

export interface FeeDetailsTableProps {
  fee: MagicDoor.Api.ManagementFeesPropertyDto;
  feeAmount?: number;
}

export const FeeDetailsTable = (props: FeeDetailsTableProps) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col gap-2">
      <div class="text-xs font-semibold text-text-level01">{t('Management fee setting')}</div>
      <div class="overflow-hidden rounded-lg border border-input-border">
        <table class="relative h-[calc(100%+1px)] w-full table-auto border-collapse text-sm">
          <thead class="bg-[rgba(243,_200,_255,_0.40)] text-xs text-essential-colour">
            <tr>
              <th class="border-b px-4 py-3 text-left font-normal">{t('Fee type')}</th>
              <th class="border-b px-4 py-3 text-right font-normal">{t('Percentage')}</th>
              <th class="border-b px-4 py-3 text-right font-normal">{t('Minimum fee')}</th>
              <th class="border-b px-4 py-3 text-right font-normal">{t('Maximum fee')}</th>
              <th class="border-b px-4 py-3 text-right font-normal">{t('Total amount')}</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr>
              <td class="border-b border-r border-slate-100 px-4 py-3 text-title-gray">
                {props.fee.settings?.feeType || emptyPlaceholder}
              </td>
              <td class="border-b border-r border-slate-100 px-4 py-3 text-right text-title-gray">
                {percentFormat(props.fee.settings?.feePercentage)}
              </td>
              <td class="border-b border-r border-slate-100 px-4 py-3 text-right text-title-gray">
                {currency(props.fee.settings?.minimumFee)}
              </td>
              <td class="border-b border-r border-slate-100 px-4 py-3 text-right text-title-gray">
                {currency(props.fee.settings?.maximumFee || undefined)}
              </td>
              <td class="border-b border-slate-100 px-4 py-3 text-right text-link">{currency(props.feeAmount)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end">
        <a href="/settings/property/management-fees" class="flex cursor-pointer items-center gap-1 text-xs text-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M10 18C5.58187 18 2 14.4181 2 10C2 5.58187 5.58187 2 10 2C14.4181 2 18 5.58187 18 10C18 14.4181 14.4181 18 10 18ZM10 16.9333C13.8293 16.9333 16.9333 13.8293 16.9333 10C16.9333 6.17067 13.8293 3.06667 10 3.06667C6.17067 3.06667 3.06667 6.17067 3.06667 10C3.06667 13.8293 6.17067 16.9333 10 16.9333Z"
              fill="#3C36DD"
            />
            <path
              d="M13.2828 10.2828C13.4391 10.1266 13.4391 9.87337 13.2828 9.71716L10.7373 7.17157C10.581 7.01536 10.3278 7.01536 10.1716 7.17157C10.0154 7.32778 10.0154 7.58105 10.1716 7.73726L12.4343 10L10.1716 12.2627C10.0154 12.419 10.0154 12.6722 10.1716 12.8284C10.3278 12.9846 10.581 12.9846 10.7373 12.8284L13.2828 10.2828ZM6 10.4L13 10.4L13 9.6L6 9.6L6 10.4Z"
              fill="#3C36DD"
            />
          </svg>
          {t('View setting')}
        </a>
      </div>
    </div>
  );
};
