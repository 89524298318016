import { ResetPassword } from '~/components/reset-password';
import { ResetPasswordProvider } from '~/contexts/local';

export const ResetPasswordPage = () => {
  return (
    <ResetPasswordProvider>
      <ResetPassword />
    </ResetPasswordProvider>
  );
};
