import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const CHATS_URL = '/api/chats';

export function validateChat(chat: MagicDoor.Api.HydratedMagicChatDto | MagicDoor.Api.ChatMessageDto): void {
  if (!chat) throw new ValidationError('Chat object must be provided');
}

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export interface ChatCreationResponse {
  chatId: string;
}

export type ChatFilter = {
  closed?: boolean;
};

export type SendChatData = {
  message: string;
  sendText: boolean;
  sendEmail: boolean;
  files?: (string | Blob)[];
};

export type UnreadMessageFilter = {
  page?: number;
  pageSize?: number;
};

export type ChatSearchFilter = {
  page?: number;
  pageSize?: number;
  search?: string;
  fileName?: string;
};

export class ChatRepository extends BaseRestRepository {
  public async getChats(filter?: ChatFilter): Promise<MagicDoor.Api.HydratedMagicChatDto[]> {
    const url = urlWithQuery(CHATS_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getChat(chatId: string): Promise<MagicDoor.Api.HydratedMagicChatDto> {
    const url = `${CHATS_URL}/${chatId}`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async getChatMessages(
    chatId: string,
    before?: string,
    after?: string,
    take = 20,
    search?: string,
    fileName?: string
  ): Promise<MagicDoor.Api.ChatMessageDto[]> {
    validateId(chatId);

    const params = new URLSearchParams();
    if (before) {
      params.append('before', before);
    }
    if (after) {
      params.append('after', after);
    }
    params.append('take', take.toString());

    if (search) {
      params.append('search', search);
    }

    if (fileName) {
      params.append('fileName', fileName);
    }

    const url = `${CHATS_URL}/${chatId}/messages?${params.toString()}`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async createTextMessageChat(request: MagicDoor.Api.CreatePhoneChatDto): Promise<MagicDoor.Api.ChatCreateResponseDto> {
    const url = `${CHATS_URL}/phone`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(request),
    });

    return this.getJsonResponse(response);
  }

  public async sendChatMessage(chatId: string, chatData: SendChatData) {
    validateId(chatId, 'Chat');

    const url = `${CHATS_URL}/${chatId}/messages`;

    const formData = new FormData();
    formData.append('Message', chatData.message || '');
    formData.append('SendText', String(chatData.sendText));
    formData.append('SendEmail', String(chatData.sendEmail));

    if (chatData.files && chatData.files.length > 0) {
      chatData.files.forEach((file: string | Blob) => {
        formData.append('Files', file);
      });
    }

    return await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async createChatWindow(
    entryType: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`,
    entryId: string
  ): Promise<MagicDoor.Api.ChatCreateResponseDto> {
    let url;

    switch (entryType) {
      case 'lease':
        url = `/api/leases/${entryId}/chat`;
        break;
      case 'tenant':
        url = `/api/tenants/${entryId}/chat`;
        break;
      case 'vendor':
        url = `/api/vendors/${entryId}/chat`;
        break;
      case 'owner':
        url = `/api/owners/${entryId}/chat`;
        break;
      case 'rentalApplication':
        url = `/api/rental-applications/${entryId}/chat`;
        break;
      case 'maintenanceRequest':
        url = `/api/maintenance-requests/${entryId}/chat`;
        break;
      case 'workOrder':
        url = `/api/work-orders/${entryId}/chat`;
        break;
      case 'workOrderGroup':
        url = `/api/work-orders/${entryId}/chat/group`;
        break;
      default:
        throw new Error('Invalid chat type');
    }

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
    });

    if (response.ok) {
      const responseBody = await response.json();

      return responseBody;
    } else {
      throw new Error(`Failed to create chat. Server responded with status: ${response.status}`);
    }
  }

  public async sendChatMagicIt(chatId: string, chatData: string): Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined> {
    validateId(chatId, 'Chat');

    const url = `${CHATS_URL}/${chatId}/magic`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ MyText: chatData }),
    });

    return this.getJsonResponse(response);
  }

  public async updateChat(
    chatId: string,
    updateChat: MagicDoor.Api.UpdateChatDto
  ): Promise<MagicDoor.Api.HydratedMagicChatDto | undefined> {
    validateId(chatId, 'Chat');

    const url = `${CHATS_URL}/${chatId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(updateChat),
    });
    return this.getJsonResponse(response);
  }

  public async markMessagesAsRead(chatId: string): Promise<void> {
    validateId(chatId, 'Chat');

    const url = `${CHATS_URL}/${chatId}/messages/read/all`;
    await this.fetchWithAuth(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      false
    );
  }

  public async paraphraseMessage(chatId: string, messageId: string): Promise<MagicDoor.Api.MagicChatTextResponseDto> {
    validateId(chatId, 'Chat');
    validateId(messageId, 'Message');

    const url = `${CHATS_URL}/${chatId}/messages/${messageId}/magic`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async archiveChat(chatId: string): Promise<MagicDoor.Api.HydratedMagicChatDto> {
    validateId(chatId, 'Chat');

    const url = `${CHATS_URL}/${chatId}/archive`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });

    return this.getJsonResponse(response);
  }

  public async getUnreadMessage(filter: UnreadMessageFilter): Promise<MagicDoor.Api.UnreadMessagePaginationDto> {
    const baseUrl = `${CHATS_URL}/unread-messages`;
    const url = urlWithQuery(baseUrl, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async unarchiveChat(chatId: string): Promise<MagicDoor.Api.HydratedMagicChatDto> {
    validateId(chatId, 'Chat');
    const url = `${CHATS_URL}/${chatId}/unarchive`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
    });

    return this.getJsonResponse(response);
  }

  public async searchChat(filter: ChatSearchFilter): Promise<MagicDoor.Api.UnreadMessagePaginationDto> {
    const baseUrl = `${CHATS_URL}/search`;
    const url = urlWithQuery(baseUrl, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async canCompanySendTextMessages(): Promise<MagicDoor.Api.CanCompanySendTextMessagesUseCaseResultDto> {
    const url = `/api/phones`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
