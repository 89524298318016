import { createSignal, onMount } from 'solid-js';
import IconViewBankReport from '~/assets/images/bank-account/viewBankReport.svg?component-solid';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { useBankAccount, useBankAccountsList } from '~/contexts/local';
import { BankAccountConnectType } from '~/swagger/Api';
import { PlaidAndStripePanel } from './PlaidAndStripePanel';
import type { Component } from 'solid-js';

export const ListExpandContent: Component<{
  bankAccount: MagicDoor.Api.HydratedBankAccountDto;
  onSettingsClick: (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => void;
  onViewReportClick: (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => void;
  isResponsive?: boolean;
}> = (props) => {
  const { t } = useLocalization();

  const { getBankAccountConnections } = useBankAccount();
  const { onUpdateBankAccounts } = useBankAccountsList();

  const [loading, setLoading] = createSignal<boolean>(false);
  const [connections, setConnections] = createSignal<MagicDoor.Api.BankAccountConnectionsDto>();

  const getConnections = async (isExt?: boolean) => {
    if (!isExt) setLoading(true);
    const res = await getBankAccountConnections(props.bankAccount.id);
    setConnections(res);
    setLoading(false);
    if (isExt) {
      onUpdateBankAccounts({
        ...props.bankAccount,
        plaid: {
          ...props.bankAccount?.plaid,
          status: res?.plaid?.status,
        } as MagicDoor.Api.HydratedBankAccountDto['plaid'],
        stripe: {
          ...props.bankAccount?.stripe,
          status: res?.stripe?.status,
        } as MagicDoor.Api.HydratedBankAccountDto['stripe'],
      });
    }
  };

  onMount(() => {
    getConnections();
  });

  return (
    <div class={`grid w-full gap-4 p-4 ${props.isResponsive ? 'grid-cols-1 lg:grid-cols-3' : 'grid-cols-3'}`}>
      <PlaidAndStripePanel
        type={BankAccountConnectType.Plaid}
        bankAccountId={props.bankAccount.id}
        data={connections()}
        refresh={() => getConnections(true)}
        loading={loading()}
        class="transition-transform hover:scale-[1.02]"
      />
      <PlaidAndStripePanel
        type={BankAccountConnectType.Stripe}
        bankAccountId={props.bankAccount.id}
        data={connections()}
        refresh={() => getConnections(true)}
        loading={loading()}
        class="transition-transform hover:scale-[1.02]"
      />
      <div class="flex flex-col items-center justify-center gap-4 rounded-xl border border-[#E5E5F4] bg-[#F9FAFC] p-4 sm:flex-row lg:flex-col">
        <Button onClick={() => props.onViewReportClick(props.bankAccount)} class="w-full">
          <IconViewBankReport class="size-5" />
          <span class="ml-2">{t('View bank report')}</span>
        </Button>
        <Button type="button" onClick={() => props.onSettingsClick(props.bankAccount)} variant="outlined" class="w-full">
          <IconEdit class="size-5" />
          <span class="ml-2">{t('Edit bank account')}</span>
        </Button>
      </div>
    </div>
  );
};
