import { createEffect, createSignal, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { Panel } from '~/components/common/Panels';
import { Skeleton, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChecks } from '~/contexts/local';
import { isNull, isUndefined } from '~/utils/tool';

export const OverviewTab = () => {
  const { t } = useLocalization();
  const { nextCheckNumber, updateNextCheckNumber, nextCheckNumberActions } = useChecks();

  const [newNextCheckNumber, setNewNextCheckNumber] = createSignal<number>();
  const [loading, setLoading] = createSignal<boolean>(false);

  const handleUpdateNextCheckNumber = async () => {
    setLoading(true);
    try {
      await updateNextCheckNumber({
        nextCheckNumber: newNextCheckNumber()!,
      });
      nextCheckNumberActions.mutate({
        nextCheckNumber: newNextCheckNumber()!,
      });
      toast.success(t('Operation successful'));
    } catch {
      setNewNextCheckNumber(nextCheckNumber()?.nextCheckNumber);
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    setNewNextCheckNumber(nextCheckNumber()?.nextCheckNumber);
  });
  return (
    <div class="relative grid grid-cols-2 gap-5">
      <Panel class="col-span-full" title={t('Next check number')}>
        <div class="flex flex-col items-end gap-y-5 p-5">
          <Show when={!nextCheckNumber.loading} fallback={<Skeleton class="h-9 w-full" />}>
            <LabeledNumberInput onInput={setNewNextCheckNumber} step={1} class="w-full" value={newNextCheckNumber()} />
          </Show>
          <Button
            disabled={
              nextCheckNumber()?.nextCheckNumber == newNextCheckNumber() ||
              isUndefined(newNextCheckNumber()) ||
              isNull(newNextCheckNumber()) ||
              isNaN(newNextCheckNumber()!)
            }
            loading={loading()}
            onClick={handleUpdateNextCheckNumber}
            variant="white"
            color="link"
            class="border-none p-0 hover-allowed:hover:bg-white">
            {t('Edit with one click')}
          </Button>
        </div>
      </Panel>
    </div>
  );
};
