import { For, Show } from 'solid-js';
import IconWaring from '~/assets/images/units/listing/warning.svg?component-solid';
import LabeledInput from '~/components/common/Inputs/LabeledInput';
import { Section } from '~/components/common/Section';
import Tooltip from '~/components/common/Tooltip';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { SelectedOwnerTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { UpdateOwnerIncomeAmountUseCase } from '~/pdfsigner/usecases/taxes/owner/updateOwnerIncomeAmountsUseCase';
import { cn } from '~/utils/classnames';
import { OwnerTaxAmountLine } from './OwnerTaxAmountLine';

export interface OwnerTaxAmountsPageProps {
  class?: string;
}

export const OwnerTaxAmountsPage = (props: OwnerTaxAmountsPageProps) => {
  const { t } = useLocalization();
  const { execute: updateOwnerIncomeAmount } = useUseCase(UpdateOwnerIncomeAmountUseCase);
  const { model: ownerTaxAmounts } = usePresenter(SelectedOwnerTaxAmountPresenter);

  const onRentInput = async (ownerId: string, value?: string) => {
    await updateOwnerIncomeAmount({ ownerId, rentAmount: value });
  };

  const onOtherIncomeInput = async (ownerId: string, value?: string) => {
    await updateOwnerIncomeAmount({ ownerId, otherAmount: value });
  };

  return (
    <Show
      when={ownerTaxAmounts() && (ownerTaxAmounts()!.newOrChanged.length > 0 || ownerTaxAmounts()!.existing.length > 0)}
      fallback={<div class={'text-center normal-case'}>{t('There is no income for the selected year.')}</div>}>
      <Show when={ownerTaxAmounts() && ownerTaxAmounts()!.newOrChanged.length > 0}>
        <Section
          title={t('New or changed compensation amounts')}
          class={cn('mb-4 overflow-visible px-[96px]', props.class)}
          titleClass="mt-2 px-0 normal-case">
          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 py-4">
              <div class="mb-2 flex flex-row justify-between p-2">
                <div>{t('Owner')}</div>
                <div class="flex flex-row gap-2 normal-case">
                  <div class="w-44 pr-2 text-end">{t('Rent income')}</div>
                  <div class="w-44 pr-2 text-end">{t('Other income')}</div>
                </div>
              </div>
              <For each={ownerTaxAmounts()?.newOrChanged}>
                {(owner) => (
                  <OwnerTaxAmountLine
                    id={owner.ownerId}
                    name={owner.ownerName}
                    warningMessage={owner.hasTaxId ? undefined : t('Tax ID is missing!')}
                    amount={owner.rentAmount}
                    otherAmount={owner.otherAmount}
                    isAmountEditable={true}
                    onAmountInput={onRentInput}
                    onOtherAmountInput={onOtherIncomeInput}
                  />
                )}
              </For>
            </div>
          </div>
        </Section>
      </Show>
      <Show when={ownerTaxAmounts() && ownerTaxAmounts()!.existing.length > 0}>
        <Section
          title={t('Existing compensation amounts')}
          class={cn('overflow-visible px-[96px]', props.class)}
          titleClass="mt-2 px-0 normal-case">
          <div class="grid grid-cols-2 gap-4">
            <div class="col-span-2 flex flex-col overflow-y-auto border-t border-gray-200 py-4">
              <div class="mb-2 flex flex-row justify-between p-2">
                <div>{t('Owner')}</div>
                <div class="flex flex-row gap-2 normal-case">
                  <div class="w-44 pr-2 text-end">{t('Rent income')}</div>
                  <div class="w-44 pr-2 text-end">{t('Other income')}</div>
                </div>
              </div>
              <For each={ownerTaxAmounts()?.existing}>
                {(owner) => (
                  <div class="mb-2 flex items-center justify-between rounded-lg bg-input p-4 shadow-sm transition-colors">
                    <span class="text-gray-800">{owner.ownerName}</span>
                    <Show when={!owner.hasTaxId}>
                      <Tooltip message={t('Tax ID is missing!')} align="top">
                        <IconWaring class="ml-1" />
                      </Tooltip>
                    </Show>
                    <div class="ml-auto flex flex-row gap-2">
                      <LabeledInput
                        class="!mb-0 w-44"
                        inputContainerClass="rounded-lg bg-white"
                        inputClass="bg-white text-right"
                        prefix="$"
                        onChange={(value) => onRentInput(owner.ownerId, value)}
                        value={owner.rentAmount}
                        placeholder={t('Enter amount')}
                      />
                      <LabeledInput
                        class="!mb-0 w-44"
                        inputContainerClass="rounded-lg bg-white"
                        inputClass="bg-white text-right"
                        prefix="$"
                        onChange={(value) => onOtherIncomeInput(owner.ownerId, value)}
                        value={owner.otherAmount}
                        placeholder={t('Enter amount')}
                      />
                    </div>
                  </div>
                )}
              </For>
            </div>
          </div>
        </Section>
      </Show>
    </Show>
  );
};
