import { Show, createSignal } from 'solid-js';
import { CancelWithConfirmation } from '~/components/modals';
import MiddleModal from '~/components/modals/MiddleModal';
import { useLocalization } from '~/contexts/global';
import AddBankAccount from '~/pages/bank-accounts/components/AddBankAccount';

interface AddBankAccountModalProp {
  onClose: () => void;
}

export const AddBankAccountsModal = (props: AddBankAccountModalProp) => {
  const [showConfirmationModal, setShowConfirmationModal] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);
  const { t } = useLocalization();

  return (
    <MiddleModal
      class="w-11/12 lg:w-modal-md"
      confirmation={showCloseConfirmation()}
      onClose={() => props.onClose()}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <AddBankAccount class="h-full" onClose={props.onClose} setShowCloseConfirmation={setShowCloseConfirmation} />

      <Show when={showConfirmationModal()}>
        <CancelWithConfirmation onConfirm={() => props.onClose()} onCancel={() => setShowConfirmationModal(false)} />
      </Show>
    </MiddleModal>
  );
};
