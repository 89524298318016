import dayjs from 'dayjs';
import { FormItem } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { YearRange } from '~/components/ui/Calendar/yearRange';
import { useLocalization } from '~/contexts/global';
import { whenThisDayIsNotBirthDay } from '~/utils/date';
import { email, phone } from '~/utils/formRules';

export const RentalBasicInfo = () => {
  const { t } = useLocalization();

  return (
    <div class="grid grid-cols-3 gap-2 gap-y-6">
      <FormItem
        id="new-rental-application-step1-dateOfBirth"
        placeholder={t('MM/DD/YYYY')}
        label={t(`Date of birth`)}
        rules={[
          { type: 'dateOfBirth', message: t('Date of birth is invalid') },
          { required: true, message: t('Date of birth is required') },
          {
            message: t('Age cannot be less than 18 years'),
            validator: (value) => {
              const eighteenYearsAgo = dayjs().subtract(18, 'year').subtract(2, 'day');
              return dayjs(value).isSameOrBefore(eighteenYearsAgo, 'day');
            },
          },
          {
            message: t('Age cannot be greater than 125 years'),
            validator: (value) => {
              const oneHundredTwentyFiveYearsAgo = dayjs().subtract(125, 'year');
              return dayjs(value).isAfter(oneHundredTwentyFiveYearsAgo);
            },
          },
        ]}
        formFieldName={['dateOfBirth']}
        component={DueDateInputField}
        yearRange={YearRange.Birthday}
        disabledDate={whenThisDayIsNotBirthDay}
      />
      <FormItem
        id="new-rental-application-step1-phone"
        placeholder="Phone"
        rules={phone(t)}
        label={t(`Phone number`)}
        formFieldName={['phone']}
        component={LabeledTextInput}
      />
      <FormItem
        id="new-rental-application-step1-email"
        placeholder={t('Email')}
        rules={email(t)}
        label={t(`Email address`)}
        formFieldName={['email']}
        component={LabeledTextInput}
      />
    </div>
  );
};
