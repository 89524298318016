import * as signalR from '@microsoft/signalr';
import { tokenStorage } from './storage';

export class SignalRService {
  private connection: signalR.HubConnection;

  constructor(hubUrl: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => {
          const token = tokenStorage.get();
          if (token != null) return token;
          throw new Error('No token found');
        },
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.onclose((error) => {
      console.error('SignalR connection closed due to an error.', error);
    });
  }

  async startConnection(): Promise<void> {
    const token = tokenStorage.get();
    if (!token) {
      console.log('User is not authenticated. Skipping SignalR connection.');
      return;
    }

    try {
      await this.connection.start();
      console.log('SignalR connection established.');
    } catch (err) {
      console.error('Error establishing SignalR connection:', err);
    }
  }

  onReceiveMessage(handler: (message: any) => void): void {
    this.connection.on('ReceiveMessage', handler);
  }

  onChatCreate(handler: (chatId: string) => void): void {
    this.connection.on('ChatCreate', handler);
  }

  async stopConnection(): Promise<void> {
    await this.connection.stop();
    console.log('SignalR connection stopped.');
  }
}
