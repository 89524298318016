import { Show } from 'solid-js';
import MenuHamburger from '~/assets/images/common/menuHamburger.svg?component-solid';
import MenuHamburgerActive from '~/assets/images/common/menuHamburgerActive.svg?component-solid';
import { useMenu } from '~/components/layouts/Menu/MenuContext';
import { CustomLayoutButton } from './CustomLayoutButton/CustomLayoutButton';
import LocalizationButton from './LocalizationButton/LocalizationButton';
import SearchBar from './MagicSearchBar/MagicSearch';
import { SearchProvider } from './MagicSearchBar/MagicSearchContext';
import ProfileButton from './ProfileButton/ProfileButton';

interface NavigationProps {
  setIsSearchActive: (isSearchActive: boolean) => void;
}

const Navigation = (props: NavigationProps) => {
  const menu = useMenu();

  const getRouteTitle = () => {
    return menu?.getCurrentTitle();
  };

  return (
    <div
      class="fixed top-0 z-30 flex h-16 w-full bg-white px-3 md:px-8"
      classList={{
        'md:pl-64 md:w-layout-right': !menu?.isManuallyCollapsed(),
        'md:w-layout-full': menu?.isManuallyCollapsed(),
      }}>
      <div class="flex w-full items-center justify-between md:ml-0">
        <div class="flex items-center gap-2 md:hidden">
          <div onClick={() => menu?.setIsSidebarOpen((prev) => !prev)} class="cursor-pointer rounded-lg bg-gray-100 p-2">
            <Show when={!menu?.isSidebarOpen()} fallback={<MenuHamburgerActive class="size-5" />}>
              <MenuHamburger class="size-5" />
            </Show>
          </div>

          <div class="block h-4 w-px bg-input-border md:hidden" />

          <span class="text-sm text-text-level02">{getRouteTitle()}</span>
        </div>

        <Show when={!menu?.isMobile() || !menu?.isSidebarOpen()}>
          <>
            <SearchProvider>
              <div>
                <SearchBar setIsSearchActive={props.setIsSearchActive} />
              </div>
            </SearchProvider>

            <div class="flex items-center gap-2">
              <Show when={!menu?.isMobile()}>
                <CustomLayoutButton />
              </Show>
              <div class="block h-4 w-px bg-input-border md:hidden" />
              <LocalizationButton />

              <ProfileButton />
            </div>
          </>
        </Show>
      </div>
    </div>
  );
};

export default Navigation;
