import { useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import ChargeSticker from '~/assets/images/leases/chargeSticker.svg?component-solid';
import { Modal } from '~/components/modals/Modal';
import { getTenantFullName } from '~/components/tenants';
import { TransactionLeaseName, getTransactionPaymentMethod } from '~/components/transactions';
import { UnitImage } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component, JSX } from 'solid-js';
import type { Icon } from '~/components/ui';

export const FieldItem: Component<{ label: string; icon: Component<{ class?: string }>; value: JSX.Element; class?: string }> = (props) => (
  <div class={`space-y-1.5 ${props.class}`}>
    <h5 class="text-xs uppercase text-[#464f74]">{props.label}</h5>
    <div class="flex items-center gap-2 text-sm">
      <props.icon class="shrink-0" />
      <span class="truncate">{props.value}</span>
    </div>
  </div>
);

const IconUsers: Icon = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="8" fill="#FFF1F6" />
    <path
      d="M16 12.875C16 13.7038 15.6708 14.4987 15.0847 15.0847C14.4987 15.6708 13.7038 16 12.875 16C12.0462 16 11.2513 15.6708 10.6653 15.0847C10.0792 14.4987 9.75 13.7038 9.75 12.875C9.75 12.0462 10.0792 11.2513 10.6653 10.6653C11.2513 10.0792 12.0462 9.75 12.875 9.75C13.7038 9.75 14.4987 10.0792 15.0847 10.6653C15.6708 11.2513 16 12.0462 16 12.875ZM20.375 16C21.038 16 21.6739 15.7366 22.1428 15.2678C22.6116 14.7989 22.875 14.163 22.875 13.5C22.875 12.837 22.6116 12.2011 22.1428 11.7322C21.6739 11.2634 21.038 11 20.375 11C19.712 11 19.0761 11.2634 18.6072 11.7322C18.1384 12.2011 17.875 12.837 17.875 13.5C17.875 14.163 18.1384 14.7989 18.6072 15.2678C19.0761 15.7366 19.712 16 20.375 16ZM19.125 19.2425V19.125C19.1259 18.4487 18.9065 17.7905 18.5 17.25H22.5912C23.4362 17.25 24.1225 17.935 24.1225 18.7812C24.1225 18.7812 24.1225 21.625 20.3725 21.625C19.625 21.625 19.0263 21.5125 18.5475 21.3313C18.8461 20.7841 19.0358 20.1843 19.1062 19.565C19.1137 19.4875 19.1187 19.4212 19.1213 19.3662L19.125 19.2425ZM17.875 19.125C17.875 18.6277 17.6775 18.1508 17.3258 17.7992C16.9742 17.4475 16.4973 17.25 16 17.25H9.75C9.25272 17.25 8.77581 17.4475 8.42418 17.7992C8.07254 18.1508 7.875 18.6277 7.875 19.125V19.2188C7.875 19.2188 7.875 22.875 12.875 22.875C17.64 22.875 17.8638 19.555 17.875 19.2425V19.125Z"
      fill="#FF6188"
    />
  </svg>
);

const IconBanks: Icon = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="8" fill="#FCE7FF" />
    <path
      d="M9.00059 9.76483H9.73269V23.6883H19.0142V9.76483H19.7463V8.30005H9L9.00059 9.76483ZM13.8313 13.7346V13.4081C13.8313 13.1836 13.9934 13.0025 14.1917 13.0025C14.3901 13.0025 14.5522 13.1836 14.5522 13.4081V13.7709C15.5016 13.9282 16.1482 14.4911 16.3008 15.2018C16.3342 15.2581 16.3522 15.3222 16.353 15.3876C16.353 15.6168 16.1577 15.8027 15.9142 15.8027C15.6708 15.8027 15.4564 15.6073 15.4564 15.3781C15.387 15.0225 15.0628 14.7315 14.5522 14.6098V16.6161C15.8572 16.7355 16.7134 17.4794 16.7134 18.3885C16.7134 19.2999 15.8572 20.0415 14.5522 20.1608V20.4992C14.5522 20.7237 14.3901 20.9024 14.1917 20.9024C13.9917 20.9024 13.8313 20.7213 13.8313 20.4992V20.1632C12.6866 20.0581 11.904 19.4667 11.7277 18.7055C11.6944 18.6485 11.6992 18.5933 11.6992 18.5244C11.6992 18.2952 11.8827 18.1023 12.1427 18.1023C12.4028 18.1023 12.6367 18.2952 12.6367 18.5244C12.7204 18.9252 13.1545 19.2446 13.8319 19.3307V17.296C12.5346 17.2289 11.6707 16.4611 11.6707 15.5141C11.6707 14.5695 12.5334 13.8041 13.8313 13.7346ZM15.7658 18.3908C15.7658 17.9283 15.3056 17.5394 14.5492 17.4462V19.3355C15.308 19.2423 15.7658 18.8557 15.7658 18.3908ZM19.9927 11.9647V23.6889H23.9002V14.3094L19.9927 11.9647ZM22.6782 20.0248H21.2134V18.56H22.6782V20.0248ZM22.6782 17.3387H21.2134V15.8739H22.6782M13.8313 16.4754V14.5623C13.0417 14.617 12.5483 15.0225 12.5483 15.5189C12.5483 16.0152 13.0422 16.4184 13.8313 16.4754Z"
      fill="#DC1BED"
    />
  </svg>
);

export const IconCards: Icon = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="8" fill="#FFF2DF" />
    <path
      d="M10.1126 9.00033H21.8874C23.2957 9.00033 24 9.73956 24 11.2183V21.182C24 22.6608 23.2957 23.4 21.8874 23.4H10.1126C8.70432 23.4 8 22.6608 8 21.182V11.218C8 9.73956 8.70432 9.00033 10.1126 9.00033Z"
      fill="#ED8C1B"
    />
    <path
      d="M8 12.2H24V13.4444H8V12.2ZM19.4051 19.7556H21.8646C22.175 19.7556 22.4269 20.0342 22.4269 20.3778C22.4269 20.7214 22.175 21 21.8646 21H19.4051C19.0947 21 18.8429 20.7214 18.8429 20.3778C18.8429 20.0342 19.0947 19.7556 19.4051 19.7556Z"
      fill="white"
    />
  </svg>
);

const IconIds: Icon = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="8" fill="#D2FFE4" />
    <path
      d="M17.9745 16.4235H21.7118V15.5068H17.9745V16.4235ZM17.9745 13.8843H21.7118V12.9676H17.9745V13.8843ZM10.2883 19.0323H16.3529V18.8801C16.3529 18.3454 16.084 17.9314 15.5463 17.6381C15.0085 17.3447 14.2666 17.1981 13.3206 17.1981C12.3746 17.1981 11.6324 17.3447 11.094 17.6381C10.5556 17.9314 10.287 18.3454 10.2883 18.8801V19.0323ZM13.3206 15.7176C13.7074 15.7176 14.0331 15.5853 14.2977 15.3207C14.563 15.0555 14.6956 14.7295 14.6956 14.3426C14.6956 13.9558 14.563 13.6301 14.2977 13.3655C14.0325 13.1009 13.7068 12.9683 13.3206 12.9676C12.9344 12.967 12.6086 13.0996 12.3434 13.3655C12.0782 13.6313 11.9456 13.957 11.9456 14.3426C11.9456 14.7283 12.0782 15.0543 12.3434 15.3207C12.6086 15.5872 12.9344 15.7195 13.3206 15.7176ZM9.23133 22.4166C8.80906 22.4166 8.45675 22.2755 8.17442 21.9931C7.89208 21.7108 7.75061 21.3582 7.75 20.9353V11.0646C7.75 10.6424 7.89147 10.2901 8.17442 10.0077C8.45736 9.7254 8.80936 9.58392 9.23042 9.58331H22.7696C23.1913 9.58331 23.5433 9.72479 23.8256 10.0077C24.1079 10.2907 24.2494 10.643 24.25 11.0646V20.9362C24.25 21.3579 24.1085 21.7102 23.8256 21.9931C23.5426 22.2761 23.1906 22.4173 22.7696 22.4166H9.23133Z"
      fill="#13C57A"
    />
  </svg>
);

export const IconCalendar: Icon = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="8" fill="#F1F2FF" />
    <path
      d="M8.5 12.75C8.5 11.3355 8.5 10.629 8.9395 10.1895C9.379 9.75 10.0855 9.75 11.5 9.75H20.5C21.9145 9.75 22.621 9.75 23.0605 10.1895C23.5 10.629 23.5 11.3355 23.5 12.75C23.5 13.1033 23.5 13.2803 23.3905 13.3905C23.2802 13.5 23.1025 13.5 22.75 13.5H9.25C8.89675 13.5 8.71975 13.5 8.6095 13.3905C8.5 13.2803 8.5 13.1025 8.5 12.75ZM8.5 19.5C8.5 20.9145 8.5 21.621 8.9395 22.0605C9.379 22.5 10.0855 22.5 11.5 22.5H20.5C21.9145 22.5 22.621 22.5 23.0605 22.0605C23.5 21.621 23.5 20.9145 23.5 19.5V15.75C23.5 15.3967 23.5 15.2197 23.3905 15.1095C23.2802 15 23.1025 15 22.75 15H9.25C8.89675 15 8.71975 15 8.6095 15.1095C8.5 15.2197 8.5 15.3975 8.5 15.75V19.5Z"
      fill="#5B56F8"
    />
    <path d="M12.25 8.25V10.5M19.75 8.25V10.5" stroke="#5B56F8" stroke-width="2" stroke-linecap="round" />
  </svg>
);

export const TenantPaymentDetailsModal: Component<{ visible: boolean; payment?: MagicDoor.Api.HydratedTransactionDto }> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const handleClose = () => props.payment && navigate(`/users/tenants/${props.payment.tenant?.id}/payments`, { scroll: false });

  return (
    <Modal
      title={t('Payment detail')}
      visible={props.visible}
      confirmation={false}
      cancelText={t('Close')}
      onCancel={handleClose}
      onDone={handleClose}>
      <div class="space-y-6 px-8 py-6">
        <div class="flex items-center gap-2 rounded-lg bg-input p-2">
          <UnitImage class="size-12 rounded" unitId={props.payment?.unit?.id} imageId={props.payment?.unit?.imageId} />
          <div class="space-y-0.5">
            <h4 class="text-sm font-medium text-title-gray">
              <TransactionLeaseName property={props.payment?.property} unit={props.payment?.unit} />
            </h4>
            <p class="text-xs capitalize text-success">{props.payment?.transactionIdentity}</p>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3.5 text-title-gray">
          <FieldItem
            label={t('Received from')}
            icon={IconUsers}
            value={getTenantFullName(props.payment?.tenant) || props.payment?.vendor?.name}
          />
          <FieldItem label={t('Deposit bank account')} icon={IconBanks} value={props.payment?.bankAccount.name} />
          <FieldItem
            label={t('Payment method')}
            icon={IconCards}
            value={t(getTransactionPaymentMethod(props.payment?.transactionPaymentMethod))}
          />
          <FieldItem label={t('MagicDoor transaction id')} icon={IconIds} value={props.payment?.id} />
          <FieldItem label={t('Transaction time')} icon={IconCalendar} value={dateFormat('MM/DD/YYYY', props.payment?.transactionDate)} />
          <Show when={props.payment?.externalTransactionId}>
            <FieldItem class="col-span-full" label={t('Transaction id')} icon={IconIds} value={props.payment?.externalTransactionId} />
          </Show>
        </div>
        {/* <div class="space-y-1.5">
          <h5 class="text-xs uppercase text-[#464f74]">{t('Memo')}</h5>
          <div class="h-10 rounded-lg bg-light-gray px-4 py-2.5 text-sm">{props.payment?.memo}</div>
        </div> */}
      </div>
      <div class="relative mt-3 flex h-18 shrink-0 items-center justify-end bg-light-pink px-4 lg:px-10">
        <ChargeSticker class="absolute -top-2 left-5 overflow-hidden" />
        <Show when={true}>
          <span class="mr-2 text-xs uppercase text-text-level03 lg:text-sm">{t('Total amount')}: </span>
          <span class="text-sm font-bold text-essential-colour lg:text-2xl">{currency(props.payment?.amount)}</span>
        </Show>
      </div>
    </Modal>
  );
};
