import { createEffect, createSignal } from 'solid-js';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { FormItem, Form, useForm } from '~/components/common/BetterForm';
import { isFormStoreChanged } from '~/components/common/BetterForm/utils';
import { Button } from '~/components/common/Buttons';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import {
  CategoryComponentSection,
  CategoryComponentWrapperProvider,
  CategoryContentSection,
  getErrorAndProgressProps,
} from '~/components/common/Category';
import { LabeledGroup } from '~/components/common/Inputs';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { useLocalization, useChartOfAccounts } from '~/contexts/global';
import type { Section } from '~/components/common/Category';

type AccountType = 'asset' | 'liability' | 'equity' | 'revenue' | 'expense';

interface Props {
  onClose: () => void;
  showCloseConfirmation: boolean;
  setShowCloseConfirmation: (value: boolean) => void;
  onSave: (data: MagicDoor.Api.CreateChartOfAccountDto | MagicDoor.Api.UpdateChartOfAccountDto) => Promise<void>;
  parentOptions?: MagicDoor.Api.ChartOfAccountDto[];
  type?: AccountType;
  defaultParentId?: string;
}

type AddSection = Section & { required?: boolean };

export const AddOrEditChartOfAccount = (props: Props) => {
  const { t } = useLocalization();
  const { current, addChartOfAccount } = useChartOfAccounts();
  const [activeCategory, setActiveCategory] = createSignal<string>('basicInfo');

  const form = useForm();

  const typeLabels = {
    asset: t('Asset'),
    liability: t('Liability'),
    equity: t('Equity'),
    revenue: t('Revenue'),
    expense: t('Expense'),
  };

  const handleSubmit = async () => {
    const data = form.formStore as unknown as MagicDoor.Api.CreateChartOfAccountDto;

    try {
      await props.onSave(data);
      props.setShowCloseConfirmation(false);
      props.onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const sections: AddSection[] = [
    {
      key: 'basicInfo',
      component: () => (
        <div class="space-y-4">
          <h3 class="text-lg font-semibold text-title-gray">{t('Basic information')}</h3>
          <div class="flex flex-col">
            <LabeledGroup class="capitalize text-text-level03" labelJSX={t('Type')} />
            <span class="text-sm text-text-level01">{props.type && typeLabels[props.type]}</span>
          </div>
          <FormItem
            formFieldName="name"
            placeholder={t('Please enter')}
            component={LabeledTextInput}
            label={t('Chart of account name')}
            rules={[{ required: true, message: t('Please enter the {name}', { name: t('Chart of account name') }) }]}
          />
          <FormItem
            formFieldName="parentId"
            placeholder={t('Please select')}
            component={LabeledChartOfAccountSelect}
            label={t('Parent chart of account')}
            types={props.type}
          />
          <FormItem
            formFieldName="offsetChartOfAccountId"
            placeholder={t('Please select')}
            component={LabeledChartOfAccountSelect}
            label={t('Offset chart of account')}
          />
        </div>
      ),
      name: t('Basic information'),
      required: true,
      fields: ['name', 'parentId'],
    },
  ];

  let scrollContainer!: HTMLDivElement;

  createEffect(() => {
    props.setShowCloseConfirmation(isFormStoreChanged(current(), form.formStore));
  });

  createEffect(() => {
    if (current()) {
      form.setFieldsValue({
        parentId: current()?.parentId || '',
        name: current()?.name || '',
        type: current()?.type || '',
        offsetChartOfAccountId: current()?.offsetChartOfAccountId || '',
      });
    } else {
      form.setFieldsValue({
        parentId: props.defaultParentId || '',
        name: '',
        type: props.type || '',
        offsetChartOfAccountId: '',
      });
    }
  });

  return (
    <Form defaultForm={form} onFormSubmit={handleSubmit} class="relative flex flex-col text-sm">
      <CategoryComponentWrapperProvider
        sections={sections}
        getScrollElement={() => scrollContainer}
        onActiveCategoryChange={(key) => setActiveCategory(key)}
        activeCategory={activeCategory()}>
        <div class="relative flex flex-1 items-start">
          <div class="sticky top-0 hidden h-full w-1/3 bg-inputbox-bg text-text-level03 lg:block">
            <CategoryComponentSection
              mode="progress"
              categoryFooterTitle={t('Chart of account')}
              class="h-full min-w-0 bg-transparent"
              {...getErrorAndProgressProps(form)}
            />
          </div>

          <CategoryContentSection
            renderMethod={({ setContentRef }) => {
              return (
                <div ref={scrollContainer} class="thinscroll flex h-[610px] flex-1 flex-col overflow-auto px-8">
                  <div class="h-full py-8" ref={(ref) => setContentRef('basicInfo', ref)}>
                    {sections[0].component({})}
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div class="z-20 flex w-full items-center justify-between border-t bg-white p-6">
          <CancelWithConfirmation
            enabled={props.showCloseConfirmation}
            onConfirm={props.onClose}
            message={t('If you cancel the addition, the information already filled in cannot be saved')}>
            <Button variant="outlined">{t('Cancel')}</Button>
          </CancelWithConfirmation>

          <CallToActionButton type="submit" loading={addChartOfAccount.loading}>
            {current() ? t('Update account') : t('Create account')}
          </CallToActionButton>
        </div>
      </CategoryComponentWrapperProvider>
    </Form>
  );
};
