import { Route, Routes, useNavigate } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import IconCompanyPaymentMethods from '~/assets/images/settings/company/companyPaymentMethods.svg';
import { Button } from '~/components/common/Buttons';
import { Empty } from '~/components/common/Empty';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { IconLoader, toast } from '~/components/ui';
import { useCompanyPaymentMethods, useCompanySettings, useLocalization } from '~/contexts/global';
import { useBankAccountsList } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { AddPlaidPaymentAccountModal } from '~/pages/settings/company-settings/company-payment-accounts/AddPlaidPaymentAccountModal';
import { AddPaymentAccountModal } from '~/pages/settings/company-settings/company-payment-accounts/AddStripePaymentAccountModal';
import { BankAccountCard } from '~/pages/settings/company-settings/company-payment-accounts/BankAccountCard';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const CompanyPaymentMethods = () => {
  const { t } = useLocalization();
  const { validatedPaymentAccounts, paymentAccounts, removePaymentAccount, paymentAccountsActions, validatedPaymentAccountsOptions } =
    useCompanyPaymentMethods();
  const { companySettings, changeCompanySettings, companySettingsActions } = useCompanySettings();
  const { bankAccounts } = useBankAccountsList();
  const navigate = useNavigate();

  const accountsModel = createMemo(() => {
    return {
      invoicePaymentAccountId: companySettings()?.invoicePaymentAccountId || null,
      invoiceBankAccountId: companySettings()?.invoiceBankAccountId || null,
    };
  });

  const sections = createMemo<ItemProps[]>(() => {
    return [
      validatedPaymentAccountsOptions()?.length
        ? {
            field: 'invoicePaymentAccountId',
            label: 'Invoice payment account',
            type: 'select',
            class: '2xl:col-span-1 col-span-2',
            options: validatedPaymentAccountsOptions(),
            description: 'The account used for making invoice payments',
            placeholder: 'Please select payment account',
          }
        : null,
      {
        field: 'invoiceBankAccountId',
        label: 'Invoice accounting bank account',
        type: 'select',
        class: '2xl:col-span-1 col-span-2',
        options: bankAccounts().map((account) => ({ label: account.name, value: account.id })),
        description: 'The bank account used for tracking invoice transactions',
        placeholder: 'Please select bank account',
      },
    ].filter((item) => item !== null) as ItemProps[];
  });

  const handleSavePaymentAccounts = async (data: MagicDoor.Api.CompanySettingsDto) => {
    await changeCompanySettings(data);
    toast.success(t('Payment methods updated'));
    companySettingsActions.refetch();
  };

  const handleRemovePaymentAccount = async (id: string) => {
    const isInvoicePaymentAccount = id === accountsModel().invoicePaymentAccountId;
    await removePaymentAccount(id);

    if (isInvoicePaymentAccount) {
      companySettingsActions.refetch();
    }

    paymentAccountsActions.mutate((val) => {
      if (!val) return val;
      return {
        paymentAccounts: val?.paymentAccounts.filter((account) => account.id !== id),
      };
    });
  };

  return (
    <div class="flex flex-col gap-3">
      <ThreeSectionBox
        topTitle={<SettingTitle title={t('Company invoice settings')} icon={IconCompanyPaymentMethods} />}
        middle={
          <div class="flex flex-col gap-5 p-3 md:p-6">
            <Show
              when={!companySettings.loading && !changeCompanySettings.loading}
              fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => handleSavePaymentAccounts(data as MagicDoor.Api.CompanySettingsDto)}
                  model={accountsModel()}
                />
              </div>
            </Show>
          </div>
        }
      />

      <ThreeSectionBox
        topTitle={<SettingTitle title={t('Payment accounts')} />}
        middle={
          <Show when={!paymentAccounts.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
            <Show when={validatedPaymentAccounts()?.length} fallback={<Empty description={t('No payment accounts')} />}>
              <div class="flex flex-col flex-wrap items-center gap-3 p-4 xs:flex-row">
                <For each={validatedPaymentAccounts()}>
                  {(account) => <BankAccountCard account={account} onRemove={() => handleRemovePaymentAccount(account.id)} />}
                </For>
              </div>
            </Show>
          </Show>
        }
        coloredClass="flex-col items-start gap-2 md:flex-row"
        topRightClass="w-full md:w-fit"
        topAction={
          <div class="flex w-full flex-col items-center justify-center gap-2 text-center md:w-fit md:flex-row">
            <Button onClick={() => navigate('./add-stripe')} class="w-full justify-center md:w-fit">
              {t('Add credit/debit card')}
            </Button>
            <Button onClick={() => navigate('./add-plaid')} class="w-full justify-center md:w-fit">
              {t('Add Plaid account')}
            </Button>
          </div>
        }
      />
      <Routes>
        <Route path="/add-stripe" element={<AddPaymentAccountModal />} />
        <Route path="/add-plaid" element={<AddPlaidPaymentAccountModal />} />
      </Routes>
    </div>
  );
};
