import { Panel } from '~/components/common/Panels';
import { LeaseDeposits } from '~/components/leases/LeaseDeposits';
import { isEndedLease, isEvictionLease } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type BalancesProps = {
  class?: string;
  lease: MagicDoor.Api.HydratedLeaseDto | undefined;
};

export const RentAmount: Component<BalancesProps> = (props) => {
  const { t } = useLocalization();

  return (
    <>
      <Panel title={t('Rent amount')} class={cn('hidden p-2 md:block', props.class)}>
        <div
          class="mt-1 flex flex-col items-center justify-center rounded-lg py-3"
          classList={{
            'bg-success-light': !isEvictionLease(props.lease) && !isEndedLease(props.lease),
            'bg-[#F3F2F9]': isEvictionLease(props.lease) || isEndedLease(props.lease),
          }}>
          <div class="text-base font-semibold text-success">{currency(props?.lease?.currentRent)}</div>
          <LeaseDeposits lease={props.lease} class="text-sm" showPrefix />
        </div>
      </Panel>

      <div class={cn('overflow-hidden rounded-lg border border-partingline bg-white p-2 md:hidden', props.class)}>
        <div
          class="flex h-15 items-center justify-between rounded-lg p-3"
          classList={{
            'bg-success-light': !isEvictionLease(props.lease) && !isEndedLease(props.lease),
            'bg-[#F3F2F9]': isEvictionLease(props.lease) || isEndedLease(props.lease),
          }}>
          <div class="font-semibold capitalize text-text-level01">{t('Rent amount')}</div>
          <div class="text-xl font-bold text-success">{currency(props?.lease?.currentRent)}</div>
        </div>
      </div>
    </>
  );
};
