import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { emptyPlaceholder } from '~/utils/constant';
import type { ValidComponent } from 'solid-js';

export const getMaskString = (accountMask?: null | string) => {
  if (!accountMask) return emptyPlaceholder;
  const mask = accountMask.slice(-4);
  return `***** ${mask}`;
};

export const BankAccountMask = (props: { accountMask?: string | null; as?: ValidComponent; class?: string }) => {
  return (
    <Show when={props.accountMask} fallback={emptyPlaceholder}>
      <Dynamic component={props.as ?? 'span'} class={props.class}>
        {getMaskString(props.accountMask)}
      </Dynamic>
    </Show>
  );
};
