import { createEffect, onCleanup } from 'solid-js';
import type { JSX } from 'solid-js';

interface ScrollObserverWrapperProps {
  children: JSX.Element;
  index: number;
  onScrollIntoView?: (index: number) => void;
  threshold?: number;
}

export const ScrollObserver = (props: ScrollObserverWrapperProps) => {
  let elementRef: HTMLElement | undefined = undefined;
  let observer: IntersectionObserver | undefined = undefined;

  createEffect(() => {
    if (elementRef) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              props.onScrollIntoView?.(props.index);
            }
          });
        },
        { root: null, threshold: props.threshold ?? 0.1 }
      );
      observer.observe(elementRef);
    }
  });

  onCleanup(() => {
    observer?.disconnect();
  });

  return (
    <div class="flex" ref={(el) => (elementRef = el)}>
      {props.children}
    </div>
  );
};
