import { For, Show, splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { IconCheck, Select } from '~/components/ui';
import { useChartOfAccounts, useLocalization } from '~/contexts/global';
import type { JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { SelectProps } from '~/components/ui';
import type { ChartOfAccountGroup } from '~/contexts/global';
import type { Merge } from '~/utils/types';

type AccountType = `${MagicDoor.Api.ChartOfAccountType}`;

type ChartOfAccountSelectProps<T extends boolean | undefined> = Merge<
  SelectProps<string, T>,
  { options?: never; types?: AccountType | AccountType[]; withAll?: boolean }
>;

type LeveledOption = { label: string; value: string; level: number; parentLast?: boolean; selfLast?: boolean };

const Spacer = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => (
  <svg
    class="h-10 shrink-0 stroke-input-border"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 40"
    stroke-width="1.5"
    fill="none"
    {...props}>
    <path d="M30 20h-7.5s-7.5.596-7.5-7.5V0v40" />
  </svg>
);

const LevelSpacer = (props: LeveledOption) => (
  <Show when={props.level > 0}>
    <Show when={!props.parentLast} fallback={<i style={{ width: `${(props.level - 1) * 30}px` }} />}>
      <For each={Array(props.level - 1)}>{() => <Spacer stroke-dasharray="40 100" stroke-dashoffset="-32" />}</For>
    </Show>
    <Spacer stroke-dasharray={props.selfLast ? '40 100' : undefined} />
  </Show>
);

export const ChartOfAccountSelect = <T extends boolean | undefined = undefined>(props: ChartOfAccountSelectProps<T>) => {
  const { t } = useLocalization();
  const { chartOfAccountGroups } = useChartOfAccounts();

  const filtered = () => {
    const types = props.types;
    if (types) {
      if (Array.isArray(types)) {
        return chartOfAccountGroups()?.filter((group) => types.includes(group.type));
      } else {
        return chartOfAccountGroups()?.filter((group) => group.type === types);
      }
    } else {
      return chartOfAccountGroups();
    }
  };

  const loadItems = (items: ChartOfAccountGroup['items'], level = 0, parentLast = false): LeveledOption[] =>
    items.flatMap((item, i) => [
      { label: item.name, value: item.id, level, parentLast, selfLast: i === items.length - 1 },
      ...loadItems(item.children, level + 1, i === items.length - 1),
    ]);

  const options = () => {
    const groups = filtered();
    if (groups.length === 0) return [];
    return groups.flatMap((group) => [{ label: group.title, type: 'group' as const }, ...loadItems(group.items)]);
  };

  const allOptions = () => {
    const inner = options();
    return props.withAll ? [{ label: t('All'), value: '' }, ...inner] : inner;
  };

  return (
    <Select
      {...props}
      popoverClass="gap-0"
      options={allOptions()}
      placeholder={props.placeholder ?? t('Please type or select a chart of account')}
      filterable={props.filterable ?? true}
      renderOption={(option) => (
        <div class="flex items-center">
          <LevelSpacer {...(option as unknown as LeveledOption)} />
          <span class="flex flex-1 cursor-pointer items-center rounded-md px-3 py-2.5 text-title-gray transition-colors hover-allowed:hover:bg-light-pink">
            {option.label}
            <Show when={option.selected}>
              <IconCheck class="ml-auto size-4 text-essential-colour" />
            </Show>
          </span>
        </div>
      )}
    />
  );
};

export const LabeledChartOfAccountSelect = <T extends boolean | undefined = undefined>(
  props: Omit<ChartOfAccountSelectProps<T>, 'onChange'> &
    LabeledGroupProps & { onInput?: ChartOfAccountSelectProps<T>['onChange'] } & {
      groupClass?: string;
    }
) => {
  const [selectProps, labeledProps] = splitProps(props, [
    'renderOption',
    'prefix',
    'value',
    'multiple',
    'filterable',
    'placeholder',
    'types',
    'withAll',
    'onInput',
    'class',
  ]);

  return (
    <LabeledGroup {...labeledProps}>
      <ChartOfAccountSelect {...selectProps} id={labeledProps.id} onChange={props.onInput} filterable={props.filterable ?? true} />
    </LabeledGroup>
  );
};
