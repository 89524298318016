import { Route, Routes } from '@solidjs/router';
import { LeaseRentTransactionsProvider, RentPaymentSettingsProvider } from '~/contexts/local';
import { AddOfflinePaymentView } from './AddOfflinePaymentView';

export const AddOfflinePaymentRoutes = () => {
  return (
    <LeaseRentTransactionsProvider>
      <Routes>
        <Route
          path="*"
          element={
            <RentPaymentSettingsProvider>
              <AddOfflinePaymentView />
            </RentPaymentSettingsProvider>
          }
        />
      </Routes>
    </LeaseRentTransactionsProvider>
  );
};
