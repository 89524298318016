import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { DataPathManager } from '~/pdfsigner/state/dataPathManager';
import { MagicUseCase } from '~/pdfsigner/usecases/baseUseCases/magicUseCase';

export class GetDataPathsUseCase extends MagicUseCase {
  public async runLogic(params: { type?: `${MagicDoor.Api.LeaseTemplateType}`; leaseDocumentId?: string }): Promise<void> {
    const dataPaths = await leaseTemplateRepository.getDataPaths(params.type, params.leaseDocumentId);
    this.getState().dataPathManager = new DataPathManager(dataPaths);
  }
}
