import { Outlet, useNavigate } from '@solidjs/router';
import { createMemo, createSignal, Show, For } from 'solid-js';
import IconAccountTab from '~/assets/images/settings/tabs/accountTab.svg?component-solid';
import IconCompanyTab from '~/assets/images/settings/tabs/companyTab.svg?component-solid';
import IconLeaseTab from '~/assets/images/settings/tabs/leaseTab.svg?component-solid';
import IconPortalTab from '~/assets/images/settings/tabs/portalTab.svg?component-solid';
// import IconPreferencesTab from '~/assets/images/settings/tabs/preferencesTab.svg?component-solid';
import IconPropertyTab from '~/assets/images/settings/tabs/propertyTab.svg?component-solid';
import IconRentalTab from '~/assets/images/settings/tabs/rentalApplcation.svg?component-solid';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MobileTabs } from '~/components/common/MobileTabs';
import { Tabs } from '~/components/common/Tabs';
import { useLocalization } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';

const Layout = () => {
  const { t } = useLocalization();
  const goBack = useGoBack();
  const { isMobileView } = useDeviceDetect();
  const [isShowDetails, setIsShowDetails] = createSignal<boolean>(false);
  const [activeTab, setActiveTab] = createSignal<string>('');
  const navigate = useNavigate();

  const accountNavItems = createMemo(() => [
    {
      name: t('Information'),
      path: 'account/information',
    },
    // {
    //   name: t('Activity logs'),
    //   path: 'account/activities',
    // },
    {
      name: t('Login & Password'),
      path: 'account/security',
    },
  ]);

  const companyNavItems = createMemo(() => [
    {
      name: t('Company information'),
      path: 'company/information',
    },
    {
      name: t('Business information'),
      path: 'company/business',
    },
    {
      name: t('Payment accounts'),
      path: 'company/payment-accounts',
    },
  ]);

  const propertyNavItems = createMemo(() => [
    {
      name: t('Property'),
      path: 'property/general',
    },
    {
      name: t('Rent notifications'),
      path: 'property/rent-notifications',
    },
    {
      name: t('Rent payment'),
      path: 'property/rent-payment',
    },
    {
      name: t('Deposit payment'),
      path: 'property/deposit-payment',
    },
    {
      name: t('Work order'),
      path: 'property/work-order',
    },
    {
      name: t('Rent payment processing fees'),
      path: 'property/rent-payment-processing-fees',
    },
    {
      name: t('Late fees'),
      path: 'property/late-fees',
    },
    {
      name: t('Management fees'),
      path: 'property/management-fees',
    },
    {
      // TODO Leave it aside for now
      name: t('Maintenance request'),
      path: 'property/settings-maintenance',
    },
    {
      name: t('Contributions'),
      path: 'property/contributions',
    },
  ]);

  const leaseNavItems = createMemo(() => [
    {
      name: t('Lease renewal'),
      path: 'lease/renewal',
    },
  ]);

  const chatsNavItems = createMemo(() => [
    {
      name: t('Chat'),
      path: 'chats/chat',
    },
    {
      name: t('Text'),
      path: 'chats/text',
    },
    {
      name: t('AI'),
      path: 'chats/ai',
    },
  ]);

  const portalNavItems = createMemo(() => [
    // {
    //   name: t('Tenant portal'),
    //   path: 'portal/tenant-portal',
    // },
    // {
    //   name: t('Owner portal'),
    //   path: 'portal/owner-portal',
    // },
    {
      name: t('Portal invite & url'),
      path: 'portal/invite-url',
    },
    {
      name: t('Portal invite & text'),
      path: 'portal/invite-text',
    },
    {
      name: t('Portal settings'),
      path: 'portal/portal-settings',
    },
  ]);

  // const preferencesNavItems = createMemo(() => [
  //   {
  //     name: t('Preferences'),
  //     path: 'preferences',
  //   },
  // ]);

  const rentalApplicationNavItems = createMemo(() => [
    {
      name: t('Application fee'),
      path: 'rental/applicationfee',
    },
    {
      name: t('Applicant information'),
      path: 'rental/information',
    },
    {
      name: t('Chart of account'),
      path: 'rental/chart-of-account',
    },
    {
      name: t('General settings'),
      path: 'rental/general-settings',
    },
    {
      name: t('Standard questions'),
      path: 'rental/standard-questions',
    },
  ]);

  const tabsNav = createMemo(() => [
    {
      name: accountNavItems(),
      icon: IconAccountTab,
      title: 'Account',
    },
    {
      name: companyNavItems(),
      icon: IconCompanyTab,
      title: 'Company',
    },
    {
      name: propertyNavItems(),
      icon: IconPropertyTab,
      title: 'Property',
    },
    {
      name: leaseNavItems(),
      icon: IconLeaseTab,
      title: 'Lease',
    },
    {
      name: rentalApplicationNavItems(),
      icon: IconRentalTab,
      title: 'Rental application',
    },
    {
      name: portalNavItems(),
      icon: IconPortalTab,
      title: 'Portal management',
    },
    {
      name: chatsNavItems(),
      icon: IconPortalTab,
      title: 'Chats',
    },
    // {
    //   name: preferencesNavItems(),
    //   icon: IconPreferencesTab,
    //   title: 'Portal management'
    // },
  ]);

  const breadcrumbItems = createMemo(() => [{ label: activeTab() }]);

  const handleClickTab = (tab: { name: string; path: string }) => {
    setIsShowDetails(true);
    setActiveTab(tab.name);
    navigate(tab.path);
  };

  const handleGoBack = () => {
    setIsShowDetails(false);
    goBack();
  };

  return (
    <div class="flex size-full gap-4 md:p-8">
      <div class="hidden gap-0 divide-y capitalize transition-all md:flex md:flex-col md:gap-4">
        <For each={tabsNav()}>{(tab) => <Tabs items={tab.name} icon={tab.icon} title={t(tab.title)} />}</For>
      </div>
      <Show when={isMobileView() && !isShowDetails()}>
        <div class="flex w-full flex-col gap-3 p-3">
          <For each={tabsNav()}>
            {(tab) => <MobileTabs items={tab.name} icon={tab.icon} title={t(tab.title)} onClickTab={handleClickTab} />}
          </For>
        </div>
      </Show>
      <Show when={!isMobileView() || isShowDetails()}>
        <main class="relative flex-1">
          <Show when={isMobileView()}>
            <Breadcrumb backLink={handleGoBack} items={breadcrumbItems()} />
          </Show>
          <div class="p-3">
            <Outlet />
          </div>
        </main>
      </Show>
    </div>
  );
};

export default Layout;
