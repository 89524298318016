import { createMemo, Show } from 'solid-js';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { cn } from '~/utils/classnames';
import { currency, isNumber } from '~/utils/number';
import type { Component } from 'solid-js';

export const PaymentAmount: Component<{
  label: string;
  mount?: string | number;
  min?: number;
  max?: number;
  class?: string;
}> = (props) => {
  const isLegalValue = createMemo(() => {
    let result = false;
    const currentMount = Number(props.mount);
    if (!props.min && !props.max) {
      result = true;
    }
    if (props.min && props.max && currentMount >= props.min && currentMount <= props.max) {
      result = true;
    }
    return result;
  });

  return (
    <div class={cn('relative mt-10 flex w-full items-center justify-end bg-pink-100 py-5', props.class)}>
      <img src={ModalBgIcon} class="absolute bottom-0 left-4 h-20 w-52" />
      <div class="mr-4 flex items-center">
        <span class="mr-2 text-xs uppercase lg:text-sm">{props.label}:</span>
        <Show when={isLegalValue()} fallback={<span class="text-sm font-bold text-text-level03 lg:text-2xl">--</span>}>
          <span class="text-sm font-bold text-essential-colour lg:text-2xl">{currency(isNumber(props.mount) ? props.mount : 0)}</span>
        </Show>
      </div>
    </div>
  );
};
