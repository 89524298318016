import { Show, For, createSignal, createMemo } from 'solid-js';
import IconWarning from '~/assets/images/common/linear-warning.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { RingLoader } from '~/components/common/Loaders';
import { BaseModal } from '~/components/modals/BaseModal';
import { ReportStatusIndicator } from '~/components/rental-application/components/StatusIndicators';
import { REPORT_STATUS_MAP, statusIndicatorsArray } from '~/components/rental-application/components/constants';
import { ReportNotReady } from '~/components/rental-application/rental-application-details/infomation/reports/ReportNotReady';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { ReportStatus, TransunionReportType } from '~/swagger/Api';
import { REPORT_LOAD_FAILED_MARK } from '~/utils/constant';

export const TransunionReports = () => {
  const { t } = useLocalization();
  const { applicationStore, previewReport, setApplicationStore, requestReport } = useRentalApplication();

  const [parsedReport, setParsedReport] = createSignal<any>();

  const reportStatus = createMemo(() => applicationStore.application?.reportStatus ?? 'notRequested');

  const reports = createMemo(() => [
    {
      title: t('Credit report'),
      type: TransunionReportType.Credit,
    },
    {
      title: t('Eviction relation proceedings'),
      type: TransunionReportType.Eviction,
    },
    {
      title: t('Criminal background report'),
      type: TransunionReportType.Criminal,
    },
  ]);

  const handleGetReport = async (type: MagicDoor.Api.TransunionReportType) => {
    const report = await previewReport(type);
    setParsedReport(!report || report === REPORT_LOAD_FAILED_MARK ? REPORT_LOAD_FAILED_MARK : report);
  };

  const NotRequested = createMemo(() => {
    const isDisabled = applicationStore.application?.draft || !applicationStore.application?.hasBeenSubmitted;
    return (
      <div class="flex grow flex-col items-stretch justify-between gap-5 rounded-xl border border-partingline bg-white p-6">
        <div class="flex items-center gap-2">
          <div class="text-base font-bold text-text-level01">{t('Report status')}</div>
          <ReportStatusIndicator label={ReportStatus.NotRequested} color="#BEC6DB" />
        </div>
        <Button
          loading={requestReport.loading}
          onClick={() => requestReport()}
          disabled={isDisabled}
          variant="outlined"
          class="flex w-full justify-center text-sm capitalize">
          {t('Request report')}
        </Button>
        <Show when={isDisabled}>
          <div class="text-sm text-text-level02">{t('You can request a report after the application has been submitted')}</div>
        </Show>
      </div>
    );
  });

  return (
    <>
      <Show when={applicationStore.application?.reportStatus !== ReportStatus.NotRequested} fallback={<NotRequested />}>
        <Show when={reportStatus() === ReportStatus.Requested}>
          <div class="flex items-center gap-2 rounded-lg bg-[#f5e2db] p-4 text-warning">
            <IconWarning class="mx-2 size-5 shrink-0" />
            <span class="text-sm">
              {t('Reports may take up to 48 hours to generated, dependent on if manual verification by Transunion is needed')}
            </span>
          </div>
        </Show>
        <div class="flex flex-col divide-y rounded-lg bg-white px-6 py-4">
          <For each={reports()}>
            {(section) => (
              <div class="flex items-center justify-between gap-4 py-6">
                <div class="flex flex-col items-start justify-between gap-1.5">
                  <h2 class="flex-1 text-sm font-medium capitalize text-text-level01">{section.title}</h2>
                  <ReportStatusIndicator
                    {...(statusIndicatorsArray.find(
                      (item) =>
                        item.label.toLowerCase() === REPORT_STATUS_MAP[reportStatus() as keyof typeof REPORT_STATUS_MAP].toLowerCase()
                    ) as (typeof statusIndicatorsArray)[0])}
                  />
                </div>
                <Button
                  onClick={() => handleGetReport(section.type)}
                  disabled={reportStatus() !== ReportStatus.Available}
                  variant="outlined"
                  size="xs"
                  class="shrink-0 rounded-full">
                  {t('View report')}
                </Button>
              </div>
            )}
          </For>
        </div>
      </Show>

      <BaseModal
        class="h-full !w-[960px] max-w-4xl"
        visible={!!applicationStore.currentPreviewReportType}
        onClose={() => {
          setApplicationStore('currentPreviewReportType', undefined);
        }}
        showCloseButton
        title={reports().find((item) => item.type === applicationStore.currentPreviewReportType)?.title}>
        <Show
          when={!previewReport.loading}
          fallback={
            <div class="flex size-full items-center justify-center">
              <RingLoader color="#A126EC" />
            </div>
          }>
          <Show when={parsedReport() !== REPORT_LOAD_FAILED_MARK} fallback={<ReportNotReady />}>
            <div class="p-6">{parsedReport()}</div>
          </Show>
        </Show>
      </BaseModal>
    </>
  );
};
