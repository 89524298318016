import { createMemo, For, Show, createSignal } from 'solid-js';
import DeleteIcon from '~/assets/images/common/delete.svg?component-solid';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import ImgMoveoutBg from '~/assets/images/tenant/moveoutBg.png';
import { Button } from '~/components/common/Buttons';
import { Empty } from '~/components/common/Empty';
import { MessageModal } from '~/components/modals/MessageModal';
import {
  PotentialUnitsModal,
  Unit,
} from '~/components/rental-application/rental-application-details/infomation/potential-units/PotentialUnitsModal';
import { toast } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { validArr } from '~/utils/tool';

interface InfoWithTitleProps {
  title: string;
  children?: any;
  icon?: string;
}

interface UnitItemProps {
  onClick: (id?: string) => void;
  item?: MagicDoor.Api.RentalApplicationInterestDto;
  selected?: boolean;
  onRemove?: (item: MagicDoor.Api.UnitDto) => void;
}

const InfoWithTitle = (props: InfoWithTitleProps) => {
  return (
    <div class="flex items-center gap-3">
      <Show when={props.icon}>
        <div class="flex size-9 items-center justify-center rounded-full border border-partingline">
          <img class="size-5" src={props.icon} />
        </div>
      </Show>
      <div>
        <h3 class="col-span-1 mb-2 text-sm uppercase text-text-level02">{props.title}</h3>
        <Show when={props.children}>
          <div class="col-span-1 text-sm text-text-level01">{props.children}</div>
        </Show>
      </div>
    </div>
  );
};

const UnitItem = (props: UnitItemProps) => {
  const { t } = useLocalization();
  const handleDelete = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.onRemove && props.onRemove(props.item?.unit as MagicDoor.Api.UnitDto);
  };
  return (
    <div
      onClick={() => {
        props.onClick(props.item?.unit.id);
      }}
      class={cn('flex h-fit cursor-pointer select-none flex-wrap items-center justify-between gap-y-4 rounded bg-partingline p-3', {
        'bg-stone-200': props.selected,
      })}>
      <Unit interestUnit={props.item} />
      <div class="grow-1 flex basis-32 items-center border-l pl-3">
        <Button variant="outlined" class="rounded-full p-1 px-3" onClick={handleDelete}>
          <DeleteIcon />
          {t('Remove')}
        </Button>
      </div>
    </div>
  );
};

export const PotentialUnits = () => {
  const { t } = useLocalization();
  const { applicationStore, onTogglePotentialUnitIdsSelect, updatePotentialUnits } = useRentalApplication();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = createSignal<boolean>(false);
  const [currentUnit, setCurrentUnit] = createSignal<MagicDoor.Api.UnitDto | undefined>(undefined);

  const interestedUnits = createMemo(() => {
    const interests = applicationStore.application?.interests;

    if (!interests) return {};
    return {
      favorite: interests[0],
      others: interests.slice(1),
    };
  });

  const onRemovePotentialUnit = (unit: MagicDoor.Api.UnitDto) => {
    setCurrentUnit(unit);
    unit && setShowDeleteConfirmationModal(true);
  };

  const handleConfirmBtnClick = async () => {
    const unitId = currentUnit()?.id;
    if (unitId) {
      await updatePotentialUnits('remove', [unitId]);
    }
    setShowDeleteConfirmationModal(false);
    toast.success(t('{name} has been deleted successfully', { name: t('Unit') }));
  };

  return (
    <div>
      <Show when={interestedUnits().favorite} fallback={<Empty description={t('Tenant has not report this information')} />}>
        <UnitItem
          item={interestedUnits().favorite}
          selected={applicationStore.selectedPotentialUnitIds.has(interestedUnits().favorite?.unit.id as string)}
          onClick={onTogglePotentialUnitIdsSelect}
          onRemove={onRemovePotentialUnit}
        />

        <Show when={validArr(interestedUnits().others)}>
          <div class="my-4">
            <InfoWithTitle title={t(`Other interested units`)} />
          </div>

          <div class="flex flex-col gap-2">
            <For each={interestedUnits().others}>
              {(section) => (
                <UnitItem
                  item={section}
                  selected={applicationStore.selectedPotentialUnitIds.has(section.unit.id)}
                  onClick={onTogglePotentialUnitIdsSelect}
                  onRemove={onRemovePotentialUnit}
                />
              )}
            </For>
          </div>
        </Show>
      </Show>

      <MessageModal
        title={t('Delete unit')}
        visible={!!showDeleteConfirmationModal()}
        loading={applicationStore.updateLoading}
        onClose={() => setShowDeleteConfirmationModal(false)}
        onDone={handleConfirmBtnClick}>
        <img class="h-24 w-full" src={ImgMoveoutBg} />
        <div class="p-6 text-sm leading-5 text-text-level02">
          {t('Please confirm whether to delete ')} {currentUnit()?.name}
        </div>
      </MessageModal>

      <PotentialUnitsModal />
    </div>
  );
};

export const PotentialUnitsActions = () => {
  const { onAddPotentialUnit } = useRentalApplication();
  const { t } = useLocalization();
  return (
    <div>
      <Button variant="outlined" class="rounded-full" size="xs" onClick={() => onAddPotentialUnit()}>
        <PlusIcon />
        {t('Add')}
      </Button>
    </div>
  );
};
