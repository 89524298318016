import { Show } from 'solid-js';
import { Expand } from '~/components/ui/Expand';
import type { JSX } from 'solid-js';

export const Expandable = (props: { title: string; content: JSX.Element; actions?: JSX.Element }) => {
  return (
    <Expand class="flex flex-col rounded-lg bg-white p-6" defaultOpen>
      <Expand.Trigger as="button" class="flex items-center justify-between">
        <div class="font-['Poppins'] text-lg font-semibold text-[#333850]">{props.title}</div>
        <div class="flex items-center gap-2">
          <div
            onPointerUp={(e) => {
              e.stopPropagation();
            }}>
            <Show when={props.actions}>{props.actions}</Show>
          </div>
          <Expand.Indicator />
        </div>
      </Expand.Trigger>
      <Expand.Content>
        <div class="mt-4 border-t border-input-border pt-6">{props.content}</div>
      </Expand.Content>
    </Expand>
  );
};
