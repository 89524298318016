import { Route, Routes } from '@solidjs/router';
import { LeaseRenewalSettingsWrapper } from './lease-renewal';

const LeaseRoutes = () => {
  return (
    <Routes>
      <Route path="/renewal" component={LeaseRenewalSettingsWrapper} />
    </Routes>
  );
};

export default LeaseRoutes;
