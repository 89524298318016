import { mergeProps, Show } from 'solid-js';
import { Portal } from 'solid-js/web';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import { cn } from '~/utils/classnames';
import { useLockBodyScroll } from '~/utils/hooks';
import type { Component, JSXElement } from 'solid-js';

export type BaseModalProps = {
  children?: JSXElement | JSXElement[];
  title?: JSXElement;
  visible: boolean;
  size?: 'sm' | 'md' | 'lg';
  onClose?: () => void;
  showCloseButton?: boolean;
  portal?: HTMLDivElement;
  class?: string;
  closeBtnClass?: string;
  contentClass?: string;
};

export const BaseModal: Component<BaseModalProps> = (originProps) => {
  const props = mergeProps(
    {
      size: 'sm',
    },
    originProps
  );

  useLockBodyScroll(() => props.visible);

  return (
    <Show when={props.visible}>
      <Portal mount={props.portal}>
        <div class="overlay fixed inset-0 z-50 flex animate-fade-in items-center justify-center bg-black/70 py-10">
          <div
            class={cn(
              'relative flex max-h-full flex-col overflow-hidden rounded-lg bg-white text-left shadow',
              {
                'w-11/12 lg:w-modal-sm': props.size === 'sm',
                'w-11/12 lg:w-modal-md': props.size === 'md',
                'w-11/12 lg:w-modal-lg': props.size === 'lg',
              },
              props.class
            )}>
            <Show when={props.title}>
              <div
                class="relative border-b border-partingline py-4 pl-6 pr-16 text-left text-lg font-semibold text-text-level01"
                data-slot="title">
                {props.title}
                <Show when={props.showCloseButton}>
                  <button
                    id="base-modal-close-btn"
                    class="absolute right-6 top-1/2 z-20 -translate-y-1/2 rounded-full bg-white p-1 duration-200 hover:rotate-180 hover:bg-inputbox-bg"
                    type="button"
                    onClick={() => props.onClose && props.onClose()}
                    data-slot="close">
                    <IconClose class={cn('size-8 text-text-level03', props.closeBtnClass)} />
                  </button>
                </Show>
              </div>
            </Show>
            <div class={cn('thinscroll flex flex-1 flex-col overflow-auto', props.contentClass)} data-slot="content">
              {props.children}
            </div>
          </div>
        </div>
      </Portal>
    </Show>
  );
};
