import { createSignal, For, Show } from 'solid-js';
import { produce } from 'solid-js/store';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { uuid } from '~/utils/tool';
import { OwnerPaymentMethodSection } from './OwnerPaymentMethodSection';
import type { JSX } from 'solid-js';
import type { EditingDistribution } from '~/contexts/local';

export interface OwnerPaymentMethodRowProps {
  ownerId: string;
  records: EditingDistribution[];
  isExpanded: boolean;
  columns: { title: string; headerClass: string; width: string; render: (records: EditingDistribution[]) => JSX.Element }[];
}

export const OwnerPaymentMethodRow = (props: OwnerPaymentMethodRowProps) => {
  const { t } = useLocalization();
  const { store, setStore } = useAddOwnerDistributions();
  const [draggingGroupId, setDraggingGroupId] = createSignal('');
  const onCreateEmptyGroup = (sourceGroupId: string) => {
    setStore(
      produce((state) => {
        const sourceGroupIndex = state.editingDistributionGroups.findIndex((group) => group.groupId === sourceGroupId);
        state.editingDistributionGroups.splice(sourceGroupIndex + 1, 0, { ownerId: props.ownerId, groupId: uuid(), propertyIds: [] });
      })
    );
  };

  const onCreateGroupWithProperty = (event: DragEvent) => {
    onDragLeave(event);

    if (!draggingGroupId()) {
      return;
    }

    try {
      const data = event.dataTransfer?.getData('text');
      const { ownerId, propertyId, sourceGroupId } = JSON.parse(data as string);

      if (!ownerId || !propertyId || !sourceGroupId || ownerId !== props.ownerId) {
        return;
      }

      setStore(
        produce((state) => {
          const sourceIndex = state.editingDistributionGroups.findIndex((group) => group.groupId === sourceGroupId);

          state.editingDistributionGroups[sourceIndex].propertyIds =
            state.editingDistributionGroups.at(sourceIndex)?.propertyIds.filter((id) => id !== propertyId) ?? [];
          state.editingDistributionGroups.splice(sourceIndex + 1, 0, { groupId: uuid(), ownerId, propertyIds: [propertyId] });
        })
      );
    } catch {
      // do nothing...
    }
  };

  const onInsertPropertyIntoGroup = (event: DragEvent, targetGroupId: string) => {
    try {
      const data = event.dataTransfer?.getData('text') as string;
      const { ownerId, propertyId, sourceGroupId } = JSON.parse(data);

      if (!ownerId || !propertyId || !sourceGroupId || !targetGroupId || ownerId !== props.ownerId) {
        return;
      }

      setStore(
        produce((state) => {
          const sourceIndex = state.editingDistributionGroups.findIndex((group) => group.groupId === sourceGroupId);
          const targetIndex = state.editingDistributionGroups.findIndex((group) => group.groupId === targetGroupId);

          state.editingDistributionGroups[sourceIndex].propertyIds =
            state.editingDistributionGroups.at(sourceIndex)?.propertyIds.filter((id) => id !== propertyId) ?? [];
          state.editingDistributionGroups[targetIndex]?.propertyIds.push(propertyId);
        })
      );
    } catch {
      // do nothing...
    }
  };

  const onDragEnter = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target || !draggingGroupId()) {
      return;
    }

    target.style.transform = 'scale(1.02)';
    target.style.boxShadow = '0 0 30px rgba(0, 0, 0, 0.1)';
  };

  const onDragLeave = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target) {
      return;
    }

    target.style.transform = '';
    target.style.boxShadow = '';
  };

  return (
    <div class={cn({ 'flex flex-col overflow-hidden rounded-lg border border-input-border': props.isExpanded })}>
      <div
        class={cn(
          'relative flex cursor-pointer items-center rounded-lg border border-input-border bg-input',
          props.isExpanded && '-left-px w-[calc(100%+2px)] rounded-b-none'
        )}>
        <For each={props.columns}>
          {(column) => <div class={cn('px-4 py-3 text-xs text-text-level01', column.width)}>{column.render(props.records)}</div>}
        </For>
      </div>
      <Show when={props.isExpanded}>
        <div class="flex flex-col gap-4 bg-input px-4 py-3">
          <For each={store.editingDistributionGroups.filter((item) => item.ownerId === props.ownerId)}>
            {(group) => (
              <>
                <OwnerPaymentMethodSection
                  groupId={group.groupId}
                  ownerId={props.ownerId}
                  distributions={props.records.filter((distribution) => group.propertyIds.includes(distribution.propertyId))}
                  isOtherSectionDragging={!!draggingGroupId() && draggingGroupId() !== group.groupId}
                  onInsertPropertyIntoGroup={onInsertPropertyIntoGroup}
                  onSetDraggingGroupId={setDraggingGroupId}
                />
                <div
                  class="flex cursor-pointer justify-center rounded-lg border border-dashed border-primary bg-transparent py-[14px] text-xs text-primary transition-all duration-100"
                  onClick={() => onCreateEmptyGroup(group.groupId)}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  onDrop={onCreateGroupWithProperty}>
                  {t('Click or drag property here to create a new payment method')}
                </div>
              </>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};
