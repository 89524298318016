import { createMemo, For } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

export const TabNav = <T extends string>(props: {
  tabs: { label: string; icon?: JSX.Element; key: T; component: JSX.Element | Component }[];
  activeTab: T;
  setActiveTab: (key: T) => void;
}) => {
  const component = createMemo(() => {
    return props.tabs.find((tab) => tab.key === props.activeTab)?.component;
  });

  return (
    <>
      <div class="mb-4 flex items-center justify-between rounded bg-partingline p-1">
        <For each={props.tabs}>
          {(tab) => {
            return (
              <div
                id={`tab-${tab.key}`}
                onClick={() => props.setActiveTab(tab.key)}
                class={cn('flex flex-1 cursor-pointer items-center justify-center gap-1 rounded py-2 text-sm text-text-level01', {
                  'bg-white': props.activeTab === tab.key,
                })}>
                {tab.label}
              </div>
            );
          }}
        </For>
      </div>
      {component()}
    </>
  );
};
