import { createEffect, createSignal, on, Show } from 'solid-js';
import IconAddDistributionBottom from '~/assets/images/owner-funds/addDistributionBottom.svg';
import { handlePrint } from '~/components/checks/PrintCheck';
import { PrintCheckModal } from '~/components/checks/PrintResultModal';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { LabeledOwnerSearch } from '~/components/owner';
import { CustomLevelSearch } from '~/components/search-select/LevelSearchComponents/SearchLevels';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions, useOwnerTransfers } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { PayBillType } from '~/swagger/Api';
import { paymentTypeOptions } from '~/utils/constant';
import { currency } from '~/utils/number';
import { createMutation } from '~/utils/resource';
import type { JSX } from 'solid-js';
import type { Option } from '~/components/common/DropdownMenu';
import type { AddDistributionData } from '~/contexts/local';
import type { HydratedCheckDto } from '~/swagger/Api';

const ExternalFormItemsContainer = (props: { children: JSX.Element }) => {
  return (
    <div class="relative col-span-2 grid grid-cols-2 gap-5 rounded-lg bg-[#FAE9FF] p-4">
      {props.children}
      <div class="absolute left-3/4 top-px size-5 -translate-y-1/2 rotate-45 rounded-md bg-[#FAE9FF]" />
    </div>
  );
};

export const AddDistributionModal = () => {
  const { t } = useLocalization();
  const form = useForm();
  const goBack = useGoBack();
  const { paymentMethodOptions, onSubmitOwnerDistribution, bankAccountOptions, onFetchOwnerPaymentAccounts } = useAddOwnerDistributions();
  const { refetch } = useOwnerTransfers();
  const [ownerPaymentAccounts, setOwnerPaymentAccounts] = createSignal<Option[]>([]);
  const [check, setCheck] = createSignal<HydratedCheckDto>();
  const [forceEmpty, setForceEmpty] = createSignal(false);

  const submit = createMutation(async () => {
    const { validateStatus } = form.validateForm();
    if (validateStatus) {
      const res = await onSubmitOwnerDistribution(form.formStore as AddDistributionData);
      const check = res.printCheck?.at(0)?.check;
      if (check) {
        setCheck(check);
      } else {
        refetch();
        goBack();
      }
    }
  });

  function onDone() {
    handlePrint(check() as HydratedCheckDto);
    goBack();
  }

  createEffect(
    on(
      () => form.formStore.propertyId,
      () => {
        form.resetFields(['ownerId']);
        setForceEmpty(true);
        queueMicrotask(() => setForceEmpty(false));
      }
    )
  );

  createEffect(
    on(
      () => form.formStore.ownerId,
      async (ownerId: string) => {
        form.resetFields(['paymentAccountId']);

        if (!ownerId) {
          return;
        }

        const options = await onFetchOwnerPaymentAccounts(ownerId);
        setOwnerPaymentAccounts(options ?? []);
      }
    )
  );

  return (
    <Show when={!check()} fallback={<PrintCheckModal check={check()} onDone={onDone} />}>
      <Modal
        visible
        onDone={submit}
        doneText={t('Confirm')}
        class="lg:w-modalSm"
        title={t('Add distribution')}
        loading={submit.loading}
        onCancel={() => {
          goBack();
        }}>
        <Form class="grid grid-cols-1 gap-5 p-5 lg:grid-cols-2" defaultForm={form}>
          <FormItem
            component={LabeledLeveledSearchSelect}
            enabledTypes={[CustomLevelSearch.Property]}
            label={t('Property')}
            placeholder={t('Please select')}
            formFieldName="propertyId"
            rules={[{ required: true, message: t('Property is required') }]}
            class="col-span-2 !mb-0"
            onChange={(selected: any[]) => {
              form.setFieldValue('propertyId', selected[0]?.id);
            }}
          />
          <FormItem
            filter={{
              propertyIds: [form?.formStore.propertyId || '0'],
            }}
            component={LabeledOwnerSearch}
            label={t('Select owner')}
            placeholder={t('Please select')}
            formFieldName="ownerId"
            selected={forceEmpty() ? [] : undefined}
            rules={[{ required: true, message: t('Owner is required') }]}
            class="col-span-1 !mb-0"
          />
          <FormItem
            placeholder={t('Please enter')}
            label={t(`Distribution amount`)}
            formFieldName="distributionAmount"
            prepend="$"
            component={LabeledNumberInput}
            rules={[
              {
                message: t(`Please enter`),
                required: true,
              },
              {
                message: t(`The distribution amount must be at least 1`),
                validator: (value: number) => value >= 1,
              },
            ]}
            class="col-span-1 !mb-0"
          />
          <FormItem
            label={t('Bank Account')}
            class="col-span-1 !mb-0"
            placeholder={t('Please select')}
            rules={[{ message: t('Please select'), required: true }]}
            formFieldName="bankAccountId"
            options={bankAccountOptions() ?? []}
            onChangeMethodName="onChange"
            component={DropdownMenu}
          />
          <FormItem
            formFieldName="paymentType"
            label={t('Payment type')}
            options={paymentTypeOptions(t)}
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
            rules={[{ required: true, message: t('Please select') }]}
            class="col-span-1 !mb-0"
          />
          <Show when={form.formStore.paymentType === 'manual'}>
            <ExternalFormItemsContainer>
              <FormItem
                formFieldName="paymentMethod"
                label={t('Payment method')}
                options={paymentMethodOptions}
                buttonClass="bg-white"
                onChangeMethodName="onChange"
                component={DropdownMenu}
                rules={[{ required: true, message: t('Please select') }]}
                placeholder={t('Please select')}
                class="col-span-1 !mb-0"
              />
              <FormItem
                label={t('External transaction ID')}
                placeholder={t('Enter transaction ID')}
                inputContainerClass="bg-white"
                component={LabeledTextInput}
                rules={[{ required: true, message: t('Please enter') }]}
                formFieldName="externalTransactionId"
                class="col-span-1 !mb-0"
              />
            </ExternalFormItemsContainer>
          </Show>
          <Show when={form.formStore.paymentType === PayBillType.Ach}>
            <ExternalFormItemsContainer>
              <FormItem
                formFieldName="paymentAccountId"
                buttonClass="bg-white"
                label={t('Receiving Account')}
                options={ownerPaymentAccounts()}
                onChangeMethodName="onChange"
                component={DropdownMenu}
                rules={[{ required: true, message: t('Please select') }]}
                placeholder={t('Please select')}
                class="col-span-1 !mb-0"
              />
            </ExternalFormItemsContainer>
          </Show>
          <FormItem
            class="col-span-1 !mb-0 lg:col-span-2"
            formFieldName="memo"
            label={t('Memo')}
            component={LabeledTextInput}
            placeholder={t('Please enter')}
          />
        </Form>
        <div class="mt-4 flex h-[76px] items-center justify-between overflow-visible bg-light-pink pr-10">
          <img src={IconAddDistributionBottom} class="h-[calc(100%_+5px)]" />
          <p>
            <span class="mr-1 text-xs text-text-level02">{t('Distribution Amount')}:</span>
            <span class="text-2xl font-bold text-primary">{currency(form.formStore.distributionAmount || 0)}</span>
          </p>
        </div>
      </Modal>
    </Show>
  );
};
