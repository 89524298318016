import { NotFoundError } from '@magicdoor/errors';
import { PDFDocument } from 'pdf-lib';
import { inputAnnotations } from '~/pdfsigner/usecases/types/annotation';
import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { urlWithQuery } from '~/utils/url';
import { PdfLoader } from './pdfLoader';
import type { PDFPage } from 'pdf-lib';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import type { AnnotatedPdfPage } from '~/pdfsigner/usecases/types/annotatedPdfPage';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';
import type { Document } from '~/pdfsigner/usecases/types/document';
import type { LeaseTemplateCategory } from '~/pdfsigner/usecases/types/leaseTemplateCategory';

class LeaseTemplateRepository extends BaseRestRepository {
  public async getLeaseTemplateCategories(): Promise<LeaseTemplateCategory[]> {
    const url = '/api/lease-templates/categories';
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getDataPaths(type?: `${MagicDoor.Api.LeaseTemplateType}`, leaseDocumentId?: string): Promise<DataPathNode[]> {
    const url = urlWithQuery(leaseDocumentId ? `/api/lease-documents/${leaseDocumentId}/data-paths` : '/api/lease-templates/data-paths', {
      type,
    });
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    return json.dataPaths.map(this.mapToDataPathNode);
  }

  public async getLeaseTemplates(): Promise<Document[]> {
    const url = '/api/lease-templates/';
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    const result: Document[] = [];
    for (const docJson of json) {
      result.push(await this.createLeaseTemplateFromJson(docJson));
    }
    return result;
  }

  public async getLeaseTemplate(id: string): Promise<Document> {
    const url = '/api/lease-templates/' + id;
    const response = await this.fetchWithAuth(url);
    const json = await this.getJsonResponse(response);
    return await this.createLeaseTemplateFromJson(json);
  }

  public async saveTemplate(document: Document): Promise<string> {
    let id = document.id;
    if (id === undefined) {
      id = await this.createTemplate(document);
    }
    return this.updateTemplate(id, document);
  }

  public async createTemplate(document: Document): Promise<string> {
    const url = '/api/lease-templates';
    const formData = new FormData();
    const title = document.title || 'unknown';
    const mergedPdfBlob = await this.mergePdfs(document.pdfs);
    formData.append('LeaseTemplateCategoryId', `${document.category?.id}`);
    formData.append('File', mergedPdfBlob, `${title}.pdf`);
    formData.append('Name', title);
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const json = await this.getJsonResponse(response);
    return json.id;
  }

  private async createLeaseTemplateFromJson(json: any): Promise<Document> {
    const data: Document = {
      pdfs: [],
      pdfPages: [],
      signers: [],
      status: DocumentStatus.None,
    };
    data.id = json.id;
    data.category = {
      id: json.leaseTemplateCategoryId,
      name: '',
      isDefault: false,
    };
    data.signers = json.signers.map((signer: any) => ({
      id: signer.id,
      name: signer.name,
      isPropertyManager: signer.type === 'propertyManager',
    }));
    data.title = json.name;
    const fileUrl = `/api${json.signedUrl}`;
    const pdfLoader = new PdfLoader();
    const pdf = await pdfLoader.loadPdf(fileUrl);
    if (!pdf) {
      throw new NotFoundError();
    }
    data.pdfs.push(pdf);
    data.pdfPages = await pdfLoader.getPdfPages(pdf);
    const annotations: any[] = json.annotations;
    annotations.forEach((annotation: any) => {
      const page = data.pdfPages[annotation.page - 1];
      if (!page) {
        return;
      }
      page.annotations.push({
        id: annotation.id,
        x: annotation.x,
        y: annotation.y,
        width: annotation.width,
        height: annotation.height,
        text: annotation.text,
        type: annotation.type,
        dataPathKey: annotation.dataPath,
        signerId: annotation.signerId,
        fontSize: annotation.fontSize,
        isInputField: annotation.isInputField !== undefined ? annotation.isInputField : inputAnnotations.has(annotation.type),
      });
    });
    return data;
  }

  private async updateTemplate(id: string, pdfs: Document): Promise<string> {
    const url = '/api/lease-templates/' + id;
    const signers = pdfs.signers.map((signer) => ({
      id: signer.id,
      name: signer.name,
      type: signer.isPropertyManager ? 'propertyManager' : 'tenant',
    }));
    const body = {
      leaseTemplateCategoryId: pdfs.category?.id,
      name: pdfs.title,
      signers,
      annotations: this.getAnnotationsArray(pdfs.pdfPages),
    };
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    const json = await this.getJsonResponse(response);
    return json.id;
  }

  private getAnnotationsArray(annotatedPdfs: AnnotatedPdfPage[]): any[] {
    return annotatedPdfs.flatMap((page, index) =>
      page.annotations.map((annotation) => ({
        id: annotation.id,
        signerId: annotation.signerId,
        page: index + 1,
        x: annotation.x,
        y: annotation.y,
        width: annotation.width,
        height: annotation.height,
        text: annotation.text,
        type: annotation.type,
        dataPath: annotation.dataPathKey,
        fontSize: annotation.fontSize,
        isInputField: !!annotation.isInputField,
      }))
    );
  }

  private async mergePdfs(pdfs: PDFDocumentProxy[]): Promise<Blob> {
    const mergedPdf = await PDFDocument.create();
    for (const pdf of pdfs) {
      const loadedPDF = await PDFDocument.load(await pdf.getData(), { ignoreEncryption: true });
      const pages = await mergedPdf.copyPages(loadedPDF, loadedPDF.getPageIndices());
      pages.forEach((page: PDFPage) => {
        mergedPdf.addPage(page);
      });
    }
    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: 'application/pdf' });
  }

  private mapToDataPathNode = (node: any): DataPathNode => ({
    key: node.key,
    name: node.name,
    canSelect: node.type !== 'none',
    children: node.children ? node.children.map(this.mapToDataPathNode) : undefined,
    value: node.text,
  });
}

export const leaseTemplateRepository = new LeaseTemplateRepository();
