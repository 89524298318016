import { createMemo, createSignal, For, Show } from 'solid-js';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import IconDelete from '~/assets/images/common/deleteRegular.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import { OwnerPaymentMethodRow } from './OwnerPaymenMethodRow';
import type { EditingDistribution } from '~/contexts/local';

export const OwnerPaymentMethodsListView = () => {
  const { t } = useLocalization();
  const { store, onRemoveOwnerDistributionByOwnerId } = useAddOwnerDistributions();
  const [collapsedOwnerIds, setCollapsedOwnerIds] = createSignal<Set<string>>(new Set());
  const validatedOwnerIds = createMemo(() => [
    ...new Set(
      store.editingDistributions.filter((distribution) => distribution.distributionAmount > 0).map((distribution) => distribution.ownerId)
    ),
  ]);

  const columns = [
    {
      title: t('Owner'),
      width: 'w-[55%]',
      headerClass: 'whitespace-nowrap text-text-level02',
      render: (records: EditingDistribution[]) => (
        <div class="flex items-center gap-3">
          <div class="inline-flex items-center gap-1.5">
            <Avatar name={records.at(0)?.ownerName ?? ''} />
            <span>{records.at(0)?.ownerName}</span>
          </div>
          <Show when={records.length > 1}>
            <span class="shrink-0 truncate text-[#FF7448]">{`${records.length} ${t('Properties')}`}</span>
          </Show>
        </div>
      ),
    },
    {
      title: t('Distribution amount'),
      width: 'w-[30%]',
      headerClass: 'text-right whitespace-nowrap text-text-level02',
      render: (records: EditingDistribution[]) => (
        <span class="block text-right text-[#FF7448]">{currency(records.reduce((acc, cur) => acc + cur.distributionAmount, 0))}</span>
      ),
    },
    {
      title: '',
      width: 'w-[15%]',
      headerClass: 'whitespace-nowrap text-text-level02',
      render: (records: EditingDistribution[]) => (
        <div class="flex items-center justify-end gap-2">
          <div class="w-fit cursor-pointer rounded bg-transparent p-1 transition-all hover-allowed:hover:bg-input-border">
            <CircleArrowIcon
              onClick={() => onToggleExpand(records.at(0)?.ownerId ?? '')}
              class={cn({
                'text-text-level02': collapsedOwnerIds().has(records.at(0)?.ownerId ?? ''),
                'rotate-180 text-primary': !collapsedOwnerIds().has(records.at(0)?.ownerId ?? ''),
              })}
            />
          </div>
          <div class="w-fit cursor-pointer rounded bg-transparent p-1 transition-all hover-allowed:hover:bg-input-border">
            <IconDelete class="text-text-level02" onClick={() => onRemoveOwnerDistributionByOwnerId(records.at(0)?.ownerId)} />
          </div>
        </div>
      ),
    },
  ];

  const onToggleExpand = (ownerId: string) => {
    setCollapsedOwnerIds((old) => {
      const newSet = new Set(old);

      if (newSet.has(ownerId)) {
        newSet.delete(ownerId);
      } else {
        newSet.add(ownerId);
      }

      return newSet;
    });
  };

  return (
    <div class="flex flex-col">
      <div class="flex flex-col gap-4">
        <For each={validatedOwnerIds()}>
          {(ownerId) => (
            <OwnerPaymentMethodRow
              ownerId={ownerId}
              isExpanded={!collapsedOwnerIds().has(ownerId)}
              columns={columns}
              records={store.editingDistributions.filter((item) => item.ownerId === ownerId)}
            />
          )}
        </For>
      </div>
    </div>
  );
};
