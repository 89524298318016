import { createSignal, createEffect, createContext, useContext, splitProps, Show, onCleanup, on } from 'solid-js';
import { Dynamic, Portal } from 'solid-js/web';
import { cn } from '~/utils/classnames';
import { computePosition, observeMove, observeSize } from '~/utils/floating';
import { useOutsideClick } from '~/utils/hooks';
import { debounce, isAsyncFunction } from '~/utils/tool';
import type { ComponentProps, Component, Accessor, Setter, JSX, ValidComponent } from 'solid-js';
import type { Merge } from '~/utils/types';

type PopoverContextValue = {
  id: string;
  open: Accessor<boolean>;
  setOpen: Setter<boolean>;
  container: Accessor<HTMLElement | undefined>;
  portal: Accessor<HTMLElement | undefined>;
  setPortal: Setter<HTMLElement | undefined>;
};

const PopoverContext = createContext<PopoverContextValue>();

const usePopoverContext = () => {
  const context = useContext(PopoverContext);
  if (!context) throw new Error('usePopoverContext must be used within a Popover component');
  return context;
};

type PopoverRenderFunction = (context: Pick<PopoverContextValue, 'open' | 'setOpen'>) => JSX.Element;

const isRenderFunction = (children: JSX.Element | PopoverRenderFunction): children is PopoverRenderFunction => {
  return typeof children === 'function' && children.length > 0;
};

export type PopoverProps = Merge<
  ComponentProps<'div'>,
  {
    defaultOpen?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    onOutsideClick?: (e: MouseEvent) => void;
    children: JSX.Element | PopoverRenderFunction;
  }
>;

const Popover: Component<PopoverProps> = (props) => {
  const [params, rest] = splitProps(props, ['defaultOpen', 'open', 'onOutsideClick', 'children']);
  const [open, setOpen] = createSignal<boolean>(params.defaultOpen ?? false);
  const [container, setContainer] = createSignal<HTMLElement>();
  const [portal, setPortal] = createSignal<HTMLElement>();

  createEffect(() => params.open != null && setOpen(params.open));
  createEffect(on(open, (value) => props.onOpenChange?.(value), { defer: true }));

  useOutsideClick(portal, open, (e, start) => {
    const event = new MouseEvent('click', { bubbles: true, cancelable: true });
    Object.defineProperties(event, {
      target: { value: e.target, writable: false },
      currentTarget: { value: container(), writable: false },
      relatedTarget: { value: start, writable: false },
    });
    params.onOutsideClick?.(event);
    event.defaultPrevented || event.target !== event.relatedTarget || setOpen(false);
  });

  const id = Math.random().toString(36).slice(2);

  const context = { id, open, setOpen, container, portal, setPortal };

  const inner = () => {
    const child = params.children;
    return isRenderFunction(child) ? child(context) : child;
  };

  return (
    <PopoverContext.Provider value={context}>
      <div ref={setContainer} {...rest} data-open={open() ? '' : undefined} aria-controls={id}>
        {inner()}
      </div>
    </PopoverContext.Provider>
  );
};

type PopoverTriggerProps<T extends ValidComponent> = Omit<ComponentProps<T>, 'onClick'> & {
  as?: T;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  class?: string;
};

const PopoverTrigger = <T extends ValidComponent>(props: PopoverTriggerProps<T>) => {
  const [params, rest] = splitProps(props, ['as', 'onClick', 'class']);
  const { open, setOpen } = usePopoverContext();
  const handleClick = async (e: MouseEvent) => {
    if (props.disabled) return;
    if (isAsyncFunction(params.onClick)) {
      await params.onClick?.(e);
    } else {
      params.onClick?.(e);
    }

    e.defaultPrevented || setOpen((prev) => !prev);
  };
  return (
    <Dynamic
      type="button"
      class={cn(open() ? 'expanded' : 'unexpanded', params.class)}
      role="button"
      {...rest}
      component={params.as ?? 'button'}
      onClick={handleClick}
      aria-expanded={open()}
    />
  );
};

type PopoverContentProps<T extends ValidComponent> = Omit<ComponentProps<T>, 'align'> & {
  as?: T;
  align?: 'start' | 'end';
  class?: string;
};

const PopoverContent = <T extends ValidComponent>(props: PopoverContentProps<T>) => {
  const [params, rest] = splitProps(props, ['as', 'align', 'class']);
  const { id, open, container, portal, setPortal } = usePopoverContext();

  const [isKeyboardOpen, setIsKeyboardOpen] = createSignal(false);

  createEffect(() => {
    if (!open()) return;

    const reference = container();
    const floating = portal();
    if (reference == null || floating == null) return;

    floating.id = id;
    floating.role = 'dialog';
    floating.style.position = 'fixed';
    floating.style.zIndex = '99';

    const checkKeyboardVisibility = () => {
      const isKeyboardVisible = window.visualViewport
        ? window.innerHeight > window.visualViewport.height + 150
        : window.innerHeight < window.outerHeight - 150;

      setIsKeyboardOpen(window.innerWidth <= 768 && isKeyboardVisible);
      floating.style.position = isKeyboardOpen() ? 'absolute' : 'fixed';
    };

    const isIOS = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /iphone|ipod|ipad/.test(userAgent) && /safari/.test(userAgent) && !/chrome/.test(userAgent);
    };

    const update = () => {
      checkKeyboardVisibility();

      const alignment =
        params.align ||
        (() => {
          const { left, right } = reference.getBoundingClientRect();
          return left > window.innerWidth - right ? 'end' : 'start';
        })();

      const referenceRect = reference.getBoundingClientRect();
      const floatingRect = floating.getBoundingClientRect();
      const isInput = reference.querySelector('input, textarea, [contenteditable="true"]') !== null;
      const isMobile = window.innerWidth <= 768;

      const position = computePosition(reference, floating, { alignment, allowPlacements: ['top', 'bottom'] });

      let top = referenceRect.top + (position.placement === 'bottom' ? referenceRect.height : -floatingRect.height);
      let left = referenceRect.left + (alignment === 'end' ? referenceRect.width - floatingRect.width : 0);

      const viewportWidth = window.visualViewport?.width ?? window.innerWidth;
      const viewportHeight = window.visualViewport?.height ?? window.innerHeight;
      const offsetTop = window.visualViewport?.offsetTop ?? 0;

      if (left < 0) {
        left = 0;
      } else if (left + floatingRect.width > viewportWidth) {
        left = viewportWidth - floatingRect.width;
      }

      if (isMobile && isInput) {
        if (isIOS() && isKeyboardOpen()) {
          top = referenceRect.bottom + (window.scrollY || document.documentElement.scrollTop);
        } else {
          top = referenceRect.bottom + offsetTop;
        }
      } else if (top < offsetTop) {
        top = referenceRect.bottom;
      } else if (top + floatingRect.height > offsetTop + viewportHeight) {
        top = referenceRect.top - floatingRect.height;
      }

      if (isMobile && window.visualViewport && referenceRect.top > window.visualViewport?.height * 0.6) {
        top = top - floating.offsetHeight - referenceRect.height;
      }

      floating.style.top = `${top}px`;
      floating.style.left = `${left}px`;

      const align = position.alignment == null ? 'center' : position.alignment === 'start' ? 'left' : 'right';
      floating.style.setProperty('--transform-origin', `${align} ${position.placement === 'top' ? 'bottom' : 'top'}`);
      floating.style.setProperty('--reference-width', `${referenceRect.width}px`);
    };

    const debouncedUpdate = debounce(update, 16);

    update();

    window.addEventListener('scroll', debouncedUpdate, true);
    window.addEventListener('resize', debouncedUpdate);

    const cleanupMove = observeMove(reference, debouncedUpdate);
    const cleanupSize = observeSize([reference, floating], debouncedUpdate);

    onCleanup(() => {
      cleanupMove();
      cleanupSize();
      window.removeEventListener('scroll', debouncedUpdate, true);
      window.removeEventListener('resize', debouncedUpdate);
    });
  });

  return (
    <Show when={open()}>
      <Portal ref={setPortal}>
        <Dynamic component={params.as ?? 'div'} {...rest} class={cn('origin-[--transform-origin] animate-zoom-in', params.class)} />
      </Portal>
    </Show>
  );
};

const PopoverWrap = Object.assign(Popover, { Trigger: PopoverTrigger, Content: PopoverContent });

export { PopoverWrap as Popover, PopoverTrigger, PopoverContent };
