import { Outlet } from '@solidjs/router';

const FullScreenLayout = () => {
  return (
    <div class="flex h-dvh">
      <Outlet />
    </div>
  );
};

export default FullScreenLayout;
