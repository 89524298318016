import { BaseRestRepository } from './baseRestRepository';
import type { UploadResultDto } from '~/swagger/Api';

const ATTACHMENTS_URL = '/api/file-attachments/attachment';

type EntityType = `${MagicDoor.Api.EntityType}`;

// 从 request.ts 合并的类型
type UploadParams = {
  fileName?: string;
  params?: Record<string, string>;
};

export class FileRepository extends BaseRestRepository {
  async deleteFile(url: string) {
    return this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  async uploadFileToUrl(url: string, file: File, opts?: UploadParams) {
    const formData = new FormData();
    formData.append(opts?.fileName || 'file', file);

    if (opts?.params) {
      for (const [key, value] of Object.entries(opts.params)) {
        formData.append(key, value);
      }
    }

    return this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async uploadFile(file: File, needProcess: boolean): Promise<Record<string, UploadResultDto>> {
    const url = `/api/files`;
    const formData = new FormData();
    formData.append('file', file);
    needProcess && formData.append('process', 'true');

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public getFile(fileId: string, fileName: string, ext: string): string {
    const url = `/api/files/${fileId}/${fileName}.${ext}`;
    return url;
  }

  public async getAttachments(entityType: EntityType, entityId: string): Promise<MagicDoor.Api.HydratedFileAttachmentDto[]> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addAttachment(
    entityType: EntityType,
    entityId: string,
    file: File,
    description?: string,
    signal?: AbortSignal
  ): Promise<MagicDoor.Api.HydratedFileAttachmentDto> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files`;
    const formData = new FormData();
    formData.append('File', file);
    formData.append('Description', description || '');

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
    });
    return this.getJsonResponse(response);
  }

  public async updateAttachment(
    entityType: EntityType,
    entityId: string,
    fileAttachmentId: string,
    updateData: { fileName: string; description?: string },
    signal?: AbortSignal
  ): Promise<MagicDoor.Api.HydratedFileAttachmentDto> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files/${fileAttachmentId}`;
    const payload = {
      fileName: updateData.fileName,
      description: updateData.description || '',
    };

    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    });
    return this.getJsonResponse(response);
  }

  public async deleteAttachment(entityType: EntityType, entityId: string, fileAttachmentId: string): Promise<void> {
    const url = `${ATTACHMENTS_URL}/${entityType}/${entityId}/files/${fileAttachmentId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }
}

export const fileRepository = new FileRepository();
