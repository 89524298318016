import { createMemo, Show } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import { getTenantFullName } from '~/components/tenants';
import { Skeleton } from '~/components/ui';
import { useRentalApplication, useLocalization } from '~/contexts/global';
import { CommentSection } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import { ApplicantDetails } from './ApplicantDetails';
import { QuickActions } from './QuickActions';
import type { RentalApplicationCommentDto } from '~/swagger/Api';

const getCommentByType = (comments: RentalApplicationCommentDto[] = [], type: CommentSection) => {
  return comments.find((comment) => comment.section === type)?.comment || emptyPlaceholder;
};

const UserComments = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();
  return (
    <div class="pt-6">
      <div class="mb-3 text-base font-semibold text-text-level01">{t('Comments')}</div>
      <p class="text-xs font-medium leading-[18px] text-text-level01">
        {getCommentByType(applicationStore.application?.comments, CommentSection.ApplicantInformation)}
      </p>
    </div>
  );
};

export const BasicInfo = () => {
  const { applicationStore, fetchApplication } = useRentalApplication();
  const { t } = useLocalization();

  const fullName = createMemo(() => {
    return getTenantFullName(applicationStore.application);
  });

  return (
    <div class="top-20 flex shrink-0 basis-80 flex-col gap-6 divide-y self-stretch rounded-lg bg-white p-6 xl:sticky xl:self-start">
      <div class="flex flex-col gap-6">
        <div class="text-base font-bold capitalize text-text-level01">{t('Applicant information')}</div>
        <Show when={!fetchApplication.loading} fallback={<Skeleton />}>
          <div class="flex items-center gap-2">
            <Avatar size="large" name={fullName()} />
            <div class="text-base font-semibold text-text-level01">{fullName()}</div>
          </div>
        </Show>
      </div>

      <QuickActions />

      <ApplicantDetails />
      <UserComments />
    </div>
  );
};
