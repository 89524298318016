import { For } from 'solid-js';
import { Skeleton } from '~/components/ui';
import type { Component } from 'solid-js';

interface LoadingSkeletonProps {
  extraFields?: number;
}

export const LoadingSkeleton: Component<LoadingSkeletonProps> = (props) => (
  <>
    <div class="flex flex-col gap-2 px-5 py-4">
      <Skeleton class="h-6 w-48" />
      <Skeleton class="h-4 w-32" />
      <Skeleton class="h-4 w-32" />
      <For each={Array(props.extraFields ?? 0)}>{() => <Skeleton class="h-4 w-32" />}</For>
    </div>
    <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
      <Skeleton class="h-8 w-48" />
      <Skeleton class="h-8 w-48" />
    </div>
  </>
);
