import { createMemo, Show } from 'solid-js';
import BalancesImg from '~/assets/images/leases/balances.svg';
import IconBalanceBg from '~/assets/images/units/balancesBg.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { isEndedLease, isEvictionLease } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

type BalancesProps = {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto;
};

export const Balances: Component<BalancesProps> = (props) => {
  const { t } = useLocalization();

  const isDangerColor = createMemo(
    () => !isEvictionLease(props?.lease) && !isEndedLease(props?.lease) && !!props.lease?.balance && props.lease?.balance > 0
  );
  const isSuccessColor = createMemo(
    () => !isEvictionLease(props?.lease) && !isEndedLease(props?.lease) && !!props.lease?.balance && props.lease?.balance < 0
  );
  const isInfoColor = createMemo(() => isEvictionLease(props?.lease) || isEndedLease(props?.lease) || props.lease?.balance == 0);
  return (
    <>
      <Panel title={t('Balances')} class={cn('hidden p-2 capitalize md:block', props.class)}>
        <Show
          when={props.lease?.status !== 'draft'}
          fallback={
            <Empty
              imgSrc={BalancesImg}
              class="py-3"
              size="40px"
              descriptionClass="mt-1 text-xs"
              description={t('Available after activating the lease')}
            />
          }>
          <div
            class="relative mt-1 flex items-center justify-center rounded-lg"
            style={{ height: 'calc(100% - 61px)' }}
            classList={{
              'bg-danger-light': isDangerColor(),
              'bg-success-light': isSuccessColor(),
              'bg-[#F3F2F9]': isInfoColor(),
            }}>
            <span
              class="text-xl font-bold"
              classList={{
                'text-red-500': isDangerColor(),
                'text-[#1FC09F]': isSuccessColor(),
                'text-level03': isInfoColor(),
              }}>
              {currency(props.lease?.balance)}
            </span>

            <IconBalanceBg
              class="absolute right-0 top-0"
              classList={{
                'text-success': isSuccessColor(),
                'text-danger': isDangerColor(),
              }}
            />
          </div>
        </Show>
      </Panel>

      <div class={cn('overflow-hidden rounded-lg border border-partingline bg-white p-2 md:hidden', props.class)}>
        <div
          class="flex h-15 items-center justify-between rounded-lg p-3"
          classList={{
            'bg-danger-light': isDangerColor(),
            'bg-success-light': isSuccessColor(),
            'bg-[#F3F2F9]': isInfoColor(),
          }}>
          <div class="font-semibold text-text-level01">{t('Balances')}</div>
          <span
            class="text-xl font-bold"
            classList={{
              'text-red-500': isDangerColor(),
              'text-[#1FC09F]': isSuccessColor(),
              'text-level03': isInfoColor(),
            }}>
            {currency(props.lease?.balance)}
          </span>
        </div>
      </div>
    </>
  );
};
