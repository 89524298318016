import { Show, createMemo } from 'solid-js';
import IconCompanyTimezone from '~/assets/images/settings/company/companyTimezone.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, IconLoader } from '~/components/ui';
import { useLocalization, useCompanySettings } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

type settingsType = {
  subscriptionChartOfAccountId: string;
  start: string;
  end: string;
};

export const CompanyInformationSettings = () => {
  const { t } = useLocalization();

  const { companySettings, changeCompanySettings } = useCompanySettings();

  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'subscriptionChartOfAccountId',
      label: 'Subscription chart of account',
      type: 'chartOfAccountSelect',
    },
    {
      field: 'start',
      label: 'Work hours start',
      type: 'timePicker',
    },
    {
      field: 'end',
      label: 'Work hours end',
      type: 'timePicker',
    },
  ]);

  const onSave = async (data: settingsType) => {
    const param = {
      subscriptionChartOfAccountId: data.subscriptionChartOfAccountId,
      workHours: {
        start: data?.start,
        end: data?.end,
      },
    };
    await changeCompanySettings(param as MagicDoor.Api.CompanySettingsDto);
    toast(t('Company information settings saved successfully'), 'success');
    return;
  };

  return (
    <ThreeSectionBox
      class="h-fit"
      coloredClass="p-3 md:p-4"
      topTitle={<SettingTitle title={t('Company information')} icon={IconCompanyTimezone} />}
      middle={
        <div class="flex flex-col gap-5 px-3 py-5 md:p-4">
          <Show when={!companySettings.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
            <div class="flex flex-col gap-4 text-left">
              <EditForm
                sections={sections()}
                onOk={(data) => onSave(data as settingsType)}
                model={{
                  subscriptionChartOfAccountId: companySettings()?.subscriptionChartOfAccountId,
                  start: companySettings()?.workHours?.start,
                  end: companySettings()?.workHours?.end,
                }}
              />
            </div>
          </Show>
        </div>
      }
    />
  );
};
