import { Show, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import DisplayAddress from '~/components/common/DisplayAddress';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { PropertyImage } from '~/components/properties';
import { Skeleton } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { SearchNode } from '~/components/search-select/Context';
import type { PolymorphicComponent } from '~/utils/types';

export const LevelPropertyTitle: PolymorphicComponent<
  'div',
  {
    class?: string;
    property?: SearchNode<MagicDoor.Api.PropertyDto>;
    addressClass?: string;
  } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const [, rest] = splitProps(props, ['class', 'property']);
  return (
    <TitleWithFeedbackIcon
      {...pickProps(rest)}
      type="properties"
      entityId={props.property?.id}
      initalSelectedIcons={props.property?.icons}
      class="w-full flex-wrap items-start">
      <Dynamic component={props.as || 'div'} class={cn('flex max-w-full items-center gap-2 text-base', props.class)} {...rest}>
        <Show when={props.property} fallback={<Skeleton class="size-12 rounded-md" data-slot="image" />}>
          <PropertyImage class="size-8 rounded-md" property={props.property as { id: string; imageId?: string }} data-slot="image" />
        </Show>
        <Show
          when={props.property}
          fallback={
            <div class="flex flex-col gap-2">
              <Skeleton class="h-7 w-28" />
              <Skeleton class="h-4 w-44" />
            </div>
          }>
          <div class="flex flex-1">
            <div class="flex-1 space-y-0.5 text-left text-sm md:line-clamp-2" data-slot="content">
              <div class="flex items-center gap-2">
                <h4 data-slot="title" class="w-fit">
                  {props.property?.name || emptyPlaceholder}
                </h4>
                <div class="flex items-center justify-center text-xs text-text-level03">
                  (
                  <DisplayAddress
                    class={cn('text-xs font-normal text-text-level03', props.addressClass)}
                    address={props.property?.address}
                    data-slot="address"
                  />
                  )
                </div>
              </div>
            </div>
          </div>
        </Show>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};
