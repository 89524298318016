export enum IconType {
  FACE = 'face',
  LABEL = 'label',
  FUNCTIONAL = 'functional',
}

export const iconsMap: Record<IconType, { unicode: string; label: string }[]> = {
  [IconType.FACE]: [
    { unicode: 'U+1F600', label: 'smiling-face' },
    { unicode: 'U+1F605', label: 'smile-sweat' },
    { unicode: 'U+1F923', label: 'tears-of-joy' },
    { unicode: 'U+1F970', label: 'joyful' },
    { unicode: 'U+1F911', label: 'glittering-greedy' },
    { unicode: 'U+1F914', label: 'reflect-on' },
    { unicode: 'U+1F612', label: 'side-eye' },
    { unicode: 'U+1F925', label: 'lie' },
    { unicode: 'U+1F912', label: 'sick' },
    { unicode: 'U+1F915', label: 'injured' },
    { unicode: 'U+1F973', label: 'celebrate' },
    { unicode: 'U+1F97A', label: 'pitiful' },
    { unicode: 'U+1F628', label: 'panic' },
    { unicode: 'U+1F631', label: 'panic-stricken' },
    { unicode: 'U+1F624', label: 'indignant' },
    { unicode: 'U+1F621', label: 'mad' },
    { unicode: 'U+1F92C', label: 'cursing' },
    { unicode: 'U+1F63A', label: 'cat-smile' },
    { unicode: 'U+1F639', label: 'cat-cry-with-laughter' },
    { unicode: 'U+1F63B', label: 'cat-love' },
    { unicode: 'U+1F63D', label: 'cat-kiss' },
    { unicode: 'U+1F640', label: 'cat-panic' },
    { unicode: 'U+1F63F', label: 'cat-tears' },
    { unicode: 'U+1F648', label: 'monkey-cover-eyes' },
    { unicode: 'U+1F649', label: 'monkey-cover-ears' },
    { unicode: 'U+1F64A', label: 'monkey-cover-mouth' },
  ],
  [IconType.FUNCTIONAL]: [
    { unicode: 'U+1F6AC', label: 'smoke' },
    { unicode: 'U+1F6A8', label: 'warn' },
    { unicode: 'U+1F6B8', label: 'pay-attention-to-children' },
    { unicode: 'U+26D4', label: 'reminder' },
    { unicode: 'U+1F6AB', label: 'forbid' },
    { unicode: 'U+1F17F', label: 'parking-lot' },
    { unicode: 'U+1F198', label: 'SOS' },
    { unicode: 'U+1F6B3', label: 'no-cycling' },
    { unicode: 'U+1F6AD', label: 'no-smoking' },
    { unicode: 'U+1F6B1', label: 'no-water-connection' },
    { unicode: 'U+1F4F5', label: 'no-cellphone' },
    { unicode: 'U+1F51E', label: '18-prohibitions' },
    { unicode: 'U+2622', label: 'radiation' },
    { unicode: 'U+2623', label: 'poisonous' },
    { unicode: 'U+2753', label: 'question-mark' },
    { unicode: 'U+2757', label: 'exclamation-point' },
    { unicode: 'U+1f4b2', label: 'dollar-sign' },
    { unicode: 'U+2678', label: 'recycle' },
    { unicode: 'U+2705', label: 'green-check' },
    { unicode: 'U+2714', label: 'check' },
    { unicode: 'U+274C', label: 'cross' },
    { unicode: 'U+274E', label: 'green-cross' },
    { unicode: 'U+1F194', label: 'id' },
    { unicode: 'U+1F17E', label: 'zero' },
    { unicode: 'U+1F197', label: 'ok' },
    { unicode: 'U+1F501', label: 'exchange' },
    { unicode: 'U+1F506', label: 'sunshine' },
    { unicode: 'U+1F4F6', label: 'signal' },
    { unicode: 'U+1F4F4', label: 'mobile-off' },
    { unicode: 'U+2642', label: 'male' },
    { unicode: 'U+2640', label: 'female' },
    { unicode: 'U+26A7', label: 'gender-neutral' },
    { unicode: 'U+1F303', label: 'city at night' },
  ],
  [IconType.LABEL]: [
    { unicode: 'U+1F5DD', label: 'old-key' },
    { unicode: 'U+1F511', label: 'key' },
    { unicode: 'U+1F510', label: 'lock-and-key' },
    { unicode: 'U+1F50F', label: 'lock-and-pen' },
    { unicode: 'U+1F513', label: 'open-lock' },
    { unicode: 'U+1F512', label: 'lock' },
    { unicode: 'U+2702', label: 'scissor   ' },
    { unicode: 'U+1F4CD', label: 'dot-icon' },
    { unicode: 'U+1F4CB', label: 'document' },
    { unicode: 'U+1F4CC', label: 'pin' },
    { unicode: 'U+1F4BC', label: 'briefcase' },
    { unicode: 'U+1F4DD', label: 'writing' },
    { unicode: 'U+1F5F3', label: 'vote' },
    { unicode: 'U+1F4EE', label: 'get-mail' },
    { unicode: 'U+1F4ED', label: 'no-mail-in-mailbox' },
    { unicode: 'U+1F4EC', label: 'mail-in-mailbox' },
    { unicode: 'U+1F4EB', label: 'closed-mailbox' },
    { unicode: 'U+1F4E9', label: 'download-mail' },
    { unicode: 'U+1F4E8', label: 'collect-mail' },
    { unicode: 'U+1F4E7', label: 'email' },
    { unicode: 'U+2709', label: 'letter' },
    { unicode: 'U+1F9FE', label: 'receipt' },
    { unicode: 'U+1F4B3', label: 'credit-card' },
    { unicode: 'U+1F4B6', label: 'euro-banknotes' },
    { unicode: 'U+1F4B7', label: 'money-from-other-countries' },
    { unicode: 'U+1F4B5', label: 'american-dollar' },
    { unicode: 'U+1F4B4', label: 'china-yuan' },
    { unicode: 'U+1FA99', label: 'coin' },
    { unicode: 'U+1F4B0', label: 'bag-of-money' },
    { unicode: 'U+1F3F7', label: 'label' },
    { unicode: 'U+1FA78', label: 'blood' },
    { unicode: 'U+1F9F2', label: 'magnet' },
    { unicode: 'U+1F9FO', label: 'work-box' },
    { unicode: 'U+2699', label: 'gear' },
    { unicode: 'U+1F529', label: 'nut' },
    { unicode: 'U+1FA9B', label: 'screwdriver' },
    { unicode: 'U+1F527', label: 'spanner' },
    { unicode: 'U+1FA9A', label: 'saw' },
    { unicode: 'U+1F6E0', label: 'tool' },
    { unicode: 'U+1FA93', label: 'axe' },
    { unicode: 'U+1F4A1', label: 'bulb' },
    { unicode: 'U+1F9F1', label: 'brick' },
    { unicode: 'U+1F50C', label: 'plug' },
    { unicode: 'U+1F6AA', label: 'door' },
    { unicode: 'U+1FA9E', label: 'mirror' },
    { unicode: 'U+1FA9F', label: 'window' },
    { unicode: 'U+1F6CF', label: 'bed' },
    { unicode: 'U+1F6CB', label: 'sofa' },
    { unicode: 'U+1FA91', label: 'chair' },
    { unicode: 'U+1F6BD', label: 'toilet' },
    { unicode: 'U+1F6BF', label: 'shower' },
    { unicode: 'U+1F6C1', label: 'bath' },
    { unicode: 'U+1F9FB', label: 'toilet-paper' },
    { unicode: 'U+1F9EF', label: 'extinguisher' },
    { unicode: 'U+1F4A7', label: 'water' },
    { unicode: 'U+1F525', label: 'fire' },
    { unicode: 'U+2744', label: 'snow' },
    { unicode: 'U+26A1', label: 'lightning' },
    { unicode: 'U+1F321', label: 'thermometer' },
    { unicode: 'U+23F3', label: 'hourglass' },
  ],
};

const findUnicodeByLabel = (label: string) => {
  for (const iconType in iconsMap) {
    const icons = iconsMap[iconType as IconType];
    const icon = icons.find((icon) => icon.label === label);

    if (icon) {
      return icon.unicode;
    }
  }

  return '';
};

export function unicodeToEscapeSequence(unicode: string): string {
  const _unicode = unicode.startsWith('U+') ? unicode : findUnicodeByLabel(unicode);
  try {
    if (_unicode) return String.fromCodePoint(parseInt(_unicode.slice(2), 16));
  } catch {
    return '';
  }
  return '';
}
