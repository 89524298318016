import { useParams, useNavigate, Route, Routes } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Panel } from '~/components/common/Panels';
import { toast } from '~/components/ui';
import { Skeleton } from '~/components/ui/Skeleton';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { EditBankAccountsModal } from '~/pages/bank-accounts/list-bank-accounts/EditBankAccountPage';
import { ListExpandContent } from '~/pages/bank-accounts/list-bank-accounts/list-components/ListExpandContent';
import { useBankAccountActions } from '~/pages/bank-accounts/utils/bankAccountActions';
import { AccountDetailsPanel } from './components/AccountDetailsPanel';

export const BankAccountDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useLocalization();
  const { fetchBankAccountById, bankAccount } = useBankAccount();
  const [loading, setLoading] = createSignal<boolean>(false);
  const { handleSettings, handleViewBankReport } = useBankAccountActions();

  const breadcrumbItems = [
    { label: t('Bank accounts'), link: '/accounting/bank-accounts' },
    { label: bankAccount()?.name || t('Bank account details') },
  ];

  const loadBankAccount = async () => {
    try {
      setLoading(true);
      await fetchBankAccountById(params.bankAccountId);
    } catch (error) {
      console.error('Failed to load bank account:', error);
      toast.error('Failed to load bank account');
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    if (params.bankAccountId) {
      loadBankAccount();
    }
  });

  return (
    <>
      <div class="bg-background flex min-h-screen w-full flex-col">
        <Breadcrumb items={breadcrumbItems} backLink="/accounting/bank-accounts" loading={loading()} />
        <div class="flex flex-col gap-6 p-4 md:p-8">
          <div class="flex items-center justify-between">
            <Show when={!loading()} fallback={<Skeleton class="h-8 w-48" />}>
              <h1 class="text-xl font-semibold md:text-2xl">{bankAccount()?.name}</h1>
            </Show>
          </div>

          <AccountDetailsPanel loading={loading()} bankAccount={bankAccount()} />

          <Panel title={t('Connections')} class="space-y-4">
            <ListExpandContent
              onSettingsClick={() => handleSettings(params.bankAccountId)}
              onViewReportClick={() => handleViewBankReport(params.bankAccountId)}
              bankAccount={{ id: params.bankAccountId } as MagicDoor.Api.HydratedBankAccountDto}
              isResponsive={true}
            />
          </Panel>
        </div>
      </div>

      <Routes>
        <Route
          path="edit"
          element={
            <>
              <EditBankAccountsModal
                onClose={() => {
                  navigate(`/accounting/bank-accounts/${params.bankAccountId}`, { scroll: false });
                }}
                source="details"
              />
            </>
          }
        />
      </Routes>
    </>
  );
};
