import { useNavigate } from '@solidjs/router';
import { createMemo, For } from 'solid-js';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import { Panel } from '~/components/common/Panels';
import { IconLink, toast, IconTrash } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';

export const WorkOrderQuickActions = (props: {
  workOrder?: MagicDoor.Api.HydratedWorkOrderDto;
  onBindMaintenanceRequest: () => void;
  onBindVendor: () => void;
}) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { updateWorkOrderStatus, deleteWorkOrder } = useWorkOrders();

  const handleStatusChange = async (id: string, status: `${MagicDoor.Api.Status}`) => {
    await updateWorkOrderStatus(id, {
      status,
    } as MagicDoor.Api.UpdateWorkOrderStatusDto);
    toast.success(t('Operation successful'));
  };

  const quickActions = createMemo(() => [
    {
      title: t(props.workOrder?.maintenanceRequestId ? 'Change maintenance request' : 'Assign maintenance request'),
      icon: IconLink,
      code: 'BindMaintenanceRequest',
      bgColor: '#FDF5FF',
    },
    {
      title: t(props.workOrder?.vendorId ? 'Change vendor' : 'Assign vendor'),
      icon: IconLink,
      code: 'BindVendor',
      bgColor: '#F4FFFD',
    },

    {
      title: t('Close'),
      icon: IconClose,
      code: 'Close',
      bgColor: '#FFF1F6',
    },
    {
      title: t('Delete'),
      icon: IconTrash,
      code: 'Delete',
      bgColor: '#FFE9EE',
    },
  ]);

  const handleQuickAction = async (code: string) => {
    if (!props.workOrder) return;

    switch (code) {
      case 'BindMaintenanceRequest':
        props.onBindMaintenanceRequest();
        break;
      case 'BindVendor':
        props.onBindVendor();
        break;

      case 'Close':
        await handleStatusChange(props.workOrder.id, 'closed');
        break;
      case 'Delete':
        await deleteWorkOrder(props.workOrder.id);
        toast.success(t('{name} has been deleted successfully', { name: t('Work order') }));
        navigate('/maintenance/work-orders');
        break;
    }
  };

  return (
    <Panel title={t('Quick action')}>
      <div class="grid grid-cols-2 gap-4 p-4">
        <For each={quickActions()}>
          {(item) => (
            <div
              onClick={() => handleQuickAction(item.code)}
              class="flex cursor-pointer flex-col items-center justify-center rounded-md p-4 transition-colors hover-allowed:hover:bg-gray-100">
              <div class="flex size-12 items-center justify-center rounded-full" style={{ 'background-color': item.bgColor }}>
                <item.icon class="size-6 text-gray-600" />
              </div>
              <span class="mt-2 text-center text-sm font-medium text-gray-800">{item.title}</span>
            </div>
          )}
        </For>
      </div>
    </Panel>
  );
};
