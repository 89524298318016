import { Route, Routes } from '@solidjs/router';
import { createMemo, createSignal, Show, For } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import ConfirmationModal from '~/components/modals/ConfirmationModal';
import { ListView, TableActions, toast } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { AddAndEditBillModal } from '~/pages/work-orders/work-orders-details/components/AddAndEditBillModal';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';

const FilesComponent = (props: { files: MagicDoor.Api.FileDto[] }) => {
  return (
    <div class="text-xs font-medium text-link">
      <For each={props.files}>
        {(file, index) => (
          <>
            {file.fileName}
            {index() < props.files.length - 1 && <br />}
          </>
        )}
      </For>
    </div>
  );
};

const BillsTable: Component<{
  loading: boolean;
  isDraft: boolean;
  bills?: (MagicDoor.Api.WorkOrderBillDto & { chartOfAccounts?: string; price?: number })[];
}> = (props) => {
  const { t } = useLocalization();
  const { deleteDraftBill, workOrder, convertDraftBill } = useWorkOrders();
  const [alertVisible, setAlertVisible] = createSignal<boolean>(false);

  const removeDraftBill = async (billId: string) => {
    await deleteDraftBill(workOrder()?.id as string, billId);
    toast.success(t('{name} has been deleted successfully', { name: t('Bill') }));
  };

  const convertToBill = async (billId: string) => {
    if (!workOrder()?.vendorId) {
      setAlertVisible(true);
      return;
    }
    try {
      await convertDraftBill(workOrder()?.id as string, billId);
      toast.success(t('Operation successful'));
    } catch {
      toast.error(t('Operation failed, please try again later'));
    }
  };

  return (
    <>
      <ListView
        title={t(props.isDraft ? 'Draft bills' : 'Bills')}
        titleActions={
          <Show when={props.isDraft}>
            <div class="flex items-center gap-2">
              <Button class="ml-auto text-sm capitalize" href="add-draft-bill">
                {t('Add draft bill')}
              </Button>
            </div>
          </Show>
        }
        data={props.bills}
        loading={props.loading}
        skeletonSize={4}
        rowLink={(item) => `${item.id}/edit-draft-bill`}
        columns={[
          {
            title: t('Date'),
            render: (item) => <div>{dateFormat(t('MM/DD/YYYY'), item.billDate)}</div>,
          },

          {
            title: t('Chart of account'),
            render: (item) => <div class="font-medium"> {item.chartOfAccounts}</div>,
          },
          {
            title: t('Files'),
            render: (item) => <FilesComponent files={item?.files ?? []} />,
          },
          {
            title: t('Price'),
            render: (item) => <div class="font-medium"> {currency(item.price)}</div>,
          },
          {
            title: t('Due date'),
            render: (item) => <div>{dateFormat(t('MM/DD/YYYY'), item.dueDate)}</div>,
          },
          {
            title: '',
            class: 'relative',
            render: (item) => (
              <div class="flex items-center gap-2">
                <Show when={props.isDraft}>
                  <Button
                    onClick={() => convertToBill(item.id)}
                    class="cursor-pointer rounded-lg border-none bg-[#FF8618] px-2.5 py-1 text-xs font-medium text-white hover-allowed:hover:bg-[#FF8618]/80">
                    {t('Convert to bill')}
                  </Button>
                </Show>

                <TableActions
                  actions={[
                    {
                      icon: IconEdit,
                      label: t('Edit'),
                      href: `${item.id}/edit-draft-bill`,
                    },
                    {
                      icon: IconTrash,
                      label: t('Delete'),
                      onClick: () => removeDraftBill(item.id),
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
      <Show when={alertVisible()}>
        <ConfirmationModal
          onConfirm={() => setAlertVisible(false)}
          onCancel={() => setAlertVisible(false)}
          cancelText={t('Cancel')}
          confirmText={t('Close')}
          message={t('Please assign vendor first')}
        />
      </Show>
    </>
  );
};

export const BillsTab: Component = () => {
  const { workOrder } = useWorkOrders();

  const draftBills = createMemo(() =>
    workOrder()
      ?.bills?.filter((bill) => !bill.billId)
      .map((bill) => {
        const chartOfAccountIds = bill.lineItems?.map((lineItem) => lineItem.chartOfAccountId);
        const chartOfAccounts = workOrder()
          ?.chartOfAccounts.filter((chartOfAccount) =>
            chartOfAccountIds?.some((chartOfAccountId) => chartOfAccountId === chartOfAccount.id)
          )
          .map((chartOfAccount) => chartOfAccount.name);
        return {
          ...bill,
          chartOfAccounts: chartOfAccounts?.join(','),
          price: bill.lineItems?.reduce((previous, current) => previous + (current.amount || 0), 0),
        };
      })
  );

  const bills = createMemo(() =>
    workOrder()
      ?.bills?.filter((bill) => bill.billId)
      .map((bill) => {
        const chartOfAccountIds = bill.lineItems?.map((lineItem) => lineItem.chartOfAccountId);
        const chartOfAccounts = workOrder()
          ?.chartOfAccounts.filter((chartOfAccount) =>
            chartOfAccountIds?.some((chartOfAccountId) => chartOfAccountId === chartOfAccount.id)
          )
          .map((chartOfAccount) => chartOfAccount.name);
        return {
          ...bill,
          chartOfAccounts: chartOfAccounts?.join(','),
          price: bill.lineItems?.reduce((previous, current) => previous + (current.amount || 0), 0),
        };
      })
  );

  return (
    <>
      <div class="flex flex-col gap-5">
        <BillsTable loading={workOrder.loading} isDraft bills={draftBills()} />
        <BillsTable loading={workOrder.loading} isDraft={false} bills={bills()} />
      </div>

      <Routes>
        <Route path="add-draft-bill" component={AddAndEditBillModal} />
        <Route path=":billId/edit-draft-bill" component={AddAndEditBillModal} />
      </Routes>
    </>
  );
};
