import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import type { Component, JSX } from 'solid-js';
import type { HydratedCheckDto } from '~/swagger/Api';

function getPayto(checkData: HydratedCheckDto) {
  if (checkData.vendor?.name) {
    return checkData.vendor?.name;
  }
  if (checkData.owner?.firstName && checkData.owner?.lastName) {
    return `${checkData.owner?.firstName} ${checkData.owner?.lastName}`;
  }
  return emptyPlaceholder;
}

export const handlePrint = async (checkData: HydratedCheckDto) => {
  const printWindow = window.open('', '__blank', 'width=' + '1024px' + ', height=' + '800px');
  let checkContainers = '';
  const lineItems = checkData.billPayments
    ?.map(
      (bill) => `
    <tr>
     <td>${bill.data?.Property || emptyPlaceholder}</td>
      <td>${bill.data?.Reference || emptyPlaceholder}</td>
      <td>${bill.data?.Memo || emptyPlaceholder}</td>
      <td>$${bill.amount?.toFixed(2) || emptyPlaceholder}</td>
    </tr>`
    )
    .join('');

  checkContainers += `<div class="check-container">
      <div class="top-routing">${checkData.checkNumber}</div>
      <div class="check-header">
        <div>
          <div>${checkData?.company?.name || ''}</div>
          <div>${checkData?.company?.address?.streetAddress1 || ''}</div>
          <div>${checkData?.company?.address?.city || ''}, ${checkData?.company?.address?.state || ''} ${checkData?.company?.address?.zipCode || ''}</div>
        </div>
        <div>Date: ${dateFormat('MM/DD/YYYY', checkData.date)}</div>
      </div>
      <div class="payee-section">
        <div class="half-size">Pay to the order of:</div>
        <div>
          <strong>${getPayto(checkData)}</strong>
        </div>
      </div>
      <div class="payee-section">
        <div class="half-size">This amount:</div>
        <div class="amount-section-wrapper">
          <div> **** ${checkData.amountString}</div>
          <div class="amount-section">
            $${checkData.amount?.toFixed(2)}
          </div>
        </div>
      </div>
      <div class="payee-address">
        ${checkData.vendor?.contact.address?.streetAddress1 || checkData.owner?.address?.streetAddress1 || ''}<br>
        ${checkData.vendor?.contact.address?.city || checkData.owner?.address?.city || ''} ${checkData.vendor?.contact.address?.city ? ',' : ''}
        ${checkData.vendor?.contact.address?.state || checkData.owner?.address?.state || ''} ${checkData.vendor?.contact.address?.zipCode || checkData.owner?.address?.zipCode || ''}
      </div>
      <div class="memo-section">
        ${checkData.memo || emptyPlaceholder}
      </div>
      <div class="micr-font">
        ${checkData.micrCode}
      </div>
      <div class="check-info-wrapper">
        <div class="check-information">
          <div class="date-section">
            <div>Date: ${dateFormat('MM/DD/YYYY', checkData.date)}</div>
            <div>Check: #${checkData.checkNumber}</div>
            <div>Account: ${checkData.bankAccount?.name}</div>
          </div>
          <div class="account-section">
            Pay to: ${checkData.vendor?.name || (checkData.owner?.firstName || checkData.owner?.lastName ? `${checkData.owner?.firstName || ''} ${checkData.owner?.lastName || ''}` : emptyPlaceholder)}
          </div>
        </div>
        <div class="check-routing">${checkData.checkNumber}</div>
      </div>
      <table class="line-items-table">
        <thead>
         <tr>
            <th>Property</th>
            <th>Reference</th>
            <th>Memo</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          ${lineItems}
          <tr>
            <td class="line-items-total" colspan="3">Total:</td>
            <td>$${checkData.amount?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>`;

  printWindow?.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
    @font-face {
            font-family: 'MICR';
            src: url('/fonts/micr-encoding.ttf') format('truetype');
          }

         @page { size: auto;  margin: 0mm; }


      body {
        font-family: Arial, sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
          .check-container {
            width: 1000px;
            margin: 20px auto;
            padding: 30px;
            padding-top: 10px;
            padding-bottom: 20px;
            position: relative;
            page-break-before: always;
                                font-size: 15px;

          }
        .check-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 64px;
        padding: 0 60px;
      }
          .check-header div {
            text-align: left;
          }
          .half-size {
            width: 138px;
            flex-shrink: 0;
          }
        .top-routing {
        text-align: right;
        padding-bottom: 10px;
              padding: 0 60px;
      }
          .payee-section {
            margin: 0 20px 10px 0;
            display: flex;
          }
          .payee-section strong {
            font-size: 16px;
            display: block;
          }
      .amount-section-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
           font-size: 16px;
      }
        .amount-section {
        font-size: 16px;
        font-weight: bold;
              padding: 0 60px;
      }

      .payee-address {
        font-size: 16px;
        margin-left: 80px;
        height: 74px;
      }
        .memo-section {
          margin-top: 20px;
          font-size: 15px;
        }
          .memo-section:before {
            content: 'MEMO';
            display: inline-block;
            margin-right: 10px;
          }
          .date-section {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        gap: 50px;
      }
      .account-section {
        margin-bottom: 10px;
        font-size: 14px;
      }
          .check-info-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 60px;
          }
          .check-number {
            font-size: 20px;
            font-weight: bold;
            position: absolute;
            right: 30px;
            top: 30px;
          }
          .check-routing {
            font-size: 20px;
          }
       .line-items-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
        font-size: 14px;
      }
          .line-items-table th,
          .line-items-table td {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
          }
          .line-items-table th {
            background-color: #f2f2f2;
          }
          .line-items-total {
            text-align: right;
            padding-right: 10px;
            font-weight: bold;
            font-size: 14px;
          }
     .micr-font {
        font-family: 'MICR', monospace;
        font-size: 32px;
        text-align: center;
        margin-top: 30px;
        letter-spacing: 2px;
      }
        </style>
        <title>Print check</title>
      </head>
      <body>
        ${checkContainers}
      </body>
      </html>
      `);

  printWindow?.document.close();

  setTimeout(() => {
    printWindow?.print();
    printWindow?.close();
  }, 500);
};

export const PrintCheck: Component<{ checkData: HydratedCheckDto; children: JSX.Element }> = (props) => {
  return <span onClick={() => handlePrint(props.checkData)}>{props.children}</span>;
};
