import { createEffect, createSignal, on } from 'solid-js';
import { confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BillRepository } from '~/repositories/billRepository';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import type { NewFileReadyToUpload } from '~/components/file-attachments';
import type { BillListFilter } from '~/repositories/billRepository';

const repo = new BillRepository();

export const [BillsProvider, useBills] = createMagicDoorContext('Bills', () => {
  const { t } = useLocalization();
  const [filter, setFilter] = createSignal<BillListFilter>({});

  const [bills, getBills, billsActions] = createTriggerResource(async (filters: BillListFilter) => {
    const pagination = await repo.getBills(filters);
    return {
      items: pagination.items,
      page: pagination.currentPage,
      pageSize: pagination.pageSize,
      totalPages: pagination.totalPages,
      totalCount: pagination.totalCount,
    };
  });

  createEffect(
    on(
      filter,
      (f) => {
        getBills(f);
      },
      { defer: true }
    )
  );

  const [fileList, setFileList] = createSignal<NewFileReadyToUpload[]>([]);
  const [currentId, setCurrentId] = createSignal<string | undefined>(undefined);
  const [current, currentActions] = createLazyResource(currentId, (id) => repo.getBill(id));

  const addBill = createMutation(async (model: MagicDoor.Api.CreateVendorBillDto, callback?: any) => {
    const created = await repo.createBill(model);
    getBills(filter());

    for (const file of fileList()) {
      callback?.(`${t('Uploading')}  ${file.fileName}...`);
      await addBillFile(created.id, file.file, file.description);
    }

    return created;
  });

  const updateBill = createMutation(
    async (billId: string, model: MagicDoor.Api.UpdateVendorBillDto): Promise<MagicDoor.Api.HydratedBillDto> => {
      const updated = await repo.updateBill(billId, model);
      if (billId === currentId()) currentActions.mutate(updated);
      getBills(filter());
      return updated;
    }
  );

  const deleteBill = createMutation(async (billId: string, deleteMethod: MagicDoor.Api.DeleteBillPaymentMethod) => {
    await confirmDeleteBill(async () => {
      await repo.deleteBill(billId, deleteMethod);
    });
  });

  const confirmDeleteBill = (handler: () => Promise<void>) => {
    const { resolve, promise, reject } = Promise.withResolvers();
    confirm({
      title: t('Delete bill'),
      content: (
        <p class="mt-4">
          {t(
            'Deleting {type} will only delete the accounting entries for the {type}, if an ACH payment was sent out it will not be pulled back',
            { type: t('bill') }
          )}
        </p>
      ),
      onResolve: async (confirmed) => {
        if (!confirmed) return reject(undefined);
        try {
          await handler();
          return resolve(undefined);
        } catch (error) {
          return reject(error);
        }
      },
    });
    return promise;
  };

  const deleteBillItem = createMutation(
    async (billId: string, transactionId: string, deleteMethod: MagicDoor.Api.DeleteBillPaymentMethod) => {
      await confirmDeleteBill(async () => {
        await repo.deleteBillItem(billId, transactionId, deleteMethod);
        currentActions.refetch();
      });
    }
  );

  const payBill = createMutation(async (payload: MagicDoor.Api.PayVendorBillsDto) => {
    const result = await repo.payBill(payload);
    return result;
  });

  const addBillFile = createMutation(async (billId: string, file: File, description?: string) => {
    const created = await repo.addBillFile(billId, file, description);
    currentActions.mutate(created);
  });

  const deleteBillFile = createMutation(async (billId: string, fileId: string) => {
    await repo.deleteBillFile(billId, fileId);

    const update = {
      ...current(),
      files: current()?.files?.filter((file) => file.fileId !== fileId),
    };
    currentActions.mutate(update as MagicDoor.Api.HydratedBillDto);
  });

  return {
    get filtered() {
      return bills;
    },
    loadBills() {
      getBills(filter());
    },
    refetch() {
      billsActions.refetch();
    },
    setFilter,
    setCurrentId,
    current,
    addBill,
    updateBill,
    deleteBill,
    deleteBillItem,
    payBill,
    addBillFile,
    deleteBillFile,
    setFileList,
    fileList,
  };
});
