import { createMemo, createSignal, onMount, Show } from 'solid-js';
import { Form, useForm } from '~/components/common/BetterForm';
import { confirm, IconLoader, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BusinessInfoStatusBanner } from '~/pages/settings/company-settings/company-business/BusinessInfoStatus';
import { SaveBtn } from '~/pages/settings/components/edit-form/SaveBtn';
import { companyBusinessInfoRepository } from '~/repositories/companyBusinessInfo';
import { CompanyBusinessInfoStatus } from '~/swagger/Api';
import { createLazyResource } from '~/utils/resource';
import { cloneDeep, isEmptyData } from '~/utils/tool';
import { CompanyBusinessInformation } from './CompanyBusinessInformation';
import { CompanyPrincipalsInformation } from './CompanyPrincipalsInformation';
import { COMPANY_BUSINESS_INFO_STORAGE_KEY } from './storageKey';

const useCache = () => {
  let _cache: any = null;

  const get = () => {
    return cloneDeep(_cache);
  };

  const set = (value: any) => {
    _cache = cloneDeep(value);
  };

  return [get, set] as const;
};

export const CompanyBusiness = () => {
  const { t } = useLocalization();
  const [isDirty, setIsDirty] = createSignal(false);

  const [status, statusAction] = createLazyResource(companyBusinessInfoRepository.getStatus);
  const [getSnapshot, setSnapshot] = useCache();

  const form = useForm({
    onFieldChange: () => {
      setIsDirty(true);
    },
    initialValues: {
      principals: [{ type: 'controlPerson' }],
    },
  });

  const confirmSubmit = () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) {
      return;
    }
    const data = cloneDeep(form.formStore) as MagicDoor.Api.UpdateCompanyBusinessInfoDto;

    confirm({
      title: t('Confirm'),
      content: t('After submission, the status will be changed to "Pending"'),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await onSave(data);
      },
    });
  };

  const formDataToLocalStorage = (data: MagicDoor.Api.UpdateCompanyBusinessInfoDto) => {
    return JSON.stringify(data, (key, value) => {
      if (key.endsWith('FileId')) return '';
      return value;
    });
  };

  const onSave = async (data: MagicDoor.Api.UpdateCompanyBusinessInfoDto) => {
    await companyBusinessInfoRepository.saveBusinessInfo(data);
    const _newCache = formDataToLocalStorage(data);
    localStorage.setItem(COMPANY_BUSINESS_INFO_STORAGE_KEY, _newCache);
    toast.success(t('Business information saved successfully'));
    statusAction.refetch();
    setSnapshot(JSON.parse(_newCache));
    form.setFormStore(JSON.parse(_newCache));
    setIsDirty(false);
  };

  const onCancel = () => {
    if (!isDirty()) return;
    const _initialStore = getSnapshot();
    form.resetFields();
    if (!isEmptyData(_initialStore)) {
      form.setFormStore(_initialStore);
    }
    setIsDirty(false);
  };

  onMount(() => {
    const localData = localStorage.getItem(COMPANY_BUSINESS_INFO_STORAGE_KEY);
    if (localData) {
      form.setFormStore(JSON.parse(localData));
    }
    setSnapshot(form.formStore);
  });

  const content = createMemo(() => {
    const _status = status();
    if (_status?.status === CompanyBusinessInfoStatus.Approved) {
      return <BusinessInfoStatusBanner businessinfoStatus={_status} />;
    }

    return (
      <>
        <div class="border-blue-100 bg-blue-50 text-blue-800 mb-6 space-y-4 rounded-lg border p-6 text-sm">
          <div class="space-y-2">
            <h3 class="font-medium">Bank Account Integration</h3>
            <p>
              MagicDoor partners with Plaid to handle your bank transfers. When you connect with Plaid, a real bank account is created for
              you—one that is always owned by you, never by MagicDoor.
            </p>
          </div>

          <div class="space-y-2">
            <h3 class="font-medium">Information Requirements</h3>
            <p>
              Government regulations require us to collect certain personal information in order to set up this account. However, none of
              this data is shared with the government or any third parties aside from Plaid, which is used solely to facilitate your
              transfers.
            </p>
          </div>

          <div class="space-y-2">
            <h3 class="font-medium">Data Security</h3>
            <p>
              Your data is protected using industry-leading security measures, including encryption and access controls that ensure it
              remains private. We take every precaution to keep your information safe, so you can rest assured that only authorized
              parties—namely Plaid and MagicDoor—can access it.
            </p>
          </div>
        </div>
        <Form defaultForm={form} class="flex w-full flex-col gap-3">
          <Show when={_status}>
            <BusinessInfoStatusBanner businessinfoStatus={_status} />
          </Show>
          <CompanyBusinessInformation />
          <CompanyPrincipalsInformation />
          <SaveBtn
            dirty={isDirty()}
            onSave={confirmSubmit}
            onCancel={onCancel}
            class="overflow-hidden rounded-lg border border-partingline bg-white"
            disableConfirm
          />
        </Form>
      </>
    );
  });

  return (
    <Show when={!status.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
      {content()}
    </Show>
  );
};
