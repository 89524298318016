import { Route, Routes, useNavigate } from '@solidjs/router';
import { VendorTabRoutes } from './VendorTabRoutes';
import { AddVendorModal } from './add-vendor';
import { VendorEditPage } from './edit-vendor';
import { VendorDetailsPage } from './vendor-details';

const VendorRoutes = () => {
  const navigate = useNavigate();
  return (
    <>
      <Routes>
        <Route path="/:vendorId/edit" component={VendorEditPage} matchFilters={{ vendorId: /\d+/ }} />
        <Route path="/:vendorId/*" component={VendorDetailsPage} matchFilters={{ vendorId: /\d+/ }} />
        <Route path="*" component={VendorTabRoutes} />
      </Routes>
      <Routes>
        <Route path="/add" element={<AddVendorModal onClose={() => navigate('maintenance/vendors', { scroll: false })} />} />
      </Routes>
    </>
  );
};

export default VendorRoutes;
