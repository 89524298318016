import { createMemo, mergeProps, Show } from 'solid-js';
import IconBot from '~/assets/images/common/bot.png';
import { cn } from '~/utils/classnames';
import styles from './styles.module.css';
import type { Component, JSX } from 'solid-js';

type RingLoaderProps = {
  class?: string;
  size?: number;
  color?: string;
  borderWidth?: number;
  showIcon?: boolean;
  text?: string;
};

export const RingLoader: Component<RingLoaderProps> = (originProps) => {
  const props = mergeProps(
    {
      color: '#fff',
      size: 20,
      borderWidth: 2,
    },
    originProps
  );
  const childStyle = createMemo(
    () =>
      ({
        width: `${props.size}px`,
        height: `${props.size}px`,
        margin: `${props.borderWidth / 2}px`,
        'border-width': `${props.borderWidth}px`,
        'border-color': `${props.color} transparent transparent transparent`,
      }) as JSX.CSSProperties
  );

  return (
    <div class="flex flex-col items-center justify-center">
      <div
        class={cn(styles['lds-ring'], 'relative overflow-hidden', props.class)}
        style={{ width: `${props.size + props.borderWidth}px`, height: `${props.size + props.borderWidth}px` }}>
        <div style={childStyle()} />
        <div style={childStyle()} />
        <div style={childStyle()} />
        <div style={childStyle()} />
        <Show when={props.showIcon}>
          <img
            class="absolute inset-0 m-auto rounded-full"
            src={IconBot}
            alt="IconBot"
            style={{
              width: `${props.size - props.borderWidth * 2 - 10}px`,
            }}
          />
        </Show>
      </div>
      <Show when={props.text}>
        <div class="mt-2 w-full text-center text-sm text-text-level03">{props.text}</div>
      </Show>
    </div>
  );
};
