import { createMemo, Match, Show, Switch, createSignal } from 'solid-js';
import IconClock from '~/assets/images/bank-account/clock.svg?component-solid';
import IconPlaid from '~/assets/images/bank-account/plaid.svg?component-solid';
import IconStripe from '~/assets/images/bank-account/stripe.svg?component-solid';
import IconWarning from '~/assets/images/common/linear-warning.svg?component-solid';
import ImgPlaid from '~/assets/third-parties/plaidSm.svg';
import ImgStripe from '~/assets/third-parties/stripeSm.svg';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { confirm, toast } from '~/components/ui';
import { Skeleton } from '~/components/ui/Skeleton';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { LimitsList } from '~/pages/bank-accounts/components/LimitsList';
import { LinkPlaidModal } from '~/pages/bank-accounts/list-bank-accounts/list-components/LinkPlaidModal';
import { getStatusBgColor, getStatusCircleColor, getStatusText, getStatusTextColor } from '~/pages/bank-accounts/utils';
import { BankAccountConnectType } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import { createMutation } from '~/utils/resource';
import { upperFirst } from '~/utils/tool';

interface PlaidPanelProps {
  class?: string;
  type: BankAccountConnectType;
  data?: MagicDoor.Api.BankAccountConnectionsDto;
  bankAccountId: string;
  refresh?: () => void;
  loading?: boolean;
}

export const PlaidAndStripePanel = (props: PlaidPanelProps) => {
  const { t } = useLocalization();
  const { bankAccountConnections, plaidSweeps } = useBankAccount();

  const data = createMemo(() => props.data?.[props.type]);

  const [linkPlaidModalVisible, setLinkPlaidModalVisible] = createSignal(false);

  const isEligible = createMemo(() => {
    return data()?.eligible;
  });

  const handleLink = createMutation(async (data = {}) => {
    await bankAccountConnections(
      {
        bankAccountId: props.bankAccountId,
        type: props.type,
        ...data,
      },
      false
    );
    props.refresh && props.refresh();
  });

  const handleClick = () => {
    if (props.type === BankAccountConnectType.Plaid) {
      setLinkPlaidModalVisible(true);
    } else {
      handleLink();
    }
  };

  const handleSweep = async () => {
    confirm({
      icon: () => <IconWarning class="my-1 size-5 text-warning" />,
      title: t('Sweep'),
      titleClass: 'text-warning',
      content: t('Are you sure you want to perform this operation? '),
      doneText: t('Confirm'),
      class: 'w-[380px]',
      async onResolve(confirmed) {
        if (!confirmed) return;
        await plaidSweeps(props.bankAccountId);
        toast.success(t('Operation successful'));
        props.refresh && (await props.refresh());
      },
    });
  };

  const connectButton = (
    <Tooltip disabled={isEligible()} message={data()?.notEligibleReason} class="cursor-pointer" align="top">
      <Button
        loading={handleLink.loading}
        disabled={handleLink.loading || !isEligible()}
        onClick={handleClick}
        size="xs"
        class="rounded-full border-none bg-[#4B73FF] hover-allowed:hover:bg-[#4B73FF]/90">
        <Show
          when={props.type === 'plaid'}
          fallback={
            <>
              <img src={ImgStripe} />
              <span>{t('Link Stripe')}</span>
            </>
          }>
          <img src={ImgPlaid} />
          <span>{t('Link Plaid')}</span>
        </Show>
      </Button>
    </Tooltip>
  );

  return (
    <Panel
      class="flex flex-col border-[#E5E5F4] bg-[#F9FAFC]"
      headerClass="border-[#E5E5F4]"
      title={
        <div class="flex items-center gap-4">
          <div class="flex items-center gap-0.5">
            <Show when={props.type === 'plaid'} fallback={<IconStripe />}>
              <IconPlaid />
            </Show>
            <span class="text-sm text-[#4B73FF]">{upperFirst(props.type)}</span>
          </div>
          <Show when={!props.loading && data()?.status === 'approved' && props.type === 'plaid'}>
            <Tooltip
              message={
                <LimitsList
                  titleClass="w-modal-sm"
                  class="text-nowrap text-white"
                  limits={(data() as MagicDoor.Api.BankAccountPlaidConnectionDto)?.limits ?? undefined}
                />
              }>
              <div class="flex cursor-pointer items-center gap-1">
                <span class="text-xs font-normal text-[#666666]">{t('Limits')}</span>
                <IconWarning class="size-[14px] text-[#666666]" />
              </div>
            </Tooltip>
          </Show>
        </div>
      }
      actions={
        <Show when={!props.loading} fallback={<Skeleton class="h-6 w-20" />}>
          <div class={cn('flex items-center gap-1 rounded-xl px-2 py-0.5', getStatusBgColor(data()?.status))}>
            <Show
              when={data()?.status === 'pending'}
              fallback={<div class={cn('size-1.5 rounded-full', getStatusCircleColor(data()?.status))} />}>
              <IconClock class="size-4 text-light-warning" />
            </Show>
            <span class={cn('text-xs', getStatusTextColor(data()?.status))}>{t(getStatusText(data()?.status))}</span>
          </div>
        </Show>
      }>
      <div class="flex w-full flex-1 flex-col items-center justify-center gap-2 px-5 py-3 text-sm">
        <Show
          when={!props.loading}
          fallback={
            <div class="flex w-full flex-col gap-2">
              <Skeleton class="h-6 w-full" />
              <Skeleton class="h-6 w-full" />
            </div>
          }>
          <Switch>
            <Match when={!data()?.status}>
              <div class="flex size-full items-center justify-center">{connectButton}</div>
            </Match>
            <Match when={data()?.status === 'pending'}>
              <div class="flex w-full items-center justify-center gap-1 py-5 text-[#333]">
                <Show when={props.type === 'plaid'} fallback={connectButton}>
                  <IconClock class="size-5 text-light-warning" />
                  {t('Your account is awaiting approval')}
                </Show>
              </div>
            </Match>

            <Match when={data()?.status === 'approved'}>
              <div class="flex w-full items-center justify-between text-text-level01">
                <span>{t('Pending')}</span>
                <span>{currency(data()?.balance?.pending)}</span>
              </div>
              <div class="flex w-full items-center justify-between text-text-level01">
                <span>{t('Available')}</span>
                <div class="flex items-center gap-2">
                  <span>{currency(data()?.balance?.available)}</span>
                  <Show when={data()?.balance?.available && props.type === 'plaid'}>
                    <button
                      onClick={handleSweep}
                      class="rounded-lg bg-essential-colour px-2 py-1 text-white hover-allowed:hover:bg-essential-colour/90">
                      {t('Sweep')}
                    </button>
                  </Show>
                </div>
              </div>
            </Match>
            <Match when={data()?.status === 'denied'}>
              <div class="w-full py-5 text-center text-light-danger">
                {data()?.deniedReason ||
                  t('Your {name} link has been rejected, please contact support', {
                    name: upperFirst(props.type),
                  })}
              </div>
            </Match>
          </Switch>
        </Show>
      </div>
      <LinkPlaidModal visible={linkPlaidModalVisible()} onCancel={() => setLinkPlaidModalVisible(false)} onDone={handleLink} />
    </Panel>
  );
};
