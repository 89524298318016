import { For, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import { FileItem } from './FileItem';
import { useUpload } from './context';

export const FileList = function () {
  const { fileList } = useUpload();

  return (
    <Show when={fileList().length}>
      <div class={cn('flex min-w-0 flex-wrap gap-1')}>
        <For each={fileList()}>{(file) => <FileItem file={file} />}</For>
      </div>
    </Show>
  );
};
