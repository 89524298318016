import { createMemo, For, untrack } from 'solid-js';
import { safeCall } from '~/utils/tool';
import { copyStore, getValueFromStore, useFormContext } from './context';
import { FormListContext } from './formListContext';
import { transFormNamePath } from './utils';
import type { BetterForm } from './type';

export const FormList = (props: BetterForm.FormListProps) => {
  const formContext = useFormContext();
  if (!formContext) throw new Error('FormList must be used within a FormWrapper');
  const val = createMemo(() => (getValueFromStore(formContext.formStore, props.formFieldName) || []) as unknown[]);

  const onDelete = (index: number) => {
    const arr = copyStore(val() as any[]);
    if (index >= arr.length) return;
    arr.splice(index, 1);
    formContext.setFieldValue(props.formFieldName, arr, false);
  };

  const itemsGroups = createMemo(() => {
    if (!props.renderItemsGroup) return null;

    return (
      <For each={val()}>
        {(item, index) => (
          <FormListContext.Provider
            value={{
              formFieldName: props.formFieldName,
              autoCombineFieldName: props.autoCombineFieldName || true,
              index: untrack(() => index()),
            }}>
            {safeCall(props.renderItemsGroup, {
              remove: onDelete.bind(null, index()),
              item,
              index,
            })}
          </FormListContext.Provider>
        )}
      </For>
    );
  });

  const children = createMemo(() => {
    if (!props.children) return null;
    return props.children({
      val,
      add() {
        const arr = copyStore(val());
        arr.push({});
        formContext.setFieldValue(props.formFieldName, arr, false);
      },
      delete: onDelete,
      prefix: transFormNamePath(props.formFieldName),
    });
  });

  return (
    <div class={props.class}>
      <div class={props.itemsGroupsClass}>{itemsGroups()}</div>
      {children()}
    </div>
  );
};
