import dayjs from 'dayjs';
import { createMemo, createSignal, For, Match, Show, Switch } from 'solid-js';
import { produce } from 'solid-js/store';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Empty } from '~/components/common/Empty';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RingLoader } from '~/components/common/Loaders';
import Tooltip from '~/components/common/Tooltip';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { MAX_INPUT_AMOUNT_2 } from '~/utils/constant';
import { currency } from '~/utils/number';
import { FeeDetailsTable } from './FeeDetailsTable';
import { RevenueCalculationTable } from './RevenueCalculationTable';
import { dateTimeRangeOptions } from './store';
import type { ManagementFeeForm, ManagementFeeSubForm, ManagementFee } from './types';
import type { Component } from 'solid-js';

export interface ManagementFeeItemProps {
  isDefaultOpen?: boolean;
  data: MagicDoor.Api.ManagementFeesPropertyDto;
  form: ManagementFeeForm;
  originalIndex: number;
  onChange: (data: { key: keyof ManagementFee; value: any }) => void;
}

const ManagementFeeItem = (props: ManagementFeeItemProps) => {
  const [isOpen, setIsOpen] = createSignal(props.isDefaultOpen ?? false);
  const { t } = useLocalization();

  return (
    <div class="mb-4 min-w-fit overflow-hidden rounded-lg border">
      <div onClick={() => setIsOpen((isOpen) => !isOpen)} class="flex cursor-pointer items-center bg-input py-2.5">
        <div class="w-[320px] px-4">
          <Tooltip
            message={<DisplayAddress class="overflow-visible whitespace-normal" address={props.data.property?.address} />}
            align="bottom">
            <PropertyTitle
              class="[&>img]:size-9 [&_h4]:text-sm"
              addressClass="w-64"
              property={props.data.property}
              feedbackIconReadonly={true}
            />
          </Tooltip>
        </div>
        <div class="w-[240px] px-4" onClick={(e) => e.stopPropagation()}>
          <LabeledTextInput
            inputClass="bg-white text-xs"
            value={props.data.memo}
            onInput={(value) => props.onChange({ key: 'memo', value })}
            placeholder={t('Please enter')}
          />
        </div>
        <div class="w-[100px] px-4 text-right text-xs text-text-level01">
          {currency((props.data.credits || 0) - (props.data.debits || 0))}
        </div>
        <div class="w-[180px] px-4 text-xs text-text-level01">
          {props.data.feeDateRange?.start && props.data.feeDateRange?.end
            ? `${dayjs(props.data.feeDateRange?.start).format('MM/DD/YYYY')} - ${dayjs(props.data.feeDateRange?.end).format('MM/DD/YYYY')}`
            : t('Never')}
        </div>
        <div class="flex w-[160px] items-center justify-end gap-2 px-4" onClick={(e) => e.stopPropagation()}>
          <LabeledNumberInput
            class="w-32"
            min={0}
            inputClass="bg-white text-right text-xs"
            onInput={(value) => props.onChange({ key: 'feeAmount', value })}
            value={props.form.managementFees[props.originalIndex]?.feeAmount}
            placeholder={t('Please enter')}
            max={MAX_INPUT_AMOUNT_2}
          />
        </div>
        <div class="w-[52px] px-4">
          <CircleArrowIcon
            classList={{
              'text-text-level02': !isOpen(),
              'text-primary rotate-180': isOpen(),
            }}
          />
        </div>
      </div>
      <div
        class="flex-start flex flex-nowrap gap-[30px] px-8 py-4 transition-all [&>div]:grow"
        classList={{ hidden: !isOpen(), block: isOpen() }}>
        <RevenueCalculationTable property={props.data.property} fee={props.data} />
        <FeeDetailsTable fee={props.data} feeAmount={props.form.managementFees[props.originalIndex]?.feeAmount} />
      </div>
    </div>
  );
};

const FeeCalculationForm: Component<ManagementFeeSubForm> = (props) => {
  const { t } = useLocalization();
  const managementFessByMonth = createMemo(() => {
    const arrayByMonth: {
      startAt: string;
      records: { data: MagicDoor.Api.ManagementFeesPropertyDto; originalIndex: number }[];
    }[] = [];

    props.store.managementFees?.forEach((record, index) => {
      const foundIndex = arrayByMonth.findIndex((item) => item.startAt === record.feeDateRange?.start);

      if (foundIndex === -1) {
        arrayByMonth.push({
          startAt: record.feeDateRange?.start as string,
          records: [{ data: record, originalIndex: index }],
        });
      } else {
        arrayByMonth[foundIndex] = {
          ...arrayByMonth[foundIndex],
          records: [...arrayByMonth[foundIndex].records, { data: record, originalIndex: index }],
        };
      }
    });

    return arrayByMonth.reverse();
  });

  const handlePaymentTotalPerMonth = (records: { data: MagicDoor.Api.ManagementFeesPropertyDto; originalIndex: number }[]) => {
    return records.reduce((acc, cur) => {
      return acc + Number(props.form.managementFees[cur.originalIndex]?.feeAmount ?? 0);
    }, 0);
  };

  return (
    <div class="thinscroll mx-auto flex min-w-[710px] flex-col gap-5 overflow-x-auto">
      <Switch>
        <Match when={!props.store.managementFeesLoading && props.store.managementFees?.length !== 0}>
          <div>
            <div class="mb-4 text-xs text-text-level02">{t('Management fees will not be added until you click save here.')}</div>
            <div class="flex flex-col gap-9">
              <For each={managementFessByMonth()}>
                {(item, itemIndex) => (
                  <div>
                    <div class="flex items-baseline border-b border-partingline pb-3">
                      <h4 class="text-base font-semibold">{t('Bills of {month}', { month: t(dayjs(item.startAt).format('MMMM')) })}</h4>
                      <Show when={itemIndex() === 0}>
                        <span class="ml-3 text-xs text-text-level02">
                          {`${t('Run as of')}: ${dateTimeRangeOptions.find((option) => option.value === props.form.dateTimeRange)?.label}`}
                        </span>
                      </Show>
                    </div>
                    <div class="my-4 flex min-w-fit text-xs font-normal text-slate-400">
                      <span class="w-[320px] px-4">{t('Property')}</span>
                      <span class="w-[240px] px-4">{t('Memo')}</span>
                      <span class="w-[100px] px-4 text-right">{t('Revenue')}</span>
                      <span class="w-[180px] px-4">{t('Fee date range')}</span>
                      <span class="w-[160px] px-4 text-right">{t('Charge amount')}</span>
                      <span class="w-[52px] px-4 text-right" />
                    </div>
                    <For each={item.records}>
                      {(record, recordIndex) => (
                        <ManagementFeeItem
                          isDefaultOpen={itemIndex() === 0 && recordIndex() === 0}
                          data={record.data}
                          originalIndex={record.originalIndex}
                          onChange={(arg) => {
                            const { value, key } = arg;

                            props.setFormData(
                              produce((state) => {
                                (state.managementFees[record.originalIndex][key] as any) = value;
                              })
                            );
                          }}
                          form={props.form}
                        />
                      )}
                    </For>
                    <div class="mt-8 flex items-center justify-end text-sm text-text-level02">
                      <div class="text-sm uppercase text-text-level02">
                        {t('Payment total')}:{' '}
                        <span class="text-base font-semibold text-essential-colour">
                          {currency(handlePaymentTotalPerMonth(item.records))}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </For>
            </div>
          </div>
        </Match>
        <Match when={props.store.managementFeesLoading}>
          <div class="flex items-center justify-center">
            <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
          </div>
        </Match>
        <Match when={props.store.managementFees?.length === 0 && !props.store.managementFeesLoading}>
          <Empty description={t('No results found')} />
        </Match>
      </Switch>
    </div>
  );
};

export default FeeCalculationForm;
