import { LinkButton } from '~/components/common/Buttons';
import { IconCircleChevronRight } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { RentalApplicationPaymentAccountProvider } from '~/contexts/local';
import { RentalApplicationListView } from './list-components/RentalApplicationListView';

export const DraftRentalApplicationsPage = () => {
  const { t } = useLocalization();

  return (
    <RentalApplicationPaymentAccountProvider>
      <RentalApplicationListView
        class="size-full"
        title={
          <div class="flex items-center gap-3">
            {t('Draft rental applications')}
            <LinkButton
              href="/leasing/rental-applications"
              class="whitespace-nowrap px-3 py-1 text-xs font-normal"
              rounded="full"
              variant="outlined">
              {t('View rental applications')}
              <IconCircleChevronRight class="size-3 shrink-0 text-essential-colour" />
            </LinkButton>
          </div>
        }
        filter={{ hasBeenSubmitted: false, hasName: true }}
      />
    </RentalApplicationPaymentAccountProvider>
  );
};
