import { useNavigate } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import UploadIcon from '~/assets/images/common/upload.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { RentalInfomationModal } from '~/components/rental-application/rental-application-details/infomation/rental-information/RentalInfomationModal';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currentUpdateType, emptyPlaceholder } from '~/utils/constant';
import type { JSX } from 'solid-js';
const KeyValueItem = (props: { itemKey: string; itemValue: JSX.Element; labelClass?: string; valueClass?: string }) => {
  return (
    <div class="flex items-center gap-2 text-sm font-normal leading-tight text-text-level02">
      <span class={props.labelClass}>{props.itemKey}</span>
      <div class={cn('grow text-right font-medium text-text-level01', props.valueClass)}>{props.itemValue ?? emptyPlaceholder}</div>
    </div>
  );
};

// const Box = (props: ParentProps<{ memo?: string }>) => {
//   return (
//     <div class="mt-3 flex flex-col gap-4 divide-y rounded-lg bg-light-gray p-4">
//       {props.children}
//       <div class="mt-2 pt-4 text-sm font-normal leading-tight text-text-level02">
//         <div class="mb-4 text-sm font-semibold text-text-level01">Comment</div>
//         {props.memo || emptyPlaceholder}
//       </div>
//     </div>
//   );
// };

export const RenterInformation = () => {
  const { applicationStore } = useRentalApplication();
  const { t } = useLocalization();

  const questions = createMemo(() => applicationStore.application?.questions ?? {});

  return (
    <div class="flex grow flex-col space-y-6 divide-y">
      <Show when={Object.keys(questions()).length > 0} fallback={<div class="text-sm text-text-level03">{t('No questions asked')}</div>}>
        <div class="flex flex-col gap-2 py-4">
          <For each={Object.entries(questions())}>
            {([key, value]) => <KeyValueItem itemKey={key} itemValue={value ?? emptyPlaceholder} />}
          </For>
        </div>
      </Show>

      {/* <div class="pt-3">
        <div class="text-base font-semibold leading-tight text-title-gray">Pets</div>

        <Show when={applicationStore.application?.pets?.length} fallback={<Empty description={t('No pets')} />}>
          <For each={applicationStore.application?.pets}>
            {(pet) => {
              return (
                <Box memo={'--'}>
                  <div class="flex gap-2">
                    <div class="grid grow grid-cols-2 gap-4">
                      <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Pet name')} itemValue={pet.name} />
                      <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Gender')} itemValue={pet.gender} />
                      <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Breed')} itemValue={pet.breed} />
                      <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Weight(lbs)')} itemValue={pet.weight} />
                      <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Age')} itemValue={pet.age} />
                    </div>
                  </div>
                </Box>
              );
            }}
          </For>
        </Show>
      </div> */}

      {/* <div class="pt-3">
        <div class="text-base font-semibold leading-tight text-title-gray">Cars</div>

        <Show when={false} fallback={<Empty description={t('No cars')} />}>
          <For each={[]}>
            {(car) => {
              return (
                <Box memo={'--'}>
                  <div class="grid grow grid-cols-2 gap-4">
                    <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Car maker')} itemValue="Car" />
                    <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Model')} itemValue="Car" />
                    <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Year')} itemValue="Car" />
                    <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('License plate')} itemValue="Car" />
                    <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('State registration')} itemValue="Car" />
                    <KeyValueItem labelClass="w-32" valueClass="text-left" itemKey={t('Color')} itemValue="Car" />
                  </div>
                </Box>
              );
            }}
          </For>
        </Show>
      </div> */}
      <RentalInfomationModal />
    </div>
  );
};

export const RenterInformationActions = () => {
  const { applicationStore, setApplicationStore, isNewApplicant } = useRentalApplication();
  const { t } = useLocalization();
  const navigate = useNavigate();

  return (
    <div class="flex gap-2">
      <Show when={!isNewApplicant()}>
        <Button
          variant="outlined"
          class="rounded-full"
          size="xs"
          onClick={() => navigate(`/users/tenants/${applicationStore.application?.tenant?.id}`)}>
          {t('View tenant detail')}
        </Button>
      </Show>
      <Button
        variant="outlined"
        class="rounded-full"
        size="xs"
        onClick={() => setApplicationStore({ currentUpdateType: currentUpdateType.rentalInfo })}>
        <UploadIcon />
        {t('Update')}
      </Button>
    </div>
  );
};
