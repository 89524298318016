import { createMemo, splitProps, Show } from 'solid-js';
import IconFilledCheck from '~/assets/images/common/filledCheck.svg?component-solid';
import { cn } from '~/utils/classnames';
import { uuid, safeCall } from '~/utils/tool';
import type { Component, JSX } from 'solid-js';

type CheckboxProps = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'onInput'> & {
  class?: string;
  id?: string;
  label?: JSX.Element;
  onInput?: (checked: boolean) => void;
  labelClass?: string;
  labelStyle?: Record<string, string>;
  showLabel?: boolean;
  checkBoxClass?: string;
  htmlType?: 'radio' | 'checkbox';
  labelPosition?: 'left' | 'right';
};

export const Checkbox: Component<CheckboxProps> = (props) => {
  const id = createMemo(() => props.id || uuid());
  const [localProps, inputProps] = splitProps(props, ['id', 'label', 'class', 'htmlType', 'labelPosition']);

  return (
    <div class={cn('inline-flex shrink-0 select-none items-center gap-2 overflow-hidden', props.checkBoxClass)}>
      <Show when={localProps.labelPosition === 'left'}>
        <Show when={props.showLabel && props.label}>
          <label
            for={id()}
            class={cn('cursor-pointer truncate', { 'cursor-not-allowed': props.disabled }, props.labelClass)}
            style={props.labelStyle}>
            {props.label}
          </label>
        </Show>
      </Show>
      <label class="relative flex items-center" for={id()} data-ripple-dark="true">
        <input
          type={localProps.htmlType || 'checkbox'}
          class={cn(
            "before:content[''] border-blue-gray-200 before:bg-blue-gray-500 peer relative size-5 cursor-pointer appearance-none rounded-md border transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:size-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-essential-colour checked:bg-essential-colour hover-allowed:hover:before:opacity-10",
            { 'rounded-full checked:bg-white': props.htmlType === 'radio' },
            { 'cursor-not-allowed': props.disabled },
            { 'border-input-border bg-input-border checked:border-input-border checked:bg-input-border': props.disabled },
            props.class
          )}
          id={id()}
          {...inputProps}
          onInput={(ev) => safeCall(props.onInput, ev.target.checked)}
        />
        <Show
          when={props.htmlType === 'radio'}
          fallback={
            <div class="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
              <IconFilledCheck />
            </div>
          }>
          <i class="pointer-events-none absolute left-2/4 top-2/4 size-2 -translate-x-2/4 -translate-y-2/4 rounded-full bg-essential-colour opacity-0 peer-checked:opacity-100" />
        </Show>
      </label>
      <Show when={localProps.labelPosition !== 'left'}>
        <Show when={props.showLabel && props.label}>
          <label
            for={id()}
            class={cn(
              'cursor-pointer truncate',
              { 'order-1 ml-0 mr-4': localProps.labelPosition === 'left' },
              { 'cursor-not-allowed': props.disabled },
              props.labelClass
            )}
            style={props.labelStyle}>
            {props.label}
          </label>
        </Show>
      </Show>
    </div>
  );
};
