import { createMemo, For, Show } from 'solid-js';
import { Section } from '~/components/common/FormSectionTitle';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { MAX_INPUT_AMOUNT_2 } from '~/utils/constant';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';
import type { EditingDistribution } from '~/contexts/local';

export interface DistributionRowProps {
  columns: {
    title: string;
    class?: string;
    render: (record: MagicDoor.Api.CalculatedDistributionsForPropertyDto) => JSX.Element | string;
  }[];
  record: MagicDoor.Api.CalculatedDistributionsForPropertyDto;
  isLoading: boolean;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const DistributionRow = (props: DistributionRowProps) => {
  const { t } = useLocalization();
  const { store, updateOwnerDistributionRecord } = useAddOwnerDistributions();
  const totalDistributedAmount = createMemo(() => {
    const recordsUnderCurrentProperty = store.editingDistributions.filter((record) => record.propertyId === props.record.property?.id);

    return recordsUnderCurrentProperty.reduce((acc, cur) => {
      return acc + cur.distributionAmount;
    }, 0);
  });

  const remainingAmount = createMemo(() => (props.record.availableForDistribution ?? 0) - totalDistributedAmount());

  const getEditingFieldData = (ownerId: string | undefined, key: keyof EditingDistribution): any => {
    if (!ownerId) {
      return undefined;
    }
    const data = store.editingDistributions.find((record) => record.propertyId === props.record.property?.id && record.ownerId === ownerId);

    return data?.[key];
  };

  const ownerColumns = [
    {
      title: t('Owner'),
      headerClass: 'whitespace-nowrap w-1/3 bg-cell-purple-bg text-cell-purple-text',
      render: (item: MagicDoor.Api.DistributionOwnerDto) => (
        <span class="whitespace-nowrap">{`${item.owner?.firstName} ${item.owner?.lastName || ''}`}</span>
      ),
    },
    {
      title: t('Percentage'),
      headerClass: 'whitespace-nowrap bg-cell-purple-bg text-cell-purple-text',
      render: (item: MagicDoor.Api.DistributionOwnerDto) => `${((item.ownershipPercentage ?? 0) * 100).toFixed(2)}%`,
    },
    {
      title: t('Amount'),
      headerClass: 'whitespace-nowrap w-[180px] bg-cell-purple-bg text-cell-purple-text',
      render: (item: MagicDoor.Api.DistributionOwnerDto) => (
        <LabeledNumberInput
          min={0}
          isBlur={false}
          value={getEditingFieldData(item.owner?.id, 'distributionAmount') as number}
          placeholder={t('Please input amount')}
          onInput={(value) =>
            updateOwnerDistributionRecord({
              ownerId: item.owner?.id,
              propertyId: props.record.property?.id,
              payload: { distributionAmount: value || 0 },
            })
          }
          max={MAX_INPUT_AMOUNT_2}
        />
      ),
    },
  ];

  return (
    <div class={cn({ 'flex flex-col overflow-hidden rounded-lg border border-input-border': props.isExpanded })}>
      <div
        class={cn(
          'relative flex cursor-pointer items-center rounded-lg border border-input-border bg-input',
          props.isExpanded && '-left-px -top-px w-[calc(100%+2px)]'
        )}
        onClick={() => props.onToggleExpand}>
        <For each={props.columns}>{(column) => <div class={cn('p-4', column.class)}>{column.render(props.record)}</div>}</For>
      </div>
      <Show when={props.isExpanded}>
        <div class="px-6 pb-4 pt-2.5">
          <Section title={t('Owner distributions')} class="mb-0">
            <div class="thinscroll overflow-auto rounded-lg border border-input-border">
              <Table columns={ownerColumns} data={props.record.distributionOwners} />
            </div>
            <div class="flex flex-col items-end pt-2.5 text-right text-xs text-text-level02">
              <div class="mb-1 normal-case">{`${t('To be distributed')}: ${currency(props.record.availableForDistribution ?? 0)}`}</div>
              <div class="border-input-borer mb-2.5 w-[203px] border-b pb-2">
                {`${t('Distributed')}: ${currency(totalDistributedAmount())}`}
              </div>
              <div
                class={cn('text-sm', {
                  'text-danger': remainingAmount() < 0,
                  'text-warning': remainingAmount() > 0,
                  'text-green': remainingAmount() === 0,
                })}>
                {`${t('Remaining')}: ${currency(remainingAmount())}`}
              </div>
            </div>
          </Section>
        </div>
      </Show>
    </div>
  );
};
