import { useNavigate } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import IconDelete from '~/assets/images/common/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Panel } from '~/components/common/Panels/Panel';
import { Skeleton, DropdownActions, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { VendorAndPropertyManager } from '~/pages/ai-runbooks/runbooks-details/components/RunbookSummary';
import { RunbookTitle } from '~/pages/ai-runbooks/runbooks-details/components/RunbookTitle';
import type { Component } from 'solid-js';

enum RunBookActionExits {
  AskQuestion = 'askQuestion',
  CloseRequest = 'closeRequest',
  CreateWorkOrder = 'createWorkOrder',
  HandOverToHuman = 'handOverToHuman',
  ReselectRunBook = 'reselectRunBook',
}

const ACTION_EXIT_LABELS: Record<RunBookActionExits, string> = {
  [RunBookActionExits.AskQuestion]: 'Ask question',
  [RunBookActionExits.CloseRequest]: 'Close request',
  [RunBookActionExits.CreateWorkOrder]: 'Create work order',
  [RunBookActionExits.HandOverToHuman]: 'Hand over to human',
  [RunBookActionExits.ReselectRunBook]: 'Reselect runbook',
};

export const RunbookOverviewTab: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { runBook, deleteRunBook } = useRunBooks();

  const sumAIResults = createMemo(() => {
    let total = 0;
    const groupsByTypes = Object.entries(RunBookActionExits).reduce(
      (acc, [key, value]) => {
        const count = runBook()?.actionExitCounts[value] ?? '-';
        if (typeof count === 'number') {
          total += count;
        }
        acc.push({
          type: key,
          label: t(ACTION_EXIT_LABELS[value as RunBookActionExits]),
          count,
        });
        return acc;
      },
      [] as Array<{ type: string; label: string; count: string | number }>
    );
    return {
      total,
      groupsByTypes,
    };
  });

  const handleDeleteRunBook = async () => {
    await deleteRunBook(runBook()?.id as string);
    toast.success(t('{name} has been deleted successfully', { name: t('AI Runbooks') }));
    navigate('/maintenance/ai-runbooks');
  };

  return (
    <div class="flex flex-col-reverse gap-4 lg:flex-row">
      <div class="flex flex-1 flex-col gap-5">
        <RunbookTitle
          actions={
            <Show when={runBook()}>
              <DropdownActions
                actions={[
                  {
                    icon: IconDelete,
                    label: t('Delete'),
                    onClick: handleDeleteRunBook,
                  },
                  {
                    label: t('Edit'),
                    icon: IconPencil,
                    onClick: () => navigate(`/maintenance/ai-runbooks/${runBook()?.id}/edit`),
                  },
                ]}
              />
            </Show>
          }
        />
        <Panel
          title={t(`The results of AI`)}
          actions={
            <span class="text-text-level03">
              {t('Total')}: <span class="text-lg font-medium text-text-level01">{sumAIResults().total}</span>
            </span>
          }>
          <div class="grid grid-cols-2 gap-5 px-5 py-6 sm:grid-cols-3 lg:grid-cols-5">
            <For each={sumAIResults().groupsByTypes}>
              {(item) => (
                <div class="col-span-1 flex flex-col items-center justify-center gap-2">
                  <div class="text-lg font-medium">{item.count}</div>
                  <div class="text-center text-sm text-text-level02">{item.label}</div>
                </div>
              )}
            </For>
          </div>
        </Panel>

        <Panel title={t('Description')}>
          <div class="flex flex-col px-3 py-6 lg:px-5">
            <div class="rounded-lg bg-light-gray p-3 lg:p-5">
              <Show
                when={runBook.loading}
                fallback={<div class="break-words font-medium normal-case text-title-gray">{runBook()?.description}</div>}>
                <Skeleton />
                <Skeleton class="mt-1 w-2/3" />
              </Show>
            </div>
          </div>
        </Panel>
      </div>
      <div class="w-full lg:w-[267px]">
        <VendorAndPropertyManager />
      </div>
    </div>
  );
};
