import { A } from '@solidjs/router';
import { Show } from 'solid-js';
import IconChatTime from '~/assets/images/chat/chatTime.svg?component-solid';
import IconTextMsg from '~/assets/images/chat/sendText.svg?component-solid';
import IconWarning from '~/assets/images/common/linear-warning.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import { useChatsSettings } from '~/contexts/local';
import { convertTo12HourFormat } from '~/utils/date';

interface MessageStatusProps {
  message: MagicDoor.Api.ChatMessageDto;
}

export const MessageStatus = (props: MessageStatusProps) => {
  const { t } = useLocalization();

  const { chatsSettings } = useChatsSettings();

  const getFormattedTime = () => {
    const endTime = chatsSettings()?.quitHours?.end;
    return endTime ? convertTo12HourFormat(endTime.toString()) : '';
  };

  return (
    <Show when={props.message.textStatus === 'notSent' && chatsSettings()?.quitHours?.end}>
      <div class="flex items-center gap-2">
        <A
          class="flex cursor-help items-center gap-1 text-[#BDBDBD] hover-allowed:hover:underline"
          href="/settings/chats/chat"
          target="_blank">
          <IconChatTime />
          <span>{t('Off time')}</span>
        </A>
        <div class="flex items-center gap-1 rounded-full bg-[#F46E0C]/10 px-2 py-1 text-xs text-[#F46E0C]">
          <Show when={props.message.textStatus === 'notSent'}>
            <IconTextMsg />
          </Show>

          <span>
            {t('Scheduled to send at')} {getFormattedTime()}
          </span>
        </div>

        <Show when={props.message.textStatus === 'failed'}>
          <div class="flex items-center gap-1 rounded-full bg-error/10 px-2 py-1 text-xs text-error">
            <IconWarning class="size-4 text-error" />
            <span class="capitalize">{props.message.textFailedReason}</span>
          </div>
        </Show>
      </div>
    </Show>
  );
};
