import { createEffect, createMemo, createSignal, mergeProps, on } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { fileRepository } from '~/repositories/fileRepository';
import { contextCreator } from '~/utils/contextCreator';
import { createMutation } from '~/utils/resource';
import { deepEqual } from '~/utils/tool';
import { DeleteStatus, UploadStatus } from './Interface';
import { getUid } from './utils';
import type { HydrateFile, UploadProps } from './Interface';
import type { Accessor } from 'solid-js';
import type { PreviewFile } from '~/components/file-attachments/FilePreviewModal';

export const [UploadProvider, useUpload] = contextCreator('Upload', (props: UploadProps) => {
  const [fileList, setFileList] = createSignal<HydrateFile[]>([]);
  const [preview, setPreview] = createSignal<PreviewFile>();
  const { t } = useLocalization();
  const uploadProps = mergeProps({ needProcess: true, usePreUpload: true, maxSize: 100 }, props);

  const removeFilesIfReachTheLimit = (files: File[]) => {
    if (uploadProps.maxCount) {
      const delta = files.length + fileList().length - uploadProps.maxCount;

      if (delta > 0) {
        toast.error(t('You can only upload up to {max} files at a time', { max: uploadProps.maxCount }));
        return files.slice(0, -delta);
      }
    }
    return files;
  };

  const handleDropFiles = async (files: FileList) => {
    if (files.length === 0) return;

    const _files = removeFilesIfReachTheLimit(Array.from(files));

    const fileUrl = (file: File) => URL.createObjectURL(file);

    setFileList(
      fileList().concat(
        _files.map((file) => ({
          fileId: getUid(),
          fileName: file.name,
          originalFile: file,
          fileUrl: fileUrl(file),
          uploaded: UploadStatus.IDLE,
        }))
      )
    );
    uploadProps.onFilesListChange?.(fileList());

    if (uploadProps.usePreUpload) {
      batchUpload();
    }
  };

  const onFileRemove = async (file: HydrateFile) => {
    if (!file.originalFile) {
      const entityFilePrefixURL = entityFilesURL();
      const fileIndex = fileList().findIndex((f) => f.fileId === file.fileId);
      if (entityFilePrefixURL) {
        try {
          setFileList(fileList().toSpliced(fileIndex, 1, { ...file, deleted: DeleteStatus.DELETING }));
          await fileRepository.deleteFile(`${entityFilePrefixURL}/${file.fileId}`);
          setFileList(fileList().toSpliced(fileIndex, 1, { ...file, deleted: DeleteStatus.DELETED }));
        } catch {
          setFileList(fileList().toSpliced(fileIndex, 1, { ...file, deleted: DeleteStatus.FAILED }));
        }
      }
    }

    setFileList(fileList().filter((f) => f.fileId !== file.fileId));
    uploadProps.onFilesListChange?.(fileList());
  };

  const onFileRetry = (file: HydrateFile) => {
    const index = fileList().findIndex((f) => f.fileId === file.fileId);
    if (index === -1) return;

    setFileList(fileList().toSpliced(index, 1, { ...file, uploaded: UploadStatus.UPLOADING }));
    doUpload(fileList, index);
  };

  const entityFilesURL = createMemo(() => {
    if (uploadProps.entityFilePrefixURL) return uploadProps.entityFilePrefixURL;
    return undefined;
  });

  const needUpload = (file: HydrateFile): file is HydrateFile & { originalFile: File } => {
    return !!file.originalFile && file.uploaded === UploadStatus.IDLE;
  };

  const batchUpload = () => {
    for (const [index, file] of fileList().entries()) {
      if (!needUpload(file)) continue;
      setFileList(fileList().toSpliced(index, 1, { ...file, uploaded: UploadStatus.UPLOADING }));
      doUpload(fileList, index);
    }
  };

  const doUpload = createMutation(async (files: Accessor<HydrateFile[]>, index: number) => {
    const file = files()[index];

    let newFiles = files();

    uploadProps.onBeforeUpload?.(file.originalFile!);
    uploadProps.onUploadingChange?.(true);

    try {
      const {
        file: { fileId, signedUrl },
      } = await fileRepository.uploadFile(file.originalFile!, uploadProps.needProcess || true);

      if (file.fileUrl?.startsWith('blob:')) {
        URL.revokeObjectURL(file.fileUrl);
      }

      const nextFile = { ...file, uploaded: UploadStatus.UPLOADED, fileId, fileUrl: signedUrl };

      uploadProps.onAfterUpload?.(nextFile);

      newFiles = files().toSpliced(index, 1, nextFile);
    } catch {
      newFiles = files().toSpliced(index, 1, { ...file, uploaded: UploadStatus.FAILED });
    } finally {
      uploadProps.onUploadingChange?.(false);
    }

    setFileList(newFiles);
  });

  const _defaultFileList = createMemo(() => {
    return (uploadProps.defaultFileList ?? []).map((file) => Object.assign(file, { uploaded: UploadStatus.UPLOADED })) as HydrateFile[];
  });

  const hasFileNotUpload = createMemo(() => {
    return fileList().some((file) => file.uploaded && [UploadStatus.UPLOADING, UploadStatus.IDLE].includes(file.uploaded));
  });

  createEffect(
    on(
      () => props.defaultFileList,
      (val, preVal) => {
        if (val?.length && !preVal && !deepEqual(val, preVal)) {
          setFileList(_defaultFileList().concat(fileList()));
        }
      }
    )
  );

  createEffect(
    on(fileList, () => {
      if (deepEqual(fileList(), _defaultFileList())) return;
      if (hasFileNotUpload()) return;
      if (!uploadProps.usePreUpload) return;
      uploadProps.onInput?.(fileList().filter((file) => file.uploaded === UploadStatus.UPLOADED));
    })
  );

  createEffect(
    on([() => uploadProps.controlled, () => uploadProps.value], (state, preState) => {
      const [controlled, value] = state;
      if (controlled && !deepEqual(preState, state)) {
        const _valueInHydrateFile = controlled(value);
        setFileList(fileList().filter((file) => _valueInHydrateFile.some((f) => f.fileId === file.fileId)));
      }
    })
  );

  return {
    uploadProps,
    handleDropFiles,
    onFileRemove,
    entityFilesURL,
    fileList,
    setFileList,
    onFileRetry,
    preview,
    setPreview,
    doUpload,
  };
});
