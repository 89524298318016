import { Outlet } from '@solidjs/router';
import { TabLinks } from '~/components/ui/Tabs/TabLinks';
import { useLocalization } from '~/contexts/global';

export const MaintenanceRequestEntry = () => {
  const { t } = useLocalization();
  const items = [
    {
      label: t('Maintenance requests'),
      href: '/maintenance/maintenance-request',
    },
    {
      label: t('Categories'),
      href: '/maintenance/maintenance-request/categories',
    },
  ];
  return (
    <div class="flex size-full flex-col gap-4 p-8">
      <TabLinks class="rounded-lg border border-partingline bg-white" items={items} />
      <Outlet />
    </div>
  );
};
