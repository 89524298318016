import { createMemo, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseTemplateRepository } from '~/repositories/leaseTemplateRepository';
import { createLazyResource, createMutation } from '~/utils/resource';
import type { CreateLeaseTemplateDto } from '~/repositories/leaseTemplateRepository';

const repo = new LeaseTemplateRepository();

export const [LeaseTemplateProvider, useLeaseTemplate] = createMagicDoorContext('LeaseTemplate', () => {
  const [leasetemplates, { fetch, refetch, mutate }] = createLazyResource(async () => {
    return repo.getLeaseTemplates();
  });

  const [currentId, setCurrentId] = createSignal<string>();

  const current = createMemo(() => repo.getLeaseTemplate(currentId() || ''));

  const addLeaseTemplate = createMutation(async (params: CreateLeaseTemplateDto) => {
    const created = await repo.createLeaseTemplate(params);
    mutate((prev) => [...(prev ?? []), created]);
    return created;
  });

  const updateLeaseTemplate = createMutation(async (leaseTemplateId: string, model: MagicDoor.Api.UpdateLeaseTemplateDto) => {
    const updated = await repo.updateLeaseTemplate(leaseTemplateId, model);
    mutate((prev) => prev?.map((t) => (t.id === leaseTemplateId ? updated : t)));
  });

  const deleteLeaseTemplate = createMutation(async (leaseTemplateId: string) => {
    await repo.deleteLeaseTemplate(leaseTemplateId);
    mutate((prev) => prev?.filter((t) => t.id !== leaseTemplateId));
  });

  const addLeaseTemplateAttachment = createMutation(async (leaseTemplateId: string, file: File) => {
    const created = await repo.addLeaseTemplateAttachment(leaseTemplateId, file);
    mutate((prev) => prev && [created, ...prev]);
  });

  return {
    get leasetemplates() {
      fetch();
      return leasetemplates;
    },
    get loading() {
      fetch();
      return () => leasetemplates.loading;
    },
    refetch,
    setCurrentId,
    current,
    addLeaseTemplate,
    currentId,
    updateLeaseTemplate,
    addLeaseTemplateAttachment,
    deleteLeaseTemplate,
  };
});
