import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createMemo, onMount, Show } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RingLoader } from '~/components/common/Loaders';
import { Modal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { maintenanceRequestCategoryRepository } from '~/repositories/categoryRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';

export const CategoryModal = () => {
  const form = useForm();
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { id } = useParams();
  const { categoriesActions } = useMaintenanceRequests();

  const [category, getCategory] = createTriggerResource(
    maintenanceRequestCategoryRepository.getCategory.bind(maintenanceRequestCategoryRepository)
  );

  const handleDone = createMutation(async () => {
    const { validateStatus } = form.validateForm();
    if (validateStatus) {
      if (id) {
        await maintenanceRequestCategoryRepository.updateCategory(id, form.formStore);
      } else {
        await maintenanceRequestCategoryRepository.createCategory({
          name: form.formStore.name,
          description: form.formStore.description,
        });
      }

      toast.success(t('Category saved successfully'));

      categoriesActions.refetch();

      navigate(-1);
    }
  });

  onMount(() => {
    if (id) {
      getCategory(id);
    }
  });

  createEffect(() => {
    const currentCategory = category();
    if (currentCategory) {
      form.setFormStore({
        name: currentCategory.name,
        description: currentCategory.description,
      });
    }
  });

  const modalTitle = createMemo(() => {
    if (id) {
      return t('Edit category');
    }
    return t('Add a new category');
  });

  return (
    <Modal
      title={modalTitle()}
      visible={true}
      loading={handleDone.loading}
      onCancel={() => {
        navigate(-1);
      }}
      onDone={handleDone}
      confirmation={false}>
      <Show fallback={<RingLoader class="mx-auto my-10 h-8" color="#A126EC" />} when={!id || category()}>
        <Form defaultForm={form} class="flex flex-col gap-6 p-6">
          <FormItem
            label={t('Name')}
            formFieldName="name"
            component={LabeledTextInput}
            rules={[
              { required: true, message: t('{name} is required', { name: t('Name') }) },
              { length: 150, message: t('{name} must be {length} characters or less', { name: t('Name'), length: '150' }) },
            ]}
          />
          <FormItem
            label={t('Description')}
            formFieldName="description"
            rules={[
              { required: true, message: t('{name} is required', { name: t('Description') }) },
              { length: 2000, message: t('{name} must be {length} characters or less', { name: t('Description'), length: '2000' }) },
            ]}
            component={LabeledTextArea}
          />
        </Form>
      </Show>
    </Modal>
  );
};
