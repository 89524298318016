import { createMemo, createSignal, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRentPayment from '~/assets/images/settings/property/propertyRentPayment.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { Html, IconLoader, confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRentPaymentProcessingFeesSettings } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const RentPaymentProcessingFeesSettings = () => {
  const { t } = useLocalization();
  const { allSettings, updateSettings, updateLevelSettings, deleteLevelSettings } = useRentPaymentProcessingFeesSettings();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  const sections = createMemo<ItemProps[]>(() => {
    return [
      {
        field: 'propertyManagerPaysProcessingFees',
        label: 'Pay the payment processing fee on behalf of tenants',
        type: 'boolean',
        class: 'col-span-2',
        labelClass: 'flex-1 md:flex-none md:w-1/2',
        labelContainerClass:
          'flex-row gap-5 items-center flex-wrap md:items-start justify-between border border-[#E5E6F8] px-3 py-2 rounded-lg md:border-none md:p-0',
        description:
          'When enabled, you will cover the $2.49 processing fee instead of your tenants. The total fees will be billed to you at the end of each month.',
      },
      {
        field: 'propertyManagerPaysProcessingFeesChartOfAccountId',
        label: 'Payment processing chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description: 'Select the chart of account where payment processing fees should be recorded.',
      },
      {
        field: 'propertyManagerProcessingFeeChartOfAccountId',
        label: 'Failed payment processing chart of account',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
        description:
          'When a payment fails (e.g., due to insufficient funds), we need to recover the processing fee. Select the chart of account where these recovery charges should be recorded.',
      },
      {
        field: 'propertyManagerProcessingFee',
        label: 'Property manager processing fee',
        type: 'number',
        prepend: '$',
        class: 'col-span-2',
        labelClass: 'w-full md:w-1/2',
        labelContainerClass: 'flex-row justify-between flex-wrap gap-5',
        inputContainerClass: 'h-10',
        description:
          'Set an additional processing fee that will be charged to tenants when they pay online. This fee will be deposited into your account but will not be visible to property owners. This is separate from the standard $2.49 processing fee.',
      },
    ];
  });

  const handleSave = async (data: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto) => {
    await updateSettings(data);
    toast.success(t('Rent payment processing fees settings saved successfully'));
  };

  const handleCreateOverride = async (level: string, levelId: string) => {
    await updateLevelSettings(
      level as MagicDoor.Api.SettingsLevel,
      levelId,
      allSettings()?.settings ?? ({} as MagicDoor.Api.RentPaymentProcessingFeesSettingsDto)
    );
    toast.success(t('Rent payment processing fees override created successfully'));
    setShowModal(false);
  };

  const handleSaveOverride = async (data: MagicDoor.Api.RentPaymentProcessingFeesSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      toast.error(t('Something went wrong'));
      return;
    }
    await updateLevelSettings(level as MagicDoor.Api.SettingsLevel, levelId, data);
    toast.success(t('Rent payment processing fees override saved successfully'));
  };

  const handleDeleteOverride = async (level: string, levelId: string) => {
    const confirmed = await confirm({
      title: t(`Delete override`),
      content: (
        <Html as="p" class="text-text-level03 [&>b]:font-bold [&>b]:text-primary">
          {t('Are you sure you want to delete <b>{level}</b> override?', { level })}
        </Html>
      ),
    });
    if (!confirmed) return; // eslint-disable-line solid/components-return-once
    await deleteLevelSettings(level as MagicDoor.Api.SettingsLevel, levelId);
    toast.success(t('Rent payment processing fees override deleted successfully'));
  };

  return (
    <>
      <ThreeSectionBox
        class="self-start"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new override')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Rent payment processing fees')} icon={IconRentPayment} />}
        middle={
          <Show when={!allSettings.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
            <div class="flex flex-col gap-5 p-3 md:p-6">
              <div class="flex flex-col gap-4 text-left">
                <EditForm
                  sections={sections()}
                  onOk={handleSave}
                  model={allSettings()?.settings || ({} as MagicDoor.Api.RentPaymentProcessingFeesSettingsDto)}
                />
              </div>
              <Overrides overrides={allSettings()?.overrides || []} onDelete={handleDeleteOverride} title={t('Rent payment overrides')}>
                {(override) => {
                  return (
                    <div class="flex flex-col gap-4 p-2 text-left">
                      <EditForm
                        sections={sections()}
                        onOk={(data) => handleSaveOverride(data, override.level, override.levelId)}
                        model={override.settings || ({} as MagicDoor.Api.RentPaymentProcessingFeesSettingsDto)}
                      />
                    </div>
                  );
                }}
              </Overrides>
            </div>
          </Show>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allSettings()?.overrides || []}
          onClose={() => setShowModal(false)}
          onDone={handleCreateOverride}
          loading={updateLevelSettings.pending}
        />
      </Show>
    </>
  );
};
