import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { PropertyManagersRepository, PROPERTY_MANAGERS_URL } from '~/repositories/propertyManagersRepository';
import { createLazyResource, createMutation } from '~/utils/resource';
import type { UploaderFile } from '~/components/common/Upload';

const repo = new PropertyManagersRepository();

export const [PropertyManagersProvider, usePropertyManagers] = createMagicDoorContext('PropertyManagers', () => {
  const [currentManager, setCurrentManager] = createSignal<MagicDoor.Api.PropertyManagerDto | undefined>(undefined);
  const [newFiles, setNewFiles] = createSignal<UploaderFile[]>([]);
  const [propertyManagers, { fetch, refetch }] = createLazyResource(async () => {
    setCurrentManager(undefined);
    const data = await repo.getPropertyManagers();
    return data;
  });

  const updatePropertyManager = createMutation(async (id: string, model: MagicDoor.Api.PropertyManagerDto) => {
    await repo.updatePropertyManager(id, model);
  });

  const addPropertyManager = createMutation(async (model: MagicDoor.Api.PropertyManagerDto) => {
    await repo.createPropertyManager(model);
  });

  const deletePropertyManager = createMutation(async (id: string) => {
    await repo.deletePropertyManager(id);
  });

  const getPropertyManagerInfo = createMutation(async (id: string) => {
    return await repo.getPropertyManagerById(id);
  });

  const changePropertyManagerPassword = createMutation(async (id: string, currentPassword: string, newPassword: string) => {
    await repo.changePropertyManagerPassword(id, currentPassword, newPassword);
  });

  const changePropertyManagerActiveStatus = createMutation(async (id: string, isActive: boolean) => {
    await repo.changePropertyManagerActiveStatus(id, isActive);
  });

  const uploadFileUrl = (managerId: string) => `${PROPERTY_MANAGERS_URL}/${managerId}/image`;

  return {
    get propertyManagers() {
      fetch();
      return propertyManagers;
    },
    get loading() {
      fetch();
      return () => propertyManagers.loading;
    },
    uploadFileUrl,
    newFiles,
    setNewFiles,
    updatePropertyManager,
    refetch,
    currentManager,
    setCurrentManager,
    addPropertyManager,
    deletePropertyManager,
    getPropertyManagerInfo,
    changePropertyManagerPassword,
    changePropertyManagerActiveStatus,
  };
});
