import { createEffect, createMemo, createSignal, onCleanup } from 'solid-js';
import { Portal } from 'solid-js/web';
import { cn } from '~/utils/classnames';
import { usePortal } from '~/utils/hooks';
import type { Component, JSX } from 'solid-js';

interface TooltipProps {
  message: string | undefined | JSX.Element;
  class?: string;
  bgColor?: string;
  children?: JSX.Element;
  align?: 'top' | 'bottom' | 'left' | 'right';
  disabled?: boolean;
  popupClass?: string;
}
type Position = {
  [key in string]?: string;
};
const Tooltip: Component<TooltipProps> = (props) => {
  const uniqueId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
  let tooltipContainer: HTMLDivElement | undefined = undefined;
  let popupRef: HTMLDivElement | undefined = undefined;
  const [style, setStyle] = createSignal({
    display: 'none',
    visibility: 'hidden',
  } as any);
  const [containerStyle, setContainerStyle] = createSignal({});
  const align = () => props.align ?? 'top';

  const arrowPositionStyle = createMemo<Position>(() => {
    const position: Position = {};
    switch (align()) {
      case 'top':
        position['margin-left'] = '-4px';
        position.left = '50%';
        position.bottom = '-14px';
        position.transform = 'rotate(90deg)';
        break;
      case 'left':
        position.right = '-6px';
        position['margin-top'] = '-12px';
        position.top = '50%';
        break;
      case 'bottom':
        position.top = '-14px';
        position['margin-left'] = '-4px';
        position.left = '50%';
        position.transform = 'rotate(-90deg)';
        break;
      case 'right':
        position.left = '-6px';
        position['margin-top'] = '-12px';
        position.top = '50%';
        position.transform = 'rotate(180deg)';
        break;
    }
    return position;
  });

  const getContainerStyle = () => {
    if (!tooltipContainer || !popupRef) return;

    const rect = tooltipContainer.getBoundingClientRect();
    const { offsetHeight, offsetWidth } = tooltipContainer;
    const { offsetHeight: popupHeight, offsetWidth: popupWidth } = popupRef;

    let x = rect.left;
    let y = rect.top;

    switch (align()) {
      case 'top':
        x = x - popupWidth / 2 + offsetWidth / 2;
        y = y - popupHeight - 8;
        break;
      case 'left':
        x = x - popupWidth - 8;
        y = y - popupHeight / 2 + offsetHeight / 2;
        break;
      case 'bottom':
        x = x - popupWidth / 2 + offsetWidth / 2;
        y = y + offsetHeight + 8;
        break;
      case 'right':
        x = x + offsetWidth + 8;
        y = y - popupHeight / 2 + offsetHeight / 2;
        break;
    }

    if (x < 0) x = 0;
    if (x + popupWidth > window.innerWidth) x = window.innerWidth - popupWidth;
    if (y < 0) y = 0;
    if (y + popupHeight > window.innerHeight) y = window.innerHeight - popupHeight;

    if (align() === 'top' && y < 0) {
      y = rect.top + offsetHeight + 8;
    } else if (align() === 'bottom' && y + popupHeight > window.innerHeight) {
      y = rect.top - popupHeight - 8;
    } else if (align() === 'left' && x < 0) {
      x = rect.left + offsetWidth + 8;
    } else if (align() === 'right' && x + popupWidth > window.innerWidth) {
      x = rect.left - popupWidth - 8;
    }

    const offset = Math.min(popupWidth, popupHeight) / 2;
    if (x < offset) x = offset;
    if (x + popupWidth > window.innerWidth - offset) x = window.innerWidth - popupWidth - offset;
    if (y < offset) y = offset;
    if (y + popupHeight > window.innerHeight - offset) y = window.innerHeight - popupHeight - offset;

    setContainerStyle({
      inset: '0px auto auto 0px',
      transform: `translate3d(${x}px,${y}px, 0px)`,
      zIndex: 9999,
    });
  };

  const onMouseEnter = () => {
    if (props.disabled) return;
    setStyle({
      display: 'block',
      visibility: 'visible',
    });
    requestAnimationFrame(() => {
      getContainerStyle();
      requestAnimationFrame(getContainerStyle);
    });
  };

  const onMouseLeave = () => {
    setStyle({
      display: 'none',
      visibility: 'hidden',
    });
  };

  createEffect(() => {
    if (props.disabled) {
      onMouseLeave();
    }
  });

  onCleanup(() => {
    tooltipContainer = undefined;
    popupRef = undefined;
  });

  return (
    <>
      <Portal mount={usePortal(uniqueId, uniqueId)}>
        <div class="pointer-events-none fixed z-[9999] m-0 inline-block p-0" style={containerStyle()}>
          <div
            ref={popupRef}
            class={cn(
              'word-break relative z-[70] inline-block max-w-96 rounded-lg border-0 bg-[#303133] px-2.5 py-2 text-sm text-white shadow-sm',
              props.popupClass
            )}
            style={{ 'overflow-wrap': 'anywhere', ...style(), background: props.bgColor }}>
            {props.message}
            <div class="absolute" style={arrowPositionStyle()}>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-2">
                <path
                  d="M0.5 0L1.5 0C1.5 4, 3 5.5, 5 7.5S8,10 8,12S7 14.5, 5 16.5S1.5,20 1.5,24L0.5 24L0.5 0z"
                  fill={props.bgColor ?? '#303133'}
                />
                <path d="M0 0L1 0C1 4, 2 5.5, 4 7.5S7,10 7,12S6 14.5, 4 16.5S1,20 1,24L0 24L0 0z" fill={props.bgColor ?? '#303133'} />
              </svg>
            </div>
          </div>
        </div>
      </Portal>
      <div ref={tooltipContainer} class={cn('w-fit', props.class)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {props.children}
      </div>
    </>
  );
};

export default Tooltip;
