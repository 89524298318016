import { createEffect, createSignal } from 'solid-js';
import { useForm, Form } from '~/components/common/BetterForm';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import {
  CategoryComponentSection,
  CategoryComponentWrapperProvider,
  CategoryContentSection,
  getErrorAndProgressProps,
} from '~/components/common/Category';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { toast } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';
import { DeleteVendor } from '~/pages/vendors/components/DeleteVendor';
import ContactsSection from '~/pages/vendors/components/form-sections/ContactsSection';
import InsuranceSection from '~/pages/vendors/components/form-sections/InsuranceSection';
import PaymentInformationSection from '~/pages/vendors/components/form-sections/PaymentInformationSection';
import VendorInformationSection from '~/pages/vendors/components/form-sections/VendorInformationSection';
import PaymentSettingsSection from './form-sections/PaymentSettingsSection';
import type { JSX } from 'solid-js';
import type { Section } from '~/components/common/Category';

interface Props {
  onClose: () => void;
}

type AddSection = {
  key: string;
  component: (props: any) => JSX.Element;
  name: string;
  ref?: HTMLElement | null;
} & Omit<Section, 'component'>;

const vendorInfoToFormData = (vendor: MagicDoor.Api.HydratedVendorDto) => {
  const address = vendor.contact.address;
  delete vendor.contact.address;
  return {
    ...vendor,
    address,
  };
};

const EditVendor = (props: Props) => {
  const { t } = useLocalization();
  const { current, updateVendor } = useVendors();
  const form = useForm();

  const [activeCategory, setActiveCategory] = createSignal<string>('');

  createEffect(() => {
    const vendor = current();
    if (vendor == null) return;
    form.setFieldsValue(vendorInfoToFormData(vendor));
  });

  const sections = [
    {
      key: 'vendorInformation',
      component: VendorInformationSection,
      name: t('Vendor information'),
      fields: ['name', 'language', 'memo'],
    },
    {
      key: 'contacts',
      component: ContactsSection,
      name: t('Contacts'),
      fields: ['contact.phone', 'contact.email', 'address'],
    },
    {
      key: 'paymentInformation',
      component: PaymentInformationSection,
      name: t('Payment information'),
      fields: ['irs1099Form.taxId', 'irs1099Form.taxIdType'],
    },
    {
      key: 'insurance',
      component: InsuranceSection,
      name: t('Insurance'),
      fields: ['insurancePolicy.policyNumber', 'insurancePolicy.expirationDate', 'insurancePolicy.provider'],
    },
    {
      key: 'paymentSettings',
      component: PaymentSettingsSection,
      name: t('Payment settings'),
      fields: ['paymentSettings.onePaymentPerBill'],
    },
  ] as AddSection[];

  const performSave = async () => {
    const vendor = current();
    if (vendor == null) return;
    const updated = await updateVendor(vendor.id, form.formStore as MagicDoor.Api.CreateOrUpdateVendorDto);
    toast.success(t('{name} has been edited successfully', { name: updated.name }));
    props.onClose();
  };

  return (
    <Form defaultForm={form} onFormSubmit={performSave} class="m-8 h-section1 overflow-hidden rounded-lg bg-white text-sm">
      <CategoryComponentWrapperProvider
        sections={sections}
        onActiveCategoryChange={(key) => {
          setActiveCategory(key);
        }}
        activeCategory={activeCategory()}>
        <div class="relative flex h-full">
          <div class="thinscroll relative hidden h-[calc(100%-91px)] w-1/4 overflow-auto text-text-level03 lg:block">
            <div class="flex flex-col gap-2 pt-5">
              <div class="px-5 pb-2 text-left text-lg font-semibold text-text-level01">{t('Edit vendor')}</div>
              <CategoryComponentSection class="min-w-0 grow bg-transparent px-0" {...getErrorAndProgressProps(form)} />
            </div>
          </div>

          <div class="thinscroll flex w-full flex-col gap-10 overflow-auto border-l px-10 pb-36 text-left lg:w-3/4">
            <CategoryContentSection hideTitle />
          </div>

          <div class="absolute bottom-0 z-20 flex w-full items-center justify-between border-t bg-white p-6">
            <div class="w-2/3 text-left text-xs text-text-level03">
              <DeleteVendor vendorId={current()?.id} />
            </div>
            <div class="flex w-1/3 justify-end gap-2">
              <CancelWithConfirmation
                onConfirm={props.onClose}
                title={t('Are you sure you want to cancel?')}
                message={t('Are you sure you want to cancel? The changes will not be saved.')}
              />
              <CallToActionButton
                type="submit"
                loading={updateVendor.pending}
                tooltipMessage={t('Please fill out all the required fields that are in red')}>
                {t('Save changes')}
              </CallToActionButton>
            </div>
          </div>
        </div>
      </CategoryComponentWrapperProvider>
    </Form>
  );
};

export default EditVendor;
