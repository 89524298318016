import { Show } from 'solid-js';
import IconCopy from '~/assets/images/common/iconCopy.svg?component-solid';
import Tooltip from '~/components/common/Tooltip';
import { ActionButton } from '~/components/rental-application/rental-application-details/infomation/ActionButton';
import { useCompanies, useLocalization, useRentalApplication } from '~/contexts/global';
import { createClipboard } from '~/utils/clipboard';

export const CopyApplicationLink = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();
  const { companies, getWebsiteUrl } = useCompanies();
  const { copy, tooltipMessage } = createClipboard({
    successMessage: t('Link copied to clipboard'),
    errorMessage: t('Failed to copy link'),
    tooltipMessages: {
      default: t('Click to copy link'),
      copied: t('Link copied'),
    },
  });

  const getApplicationLink = () =>
    `${getWebsiteUrl(
      `/rental-applications/${applicationStore.application?.id}/${applicationStore.application?.password}/applicant-information`
    )}`;

  return (
    <Show when={companies()?.publicUrl}>
      <Tooltip message={tooltipMessage()} class="w-full cursor-pointer" align="top">
        <ActionButton
          class="w-full border border-primary text-primary"
          onClick={() => copy(getApplicationLink())}
          label={t('Copy application link')}
          icon={IconCopy}
        />
      </Tooltip>
    </Show>
  );
};
