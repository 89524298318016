import { LeaseSettingsProvider } from '~/contexts/local';
import { LeaseRenewalSettings } from './LeaseRenewalSettings';

export const LeaseRenewalSettingsWrapper = () => {
  return (
    <LeaseSettingsProvider>
      <LeaseRenewalSettings />
    </LeaseSettingsProvider>
  );
};
