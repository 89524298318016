import { For } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { IconChevronRight } from '~/components/ui/Icons';
import { cn } from '~/utils/classnames';
import type { Component, ValidComponent } from 'solid-js';

type TabItem = {
  name: string;
  path: string;
};

type MobileTabsProps = {
  items: TabItem[];
  class?: string;
  icon?: ValidComponent;
  title?: string;
  onClickTab: (tab: TabItem) => void;
};

export const MobileTabs: Component<MobileTabsProps> = (props) => {
  return (
    <div class={cn('flex w-full flex-col gap-2 rounded-lg bg-white p-3 text-sm text-text-level02', props.class)}>
      <div class="flex items-center justify-start gap-2 pb-3">
        {props.icon && <Dynamic component={props.icon} />}
        {props.title && <span class="text-xs text-text-level03">{props.title}</span>}
      </div>
      <For each={props.items}>
        {(tab) => (
          <div class="flex items-center justify-between border-b border-[#EBEBF6] py-3" onClick={() => props.onClickTab(tab)}>
            <span class="truncate">{tab.name}</span>
            <IconChevronRight class="size-4" />
          </div>
        )}
      </For>
    </div>
  );
};
