import { createSignal, createEffect } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { ChatRepository } from '~/repositories/chatRepository';
import { createTriggerResource } from '~/utils/resource';
import { useLatestMessage } from './ChatUpdateStore';
import type { Accessor, Resource } from 'solid-js';
import type { ChatFilter, ChatSearchFilter, UnreadMessageFilter } from '~/repositories/chatRepository';
import type { HydratedMagicChatDto, UnreadMessagePaginationDto } from '~/swagger/Api';

interface ChatsListContextValue {
  fetchChats: () => Promise<void>;
  chats: Accessor<MagicDoor.Api.HydratedMagicChatDto[]>;
  onChatAdded: (chat: MagicDoor.Api.HydratedMagicChatDto) => void;
  isLoading: Accessor<boolean>;
  error: Accessor<string | undefined>;
  chatCount: Accessor<number>;
  clearUnreadMessages: (chatId: string) => void;
  addNewCreatedChat: (newChat: any) => void;
  filtered: Resource<UnreadMessagePaginationDto>;
  setFilter: (trigger: UnreadMessageFilter) => UnreadMessageFilter;
  chatFiltered: Resource<HydratedMagicChatDto[]>;
  setChatFilter: (trigger: ChatFilter) => ChatFilter;
  refetch: (info?: unknown) => UnreadMessagePaginationDto | Promise<UnreadMessagePaginationDto | undefined> | null | undefined;
  chatRefetch: (info?: unknown) => HydratedMagicChatDto[] | Promise<HydratedMagicChatDto[] | undefined> | null | undefined;
  unarchiveChat: (chatId: string) => Promise<void>;
  searchChats: (filters: ChatSearchFilter) => Promise<MagicDoor.Api.UnreadMessagePaginationDto>;
}

export const [ChatsListProvider, useChatsList] = createMagicDoorContext<ChatsListContextValue>('ChatsList', () => {
  const repo = new ChatRepository();

  const [chats, setChats] = createSignal<MagicDoor.Api.HydratedMagicChatDto[]>([]);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<string | undefined>(undefined);
  const [chatCount, setChatCount] = createSignal<number>(0);
  const [filtered, setFilter, actions] = createTriggerResource((filter: UnreadMessageFilter) => repo.getUnreadMessage(filter));
  const [chatFiltered, setChatFilter, chatActions] = createTriggerResource((filter: ChatFilter) => repo.getChats(filter));

  const fetchChats = async () => {
    setIsLoading(true);
    try {
      const response = await repo.getChats();

      const list: MagicDoor.Api.HydratedMagicChatDto[] = response.map(map);

      setChats(list);
      setChatCount(list.length);
      setError(undefined);
    } catch (e) {
      setError(e instanceof Error ? e.message : String(e));
    } finally {
      setIsLoading(false);
    }
  };

  const addNewCreatedChat = (newChat: any) => {
    setChats((prevChats) => [...prevChats, newChat]);
  };

  const latestMessage = useLatestMessage();

  createEffect(() => {
    const message = latestMessage();
    if (message) {
      fetchChats();
      clearUnreadMessages(message.chatId);
    }
  });

  const map = (chat: MagicDoor.Api.HydratedMagicChatDto): MagicDoor.Api.HydratedMagicChatDto => {
    return chat;
  };

  const onChatAdded = (chat: MagicDoor.Api.HydratedMagicChatDto) => {
    setChats((prevChats) => [map(chat), ...prevChats]);
    setChatCount((count) => count + 1);
  };

  const clearUnreadMessages = (chatId: string) => {
    setChats((chats) =>
      chats.map((chat) => {
        if (chat.id === chatId) {
          return { ...chat, unreadMessages: 0 };
        }
        return chat;
      })
    );
  };

  const unarchiveChat = async (chatId: string) => {
    await repo.unarchiveChat(chatId);
    chatActions.refetch();
  };

  const searchChats = async (filters: ChatSearchFilter) => {
    const response = await repo.searchChat(filters);
    return response;
  };

  return {
    fetchChats,
    chats,
    onChatAdded,
    isLoading,
    error,
    chatCount,
    clearUnreadMessages,
    addNewCreatedChat,
    filtered: filtered,
    setFilter: (filter: UnreadMessageFilter) => setFilter(filter),
    refetch: actions.refetch,
    chatFiltered,
    setChatFilter,
    chatRefetch: chatActions.refetch,
    unarchiveChat,
    searchChats,
  };
});
