import { createSignal } from 'solid-js';
import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import { Panel } from '~/components/common/Panels';
import { DropdownActions, IconSquarePen, IconBan } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { DeactivateUnit } from '~/pages/units/components/DeactivateUnit';
import { UnitMetric } from '~/pages/units/components/UnitMetric';
import type { Component } from 'solid-js';
type UnitSummaryProps = {
  class?: string;
  unit?: MagicDoor.Api.HydratedUnitDto;
};

export const UnitSummary: Component<UnitSummaryProps> = (props) => {
  const { t } = useLocalization();
  const [deactivating, setDeactivating] = createSignal(false);

  return (
    <Panel
      title={t('Basic information')}
      class={props.class}
      actions={
        <DropdownActions
          actions={[
            {
              label: t('Edit'),
              icon: IconSquarePen,
              href: 'edit-unit',
            },
            {
              label: t('Deactivate'),
              icon: IconBan,
              onClick: () => setDeactivating(true),
            },
            // {
            //   label: t('Add service bill'),
            //   icon: IconCirclePlus,
            //   href: 'add-service-bill',
            // },
          ]}
        />
      }>
      <div class="flex flex-wrap gap-y-5 px-3 py-4 md:px-6 md:py-5">
        <UnitMetric icon={IconBedroom} title={t('Bedroom')} value={props.unit?.beds ?? '-'} />
        <UnitMetric icon={IconBathroom} title={t('Bathroom')} value={props.unit?.baths ?? '-'} />
        <UnitMetric icon={IconUnitSize} title={t('Unit size')} value={props.unit?.unitSizeSqft ?? '-'} suffix={t('Sqft')} />
      </div>
      <DeactivateUnit unit={deactivating() ? props.unit : undefined} onClose={() => setDeactivating(false)} />
    </Panel>
  );
};
