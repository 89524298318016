import { useNavigate } from '@solidjs/router';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconViewList from '~/assets/images/common/viewList.svg?component-solid';
import { BankAccountMask } from '~/components/common/BankAccountMask';
import TextOverflow from '~/components/common/TextOverflow';
import { MessageModal } from '~/components/modals';
import { IconBan, ListView, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount, useBankAccountsList } from '~/contexts/local';
import { getStatusText, getStatusTextColor } from '~/pages/bank-accounts/utils';
import { useBankAccountActions } from '~/pages/bank-accounts/utils/bankAccountActions';
import { cn } from '~/utils/classnames';
import { ListExpandContent } from './ListExpandContent';
import type { JSX } from 'solid-js';
import type { BankAccountConnectionStatus, BankAccountType } from '~/swagger/Api';

export const getAccountTypeElement = (type: `${BankAccountType}` | null | undefined) => {
  const { t } = useLocalization();
  const text = type == null ? 'Unknown' : type === 'checking' ? 'Checking' : 'Savings';
  return <div class="text-sm">{t(text)}</div>;
};

export const BankAccountsListView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const { isLoading, filterItems, filterValue, onResetFilter, onFilterChange, filteredBankAccounts } = useBankAccountsList();
  const { setBankAccount } = useBankAccount();
  const {
    handleSettings,
    handleViewBankReport,
    handleDeleteBankAccount,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    confirmDeleteBankAccount,
    isDeleting,
  } = useBankAccountActions();

  const getPlaidAndStripeElement = (status?: BankAccountConnectionStatus): JSX.Element => {
    return <div class={cn('text-sm', getStatusTextColor(status))}>{t(getStatusText(status))}</div>;
  };

  const handleLocalSettings = (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => {
    setBankAccount(bankAccount);
    handleSettings(bankAccount.id, true);
  };

  const handleLocalDelete = (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => {
    setBankAccount(bankAccount);
    confirmDeleteBankAccount(bankAccount.id);
  };

  return (
    <>
      <ListView
        hideFooter
        class="border"
        title={t('Bank account overview')}
        loading={isLoading()}
        data={filteredBankAccounts()}
        filterItems={filterItems()}
        searchPlaceholder={t('Search bank accounts')}
        filterValue={filterValue()}
        onFilterReset={onResetFilter}
        onRowClick={(item) => {
          navigate(`/accounting/bank-accounts/${item.id}`);
        }}
        onChange={(args, action) => {
          onFilterChange(args, action);
        }}
        filterPopoverOverlayClass="w-[22rem]"
        columns={[
          {
            title: t('Bank account'),
            headerClass: 'w-[20%]',
            render: (bankAccount) => <div class="text-sm text-text-level01">{bankAccount.name}</div>,
          },
          {
            title: t('Plaid'),
            headerClass: 'w-60',
            render: (bankAccount) => {
              return getPlaidAndStripeElement(bankAccount.plaid?.status);
            },
          },
          {
            title: t('Stripe'),
            headerClass: 'w-60',
            render: (bankAccount) => {
              return getPlaidAndStripeElement(bankAccount.stripe?.status);
            },
          },
          {
            title: t('Account'),
            headerClass: 'w-60',
            render: (bankAccount) => {
              return <BankAccountMask as="div" class="whitespace-nowrap text-sm" accountMask={bankAccount.accountNumber} />;
            },
          },
          {
            title: t('Routing'),
            headerClass: 'w-60',
            render: (bankAccount) => {
              return <BankAccountMask as="div" class="whitespace-nowrap text-sm" accountMask={bankAccount.routingNumber} />;
            },
          },
          {
            title: t('Type'),
            headerClass: 'w-60',
            render: (bankAccount) => {
              return getAccountTypeElement(bankAccount.type);
            },
          },
          {
            title: t('Description'),
            headerClass: 'w-96',
            render: (bankAccount) => (
              <div class="w-96 text-sm normal-case text-text-level01">
                <TextOverflow text={bankAccount.description ?? ''} maxLines={1} />
              </div>
            ),
          },
          {
            class: 'text-sm',
            render: (item) => (
              <TableActions
                actions={[
                  {
                    label: t('View bank report'),
                    icon: IconViewList,
                    onClick: () => handleViewBankReport(item.id),
                  },
                  {
                    label: t('Edit bank account'),
                    icon: () => <IconEdit class="size-4 text-text-level03" />,
                    onClick: () => handleLocalSettings(item),
                  },
                  {
                    label: t('Deactivate'),
                    icon: () => <IconBan class="size-4 text-text-level03" />,
                    onClick: () => handleLocalDelete(item),
                  },
                ]}
              />
            ),
          },
        ]}
        expandable={(item) => (
          <ListExpandContent
            onSettingsClick={() => handleLocalSettings(item)}
            onViewReportClick={() => handleViewBankReport(item.id)}
            bankAccount={item}
          />
        )}
      />
      {showDeleteConfirmation() && (
        <MessageModal
          title={t('Deactivate bank account')}
          onDone={() => handleDeleteBankAccount()}
          onClose={() => setShowDeleteConfirmation(false)}
          doneText={t('Deactivate')}
          loading={isDeleting()}
          visible={showDeleteConfirmation()}>
          <div class="p-6">
            <p>{t('Are you sure you want to deactivate this bank account? This action cannot be undone.')}</p>
          </div>
        </MessageModal>
      )}
    </>
  );
};
