import { Show } from 'solid-js';
import IconEmailMsg from '~/assets/images/chat/sendEmail.svg?component-solid';
import IconEmailFailed from '~/assets/images/chat/sendEmailFailed.svg';
import IconTextMsg from '~/assets/images/chat/sendText.svg?component-solid';
import IconTextFailed from '~/assets/images/chat/sendTextFailed.svg';
import { dateFormat } from '~/utils/date';
import Tooltip from './Tooltip';
import type { Component } from 'solid-js';
import type { TFunction } from '~/contexts/global';
import type { EmailStatus, TextStatus } from '~/swagger/Api';

interface MessageSourceIconsProps {
  message: MagicDoor.Api.ChatMessageDto;
  t: TFunction;
}

interface DeliveryStatus {
  status?: EmailStatus | TextStatus | null;
  sentAt?: string | null;
  deliveredAt?: string | null;
}

export const MessageSourceIcons: Component<MessageSourceIconsProps> = (props) => {
  const getDeliveryTooltip = (type: 'SMS' | 'Email', { status, sentAt, deliveredAt }: DeliveryStatus) => {
    if (status === 'delivered' && deliveredAt) {
      return `${props.t(`${type} delivered at`)} ${dateFormat('', deliveredAt)}`;
    }
    if (status === 'sent' && sentAt) {
      return `${props.t(`${type} sent at`)} ${dateFormat('', sentAt)}`;
    }
    if (status === 'failed') {
      return props.t(`${type} failed to send`);
    }
    if (status === 'notSent') {
      return props.t(`${type} not sent`);
    }
    if (status === 'disabled') {
      return props.t(`${type} sending disabled`);
    }
    if (status === 'noRecipients') {
      return props.t(`${type} has no recipients`);
    }

    if (type === 'Email') {
      if (status === 'bounce') {
        return props.t('Email bounced');
      }
      if (status === 'complaint') {
        return props.t('Email marked as spam');
      }
      if (status === 'deliveryDelay') {
        return props.t('Email delivery delayed');
      }
      if (status === 'reject') {
        return props.t('Email rejected');
      }
    }

    return props.t(type);
  };

  const renderMessageIcon = (type: 'email' | 'text', Icon: Component, FailedIcon: string) => {
    const status = props.message[`${type}Status`];
    const tooltipMessage = getDeliveryTooltip(type === 'email' ? 'Email' : 'SMS', {
      status,
      sentAt: props.message[`${type}SentAt`],
      deliveredAt: props.message[`${type}DeliveredAt`],
    });

    return (
      <Tooltip message={tooltipMessage} align="left">
        <Show when={status !== 'failed'} fallback={<img src={FailedIcon} />}>
          <Icon />
        </Show>
      </Tooltip>
    );
  };

  return (
    <div class="flex gap-0.5 text-auxiliary-text">
      <Show when={props.message.source === 'email' || props.message.sendEmail}>
        {renderMessageIcon('email', IconEmailMsg, IconEmailFailed)}
      </Show>
      <Show when={props.message.source === 'text' || props.message.sendText}>{renderMessageIcon('text', IconTextMsg, IconTextFailed)}</Show>
    </div>
  );
};
