import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal, For, onCleanup, onMount, Show } from 'solid-js';
import IconNoData from '~/assets/images/chat/noData.svg';
import IconTypeToSearch from '~/assets/images/chat/typeToSearch.svg';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import { BaseModal } from '~/components/modals/BaseModal';
import { IconSearch, Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChat, useChatsList } from '~/contexts/local';
import { ChatContentPrint } from './ChatContentPrint';
import { ChatContentSearch } from './chat-search/ChatContentSearch';
import { MessageContentSearch } from './chat-search/MessageContentSearch';
import type { Component } from 'solid-js';
import type { ChatMessageDto, HydratedChatParticipantDto, HydratedMagicChatDto } from '~/swagger/Api';

interface TabType {
  id: 'messages' | 'files' | 'chats';
  label: string;
  count: number;
}

interface ChatContent {
  title: string;
  content: string;
  participants: HydratedChatParticipantDto[];
  chatId?: string;
  messageId?: string;
  sentAt?: string;
  messagesInfo?: ChatMessageDto;
  chatsInfo?: HydratedMagicChatDto;
}
interface ChatContentResult {
  totalCount: number;
  items: ChatContent[];
}
export const ChatSearchModal: Component<{
  class?: string;
  visible?: boolean;
  chatSearchMode: 'chat' | 'message';
  defaultInputValue?: string;
  chat?: HydratedMagicChatDto;
  onClose?: () => void;
}> = (props) => {
  let searchInputRef: HTMLInputElement | undefined;
  const { t } = useLocalization();
  const navigate = useNavigate();

  const [searchText, setSearchText] = createSignal('');

  const { hasMoreMessages, setJumpToMessage, setChatId, setIsSearchMode, fetchSearchMessagesById, searchMessages } = useChat();
  const { chats, searchChats } = useChatsList();

  const [searchChatsResult, setSearchChatsResult] = createSignal<ChatContentResult>();
  const [chatModeMessageResults, setChatModeMessageResults] = createSignal<ChatContentResult>();

  const [searchChatCount, setSearchChatCount] = createSignal(0);
  const [searchMessageCount, setSearchMessageCount] = createSignal(0);
  const [searchFileCount, setSearchFileCount] = createSignal(0);

  const [isLoading, setIsLoading] = createSignal(false);
  const [activeTab, setActiveTab] = createSignal<TabType['id']>('messages');
  const [isJump, setIsJump] = createSignal(false);

  const tabs = createMemo(() => {
    return props.chatSearchMode === 'chat'
      ? [
          { id: 'chats' as const, label: t('Chats'), count: searchChatCount() },
          { id: 'messages' as const, label: t('Messages'), count: searchMessageCount() },
          { id: 'files' as const, label: t('Files'), count: searchFileCount() },
        ]
      : [
          { id: 'messages' as const, label: t('Messages'), count: searchMessageCount() },
          { id: 'files' as const, label: t('Files'), count: searchFileCount() },
        ];
  });

  const [currentPage, setCurrentPage] = createSignal(1);
  const [isLoadingMore, setIsLoadingMore] = createSignal(false);
  const [containerRef, setContainerRef] = createSignal<HTMLDivElement>();

  const [searchFilesResult, setSearchFilesResult] = createSignal<ChatMessageDto[]>([]);

  const isSearchTextEmpty = createMemo(() => searchText().trim() === '');

  const loadDataInChatMode = (isNew = false) => {
    if (searchText().trim() === '') {
      setSearchChatsResult({ items: [], chats: [], currentPage: 1, pageSize: 20, totalPages: 0, totalCount: 0 });
    } else {
      getChatInChatMode(searchText());
      getMessageInChatMode(searchText());
      getSearchFiles(searchText(), isNew);
    }
  };

  const loadDataInMessageMode = (isNew = false) => {
    activeTab() === 'messages' ? getMessageInMessageMode(searchText(), isNew) : getSearchFiles(searchText(), isNew);
  };

  createEffect(() => {
    const container = containerRef();
    if (container) {
      container.addEventListener('scroll', handleScroll);
      onCleanup(() => {
        container.removeEventListener('scroll', handleScroll);
      });
    }
  });

  onMount(() => {
    setActiveTab(props.chatSearchMode === 'chat' ? 'chats' : 'messages');
    props.defaultInputValue && setSearchText(props.defaultInputValue);

    setIsSearchMode(false);
    loadDataInChatMode(true);
  });

  const updateData = () => {
    if (isJump()) {
      return;
    }

    props.chatSearchMode === 'chat' ? loadDataInChatMode(true) : loadDataInMessageMode(true);
  };

  createEffect(() => {
    if (isSearchTextEmpty()) {
      setSearchChatCount(0);
      setSearchChatsResult({ items: [], totalCount: 0 });
      setSearchMessageCount(0);
      setChatModeMessageResults({ items: [], totalCount: 0 });
      setSearchFileCount(0);
      setSearchFilesResult([]);
    }
  });

  const getChatInChatMode = (search: string) => {
    if (isSearchTextEmpty()) {
      return;
    }

    search = search.trim();

    const isChatTab = activeTab() === 'chats';
    isChatTab && setIsLoading(true);

    const result = chats().filter((chat) => chat.subject.toLowerCase().includes(search.toLowerCase()));

    setSearchChatsResult({
      items: result.map((chat) => ({
        chatId: chat.id,
        title: chat.subject,
        content: chat.participants.map((participant) => participant.name).join(', '),
        participants: chat.participants,
      })),
      totalCount: result.length,
    });

    setSearchChatCount(result.length);
    setCurrentPage(1);

    isChatTab && setIsLoading(false);
  };

  const getMessageInChatMode = async (search: string, page = 1, append = false) => {
    if (isSearchTextEmpty()) {
      return;
    }

    search = search.trim();

    const isMessageTab = activeTab() === 'messages';

    if (isMessageTab) {
      page === 1 ? setIsLoading(true) : setIsLoadingMore(true);
    }

    const result = await searchChats({
      page,
      pageSize: 20,
      search,
    });

    if (append && chatModeMessageResults()) {
      setChatModeMessageResults({
        totalCount: result.totalCount,
        items: [
          ...chatModeMessageResults()!.items,
          ...result.items.map((item, index) => {
            return {
              chatId: item.chatId,
              messageId: item.id,
              sentAt: item.sentAt,
              title: getChatTitle(item.chatId),
              content: item.message as string,
              participants: getRelatedChat(item.chatId)?.participants || [],
              messagesInfo: result.items[index],
              chatsInfo: getRelatedChat(item.chatId),
            };
          }),
        ],
      });
    } else {
      setChatModeMessageResults({
        totalCount: result.totalCount,
        items: result.items.map((item, index) => ({
          chatId: item.chatId,
          messageId: item.id,
          sentAt: item.sentAt,
          title: getChatTitle(item.chatId),
          content: item.message as string,
          participants: getRelatedChat(item.chatId)?.participants || [],
          messagesInfo: result.items[index],
          chatsInfo: getRelatedChat(item.chatId),
        })),
      });
    }

    setSearchMessageCount(result.totalCount);
    setCurrentPage(page);

    if (isMessageTab) {
      page === 1 ? setIsLoading(false) : setIsLoadingMore(false);
    }
  };

  const getMessageInMessageMode = async (search: string, isNew?: boolean, messageSentAt?: string, id?: string) => {
    if ((!props.chat || !props.chat.id) && !id) {
      return;
    }

    let chatId = props.chat && props.chat.id;
    chatId = chatId || id;

    if (isNew) {
      setIsLoading(true);
      await fetchSearchMessagesById(chatId as string, '', messageSentAt || '', 20, search);
      setIsLoading(false);
    } else {
      setIsLoadingMore(true);
      await fetchSearchMessagesById(chatId as string, searchMessages()[searchMessages().length - 1].sentAt, '', 20, search);
      setIsLoadingMore(false);
    }
  };

  const getSearchFiles = async (search: string, isNew?: boolean, page = 1) => {
    if (props.chat && props.chat.id && props.chatSearchMode !== 'chat') {
      let result;
      if (isNew) {
        setIsLoading(true);
        result = await fetchSearchMessagesById(props.chat.id, '', '', 20, '', search);
      } else {
        setIsLoadingMore(true);
        result = await fetchSearchMessagesById(props.chat.id, searchMessages()[searchMessages().length - 1].sentAt, '', 20, '', search);
      }

      if (result && result.length !== 0) {
        result = result.filter((item) => {
          return (
            item.files &&
            item.files.length !== 0 &&
            item.files.some((file) => {
              return file.fileName.toLowerCase().includes(search.toLowerCase());
            })
          );
        });
      }
      if (isNew) {
        setSearchFilesResult(result || []);
      } else {
        setSearchFilesResult([...searchFilesResult(), ...(result || [])]);
      }

      isNew ? setIsLoading(false) : setIsLoadingMore(false);
    } else {
      let res;
      if (isNew) {
        setIsLoading(true);
        res = await searchChats({
          page: 1,
          pageSize: 20,
          fileName: search,
        });
      } else {
        setIsLoadingMore(true);
        res = await searchChats({
          page,
          pageSize: 20,
          fileName: search,
        });
      }

      let result;
      if (res && res.items.length !== 0) {
        result = res.items.filter((item) => {
          return (
            item.files &&
            item.files.length !== 0 &&
            item.files.some((file) => {
              return file.fileName.toLowerCase().includes(search.toLowerCase());
            })
          );
        });
      }

      if (isNew) {
        setSearchFilesResult(result || []);
      } else {
        setSearchFilesResult([...searchFilesResult(), ...(result || [])]);
      }
      props.chatSearchMode === 'chat' && setSearchFileCount(res.totalCount);
      setCurrentPage(page);
      isNew ? setIsLoading(false) : setIsLoadingMore(false);
    }
  };

  const handleScroll = async (e: Event) => {
    if (isLoadingMore()) return;

    const container = e.target as HTMLDivElement;
    const scrollPosition = container.scrollTop + container.offsetHeight;
    const scrollHeight = container.scrollHeight;
    if (scrollHeight - scrollPosition >= 50) {
      return;
    }

    if (activeTab() === 'messages' && props.chatSearchMode === 'chat') {
      const totalPages = Math.ceil((chatModeMessageResults()?.totalCount || 0) / 20);
      if (currentPage() < totalPages) {
        await getMessageInChatMode(searchText(), currentPage() + 1, true);
      }
    }

    if (activeTab() === 'messages' && props.chatSearchMode === 'message' && props.chat) {
      if (hasMoreMessages()) {
        await getMessageInMessageMode(searchText(), false);
      }
    }

    if (activeTab() === 'files') {
      if (props.chatSearchMode === 'chat') {
        const totalPages = Math.ceil((searchFileCount() || 0) / 20);
        if (currentPage() < totalPages) {
          await getSearchFiles(searchText(), false, currentPage() + 1);
        }
      } else if (hasMoreMessages()) {
        await getSearchFiles(searchText(), false, currentPage() + 1);
      }
    }
  };

  const handleClose = async () => {
    props.onClose && props.onClose();
  };

  const getChatTitle = (chatId: string) => {
    if (!chatId) {
      return '';
    }
    const chat = getRelatedChat(chatId);
    return chat?.subject || '';
  };

  const getRelatedChat = (chatId: string, chatsData?: HydratedMagicChatDto[]) => {
    if (!chatId) {
      return;
    }
    const chatsArr = chatsData || chats();
    return chatsArr.find((item) => item.id === chatId);
  };

  const goToMessage = (chatId: string, message: ChatMessageDto, messageSentAt?: string) => {
    setIsSearchMode(true);

    setChatId(chatId);
    setJumpToMessage(message);
    getMessageInMessageMode('', true, messageSentAt, chatId);
    props.onClose && props.onClose();
    navigate(`/communications/chats/${chatId}`, { replace: true, state: { time: Date.now() } });
  };

  const clearInputText = () => {
    setSearchText('');
    updateData();
  };

  onMount(() => {
    const timer = setTimeout(() => {
      if (!searchInputRef) {
        return;
      }

      searchInputRef.focus();
      clearTimeout(timer);
    });
  });

  return (
    <BaseModal
      title={props.chatSearchMode === 'chat' ? t('Search chats') : t('Chat with', { name: props.chat?.subject })}
      visible={!!props.visible}
      class="w-11/12 lg:w-[700px]"
      closeBtnClass="size-8 rounded-full border border-input-border p-1 text-text-level03"
      onClose={handleClose}
      showCloseButton
      size="lg">
      <div class="relative flex h-[80vh] flex-col">
        <div class="px-6 pb-2 pt-4">
          <div class="relative">
            <input
              class="w-full rounded-lg border py-2 pl-10 pr-4 focus:border-essential-colour focus:outline-none"
              type="text"
              placeholder={`${t('Search')}...`}
              ref={searchInputRef}
              value={searchText()}
              onChange={(e) => {
                const value = e.currentTarget.value.trim();
                setSearchText(value);
                updateData();
              }}
            />
            <div class="absolute left-3 top-1/2 -translate-y-1/2">
              <IconSearch class="size-5 text-gray-400" />
            </div>
            <Show when={searchText().trim() !== ''}>
              <button
                class="absolute right-3 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-[#E9EAF6] hover:bg-[#dbdce9]"
                onClick={clearInputText}>
                <IconClose class={'size-3 text-text-level03'} />
              </button>
            </Show>
          </div>
        </div>

        <div class="flex border-b px-6">
          <div class="flex items-center space-x-4">
            <For each={tabs()}>
              {(tab) => (
                <button
                  class={`min-w-16 py-2 font-medium transition-colors ${
                    activeTab() === tab.id ? 'border-b-2 border-essential-colour text-essential-colour' : 'text-text-level03'
                  }`}
                  onClick={() => setActiveTab(tab.id)}>
                  {tab.label}
                  {/* TODO: The message interface currently does not support totalCount. */}
                  <Show when={props.chatSearchMode === 'chat'}>
                    <span
                      class={`ml-2 rounded-full px-3 text-sm ${
                        activeTab() === tab.id ? 'bg-[#A126EC]/10 text-essential-colour' : 'bg-gray-100 text-text-level03'
                      }`}>
                      {searchText().trim() === '' ? 0 : tab.count}
                    </span>
                  </Show>
                </button>
              )}
            </For>
          </div>
        </div>

        <div class="flex-1 overflow-y-auto" ref={setContainerRef}>
          <Show when={!isLoading()}>
            {activeTab() === 'chats' && (
              <>
                <For each={searchChatsResult()?.items}>
                  {(message) => (
                    <ChatContentSearch
                      title={message.title}
                      content={message.content}
                      participant={message.participants}
                      handleJump={() => {
                        message.chatId && goToMessage(message.chatId, message.messagesInfo as ChatMessageDto);
                      }}
                      highlightText={searchText()}
                    />
                  )}
                </For>
                <Show when={isLoadingMore()}>
                  <div class="flex justify-center py-4">
                    <Skeleton class="size-8 rounded-full" />
                  </div>
                </Show>
              </>
            )}

            {activeTab() === 'messages' && (
              <>
                <Show when={props.chatSearchMode === 'chat'}>
                  <>
                    <For each={chatModeMessageResults()?.items}>
                      {(message) => (
                        <MessageContentSearch
                          message={message.messagesInfo as ChatMessageDto}
                          chatDetails={message.chatsInfo}
                          showHeaderTag
                          highlightText={searchText()}
                          handleJump={() => {
                            setIsJump(true);
                            goToMessage(message.chatId as string, message.messagesInfo as ChatMessageDto, message.sentAt);
                          }}
                        />
                      )}
                    </For>
                    <Show when={isLoadingMore()}>
                      <div class="flex justify-center py-4">
                        <Skeleton class="size-8 rounded-full" />
                      </div>
                    </Show>
                  </>
                </Show>
                <Show when={props.chatSearchMode === 'message' && !isSearchTextEmpty()}>
                  <For each={searchMessages()}>
                    {(message) => (
                      <MessageContentSearch
                        message={message}
                        chatDetails={props.chat}
                        highlightText={searchText()}
                        handleJump={() => {
                          setIsJump(true);
                          goToMessage(message.chatId, message, message.sentAt);
                        }}
                      />
                    )}
                  </For>
                  <Show when={isLoadingMore()}>
                    <div class="flex justify-center py-4">
                      <Skeleton class="size-8 rounded-full" />
                    </div>
                  </Show>
                </Show>
              </>
            )}

            {activeTab() === 'files' && !isSearchTextEmpty() && (
              <div class="flex flex-col divide-y px-6">
                <For each={searchFilesResult()}>
                  {(fileMessage) => <ChatContentPrint message={fileMessage} allMessage={[]} chatDetails={props.chat} />}
                </For>
                <Show when={isLoadingMore()}>
                  <div class="flex justify-center py-4">
                    <Skeleton class="size-8 rounded-full" />
                  </div>
                </Show>
              </div>
            )}
          </Show>
          <Show when={isLoading()}>
            <div class="flex flex-col gap-y-4 pt-4">
              <For each={Array(5)}>
                {() => (
                  <div class="flex flex-row gap-3 px-6 py-1">
                    <Skeleton class="size-10" />
                    <div class="flex-1">
                      <Skeleton class="h-4 w-40" />
                      <Skeleton class="mt-2 h-4 w-full" />
                    </div>
                  </div>
                )}
              </For>
            </div>
          </Show>
          <Show
            when={
              !isLoading() &&
              ((activeTab() === 'chats' && searchChatsResult()?.totalCount === 0) ||
                (activeTab() === 'messages' && props.chatSearchMode === 'chat' && searchChatsResult()?.totalCount === 0) ||
                (activeTab() === 'messages' && props.chatSearchMode === 'message' && searchMessages().length === 0) ||
                isSearchTextEmpty() ||
                (activeTab() === 'files' && searchFilesResult().length === 0))
            }>
            <div class="flex size-full flex-col items-center justify-center text-gray-500">
              <Show when={searchText().trim() !== ''}>
                <img src={IconNoData} alt="" class="size-5/12" />
              </Show>
              <Show when={searchText().trim() === ''}>
                <img src={IconTypeToSearch} alt="" class="size-4/12" />
              </Show>
              <p class="pb-10 text-text-level03">{isSearchTextEmpty() ? t('Type to search') + '...' : t('No data was found')}</p>
            </div>
          </Show>
        </div>
      </div>
    </BaseModal>
  );
};
