import { createEffect, createSignal, For, Show } from 'solid-js';
import IconDelete from '~/assets/images/common/deleteRegular.svg?component-solid';
import IconDrag from '~/assets/images/common/dragDots.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { cloneDeep, uuid } from '~/utils/tool';
import type { JSX } from 'solid-js';
import type { RentalApplicationQuestionDto, RentalApplicationQuestionType } from '~/swagger/Api';

interface QuestionsProps {
  value: RentalApplicationQuestionDto[] | undefined;
  onInput: (value: RentalApplicationQuestionDto[]) => void;
  label?: string;
}
type QuestionTypeMap = {
  input: Question[];
  boolean: Question[];
  select?: Question[];
  multiSelect?: Question[];
};
type ValueType = string | undefined;
type QuestionTypes = 'input' | 'boolean' | 'select' | 'multiSelect';
export type Question = {
  text: string;
  type: RentalApplicationQuestionType;
  options?: Array<{ label: JSX.Element; value: ValueType; oid: string }> | null;
  id: string;
};
export const QuestionList = (props: QuestionsProps) => {
  const { t } = useLocalization();
  const [questions, setQuestions] = createSignal<QuestionTypeMap>({
    input: [],
    boolean: [],
  });
  const [draggedItem, setDraggedItem] = createSignal<Question | null>(null);

  const questionTypeMap = {
    input: {
      title: t('Input'),
      subTitle: t('Add your question here, tenant will fill out their answers'),
    },
    boolean: {
      title: t('Yes-or-No questions'),
      subTitle: '',
    },
    select: {
      title: t('Select'),
      subTitle: t('Single-choice question'),
    },
    multiSelect: {
      title: t('Multiselect'),
      subTitle: t('Multiple-choice question'),
    },
  };

  const formatQuestion = () => {
    const tempQuestion = cloneDeep(questions());
    const formattedQuestions: RentalApplicationQuestionDto[] = [];

    for (const questionType in tempQuestion) {
      tempQuestion[questionType as keyof QuestionTypeMap]?.forEach((question) => {
        const formattedQuestion = {
          ...question,
          options: question.options?.length ? question.options?.map((opt) => opt.value) : undefined,
          type: questionType as QuestionTypes,
        };
        formattedQuestions.push(formattedQuestion as any);
      });
    }
    props.onInput && props.onInput(formattedQuestions);
  };
  const handleChange = (value: string | undefined, qid?: string, oid?: string) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      for (const questionType in updatedQuestions) {
        const questionList = updatedQuestions[questionType as keyof QuestionTypeMap];
        const questionIndex = questionList?.findIndex((q) => q.id === qid);
        if (questionIndex !== undefined && questionIndex !== -1 && questionList) {
          const updatedQuestion = { ...questionList[questionIndex] };

          if (oid) {
            updatedQuestion.options = updatedQuestion.options?.map((option: { oid: string; label: any; value: any }) =>
              option.oid === oid ? { ...option, label: value, value: value } : option
            );
          } else {
            updatedQuestion.text = value || '';
          }

          questionList[questionIndex] = updatedQuestion;
          break;
        }
      }

      return updatedQuestions;
    });
    formatQuestion();
  };
  const handleRemove = (id: string) => {
    const updatedQuestions = cloneDeep(questions());
    for (const key in questions()) {
      if (Object.prototype.hasOwnProperty.call(questions(), key)) {
        const questionList = questions()[key as keyof QuestionTypeMap];
        if (questionList) {
          updatedQuestions[key as keyof QuestionTypeMap] = questionList.filter((question) => question.id !== id);
        }
      }
    }
    setQuestions(updatedQuestions);
    formatQuestion();
  };
  const handleAddOption = (questionId: string, questionType: keyof QuestionTypeMap) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      const questionList = updatedQuestions[questionType];
      const questionIndex = questionList?.findIndex((q) => q.id === questionId);

      if (questionIndex !== undefined && questionIndex !== -1 && questionList) {
        const updatedQuestion = { ...questionList[questionIndex] };
        const newOption = { label: '', value: '', oid: uuid() };
        updatedQuestion.options = [...(updatedQuestion.options || []), newOption];
        questionList[questionIndex] = updatedQuestion;
      }
      return updatedQuestions;
    });
    formatQuestion();
  };
  const handleDeleteOption = (questionId: string, optionOid: string, questionType: keyof QuestionTypeMap) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      const questionList = updatedQuestions[questionType];
      const questionIndex = questionList?.findIndex((q) => q.id === questionId);

      if (questionIndex !== undefined && questionIndex !== -1 && questionList) {
        const updatedQuestion = { ...questionList[questionIndex] };

        if (updatedQuestion.options) {
          updatedQuestion.options = updatedQuestion.options.filter((option) => option.oid !== optionOid);
          questionList[questionIndex] = updatedQuestion;
        }
      }
      return updatedQuestions;
    });
    formatQuestion();
  };
  const handleAddQuestion = (type: QuestionTypes) => {
    let newQuestion;
    switch (type) {
      case 'input':
        newQuestion = {
          text: '',
          type: 'input',
          options: [],
          id: uuid(),
        };
        break;
      case 'boolean':
        newQuestion = {
          text: '',
          type: 'boolean',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
          id: uuid(),
        };
        break;
      case 'select':
      case 'multiSelect':
        newQuestion = {
          text: '',
          type,
          options: [{ label: '', value: '', oid: uuid() }],
          id: uuid(),
        };
        break;
      default:
        break;
    }
    if (newQuestion) {
      const tempQuestion = cloneDeep(questions());
      tempQuestion[type]?.push(newQuestion as Question);
      setQuestions(tempQuestion);
      formatQuestion();
    }
  };
  const handleFormatQuestion = () => {
    const tempQuestions: QuestionTypeMap = {
      input: [],
      boolean: [],
    };
    props.value?.map((i) => {
      tempQuestions[i.type]?.push({
        text: i.text,
        type: i.type,
        options: i.options?.map((i) => ({ label: i, value: i, oid: uuid() })) || [],
        id: uuid(),
      });
    });
    setQuestions(tempQuestions);
  };
  createEffect(() => {
    handleFormatQuestion();
  });

  const QuestionType = (props: { type: string }) => {
    return (
      <div class="mb-3">
        <span class="text-base font-medium text-title-gray">{questionTypeMap[props.type as QuestionTypes]?.title}</span>
        <Show when={questionTypeMap[props.type as QuestionTypes]?.subTitle}>
          <span class="text-xs text-text-level03"> ({questionTypeMap[props.type as QuestionTypes]?.subTitle})</span>
        </Show>
      </div>
    );
  };

  const handleDragStart = (e: DragEvent, item: Question) => {
    setDraggedItem(item);
    if (e.dataTransfer && e.target instanceof HTMLElement) {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/plain', item.id);
      e.target.style.opacity = '0.5';
    }
  };
  const handleDragOver = (e: DragEvent, item: Question) => {
    e.preventDefault();
    if (e.target instanceof HTMLElement && draggedItem() !== item) {
      e.target.style.borderTop = '2px solid #666';
    }
  };

  const handleDragLeave = (e: DragEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.borderTop = '';
    }
  };

  const handleDrop = (e: DragEvent, targetItem: Question) => {
    e.preventDefault();
    const draggedId = e.dataTransfer?.getData('text/plain');
    if (draggedId && draggedId !== targetItem.id) {
      setQuestions((prevQuestions) => {
        const updatedQuestions = { ...prevQuestions };
        for (const questionType in updatedQuestions) {
          const questionList = updatedQuestions[questionType as keyof QuestionTypeMap];
          if (questionList) {
            const draggedIndex = questionList.findIndex((q) => q.id === draggedId);
            const targetIndex = questionList.findIndex((q) => q.id === targetItem.id);
            if (draggedIndex !== -1 && targetIndex !== -1) {
              const [reorderedItem] = questionList.splice(draggedIndex, 1);
              questionList.splice(targetIndex, 0, reorderedItem);
              break;
            }
          }
        }
        return updatedQuestions;
      });
      formatQuestion();
    }
    if (e.target instanceof HTMLElement) {
      e.target.style.borderTop = '';
    }
  };

  const handleDragEnd = (e: DragEvent) => {
    if (e.target instanceof HTMLElement) {
      e.target.style.opacity = '1';
    }
    setDraggedItem(null);
  };

  return (
    <div>
      <For each={Object.entries(questions())}>
        {([key, result]) => (
          <div>
            <QuestionType type={key} />
            <For each={result}>
              {(item) => (
                <div
                  class="relative mb-3 flex overflow-hidden rounded-lg border border-input-border p-3 focus-within:border-primary md:p-4"
                  data-slot="question"
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, item)}
                  onDragOver={(e) => handleDragOver(e, item)}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, item)}
                  onDragEnd={handleDragEnd}>
                  <div class="mr-4 flex cursor-grab items-center active:cursor-grabbing">
                    <IconDrag class="text-text-level03" />
                  </div>
                  <div class="w-full">
                    <LabeledTextInput value={item.text} onInput={(value) => handleChange(value, item.id)} />
                    <Show when={item.type === 'select' || item.type === 'multiSelect'}>
                      <For each={item.options}>
                        {(option) => (
                          <div class="group relative mt-3 flex items-center rounded-lg border border-transparent px-2 py-0 transition-all duration-200 focus-within:border-solid focus-within:border-essential-colour focus-within:hover:border-essential-colour hover-allowed:hover:border-dashed hover-allowed:hover:border-[#CCD4F0] focus-within:hover-allowed:hover:border-solid">
                            <Checkbox
                              showLabel={false}
                              htmlType={item.type === 'select' ? 'radio' : 'checkbox'}
                              class="hover-allowed:hover:before:opacity-0"
                              checkBoxClass="py-2"
                            />
                            <input
                              class="w-full bg-transparent px-3 py-2 text-black outline-none placeholder:text-auxiliary-text"
                              placeholder={t('Please enter the contents of option')}
                              value={option?.value as string}
                              onChange={(e) => handleChange(e.target.value, item.id, option.oid)}
                            />
                            <IconDelete
                              class="absolute inset-y-1/2 right-2 -translate-y-1/2 cursor-pointer opacity-0 hover-allowed:group-hover:opacity-100"
                              onClick={() => handleDeleteOption(item.id, option.oid, item.type as keyof QuestionTypeMap)}
                            />
                          </div>
                        )}
                      </For>
                    </Show>
                    <Show when={['select', 'multiSelect'].includes(item.type)}>
                      <div class="mt-3 flex justify-start pl-2 text-sm text-primary">
                        <IconPlus class="size-5" />
                        <span class="ml-1 cursor-pointer" onClick={() => handleAddOption(item.id, item.type as keyof QuestionTypeMap)}>
                          {t('Add option')}
                        </span>
                      </div>
                    </Show>
                  </div>
                  <div class="ml-4 flex items-center border-l border-input-border pl-3" onClick={() => handleRemove(item.id)}>
                    <span class="flex size-7 cursor-pointer items-center justify-center rounded-full border border-text-level03">
                      <IconDelete />
                    </span>
                  </div>
                </div>
              )}
            </For>
            <div class="mb-8 flex justify-start text-sm text-link">
              <IconPlus class="size-5" />
              <span class="ml-1 cursor-pointer" onClick={() => handleAddQuestion(key as QuestionTypes)}>
                {t('Add additional questions')}
              </span>
            </div>
          </div>
        )}
      </For>
    </div>
  );
};
