import { Routes, Route } from '@solidjs/router';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { DraftRentalApplicationsPage } from './DraftRentalApplicationsPage';
import { RentalApplicationsPage } from './RentalApplicationsPage';

export const RentalApplicationsPageRoutes = () => {
  const { t } = useLocalization();

  const leaseRouteOptions = [
    { label: t('Submitted'), href: '' },
    { label: t('Unfinished'), href: 'draft-rental-applications' },
  ];

  return (
    <div class="flex size-full flex-col gap-y-4 p-3 md:p-8">
      <div class="hidden w-full items-center justify-between overflow-hidden rounded-lg border border-partingline bg-white md:flex">
        <TabLinks items={leaseRouteOptions} />
      </div>
      <Routes>
        <Route path="/" component={RentalApplicationsPage} />
        <Route path="/draft-rental-applications" component={DraftRentalApplicationsPage} />
      </Routes>
    </div>
  );
};
