import { A } from '@solidjs/router';
import { For, Show, createEffect, createMemo, createSignal, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import NoDataImg from '~/assets/images/empty/noData.svg';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { DropdownActions } from '~/components/ui/DropdownActions';
import { IconChevronDown } from '~/components/ui/Icons';
import { Skeleton } from '~/components/ui/Skeleton';
import { ViewAllButton } from '~/components/ui/Table/ViewAllButton';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { ComponentProps, JSX } from 'solid-js';
import type { DropdownAction } from '~/components/ui/DropdownActions';
import type { Merge } from '~/utils/types';

export type TableColumns<T> = TableColumn<T>[];

export type TableColumn<T> = { title?: JSX.Element; class?: string; headerClass?: string } & (
  | { key: keyof T; render?: (item: T, value?: any) => JSX.Element }
  | { render: (item: T, value?: any) => JSX.Element }
);

export type TableProps<T> = {
  data?: T[];
  columns: TableColumn<T>[];
  loading?: boolean;
  skeletonSize?: number;
  empty?: JSX.Element;
  rowLink?: (item: T) => string;
  onRowClick?: (item: T) => void;
  onViewAllClicked?: (rowIndex: number, status: boolean) => void;
  selected?: T[];
  onSelect?: (selected: T[]) => void;
  actions?:
    | ComponentProps<typeof DropdownActions>['actions']
    | ((item: T, rowIndex: number) => ComponentProps<typeof DropdownActions>['actions']);
  theadClass?: string;
  tbodyClass?: string;
  rowClass?: string;
  viewAllSuffix?: string;
  getTotalRowCount?: (item: T) => number;
  expandable?: (item: T) => JSX.Element;
};

export const hasRender = <T,>(column: TableColumn<T>): column is { render: (item: T) => JSX.Element } => {
  return typeof column.render === 'function';
};

const Cell = (props: ComponentProps<'div'>) => {
  return (
    <div class={cn('table-cell break-keep px-5 py-3 text-left align-middle', props.class)} role={props.role}>
      {props.children}
    </div>
  );
};

const Row = <T extends Record<string, any>>(props: {
  columns: TableColumn<T>[];
  item: TableRowData<T>;
  class?: string;
  level?: number; // Indentation level
  href?: string;
  onClick?: () => void;
  onViewAllClicked?: (rowIndex: number, status: boolean) => void;
  onChecked?: (checked: boolean) => void;
  selected?: boolean;
  actions?:
    | ComponentProps<typeof DropdownActions>['actions']
    | ((item: T, rowIndex: number) => ComponentProps<typeof DropdownActions>['actions']);
  rowIndex: number;
  viewAllSuffix?: string;
  getTotalRowCount?: (item: T) => number;
  expandable?: (item: T) => JSX.Element;
}) => {
  const handleClick = (e: MouseEvent) => {
    let current = e.target as HTMLElement | null;
    while (current != null && current !== e.currentTarget) {
      if (current.tagName === 'BUTTON') {
        e.preventDefault();
        return;
      }
      current = current.parentElement;
    }
    const selection = window.getSelection();
    if (selection?.type === 'Range') return;
    props.onClick?.();
  };

  const [showAll, setShowAll] = createSignal(false);
  const [isExpanded, setIsExpanded] = createSignal(false);

  let rowRef: any;

  const toggleViewAll = (rowIndex: number) => {
    setShowAll(!showAll());
    props.onViewAllClicked?.(rowIndex, showAll());
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded());
  };

  const getColumnRender = (col: TableColumn<T>) => {
    if ('key' in col) {
      const value = props.item[col.key];
      if (Array.isArray(value) && !showAll()) {
        return col.render?.(props.item, value.slice(0, 1));
      }
      return col.render?.(props.item, value);
    }
    return col.render(props.item);
  };

  const totalCount = createMemo(() => props.getTotalRowCount?.(props.item) ?? 0);

  const totalCol = createMemo(() => {
    let count = props.columns.length;
    if (props.selected) count++;
    if (props.actions) count++;
    if (props.viewAllSuffix) count++;
    if (props.expandable) count++;
    return count;
  });

  return (
    <>
      <Dynamic
        component={props.href ? A : 'div'}
        href={props.href}
        ref={rowRef}
        class={cn('box relative table-row select-text overflow-hidden text-xs transition-colors md:text-sm', props.class, {
          'bg-white': (props.level ?? 0) === 0,
          'bg-[#FAFAFA]': (props.level ?? 0) > 0,
          'cursor-pointer hover-allowed:hover:bg-[#FAFBFD]': props.onClick != null || props.href != null,
          'border-2 border-inputbox-bg': (props.level ?? 0) === 0 && props.item.children && props.item.children.length > 0,
          'bg-[#A126EC05]': isExpanded(),
        })}
        role="row"
        onClick={handleClick}>
        <Show when={props?.expandable != null}>
          <Cell role="cell" style={{ 'padding-left': `${(props.level ?? 0) * 30}px` }}>
            <button onClick={toggleExpand}>
              <IconChevronDown
                classList={{
                  'rotate-180': isExpanded(),
                }}
                class="size-5 cursor-pointer text-title-gray transition-transform"
              />
            </button>
          </Cell>
        </Show>
        <Show when={props.selected != null}>
          <Cell role="cell" style={{ 'padding-left': `${(props.level ?? 0) * 30}px` }}>
            <Checkbox checkBoxClass="block" checked={props.selected} onInput={props.onChecked} onClick={(e) => e.stopPropagation()} />
          </Cell>
        </Show>

        <For each={props.columns}>
          {(column, index) => (
            <Cell class={column.class} role="cell">
              <div style={{ 'padding-left': index() === 0 ? `${(props.level ?? 0) * 30}px` : '0px' }}>
                {hasRender(column) ? getColumnRender(column) : props.item[column.key]}
              </div>
            </Cell>
          )}
        </For>

        <Show when={props.actions}>
          <Cell role="cell">
            <DropdownActions
              actions={
                typeof props.actions === 'function' ? props.actions(props.item, props.rowIndex) : (props.actions as DropdownAction[])
              }
            />
          </Cell>
        </Show>
        <Show when={props.viewAllSuffix && totalCount() > 1}>
          <ViewAllButton
            class="absolute bottom-[-10px] left-1/2 -translate-x-1/2 cursor-pointer rounded-t-lg border bg-white px-2 text-xs hover-allowed:hover:bg-essential-colour/80 hover-allowed:hover:text-white"
            onClick={() => toggleViewAll(props.rowIndex)}>
            {showAll()
              ? `Collapse ${props.viewAllSuffix ? props.viewAllSuffix : ''}`
              : `View all ${props.viewAllSuffix ? props.viewAllSuffix : ''} +${totalCount() - 1}`}
          </ViewAllButton>
        </Show>
      </Dynamic>
      <Show when={props.expandable != null && isExpanded()}>
        <tr class="table-row bg-[#F9FAFC]">
          <td colspan={totalCol()}>{props.expandable?.(props.item)}</td>
        </tr>
      </Show>
    </>
  );
};

const Empty = (props: { colSpan: number; empty?: JSX.Element }) => {
  const { t } = useLocalization();
  return (
    <td colSpan={props.colSpan}>
      <Show when={props.empty == null} fallback={props.empty}>
        <div class="p-10 text-center text-text-level03">
          <img src={NoDataImg} class="mx-auto mb-5 w-44" alt={t('No results found')} />
          {t('No results found')}
        </div>
      </Show>
    </td>
  );
};

const TableSkeleton = (props: {
  columns: TableColumns<any>;
  size: number;
  selected?: boolean;
  actions?: boolean;
  onViewAllClicked?: (rowIndex: number, status: boolean) => void;
  expandable?: boolean;
}) => {
  const columns = createMemo(() => {
    const rows = { length: props.columns.length };
    props.selected && rows.length++;
    props.actions && rows.length++;
    props.expandable && rows.length++;
    return Array.from(rows, () => ({ render: () => <Skeleton class="h-5" /> }));
  });
  return (
    <For each={Array.from({ length: props.size })}>
      {(_, index) => <Row rowIndex={index()} columns={columns()} item={{}} onViewAllClicked={props.onViewAllClicked} />}
    </For>
  );
};

export const Table = <T extends Record<string, any>>(props: Merge<ComponentProps<'div'>, TableProps<T>>) => {
  const [params, rest] = splitProps(props, [
    'class',
    'data',
    'columns',
    'loading',
    'skeletonSize',
    'empty',
    'selected',
    'onSelect',
    'rowLink',
    'onRowClick',
    'actions',
    'theadClass',
    'tbodyClass',
    'rowClass',
    'onViewAllClicked',
    'viewAllSuffix',
    'getTotalRowCount',
    'expandable',
  ]);

  const { t } = useLocalization();

  const [selected, setSelected] = createSignal<T[]>();

  const renderRow = (item: TableRowData<T>, level = 0, rowIndex = 0) => {
    return (
      <>
        <Row
          columns={params.columns}
          item={item}
          level={level}
          class={params.rowClass}
          href={params.rowLink?.(item)}
          selected={selected()?.includes(item)}
          onClick={params.onRowClick && (() => params.onRowClick?.(item))}
          onChecked={(checked) => handleSelect(checked, item)}
          actions={params.actions}
          onViewAllClicked={params.onViewAllClicked}
          rowIndex={rowIndex}
          viewAllSuffix={params.viewAllSuffix}
          getTotalRowCount={params.getTotalRowCount}
          expandable={params.expandable}
        />

        <For each={item.children ?? []}>{(child) => renderRow(child, level + 1)}</For>
      </>
    );
  };

  createEffect(() => setSelected(params.selected));

  const handleSelect = (checked: boolean, item: T) => {
    setSelected((prev) => {
      if (checked) {
        return [...(prev ?? []), item];
      } else {
        return prev?.filter((i) => i !== item);
      }
    });
    params.onSelect?.(selected() ?? []);
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelected(params.data);
    } else {
      setSelected([]);
    }
    params.onSelect?.(selected() ?? []);
  };

  return (
    <div class="thinscroll w-full overflow-auto">
      <div {...rest} class={cn('table w-full border-collapse', params.class)} role="table">
        <div class={cn('sticky top-0 z-[1] table-header-group bg-white text-xs text-text-level03')} role="rowgroup">
          <div class={cn('table-row bg-input', params.theadClass)} role="row">
            <Show when={params.expandable != null}>
              <Cell class="w-15" role="columnheader" />
            </Show>
            <Show when={selected() != null}>
              <Cell class="w-15" role="columnheader">
                <Checkbox
                  checkBoxClass="block"
                  checked={params.data != null && params.data.length != 0 && selected()?.length === params.data.length}
                  onInput={handleSelectAll}
                />
              </Cell>
            </Show>
            <For each={params.columns}>
              {(column) => (
                <Cell class={column.headerClass} role="columnheader">
                  {column.title}
                </Cell>
              )}
            </For>
            <Show when={params.actions}>
              <Cell class="w-18" role="columnheader">
                <div class="sr-only">{t('Operation')}</div>
              </Cell>
            </Show>
          </div>
        </div>
        <div class={cn('table-row-group divide-y divide-input-border', params.tbodyClass)} role="rowgroup">
          <Show
            when={!params.loading}
            fallback={
              <TableSkeleton
                columns={params.columns}
                size={params.skeletonSize ?? 5}
                selected={selected() != null}
                actions={params.actions != null}
                onViewAllClicked={params.onViewAllClicked}
                expandable={params.expandable != null}
              />
            }>
            <Show
              when={params.data == null || params.data.length > 0}
              fallback={<Empty colSpan={params.columns.length + 2} empty={params.empty} />}>
              <For each={params.data ?? []}>{(item, rowIndex) => renderRow(item, 0, rowIndex())}</For>
            </Show>
          </Show>
        </div>
      </div>
    </div>
  );
};

export type TableRowData<T> = T & { children?: T[] };
