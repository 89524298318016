import { FormItem, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { EmploymentSubForm } from '~/components/rental-application/rental-application-details/infomation/work-history/WorkHistoryModal';
import { useLocalization } from '~/contexts/global';
import { MAX_INPUT_AMOUNT } from '~/utils/constant';

export const Employment = () => {
  const { t } = useLocalization();

  return (
    <>
      <Section required border title={t('Income Information')}>
        <FormItem
          id="new-rental-application-step3-annualIncome"
          label={t('Annual income')}
          placeholder={t('Input Annual Income')}
          formFieldName={['incomeInformation', 'annualIncome']}
          component={LabeledNumberInput}
          prepend="$"
          max={MAX_INPUT_AMOUNT}
          min={0}
          rules={[
            { required: true, message: t('Please input annual income') },
            { type: 'number', message: t('Please input a valid number') },
          ]}
        />
      </Section>
      <Section border title={t('Employment')}>
        <FormList
          formFieldName={'employment'}
          renderItemsGroup={({ index, remove }) => {
            return (
              <FormListRemoverWrapper index={index} remove={remove} showRemove={index() > 0}>
                <EmploymentSubForm index={index()} />
              </FormListRemoverWrapper>
            );
          }}>
          {({ add }) => {
            return (
              <Button id="new-rental-application-step3-add-more" variant="outlined" onClick={() => add()}>
                {t('Add more')}
              </Button>
            );
          }}
        </FormList>
      </Section>
    </>
  );
};
