import { ChatWindow } from '~/components/common/ChatWindow';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';

export const GroupChatTab = () => {
  const { t } = useLocalization();
  const { maintenanceRequest } = useMaintenanceRequests();

  return (
    <Panel title={t('Communications')} class="flex h-section flex-col">
      <ChatWindow
        participantId={maintenanceRequest()?.workOrder?.id}
        chatId={maintenanceRequest()?.workOrder?.groupChatId}
        participantType="workOrderGroup"
        class="h-section2 overflow-hidden rounded-lg border-none"
      />
    </Panel>
  );
};
