import { createMemo, createSignal, Match, onMount, Switch, Show } from 'solid-js';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import IconSwitch from '~/assets/images/common/switch.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { Radio } from '~/components/common/Inputs/Radio';
import { LeaseTitle } from '~/components/leases';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import { PropertyTitle } from '~/components/properties';
import { IconLink, ListView, toast } from '~/components/ui';
import { UnitTitle } from '~/components/units';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { emptyPlaceholder, workOrderStatusTypes } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { getImageUrl } from '~/utils/file';
import { BindingLeaseModal } from './BindingLeaseModal';
import { BindingMaintenanceRequestModal } from './BindingMaintenanceRequestModal';
import { BindingVendorModal } from './BindingVendorModal';
import type { WorkOrdersFilter } from '~/repositories/workOrderRepository';

type WorkOrderListItem = MagicDoor.Api.WorkOrderListDto & {
  unit?: MagicDoor.Api.UnitDto;
  property?: MagicDoor.Api.PropertyDto;
  lease?: MagicDoor.Api.LeaseDto;
  vendor?: MagicDoor.Api.VendorDto;
  propertyManager?: MagicDoor.Api.PropertyManagerDto;
};

export const WorkOrderTable = (props: { title: string; constantParams?: WorkOrdersFilter }) => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('WorkOrdersListView', {
    currentPage: 1,
    pageSize: 10,
  });

  const { filtered, setFilter, updateWorkOrderStatus, deleteWorkOrder } = useWorkOrders();
  const [maintenanceRequestVisible, setMaintenanceRequestVisible] = createSignal<boolean>(false);
  const [vendorVisible, setVendorVisible] = createSignal<boolean>(false);
  const [leaseVisible, setLeaseVisible] = createSignal<boolean>(false);

  const [workOrderId, setWorkOrderId] = createSignal<string>('');

  const listData = createMemo<WorkOrderListItem[]>(() =>
    (filtered()?.items || []).map((item: MagicDoor.Api.WorkOrderListDto) => {
      const leaseTemp = filtered()?.leases?.find((lease) => lease.id === item.leaseId);
      const tenantsId = leaseTemp?.tenants?.map((tenant) => tenant.tenantId) || [];
      return {
        ...item,
        property: filtered()?.properties?.find((property) => property.id === item.propertyId),
        unit: filtered()?.units?.find((unit) => unit.id === item.unitId),
        lease: leaseTemp
          ? ({
              ...leaseTemp,
              tenants: filtered()
                ?.tenants.filter((tenant) => tenantsId.includes(tenant.id))
                .map((tenant) => ({ tenant })),
            } as any)
          : undefined,
        vendor: filtered()?.vendors?.find((vendor) => vendor.id === item.vendorId),
        propertyManager: filtered()?.propertyManagers.find((propertyManager) => propertyManager.id === item.assignedPropertyManagerId),
      };
    })
  );

  const bindingMaintenanceRequest = (item: WorkOrderListItem) => {
    setWorkOrderId(item.id);
    setMaintenanceRequestVisible(true);
  };

  const bindingVendor = (item: WorkOrderListItem) => {
    setWorkOrderId(item.id);
    setVendorVisible(true);
  };

  const handleStatusChange = async (id: string, status: `${MagicDoor.Api.Status}`) => {
    await updateWorkOrderStatus(id, {
      status,
    } as MagicDoor.Api.UpdateWorkOrderStatusDto);
    toast.success(t('Operation successful'));
  };

  const handleDeleteWorkOrder = async (item: WorkOrderListItem) => {
    await deleteWorkOrder(item.id);
    toast.success(t('{name} has been deleted successfully', { name: t('Work order') }));
  };

  onMount(() => setFilter({ page: page(), pageSize: pageSize(), ...props.constantParams }));

  return (
    <>
      <ListView
        class="mb-4"
        title={t(props.title)}
        onChange={({ page, pageSize }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, ...props.constantParams });
        }}
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={listData()}
        loading={filtered.loading}
        skeletonSize={10}
        rowLink={(item) => `/maintenance/work-orders/${item.id}`}
        columns={[
          {
            title: t('Title'),
            render: (row) => (
              <>
                <div class="mb-1 font-medium">{row.title}</div>
                <div class="text-gray-400">#{row.reference}</div>
              </>
            ),
          },
          {
            title: t('Priority'),
            render: (row) => <MaintenanceRequestUrgency urgency={row.urgency} />,
          },
          {
            title: t('Selective type'),
            headerClass: 'w-[30%]',
            render: (row) => (
              <Switch fallback={emptyPlaceholder}>
                <Match when={row.property && !row.unit && !row.lease}>
                  <PropertyTitle property={row.property} />
                </Match>
                <Match when={row.unit && !row.lease}>
                  <UnitTitle property={row.property} unit={row.unit as MagicDoor.Api.HydratedUnitDto} />
                </Match>
                <Match when={row.lease}>
                  <LeaseTitle property={row.property} unit={row.unit} lease={row.lease} showAllTenants />
                </Match>
              </Switch>
            ),
          },
          {
            title: t('Assignee'),
            render: (row) => {
              return (
                <Show when={row.propertyManager}>
                  <Avatar
                    class="size-9 shrink-0"
                    imgUrl={getImageUrl('avatar', row?.propertyManager?.id as string)}
                    name={row?.propertyManager?.name ?? ''}
                  />
                </Show>
              );
            },
          },
          {
            title: t('Vendor'),
            render: (row) => <div>{row.vendor?.name || emptyPlaceholder}</div>,
          },
          {
            title: t('Due date'),
            render: (row) => <div>{dateFormat('', row.dueDate)}</div>,
          },
          {
            title: t('Create time'),
            render: (row) => <div>{dateFormat('', row.created)}</div>,
          },
        ]}
        actions={(item) => [
          {
            label: t('Modify Status'),
            icon: IconSwitch,
            children: (
              <Radio
                direction="column"
                class="w-28"
                items={workOrderStatusTypes.map((item) => ({ ...item, label: t(item.label) }))}
                value={item.status}
                onInput={(value) => handleStatusChange(item.id, value as MagicDoor.Api.Status)}
              />
            ),
          },
          {
            label: t(item?.maintenanceRequestId ? 'Change maintenance request' : 'Assign maintenance request '),
            icon: () => (
              <div class="text-level03 flex size-4 items-center justify-center rounded-full">
                <IconLink class="size-4" />
              </div>
            ),
            onClick: () => bindingMaintenanceRequest(item),
          },
          {
            label: t(item.vendorId ? 'Change vendor' : 'Assign vendor'),
            icon: () => (
              <div class="text-level03 flex size-4 items-center justify-center rounded-full">
                <IconLink class="size-4" />
              </div>
            ),
            onClick: () => bindingVendor(item),
          },
          //TODO: Need to fix it so it updates the current selective type correctly
          // {
          //   label: t('Binding lease'),
          //   icon: IconLink,
          //   onClick: () => bindingLease(item),
          // },
          {
            label: t('Edit'),
            icon: IconPencil,
            href: `${item.id}/edit`,
          },
          {
            label: t('Close'),
            icon: () => (
              <div class="flex size-4 items-center justify-center rounded-full border border-text-level03">
                <IconClose class="size-2" />
              </div>
            ),
            onClick: () => handleStatusChange(item.id, 'closed'),
          },
          {
            label: t('Delete'),
            icon: IconDelete,
            onClick: () => handleDeleteWorkOrder(item),
          },
        ]}
      />
      <BindingMaintenanceRequestModal workOrderId={workOrderId()} visible={[maintenanceRequestVisible, setMaintenanceRequestVisible]} />
      <BindingVendorModal workOrderId={workOrderId()} visible={[vendorVisible, setVendorVisible]} />
      <BindingLeaseModal workOrderId={workOrderId()} visible={[leaseVisible, setLeaseVisible]} />
    </>
  );
};
