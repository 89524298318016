import { dataKeyName, namePathToString } from '~/components/common/BetterForm/utils';

export const scrollToFormField = (errorFields: any[]) => {
  for (const field of errorFields) {
    const name = field.name;
    if (!name) continue;
    const formField = document.querySelector(`[${dataKeyName}="${namePathToString(name)}"]`);
    if (formField) {
      formField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      break;
    }
  }
};
