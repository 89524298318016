import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconAiGradientBg from '~/assets/images/chat/aiChatGradientBg.png';
import IconAiStar from '~/assets/images/chat/aiChatStar.svg?component-solid';
import IconAiStarBg from '~/assets/images/chat/aiChatStarBg.png';
import { useLocalization } from '~/contexts/global';

interface AiGenerateMessageBannerProps {
  onClick: () => void;
}

export const AiGenerateMessageBanner = (props: AiGenerateMessageBannerProps) => {
  const { t } = useLocalization();

  return (
    <div class="bg-gradient-purple-blue">
      <div
        class="flex h-12 items-center justify-between bg-contain bg-left bg-no-repeat px-8 text-white"
        style={{
          'background-image': `url(${IconAiGradientBg}), url(${IconAiStarBg})`,
          'background-position': 'left, right',
          'background-repeat': 'no-repeat, no-repeat',
        }}>
        <div class="flex gap-6">
          <div class="flex items-center justify-center gap-2 text-xs font-medium md:text-sm">
            <img src={IconAiBot} class="hide-on-mobile" />
            <span>{t('Use AI to polish your message')}</span>
          </div>
        </div>
        <button
          onClick={() => props.onClick()}
          class="flex gap-2 rounded-full border border-white bg-gradient-to-br from-[#FFC38C] to-[#D9511F] px-3 py-1 text-xs font-medium hover-allowed:hover:bg-orange-600 md:text-sm">
          <IconAiStar class="hide-on-mobile text-white" />
          {t('Magic it')}
        </button>
      </div>
    </div>
  );
};
