import { useParams } from '@solidjs/router';
import { Show, createEffect, createSignal, onMount, onCleanup } from 'solid-js';
import AccountSettingIcon from '~/assets/images/leases/accountSetting.svg?component-solid';
import ChargeSticker from '~/assets/images/leases/chargeSticker.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Radio } from '~/components/common/Inputs/Radio';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseRentCharges } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { formConfirmationContent } from '~/utils/constant';
import { currency } from '~/utils/number';
import type { Accessor, Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type EditChargeModalProps = Omit<ModalProps, 'title' | 'doneText' | 'submitId' | 'loading'> & {
  updatingCharge: Accessor<MagicDoor.Api.HydratedRecurringLeaseChargeDto | undefined>;
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const EditChargeModal: Component<EditChargeModalProps> = (props) => {
  const { t } = useLocalization();
  const params = useParams();
  const form = useForm();

  const { triggerUpdate } = useUpdates();

  const { addRecurringCharge, updateRecurringCharge } = useLeaseRentCharges();

  const [loading, setLoading] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const isCreating = () => props.updatingCharge() == null;

  form.setFormStore({ chargeFrequency: 'monthly', companyCharge: false });

  createEffect(() => {
    const charge = props.updatingCharge();
    if (charge == null) return;
    form.setFormStore({
      chargeFrequency: charge.chargeFrequency,
      startFrom: charge.startFrom,
      end: charge.end,
      chartOfAccountId: charge.chartOfAccountId,
      description: charge.description,
      amount: charge.amount,
      allowPastCharges: false,
      companyCharge: charge.companyCharge,
    });
  });

  const handleCancel = () => {
    form.resetFields(['end', 'chartOfAccountId', 'description', 'amount', 'allowPastCharges', 'companyCharge']);
    props.onCancel?.();
  };

  const handleDone = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) {
      return;
    }
    setLoading(true);

    const { chargeFrequency, startFrom, end, chartOfAccountId, description, amount, allowPastCharges, companyCharge } = form.formStore;
    const basePayload = { chargeFrequency, startFrom, end, chartOfAccountId, description, amount, companyCharge };

    try {
      if (isCreating()) {
        await addRecurringCharge(params.leaseId, {
          ...basePayload,
          createPastCharges: allowPastCharges,
        });
      } else {
        const chargeId = props.updatingCharge()?.id;
        if (chargeId == null) return;
        await updateRecurringCharge(params.leaseId, chargeId, {
          ...basePayload,
          updatePastCharges: allowPastCharges,
        });
      }

      triggerUpdate();

      form.resetFields(['end', 'chartOfAccountId', 'description', 'amount', 'allowPastCharges', 'companyCharge']);
      props.onDone?.();

      toast.success(t(`{name} has been ${isCreating() ? 'added' : 'edited'} successfully`, { name: t('Recurring charge') }));
    } finally {
      setLoading(false);
    }
  };

  let oldDataStr = '';
  onMount(() => {
    oldDataStr = JSON.stringify(form.formStore);
  });

  createEffect(() => {
    const newDataStr = JSON.stringify(form.formStore);
    setShowCloseConfirmation(newDataStr !== oldDataStr);
  });

  onCleanup(() => {
    form.setFormStore({});
  });

  return (
    <Modal
      {...props}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}
      title={t(isCreating() ? 'Set up recurring charge' : 'Edit recurring charge')}
      doneText={t('Save')}
      loading={loading()}
      onCancel={handleCancel}
      onDone={handleDone}>
      <div class="thinscroll overflow-auto px-8 py-6">
        <LeaseOverviewBanner class="mb-6" lease={props.currentLease} />
        <Form class="grid grid-cols-2 gap-x-7 gap-y-6" defaultForm={form}>
          <FormItem
            class="col-span-full lg:col-span-1"
            component={DropdownMenu}
            formFieldName="chargeFrequency"
            rules={[{ required: true, message: t('Please select frequency') }]}
            onChangeMethodName="onChange"
            label={t('Frequency')}
            options={[
              { label: t('Monthly'), value: 'monthly' },
              { label: t('Twice a month'), value: 'monthly2XSplit' },
              { label: t('Weekly'), value: 'weekly' },
              { label: t('Daily'), value: 'daily' },
            ]}
            placeholder={t('Please enter')}
          />
          <FormItem
            class="col-span-full lg:col-span-1 lg:col-start-1"
            component={DueDateInputField}
            formFieldName="startFrom"
            required
            rules={[{ required: true, message: t('Please select start date') }]}
            label={t('Start date')}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            component={DueDateInputField}
            formFieldName="end"
            rules={[
              { validator: (value) => value == null || value >= form.formStore.startFrom, message: t('End date must be after start date') },
            ]}
            label={t('End date')}
          />
          <FormItem
            component={Radio}
            formFieldName="companyCharge"
            label={t('Charge Type')}
            class="col-span-full flex w-full flex-col gap-4 pr-20 sm:flex-row sm:justify-between [&>*]:w-full [&>*]:sm:w-auto"
            items={[
              { label: t('This is a property bill'), value: false },
              { label: t('This is a company bill'), value: true },
            ]}
          />

          <h3 class="col-span-2 flex gap-2 border-b border-partingline py-3 font-semibold text-text-level01">
            <AccountSettingIcon />
            {t('Chart of account settings')}
          </h3>
          <FormItem
            class="col-span-full lg:col-span-1"
            component={LabeledChartOfAccountSelect}
            formFieldName="chartOfAccountId"
            rules={[{ required: true, message: t('Please select chart of account') }]}
            label={t('Chart of account')}
            types={'revenue' as any}
            placeholder={t('Please enter')}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            component={LabeledNumberInput}
            formFieldName="amount"
            rules={[{ required: true, message: t('Please input the amount') }]}
            label={t('Amount')}
            placeholder={t('Please enter')}
            min={1}
          />
          <FormItem
            class="col-span-2"
            component={LabeledTextInput}
            formFieldName="description"
            rules={[{ required: true, message: t('Please input the description') }]}
            label={t('Description')}
            placeholder={t('Please enter')}
            maxlength={200}
          />
          <div class="col-span-2">
            <FormItem
              component={Checkbox}
              formFieldName="allowPastCharges"
              showLabel
              label={isCreating() ? t('Update past charges') : t('Include past charges')}
            />
          </div>
        </Form>
      </div>
      <div class="relative mt-3 flex h-18 shrink-0 items-center justify-end bg-light-pink px-4 lg:px-10">
        <ChargeSticker class="absolute -top-2 left-5 overflow-hidden" />
        <Show when={form.formStore.amount}>
          <span class="mr-2 text-xs uppercase text-text-level03 lg:text-sm">{t('Total amount')}: </span>
          <span class="text-sm font-bold text-essential-colour lg:text-2xl">{currency(form.formStore.amount)}</span>
        </Show>
      </div>
    </Modal>
  );
};
