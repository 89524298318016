import { Show } from 'solid-js';
import type { Component } from 'solid-js';
type UnitMetricProps = {
  icon: string;
  title: string;
  value?: string | number;
  suffix?: string;
};

export const UnitMetric: Component<UnitMetricProps> = (props) => {
  return (
    <div class="grow-1 flex basis-40 items-center space-x-2 text-left">
      <div class="size-9 rounded-full bg-inputbox-bg p-2">
        <img src={props.icon} alt={props.title} />
      </div>
      <div>
        <div class="text-xs text-text-level03">{props.title}</div>
        <div>
          <span class="text-sm text-text-level01">{props.value}</span>
          <Show when={!!props.suffix}>
            <span class="text-xs text-text-level03"> {props.suffix}</span>
          </Show>
        </div>
      </div>
    </div>
  );
};
