import { createContext, useContext } from 'solid-js';
import type { ParentProps } from 'solid-js';

const contextStore: Record<string, any> = {};

/**
 * do not use this helper outside of contexts, use `utils/contextCreator` instead
 */
export const createMagicDoorContext = <T, U extends Record<string, any> = Record<string, any>>(
  name: string,
  factory: (props: ParentProps<U>) => T
) => {
  // 加上这一行，开发模式下，可以避免修改 context 文件之后， useContext 找不到 context，报错的问题
  const Context = contextStore[name] ?? (contextStore[name] = createContext<T>());

  const Provider = (props: ParentProps<U>) => {
    if (import.meta.env.DEV) {
      const ctx = useContext(Context);
      if (ctx != null) {
        throw new Error(`${name} Provider should not be nested within same provider`);
      }
    }
    const value = factory(props);
    return Context.Provider({
      value,
      get children() {
        return props.children;
      },
    });
  };

  const use = () => {
    const value = useContext(Context);
    if (value != null) return value as T;
    throw new Error(`${name} Context must be used within ${name} Provider`);
  };

  return [Provider, use] as const;
};
