import { useParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onMount, Show } from 'solid-js';
import { useFormContext } from '~/components/common/BetterForm';
import { PageCloseGuard } from '~/components/common/PageCloseGuard';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useNewRentalApplication } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { Employment } from '~/pages/rental-applications/add-rental-application/Employment';
import { Identity } from '~/pages/rental-applications/add-rental-application/Identity';
import { QuestionAndTerms } from '~/pages/rental-applications/add-rental-application/QuestionAndTerms';
import { RentalHistory } from '~/pages/rental-applications/add-rental-application/RentalHistory';
import { TenantProfile } from '~/pages/rental-applications/add-rental-application/TenantProfile';

export const StepsBeforePayment = () => {
  const goBack = useGoBack();
  const form = useFormContext();
  const { onStepChange, currentStep, steps, setCurrentStep, disableNext } = useNewRentalApplication();

  const { rentalApplication } = useNewRentalApplication();

  const params = useParams<{
    applicationId: string;
    step: string;
  }>();

  const components = createMemo(() => {
    const base = [TenantProfile, RentalHistory, Employment, Identity, QuestionAndTerms];
    return base;
  });

  createEffect(() => {
    if (params.step) {
      const step = steps().findIndex((step) => step.key === params.step);
      if (step !== -1) {
        setCurrentStep(step);
      }
    }
  });

  const [isDirty, setIsDirty] = createSignal(false);

  const handleSubmit = async () => {
    await onStepChange(Infinity, form);
  };
  const _onStepChange = async (step: number) => {
    if (step === currentStep()) return;
    await onStepChange(step, form);
    setIsDirty(false);
  };

  onMount(() => {
    if (rentalApplication()) {
      form.setFieldsValue(rentalApplication());
    }
  });

  return (
    <Show when={currentStep() < Infinity}>
      <PageCloseGuard isDirty={isDirty()}>
        <MultiSteps
          stepper={
            <div class="flex items-center justify-center pb-10">
              <Stepper class="p-9" steps={steps().map((step) => step.label)} step={currentStep()} />
            </div>
          }
          currentStep={currentStep()}
          contentClass="[&>div]:mx-auto [&>div]:mb-10 [&>div]:max-w-4xl"
          onStepChange={_onStepChange}
          onClose={() => goBack()}
          onSubmit={handleSubmit}
          disableNext={disableNext()}
          showPrevious
          steps={components()}
          loading={onStepChange.loading}
        />
      </PageCloseGuard>
    </Show>
  );
};
