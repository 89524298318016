import { createMemo, createSignal, For, Show } from 'solid-js';
import { produce } from 'solid-js/store';
import IconCircleArrow from '~/assets/images/common/circleArrow.svg?component-solid';
import IconDelete from '~/assets/images/common/deleteRegular.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { useLocalization } from '~/contexts/global';
import { usePayVendorBills } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import { uuid } from '~/utils/tool';
import { PropertiesSection } from './PropertiesSection';
import type { BillPayment } from '~/contexts/local';

export const PaymentForm = () => {
  const { t } = useLocalization();
  const { store, setStore } = usePayVendorBills();
  const [collapsedVendorIds, setCollapsedVendorIds] = createSignal(new Set<string | undefined>());
  const vendorIds = createMemo(() =>
    Array.from(new Set(store.billPayments.filter((item) => item.paymentAmount && item.paymentAmount > 0).map((item) => item.vendor.id)))
  );

  const onUpdateBills = (payload: { key: keyof BillPayment; value: any; billId?: string; vendorId?: string }) => {
    const { key, value, vendorId, billId } = payload;

    setStore(
      produce((state) => {
        for (let i = 0; i < state.billPayments.length; i++) {
          if (vendorId && state.billPayments.at(i)?.vendor.id === vendorId) {
            state.billPayments[i][key] = value as never;
          } else if (billId && state.billPayments.at(i)?.id === billId) {
            state.billPayments[i][key] = value as never;
            break;
          }
        }
      })
    );
  };

  const handleDateChange = (date: string | undefined) => {
    setStore('paymentDate', date);
  };

  const onToggleExpanded = (vendorId?: string) => {
    setCollapsedVendorIds((prev) => {
      const next = new Set(prev);

      if (next.has(vendorId)) {
        next.delete(vendorId);
      } else {
        next.add(vendorId);
      }

      return next;
    });
  };

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] max-w-[1200px] flex-col overflow-x-auto">
      <Show
        when={store.billPayments.length}
        fallback={<Empty description={t('The property and the vendor you selected do not have a bill')} />}>
        <h4 class="mt-3 text-base font-semibold text-text-level01">{t('Payment date')}</h4>
        <div class="mb-5 mt-4">
          <DueDateInputField value={store.paymentDate} class="w-1/2" onInput={handleDateChange} placeholder={t('Select payment date')} />
        </div>
        <h4 class="mb-3 text-base font-semibold text-text-level01">{t('Payment Method')}</h4>
        <div class="flex flex-col gap-4">
          <For each={vendorIds()}>
            {(vendorId) => (
              <VendorGroup
                vendorId={vendorId}
                isExpanded={!collapsedVendorIds().has(vendorId)}
                onToggleExpanded={() => onToggleExpanded(vendorId)}
                onUpdateBills={onUpdateBills}
              />
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};

interface VendorGroupProps {
  vendorId: string;
  isExpanded: boolean;
  onToggleExpanded: () => void;
  onUpdateBills: (payload: { key: keyof BillPayment; value: any; billId?: string; vendorId?: string }) => void;
}

const VendorGroup = (props: VendorGroupProps) => {
  const { t } = useLocalization();
  const { store, setStore } = usePayVendorBills();
  const [draggingSectionId, setDraggingSectionId] = createSignal('');
  const billsUnderCurrentVendor = createMemo(() => store.billPayments.filter((item) => item.vendor.id === props.vendorId));
  const columns = [
    {
      class: 'flex-grow',
      render: () => (
        <div class="flex items-center gap-5">
          <span>{billsUnderCurrentVendor().at(0)?.vendor.name}</span>
          <Show when={billsUnderCurrentVendor().length > 1}>
            <span class="whitespace-nowrap text-[#FF7448]">{`${billsUnderCurrentVendor().length} ${t('Payments')}`}</span>
          </Show>
        </div>
      ),
    },
    {
      class: 'w-[15%] shrink-0 text-right',
      render: () => (
        <span class="font-semibold text-[#FF7448]">
          {currency(billsUnderCurrentVendor().reduce((acc, cur) => acc + (cur.paymentAmount ?? 0), 0))}
        </span>
      ),
    },
    {
      class: 'shrink-0',
      render: () => (
        <div class="flex items-center justify-end gap-3">
          <div class="w-fit cursor-pointer rounded bg-transparent p-1 transition-all hover-allowed:hover:bg-input-border">
            <IconCircleArrow
              class={cn({
                'text-text-level02': !props.isExpanded,
                'rotate-180 text-primary': props.isExpanded,
              })}
            />
          </div>
          <div class="w-fit cursor-pointer rounded bg-transparent p-1 transition-all hover-allowed:hover:bg-input-border">
            <IconDelete
              class="text-text-level02"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveBills(props.vendorId);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const onRemoveBills = (vendorId?: string) => {
    setStore(
      'billPayments',
      store.billPayments.filter((item) => item.vendor.id !== vendorId)
    );
  };

  const onSetDraggingSectionId = (sectionId: string) => {
    setDraggingSectionId(sectionId);
  };

  const onInsertPropertyIntoSection = (event: DragEvent, targetSectionId: string) => {
    try {
      const data = event.dataTransfer?.getData('text') as string;
      const { vendorId, billId, sourceSectionId } = JSON.parse(data);

      if (!vendorId || !billId || !sourceSectionId || !targetSectionId || vendorId !== props.vendorId) {
        return;
      }

      setStore(
        produce((state) => {
          const sourceIndex = state.billPaymentSections.findIndex((section) => section.sectionId === sourceSectionId);
          const targetIndex = state.billPaymentSections.findIndex((section) => section.sectionId === targetSectionId);

          state.billPaymentSections[sourceIndex].billIds =
            state.billPaymentSections.at(sourceIndex)?.billIds.filter((id) => id !== billId) ?? [];
          state.billPaymentSections[targetIndex]?.billIds.push(billId);
        })
      );
    } catch {
      // do nothing...
    }
  };

  const onDragEnter = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target || !draggingSectionId()) {
      return;
    }

    target.style.transform = 'scale(1.02)';
    target.style.boxShadow = '0 0 30px rgba(0, 0, 0, 0.1)';
  };

  const onDragLeave = (e: DragEvent) => {
    const target = e.target as HTMLDivElement;

    if (!target) {
      return;
    }

    target.style.transform = '';
    target.style.boxShadow = '';
  };

  const onCreatedEmptySection = (sourceSectionId: string) => {
    setStore(
      produce((state) => {
        const sourceSectionIndex = state.billPaymentSections.findIndex((section) => section.sectionId === sourceSectionId);
        state.billPaymentSections.splice(sourceSectionIndex + 1, 0, {
          sectionId: uuid(),
          vendorId: props.vendorId,
          billIds: [],
          isManualAdded: true,
        });
      })
    );
  };

  const onCreateSectionWithProperty = (event: DragEvent) => {
    onDragLeave(event);

    if (!draggingSectionId()) {
      return;
    }

    try {
      const data = event.dataTransfer?.getData('text');
      const { vendorId, billId, sourceSectionId } = JSON.parse(data as string);

      if (!vendorId || !billId || !sourceSectionId || vendorId !== props.vendorId) {
        return;
      }

      setStore(
        produce((state) => {
          const sourceIndex = state.billPaymentSections.findIndex((section) => section.sectionId === sourceSectionId);

          state.billPaymentSections[sourceIndex].billIds =
            state.billPaymentSections.at(sourceIndex)?.billIds.filter((id) => id !== billId) ?? [];
          state.billPaymentSections.splice(sourceIndex + 1, 0, {
            sectionId: uuid(),
            vendorId,
            billIds: [billId],
            isManualAdded: true,
          });
        })
      );
    } catch {
      // do nothing...
    }
  };

  return (
    <div class="overflow-hidden rounded-lg border border-input-border bg-input" onClick={() => props.onToggleExpanded()}>
      <div class={cn('flex cursor-pointer items-center py-4', { 'border-b border-input-border': props.isExpanded })}>
        <For each={columns}>{(column) => <div class={cn('px-4 text-xs text-text-level02', column.class)}>{column.render()}</div>}</For>
      </div>
      <Show when={props.isExpanded}>
        <div class="flex flex-col gap-4 p-4" onClick={(e) => e.stopPropagation()}>
          <For each={store.billPaymentSections.filter((section) => section.vendorId === props.vendorId)}>
            {(section) => (
              <>
                <PropertiesSection
                  sectionId={section.sectionId}
                  vendorId={props.vendorId}
                  isOtherSectionDragging={!!draggingSectionId() && draggingSectionId() !== section.sectionId}
                  onSetDraggingSectionId={onSetDraggingSectionId}
                  bills={billsUnderCurrentVendor().filter((bill) => section.billIds.includes(bill.id as string))}
                  onInsertPropertyIntoSection={onInsertPropertyIntoSection}
                />
                <Show when={!section.isManualAdded && section.billIds.length > 1}>
                  <div
                    class="flex cursor-pointer justify-center rounded-lg border border-dashed border-primary bg-transparent py-[14px] text-xs text-primary transition-all duration-100"
                    onClick={() => onCreatedEmptySection(section.sectionId)}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onCreateSectionWithProperty}>
                    {t('Click or drag property here to create a new payment method')}
                  </div>
                </Show>
              </>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
};
