import { createSignal, createEffect, splitProps, Show, For, on, createMemo } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Filter } from '~/components/ui/Filter';
import { IconRefresh } from '~/components/ui/Icons';
import { Pagination } from '~/components/ui/Pagination';
import { SearchListInput } from '~/components/ui/SearchListInput';
import { Skeleton } from '~/components/ui/Skeleton';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { JSX } from 'solid-js';
import type { FilterItems } from '~/components/ui/Filter';

export type MobileListViewArgs<T> = {
  filter: T;
  page: number;
  pageSize: number;
  search?: string;
};

export type MobileListViewProps<T extends Record<string, any>, U extends Record<string, undefined | string | string[]>> = {
  class?: string;
  searchClass?: string;
  headerClass?: string;
  footerClass?: string;
  title?: JSX.Element;
  titleActions?: JSX.Element;
  subHeader?: JSX.Element;
  searchValue?: string;
  searchPlaceholder?: string;
  filterValue?: U;
  filterItems?: FilterItems<U>;
  page?: number;
  pageSize?: number;
  totalPages?: number;
  footerSummary?: JSX.Element;
  filterPopoverOverlayClass?: string;
  data: any[];
  loading?: boolean;
  empty?: JSX.Element;
  renderItem: (item: T, index: number) => JSX.Element;
  onChange?: (args: MobileListViewArgs<U>, action: 'search' | 'filter' | 'paginate') => void;
  onFilterReset?: () => void;
  onPrint?: () => void;
  hideFooter?: boolean;
  titleClass?: string;
};

export const MobileListView = <T extends Record<string, any>, U extends Record<string, undefined | string | string[]>>(
  props: MobileListViewProps<T, U>
) => {
  const [params, rest] = splitProps(props, [
    'data',
    'loading',
    'empty',
    'renderItem',
    'headerClass',
    'searchClass',
    'footerClass',
    'title',
    'titleActions',
    'subHeader',
    'searchValue',
    'searchPlaceholder',
    'filterValue',
    'filterItems',
    'page',
    'pageSize',
    'totalPages',
    'footerSummary',
    'onChange',
    'onFilterReset',
    'onPrint',
    'titleClass',
  ]);

  const { t } = useLocalization();

  const [page, setPage] = createSignal<number>(1);
  const [pageSize, setPageSize] = createSignal(50);
  const [search, setSearch] = createSignal<string>();
  const [filter, setFilter] = createSignal<U>({} as U);
  const [filterBadges, setFilterBadges] = createSignal<string[]>([]);
  const totalPages = createMemo(() => params?.totalPages);

  createEffect(() => setPage(params.page ?? 1));
  createEffect(() => setPageSize(params.pageSize ?? 10));
  createEffect(() => setSearch(params.searchValue));
  createEffect(
    on(
      () => params.filterValue,
      () => {
        setFilter(params.filterValue ?? ({} as any));
        handleSetFilterBadges();
      }
    )
  );
  createEffect(
    on(totalPages, (value) => {
      if (value && page() > value) {
        handleChange('page')(value);
      }
    })
  );
  const headerProps = ['title', 'titleActions', 'searchValue', 'searchPlaceholder', 'filterItems'] as const;
  const showHeader = () => headerProps.some((prop) => params[prop] != null);

  const handleSetFilterBadges = () => {
    const items = params.filterItems ?? [];
    Promise.all(
      items.map(async (item) => {
        if (item.type === 'group') return;
        const val = filter()[item.key];
        if (val == null) return;
        const k = item.label;
        if (item.type === 'date' || item.type === 'customDate') {
          return `${k}: ${val}`;
        }
        const options = typeof item.options === 'function' ? await item.options() : item.options;
        const values = Array.isArray(val) ? val : [val];
        const labels = values.map((v) => options.find((i) => i.type != 'group' && i.value === v)?.label).filter(Boolean);
        return `${k}: ${labels.join(', ')}`;
      })
    ).then((res) => setFilterBadges(res.filter(Boolean) as string[]));
  };

  type Payload<T> = T extends 'search' ? string | undefined : T extends 'filter' ? U : T extends 'page' | 'pageSize' ? number : never;

  const handleChange =
    <T extends 'search' | 'filter' | 'page' | 'pageSize'>(action: T) =>
    (payload: Payload<T>) => {
      switch (action) {
        case 'search':
          setPage(1);
          setSearch(payload as string | undefined);
          break;
        case 'filter':
          setPage(1);
          setFilter(payload as any);
          handleSetFilterBadges();
          break;
        case 'page':
          setPage(payload as number);
          break;
        case 'pageSize':
          setPage(1);
          setPageSize(payload as number);
          break;
      }
      const changeAction = action === 'search' ? 'search' : action === 'filter' ? 'filter' : 'paginate';
      params.onChange?.({ search: search(), filter: filter(), page: page(), pageSize: pageSize() }, changeAction);
    };

  return (
    <div class={cn('flex flex-col divide-y divide-partingline rounded-lg bg-white', rest.class)}>
      <Show when={showHeader()}>
        <header
          class={cn(
            'flex flex-wrap items-center gap-x-2 gap-y-1 border-b border-[#edeff2] px-2.5 py-3 text-title-gray',
            params.headerClass
          )}>
          <Show when={params.title}>
            <h2 class={cn('w-full text-lg font-semibold capitalize', params.titleClass)}>{params.title}</h2>
          </Show>

          <Show when={params.onPrint}>
            <Button onClick={params.onPrint} disabled={false} color={'white'} variant={'outlined'}>
              <div class="flex items-center">
                <PrintIcon class="mr-1 size-5" />
                {t('Print')}
              </div>
            </Button>
          </Show>

          <Show when={params.searchValue != null || params.searchPlaceholder != null}>
            <SearchListInput
              class={cn('w-24', params.searchClass)}
              value={search()}
              placeholder={params.searchPlaceholder}
              onChange={handleChange('search')}
            />
          </Show>
          <Show when={params.filterItems}>
            <Filter
              overlayClass={rest.filterPopoverOverlayClass}
              items={params.filterItems as FilterItems<U>}
              value={filter()}
              onChange={handleChange('filter')}
              onReset={params.onFilterReset}
            />
          </Show>
          {params.titleActions}
          <Show when={filterBadges().length > 0}>
            <div class="flex basis-full gap-2 text-xs" data-badges>
              <For each={filterBadges()}>
                {(badge) => <span class="rounded-full border border-current bg-light-pink px-2 py-1 text-essential-colour">{badge}</span>}
              </For>
              <button
                type="button"
                class="ml-1 flex items-center gap-1 opacity-80 transition-opacity hover-allowed:hover:opacity-95"
                onClick={() => handleChange('filter')({} as U)}>
                <IconRefresh class="size-3" />
                {t('Clear all')}
              </button>
            </div>
          </Show>
        </header>
      </Show>
      <Show when={params.subHeader}>{params.subHeader}</Show>

      <div class="flex-1 border-none">
        <Show
          when={!params.loading}
          fallback={
            <div class="p-4">
              <Skeleton class="h-32 w-full" />
            </div>
          }>
          <Show when={params.data.length > 0} fallback={<div class="p-4 text-center text-gray-500">{params.empty || t('No data')}</div>}>
            <div class="flex flex-col divide-y divide-partingline">
              <For each={params.data}>{(item, index) => params.renderItem(item, index())}</For>
            </div>
          </Show>
        </Show>
      </div>

      <Show when={!props.hideFooter}>
        <footer class={cn('flex items-center justify-between gap-2 p-4 text-sm text-[#a0aec0]', params.footerClass)}>
          <Show when={params.footerSummary}>{params.footerSummary}</Show>
          <Show
            when={!params.loading}
            fallback={
              <>
                <Skeleton class="h-9 w-44" />
                <Skeleton class="h-9 w-72" />
              </>
            }>
            <Pagination total={params.totalPages || 0} value={page()} onChange={handleChange('page')} />
          </Show>
        </footer>
      </Show>
    </div>
  );
};
