import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { Uploader } from '~/components/common/Upload/Upload';
import type { TextAreaProps } from '~/components/common/Inputs/LabeledTextArea';

export const MessageInput = (props: TextAreaProps & { onFilesChange: (files: File[]) => void }) => {
  const handleFilesChange = (newFiles: File[]) => {
    props.onFilesChange(newFiles);
  };

  return (
    <div class="flex flex-col gap-2 divide-y divide-gray-200">
      <LabeledTextArea
        rows={5}
        {...props}
        extraItem={() => (
          <div class="border-t border-t-[#e4e4e4] pt-2">
            <Uploader.PreUploader
              accept="*"
              {...{
                onFilesListChange: (files) => {
                  handleFilesChange(files.map((file) => file.originalFile!));
                },
                usePreUpload: false,
                maxCount: 5,
              }}
              class="border-none p-0"
            />
          </div>
        )}
      />
    </div>
  );
};
