import { useNavigate } from '@solidjs/router';
import { Show, For, createSignal, createMemo, createEffect } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { formatTenantNames } from '~/components/tenants/utils';
import { Popover } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';
const repo = new LeaseRepository();

const TenantNames: Component<{ lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto }> = (props) => {
  const { t } = useLocalization();
  const [loading, setLoading] = createSignal<boolean>(true);
  const [currentLease, setCurrentLease] = createSignal<MagicDoor.Api.HydratedLeaseDto>();

  const currentTenants = createMemo(() => currentLease()?.tenants);

  async function loadLeaseWithTenants() {
    setLoading(true);
    const lease = await repo.getLease(props.lease.id);
    setCurrentLease(lease);
    setLoading(false);
  }

  createEffect(() => {
    if (props.lease) {
      loadLeaseWithTenants();
    }
  });

  return (
    <Show when={props.lease && !loading()} fallback={<div class="text-xs text-text-level03">{t('Loading')}</div>}>
      <div class="text-xs text-text-level03">{currentTenants() ? formatTenantNames(currentTenants()) : emptyPlaceholder}</div>
    </Show>
  );
};

export const LeaseItemWithTenants = (props: { lease?: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto }) => {
  const navigate = useNavigate();
  return (
    <div
      class="flex w-full cursor-pointer flex-col gap-1"
      onClick={(e) => {
        navigate(`/leasing/leases/${props.lease?.id}`);
        e.preventDefault();
      }}>
      <div class="flex flex-1 items-center">
        <LeaseStatus class="text-xs font-medium" lease={props.lease} />
        <span class="ml-1 flex whitespace-nowrap text-xs text-text-level03">
          (<LeasePeriod lease={props.lease} />)
        </span>
      </div>
      <TenantNames lease={props.lease!} />
    </div>
  );
};

export const Leases = (props: { leases?: (MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto)[] }) => {
  const { t } = useLocalization();

  const deLeases = createMemo(() => {
    return {
      firstLease: props.leases?.[0],
      restLeases: props.leases?.slice(1),
    };
  });

  return (
    <Show when={deLeases().firstLease} fallback={<div class="text-xs text-danger">{t('Not leased')}</div>}>
      <div class="flex flex-col items-end gap-2 text-xs">
        <LeaseItemWithTenants lease={deLeases().firstLease} />
        <Show when={deLeases().restLeases?.length}>
          <Popover class="relative">
            {({ setOpen }) => (
              <>
                <Button onClick={() => setOpen(true)} class="mt-1 rounded-full px-2 py-0.5 text-xs" variant="outlined">
                  {t(`View all leases`)}
                </Button>
                <Popover.Content
                  as={() => (
                    <div class="flex w-96 max-w-full cursor-pointer flex-col gap-1 divide-y divide-partingline rounded-lg border border-partingline bg-white p-2 shadow-lg">
                      <For each={deLeases().restLeases}>{(lease) => <LeaseItemWithTenants lease={lease} />}</For>
                    </div>
                  )}
                  align="start"
                />
              </>
            )}
          </Popover>
        </Show>
      </div>
    </Show>
  );
};
