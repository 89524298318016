import { isFalsy } from '~/components/common/BetterForm/utils';
import { emptyPlaceholder } from '~/utils/constant';

export type amenityType = {
  label?: string;
  value?: boolean;
};

export function cloneDeep<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const copy = obj.constructor();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = cloneDeep(obj[key]);
    }
  }

  return copy;
}

export function throttle<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let lastArgs: Parameters<T> | undefined;

  return function throttled(this: D, ...args: Parameters<T>): void {
    if (timeoutId === undefined) {
      func.apply(this, args);
      timeoutId = setTimeout(() => {
        timeoutId = undefined;
        if (lastArgs !== undefined) {
          throttled.apply(this, lastArgs);
          lastArgs = undefined;
        }
      }, wait);
    } else {
      lastArgs = args;
    }
  };
}

export function debounce<T extends (...args: any[]) => any, D>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function debounced(this: D, ...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const result: any = {};
  keys.forEach((key) => {
    result[key] = obj[key];
  });
  return result;
}

export function omit<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const result: any = {};
  Object.keys(obj as any).forEach((key) => {
    if (!keys.includes(key as any)) {
      result[key] = obj[key as keyof T];
    }
  });
  return result;
}

export function clamp(number: number, min: number, max: number): number {
  return Math.min(Math.max(number, min), max);
}

export function objectToQueryString(obj: Record<string, string | number>, opts?: { base?: string; prefix?: string }): string {
  const _opts = { prefix: '?', base: '', ...opts };
  const res = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

  if (res && _opts.base) {
    return `${_opts.base}${_opts.prefix}${res}`;
  }
  return res;
}

export function padStart(string: any, length: number, pad: string) {
  const s = String(string);
  if (!s || s.length >= length) return string;
  return `${Array(length + 1 - s.length).join(pad)}${string}`;
}

export function validArr(arr: unknown) {
  if (Array.isArray(arr) && arr.length > 0) {
    return true;
  }
  return false;
}

export function cleanData(data: any): any {
  if (Array.isArray(data)) {
    const cleanedArray = data.map((item) => cleanData(item)).filter((item) => !isFalsy(item));
    return cleanedArray.length > 0 ? cleanedArray : undefined;
  } else if (typeof data === 'object' && data !== null && !(data instanceof Date)) {
    const cleanedObject = Object.entries(data).reduce((acc: { [key: string]: any }, [key, value]) => {
      const cleanedValue = cleanData(value);
      if (!isFalsy(cleanedValue)) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : undefined;
  }
  return data;
}

export function upperFirst(str: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function showEmptyPlaceholderIfValueIsEmpty(value: string | null | undefined) {
  return value ?? emptyPlaceholder;
}

export function uuid() {
  return Math.random().toString(36).slice(2);
}

export function safeCall<T extends (...args: any[]) => any>(callback?: T, ...args: Parameters<T>) {
  return callback && callback(...args);
}

export function isEmptyData(obj: any): boolean {
  let result = true;
  for (const key in obj) {
    const data = obj[key as any];
    if (data) {
      if (data ? data.constructor === Object : false) {
        if (!isEmptyData(data)) {
          result = false;
          break;
        }
      } else {
        result = false;
        break;
      }
    }
  }
  return result;
}

export function isUndefined(val: any): val is undefined {
  return typeof val === 'undefined';
}

export function isNull(val: any): val is null {
  return val === null;
}
export function getDecimalPlaces(input: any) {
  const num = parseFloat(input);
  if (isNaN(num)) {
    return 0;
  }

  return num.toString().indexOf('.') === -1 ? 0 : num.toString().split('.')[1].length;
}

export function isObject(val: any): val is object {
  return typeof val === 'object';
}

export function escapeHtml(html: string) {
  const text = document.createTextNode(html);
  const div = document.createElement('div');
  div.appendChild(text);
  return div.innerHTML;
}

export function makeLinksClickable(text: string) {
  if (!text) return '<br/>';
  const escapedMessage = escapeHtml(text);
  const urlPattern = /\b(https?:\/\/[^\s/$.?#].[^\s]*|www\.[^\s/$.?#].[^\s]*|[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([\\/][^\s]*)?)\b/g;
  return escapedMessage.replace(urlPattern, (url) => {
    const href = url.startsWith('http') ? url : 'http://' + url;
    return `<a href="${href}" class="underline hover-allowed:hover:text-white/70" rel="noopener noreferrer" target="_blank">${url}</a>`;
  });
}

export function hydratedCheckbox(amenities: MagicDoor.Api.AmenityDto[]) {
  const tempArr = [] as amenityType[];
  amenities?.forEach((item) => {
    if (item.avalability === 'available' || item.avalability === 'unavailable') {
      tempArr.push({
        label: item.name,
        value: item.avalability === 'available' ? true : false,
      });
    }
  });
  return tempArr;
}

export const cropImage = async (file: File, cropSize: number = 200) =>
  new Promise<Blob | null>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const size = Math.min(img.width, img.height, cropSize);
      const scale = size / Math.min(img.width, img.height);
      const width = img.width * scale;
      const height = img.height * scale;
      const dx = (width - size) / 2;
      const dy = (height - size) / 2;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = size;
      canvas.height = size;
      ctx?.drawImage(img, -dx, -dy, width, height);
      canvas.toBlob((blob) => resolve(blob), 'image/png', 0.9);
      URL.revokeObjectURL(img.src);
    };
    img.onerror = () => reject(new Error('Failed to load image'));
  });

export function toLowerCaseFirstLetter(str: string) {
  if (str.length === 0) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function filterUndefined(obj: any): any {
  if (typeof obj !== 'object' || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj.map(filterUndefined).filter((item) => item !== undefined);
  }

  const result: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = filterUndefined(obj[key]);
      if (value !== undefined) {
        result[key] = value;
      }
    }
  }
  return result;
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export function sleep(ms: number) {
  const { resolve, promise } = Promise.withResolvers<void>();

  setTimeout(() => {
    resolve();
  }, ms);

  return promise;
}

export function isAsyncFunction(func: any) {
  return Object.prototype.toString.call(func) === '[object AsyncFunction]';
}
