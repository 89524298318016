import { createMemo, Show } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import Tooltip from '~/components/common/Tooltip';
import { TOOLTIP_BG_COLOR } from '~/components/layouts/Menu/components/config';
import { IconLink } from '~/components/ui/Icons';
import { useCompanies, useLocalization } from '~/contexts/global';
export const LinkToWebsite = (props: { isMenuCollapsed?: boolean }) => {
  const { getWebsiteUrl } = useCompanies();
  const { t } = useLocalization();
  const websiteUrl = createMemo(() => getWebsiteUrl('/'));

  return (
    <div class="absolute inset-x-0 bottom-0 z-100 flex justify-center border-t border-white/40 bg-[#681AAA] py-4">
      <Show
        when={!props.isMenuCollapsed}
        fallback={
          <Tooltip bgColor={TOOLTIP_BG_COLOR} align="right" message={t('Go company website')}>
            <LinkButton
              href={websiteUrl()}
              variant="outlined"
              class="flex size-10 justify-center border-white/40 px-0 text-white"
              target="_blank">
              <IconLink class="size-4" />
            </LinkButton>
          </Tooltip>
        }>
        <LinkButton
          href={websiteUrl()}
          variant="outlined"
          class="flex w-[236px] justify-center border-white/40 px-0 text-white"
          target="_blank">
          <IconLink class="size-4" />
          {t('Go company website')}
        </LinkButton>
      </Show>
    </div>
  );
};
