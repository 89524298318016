import { ValidationError } from '~/errors';
import { BaseRestRepository } from './baseRestRepository';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export type Category = {
  id: string;
  name: string;
};

export class CategoryRepository<T extends Category> extends BaseRestRepository {
  private readonly baseUrl: string;

  constructor(type: string) {
    super();
    this.baseUrl = `/api/${type}/categories`;
  }

  public async getCategories(): Promise<T[]> {
    const response = await this.fetchWithAuth(this.baseUrl);
    return this.getJsonResponse(response);
  }

  public async getCategory(categoryId: string): Promise<T> {
    validateId(categoryId);
    const url = `${this.baseUrl}/${categoryId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createCategory(category: Omit<T, 'id'>): Promise<T> {
    const response = await this.fetchWithAuth(
      this.baseUrl,
      {
        method: 'POST',
        body: JSON.stringify(category),
      },
      false
    );
    return this.getJsonResponse(response);
  }

  public async updateCategory(categoryId: string, category: Partial<Omit<T, 'id'>>): Promise<T> {
    validateId(categoryId);
    const url = `${this.baseUrl}/${categoryId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(category),
    });
    return this.getJsonResponse(response);
  }

  public async deleteCategory(categoryId: string): Promise<void> {
    validateId(categoryId);
    const url = `${this.baseUrl}/${categoryId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}

export const maintenanceRequestCategoryRepository = new CategoryRepository<MagicDoor.Api.MaintenanceRequestCategoryDto>(
  'maintenance-requests'
);
