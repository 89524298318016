import { useLocalization } from '~/contexts/global';
import type { JSX } from 'solid-js';

interface IconFieldSkeletonProps {
  name?: string;
  src?: () => JSX.Element;
}

const IconFieldSkeleton = (props: IconFieldSkeletonProps) => {
  const { t } = useLocalization();
  const defaultName = t('Loading');

  return (
    <div class="flex h-9 w-full flex-row items-center gap-2">
      <div class="rounded-lg border border-solid p-1.5">
        <div class="size-5"> {props.src && <props.src />}</div>
      </div>
      <div class="flex w-32 flex-col text-left">
        <div class="uppercase text-text-level03">{props.name || defaultName}</div>
        <div class="skeleton-shimmer skeleton-text-sm mt-1 h-4" />
      </div>
    </div>
  );
};

export default IconFieldSkeleton;
