import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import { Form } from '~/components/common/BetterForm';
import { useForm } from '~/components/common/BetterForm/context';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import {
  CategoryContentSection,
  CategoryComponentSection,
  getErrorAndProgressProps,
  CategoryComponentWrapper,
} from '~/components/common/Category';
import { toast } from '~/components/ui';
import { useLocalization, useProperties, useUnits, useWorkOrders } from '~/contexts/global';
import { useGoBack } from '~/hooks';
import { transformKeyValuePairs } from '~/pages/maintenance-request/tools';
import { FileRepository } from '~/repositories/fileRepository';
import { WORK_ORDER_BASE_URL } from '~/repositories/workOrderRepository';
import { AssignedTo } from './AssignTo';
import { EssentialInformation } from './EssentialInformation';
import { MagicTags } from './MagicTags';
import type { KeyValuePair } from '~/components/magic-tags/EditMagicTagsModal';

const fileRepository = new FileRepository();

type SubmitFormData = MagicDoor.Api.CreateWorkOrderDto & {
  unitId: string;
  propertyId: string;
};

export const AddWorkOrderView = () => {
  const navigate = useNavigate();
  const goBack = useGoBack();
  const { t } = useLocalization();
  const { addWorkOrder, newFiles } = useWorkOrders();
  const { updateUnitMagicTags, unit, getUnit } = useUnits();
  const { updatePropertyMagicTags, setCurrentId, current } = useProperties();

  const form = useForm();

  const [isSubmiting, setIsSubmiting] = createSignal<boolean>(false);
  const [selectedMagicTags, setSelectedMagicTags] = createSignal<KeyValuePair>();

  async function onSubmit(formData: SubmitFormData) {
    try {
      setIsSubmiting(true);

      const magicTags = transformKeyValuePairs(formData as never as Record<string, string>);
      const workOrder = await addWorkOrder({
        ...formData,
        permissionToEnter: true,
      });
      for (const file of newFiles()) {
        if (!file.originalFile) continue;
        await fileRepository.uploadFileToUrl(`${WORK_ORDER_BASE_URL}/${workOrder.id}/files`, file.originalFile);
      }
      if (formData.unitId) {
        await updateUnitMagicTags(formData.unitId, { magicTags });
      } else {
        await updatePropertyMagicTags(formData.propertyId, { magicTags });
      }
      form?.resetFields();
      toast.success(t('{name} has been added successfully', { name: 'Work order' }));
      navigate(`/maintenance/work-orders/${workOrder.id}`, { replace: true });
    } finally {
      setIsSubmiting(false);
    }
  }

  createEffect(() => {
    if (form.formStore.unitId) {
      getUnit(form.formStore.unitId);
      setSelectedMagicTags(unit()?.magicTags);
    } else if (form.formStore.propertyId) {
      setCurrentId(form.formStore.propertyId);
      setSelectedMagicTags(current()?.magicTags);
    }
  });

  return (
    <div class="flex size-full flex-col">
      <Form
        initialValues={{
          status: 'pending',
          urgency: 'low',
        }}
        defaultForm={form}
        onFormSubmit={(form) => onSubmit(form as SubmitFormData)}>
        <CategoryComponentWrapper
          sections={[
            {
              key: 'essential-information',
              name: t('Essential information'),
              component: EssentialInformation,
              fields: ['description', 'title', 'unitId', 'urgency', 'propertyId', 'leaseId', 'vendorId', 'status', 'dueDate'],
            },
            {
              key: 'magic-tags',
              name: t('Magic tags'),
              component: () => <MagicTags defaultValue={selectedMagicTags()} form={form} />,
            },
            {
              key: 'assigned-to',
              name: t('Assigned to'),
              component: () => <AssignedTo isCreate />,
              fields: ['assignedPropertyManagerId', 'disableAi'],
            },
          ]}>
          <Breadcrumb backLink={() => goBack()} items={[{ label: t(`Work orders`), link: '../' }, { label: t(`Add work order`) }]} />
          <div class="m-8 flex h-section1 flex-col overflow-hidden rounded-lg bg-white text-sm">
            <div class="flex flex-1 overflow-hidden">
              <div class="hidden w-[330px] shrink-0 border-r border-r-partingline lg:block">
                <h2 class="p-6 text-lg font-medium text-text-level01">{t('Work order')}</h2>
                <CategoryComponentSection {...getErrorAndProgressProps(form)} />
              </div>
              <div class="thinscroll flex grow flex-col items-stretch overflow-auto pb-8 text-left">
                <CategoryContentSection mode="padding" />
              </div>
            </div>
            <div class="flex h-24 items-center justify-end gap-2 border-t border-partingline px-5">
              <Button
                onClick={() => {
                  goBack();
                }}
                variant="outlined">
                {t('Cancel')}
              </Button>
              <Button loading={isSubmiting()} type="submit">
                {t('Create work order')}
              </Button>
            </div>
          </div>
        </CategoryComponentWrapper>
      </Form>
    </div>
  );
};
