import { useParams } from '@solidjs/router';
import { onMount, Show } from 'solid-js';
import { BasicInfo } from '~/components/rental-application/rental-application-details/infomation/BasicInfo';
import { Expandable } from '~/components/rental-application/rental-application-details/infomation/Expandable';
import { PaymentStatus } from '~/components/rental-application/rental-application-details/infomation/PaymentStatus';
import { PotentialUnitsActions } from '~/components/rental-application/rental-application-details/infomation/PotentialUnits';
import { RentalHistoryActions, RentalHistory } from '~/components/rental-application/rental-application-details/infomation/RentalHistory';
import {
  RenterInformation,
  RenterInformationActions,
} from '~/components/rental-application/rental-application-details/infomation/RenterInformation';
import { AiScore } from '~/components/rental-application/rental-application-details/infomation/ai-score';
import { Documents, DocumentsActions } from '~/components/rental-application/rental-application-details/infomation/documents/Documents';
import { PotentialUnits } from '~/components/rental-application/rental-application-details/infomation/potential-units/PotentialUnits';
import { TransunionReports } from '~/components/rental-application/rental-application-details/infomation/reports/TransunionReports';
import {
  WorkHistory,
  WorkHistoryActions,
} from '~/components/rental-application/rental-application-details/infomation/work-history/WorkHistory';
import { IconLoader } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';

export const RentalApplicationInformation = () => {
  const params = useParams();
  const { fetchApplication } = useRentalApplication();
  const { t } = useLocalization();
  onMount(() => {
    fetchApplication(params.applicationId);
  });

  return (
    <div class="relative flex flex-col gap-4 overflow-visible xl:flex-row">
      <BasicInfo />
      <div class="flex grow flex-col gap-4">
        <Show when={!fetchApplication.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin text-primary" />}>
          <div class="flex flex-col gap-4 2xl:flex-row">
            <AiScore />
            <div class="flex flex-col gap-4 self-stretch md:flex-row 2xl:basis-96 2xl:flex-col 2xl:self-start">
              <PaymentStatus />
              <TransunionReports />
            </div>
          </div>
          <Expandable title={t('Renter information')} content={<RenterInformation />} actions={<RenterInformationActions />} />
          <Expandable title={t('Potential units')} content={<PotentialUnits />} actions={<PotentialUnitsActions />} />
          <Expandable title={t('Rental history')} content={<RentalHistory />} actions={<RentalHistoryActions />} />
          <Expandable title={t('Work history')} content={<WorkHistory />} actions={<WorkHistoryActions />} />
          <Expandable title={t('Documents')} content={<Documents />} actions={<DocumentsActions />} />
        </Show>
      </div>
    </div>
  );
};
