import { A, useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import IconActicateLease from '~/assets/images/units/acticateLease.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconDraft from '~/assets/images/units/draft.svg?component-solid';
import IconViewLease from '~/assets/images/units/viewLease.svg?component-solid';
import { LeasePeriod } from '~/components/leases/LeasePeriod';
import { toast, DropdownActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease, useLeasesList } from '~/contexts/local';
import type { Component } from 'solid-js';

type LeaseDraftProps = {
  lease?: MagicDoor.Api.LeaseDto;
  source?: 'lease' | 'unit';
};

export const LeaseDraft: Component<LeaseDraftProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { setLease, activateDraft } = useLease();
  const { refetchLeases } = useLeasesList();

  return (
    <Show when={props.lease}>
      <div
        class="col-span-full flex h-14 items-center justify-between rounded-md px-3 text-white md:px-6"
        style={{
          'background-color': '#A126EC',
        }}>
        <A href={`/leasing/leases/${props.lease?.id}`} class="flex flex-wrap items-center hover-allowed:hover:opacity-90">
          <IconDraft />
          <span class="ml-2 text-base">{t('Draft leases')}:</span>
          <LeasePeriod class="ml-2 text-xs text-white/70" lease={props.lease} />
        </A>

        <Show when={props.source !== 'lease'}>
          <div class="grid-3 flex items-center">
            <DropdownActions
              class="*:text-white"
              actions={[
                {
                  icon: IconViewLease,
                  label: t('View lease'),
                  href: `/leasing/leases/${props.lease?.id}`,
                },
                {
                  icon: IconActicateLease,
                  label: t('Activate lease'),
                  onClick: async () => {
                    if (props.lease?.id == null) return;
                    await activateDraft(props.lease.id);
                    toast.success(t('Operation successful'));
                    refetchLeases();
                  },
                },
                {
                  icon: IconDelete,
                  label: t('Delete'),
                  onClick: () => {
                    setLease(props.lease as any);
                    navigate('delete-lease');
                  },
                },
              ]}
            />
          </div>
        </Show>
      </div>
    </Show>
  );
};
