import { BankAccountConnectionStatus } from '~/swagger/Api';

export const getStatusBgColor = (status?: BankAccountConnectionStatus | null) => {
  switch (status) {
    case BankAccountConnectionStatus.Approved:
      return 'bg-[#C8FFEE]';
    case BankAccountConnectionStatus.Pending:
      return 'bg-[#FFF4D7]';
    case BankAccountConnectionStatus.Denied:
      return 'bg-[#FFF1F6]';
    default:
      return 'bg-[#E2E9F2]';
  }
};

export const getStatusTextColor = (status?: BankAccountConnectionStatus | null) => {
  switch (status) {
    case BankAccountConnectionStatus.Approved:
      return 'text-[#009D7C]';
    case BankAccountConnectionStatus.Pending:
      return 'text-[#F45A29]';
    case BankAccountConnectionStatus.Denied:
      return 'text-[#DD3668]';
    default:
      return 'text-[#6C80AF]';
  }
};

export const getStatusCircleColor = (status?: BankAccountConnectionStatus | null) => {
  switch (status) {
    case BankAccountConnectionStatus.Approved:
      return 'bg-[#009D7C]';
    case BankAccountConnectionStatus.Pending:
      return 'bg-[#F45A29]';
    case BankAccountConnectionStatus.Denied:
      return 'bg-[#DD3668]';
    default:
      return 'bg-[#6C80AF]';
  }
};

export const getStatusText = (status?: BankAccountConnectionStatus | null) => {
  switch (status) {
    case BankAccountConnectionStatus.Approved:
      return 'Connected';
    case BankAccountConnectionStatus.Pending:
      return 'Pending';
    case BankAccountConnectionStatus.Denied:
      return 'Failed';
    default:
      return 'Not Connected';
  }
};
