import { Match, Show, Switch } from 'solid-js';
import IconError from '~/assets/images/common/error.png';
import { CircularProgress } from '~/components/ui';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export interface SidebarProgress {
  progress: number;
  isError: boolean;
}

interface CategoryProps<T extends Record<string, any>> {
  label: string;
  categoryKey: string;
  progress: SidebarProgress | undefined;
  isActive: boolean;
  error: string | undefined | boolean;
  onSetActiveCategory: (categoryKey: string) => void;
  mode?: 'progress' | 'plain';
  icon?: Component<T>;
  class?: string;
}

export const CategoryComponent = function <T extends Record<string, any>>(props: CategoryProps<T>) {
  return (
    <div
      onClick={() => props.onSetActiveCategory(props.categoryKey)}
      class={cn('flex cursor-pointer items-center gap-2 rounded-md border bg-white p-6', props.class)}
      classList={{
        'text-essential-colour': props.isActive,
        'border-essential-colour': props.isActive,
        'font-medium': props.isActive,
      }}>
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center gap-2">
          <Switch>
            <Match when={props.mode === 'plain'}>{props.icon as Component}</Match>
            <Match when={props.mode === 'progress' || !props.mode}>
              <CircularProgress class="size-6 text-primary" value={(props.progress?.progress ?? 0) / 100} />
            </Match>
          </Switch>
          <span>{props.label}</span>
        </div>
        <Show when={props.error}>
          <img class="size-6 shrink-0" src={IconError} />
        </Show>
      </div>
    </div>
  );
};
