import { A } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show, createEffect, createMemo, createSignal } from 'solid-js';
import IconArchiveList from '~/assets/images/chat/archiveList.svg?component-solid';
import { PortfolioIcon } from '~/components/portfolios';
import { DropdownActions, IconListFilter, IconMessageSquareText, IconSearch, IconX, IconCirclePlus } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useChat, useChatsList, useChatsSettings } from '~/contexts/local';
import { ChatSearchModal } from '~/pages/chats/chat-details/components/ChatSearchModal';
import { cn } from '~/utils/classnames';
import { ChatDropdown } from './ChatDropdown';
import { categoryIcons, parseChats } from './chatUtils';
import type { CategorizedChats } from './chatUtils';
import type { Component, ComponentProps } from 'solid-js';

export interface ChatSidebarProps extends ComponentProps<'aside'> {
  onToggleSidebarVisible: () => void;
}

export const ChatSidebar: Component<ChatSidebarProps> = (props) => {
  const { t } = useLocalization();
  const { chats, isLoading } = useChatsList();
  const { isMobileView } = useChatsSettings();
  const { chatId } = useChat();
  const { companies } = useCompanies();

  const [categorizedChats, setCategorizedChats] = createSignal<CategorizedChats>({});
  const [filter, setFilter] = createSignal<string>('clearFilter');
  const [searchQuery, setSearchQuery] = createSignal<string>('');
  const [showSearchChatModal, setShowSearchChatModal] = createSignal<boolean>(false);

  const filteredChats = createMemo<Record<string, any>>(() => {
    const currentFilter = filter();
    const now = dayjs();

    // eslint-disable-next-line solid/reactivity
    return Object.keys(categorizedChats()).reduce<Record<string, any>>((acc, category) => {
      let chats = categorizedChats()[category];

      chats = chats.filter((chat) => !chat.closed);

      chats = chats.filter((chat) => {
        const chatDate = chat.lastMessageSentAt ? dayjs(chat.lastMessageSentAt) : null;
        const monthDifference = chatDate ? chatDate.diff(now, 'month') : null;
        const isUnread = chat.unreadMessages > 0;
        const isWithin1Month = monthDifference !== null && monthDifference < 1;
        const isWithin3Months = monthDifference !== null && monthDifference < 3;

        switch (currentFilter) {
          case 'unread':
            return isUnread;
          case '1month':
            return isWithin1Month;
          case '3months':
            return isWithin3Months;
          default:
            return true;
        }
      });

      acc[category] = chats;
      return acc;
    }, {});
  });

  const filterText = createMemo(() => {
    switch (filter()) {
      case 'unread':
        return t('Unread messages');
      case '1month':
        return t('Within 1 month');
      case '3months':
        return t('Within 3 months');
    }
  });

  createEffect(() => {
    if (!isLoading()) {
      setCategorizedChats(parseChats(chats()));
    }
  });

  return (
    <aside {...props} class={cn('w-[340px] bg-partingline', { 'bg-white': isMobileView() }, props.class)}>
      <div
        class={cn('thinscroll relative z-10 h-[calc(100%-56px)] w-full select-none overflow-auto px-5 py-3', {
          'px-3 py-4': isMobileView(),
        })}>
        <Show when={isMobileView()}>
          <A
            href="/communications/chats/add"
            class="flex w-full items-center justify-center gap-1 rounded-lg bg-essential-colour py-[14px] text-white">
            <IconCirclePlus />
            <span>{t('New Communication')}</span>
          </A>
        </Show>
        <div class="flex items-center justify-between gap-2 border-b py-4">
          <PortfolioIcon class="shrink-0" />
          <span class="mr-auto truncate text-lg font-semibold capitalize">{companies()?.name}</span>
          <Show when={!isMobileView()}>
            <A href="/communications/chats/add" class="rounded-full bg-primary p-2 text-white" noScroll>
              <IconMessageSquareText class="size-5" />
            </A>
          </Show>
        </div>

        <div class="relative my-4 flex w-full items-center gap-2">
          <Show when={!isMobileView()}>
            <DropdownActions
              align="start"
              class="shrink-0"
              triggerClass="rounded-lg border border-text-level03 p-2 aria-expanded:border-primary aria-expanded:text-primary"
              actions={[
                {
                  label: t('Reset to default'),
                  onClick: () => setFilter('clearFilter'),
                },
                {
                  label: t('Unread'),
                  onClick: () => setFilter('unread'),
                },
                {
                  label: t('Within 1 month'),
                  onClick: () => setFilter('1month'),
                },
                {
                  label: t('Within 3 months'),
                  onClick: () => setFilter('3months'),
                },
              ]}>
              <IconListFilter class="size-5" />
            </DropdownActions>
          </Show>
          <input
            type="text"
            value={searchQuery()}
            onInput={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && setShowSearchChatModal(true)}
            placeholder={`${t('Search chats')}...`}
            class="w-full rounded-lg border border-text-level03 bg-transparent py-2 pl-3 pr-9 outline-none transition-colors focus:border-primary"
          />
          <IconSearch class="absolute right-3 size-4 text-text-level03" />
        </div>

        <Show when={filterText()}>
          <div class="my-4 flex w-fit items-center gap-1 rounded-full border border-primary/50 px-2 py-0.5 text-xs text-primary">
            <span>
              {t('Filter')}: {filterText()}
            </span>
            <button onClick={() => setFilter('clearFilter')}>
              <IconX class="size-3.5 text-text-level03" />
            </button>
          </div>
        </Show>

        <For each={Object.keys(categorizedChats())}>
          {(category) => (
            <ChatDropdown
              icon={categoryIcons[category]}
              title={category}
              chats={filteredChats()[category]}
              selectedChatId={chatId() || ''}
              onChatClick={props.onToggleSidebarVisible}
            />
          )}
        </For>
      </div>
      <A
        href="/communications/archive-messages"
        class="flex h-[56px] items-center justify-center gap-1.5 border-t bg-white text-sm font-medium text-text-level02">
        <IconArchiveList /> <span>{t('Archive Messages')}</span>
      </A>
      <Show when={showSearchChatModal()}>
        <ChatSearchModal
          chatSearchMode="chat"
          visible={showSearchChatModal()}
          defaultInputValue={searchQuery()}
          onClose={() => {
            setShowSearchChatModal(false);
            setSearchQuery('');
          }}
        />
      </Show>
    </aside>
  );
};
