import { createMemo } from 'solid-js';
import { Description } from '~/components/common/Description';
import { useNotificationModal } from './NotificationModalContext';
import type { TextAreaProps } from '~/components/common/Inputs/LabeledTextArea';

export const MessageInput = (props: TextAreaProps & { type: 'chat' | 'email' }) => {
  const { setChatFiles, setEmailFiles } = useNotificationModal();

  const setFiles = createMemo(() => (props.type === 'chat' ? setChatFiles : setEmailFiles));

  return (
    <Description
      {...props}
      preUploadProps={{
        onFilesListChange: (files) => {
          setFiles()(files.map((file) => file.originalFile!));
        },
        usePreUpload: false,
      }}
    />
  );
};
