import { FormItem } from '~/components/common/BetterForm';
import { Section } from '~/components/common/FormSectionTitle';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RentalApplicationDocuments } from '~/components/rental-application/rental-application-details/infomation/documents/DocumentsForm';
import { useLocalization, useNewRentalApplication } from '~/contexts/global';
import { ssn } from '~/utils/formRules';

export const Identity = () => {
  const { t } = useLocalization();
  const { setDisableNext } = useNewRentalApplication();
  return (
    <>
      <Section required border title={t('Income verification')}>
        <div class="flex flex-col gap-y-8">
          <FormItem
            id="new-rental-application-step4-ssn"
            placeholder={t('Social security number')}
            label={t('Social security number (ssn)')}
            formFieldName={['ssn']}
            component={LabeledTextInput}
            rules={ssn(t)}
          />
          <FormItem
            id="new-rental-application-step4-files"
            formFieldName="files"
            component={RentalApplicationDocuments}
            beforeUpload={() => setDisableNext(true)}
            afterUpload={() => setDisableNext(false)}
          />
        </div>
      </Section>
    </>
  );
};
