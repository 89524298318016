import { Show } from 'solid-js';
import IconWaring from '~/assets/images/units/listing/warning.svg?component-solid';
import { cn } from '~/utils/classnames';
import Tooltip from './Tooltip';

export interface SelectableEntityNameProps {
  id: string;
  name: string;
  warningMessage?: string;
  isSelected: boolean;
  index: number;
  onClick: (entityId: string) => void;
}

export const SelectableEntityName = (props: SelectableEntityNameProps) => {
  return (
    <div
      class={cn(
        'flex cursor-pointer items-center p-2 transition-colors hover-allowed:hover:bg-gray-100',
        props.index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
      )}
      onClick={() => props.onClick(props.id)}>
      <input type="checkbox" checked={props.isSelected} class="mr-2" onClick={() => props.onClick(props.id)} />
      <span class="text-gray-800">{props.name}</span>
      <Show when={props.warningMessage}>
        <Tooltip message={props.warningMessage} align="top">
          <IconWaring class="ml-2" />
        </Tooltip>
      </Show>
    </div>
  );
};
