import { useForm } from '@magicdoor/form';
import { useSearchParams } from '@solidjs/router';
import { createSignal, onMount } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { authRepository } from '~/repositories/authRepository';
import { createMutation } from '~/utils/resource';

export enum ResetPasswordStep {
  InputAccount = 'InputAccount',
  InputPasswords = 'InputPasswords',
  LinkSended = 'LinkSended',
  ResetSuccess = 'ResetSuccess',
}

export const [ResetPasswordProvider, useResetPassword] = createMagicDoorContext('ResetPassword', () => {
  const [step, setStep] = createSignal<ResetPasswordStep>(ResetPasswordStep.InputAccount);

  const form = useForm();
  const [searchParams] = useSearchParams<{ token: string }>();

  const getResetPasswordEmail = createMutation(async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await authRepository.getResetPasswordEmail(form.formStore.email);
    setStep(ResetPasswordStep.LinkSended);
  });

  const resetPassword = createMutation(async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await authRepository.resetPasswordWithToken({
      token: form.formStore.token,
      newPassword: form.formStore.password,
    });
    setStep(ResetPasswordStep.ResetSuccess);
  });

  onMount(() => {
    if (searchParams.token) {
      setStep(ResetPasswordStep.InputPasswords);
      form.setFormStore({
        token: searchParams.token,
      });
    }
  });

  const handleFormSubmit = () => {
    if (step() === ResetPasswordStep.InputAccount) {
      getResetPasswordEmail();
    }
    if (step() === ResetPasswordStep.InputPasswords) {
      resetPassword();
    }
  };

  return {
    step,
    form,
    setStep,
    getResetPasswordEmail,
    resetPassword,
    handleFormSubmit,
  };
});
