import { Show } from 'solid-js';
import { IconMail } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { OtherTargetsList } from '~/pages/announcements/components/OtherTargetsList';
import { PortfolioTargetList } from '~/pages/announcements/components/PortfolioTargetList';
import { renderField, renderAttachments } from '~/pages/announcements/components/RenderAnnouncement';
type ReviewProps = {
  reviewData: any;
  model: any;
};

export const Review = (props: ReviewProps) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col gap-4">
      <h4 class="border-b py-3 text-base font-semibold uppercase text-text-level02">{t('Review')}</h4>

      <Show when={props.model}>
        <h4 class="text-xs uppercase text-text-level02">{t('Content')}</h4>
        <div class="flex flex-col gap-2 text-sm text-text-level01">
          <div>
            <Show when={props.model.chatMessage}>{renderField('Chat message', props.model.chatMessage, t)}</Show>
            {renderAttachments(props.model.chatAttachments, 'Chat attachments', t)}
          </div>

          <div>
            <Show when={props.model.emailMessage}>{renderField('Email message', props.model.emailMessage, t)}</Show>
            {renderAttachments(props.model.emailAttachments, 'Email attachments', t)}
          </div>
        </div>
        <h4 class="text-xs uppercase text-text-level02">{t('Title')}</h4>
        <div class="text-text-level01, text-base">{props.model.subject || t('Announcement')}</div>
      </Show>

      <Show when={props.reviewData}>
        <Show when={props.reviewData.portfolioTargets && props.reviewData.portfolioTargets.length > 0}>
          <PortfolioTargetList targetType={props.model.targetType} portfolioTargets={props.reviewData.portfolioTargets} />
        </Show>
        <Show when={!props.reviewData.portfolioTargets || props.reviewData.portfolioTargets.length === 0}>
          <OtherTargetsList targets={props.reviewData.owners} title="Owners" />
          <OtherTargetsList targets={props.reviewData.vendors} title="Vendors" />
          <OtherTargetsList targets={props.reviewData.tenants} title="Tenants" />
        </Show>
      </Show>

      <Show when={props.model}>
        <h4 class="border-b py-3 text-base font-semibold uppercase text-text-level02">{t('Target type')}</h4>
        <div class="flex h-10 w-[280px] items-center gap-1 rounded-lg bg-light-gray pl-4">
          <IconMail class="size-4 text-text-level02" />
          <span class="text-xs font-medium text-title-gray">{props.model.targetType}</span>
        </div>
        <h4 class="border-b py-3 text-base font-semibold uppercase text-text-level02">{t('Filters')}</h4>
        <ul class="list-inside list-disc">
          <li>
            {t('Has balance due')}:
            <span class="pl-1">
              {props.model.hasBalanceDue === true ? t('Yes') : props.model.hasBalanceDue === false ? t('No') : t('Not selected')}
            </span>
          </li>
          <li>
            {t('Include ended lease')}: {props.model.includeEndedLease ? t('Yes') : t('No')}
          </li>
        </ul>
      </Show>
    </div>
  );
};
