import { createMemo, createSignal, onMount } from 'solid-js';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { BankAccountConnectionStatus } from '~/swagger/Api';
import type { Accessor } from 'solid-js';
import type { FilterItems, ListViewArgs } from '~/components/ui';

type FilterValue = {
  plaidLinkStatus?: 'All' | BankAccountConnectionStatus;
  stripeLinkStatus?: 'All' | BankAccountConnectionStatus;
};

interface BankAccountsListContextValue {
  fetchBankAccounts: () => Promise<void>;
  bankAccounts: Accessor<MagicDoor.Api.HydratedBankAccountDto[]>;
  filteredBankAccounts: Accessor<MagicDoor.Api.HydratedBankAccountDto[]>;
  onBankAccountAdded: (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => void;
  onBankAccountDeleted: (bankAccountId: string) => void;
  isLoading: Accessor<boolean>;
  error: Accessor<string | undefined>;
  bankAccountCount: Accessor<number>;
  filterItems: Accessor<FilterItems<FilterValue>>;
  filterValue: Accessor<FilterValue>;
  onResetFilter: () => void;
  onFilterChange: (args: ListViewArgs<FilterValue>, action: 'search' | 'filter' | 'paginate') => void;
  onUpdateBankAccounts: (bankAccounts: MagicDoor.Api.HydratedBankAccountDto) => void;
}

const DEFAULT_FILTER_VALUE: FilterValue = { plaidLinkStatus: 'All', stripeLinkStatus: 'All' };

export const [BankAccountsListProvider, useBankAccountsList] = createMagicDoorContext<BankAccountsListContextValue>(
  'BankAccountsList',
  () => {
    const { t } = useLocalization();
    const repo = new BankAccountRepository();
    const [bankAccounts, setBankAccounts] = createSignal<MagicDoor.Api.HydratedBankAccountDto[]>([]);
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<string | undefined>(undefined);
    const [bankAccountCount, setBankAccountCount] = createSignal<number>(0);
    const [search, setSearch] = createSignal('');
    const [filterValue, setFilterValue] = createSignal<FilterValue>(Object.assign({}, DEFAULT_FILTER_VALUE));
    const filteredBankAccounts = createMemo(() => {
      return bankAccounts().filter((account) => {
        const isSearchPassed =
          !search() || account.name.toLowerCase().includes(search().toLowerCase()) || account.description?.includes(search().toLowerCase());
        const isPlaidLinkStatusPassed =
          filterValue().plaidLinkStatus === 'All' || !filterValue().plaidLinkStatus
            ? true
            : account.plaid?.status === filterValue().plaidLinkStatus;
        const isStripeLinkStatusPassed =
          filterValue().stripeLinkStatus === 'All' || !filterValue().stripeLinkStatus
            ? true
            : account.stripe?.status === filterValue().stripeLinkStatus;

        return isSearchPassed && isPlaidLinkStatusPassed && isStripeLinkStatusPassed;
      });
    });

    const filterItems = createMemo(() => [
      {
        type: 'select',
        key: 'plaidLinkStatus',
        label: t('Plaid link status'),
        options: [
          { label: t('All'), value: 'All' },
          { label: t('Pending'), value: BankAccountConnectionStatus.Pending },
          { label: t('Connected'), value: BankAccountConnectionStatus.Approved },
          { label: t('Denied'), value: BankAccountConnectionStatus.Denied },
        ],
      },
      {
        type: 'select',
        key: 'stripeLinkStatus',
        label: t('Stripe link status'),
        options: [
          { label: t('All'), value: 'All' },
          { label: t('Pending'), value: BankAccountConnectionStatus.Pending },
          { label: t('Connected'), value: BankAccountConnectionStatus.Approved },
          { label: t('Denied'), value: BankAccountConnectionStatus.Denied },
        ],
      },
    ]) as Accessor<FilterItems<FilterValue>>;

    const fetchBankAccounts = async () => {
      setIsLoading(true);
      try {
        const response = await repo.getBankAccounts();
        const list = response;

        setBankAccounts(list);

        setBankAccountCount(list.length);
        setError(undefined);
      } catch (e) {
        setError(e instanceof Error ? e.message : String(e));
      } finally {
        setIsLoading(false);
      }
    };

    onMount(() => {
      fetchBankAccounts();
    });

    const onBankAccountAdded = (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => {
      setBankAccounts((prevBankAccounts) => [...prevBankAccounts, bankAccount]);
      setBankAccountCount((count) => count + 1);
    };

    const onBankAccountDeleted = (bankAccountId: string) => {
      setBankAccounts((prevBankAccounts) => prevBankAccounts.filter((t) => t.id?.toString() !== bankAccountId));
      setBankAccountCount((count) => count - 1);
    };

    const onResetFilter = () => {
      setFilterValue(Object.assign({}, DEFAULT_FILTER_VALUE));
    };

    const onFilterChange = (args: ListViewArgs<FilterValue>) => {
      const { search, filter } = args;

      setFilterValue(filter);
      setSearch(search ?? '');
    };

    const onUpdateBankAccounts = (bankAccount: MagicDoor.Api.HydratedBankAccountDto) => {
      setBankAccounts((prevBankAccounts) =>
        prevBankAccounts.map((t) => (t.id?.toString() === bankAccount.id?.toString() ? bankAccount : t))
      );
    };

    return {
      fetchBankAccounts,
      bankAccounts,
      onBankAccountAdded,
      onBankAccountDeleted,
      isLoading,
      error,
      bankAccountCount,
      filterItems,
      filterValue,
      onResetFilter,
      onFilterChange,
      filteredBankAccounts,
      onUpdateBankAccounts,
    };
  }
);
