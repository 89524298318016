import { useNavigate, useParams } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { DeactivateModal } from '~/components/modals';
import { IconBan, toast } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';

export const DeactivateProperty = () => {
  const { t } = useLocalization();
  const params = useParams();
  const navigate = useNavigate();
  const { current, deactivateProperty } = useProperties();
  const [showModal, setShowModal] = createSignal(false);

  const handleConfirm = async () => {
    const property = current();
    if (property == null) return;
    await deactivateProperty(property.id, true);
    navigate(`/portfolios/${params.portfolioId}`, { replace: true });
    toast.success(t('{name} has been deactivated successfully', { name: t('Property') }));
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        class="flex items-center gap-2 rounded-md border px-4 py-2.5 text-text-level03 transition-colors hover-allowed:hover:bg-text-level02/5">
        <IconBan class="size-4" />
        <div class="hidden md:block">{t('Deactivate property')}</div>
      </button>
      <DeactivateModal
        visible={showModal()}
        onConfirm={handleConfirm}
        onCancel={() => {
          setShowModal(false);
        }}>
        {t(
          'Deactivating <b>“{name}”</b> Property will also <b>deactivate all Units under it</b>, and the data will not be displayed in the report.',
          { name: current()?.name ?? '-' }
        )}
      </DeactivateModal>
    </>
  );
};
