import { useLocalization } from '~/contexts/global';
import { REPORT_STATUS_MAP, PAYMENT_STATUS_MAP } from './constants';
import type { PaymentStatusIndicatorProps, StatusIndicatorProps } from './constants';
import type { Component } from 'solid-js';
import type { ApplicationPaymentStatus } from '~/swagger/Api';

export const PaymentStatusIndicator: Component<PaymentStatusIndicatorProps> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="relative flex h-6 items-center justify-center overflow-hidden rounded-2xl px-2" style={{ color: props.color }}>
      <div class="capitalize">{t(PAYMENT_STATUS_MAP[props.label as ApplicationPaymentStatus])}</div>
      <div class="absolute inset-0" style={{ background: props.color, opacity: 0.2 }} />
    </div>
  );
};

export const ReportStatusIndicator: Component<StatusIndicatorProps> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="relative flex h-6 items-center justify-center overflow-hidden rounded-2xl px-2" style={{ color: props.color }}>
      <div class="mr-1 size-2 rounded-full" style={{ background: props.color }} />
      <div>{t(REPORT_STATUS_MAP[props.label as keyof typeof REPORT_STATUS_MAP])}</div>
      <div class="absolute inset-0" style={{ background: props.color, opacity: 0.2 }} />
    </div>
  );
};
