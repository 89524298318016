import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createSignal } from 'solid-js';
import { Form, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import {
  CategoryContentSection,
  CategoryComponentSection,
  getErrorAndProgressProps,
  CategoryComponentWrapper,
} from '~/components/common/Category';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { AssociationViolationStatus } from '~/swagger/Api';
import { EssentialInformation } from './EssentialInformation';

export const AddViolationView = () => {
  const navigate = useNavigate();
  const goBack = useGoBack();

  const { t } = useLocalization();
  const { addViolation } = useViolations();

  const form = useForm();

  const [isSubmiting, setIsSubmiting] = createSignal<boolean>(false);

  async function onSubmit(formData: MagicDoor.Api.CreateAssociationViolationRequestDto) {
    try {
      setIsSubmiting(true);
      const response = await addViolation(formData);
      form?.resetFields();
      toast.success(t('{name} has been added successfully', { name: 'Violation' }));
      navigate(`/associations/violations/${response.id}`);
    } finally {
      setIsSubmiting(false);
    }
  }

  return (
    <div class="flex size-full flex-col">
      <Form
        initialValues={{
          status: AssociationViolationStatus.Received,
          violationReceivedDate: dayjs().format('YYYY-MM-DD'),
          violationDate: dayjs().format('YYYY-MM-DD'),
        }}
        defaultForm={form}
        onFormSubmit={(form) => onSubmit(form as MagicDoor.Api.CreateAssociationViolationRequestDto)}>
        <CategoryComponentWrapper
          sections={[
            {
              key: 'essential-information',
              name: t('Essential information'),
              component: EssentialInformation,
              fields: [
                'description',
                'title',
                'unitId',
                'leaseId',
                'status',
                'propertyId',
                'hoaVendorId',
                'violationDate',
                'violationReceivedDate',
                'fixByDate',
                'associationFine',
                'reference',
              ],
            },
          ]}>
          <Breadcrumb
            backLink={() => goBack()}
            items={[{ label: t(`Violations`), link: '../' }, { label: t(`Add association violation`) }]}
          />
          <div class="m-8 flex h-section1 flex-col overflow-hidden rounded-lg bg-white text-sm">
            <div class="flex flex-1 overflow-hidden">
              <div class="hidden w-[330px] shrink-0 border-r border-r-partingline lg:block">
                <h2 class="p-6 text-lg font-medium text-text-level01">{t('Violation')}</h2>
                <CategoryComponentSection {...getErrorAndProgressProps(form)} />
              </div>
              <div class="thinscroll flex grow flex-col items-stretch overflow-auto pb-8 text-left">
                <CategoryContentSection mode="padding" />
              </div>
            </div>
            <div class="flex h-24 items-center justify-end gap-2 border-t border-partingline px-5">
              <Button
                onClick={() => {
                  goBack();
                }}
                variant="outlined">
                {t('Cancel')}
              </Button>
              <Button loading={isSubmiting()} type="submit">
                {t('Add association violation')}
              </Button>
            </div>
          </div>
        </CategoryComponentWrapper>
      </Form>
    </div>
  );
};
