import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal, For, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconCircleNotSelected from '~/assets/images/common/circleNotSelected.svg?component-solid';
import IconCircleSelected from '~/assets/images/common/circleSelected.svg?component-solid';
import IconApprove from '~/assets/images/rental-application/approve.svg?component-solid';
import IconConvertToLease from '~/assets/images/rental-application/convert-to-lease.svg?component-solid';
import IconConvertToTenant from '~/assets/images/rental-application/convert-to-tenant.svg?component-solid';
import IconIgnore from '~/assets/images/rental-application/quick-ignore.svg?component-solid';
import IconReject from '~/assets/images/rental-application/quick-reject.svg?component-solid';
import IconPayment from '~/assets/images/rental-application/receive-payment.svg?component-solid';
import IconRefundPayment from '~/assets/images/rental-application/refund-payment.svg?component-solid';
import { useForm } from '~/components/common/BetterForm';
import { Dropdown } from '~/components/common/Dropdown';
import { RingLoader } from '~/components/common/Loaders';
import { ActionButton } from '~/components/rental-application/rental-application-details/infomation/ActionButton';
import { ConvertToLeaseModal } from '~/components/rental-application/rental-application-details/infomation/quick-actions-modal/ConvertToLeaseModal';
import { ConvertToTenantModal } from '~/components/rental-application/rental-application-details/infomation/quick-actions-modal/ConvertToTenantModal';
import { ReceivePaymentModal } from '~/components/rental-application/rental-application-details/infomation/quick-actions-modal/ReceivePaymentModal';
import { RefundPaymentModal } from '~/components/rental-application/rental-application-details/infomation/quick-actions-modal/RefundPaymentModal';
import { confirm, IconChevronDown, Skeleton, toast } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { ApplicationDecision } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { paymentMethodOptions } from '~/utils/constant';
import { urlWithQuery } from '~/utils/url';
import { CopyApplicationLink } from './CopyApplicationLink';
import type { Component } from 'solid-js';

const ApplicationDecisionConfig: Record<string, { label: string; icon: Component; class: string }> = {
  [ApplicationDecision.Approved]: {
    label: 'Approve',
    icon: IconApprove,
    class: 'bg-light-green/5 text-light-green border-light-green/40',
  },
  [ApplicationDecision.Ignored]: {
    label: 'Ignore',
    icon: IconIgnore,
    class: 'bg-[#3651BE]/5 text-[#3651BE] border-[#3651BE]/40',
  },
  [ApplicationDecision.Rejected]: {
    label: 'Reject',
    icon: IconReject,
    class: 'bg-danger/5 text-danger border-danger/40',
  },
};

const useQuickActions = () => {
  const { t } = useLocalization();
  const {
    applicationStore,
    tenantConvert,
    handleUpdateDecision,
    paymentRefund,
    fetchApplication,
    canConvertToLease,
    canConvertToTenant,
    canRefundPayment,
    canReceivePayment,
    receivePayment,
    settings,
  } = useRentalApplication();
  const navigate = useNavigate();
  const [unitId, setUnitId] = createSignal<string>('');
  const [refundAmount, setRefundAmount] = createSignal<string>('');
  const [refundReason, setRefundReason] = createSignal<string>('');
  const [isRefundDisabled, setRefundDisabled] = createSignal<boolean>(false);

  createEffect(() => {
    setRefundDisabled(refundAmount().length === 0 || refundReason().length === 0 || refundReason().length > 150);
  });

  const hasActions = createMemo(() => {
    return canConvertToTenant() || canConvertToLease() || canRefundPayment() || canReceivePayment();
  });

  const handleConvertToTenant = () => {
    const name = `${applicationStore.application?.firstName} ${applicationStore.application?.lastName}`;

    return confirm({
      title: t('Convert to tenant'),
      class: 'px-0',
      footerClass: 'px-5',
      titleClass: 'px-5',
      doneText: t('Confirm'),
      content: <ConvertToTenantModal name={name} />,
      async onResolve(confirmed) {
        if (!confirmed) return;
        const applicationId = applicationStore.application?.id;
        if (!applicationId) return;
        const tenant = await tenantConvert(applicationId);
        navigate(`/users/tenants/${tenant.id}`);
      },
    });
  };

  const handleConvertToLease = () => {
    const params: any = {
      rentalApplicationId: applicationStore.application?.id,
      startDate: applicationStore.application?.desiredMoveInDate,
      convertTenant: 1,
    };

    const preferredUnitOptions: MagicDoor.Api.UnitDto[] = applicationStore.application?.interests?.map((item) => item.unit) || [];

    const navigateToNewLease = async () => {
      await handleUpdateDecision(ApplicationDecision.Approved, false);
      const link = urlWithQuery(`/leasing/leases/new`, params);
      navigate(link);
    };

    return confirm({
      title: t('Convert to lease'),
      content: () => (
        <ConvertToLeaseModal
          preferredUnitOptions={preferredUnitOptions}
          onUnitSelect={(item) => {
            if (item) {
              params.portfolioId = item.portfolioId;
              params.propertyId = item.propertyId;
              params.unitId = item.id;
              setUnitId(item.id);
            } else {
              setUnitId('');
            }
          }}
        />
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        if (!unitId()) return toast.error(t('Please select unit'));
        await navigateToNewLease();
      },
      confirmDisabled: () => !unitId(),
    });
  };

  const handleRefundPayment = () => {
    return confirm({
      title: t('Refund payment'),
      class: 'px-0',
      footerClass: 'px-6',
      titleClass: 'px-5',
      doneText: t('Confirm'),
      content: () => (
        <RefundPaymentModal
          refundAmount={refundAmount()}
          refundReason={refundReason()}
          maxAmount={applicationStore.application?.paymentAmount || 0}
          onAmountChange={setRefundAmount}
          onReasonChange={setRefundReason}
        />
      ),
      confirmDisabled: () => isRefundDisabled(),
      async onResolve(confirmed) {
        if (!confirmed) {
          setRefundAmount('');
          setRefundReason('');
          return;
        }
        const applicationId = applicationStore.application?.id;
        if (!applicationId) return;
        try {
          await paymentRefund(applicationId, Number(refundAmount()), refundReason());
          fetchApplication(applicationId);
        } catch (error) {
          toast.error(t(`Operation failed, please try again later ${error}`));
        }
      },
    });
  };

  const handleReceivePayment = () => {
    const form = useForm({
      initialValues: {
        amount: settings()?.paymentAmount,
        paymentMethod: paymentMethodOptions(t)[0].value,
      },
    });
    const handleSubmit = async () => {
      const { validateStatus } = form.validateForm();
      if (!validateStatus || !applicationStore.application?.id) {
        return;
      }
      await receivePayment(applicationStore.application?.id, form.formStore as MagicDoor.Api.RentalApplicationOfflinePaymentDto);
      fetchApplication(applicationStore.application?.id);
      toast.success(t('{name} has been added successfully', { name: t('Receive payment') }));
    };

    return confirm({
      title: t('Receive payment'),
      content: () => <ReceivePaymentModal form={form} />,
      async onResolve(confirmed) {
        if (!confirmed) return;
        const { validateStatus } = form.validateForm();
        if (!validateStatus) throw new Error('Invalid form');
        await handleSubmit();
      },
      confirmDisabled: () => !form.isValidate,
    });
  };

  return {
    handleConvertToTenant,
    handleConvertToLease,
    handleRefundPayment,
    handleReceivePayment,
    hasActions,
  };
};

const DecisionTag = (props: { decision?: string }) => {
  const { t } = useLocalization();
  const currentDecisionConfig = createMemo(() => (props.decision ? ApplicationDecisionConfig[props.decision] : null));
  return (
    <div class="flex items-center gap-2">
      <Dynamic component={currentDecisionConfig()?.icon} />
      <span>{t(currentDecisionConfig()?.label)}</span>
    </div>
  );
};

const StatusSelector = () => {
  const { applicationStore, handleUpdateDecision } = useRentalApplication();
  const currentDecisionConfig = createMemo(
    () => applicationStore.application?.applicationDecision && ApplicationDecisionConfig[applicationStore.application?.applicationDecision]
  );
  return (
    <Dropdown
      class="grid grid-cols-1"
      renderLabel={(isOpen, open) => {
        return (
          <ActionButton
            onClick={() => open(!isOpen)}
            label={
              <>
                <DecisionTag decision={applicationStore.application?.applicationDecision} />
                <IconChevronDown
                  class="size-3.5 transition-transform ease-in-out"
                  classList={{
                    'rotate-180': isOpen,
                  }}
                />
              </>
            }
            class={cn(
              'flex cursor-pointer select-none items-center justify-between rounded-lg border border-light-green/40 bg-light-green/5 text-light-green',
              currentDecisionConfig()?.class
            )}
          />
        );
      }}>
      {(_, open) => (
        <div class="flex flex-col gap-2">
          <For each={[ApplicationDecision.Approved, ApplicationDecision.Rejected, ApplicationDecision.Ignored]}>
            {(decision) => (
              <div
                class="flex cursor-pointer items-center justify-between gap-2"
                onClick={() => {
                  handleUpdateDecision(decision);
                  open(false);
                }}>
                <DecisionTag decision={decision} />
                <Show when={decision === applicationStore.application?.applicationDecision} fallback={<IconCircleNotSelected />}>
                  <IconCircleSelected />
                </Show>
              </div>
            )}
          </For>
        </div>
      )}
    </Dropdown>
  );
};

const StatusSwitch = () => {
  const { t } = useLocalization();
  const { isInitial, handleUpdateDecision } = useRentalApplication();

  return (
    <div class="relative">
      <Show when={isInitial()} fallback={<StatusSelector />}>
        <div class="grid grid-cols-3 gap-2">
          <ActionButton
            class="flex-col border border-light-green/40 bg-light-green/5 text-light-green"
            label={t('Approve')}
            icon={IconApprove}
            onClick={() => handleUpdateDecision(ApplicationDecision.Approved)}
          />
          <ActionButton
            class="flex-col border border-[#3651BE]/40 bg-[#3651BE]/5 text-[#3651BE]"
            label={t('Ignore')}
            icon={IconIgnore}
            onClick={() => handleUpdateDecision(ApplicationDecision.Ignored)}
          />
          <ActionButton
            class="flex-col border border-danger/40 bg-danger/5 text-danger"
            label={t('Reject')}
            icon={IconReject}
            onClick={() => handleUpdateDecision(ApplicationDecision.Rejected)}
          />
        </div>
      </Show>
      <Show when={handleUpdateDecision.loading}>
        <div class="absolute inset-0 -m-1 flex items-center justify-center rounded-lg bg-black/5">
          <RingLoader color={'#A126EC'} />
        </div>
      </Show>
    </div>
  );
};

const LoadingSkeleton = () => {
  return (
    <div class="flex flex-col gap-2">
      <Skeleton />
      <Skeleton class="w-3/4" />
      <Skeleton class="w-3/4" />
    </div>
  );
};

export const QuickActions = () => {
  const { t } = useLocalization();
  const { handleConvertToTenant, handleConvertToLease, handleRefundPayment, handleReceivePayment } = useQuickActions();
  const { fetchApplication, canConvertToTenant, canConvertToLease, canRefundPayment, canReceivePayment } = useRentalApplication();

  return (
    <div class="flex flex-col gap-2 pt-6">
      <div class="text-base font-semibold leading-tight text-text-level01">{t('Quick action')}</div>
      <Show when={!fetchApplication.loading} fallback={<LoadingSkeleton />}>
        <StatusSwitch />
        <Show when={canConvertToTenant()}>
          <ActionButton
            class="bg-[#5C63F4]"
            label={t('Convert to tenant')}
            icon={IconConvertToTenant}
            onClick={() => handleConvertToTenant()}
          />
        </Show>
        <Show when={canConvertToLease()}>
          <ActionButton class="bg-primary" label={t('Convert to lease')} icon={IconConvertToLease} onClick={() => handleConvertToLease()} />
        </Show>
        <Show when={canRefundPayment()}>
          <ActionButton class="bg-danger" label={t('Refund payment')} icon={IconRefundPayment} onClick={() => handleRefundPayment()} />
        </Show>
        <Show when={canReceivePayment()}>
          <ActionButton class="bg-light-green" label={t('Receive payment')} icon={IconPayment} onClick={() => handleReceivePayment()} />
        </Show>
        <CopyApplicationLink />
      </Show>
    </div>
  );
};
