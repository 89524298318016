import { Show } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type IncomeStatisticsProps = {
  class?: string;
  title?: string;
  description?: string;
  actions?: JSX.Element;
  children: JSX.Element;
  bgClass?: string;
  bgImgSrc?: string;
  contentClass?: string;
  header?: JSX.Element;
};

const Card: Component<IncomeStatisticsProps> = (props) => {
  const header = () => {
    return (
      <div class={cn('relative flex h-16 w-full items-center justify-between border-b border-partingline px-6 py-3', props.bgClass)}>
        <Show when={!props.header} fallback={props.header}>
          <div>
            <div class="truncate text-sm font-semibold capitalize text-text-level01 lg:text-lg">{props.title}</div>
            <div class="mt-0.5 text-xxs normal-case text-text-level03 md:text-xs">{props.description}</div>
          </div>
          <div class="z-10">{props.actions}</div>
          <Show when={props.bgImgSrc}>
            <img class="absolute right-0 z-0 h-full" src={props.bgImgSrc} alt="Banner Background" />
          </Show>
        </Show>
      </div>
    );
  };
  return (
    <Panel class={cn('min-h-full', props.class)} header={header()}>
      <div class={cn('thinscroll size-full h-[340px] overflow-y-auto', props.contentClass)}>{props.children}</div>
    </Panel>
  );
};

export default Card;
