import { createErrorFromResponse, getContentFromResponse, UnauthorizedError } from '@magicdoor/errors';
import { toast, alert } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { parseErrorMessage } from '~/errors/errorCodeMap';
import { tokenStorage } from '~/utils/storage';

export abstract class BaseRestRepository {
  protected async _fetch(url: string, init: RequestInit, showErrorMessage = true) {
    const response = await fetch(url, init);

    if (response.ok) {
      return response;
    }
    const error = createErrorFromResponse(response.status, await getContentFromResponse(response));

    if (response.status === 401) {
      this.redirectToAuth();
      throw error;
    }

    if (showErrorMessage) toast.error(parseErrorMessage(error));
    throw error;
  }
  protected async fetchWithAuth(url: string, options: RequestInit = {}, showErrorMessage = true): Promise<Response> {
    const init = this.getRequestInit(true, options);

    return this._fetch(url, init, showErrorMessage);
  }

  protected async fetchWithoutAuth(url: string, options: RequestInit = {}, showErrorMessage = true): Promise<Response> {
    const init = this.getRequestInit(false, options);

    return this._fetch(url, init, showErrorMessage);
  }

  protected async getJsonResponse(response: Response, swallow = false) {
    try {
      return await response.json();
    } catch (e: any) {
      if (response.status === 200) {
        console.error('Error parsing json:', e);
        if (swallow) {
          console.warn('Swallowing error');
          return {};
        }
        throw new Error('JSON parsing failed');
      }
    }
  }

  private getRequestInit(withAuth: boolean, init: RequestInit = {}): RequestInit {
    init.headers = init.headers || {};

    if (init.headers instanceof Headers) {
      init.headers = { ...init.headers };
    }

    const headers = init.headers as Record<string, string | undefined>;

    const contentType = headers['content-type'] || headers['Content-Type'];

    if (contentType === 'multipart/form-data') {
      // delete custom content-type, browser will add correct one
      delete headers['content-type'];
      delete headers['Content-Type'];
    } else if (contentType == null) {
      headers['content-type'] = 'application/json';
    }

    if (withAuth) {
      const token = tokenStorage.get();
      if (token == null) throw new UnauthorizedError();
      headers['authorization'] = `Bearer ${token}`;
    }

    return init;
  }

  private redirectToAuth() {
    const { t } = useLocalization();
    alert({
      content: t('Your login has expired. Please log in again.'),
      doneText: t('Confirm'),
      class: 'w-[380px]',
      singleDialogMode: true,
      async onResolve() {
        tokenStorage.clear();
        const authUrl = '/auth/signin?redirect=' + location.pathname + location.search;
        window.location.replace(authUrl);
      },
    });
  }
}
