import { useParams } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

const PropertyManagerInformationSection: Component = () => {
  const { t } = useLocalization();
  const { managerId } = useParams<{ managerId?: string }>();

  const lastNameRules = createMemo<BetterForm.Rule[]>(() => {
    const rules: BetterForm.Rule[] = [
      {
        length: 150,
        message: t('Cannot exceed 150 characters'),
      },
    ];

    return rules;
  });

  return (
    <div class="space-y-6">
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">
        {t('Property manager information')}
      </div>
      <div class="grid grid-cols-2 gap-6">
        <FormItem
          label={t('First name')}
          placeholder={t('Please enter the {name}', { name: t('First name') })}
          component={LabeledTextInput}
          formFieldName="firstName"
          rules={[
            {
              required: true,
              message: t('Please enter the {name}', { name: t('First name') }),
            },
            {
              length: 150,
              message: t('Cannot exceed 150 characters'),
            },
          ]}
        />
        <FormItem
          label={t('Last name')}
          placeholder={t('Please enter the {name}', { name: t('Last name') })}
          component={LabeledTextInput}
          formFieldName="lastName"
          rules={lastNameRules()}
        />
        <FormItem
          label={t('Email')}
          placeholder={t('Please enter the {name}', { name: t('Email') })}
          component={LabeledTextInput}
          formFieldName="email"
          rules={[
            {
              required: true,
              message: t('Please enter the {name}', { name: t('Email') }),
            },
            {
              type: 'email',
              message: t('Please enter a valid email'),
            },
          ]}
        />
        <Show when={!managerId}>
          <FormItem
            label={t('Password')}
            type="password"
            placeholder={t('Please enter the {name}', { name: t('Password') })}
            component={LabeledTextInput}
            formFieldName="clearTextPassword"
            rules={[
              {
                required: true,
                message: t('Please enter the {name}', { name: t('Password') }),
              },
              {
                validator: (value: string) => !value || value.length >= 5,
                message: t('{name} must be at least {minLength} characters', { name: t('Password'), minLength: 5 }),
              },
              {
                length: 150,
                message: t('Cannot exceed 150 characters'),
              },
            ]}
          />
        </Show>
      </div>
    </div>
  );
};

export default PropertyManagerInformationSection;
