import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { MultiSteps } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { OwnerTaxAmountPresenter } from '~/pdfsigner/presenters/taxes/TaxDocPresenter';
import { GenerateOwnerTaxDocsUseCase } from '~/pdfsigner/usecases/taxes/owner/generateOwnerTaxDocsUseCase';
import { GetAvailableOwnerTaxYearsUseCase } from '~/pdfsigner/usecases/taxes/owner/getAvailableOwnerTaxYearsUseCase';
import { ChooseOwners } from './ChooseOwners';
import { OwnerTaxAmountsPage } from './OwnerTaxAmountsPage';
import { OwnerTaxDocGenerationSummary } from './OwnerTaxDocGenerationSummary';

export const GenerateOwnerTaxDocs = () => {
  const { t } = useLocalization();
  const { ownerId } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = createSignal(0);
  const breadcrumbItems = createMemo(() => [
    { label: t('Tax documents'), link: '/users/owners/tax-documents' },
    { label: t('Generate tax documents') },
  ]);
  const steps = ownerId ? [t('Choose year'), t('Income amounts'), t('Summary')] : [t('Choose owners'), t('Income amounts'), t('Summary')];
  const stepComponents = createMemo(() => [ChooseOwners, OwnerTaxAmountsPage, OwnerTaxDocGenerationSummary]);
  const { execute: generateOwnerTaxDocs } = useUseCase(GenerateOwnerTaxDocsUseCase);
  const { execute: getAvailableTaxYears, isLoading } = useUseCase(GetAvailableOwnerTaxYearsUseCase);
  const { model: ownerTaxAmounts } = usePresenter(OwnerTaxAmountPresenter);

  onMount(() => getAvailableTaxYears(ownerId));

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const shouldDisableNext = (): boolean => {
    if (currentStep() < 2) {
      return !ownerTaxAmounts()?.hasAmountsToShow;
    }
    const selectedNewOrChanged = ownerTaxAmounts()?.ownerTaxAmounts.newOrChanged.filter((owner) => owner.isSelected);
    return selectedNewOrChanged?.length === 0;
  };

  const getTitle = () => {
    let ownerName = undefined;
    if (ownerId) {
      const newOwnerName = ownerTaxAmounts()?.ownerTaxAmounts.newOrChanged.find((owner) => owner.ownerId === ownerId)?.ownerName;
      const existingOwnerName = ownerTaxAmounts()?.ownerTaxAmounts.existing.find((owner) => owner.ownerId === ownerId)?.ownerName;
      ownerName = newOwnerName || existingOwnerName;
    }
    if (ownerName && ownerId) {
      return t('Generate tax documents for {owner}', { owner: ownerName });
    }
    return t('Generate tax documents');
  };

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 h-section1">
        <Show
          when={!isLoading()}
          fallback={
            <div class="flex size-full flex-col items-center justify-center rounded-lg bg-white">
              <RingLoader color="#a126ec" size={100} showIcon={true} />
            </div>
          }>
          <Panel title={getTitle()} class="flex size-full flex-col p-0">
            <MultiSteps
              showPrevious
              loading={false}
              submitText={t('Submit')}
              onSubmit={() => generateOwnerTaxDocs(ownerId)}
              steps={stepComponents()}
              disableNext={shouldDisableNext()}
              currentStep={currentStep()}
              contentClass="pt-9 [&>div]:mx-auto [&>div]:w-full [&>div]:max-w-[1200px]"
              stepper={<Stepper class="flex justify-center pb-[72px]" steps={steps} step={currentStep()} />}
              onClose={() => navigate(-1)}
              onStepChange={(step) => onStepChange(step)}
            />
          </Panel>
        </Show>
      </div>
    </div>
  );
};
