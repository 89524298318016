import { createSignal, onMount, createMemo, batch } from 'solid-js';
import { usePortfolios, useLocalization } from '~/contexts/global';

export type ExtendableProtfolioAndPropertyFilterValue<T> = {
  portfolio?: string;
  property?: string;
} & T;

export interface ProtfolioAndPropertyFilterProps<T> {
  defaultValue?: T;
}

export const allOption = createMemo(() => {
  const { t } = useLocalization();
  return { label: t('All'), value: undefined };
});

export const protfolioAndPropertyFilter = <T>(opts?: ProtfolioAndPropertyFilterProps<T>) => {
  const { defaultValue } = opts ?? {};
  const [filterValue, setFilterValue] = createSignal<T>({} as T);
  const { t } = useLocalization();

  const { portfolios } = usePortfolios();

  const onPropertyIdChange = (value: string) => {
    const portfolio = portfolios()?.find((item) => item.properties?.some((prop) => prop.id === value));
    setFilterValue((prev) => ({ ...prev, portfolio: portfolio?.id }));
  };

  const [portfolioId, setPortfolioId] = createSignal<string>();

  const handleFilterReset = () => {
    setFilterValue(() => ({}) as T);
    setPortfolioId(undefined);
  };

  const onPortfolioIdChange = (value: string) => {
    setFilterValue((prev) => ({ ...prev, property: undefined }));
    setPortfolioId(value);
  };

  const filterItems = [
    {
      type: 'select',
      key: 'portfolio',
      label: t('Portfolio'),
      filterable: true,
      options: async () => {
        if (portfolios.loading) return new Promise<[]>(() => void 0);
        return [allOption(), ...(portfolios()?.map((item) => ({ value: item.id, label: item.name })) ?? [])];
      },
      onChange: onPortfolioIdChange,
    },
    {
      type: 'select',
      key: 'property',
      label: t('Property'),
      filterable: true,
      options: async () => {
        if (portfolios.loading) return new Promise<[]>(() => void 0);
        const id = portfolioId();
        if (id == null) {
          const opts = portfolios()?.flatMap((item) => item.properties?.map((item) => ({ value: item.id, label: item.name })) ?? []);
          return [allOption(), ...(opts ?? [])];
        }
        const portfolio = portfolios()?.find((item) => item.id === id);
        const opts = portfolio?.properties?.map((item) => ({ value: item.id, label: item.name })) ?? [];
        return [allOption(), ...opts];
      },
      onChange: onPropertyIdChange,
    },
  ];

  onMount(() => {
    if (!defaultValue) {
      return;
    }

    batch(() => {
      for (const key of Object.keys(defaultValue)) {
        const value: any = defaultValue[key as keyof typeof defaultValue];

        if (Object.prototype.hasOwnProperty.call(defaultValue, key)) {
          setFilterValue((prev) => ({ ...prev, [key]: value }));

          if (key === 'property') {
            onPropertyIdChange(value);
          } else if (key === 'portfolio') {
            onPortfolioIdChange(value);
          }
        }
      }
    });
  });

  return { filterValue, handleFilterReset, filterItems };
};
