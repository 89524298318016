import { createSignal, createMemo } from 'solid-js';
import { useLocalization, usePortfolios, useRentalApplication } from '~/contexts/global';

export type FilterValue = {
  portfolioIds?: string[];
  portfolioId?: string;
  propertyIds?: string[];
  propertyId?: string;
  unitIds?: string[];
  'submitted.Start'?: string;
  'submitted.End'?: string;
};

export type SelectOption = {
  value: string | undefined;
  label: string;
};

export type SelectFilterItem<T> = {
  type: 'select';
  label: string;
  key: keyof T;
  options: () => Promise<SelectOption[]> | SelectOption[];
  filterable?: boolean;
  onChange?: (value: string) => void;
};

export type FilterItem<T> = SelectFilterItem<T>;

export type FilterItems<T> = FilterItem<T>[];

export const useRentalApplicationList = () => {
  const { t } = useLocalization();
  const { isLoading, rentalApplications, getRentalApplications, pagination } = useRentalApplication();
  const [filterValue, setFilterValue] = createSignal<FilterValue>({});
  const [portfolioId, setPortfolioId] = createSignal<string>();
  const { portfolios } = usePortfolios();

  const allOption = createMemo((): SelectOption => ({ label: t('All'), value: undefined }));

  const filterItems = createMemo(
    () =>
      [
        {
          type: 'select',
          key: 'portfolioIds',
          label: t('Portfolio'),
          filterable: true,
          options: async () => {
            if (portfolios.loading) return [];
            return [allOption(), ...(portfolios()?.map((item) => ({ value: item.id, label: item.name })) ?? [])];
          },
          onChange: (value: string) => {
            setPortfolioId(value);
          },
        },
        {
          type: 'select',
          key: 'propertyIds',
          label: t('Property'),
          filterable: true,
          options: async () => {
            if (portfolios.loading) return [];
            const id = portfolioId();
            if (id == null) {
              const opts = portfolios()?.flatMap((item) => item.properties?.map((item) => ({ value: item.id, label: item.name })) ?? []);
              return [allOption(), ...(opts ?? [])];
            }
            const portfolio = portfolios()?.find((item) => item.id === id);
            const opts = portfolio?.properties?.map((item) => ({ value: item.id, label: item.name })) ?? [];
            return [allOption(), ...opts];
          },
        },
      ].filter(Boolean) as FilterItems<FilterValue>
  );

  const handleFilterReset = () => {
    setFilterValue({});
    setPortfolioId(undefined);
  };

  return {
    t,
    isLoading,
    rentalApplications,
    getRentalApplications,
    pagination,
    filterValue,
    setFilterValue,
    allOption,
    filterItems,
    handleFilterReset,
  };
};
