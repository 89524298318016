import IconSummaryBill from '~/assets/images/vendors/summaryBill.png';
import IconSummaryCommunications from '~/assets/images/vendors/summaryCommunications.png';
import IconSummaryTransaction from '~/assets/images/vendors/summaryTransaction.png';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { Image } from '~/components/ui';
import { useLocalization, useVendors } from '~/contexts/global';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';

interface VendorSummaryInformationProps {
  name: string;
  value: string | JSX.Element;
  activityOverviewNumber: number;
  activityOverview: string;
  src: string;
}

const VendorSummaryInformation = (props: VendorSummaryInformationProps) => {
  return (
    <ThreeSectionBox
      class="w-full"
      topTitle={<div class="text-sm font-semibold text-text-level02">{props.name}</div>}
      middle={
        <div class="flex justify-between p-4 text-left">
          <div class="flex flex-col gap-1">
            <div class="text-xl font-semibold text-text-level01">{props.value}</div>
            <div class="flex gap-1 text-text-level03">
              <div class="text-red-500">{props.activityOverviewNumber}</div>
              <div>{props.activityOverview}</div>
            </div>
          </div>
          <Image class="size-12" src={props.src} alt={props.name} />
        </div>
      }
    />
  );
};

const VendorSummary = () => {
  const { vendorOverview } = useVendors();

  const { t } = useLocalization();

  return (
    <div class="flex w-full items-start text-xs text-text-level01">
      <div class="flex w-full gap-4">
        <VendorSummaryInformation
          name={t('Total bill')}
          value={currency(vendorOverview()?.unpaidBills?.totalDue || 0)}
          activityOverviewNumber={vendorOverview()?.unpaidBills?.items?.length || 0}
          activityOverview={t('Bills pending')}
          src={IconSummaryBill}
        />
        <VendorSummaryInformation
          name={t('Gross transaction')}
          value={currency(vendorOverview()?.transactions?.totalAmount || 0)}
          activityOverviewNumber={vendorOverview()?.transactions?.totalCount || 0}
          activityOverview={t('New transactions')}
          src={IconSummaryTransaction}
        />
        <VendorSummaryInformation
          name={t('Communications')}
          value={vendorOverview()?.communications?.messagesCount || 0}
          activityOverviewNumber={vendorOverview()?.communications?.unreadMessagesCount || 0}
          activityOverview={t('New record')}
          src={IconSummaryCommunications}
        />
      </div>
    </div>
  );
};

export default VendorSummary;
