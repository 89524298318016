import { createSignal, mergeProps, onCleanup, onMount } from 'solid-js';
import Tooltip from '~/components/common/Tooltip';
import type { Component } from 'solid-js';

interface TextOverflowProps {
  text: string;
  maxLines?: number;
}

const TextOverflow: Component<TextOverflowProps> = (originProps) => {
  const props = mergeProps({ maxLines: 2 }, originProps);
  const [tooltipDisabled, setTooltipDisabled] = createSignal<boolean>(true);
  let textContainer: HTMLDivElement | undefined = undefined;

  onMount(() => {
    if (!textContainer) return;
    const { clientHeight, scrollHeight } = textContainer;
    if (clientHeight < scrollHeight) {
      setTooltipDisabled(false);
    } else {
      setTooltipDisabled(true);
    }
  });

  onCleanup(() => {
    textContainer = undefined;
  });

  return (
    <Tooltip align="top" disabled={tooltipDisabled()} message={props.text}>
      <div
        ref={textContainer}
        style={{
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          display: '-webkit-box',
          '-webkit-line-clamp': props.maxLines,
          '-webkit-box-orient': 'vertical',
        }}>
        <span class="cursor-pointer" style={{ 'overflow-wrap': 'anywhere' }}>
          {props.text}
        </span>
      </div>
    </Tooltip>
  );
};

export default TextOverflow;
