import { Form } from '@magicdoor/form';
import { useNavigate } from '@solidjs/router';
import { onMount } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Button } from '~/components/common/Buttons';
import { AuthPageContainer } from '~/components/reset-password/AuthPageContainer';
import { Input } from '~/components/reset-password/InputField';
import { restoreEmail, saveEmail } from '~/components/reset-password/emailStorage';
import { useLocalization } from '~/contexts/global';
import { useResetPassword, ResetPasswordStep } from '~/contexts/local';
import IconLinkSend from './link-sended.svg?component-solid';
import IconSuccess from './success.svg?component-solid';
export const ResetPassword = () => {
  const { step, form, handleFormSubmit } = useResetPassword();

  const componentMap = {
    [ResetPasswordStep.InputAccount]: InputAccount,
    [ResetPasswordStep.InputPasswords]: InputPasswords,
    [ResetPasswordStep.LinkSended]: LinkSended,
    [ResetPasswordStep.ResetSuccess]: ResetSuccess,
  };

  return (
    <AuthPageContainer>
      <Form defaultForm={form} class="flex flex-col gap-4 xl:w-96" onFormSubmit={handleFormSubmit}>
        <Dynamic component={componentMap[step()]} />
      </Form>
    </AuthPageContainer>
  );
};

const InputAccount = () => {
  const { t } = useLocalization();
  const { getResetPasswordEmail, form } = useResetPassword();

  onMount(() => {
    restoreEmail(form);
  });
  return (
    <div class="flex flex-col gap-4">
      <h2 class="text-level-1 mb-3 text-3xl font-medium">{t('Forget password')} </h2>
      <Input
        label="Enter your mail to reset"
        placeholder="mail@magicdoor.com"
        formFieldName="email"
        rules={[
          { required: true, message: t('{name} is required', { name: t('Email') }) },
          { type: 'email', message: t('{name} is invalid', { name: t('Email') }) },
        ]}
        onChange={(e) => {
          saveEmail(e.target.value);
        }}
      />
      <Button rounded="full" class="mt-4 flex justify-center" type="submit" loading={getResetPasswordEmail.loading}>
        {t('Continue')}
      </Button>
    </div>
  );
};

const InputPasswords = () => {
  const { t } = useLocalization();
  const { resetPassword } = useResetPassword();
  const { form } = useResetPassword();
  return (
    <div class="flex flex-col gap-4">
      <h2 class="text-level-1 mb-3 text-3xl font-medium">{t('Reset password')}</h2>
      <Input
        label={t('Enter your new password')}
        placeholder={t('Enter your new password')}
        formFieldName="password"
        type="password"
        rules={[
          {
            required: true,
            message: t('{name} is required', { name: t('New password') }),
          },
          {
            length: [5, 150],
            message: t('{name} must be between {min} and {max} characters', {
              name: t('New password'),
              min: 5,
              max: 150,
            }),
          },
        ]}
      />
      <Input
        label={t('Confirm your new password')}
        placeholder={t('Confirm your new password')}
        formFieldName="passwordConfirm"
        type="password"
        rules={[
          {
            required: true,
            message: t('Please confirm your new password'),
          },
          {
            validator: (value) => {
              if (value !== form.formStore.password) {
                return t('Passwords do not match');
              }
              return true;
            },
          },
        ]}
      />
      <Button rounded="full" class="mt-4 flex justify-center" type="submit" loading={resetPassword.loading}>
        {t('Reset')}
      </Button>
    </div>
  );
};

const LinkSended = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  return (
    <div class="flex flex-col items-center gap-4">
      <IconLinkSend />
      <h2 class="text-center font-medium text-text-level01">
        {t('The link for resetting your password has been sent to your email. Please check it.')}
      </h2>
      <Button rounded="full" class="mt-4 flex justify-center self-stretch" onClick={() => navigate('/auth/signin')}>
        {t('Exit')}
      </Button>
    </div>
  );
};

const ResetSuccess = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  return (
    <div class="flex flex-col items-center gap-4">
      <IconSuccess />
      <h2 class="text-center font-medium text-text-level01">{t('Reset password successfully')}</h2>
      <Button rounded="full" class="mt-4 flex justify-center self-stretch" onClick={() => navigate('/auth/signin')}>
        {t('Return to login')}
      </Button>
    </div>
  );
};
