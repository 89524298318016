import { createMemo, For, Show, createSignal, createEffect, createResource } from 'solid-js';
import IconInfo from '~/assets/images/common/info.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import Tooltip from '~/components/common/Tooltip';
import { IconCheck, IconX } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { companyRepository } from '~/repositories/companiesRepository';
import IconMagic from './magic.svg?component-solid';
import IconStar from './star.svg?component-solid';
import type { CompanyStatusDto } from '~/swagger/Api';
interface ChecklistItem {
  id: keyof CompanyStatusDto | (keyof CompanyStatusDto)[];
  titleKey: string;
  infoKey?: string;
  link?: string;
}

const userManualClosedKey = 'user-manual-closed-information-checklist-because-of-all-completed';

const StatusBadge = (props: { completed?: boolean }) => {
  return (
    <Show when={props.completed} fallback={<div class="size-4 shrink-0 rounded-full border-2 border-white/80" />}>
      <div class="flex size-4 items-center justify-center rounded-full bg-success font-medium text-white">
        <IconCheck class="size-3" />
      </div>
    </Show>
  );
};

const hideIfCompletedKeys = [
  'hasPaymentAccounts',
  'companySettingsConfigured',
  'rentPaymentSettingsConfigured',
  'depositPaymentSettingsConfigured',
];

export const Checklist = () => {
  const { t } = useLocalization();

  const [isOpen, setIsOpen] = createSignal(localStorage.getItem(userManualClosedKey) !== 'true');

  const checklist: ChecklistItem[] = [
    {
      id: 'businessInfoSubmitted',
      titleKey: 'Submit business information',
      infoKey:
        'After setting up your business information, you can create an ACH payment account. You can also link to other external services for added support',
      link: '/settings/company/business',
    },
    {
      id: 'hasBankAccounts',
      titleKey: 'Set up bank accounts',
      infoKey: 'Add a bank account to manage transactions and record financial data.',
      link: '/accounting/bank-accounts/add',
    },
    {
      id: ['stripeConnected', 'plaidConnected'],
      titleKey: 'Connect to Stripe and Plaid',
      infoKey: 'Link external trading platforms to facilitate online transactions on the platform.',
      link: '/accounting/bank-accounts',
    },
    {
      id: 'hasPaymentAccounts',
      titleKey: 'Add payment accounts',
      infoKey: 'Add an account to pay MagicDoor invoices.',
      link: '/settings/company/payment-accounts',
    },
    {
      id: 'companySettingsConfigured',
      titleKey: 'Set up invoice settings',
      infoKey: 'Set up an account to pay MagicDoor invoices',
      link: '/settings/company/payment-accounts',
    },
    {
      id: 'rentPaymentSettingsConfigured',
      titleKey: 'Set up rent payment',
      infoKey: 'Set up an account to collect rent payments.',
      link: '/settings/property/rent-payment',
    },
    {
      id: 'depositPaymentSettingsConfigured',
      titleKey: 'Set up security deposit payment',
      infoKey: 'Set up an account to collect security deposits.',
      link: '/settings/property/deposit-payment',
    },
    {
      id: 'rentalApplicationSettingsConfigured',
      titleKey: 'Set up application fee',
      infoKey: 'Set up an account to collect application fees.',
      link: '/settings/rental/applicationfee',
    },
  ];

  const [companyStatus] = createResource(async () => {
    return await companyRepository.getCompanyStatus();
  });

  const isCompleted = (item: ChecklistItem) => {
    if (Array.isArray(item.id)) {
      return item.id.every((id) => companyStatus()?.[id]);
    }
    return companyStatus()?.[item.id];
  };

  const isAllCompleted = createMemo(() => {
    return Object.values(companyStatus() ?? {}).every((value) => value);
  });

  const handleClose = () => {
    setIsOpen(!isOpen());

    if (isAllCompleted()) {
      localStorage.setItem(userManualClosedKey, 'true');
    }
  };

  const needToShow = (item: ChecklistItem) => {
    if (!isCompleted(item)) return true;
    if (Array.isArray(item.id)) {
      return !item.id.some((id) => hideIfCompletedKeys.includes(id));
    }
    return !hideIfCompletedKeys.includes(item.id);
  };

  createEffect(() => {
    if (!isAllCompleted()) {
      localStorage.removeItem(userManualClosedKey);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  });

  return (
    <Show when={isOpen() && !companyStatus.loading}>
      <div class="relative col-span-full rounded-lg bg-white p-6 [background:linear-gradient(0deg,#BD31FF_0%,#2F00B5_100%),linear-gradient(0deg,#1A68DC_0%,#1A68DC_100%)] xl:col-span-8">
        <div class="mb-6">
          <h2 class="text-xl font-semibold text-white">{t('Improve the information')}</h2>
          <p class="mt-2 text-sm font-medium text-[#FFD52D]">
            {t('Complete the following steps to experience the complete functionality')}
          </p>
        </div>

        <div class="absolute right-2 top-2 cursor-pointer rounded-full bg-white/10 p-2" onClick={handleClose}>
          <IconX class="size-4 text-white" />
        </div>

        <IconStar class="absolute right-14 top-10" />
        <div class="flex justify-between gap-10">
          <div class="flex-1">
            <For each={checklist}>
              {(item) => (
                <Show when={needToShow(item)}>
                  <div class="flex items-center justify-between gap-10 rounded-lg py-2.5">
                    <div class="flex items-center justify-between gap-3">
                      <div class="flex items-center gap-3">
                        <StatusBadge completed={isCompleted(item)} />
                        <span
                          data-link={item.link}
                          class={`font-medium ${isCompleted(item) ? 'text-white/40 line-through' : 'text-white'}`}>
                          {t(item.titleKey)}
                        </span>
                      </div>
                      <Show when={item.infoKey}>
                        <Tooltip message={t(item.infoKey as string)}>
                          <IconInfo class="size-4 text-[#FFD446]" />
                        </Tooltip>
                      </Show>
                    </div>
                    <Show when={item.link && !isCompleted(item)}>
                      <Button href={item.link} size="xs" class="shrink-0 rounded-full" variant="outlined" color="white">
                        {t('Go →')}
                      </Button>
                    </Show>
                  </div>
                </Show>
              )}
            </For>
          </div>
          <IconMagic class="mr-4 hidden shrink-0 self-end lg:block" />
        </div>
      </div>
    </Show>
  );
};
