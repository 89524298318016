import DisplayAddress from '~/components/common/DisplayAddress';
import { UnitImage } from '~/components/units';
import { cn } from '~/utils/classnames';
import { getLeaseDateName } from './utils';

interface LeaseItemProps {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto;
  name?: string;
  address?: MagicDoor.Api.AddressDto;
}
export const LeaseItem = (props: LeaseItemProps) => {
  return (
    <div class={cn('flex overflow-hidden', props.class)}>
      <UnitImage class="mr-2 size-11 rounded-lg" unitId={props.lease?.unit.id} imageId={props.lease?.unit.imageId} />
      <div class="flex min-w-0 flex-col justify-center text-left">
        <div class="truncate text-sm font-medium text-text-level01">
          {props.name || getLeaseDateName(props.lease as MagicDoor.Api.HydratedLeaseDto)}
        </div>
        <DisplayAddress
          class="truncate text-xs text-text-level03"
          address={props.address || (props.lease as MagicDoor.Api.HydratedLeaseDto)?.property?.address}
        />
      </div>
    </div>
  );
};
