import { Show, createSignal } from 'solid-js';
import IconBot from '~/assets/images/chat/botMessage.png';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { getImageUrl } from '~/utils/file';
import type { HydratedChatParticipantDto } from '~/swagger/Api';

export interface ChatMessageProps {
  content: string;
  title: string;
  highlightText: string;
  participant: HydratedChatParticipantDto[];
  handleJump?: () => void;
}

export function highlightText(text: string, searchText: string, returnType: 'JSX' | 'DOMString') {
  if (!searchText) return text;

  const regex = new RegExp(`(${searchText})`, 'gi');
  const parts = text.split(regex);

  if (returnType === 'JSX') {
    return parts.map((part) =>
      part.toLowerCase() === searchText.toLowerCase() ? <span class="text-essential-colour">{part}</span> : <span>{part}</span>
    );
  }

  return parts
    .map((part) => (part.toLowerCase() === searchText.toLowerCase() ? `<span class="text-essential-colour">${part}</span>` : part))
    .join('');
}

export const ChatContentSearch = (props: ChatMessageProps) => {
  const { t } = useLocalization();
  const [showJump, setShowJump] = createSignal(false);

  const handleJump = () => {
    props.handleJump && props.handleJump();
  };

  const getAvatar = (avatar: HydratedChatParticipantDto, className: string) => {
    const url = getImageUrl('avatar', avatar.id);

    return (
      <Image src={url} alt={avatar.name!} fallback={IconBot} class={cn('border-1 absolute min-w-1 rounded-full border-white', className)} />
    );
  };

  const renderAvatars = () => {
    const avatars = props.participant;

    return (
      <Show when={avatars?.length > 0} fallback={<div class="size-10" />}>
        <Show
          when={avatars.length === 1}
          fallback={
            <Show
              when={avatars.length === 2}
              fallback={
                <div class="relative size-10">
                  {getAvatar(avatars[0], 'bottom-0 left-0 size-6')}
                  {getAvatar(avatars[1], 'left-1/2 top-0 size-6 -translate-x-1/2')}
                  {getAvatar(avatars[2], 'bottom-0 right-0 size-6')}
                  <Show when={avatars.length > 3}>
                    <div class="border-1 absolute bottom-0 right-0 flex size-6 items-center justify-center rounded-full border-white bg-[#00000080] text-xxs text-white">
                      +{avatars.length - 3}
                    </div>
                  </Show>
                </div>
              }>
              <div class="relative size-10">
                {getAvatar(avatars[0], 'bottom-0 left-0 size-8')}
                {getAvatar(avatars[1], 'bottom-0 right-0 size-8')}
              </div>
            </Show>
          }>
          <img src={getImageUrl('avatar', avatars[0].id)} alt="" class="size-10 rounded-full border border-white" />
        </Show>
      </Show>
    );
  };

  return (
    <div
      class="group relative px-6 py-8 hover:bg-[#A126EC0A]"
      onMouseEnter={() => setShowJump(true)}
      onMouseLeave={() => setShowJump(false)}>
      <div class="flex items-center space-x-3">
        {renderAvatars()}
        <div class="box-border w-10/12">
          <div class="flex items-center justify-between">
            <span class="align-self-center text-lg font-medium text-gray-900">
              {highlightText(props.title, props.highlightText, 'JSX')}
            </span>
          </div>
          <p class="mt-1 truncate text-sm text-text-level03">{props.content}</p>
        </div>
      </div>
      <div class="relative top-8 h-px w-full bg-gray-200" />

      <Show when={showJump()}>
        <button
          onClick={handleJump}
          class="absolute right-6 top-1/2 -translate-y-1/2 rounded-full border border-essential-colour px-2 align-top text-sm text-essential-colour hover:bg-[#a126ec30]">
          {t('Jump')}
        </button>
      </Show>
    </div>
  );
};
