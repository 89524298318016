import { Route, Routes, useNavigate } from '@solidjs/router';
import { BankAccountProvider, BankAccountsListProvider } from '~/contexts/local';
import { BankAccountDetailsPage } from './bank-account-details/BankAccountDetailsPage';
import { ListBankAccountsPage } from './list-bank-accounts';
import { AddBankAccountsModal } from './list-bank-accounts/AddBankAccountPage';
import { EditBankAccountsModal } from './list-bank-accounts/EditBankAccountPage';

const BankAccountsRoutes = () => {
  const navigate = useNavigate();

  return (
    <BankAccountsListProvider>
      <BankAccountProvider>
        <Routes>
          <Route path="/">
            <Route
              path="/add"
              element={
                <>
                  <ListBankAccountsPage />
                  <AddBankAccountsModal onClose={() => navigate('/accounting/bank-accounts', { scroll: false })} />
                </>
              }
            />
            <Route
              path="/edit/:bankAccountId"
              element={
                <>
                  <ListBankAccountsPage />
                  <EditBankAccountsModal onClose={() => navigate('/accounting/bank-accounts', { scroll: false })} source="list" />
                </>
              }
            />
            <Route path="/:bankAccountId/*" element={<BankAccountDetailsPage />} />
            <Route path="*" element={<ListBankAccountsPage />} />
          </Route>
        </Routes>
      </BankAccountProvider>
    </BankAccountsListProvider>
  );
};

export default BankAccountsRoutes;
