import { Route, Routes, useParams } from '@solidjs/router';
import { createEffect, createMemo, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { AddServiceBillTypeModal } from '~/components/service-bill';
import { TabLinks } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import { LeasesListProvider } from '~/contexts/local';
import { useGoBack } from '~/hooks';
import { useDeviceDetect } from '~/hooks/usedeviceDetect';
import { AddAndEditOwnerModal } from '~/pages/properties/components/AddAndEditOwnerModal';
import { PropertyAnnouncement } from './tabs/PropertyAnnouncement';
import { PropertyApplication } from './tabs/PropertyApplication';
import { PropertyFiles } from './tabs/PropertyFiles';
import { PropertyInformation } from './tabs/PropertyInformation';
import { PropertyNotes } from './tabs/PropertyNotes';
import PropertyOverviewTab from './tabs/PropertyOverviewTab';
import { PropertyOwner } from './tabs/PropertyOwner';
import PropertyUnitTab from './tabs/PropertyUnitTab';
import { PropertySummary } from './tabs/overview/PropertySummary';

export const PropertyDetailsRoutes = () => {
  const { isMobileView } = useDeviceDetect();
  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { t } = useLocalization();
  const { current, getPropertyOverview } = useProperties();
  const goBack = useGoBack();

  const breadcrumbItems = createMemo(() => [
    { label: t('Properties'), link: '/portfolios' },
    { label: current()?.portfolio?.name || t('Loading'), link: `/portfolios/${params.portfolioId}` },
    { label: current()?.name || t('Loading') },
  ]);

  createEffect(() => {
    if (params.propertyId) {
      getPropertyOverview(params.propertyId);
    }
  });

  return (
    <div class="flex size-full flex-col">
      <Breadcrumb backLink={() => goBack()} items={breadcrumbItems()} />

      <div class="mx-3 mt-3 overflow-hidden rounded-lg bg-essential-colour md:mx-8 md:mt-0">
        <PropertySummary />
        <TabLinks
          class="bg-black/10 text-white *:text-inherit md:pl-16"
          items={[
            { label: t('Property overview'), href: '' },
            { label: t('Units'), href: 'units' },
            { label: t('Applications'), href: 'application' },
            { label: t('Announcements'), href: 'announcement' },
            { label: t('Property info'), href: 'information' },
            { label: t('Owners'), href: 'owners' },
            { label: t('Files'), href: 'files' },
            { label: t('Notes'), href: 'notes' },
          ]}
          replace
        />
      </div>

      <LeasesListProvider>
        <Routes>
          <Route path="/" component={PropertyOverviewTab} />
          <Route path="/units/*" component={PropertyUnitTab} />
          <Route path="/application" component={PropertyApplication} />
          <Route path="/announcement" component={PropertyAnnouncement} />
          <Route path="/information" component={PropertyInformation} />
          <Route path="/owners/*" component={PropertyOwner} />
          <Route path="/files/*" component={PropertyFiles} />
          <Route path="/notes/*" component={PropertyNotes} />
        </Routes>

        <Routes>
          <Show when={!isMobileView()}>
            <Route path="/owners/add" component={AddAndEditOwnerModal} />
            <Route path="/owners/:ownerId" component={AddAndEditOwnerModal} />
            <Route path="/addServiceBill" element={<AddServiceBillTypeModal type="property" />} />
          </Show>
        </Routes>
      </LeasesListProvider>
    </div>
  );
};
