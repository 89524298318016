interface Category {
  name: string;
  description: string;
}

const initialCategories: Category[] = [
  {
    name: 'Plumbing',
    description:
      'Requests addressing water systems issues, including blocked toilets, leaking faucets, broken pipes, and malfunctioning water heaters. Ideal for ensuring all plumbing fixtures and infrastructure are functioning properly',
  },
  {
    name: 'Roofing',
    description:
      'Concerns related to roof maintenance, such as shingle repairs, sealing leaks, and gutter cleaning. Essential for preventing water damage and ensuring the longevity of the roof',
  },
  {
    name: 'Gardening',
    description:
      'Covers all types of garden upkeep, including pruning, hedge trimming, lawn mowing, and pest control. Keeps outdoor spaces healthy and aesthetically pleasing',
  },
  {
    name: 'Electrical',
    description:
      'Addresses issues related to electrical systems, such as circuit breaker replacements, faulty wiring, and light fixture installations. Ensures safe and functional electrical operations',
  },
  {
    name: 'HVAC',
    description:
      'Involves heating, ventilation, and air conditioning systems, including furnace repairs, AC unit servicing, and duct cleaning. Critical for maintaining comfortable and healthy indoor air quality',
  },
  {
    name: 'Appliance Repair',
    description:
      'Focuses on the repair and maintenance of household appliances like refrigerators, dishwashers, washers, and dryers. Ensures appliances operate efficiently and prolongs their lifespan',
  },
  {
    name: 'Painting and Decor',
    description:
      'Includes painting services, wallpaper installations, and minor decorative repairs to enhance the aesthetic appeal of the property',
  },
  {
    name: 'Carpentry',
    description:
      'Encompasses woodwork repairs and installations, such as fixing broken doors, window frames, and installing new shelving units. Vital for maintaining the structural integrity and functionality of wood elements',
  },
  {
    name: 'Pest Control',
    description:
      'Deals with the extermination or removal of unwanted pests such as rodents, insects, and other wildlife. Essential for a safe and hygienic living environment',
  },
];

export default initialCategories;
