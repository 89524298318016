import { createEffect, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccountsList } from '~/contexts/local';
import { BankAccountsListView } from './list-components/BankAccountsListView';

const ListBankAccounts = () => {
  const { t } = useLocalization();
  const { error, isLoading, bankAccounts } = useBankAccountsList();

  createEffect(() => {
    if (error instanceof Error) {
      console.log('Throwing the error:', error());
      throw error();
    }
  });

  return (
    <div class="flex size-full flex-col gap-5 p-8 text-sm" classList={{ 'cursor-wait': isLoading() }}>
      <div class="flex w-full items-center justify-between rounded-lg border border-partingline bg-white p-4">
        <div class="flex items-center gap-1 text-xl font-medium">
          <Show when={!isLoading() && bankAccounts()} fallback={<Skeleton class="h-8 w-10" />}>
            <span class="text-essential-colour">{bankAccounts()?.length}</span>
          </Show>
          {t('Bank accounts')}
        </div>
        <LinkButton href="/accounting/bank-accounts/add" noScroll>
          <IconPlus class="text-white" />
          <div class="hidden md:inline-block">{t('Add a bank account')}</div>
        </LinkButton>
      </div>
      <BankAccountsListView />
    </div>
  );
};

export default ListBankAccounts;
