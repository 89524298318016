import { Match, Switch, Show, createSignal } from 'solid-js';
import IconPurpleCheck from '~/assets/images/common/checkmark.svg';
import IconPlaid from '~/assets/third-parties/plaidSm.svg';
import IconStripe from '~/assets/third-parties/stripeSm.svg';
import { BankAccountMask } from '~/components/common/BankAccountMask';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { PaymentAccountProvider } from '~/swagger/Api';
import { cn } from '~/utils/classnames';

type PresentableBankAccount = any;

export type BankAccountCardProp = {
  account: MagicDoor.Api.PaymentAccountDto;
  class?: string;
  checked?: boolean;
  buttonsClass?: string;
  buttons?: ('edit' | 'remove' | 'check')[];
  onCheck?: (account: PresentableBankAccount) => void;
  onEdit?: (account: PresentableBankAccount) => void;
  onRemove?: (account: PresentableBankAccount) => void;
};

export const BankAccountCard = (props: BankAccountCardProp) => {
  const { t } = useLocalization();
  const [showConfirmation, setShowConfirmation] = createSignal<boolean>();

  const handleClick = () => {
    props.onCheck && props.onCheck(props.account);
  };

  const handleEditClick = (e?: MouseEvent) => {
    e?.stopPropagation();
    props.onEdit && props.onEdit(props.account);
  };

  const handleRemoveClick = (e?: MouseEvent) => {
    e?.stopPropagation();
    props.onRemove && props.onRemove(props.account);
  };

  return (
    <div
      onClick={handleClick}
      class={cn(
        { 'border-border-level01 group cursor-pointer rounded-xl border p-3': props.buttons?.includes('check') },
        { 'border-primary-color text-primary-color bg-primary-color checked': props.checked },
        props.class
      )}>
      <Switch>
        <Match when={props.account.accountProvider === PaymentAccountProvider.Plaid}>
          <div class={cn('border-border-level01 flex items-center gap-2 rounded-lg border bg-white p-3 pr-7', props.class)}>
            <div class="size-10 rounded-lg bg-[#0F1010] p-2.5">
              <img src={IconPlaid} class="size-5" />
            </div>
            <div class="inline-flex flex-col gap-0.5">
              <div class="text-xs font-medium text-text-level03">{props.account.name}</div>
              <div class="group-[.checked]:text-primary-color text-sm font-semibold text-text-level01 md:text-base">
                <BankAccountMask accountMask={props.account.accountMask} />
              </div>
            </div>
          </div>
          <Show when={props.buttons && props.buttons.length}>
            <div class={cn('mt-2 flex items-center justify-between gap-2', props.buttonsClass)}>
              <div>
                <Show when={props.buttons?.includes('edit')}>
                  <Button
                    rounded="full"
                    variant="outlined"
                    color="primary"
                    class={cn('px-3 py-1 text-xs', { 'text-white': props.checked })}
                    onClick={handleEditClick}>
                    {t('Edit')}
                  </Button>
                </Show>
                <Show when={props.buttons?.includes('remove')}>
                  <Button
                    rounded="full"
                    variant="outlined"
                    class={cn('px-3 py-1 text-xs', { 'text-white': props.checked })}
                    onClick={() => setShowConfirmation(true)}>
                    {t('Remove')}
                  </Button>
                </Show>
              </div>
              <Show when={props.buttons?.includes('check')}>
                <div
                  class={cn('border-1 flex size-6 items-center justify-center rounded-[12px] border border-text-level03 bg-white', {
                    'border-primary-color': props.checked,
                  })}>
                  <Show when={props.checked}>
                    <img src={IconPurpleCheck} />
                  </Show>
                </div>
              </Show>
            </div>
          </Show>
        </Match>
      </Switch>
      <Switch>
        <Match when={props.account.accountProvider === PaymentAccountProvider.Stripe}>
          <div class={cn('border-border-level01 flex items-center gap-2 rounded-lg border bg-white p-3 pr-7', props.class)}>
            <div class="size-10 rounded-lg bg-[#635BFF] p-2.5">
              <img src={IconStripe} class="size-5" />
            </div>
            <div class="inline-flex flex-col gap-0.5">
              <div class="text-xs font-medium text-text-level03">{props.account.name}</div>
              <div class="group-[.checked]:text-primary-color text-sm font-semibold text-text-level01 md:text-base">
                <BankAccountMask accountMask={props.account.accountMask} />
              </div>
            </div>
          </div>
          <Show when={props.buttons && props.buttons.length}>
            <div class={cn('mt-2 flex items-center justify-between gap-2', props.buttonsClass)}>
              <div>
                <Show when={props.buttons?.includes('edit')}>
                  <Button
                    rounded="full"
                    variant="outlined"
                    color="primary"
                    class={cn('px-3 py-1 text-xs', { 'text-white': props.checked })}
                    onClick={handleEditClick}>
                    {t('Edit')}
                  </Button>
                </Show>
                <Show when={props.buttons?.includes('remove')}>
                  <Button
                    rounded="full"
                    variant="outlined"
                    class={cn('px-3 py-1 text-xs', { 'text-white': props.checked })}
                    onClick={() => setShowConfirmation(true)}>
                    {t('Remove')}
                  </Button>
                </Show>
              </div>
              <Show when={props.buttons?.includes('check')}>
                <div
                  class={cn('border-1 flex size-6 items-center justify-center rounded-[12px] border border-text-level03 bg-white', {
                    'border-primary-color': props.checked,
                  })}>
                  <Show when={props.checked}>
                    <img src={IconPurpleCheck} />
                  </Show>
                </div>
              </Show>
            </div>
          </Show>
        </Match>
      </Switch>
    </div>
  );
};
