import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount, useBankAccountsList } from '~/contexts/local';

export const useBankAccountActions = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { deleteBankAccount } = useBankAccount();
  const { onBankAccountDeleted } = useBankAccountsList();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = createSignal(false);
  const [bankAccountToDelete, setBankAccountToDelete] = createSignal<string | null>(null);
  const [isDeleting, setIsDeleting] = createSignal(false);

  const handleSettings = (bankAccountId: string, fromList?: boolean) => {
    if (fromList) {
      navigate(`/accounting/bank-accounts/edit/${bankAccountId}`);
    } else {
      navigate(`/accounting/bank-accounts/${bankAccountId}/edit`);
    }
  };

  const handleViewBankReport = (bankAccountId: string) => {
    navigate(`/reports/bank-account-activity?bankAccountId=${bankAccountId}`);
  };

  const confirmDeleteBankAccount = (bankAccountId: string) => {
    setBankAccountToDelete(bankAccountId);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteBankAccount = async () => {
    setIsDeleting(true);
    const id = bankAccountToDelete();
    if (!id) return;

    try {
      await deleteBankAccount(id);
      onBankAccountDeleted(id);
      toast.success(t('{name} has been deleted successfully', { name: 'Bank account' }));
      navigate('/accounting/bank-accounts');

      return id;
    } catch (error) {
      console.error(error);
    } finally {
      setBankAccountToDelete(null);
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  return {
    handleSettings,
    handleViewBankReport,
    handleDeleteBankAccount,
    confirmDeleteBankAccount,
    isDeleting,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
  };
};
